import { Component, AfterViewInit, Input } from '@angular/core';
import { BaseFilterComponent } from '../base-filter.component';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-filter',
  templateUrl: './autocomplete-filter.component.html',
  styleUrls: ['./autocomplete-filter.component.css']
})
export class AutocompleteFilterComponent extends BaseFilterComponent implements AfterViewInit {

  componentTypeName = "AutocompleteFilterComponent";

  @Input() options: any[] = [];
  @Input() nameField: string = 'name';
  @Input() valueField: string = 'value';

  equals = new FormControl();
  filteredOptions: Observable<any[]> = new Observable<any[]>();

  ngAfterViewInit():void {
    setTimeout(() => this.load());
  }

  onFilterSubmit() {

    const equals = this.equals.value;

    if (typeof equals === 'string') return;

    if (equals) {

      this.filterValue = this.getValueTag() + equals[this.valueField];
      this.filterName = equals[this.nameField];
    }
    else {
      this.filterValue = '';
      this.filterName = '';
    }

    this.filter.next(true);
    this.menu.closeMenu();
  }

  clear() {
    this.equals.setValue('');
    this.filterValue = '';
    this.filterName = '';
    this.filter.next(true);

    this.menu.closeMenu();
  }

  load() {
    this.filteredOptions = this.equals.valueChanges
    .pipe(
      startWith<string | any>(''),
      map(value => typeof value === 'string' ? value : value[this.nameField]),
      map(name => name ? this._filter(name) : this.options.slice())
    );
  }

  displayFn(user: any) {
    if (user) { return user.name; }
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

}
