import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class PreInvoiceFeesService {

  private url = environment.apiUrl + 'preinvoicefees/';

  constructor(private http: HttpClient, public router: Router) {
  }

  getPreInvoiceFees(caseId: number){
    return this.http.get<any>(this.url + caseId );
  }

  getFeeTypes() {
    return this.http.get<any>(this.url + 'FeeTypes');
  }

  getMethodsOfVerification() {
    return this.http.get<any>(this.url + 'MethodsOfVerification');
  }

  addFee(caseId: number, feeDetails: any) {
    let formDetails = {
      feeId: feeDetails.feeId,
      netAmount: feeDetails.netAmount,
      vatAmount: feeDetails.vatAmount,
      methodOfVerificationId: feeDetails.methodOfVerificationId,
      supplierId: feeDetails.supplier_id,
      description: feeDetails.description
    };

    return this.http.put<any>(this.url + caseId, formDetails);
  }

  removePreInvoiceFee(caseId: number, preInvoiceFeeId: number){
    return this.http.delete<any>(this.url + caseId + '/' + preInvoiceFeeId);
  }

}
