import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DateTime } from 'luxon';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { CalendarEventComponent } from '../calendar-event/calendar-event.component';
import { CalendarService } from '../calendar-service.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-calendar-month',
  templateUrl: './calendar-month.component.html',
  styleUrls: ['./calendar-month.component.scss']
})
export class CalendarMonthComponent implements OnInit {

  currDate: Date;
  currMoment: DateTime;//moment.Moment;
  startMoment: DateTime;//moment.Moment;
  currMonth: string;
  faChevronRight= faChevronRight;
  faChevronLeft= faChevronLeft;


  @Input() monthViewEnabled: boolean = false;
  @Output() monthViewEnabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() selectedDay?: Date;
  @Output() selectedDayChange: EventEmitter<Date> = new EventEmitter<Date>();

  weeks = [0, 1, 2, 3, 4, 5];
  days = [0, 1, 2, 3, 4, 5, 6];

  appointments:any[] = []

  constructor(public dialog: MatDialog, private calendarService: CalendarService ) {

    this.currDate = DateTime.local().toJSDate();// moment().toDate();
    this.currMoment = DateTime.local();//moment();
    this.startMoment = DateTime.local().startOf('month').startOf('week');
    this.currMonth = this.currMoment.toFormat('MMMM yyyy');
    this.loadData();

  }

  loadData() {

    this.calendarService.getMonthEvents(this.currDate.getMonth() + 1, this.currDate.getFullYear()).subscribe(result => {
      this.appointments = result;
    });
  }

  ngOnInit() { }

  getDate(week: number, day: number) {

    let date = this.startMoment.plus({weeks: week, days: day});
    return date.day;
  }

  isToday(day: number, week: number) {
    if (this.startMoment.plus({weeks : week, days: day}).toISODate() == DateTime.local().toISODate()) return true
    return false;
  }

  nextMonth() {
    this.currMoment = this.currMoment.plus({months: 1});
    this.startMoment = this.currMoment.startOf('month').startOf('week');
    this.currMonth = this.currMoment.toFormat('MMMM yyyy');
    this.currDate = this.currMoment.toJSDate();
    this.loadData();
  }

  prevMonth() {
    this.currMoment = this.currMoment.plus({months: -1});
    this.startMoment = this.currMoment.startOf('month').startOf('week');
    this.currMonth = this.currMoment.toFormat('MMMM yyyy');
    this.currDate = this.currMoment.toJSDate();
    this.loadData();
  }

  getAppointments(day:number, week:number) {
    let date = this.startMoment.plus({weeks : week, days: day}).toISODate();
    return this.appointments.filter(x => DateTime.fromISO(x.appointmentDate).toISODate() == date).slice(0, 4);
  }

  getAppointmentCount(day:number, week:number) {
    let date = this.startMoment.plus({weeks : week, days: day}).toISODate();
    return this.appointments.filter(x => DateTime.fromISO(x.appointmentDate).toISODate() == date).length;
  }

  selectDay(day:number, week:number) {

    console.log(week, day)

    let date = this.startMoment.plus({weeks : week, days: day}).toJSDate();
    this.selectedDayChange.emit(date);
    this.monthViewEnabledChange.emit(false);
  }

  selectEvent(event: any) {
    const dialogRef = this.dialog.open(CalendarEventComponent, {
      width: '450px',
      data: {event: event}
    });
  }

}
