
<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title >Case Overview History</h2>

<div mat-dialog-content class="mat-typography">

      <div>

          <table mat-table [dataSource]="items" >

          <ng-container matColumnDef="text">
            <mat-header-cell *matHeaderCellDef  style="min-width: 30%;"> Text. </mat-header-cell>
            <mat-cell *matCellDef="let item"  style="min-width: 30%;"> {{item.text}}</mat-cell>
          </ng-container>

                <ng-container matColumnDef="addedBy">
            <mat-header-cell *matHeaderCellDef> Added By. </mat-header-cell>
            <mat-cell *matCellDef="let item"> <span *ngIf="item.addedBy">{{item.addedBy.firstName}} {{item.addedBy.lastName}} </span></mat-cell>
          </ng-container>

          <ng-container matColumnDef="addedDate">
              <mat-header-cell *matHeaderCellDef> Date Added. </mat-header-cell>
              <mat-cell *matCellDef="let item"> {{formatDate(item.addedDateTime)}} </mat-cell>
            </ng-container>

          <ng-container matColumnDef="removedBy">
              <mat-header-cell *matHeaderCellDef> Removed By. </mat-header-cell>
              <mat-cell *matCellDef="let item"><span *ngIf="item.removedBy">{{item.removedBy.firstName}} {{item.removedBy.lastName}}</span></mat-cell>
            </ng-container>

          <ng-container matColumnDef="removedDate">
            <mat-header-cell *matHeaderCellDef> Removed Date. </mat-header-cell>
            <mat-cell *matCellDef="let item"> {{formatDate(item.removedDate)}} </mat-cell>
          </ng-container>


          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
          <mat-row *matRowDef="let item; columns: displayedColumns;" ></mat-row>

        </table>

        </div>
      </div>
