import { Component, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormBuilder } from '@angular/forms';
import { SearchService } from '../shared/services/search.service';
import { LookupsService } from '../shared/services/lookups.service';
import { FullSearchResponse } from '../shared/services/interfaces/full-search-response'

@Component({
  selector: 'app-search-case',
  templateUrl: './search-case.component.html',
  styleUrls: ['./search-case.component.scss']
})

export class SearchCaseComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype);
  @ViewChild(MatSort) sort: MatSort = new MatSort();

  sectionTitle: string = 'Full Search';
  resultsTitle: string = 'Search Results';
  isLoadingResults: boolean = false;
  resultsLength: number = 0;

  caseTypes: any[] = [];
  companyGroups: any[] = [];
  paralegalUsers: any[] = [];
  adminUsers: any[] = [];
  addedByUsers: any[] = [];
  createdByUsers: any[] = [];
  caseStatuses: any[] = [];
  results: any[] = [];

  displayedColumns: string[] = ['caseReference', 'caseStatus','paralegal','companyGroup', 'instructedDate', 'caseType', 'partyOneFirstName', 'partyOneLastName', 'partyOneAddressLine1', 'partyOnePostCode', 'propertyAddressLine1', 'propertyPostcode'];

  searchCriteria = this.fb.group({
    caseStatusType: [null],
    caseReference: [null],
    companyGroupId: [null],
    allocatedToParalegalUserId: [null],
    allocatedToAdminUserId: [null],
    addedByUserId: [null],
    createdByUserId: [null],
    typeId: [null],
    instructedDateFrom: [null],
    instructedDateTo: [null],
    partyOneFirstName: [null],
    partyOneLastName: [null],
    partyOneAddressLine1: [null],
    partyOnePostcode: [null],
    propertyPostcode: [null],
    propertyAddressLine1: [null],
    pageNo: [0],
    itemsPerPage: [10],
    orderByColumn: [null],
    orderByDirection: [null]
  }, {});

  constructor(private fb: FormBuilder, private searchService: SearchService, private lookupsService: LookupsService) {

    this.lookupsService.getCaseTypes().subscribe(result => {
      this.caseTypes = result;
    });

    this.lookupsService.getCompanyGroups().subscribe(result => {
      this.companyGroups = result;
    });

    this.lookupsService.getParalegals().subscribe(result => {
      this.paralegalUsers = result;
    });

    this.lookupsService.getAdmins().subscribe(result => {
      this.adminUsers = result;
    });

    this.lookupsService.getAddedBy().subscribe(result => {
      this.addedByUsers = result;
    });

    this.lookupsService.getCreatedBy().subscribe(result => {
      this.createdByUsers = result;
    });

    this.lookupsService.getCaseStatuses().subscribe(result => {
      this.caseStatuses = result;
    });
  }

  ngAfterViewInit() {
    this.paginator.page.subscribe(() => this.doSearch());
    this.sort.sortChange.subscribe(() => this.doSearch());
  }

  doClear() {
    this.resultsTitle = 'Search Results';
    this.results = [];
    this.searchCriteria.controls["caseStatusType"].patchValue(null);
    this.searchCriteria.controls["caseReference"].patchValue(null);
    this.searchCriteria.controls["companyGroupId"].patchValue(null);
    this.searchCriteria.controls["allocatedToParalegalUserId"].patchValue(null);
    this.searchCriteria.controls["allocatedToAdminUserId"].patchValue(null);
    this.searchCriteria.controls["addedByUserId"].patchValue(null);
    this.searchCriteria.controls["createdByUserId"].patchValue(null);
    this.searchCriteria.controls["typeId"].patchValue(null);
    this.searchCriteria.controls["instructedDateFrom"].patchValue(null);
    this.searchCriteria.controls["instructedDateTo"].patchValue(null);
    this.searchCriteria.controls["partyOneFirstName"].patchValue(null);
    this.searchCriteria.controls["partyOneLastName"].patchValue(null);
    this.searchCriteria.controls["partyOneAddressLine1"].patchValue(null);
    this.searchCriteria.controls["partyOnePostcode"].patchValue(null);
    this.searchCriteria.controls["propertyPostcode"].patchValue(null);
    this.searchCriteria.controls["propertyAddressLine1"].patchValue(null);
  }

  doSearch() {
    this.isLoadingResults = true;

    this.searchCriteria.controls["pageNo"].patchValue(this.paginator.pageIndex);
    this.searchCriteria.controls["itemsPerPage"].patchValue(this.paginator.pageSize);
    this.searchCriteria.controls["orderByColumn"].patchValue(this.sort.active);
    this.searchCriteria.controls["orderByDirection"].patchValue(this.sort.direction);

    this.searchService.fullCaseSearch(this.searchCriteria.value).subscribe((data: FullSearchResponse) => {
      this.results = data.pagedResults;
      this.resultsLength = data.totalResults;
      this.resultsTitle = 'Search Results (' + this.resultsLength + ' matches)';
      this.isLoadingResults = false;
    });
  }
}
