import { Component, OnInit, Inject } from '@angular/core';
import { faConstruction} from '@fortawesome/pro-light-svg-icons';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseService } from 'src/app/shared/services/case.service';
import { FormBuilder } from '@angular/forms';
import { FileClosureService } from '../../../shared/services/file-closure.service';

@Component({
  selector: 'app-file-closure',
  templateUrl: './file-closure.component.html',
  styleUrls: ['./file-closure.component.scss']
})
export class FileClosureComponent implements OnInit {

  faConstruction = faConstruction;
  formLoaded: boolean = false;
  hasError: boolean = false;
  errorMessage: string = "";

  caseId: number;

  fileClosureDetails = this.fb.group({
    id: [],
    caseId: [],
    clientName: '',
    caseReference: '',
    colfrUser: null,
    paralegalUser: null,
    adminUser: null,
    workCompleted: null,
    matterAborted: null,
    noContactFromClient: null,
    clientChangeSolicitor: null,
    clientNoLongerWishesToProceed: null,
    closureReasonOther: null,
    objectivesMet: null,
    futureReviewDate: null,
    clientAdvisingRegardingStorage: null,
    furtherActionNotified: null,
    papersItemsReturnedToClient: null,
    destroyDate: null,
    ledgerClear: null,
    writeOffs: null,
    writeOffsReason: null,
    amountWrittenOff: null,
    reasons: null,
    billRaised: null,
    allDisinbursementsDischarged: null,
    clientAccountedToForAllMonies: null,
    clientSentFileClosingLetterAndAdviseReLimitation: null,
    idOnFile: null,
    wasThereAPepAlertOnFile: null,
    amlChecksCarriedOut: null,
    signedTermsOfBusinessOnFile: null,
    fileClosureDate: null,
    dateFormOpenedByAdmin: null,
    closingRiskAssessment: null,
    dateFormOpenedByManagement: null,
    riskAssessmentDateTime: null,
    authorisedByManagementUser: null,
    authorisedByPartnerUser: null
  }, { });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private caseService: CaseService,
  private fileClosureService : FileClosureService,
  private fb: FormBuilder) {

    this.caseId = data.caseId;

    this.fileClosureService.getFileClosureForm(this.caseId).subscribe((response : any) => {

      this.fileClosureDetails.patchValue(response.fileClosureForm);
      this.formLoaded = true;
    },
    (error : any) => {

      console.log('error', error);
      this.hasError = true;
      if(error.status == 999)
        this.errorMessage = error.error;
      else if(error.status == 500 )
        this.errorMessage = "Internal Error";
      else
        this.errorMessage = error.statusText;
  });

  }


  ngOnInit(): void {
  }

  updateValue(){
    this.fileClosureService.update( this.caseId, this.fileClosureDetails.value).subscribe((response : any) => {


  }, (error : any) => {

      /*
      var errorMessage = "Failed to update case";
      if(error.error !== null && error.error.message !== undefined)
      {
      errorMessage = error.error.message;
      }*/

  });
  }

}
