import { Component, OnInit,Inject } from '@angular/core';
import { KeyDatesService } from 'src/app/shared/services/keydates.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-key-dates-history',
  templateUrl: './key-dates-history.component.html',
  styleUrls: ['./key-dates-history.component.scss']
})
export class KeyDatesHistoryComponent implements OnInit {

  private caseId: number;
  keyDates: any;

  displayedColumns: string[] = ['keyDateType', 'value', 'enteredOn', 'enteredByUser', 'removedByUser', 'removedDate', 'removalReason'];


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<KeyDatesHistoryComponent>,
              private keyDatesService: KeyDatesService,
              public dialog: MatDialog) {
                this.caseId = this.data['caseId'];
              }

  ngOnInit(): void {
    this.loadHistory();
  }

  loadHistory(){

    this.keyDatesService.getAllKeyDatesForCase(this.caseId).subscribe(result => {

      this.keyDates = result.keyDates;

    }, error => {

      var errorMessage = "Failed to get key dates history";
      if (error.error !== null && error.error.message !== undefined) {
        errorMessage = error.error.message;
      }

    });

  }

  formatDateWithTime(inDate : any){

   var formattedDate = '';


    if(inDate !== null)
    {

      var dt = DateTime.fromISO(inDate);

      formattedDate = dt.toFormat("dd-MM-yyyy HH:mm:ss");

    if(formattedDate == 'Invalid date')
      formattedDate = '';

    }


    return formattedDate;

  }

  formatDateOnly(inDate: any){

    var formattedDate = '';


    if(inDate !== null)
    {

      var dt = DateTime.fromISO(inDate);

      formattedDate = dt.toFormat("dd-MM-yyyy");

    if(formattedDate == 'Invalid date')
      formattedDate = '';

    }


    return formattedDate;

  }


  cancel(){
    this.dialogRef.close(null);
  }

}
