<div class="body">

  <div class="sidebar">
    <app-dashboard-sidebar></app-dashboard-sidebar>
  </div>

  <div class="content" cdkScrollable>

      <span *ngIf="authService.isPortalManager() || authService.isPortalParaLegalTL()" >

          <mat-tab-group #tasktabs animationDuration="0ms" class="mb20" [(selectedIndex)]="taskTabIndex">
            <mat-tab label="Management Actions" class="mb10">
                <ng-template matTabContent>
                    <app-task-list  userType="management" tasksFor="user" [showCompleted]="false"></app-task-list>
                </ng-template>
            </mat-tab>

            <mat-tab label="Paralegal Actions">
                <ng-template matTabContent class="mb10">
                    <app-task-list  userType="paralegal" tasksFor="user" [showCompleted]="false"></app-task-list>
                </ng-template>
            </mat-tab>

            <mat-tab label="Admin Actions" *ngIf="authService.canSeeAdminActionList()">
                <ng-template matTabContent class="mb10">
                    <app-task-list  userType="admin" tasksFor="user" [showCompleted]="false"></app-task-list>
                </ng-template>
            </mat-tab>

          </mat-tab-group>
      </span>

      <span *ngIf="!authService.isPortalManager() && !authService.isPortalParaLegalTL()" >

          <div *ngIf="authService.canSeeParalegalActionList()">

            <app-card-basic cardTitle="Actions">
                <div card-body>
                  <app-task-list userType="paralegal" tasksFor="user" [showCompleted]="false"></app-task-list>
                </div>
            </app-card-basic>
          </div>

          <div *ngIf="authService.canSeeAdminActionList()">

          <app-card-basic cardTitle="Actions" >
            <div card-body>
              <app-task-list  userType="admin" tasksFor="user" [showCompleted]="false"></app-task-list>
            </div>
          </app-card-basic>
          </div>

      </span>

      <div *ngIf="authService.canSeeUnallocatedCases()" class="mb20">
        <app-card-basic cardTitle="Unallocated Cases">
          <div card-body>
            <app-unallocated-case-list></app-unallocated-case-list>
          </div>
        </app-card-basic>
      </div>
      <mat-tab-group animationDuration="0ms">
          <mat-tab label="Instructed Cases" *ngIf="authService.canSeeInstructedCases()">
              <ng-template matTabContent>
                <app-case-list></app-case-list>
              </ng-template>
          </mat-tab>

          <mat-tab label="On Hold Cases" *ngIf="authService.canSeeInstructedCases()">
            <ng-template matTabContent>
              <app-onhold-case-list></app-onhold-case-list>
            </ng-template>
        </mat-tab>

          <mat-tab label="Completed Cases" *ngIf="authService.canSeeCompletedCases()">
              <ng-template matTabContent>
                <app-completed-case-list></app-completed-case-list>
              </ng-template>
          </mat-tab>

          <mat-tab label="Cancelled Cases" *ngIf="authService.canSeeCancelledCases()">
              <ng-template matTabContent>
                <app-cancelled-case-list></app-cancelled-case-list>
              </ng-template>
          </mat-tab>

          <mat-tab label="Uninstructed Cases" *ngIf="authService.canSeeUninstructedCases()">
              <ng-template matTabContent>
                <app-uninstructed-case-list></app-uninstructed-case-list>
              </ng-template>
          </mat-tab>

        </mat-tab-group>


    <router-outlet></router-outlet>


  </div>

</div>
