<app-card-basic cardTitle="Consultants">
    <div card-body>

      <div class="button-bar">
        <app-searchbar searchType="Consultants" [inline]="true"></app-searchbar>
        <button *ngIf="authService.canAdminConsultants()" mat-mini-fab (click)="addConsultant()" color="primary"><fa-icon [icon]="faPlus"></fa-icon></button>
      </div>

      <div>

          <table mat-table [dataSource]="consultants" matSort matSortActive="name" matSortDisableClear matSortDirection="asc" [ngClass]="{'clickable-list-item': authService.canAdminConsultants()}">

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>
              <app-string-filter #filter title="Filter Name." filterColumn="name">
                <span mat-sort-header>Name.</span>
              </app-string-filter>
            </mat-header-cell>
            <mat-cell *matCellDef="let consultant"> {{consultant.name}} </mat-cell>
          </ng-container>


          <ng-container matColumnDef="emailAddress">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Email. </mat-header-cell>
            <mat-cell *matCellDef="let consultant"> {{consultant.emailAddress}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="postcode">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Postcode. </mat-header-cell>
            <mat-cell *matCellDef="let consultant"> {{consultant.postcode}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="phoneNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number. </mat-header-cell>
            <mat-cell *matCellDef="let consultant"> {{consultant.phoneNumber}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="fees">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Fees. </mat-header-cell>
            <mat-cell *matCellDef="let consultant"> {{consultant.fees}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="active">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Active </mat-header-cell>
            <mat-cell *matCellDef="let consultant"> <span [hidden]="!consultant.active"> <fa-icon [icon]="faCheck"></fa-icon></span> </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
          <mat-row *matRowDef="let consultant; columns: displayedColumns;" (click)="editConsultant(consultant)">
          </mat-row>

        </table>

        <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
      </div>
    </div>
  </app-card-basic>
