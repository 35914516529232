<h1>Reallocate Outstanding Tasks</h1>

<form autocomplete="off">


      <mat-form-field class="example-full-width" >
        <input matInput type="number" placeholder="No of Days" (change)="selectionUpdated()"  [formControl]="noOfDays">
      </mat-form-field>
    <br>

    <mat-form-field class="example-full-width" *ngIf="paralegalUsers && paralegalUsers.length != 0">
        <input type="text" placeholder="Paralegal" aria-label="Paralegal" matInput [formControl]="paralegalSearchInput"
          [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectionUpdated()">
          <mat-option *ngFor="let option of filteredParalegalUsers | async" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="example-full-width" *ngIf="adminUsers && adminUsers.length != 0">
        <input type="text" placeholder="Admin" aria-label="Admin" matInput [formControl]="adminSearchInput"
          [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectionUpdated()">
          <mat-option *ngFor="let option of filteredAdminUsers | async" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div class="buttons">
        <button *ngIf="!isLoading" mat-stroked-button [disabled]="false" color="warn" type="button" (click)="cancel()">Cancel</button>
        <button *ngIf="!isLoading" mat-stroked-button [disabled]="invalidSelection" color="primary" (click)="reallocateTasks()">OK</button>

        <button *ngIf="isLoading" mat-icon-button disabled color="primary" class="right" >
            <mat-icon>
                <mat-spinner color="primary" diameter="20">
                </mat-spinner>
            </mat-icon>
        </button>
      </div>

  </form>
