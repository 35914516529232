<mat-expansion-panel>

    <mat-expansion-panel-header class="mat-row" [ngClass]="getClassForTask(task)">
        <span class="mat-cell mat-column-select" *ngIf="tasksFor == 'user' && authService.canReallocateTasks() && userType !== 'management'">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="selectItem($event)" [ngModel]="itemSelected"
            [checked]="itemSelected">
            </mat-checkbox>
          </span>
          <span class="mat-cell" *ngIf="tasksFor == 'user'">{{task.caseRef}}</span>
          <span class="mat-cell" *ngIf="tasksFor == 'user'">{{task.caseName}}</span>
          <span class="mat-cell" *ngIf="tasksFor == 'user'">{{task.caseType.caseTypeName}}</span>
          <span class="mat-cell">{{task.dueByDate | date: 'dd/MM/yyyy'}}</span>
          <span class="mat-cell"><span *ngIf="task.createdForUser">{{task.createdForUser.firstName}} {{task.createdForUser.lastName}}</span></span>
          <span class="mat-cell action">{{task.action}}</span>
          <span class="mat-cell">{{task.createdDate | date: 'dd/MM/yyyy'}}</span>
          <span class="mat-cell"><span *ngIf="task.createdByUser">{{task.createdByUser.firstName}} {{task.createdByUser.lastName}}</span></span>
          <span class="mat-cell" *ngIf="showCompleted">{{task.completedDate | date: 'dd/MM/yyyy'}}</span>
          <span class="mat-small-icon-cell" ><span *ngIf="task.taskReplies && task.taskReplies.length > 0"><fa-icon [icon]="faCommentAltDots" size="lg"></fa-icon></span></span>

    </mat-expansion-panel-header>


  <div class="container">
      <div class="task" *ngIf="task">
        <div class="task-row">
          <div class="task-cell">Case Ref.</div>
          <div class="task-cell">{{task.caseRef}}</div>
        </div>
        <div class="task-row">
          <div class="task-cell">Due</div>
          <div class="task-cell">{{task.dueByDate | date: 'dd/MM/yyyy'}}</div>
          <div class="task-cell"><span *ngIf="task.createdForUser">For {{task.createdForUser.firstName}} {{task.createdForUser.lastName}}</span></div>
        </div>
        <div class="task-row">
          <div class="task-cell">Created</div>
          <div class="task-cell">{{task.createdDate | date: 'dd/MM/yyyy'}}</div>
          <div class="task-cell"><span *ngIf="task.createdByUser">By {{task.createdByUser.firstName}} {{task.createdByUser.lastName}}</span></div>
        </div>
        <div class="task-row" *ngIf="task.completedDate">
          <div class="task-cell">Completed</div>
          <div class="task-cell">{{task.completedDate | date: 'dd/MM/yyyy'}}</div>
          <div class="task-cell"><span *ngIf="task.completedByUser">By {{task.completedByUser.firstName}} {{task.completedByUser.lastName}}</span></div>
        </div>
        <div class="task-row">
          <div class="task-cell">Entry</div>
          <div class="task-cell"></div>
          <div class="task-cell">{{task.entry}}</div>
        </div>
        <div class="complete">
          <div class="spacer"></div>
          <button mat-stroked-button color="primary" [routerLink]="['/case', task.caseId]" *ngIf="tasksFor == 'user'">
            <fa-icon [icon]="faEye"></fa-icon> View Case
          </button>
          <button mat-stroked-button color="primary" (click)="addTaskReply()">
            <fa-icon [icon]="faPlus"></fa-icon> Add Note
          </button>
          <button mat-stroked-button color="primary" (click)="completeTask()" *ngIf="task.userCanComplete">
            <fa-icon [icon]="faCheck"></fa-icon> Complete Task
          </button>
        </div>
      </div>
      <div class="children">
        <div class="child" *ngFor="let reply of task.taskReplies">
          <div class="child-user">
            <div class="date">{{reply.createdDate | date: 'dd/MM/yyyy HH:MM'}}</div>
            <div class="name" *ngIf="reply.createdByUser">{{reply.createdByUser.firstName}} {{reply.createdByUser.lastName}}</div>
          </div>
          <div class="child-reply">{{reply.entry}}</div>
        </div>

      </div>
    </div>

</mat-expansion-panel>


