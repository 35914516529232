import { Component, Inject, OnInit } from '@angular/core';
import { faHistory } from '@fortawesome/pro-light-svg-icons';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-edit-enquiry',
  templateUrl: './edit-enquiry.component.html',
  styleUrls: ['./edit-enquiry.component.css']
})
export class EditEnquiryComponent implements OnInit {

  editEnquiry = this.fb.group({
    caseId: null,
    enquiry: ''
  }, {});

  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
    //public presubmissionChecklistService: PresubmissionChecklistService,
    public dialogRef: MatDialogRef<EditEnquiryComponent>,
    private fb: FormBuilder,
  ) {
    this.editEnquiry.controls["caseId"].setValue(this.componentData.caseId);
    this.editEnquiry.controls["enquiry"].setValue(this.componentData.enquiry);

  }

  ngOnInit(): void {
  }

  save() {
    this.dialogRef.close(this.editEnquiry.value.enquiry);
  }

  close() {
    this.dialogRef.close(null);
  }

}
