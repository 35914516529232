<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Bank Details</h2>


<mat-dialog-content class="mat-typography">
    <form [formGroup]="bankDetails" autocomplete="off">

        <app-card-label label="Name on Account">
          <mat-form-field floatLabel="never">
            <input matInput formControlName="nameOnCard" (change)="updateField()" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Bank Name">
          <mat-form-field floatLabel="never">
            <input matInput formControlName="bankName" (change)="updateField()" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Account Number">
          <mat-form-field floatLabel="never">
            <input matInput formControlName="accountNumber" (change)="updateField()" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Sort Code">
          <mat-form-field floatLabel="never">
            <input matInput formControlName="sortCode" (change)="updateField()" >
          </mat-form-field>
        </app-card-label>

    </form>

</mat-dialog-content>
