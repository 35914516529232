import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-letter-template',
  templateUrl: './letter-template.component.html',
  styleUrls: ['./letter-template.component.css']
})
export class LetterTemplateComponent implements OnInit {

  @Input() details: any;
  @Input() selected: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
