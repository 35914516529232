import { Component, Input, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { HasElementRef } from '@angular/material/core/common-behaviors/color';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.css']
})
export class SidebarItemComponent implements OnInit, OnDestroy {

  @Input() icon?: IconProp;
  @Input() title: string = "";
  @Input() hideContent: boolean = false;
  @Input() disabled: boolean = false;

  constructor(private elementRef: ElementRef) { 
    this.elementRef.nativeElement.addEventListener('click', this.captureClick, true);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.removeEventListener('click', this.captureClick, true);
  }
  
  private captureClick = (event: PointerEvent) => {
    if (this.disabled) {
      event.stopPropagation();
      event.preventDefault();
      event.stopImmediatePropagation();
      return false;
    }
    return true;
  };

}
