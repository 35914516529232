<button mat-icon-button class="close" tabindex="-1" (click)="close()">x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Manual AML Check</h2>


<mat-dialog-content class="credit-safe-check-dialog">
  <form [formGroup]="manualCheck" autocomplete="off">

    <app-date-picker [simpleCardLabel]="'Date'" [simpleCardForm]="true" [dateValue]="manualCheck.value.date" (onDateChangeEvent)="onDateChange($event)"></app-date-picker>

    <mat-form-field floatLabel="never">
      <textarea matInput #notes placeholder="Notes" maxlength="300" formControlName="notes"></textarea>
      <mat-hint [align]="'end'">{{notes.value?.length || 0}}/300</mat-hint>
    </mat-form-field>



    <div class="buttons">
      <div style="text-align: right">
        <button mat-stroked-button color="primary" (click)="onConfirm()">Confirm</button>
      </div>
    </div>


  </form>

</mat-dialog-content>
