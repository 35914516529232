import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarService } from '../calendar-service.service';


@Component({
  selector: 'app-calendar-event',
  templateUrl: './calendar-event.component.html',
  styleUrls: ['./calendar-event.component.scss']
})
export class CalendarEventComponent implements OnInit {

  //eventForm: FormGroup;

  eventForm = this.formBuilder.group({
    consultantName: ['', Validators.required],
    appointmentDate: ['', Validators.required]
  });

  event = {
    caseRef: '',
    client: '',
    clientPostcode: '',
    appointmentDate: null,
    consultantName: '',
    distanceToClient: 0,
    appointmentCreatedDate: null,
    appointmentCreatedUser: '',
    caseId: -1
  }

  constructor(public dialogRef: MatDialogRef<CalendarEventComponent>, private formBuilder: FormBuilder, private calendarService: CalendarService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.calendarService.getEvent(data.event.caseId).subscribe(x => {
      this.event = x[0];
    });
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
