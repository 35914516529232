<form [formGroup]="lenderDetails" autocomplete="off">
    <app-card-basic cardTitle="Lender Details" >
        <div card-body>

            <app-card-label label="Name">
                <mat-form-field floatLabel="never">
                    <input matInput #name maxlength="75" formControlName="name">
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Active" *ngIf="!newLender">
                <app-yes-no formControlName="isActive" ></app-yes-no>
              </app-card-label>

        </div>
    </app-card-basic>
</form>

<div class="flex" *ngIf="!newLender">
    <span class="spacer"></span>
    <button mat-icon-button disabled color="primary" style="float: right;" *ngIf="updatingLender">
        <mat-icon>
            <mat-spinner color="primary" diameter="20">
            </mat-spinner>
        </mat-icon>
    </button>
    <button mat-raised-button (click)="saveLender()" color="primary" *ngIf="!updatingLender" [disabled]="lenderDetails.invalid">Update</button>
</div>

<div class="flex" *ngIf="newLender">
    <span class="spacer"></span>
    <button mat-raised-button (click)="addLender()" color="primary" [disabled]="lenderDetails.invalid">Save</button>
</div>
