import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class CaseBankDetailsService {

  private url = environment.apiUrl + 'casebankdetails/';

  constructor(private http: HttpClient, public router: Router) {



  }


  getBankDetails(caseId: number){
    return this.http.get<any>(this.url + caseId );
  }


  updateBankDetails(caseId: number,  bankDetails: any){

    let formDetails = {
      Id : bankDetails.id,
      nameOnCard: bankDetails.nameOnCard,
      bankName: bankDetails.bankName,
      accountNumber: bankDetails.accountNumber,
      sortCode: bankDetails.sortCode
    };

    return this.http.post<any>(this.url + caseId, formDetails);
  }


}
