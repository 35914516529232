import { Component, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../../shared/services/user.service';
import { Router } from '@angular/router';
import { faPlus, faCheck } from '@fortawesome/pro-light-svg-icons';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { Observable, merge, of} from 'rxjs';
import { SearchRequest } from '../../shared/services/interfaces/search-request';
import { BaseFilterComponent } from '../../shared/components/table-filters/base-filter.component';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype);
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChildren('filter') filters!: QueryList<BaseFilterComponent>;

  @Input() internalUsers: boolean = false;

  @Input() sectionTitle: string = '';

  faPlus = faPlus;
  faCheck = faCheck;

  resultsLength: number = 0;
  userData: any[] = [];
  isLoadingResults: boolean = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns: string[] = ['firstName','lastName','emailAddress','companyGroup', 'role'];

  roles: string[] = [];

  constructor(private userService: UserService, public router: Router) {
    this.roles[0] = 'Financial Advisor';
    this.roles[1] = 'Financial Advisor Manager';
    this.roles[2] = 'Financial Advisor Admin';
    this.roles[3] = 'Portal Admin';
    this.roles[4] = 'Portal Para Legal';
    this.roles[5] = 'Portal Manager';
  }

  ngOnInit() : void {
    if(this.internalUsers)
    {
      this.displayedColumns = ['firstName','lastName','emailAddress', 'role', 'isTeamLeader', 'teamLeader', 'isAMLOfficer','lastLogin', 'accountDisabled'];
    }
    else
    {
      this.displayedColumns = ['firstName','lastName','emailAddress', 'companyGroup', 'role', 'lastLogin', 'accountDisabled'];
    }
}

  ngAfterViewInit(): void {
    const outputs = this.filters.map(button => button.filter);

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(...outputs).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page, ...outputs)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          let searchRequest: SearchRequest = {
            page: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize,
            orderBy: this.sort.active,
            orderDirection: this.sort.direction,
            filterColumns: this.filters.map(button => button.filterColumn),
            filterStrings: this.filters.map(button => button.filterValue)
          };

          if(this.internalUsers)
            return this.userService.internalUsers(searchRequest);
          else
            return this.userService.externalUsers(searchRequest);

        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.recordCount;
          return data.items;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return of([]);
        })
      ).subscribe(data => this.userData = data);

  }

  editUser(user: any){

    this.router.navigateByUrl('/user/' + user.id + '/admin');
  }


  addUser(){

    if(this.internalUsers)
      this.router.navigateByUrl('/user/internal/new');
    else
      this.router.navigateByUrl('/user/external/new');
  }

  getRoleName(roleId: number){

    var roleName = "";

    if(roleId !== null)
    {
      var findId = roleId - 1;
      roleName = this.roles[findId];
    }

    return roleName;
  }

  formatDate(inDate : any){

    var formattedDate = '';

     if(inDate !== null)
     {
       var dt = DateTime.fromISO(inDate);
       formattedDate = dt.toFormat("dd-MM-yyyy HH:mm:ss");

     if(formattedDate == 'Invalid date')
       formattedDate = '';
     }

     return formattedDate;
   }

}
