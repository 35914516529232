<form [formGroup]="lenderSolDetails" autocomplete="off">
    <app-card-basic cardTitle="Lender Solicitor Details" >
        <div card-body>

            <app-card-label label="Name">
                <mat-form-field floatLabel="never">
                    <input matInput #name maxlength="75" formControlName="name">
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Address Line 1">
                <mat-form-field floatLabel="never">
                     <input matInput #addressLine1  maxlength="250" formControlName="addressLine1"/>
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Address Line 2">
                <mat-form-field floatLabel="never">
                    <input matInput #addressLine2 maxlength="250" formControlName="addressLine2"/>
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Address Line 3">
                <mat-form-field floatLabel="never">
                    <input matInput #addressLine3 maxlength="250" formControlName="addressLine3"/>
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Address Line 4">
                <mat-form-field floatLabel="never">
                    <input matInput #addressLine4 maxlength="250" formControlName="addressLine4"/>
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Address Line 5">
                <mat-form-field floatLabel="never">
                    <input matInput #addressLine5 maxlength="250" formControlName="addressLine5"/>
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Postcode">
                <mat-form-field floatLabel="never">
                    <input matInput #postcode  maxlength="12" formControlName="postcode"/>
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Active" *ngIf="!newLenderSol">
                <app-yes-no formControlName="isActive" ></app-yes-no>
              </app-card-label>

        </div>
    </app-card-basic>
</form>

<div class="flex" *ngIf="!newLenderSol">
    <span class="spacer"></span>
    <button mat-icon-button disabled color="primary" style="float: right;" *ngIf="updatingLenderSol">
        <mat-icon>
            <mat-spinner color="primary" diameter="20">
            </mat-spinner>
        </mat-icon>
    </button>
    <button mat-raised-button (click)="saveLenderSol()" color="primary" *ngIf="!updatingLenderSol" [disabled]="lenderSolDetails.invalid">Update</button>
</div>

<div class="flex" *ngIf="newLenderSol">
    <span class="spacer"></span>
    <button mat-raised-button (click)="addLenderSol()" color="primary" [disabled]="lenderSolDetails.invalid">Save</button>
</div>
