import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CaseService } from '../../../../shared/services/case.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-case-notes-history',
  templateUrl: './case-notes-history.component.html',
  styleUrls: ['./case-notes-history.component.scss']
})
export class CaseNotesHistoryComponent implements OnInit {

  private caseId: number;
  notes: any;

  displayedColumns: string[] = ['notes','addedBy', 'addedDate', 'removedBy', 'removedDate', 'lastUpdated'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<CaseNotesHistoryComponent>,
  private caseService: CaseService,
  public dialog: MatDialog) {
    this.caseId = this.data['caseId'];
  }

  ngOnInit(): void {
    this.loadNotes();
  }

  loadNotes(){

    this.caseService.getAllCaseNotes(this.caseId).subscribe(result => {

      this.notes = result.caseNotes;

    }, error => {

      var errorMessage = "Failed to get case notes history";
      if (error.error !== null && error.error.message !== undefined) {
        errorMessage = error.error.message;
      }

    });
  }

  formatDate(inDate : any){

    var formattedDate = '';


     if(inDate !== null)
     {

       var dt = DateTime.fromISO(inDate);

       formattedDate = dt.toFormat("dd-MM-yyyy HH:mm:ss");

     if(formattedDate == 'Invalid date')
       formattedDate = '';

     }


     return formattedDate;

   }


  cancel(){
    this.dialogRef.close(null);
  }
}
