
<div mat-dialog-content class="mat-typography">

    <h1>Cancel Case</h1>

    <app-card-label label="Cancellation Reason">
      <mat-form-field floatLabel="never">
        <mat-select placeholder="Select Reason"  [(ngModel)]="selectedReason" >
         <mat-option [value]="0"></mat-option>
         <mat-option *ngFor="let reason of reasons" [value]="reason.id">{{reason.reason}}</mat-option>
       </mat-select>
      </mat-form-field>
    </app-card-label>

    <app-card-label label="Details" [hidden]="selectedReason != 1">
      <mat-form-field floatLabel="never">
          <textarea autosize matInput [(ngModel)]="cancellationReasonOther"  maxlength="500"></textarea>
      </mat-form-field>
    </app-card-label>

    <div class="buttons">
      <button mat-stroked-button color="warn" type="button" (click)="cancel()">Cancel</button>
      <button mat-stroked-button [disabled]="(selectedReason == null) || (selectedReason == 1 && (cancellationReasonOther == null || cancellationReasonOther.length == 0)) " color="primary" type="button" (click)="save()">Save</button>
    </div>

</div>

