import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarService } from '../shared/components/sidebar/sidebar.service';
import { CaseService } from '../shared/services/case.service';
import { LettersService } from '../shared/services/letters.service';
import { FileUploaderComponent } from './shared/file-uploader/file-uploader.component';
import { TimelineItemComponent } from './timeline-item/timeline-item.component';
import { Subscription } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';
import { CaseFilesService } from '../shared/services/casefiles.service';
import { CorrespondenceTimelineService } from '../shared/services/correspondence-timeline.service';
import { PdfCreatorComponent } from '../case/shared/pdf-creator/pdf-creator.component';
import { RenameItemComponent } from './../shared/components/rename-item/rename-item.component';
import { EmailComponent } from './shared/email/email.component';
import { PhonecallComponent } from './shared/phonecall/phonecall.component';
import { SmsComponent } from './shared/sms/sms.component';
import { FaxComponent } from './shared/fax/fax.component';
import { faEllipsisH, faThumbtack, faSearch, faBackspace, faCheckSquare, faTimes, faPause, faPlay,faHistory } from '@fortawesome/pro-light-svg-icons';
import { environment } from '../../../src/environments/environment';
import { ViewCaseNoteComponent } from './shared/case-notes/view-case-note/view-case-note.component';
import { OnHoldComponent } from '../on-hold/on-hold.component';
import { OffHoldComponent } from '../off-hold/off-hold.component';
import { ArchiveTimelineItemComponent } from './shared/archiving-items/archive-timeline-item/archive-timeline-item.component';
import { AuthService } from '../auth/auth.service';
import { ArchivedTimelineItemListComponent } from './shared/archiving-items/archived-timeline-item-list/archived-timeline-item-list.component';
import { DocumentPreviewComponent } from './shared/document-preview/document-preview.component';
import { KlyantService } from '../shared/services/klyant.service';
import { KlyantComponent } from '../klyant/klyant.component';
import { ItemPdfGeneratorComponent } from './shared/item-pdf-generator/item-pdf-generator.component';
import { CaseSidebarComponent } from './shared/case-sidebar/case-sidebar.component';
import { BasicPopupYesNoComponent } from '../shared/components/basic-popup-yes-no/basic-popup-yes-no.component';
import { BasicPopupOkComponent } from '../shared/components/basic-popup-ok/basic-popup-ok.component';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.css']
})
export class CaseComponent implements OnInit {

  @ViewChild(CaseSidebarComponent) sideBar:CaseSidebarComponent;

  showDrop = false;
  showSidebar = false;
  isMedium = false;
  isSmall = false;
  allowMultiSelect: boolean = false;
  caseId?: number;
  klyantEnabled: boolean = false;
  klyantDetails: any = [];
  case: any;
  caseLoaded: boolean = false;
  timelineItemsLoading: boolean = false;

  searchType: any [] = [];
  searchString: string = '';
  showSearch: boolean = false;

  correspondenceTimelineItems: any[] = [];
  archivedCorrespondenceTimelineItems: any[] = [];

  selectedItems: any[] = [];
  existingFiles: any[] = [];
  faEllipsisH = faEllipsisH;
  faThumbtack = faThumbtack;
  faSearch= faSearch;
  faBackspace = faBackspace;
  faCheckSquare = faCheckSquare;
  faTimes = faTimes;
  faPause = faPause;
  faPlay = faPlay;
  faHistory = faHistory;

  showCaseHeader: boolean = false;
  caseHeaderMessage: string = '';
  caseHeaderAlert: boolean = false;
  caseHeaderWarning: boolean = false;
  caseHeaderInfo: boolean = false;
  caseHeaderColour: string = '';

  @ViewChild('innerContent') innerContent: ElementRef | undefined;
  @ViewChildren(TimelineItemComponent) timelineItems!: QueryList<TimelineItemComponent>;

  @ViewChild('contextMenuTrigger') contextMenuTrigger!: MatMenuTrigger;

  @ViewChild(MatMenuTrigger)  contextMenu!: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };

  @ViewChild(MatMenuTrigger)  contextMenuMultipleSelected!: MatMenuTrigger;

  refreshSubscription: Subscription;
  refreshTimelineSubscription: Subscription;

  selectedItem: any;

  constructor(private renderer: Renderer2,
    public dialog: MatDialog,
    breakpointObserver: BreakpointObserver,
    sidebarService: SidebarService,
    private caseService: CaseService,
    activatedRoute: ActivatedRoute,
    private caseFilesService: CaseFilesService,
    private lettersService: LettersService,
    private correspondenceTimelineService: CorrespondenceTimelineService,
    private router: Router,
    public authService: AuthService,
    private klyantService: KlyantService) {

    const layoutChanges = breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall]);

    layoutChanges.subscribe(result => {

      this.isSmall = breakpointObserver.isMatched([Breakpoints.Small, Breakpoints.XSmall]);
      this.isMedium = breakpointObserver.isMatched([Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall]);

      if (this.isSmall) {
        sidebarService.closeSideBar();
      }
    });

    sidebarService.showSideBarObservable.subscribe(result => {
      this.showSidebar = result;
    });

    activatedRoute.params.subscribe(params => {
      //this.allLoaded = false;
      //this.caseLoaded = false;
      this.caseId = params['id'];
      this.getCase();
      this.loadCorrespondenceItems();
    });

    this.refreshTimelineSubscription = this.correspondenceTimelineService.refreshCaseTimelineObservable.subscribe(() => {
      this.loadCorrespondenceItems();
    });

    this.refreshSubscription = this.caseService.refreshCaseObservable.subscribe(() => {
      this.getCase();
    });

  }

  ngOnInit() {
  }

  getCase(){

    if (this.caseId) {

      //this.reloadKlyantDetails();

      this.caseService.getCase(this.caseId).subscribe(response => {
        this.case = response;
        this.caseLoaded = true;
        this.klyantService.klyantEnabled().subscribe(response => {
          this.klyantEnabled = response;
        });

        /* Work out whether we need to display the case header*/
        if(this.case.caseData.onHold)
        {
          this.showCaseHeader = true;
          this.caseHeaderMessage = 'This case is on hold';
          this.caseHeaderColour = "#4173f0";

        }
        else if(this.case.caseData.isCancelled)
        {
          this.showCaseHeader = true;
          this.caseHeaderMessage = 'This case is cancelled';
          this.caseHeaderAlert = true;
        }
        else if(this.case.caseData.isInstructed && this.case.caseData.allocatedToParalegalUser == null) // unallocated
        {
          this.showCaseHeader = true;
          this.caseHeaderMessage = 'This case is unallocated';
          this.caseHeaderWarning = true;
        }
        else if(!this.case.caseData.isInstructed)
        {
          this.showCaseHeader = true;
          this.caseHeaderMessage = 'This case is uninstructed';
          this.caseHeaderWarning = true;
        }
        else if(this.case.caseData.isCompleted)
        {
          this.showCaseHeader = true;
          this.caseHeaderMessage = 'This case is completed';
          this.caseHeaderInfo = true;
        }
        else{
          // clear fields down otherwise the banner won't be correct when switching between cases from the recent case list
          this.showCaseHeader = false;
          this.caseHeaderMessage = '';
          this.caseHeaderAlert = false;
          this.caseHeaderWarning = false;
          this.caseHeaderInfo = false;
          this.caseHeaderColour = '';
        }


      });
    }
  }

  loadKlyant() {

    // first need to check if all data has been populated before sending over to klyant
    

    this.klyantService.checkCaseDataPopulatedForKlyant(this.caseId).subscribe(response => {
      if(response){
       this.openKlyant();
      }
      else{

        var dialogRef = this.dialog.open(BasicPopupOkComponent, {
          data: { messageHeader: 'Incomplete Case Information', popupText1: 'Check Property Address has been Completed', popupText2: ''}
        });

        /*
        var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
          data: { messageHeader: 'Case Data Missing', popupText1: 'Check Property Address has been Completed', popupText2: 'Are you sure you want to send to Klyant?'}
        });
  
        dialogRef.afterClosed().subscribe((result : any) => {
          if(result)
            this.openKlyant();
        });

        */

      }
    }); 

   
  }

  openKlyant(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId,
      klyantClientId: this.case.caseData.klyantClientId,
      klyantMatterId: this.case.caseData.klyantMatterId
    };
    const dialogRef = this.dialog.open(KlyantComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true){
        //this.caseService.refreshCaseOverview.next();
         window.location.reload();
      }
      
    }); 
  }

  viewInKlyant(){
    window.open(environment.klyant.guiBaseUrl + '/matters/' + this.case.caseData.klyantMatterId , '_blank');
  }

  loadCorrespondenceItems(){

    this.timelineItemsLoading = true;

    let search = {
      typeIds : this.searchType,
      searchText: this.searchString
    }

    if (this.caseId) {
      this.correspondenceTimelineService.getCorrespondenceTimelineItems(this.caseId, search).subscribe(response => {
        this.correspondenceTimelineItems = response.items;
        console.log(response.items);
        this.timelineItemsLoading = false;
      });
      this.correspondenceTimelineService.getArchivedCorrespondenceTimelineItems(this.caseId).subscribe(response => {
        this.archivedCorrespondenceTimelineItems = response.items;
      });
    }
  }

  getSelected() {
    if (!this.timelineItems) return null;
    return this.timelineItems.map(p => p.selected).join(', ');
  }

  atLeastOneItemSelected() {
    if (!this.timelineItems) return false;
    return this.timelineItems.some(p => p.selected == true);
  }

  atLeastTwoItemsSelected() {
    if (!this.timelineItems) return false;
    return (this.timelineItems.filter(p => p.selected == true).length >= 2);
  }


  dropActive() {

  }

  dragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.showDrop = true;
    this.renderer.setStyle(this.innerContent?.nativeElement, 'pointer-events', 'none');
  }

  dragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.showDrop = false;
    //this.renderer.setStyle(this.innerContent?.nativeElement, 'pointer-events', 'all');
  }

  dragDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    console.log('Drop', event)

    let files = event.dataTransfer.files;

    this.existingFiles = [];

    if (files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        var fileName = files[i].name;
        for (var j = 0; j < this.correspondenceTimelineItems.length; j++) {
          if (this.correspondenceTimelineItems[j].caseFile != null && fileName == this.correspondenceTimelineItems[j].caseFile.fileName) {
            this.existingFiles.push(fileName);
          }
        }

        for (var j = 0; j < this.archivedCorrespondenceTimelineItems.length; j++) {
          if (this.archivedCorrespondenceTimelineItems[j].caseFile != null && fileName == this.archivedCorrespondenceTimelineItems[j].caseFile.fileName) {
            this.existingFiles.push(fileName);
          }
        }
      }
    }

    this.showDrop = false;

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.width = "50%";
    dialogConfig.data = {
      caseId: this.caseId,
      files: files,
      existingFiles: this.existingFiles
    };

    const dialogRef = this.dialog.open(FileUploaderComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result == true){
        //this.caseService.refreshCaseTimeline.next();

      }
      this.renderer.setStyle(this.innerContent?.nativeElement, 'pointer-events', 'all');
    });


  }

  optionClick(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }


  dblClickItem(item: any)
  {
    this.selectedItem = item;

    console.log(item);
    switch(item.correspondenceTypeId)
    {
      case 1: //Email
      this.openEmail(item.emailItem);
        break;
      // @ts-ignore
      case 2: {
          if (item.caseFile.fileName.slice(item.caseFile.fileName.length - 5) == '.docx') {

            // letters will have a casefile associated with them and will open word for editing
            // window.open(environment.apiUrl + 'letters/edit/' + this.caseId + '/' + item.caseFile.caseFileId + '/' + item.caseFile.fileName, "_blank");
            this.lettersService.editletter(this.caseId!, item.caseFile.caseFileId, item.caseFile.fileName).subscribe(response => {
              var blob = response.body;
              if (blob != null) {
                var a = document.createElement('a');
                a.download = item.caseFile.fileName;
                a.href = URL.createObjectURL(blob); // (window.webkitURL || window.URL)
                //a.dataset.downloadurl = ['application/vnd.openxmlformats', a.download, a.href].join(':');
                a.click();
              }
            });
            break;
          }
          // falls through
        }

      case 3: //Case File

      /* Formats
        PDF = 1,
        DocX = 2,
        EML = 3,
        xlsX = 4,
        IMG = 5,
        Doc = 6,
        xls = 7
      */

        switch(item.caseFile.format)
        {
          case 1: //PDF
          case 5: //IMG
          case 6: //Doc
          case 7: //XLS
          case 2:
          case 4:
          case 8: // html files
              this.caseFilesService.getFile(item.entityId).subscribe(response => {
                var blob = response.body;
                if(blob != null) {
                  // For PDFs and images
                  const fileURL = URL.createObjectURL(blob);
                  //const fileURL = URL.createObjectURL(response.body);
                  window.open(fileURL, '_blank');
                }
              });
            break;


          case 3: // Emails
              //alert('email case files - not handled yet');
              this.caseFilesService.getFile(item.entityId).subscribe(response => {
                var blob = response.body;
                if(blob != null) {
                  var a = document.createElement('a');
                  a.download = item.caseFile.fileName;
                  a.href = URL.createObjectURL(blob);
                  a.click();
                }
              });
            break;
        }

      break;
      case 4: //Phonecall
        this.openPhone(item.phoneCall);
        break;
      case 5: //Text
        this.openSms(item.smsItem);
        break;
      case 6: //Fax
      this.openFax(item.faxItem);
        break;
      case 7: // O365 email picked up by Graph
        this.caseFilesService.getFile(item.entityId).subscribe(response => {
          var blob = response.body;
          if(blob != null) {
            var a = document.createElement('a');
            a.download = item.caseFile.fileName;
            a.href = URL.createObjectURL(blob);
            a.click();
          }
        });
        break;
       case 8: //Note
      this.openCaseNote(item.caseNote);
        break;
    }

  }


  onContextMenu(event: MouseEvent, item: any) {
    event.preventDefault();
    event.stopPropagation();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';

    this.contextMenu.closeMenu();

    if(this.atLeastTwoItemsSelected())
    {
      this.contextMenu.menuData = { 'item': null };
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
    }
    else{
      this.contextMenu.menuData = { 'item': item };
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
    }

  }

  previewDocument(item: any) {
    const dialogRef = this.dialog.open(DocumentPreviewComponent, {
      data: {caseFileId: item.entityId }
    });
  }


  openPortal1Case(){

      //alert('Coming soon');
    window.open(
      environment.portal1Url + '/case/' + this.caseId, "_self");

  }

  openPDFStitcher(){

    const dialogConfig = new MatDialogConfig();

    let items: any [] = [];

    this.timelineItems.forEach(p => {
      if(p.selected == true)
      {
        items.push(p.details); // details is the correspondence item itself
      }
    });

    dialogConfig.disableClose = true;
      dialogConfig.data = {
        caseId: this.caseId,
        items: items
    };

    const dialogRef = this.dialog.open(PdfCreatorComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result == true){
        this.correspondenceTimelineService.refreshCaseTimeline.next();
      }
    });


  }

  generatePDF(item: any){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
      dialogConfig.data = {
        caseId: this.caseId,
        item: item
    };

    const dialogRef = this.dialog.open(ItemPdfGeneratorComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result == true){
        this.correspondenceTimelineService.refreshCaseTimeline.next();
      }
    });


  }

  openRename(item: any){

    const dialogConfig = new MatDialogConfig();

    //dialogConfig.disableClose = true;
      dialogConfig.data = {
        timelineItem: item
    };

    const dialogRef = this.dialog.open(RenameItemComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result == true){

      }
    });

  }

  openEmail(emailObj: any) {

    const dialogConfig = new MatDialogConfig();

    let items: any [] = [];
    emailObj.attachments.forEach((item : any) => {
        items.push(item.caseFile);
     });
   
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      emailObj: emailObj,
      caseFiles: items
    };

    const dialogRef = this.dialog.open(EmailComponent, dialogConfig);
  }

  openPhone(phonecallObj: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
      dialogConfig.data = {
      phonecallObj : phonecallObj
    };

    const dialogRef = this.dialog.open(PhonecallComponent, dialogConfig);
  }

  openSms(smsObj: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
      dialogConfig.data = {
        smsObj: smsObj
    };

    const dialogRef = this.dialog.open(SmsComponent, dialogConfig);
  }

  openFax(faxObj: any) {

    const dialogConfig = new MatDialogConfig();

    let items: any [] = [];
    faxObj.attachments.forEach((item : any) => {
        items.push(item.caseFile);
     });

    dialogConfig.disableClose = true;
      dialogConfig.data = {
        faxObj: faxObj,
        caseFiles: items
    };

    const dialogRef = this.dialog.open(FaxComponent, dialogConfig);
  }

  openCaseNote(caseNoteObj: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
      dialogConfig.data = {
        caseNoteObj: caseNoteObj
    };

    const dialogRef = this.dialog.open(ViewCaseNoteComponent, dialogConfig);
  }

  emailItems(item: any){

    let items: any [] = [];
    if(item !== null)
    {
      items.push(item.caseFile);
    }
    else
    {
      this.timelineItems.forEach(item => {
        if(item.selected == true && (item.details.correspondenceTypeId == 2 || item.details.correspondenceTypeId == 3))
        {
          items.push(item.details.caseFile);
        }
      });
    }

    // Only case files/ pdfs or letters can be emailed
    if(items.length == 0)
      alert('No valid files have been selected for emailing');
    else
    {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
        dialogConfig.data = {
          caseId: this.caseId,
          caseFiles: items
      };

      const dialogRef = this.dialog.open(EmailComponent, dialogConfig);
    }
  }

  faxItems(item: any){

    let items: any [] = [];

    if(item !== null)
    {
      items.push(item.caseFile);
    }
    else
    {
      this.timelineItems.forEach(item => {
        if(item.selected == true && (item.details.correspondenceTypeId == 2 || item.details.correspondenceTypeId == 3))
        {
          items.push(item.details.caseFile);
        }
      });
    }

    // Only case files can be emailed
    if(items.length == 0)
      alert('No valid files have been selected for faxing');
    else
    {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
        dialogConfig.data = {
          caseId: this.caseId,
          caseFiles: items
      };

      const dialogRef = this.dialog.open(FaxComponent, dialogConfig);
    }
  }


  pinItem(item: any){
    this.correspondenceTimelineService.pinItem( item.id, this.caseId).subscribe(response => {
      this.correspondenceTimelineService.refreshCaseTimeline.next();
    });
  }

  unpinItem(item: any){
    this.correspondenceTimelineService.unpinItem( item.id, this.caseId).subscribe(response => {
      this.correspondenceTimelineService.refreshCaseTimeline.next();
    });
  }

  highlightItem(item: any){
    this.correspondenceTimelineService.highlightItem( item.id, this.caseId).subscribe(response => {
      this.correspondenceTimelineService.refreshCaseTimeline.next();
    });
  }

  unhighlightItem(item: any){
    this.correspondenceTimelineService.unhighlightItem( item.id, this.caseId).subscribe(response => {
      this.correspondenceTimelineService.refreshCaseTimeline.next();
    });
  }

  archiveItems(item: any){

    let items: any [] = [];

    if(item !== null)
    {
      items.push(item.id);
    }
    else
    {
      this.timelineItems.forEach(item => {
        if(item.selected == true && (item.details.correspondenceTypeId != 9 && item.details.correspondenceTypeId != 10))
        {
          items.push(item.details.id);
        }
      });
    }



    var dialogRef = this.dialog.open(ArchiveTimelineItemComponent, {
      data: { itemIds: items,
              caseId:this.caseId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true)
      this.correspondenceTimelineService.refreshCaseTimeline.next();
    });

  }

  searchTimeline(){
      this.loadCorrespondenceItems();
  }

  clearSearch(){
    this.searchType = [];
    this.searchString ='';
    this.loadCorrespondenceItems();
  }

  ngOnDestroy(){
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }

    if(this.refreshTimelineSubscription){
      this.refreshTimelineSubscription.unsubscribe();
    }
  }

  cancelMultiSelect(){
    this.allowMultiSelect = false;

    this.timelineItems.forEach(item => {
      if(item.selected == true)
      {
        item.selected = false;
      }
    });
  }

  offHoldCase(){
    var dialogRef = this.dialog.open(OffHoldComponent, {
      data: {
        caseId: this.caseId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true){
        //this.caseService.refreshCaseOverview.next();
         window.location.reload();
      }
    });
  }

  putCaseOnHold(){

    var dialogRef = this.dialog.open(OnHoldComponent, {
      data: {
        caseId: this.caseId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true){
        this.correspondenceTimelineService.refreshCaseTimeline.next();
        this.caseService.refreshCaseOverview.next();
        window.location.reload();
      }
    });
  }

  viewArchivedItems(){
    const dialogRef = this.dialog.open(ArchivedTimelineItemListComponent, {
      data: {
              caseId: this.caseId,
        }
    });
  }

  reloadKlyantDetails() {     
    if (this.sideBar) {
      this.sideBar.loadKlyantDetails()
    }
    this.klyantService.getKlyantDetails(this.caseId).subscribe(response => {
      this.klyantDetails = response;
    });      
  }
}
