import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CaseService } from '../../../../shared/services/case.service';
import { BasicPopupYesNoComponent } from '../../../../shared/components/basic-popup-yes-no/basic-popup-yes-no.component';
import { CorrespondenceTimelineService } from '../../../../shared/services/correspondence-timeline.service';

@Component({
  selector: 'app-add-case-notes',
  templateUrl: './add-case-notes.component.html',
  styleUrls: ['./add-case-notes.component.css']
})
export class AddCaseNotesComponent implements OnInit {

  private caseId: number;

  isLoading: boolean = false;

  editMode: boolean = false;
  private caseNoteId: number;

  notesForm = new FormGroup({
    caseNoteText: new FormControl()
  });

  showOnPortal1: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<AddCaseNotesComponent>,
  public dialog: MatDialog,
  private caseService: CaseService,
  private correspondenceTimelineService: CorrespondenceTimelineService) {
    this.caseId = this.data['caseId'];

    this.caseNoteId = this.data['caseNoteId'];

    if(this.caseNoteId){

      this.editMode = true;
      this.caseService.getCaseNote(this.caseId, this.caseNoteId).subscribe(result => {
        this.notesForm.controls["caseNoteText"].patchValue(result.caseNote.notes);
        this.showOnPortal1 = !result.caseNote.internalNote;
      });
    }

  }

  ngOnInit(): void {
  }

  saveNotes(){

    this.isLoading = true;

    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Is this very important information required in the Case Overview Screen?', popupText1: '', popupText2: ''}
    });

    dialogRef.afterClosed().subscribe((result : any) => {

      var addToOverview = false;

      if(result)
      {
        addToOverview = true;
      }

      let newNote = {
        caseNoteText : this.notesForm.controls['caseNoteText'].value,
        internalNote : !this.showOnPortal1,
        addToOverview: addToOverview
      };

      this.caseService.addCaseNote(this.caseId, newNote).subscribe((response : any) => {

        this.correspondenceTimelineService.refreshCaseTimeline.next();
        this.dialogRef.close(true);

        }, (error : any) => {

          var errorMessage = "Failed to add case note";
          if(error.error !== null && error.error.message !== undefined)
          {
            errorMessage = error.error.message;
          }

        });

    });
  }

  toggle(checkboxVal: boolean){
    this.showOnPortal1 = checkboxVal;

    console.log(this.showOnPortal1);
  }

  cancel(){
    this.dialogRef.close(null);
  }

  updateCaseNote(){
    let note = {
      caseNoteText : this.notesForm.controls['caseNoteText'].value,
      internalNote : !this.showOnPortal1
    };

    this.caseService.updateCaseNote(this.caseId, this.caseNoteId, note).subscribe((response : any) => {

      this.correspondenceTimelineService.refreshCaseTimeline.next();
      this.dialogRef.close(true);

      }, (error : any) => {

        var errorMessage = "Failed to update case note";
        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }

      });


  }

}
