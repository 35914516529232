import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig,MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { KeyDatesService } from '../../../../shared/services/keydates.service';
import { BasicPopupOkComponent } from '../../../../shared/components/basic-popup-ok/basic-popup-ok.component';
import { BasicPopupYesNoComponent } from '../../../../shared/components/basic-popup-yes-no/basic-popup-yes-no.component';
import { BankstatementCheckComponent } from '../bankstatement-check/bankstatement-check.component';
import { CorrespondenceTimelineService } from '../../../../shared/services/correspondence-timeline.service';

@Component({
  selector: 'app-add-key-date',
  templateUrl: './add-key-date.component.html',
  styleUrls: ['./add-key-date.component.css']
})
export class AddKeyDateComponent implements OnInit {

  private caseId?: number;
  private keyDateTypeId?: number;
  public keyDateName?: string;
  private notes?: string;

  public keyDateValue?: any;
  private keyDateId?: number;

  public keyDateTime: string = "";

  private date: any;

  public loading = false;

  includeTime?: boolean = false;

  saveButtonDisabled: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<AddKeyDateComponent>,
  private keyDatesService: KeyDatesService,
  public dialog: MatDialog,
  private correspondenceTimelineService: CorrespondenceTimelineService) {

    this.caseId = this.data.caseId;
    this.keyDateTypeId = this.data.keyDateTypeId;
    this.keyDateId = this.data.keyDateId;
    this.keyDateName = this.data.keyDateName;

    if(this.data.keyDateValue == null){
      this.date = new Date();
      this.keyDateValue = DateTime.local().toFormat('yyyy-MM-dd');
    }
    else
      this.keyDateValue = DateTime.fromISO(this.data.keyDateValue).toFormat('yyyy-MM-dd');

    this.includeTime = data.includeTime;

    if(this.includeTime)
      this.keyDateTime = "00:00";

  }

  ngOnInit(): void {
  }

  cancel(){
    this.dialogRef.close(null);
  }

  validateTime(){
    var isValid = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(this.keyDateTime);

    if(!isValid)
      this.saveButtonDisabled = true;
    else
      this.saveButtonDisabled = false;
  }

  saveKeyDate(){
    var keyDateData: any;

    if(this.includeTime)
    {
          //this.keyDateTime
      console.log('saveKeyDate', this.keyDateValue , this.keyDateTime);

      this.keyDateValue = this.keyDateValue + 'T' +  this.keyDateTime + ':00';

    }

    keyDateData = { caseId: this.caseId, keyDateType: this.keyDateTypeId, value: this.keyDateValue };

    this.loading = true;

      this.keyDatesService.addKeyDate(keyDateData).subscribe(response => {

        this.correspondenceTimelineService.refreshCaseTimeline.next();


      if(this.keyDateTypeId == 19 ) // Documents Returned from Consultant - need to popup and ask about bank statement
      {

        var bankstatementCheckDialogRef = this.dialog.open(BasicPopupYesNoComponent, {
          data: { messageHeader: 'Has the banking statement been received?', popupText1: '', popupText2: ''}
        });

        bankstatementCheckDialogRef.afterClosed().subscribe( (result : any) => {

          if(result)
          {
            this.keyDatesService.bankStatementReceived(this.caseId).subscribe(response => {
              this.correspondenceTimelineService.refreshCaseTimeline.next();
              this.keyDatesService.refreshKeyDates.next();
              this.dialogRef.close(true);

            });
          }
          else 
            this.dialogRef.close(true);
        });

      }
      else if(this.keyDateTypeId == 13 ) // Offer Received
      {
        var feeAgreementReceivedDialogRef = this.dialog.open(BasicPopupYesNoComponent, {
          data: { messageHeader: 'Has CTP/Broker Fee Agreement been received?', popupText1: '', popupText2: ''}
        });

        feeAgreementReceivedDialogRef.afterClosed().subscribe( (result : any) => {

          if(!result)
          {
            this.keyDatesService.feeAgreementNotReceived(this.caseId).subscribe(response => {
              this.dialogRef.close(true);

            });
          }
          else 
            this.dialogRef.close(true);
        });
      }
      else
      {
        this.dialogRef.close(true);

        if(this.keyDateTypeId == 45 ){ /* Key Date - ‘Initial Documents been sent to client’  */

          var mortgageCheck = this.dialog.open(BasicPopupOkComponent, {
            data: { messageHeader: 'Mortgage Details', popupText1: 'Please check the mortgage details have been updated on the case', popupText2: ''}
          });
        }

      }

      this.loading = false;

      }, (error : any) => {

        var errorMessage = "Adding Key Date Failed";
        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }

      });
  }

}
