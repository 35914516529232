import { Component, OnInit } from '@angular/core';
//import * as moment from 'moment';
// import { MediaObserver } from '@angular/flex-layout';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent  {

  mode = 'month';

  monthViewEnabled = true;
  currentDate: Date;
  isMobile = false;

  constructor(/*mediaObserver: MediaObserver*/) {
    this.currentDate = DateTime.local().toJSDate();
    /*this.isMobile = mediaObserver.isActive('xs');*/
  }


}
