import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  emailAddress?: string;
  sendingReset: boolean = false;

  constructor(private userService: UserService, public router: Router, private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  resetPassword() {

    this.sendingReset = true;
    this.userService.resetPassword({ emailAddress: this.emailAddress }).subscribe(() => {

      this.openSnackBar("Password Reset Email Sent", 'Close');
      this.router.navigateByUrl('/login');

      this.sendingReset = false;

    }, (error: any) => {

      var errorMessage = "Password Reset Failed";
      if (error.error !== null && error.error.message !== undefined) {
        errorMessage = error.error.message;
      }

      this.sendingReset = false;
      this.openSnackBar(errorMessage, 'Close');

    });
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
