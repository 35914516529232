import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { KlyantService } from '../shared/services/klyant.service';
import { KlyantUpdate } from '../shared/services/interfaces/klyant-update'

@Component({
  selector: 'app-klyant',
  templateUrl: './klyant.component.html',
  styleUrls: ['./klyant.component.css']
})
export class KlyantComponent implements OnInit {  

  caseId: number = 0;
  klyantUpdate: KlyantUpdate = <KlyantUpdate>{};  
  existingClientId?: number = 0;
  existingMatterId?: number = 0;
  existingClients: any = [];
  isLoaded: boolean = false;
  isError: boolean = false;
  errorMessage: string = '';
  gettingCodeFromKlyant: boolean = false;

  addingToKlyant: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<KlyantComponent>,
    private router: Router, 
    activatedRoute: ActivatedRoute, 
    private klyantService: KlyantService) {

      activatedRoute.params.subscribe(params => {
        if(params['code']) {
          this.gettingCodeFromKlyant = true;
          this.getTokenFromUrlCode(params['code']);
        }
        if(params['error']) {
          this.gettingCodeFromKlyant = true;
          this.isError = true;
          this.errorMessage = params['error'];          
        }
      });

      if (!this.gettingCodeFromKlyant) {
        this.caseId = this.data['caseId'];
        this.existingClientId = this.data['klyantClientId'];
        this.existingMatterId = this.data['klyantMatterId'];
        this.getClientList();
      }
  }

  closeWindow() {
    window.close();
  }

  // Invoked on a callback after logging into Klyant
  getTokenFromUrlCode(code:any) {
    if (code != null) {
      this.klyantService.setTokenAfterCallback(code).subscribe(() => {
        this.closeWindow();
      });      
    }
  }

  getClientList() {
    this.klyantService.getClients().subscribe(response => {
      var clients = JSON.parse(response.clients);
      this.existingClients = clients.data;
      this.isLoaded = true;
      this.isError = false;
    }, (error : any) => {
      console.log("ERROR calling getClientList");
      this.isError = true;
      this.errorMessage = error.error;
    });
  }

  addClientAndMatter() {   
    this.addingToKlyant = true; 
    this.klyantUpdate.caseId = this.caseId;
    this.klyantService.addClientAndMatter(this.klyantUpdate).subscribe(response => {      
      this.isError = false;
      this.errorMessage = '';            
      this.dialogRef.close(true);
    }, (error : any) => {
      console.log("ERROR calling addClientAndMatter");
      this.isError = true;
      this.errorMessage = error.error;
      this.addingToKlyant = false;
    });
  }

  updateClientAndMatter() {
    if (this.existingClientId) {
      this.klyantUpdate.caseId = this.caseId;
      this.klyantUpdate.clientId = this.existingClientId;
      this.klyantUpdate.matterId = this.existingMatterId;
      this.klyantService.updateClientAndMatter(this.klyantUpdate).subscribe(response => {
        this.isError = false;
        this.errorMessage = '';        
        this.dialogRef.close();
      }, (error : any) => {
        console.log("ERROR calling updateClientAndMatter");
        this.isError = true;
        this.errorMessage = error.error;
      });
    }
  }

  ngOnInit(): void {
  }
 
}
