import { Component, Input, OnInit } from '@angular/core';
import { faCoffee, faEllipsisH, faPaperclip, faEnvelopeOpen, faEnvelopeOpenText, faPhone, faFax, faSms, faFile,
  faFilePdf, faStickyNote, faCalendarDay, faPause, faPlay } from '@fortawesome/pro-light-svg-icons';
import { faArrowAltLeft, faArrowAltRight, faExclamation } from '@fortawesome/pro-solid-svg-icons';
import { StringToColour } from 'src/app/shared/utility/string-to-colour';
import { FileTypes } from '../../shared/constants/constants';
import { CaseFilesService } from 'src/app/shared/services/casefiles.service';

@Component({
  selector: 'app-timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline-item.component.css']
})
export class TimelineItemComponent implements OnInit {

  faCoffee = faCoffee;
  faPaperclip = faPaperclip;
  faArrowAltRight = faArrowAltRight;
  faArrowAltLeft = faArrowAltLeft;
  faEllipsisH = faEllipsisH;
  faEnvelopeOpen = faEnvelopeOpen;
  faEnvelopeOpenText = faEnvelopeOpenText;
  faPhone = faPhone;
  faFax = faFax;
  faSms = faSms;
  faFile = faFile;
  faFilePdf = faFilePdf;
  faStickyNote= faStickyNote;
  faCalendarDay = faCalendarDay;
  faPause = faPause;
  faPlay = faPlay;
  faExclamation = faExclamation;

  @Input() details: any;
  @Input() selectable: boolean = false;
  @Input() selected: boolean = false;


  constructor(private caseFilesService: CaseFilesService) {
  }

  ngOnInit() {
  }

  stringToColour(str: string): string {

    if(str)
      return StringToColour.colourise(str);
    else
      return 'transparent';

  }

  getColourForUser(user: any) : string{

    if(user.colour !== null)
      return '#' + user.colour;
    else
      return StringToColour.colourise(user.firstName + user.lastName);

  }

  openItem(event: any){
    event.preventDefault();
    event.stopPropagation();
    alert('hello');
  }

  openAttachment(caseFileEmailAttachmentId: number) {
    this.caseFilesService.getEmailAttachment(caseFileEmailAttachmentId).subscribe(response => {
      var blob = response.body;
      if(blob != null) {
        // For PDFs and images
        const fileURL = URL.createObjectURL(blob);
        //const fileURL = URL.createObjectURL(response.body);
        window.open(fileURL, '_blank');
      }
    });
  }

  getFileTypeIcon(format: any){

    if(!format) format = 0;

    var ft = FileTypes.find(ft => ft.id === format);
    if(!ft)
      return faFile;
    else
      return ft.icon;
  }
}
