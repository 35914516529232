import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseService } from '../../../../shared/services/case.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-add-case-overview-item',
  templateUrl: './add-case-overview-item.component.html',
  styleUrls: ['./add-case-overview-item.component.css']
})
export class AddCaseOverviewItemComponent implements OnInit {

  caseId: number;
  isLoading: boolean = false;

  form = new FormGroup({
    text: new FormControl()
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
            public dialogRef: MatDialogRef<AddCaseOverviewItemComponent>,
            private caseService: CaseService) {

              this.caseId = this.data.caseId;
  }

  ngOnInit(): void {
  }

  addItem(){

    this.isLoading = true;

    this.caseService.addCaseOverviewItem(this.caseId, this.form.value).subscribe((response : any) => {

      this.dialogRef.close(true);

      this.isLoading = false;

      }, (error : any) => {

        var errorMessage = "Failed to add item";
        /*
        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }*/

      });
  }

  cancel(){
    this.dialogRef.close(null);
  }

}
