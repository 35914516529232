<h1>Put Case On Hold</h1>

<form autocomplete="off" [formGroup]="onHoldDetails">

    <mat-form-field>
      <textarea autosize matInput #onHoldReason placeholder="On Hold Reason" maxlength="200" formControlName="onHoldReason"></textarea>
      <mat-hint align="end">{{onHoldReason.value?.length || 0}}/200</mat-hint>
    </mat-form-field>

    <div class="buttons">
      <button *ngIf="!isLoading" mat-stroked-button [disabled]="false" color="warn" type="button" (click)="cancel()">Cancel</button>
      <button *ngIf="!isLoading" mat-stroked-button [disabled]="onHoldReason.value?.length ==0" color="primary" (click)="putCaseOnHold()">OK</button>

      <button *ngIf="isLoading" mat-icon-button disabled color="primary" class="right" >
          <mat-icon>
              <mat-spinner color="primary" diameter="20">
              </mat-spinner>
          </mat-icon>
      </button>
    </div>

  </form>
