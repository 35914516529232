<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Invoices
  <button mat-icon-button tabindex="-1" color="primary" (click)="addInvoice()"><fa-icon [icon]="faPlus" ></fa-icon></button>
</h2>

<mat-dialog-content class="mat-typography">
    <div class="list">
        <div class="box">
          <div class="invoiceItem">
            <div class="invoice-type">Invoice Type</div>
            <div class="invoice-type">Invoice Number</div>
            <div class="invoice-cell">Created By</div>
            <div class="invoice-status">Status</div>
            <div class="actions">&nbsp;</div>
          </div>
        </div>
        <div class="box" *ngFor="let invoice of invoices; let i = index" [ngClass]="{'invoice-sent': invoice.klyantInvoiceId != null, 'invoice-ready': invoice.invoiceGeneratedDate != null && invoice.klyantInvoiceId == null}" >
            <div class="invoiceItem clickable" (dblclick)="openInvoice(invoice.id)">
              <div class="invoice-type">{{invoice.description}}</div>              
              <div class="invoice-type">{{invoice.invoiceNumber}}</div>
              <div class="invoice-cell">{{invoice.createdByUser.fullName}} </div>
              <div class="invoice-status"> 
                <span *ngIf="invoice.invoiceGeneratedDate == null">Invoice File not Generated</span>
                <span *ngIf="invoice.invoiceGeneratedDate != null && invoice.klyantInvoiceId == null">File Generated {{invoice.invoiceGeneratedDate | date:'dd/MM/yyyy HH:MM'}}</span>
                <span *ngIf="invoice.klyantInvoiceId != null">SENT TO KLYANT</span>
                &nbsp;
              </div>
              <div class="actions">
                  <fa-icon [icon]="faTimes" (click)="archiveInvoice(invoice.id, invoice.klyantInvoiceId)" class="clickable"></fa-icon>
                  &nbsp;&nbsp;&nbsp;
                  <!--fa-icon *ngIf="invoice.klyantInvoiceId != null" [icon]="faExternalLink" (click)="editInKlyant(invoice.klyantInvoiceId)" class="clickable"></fa-icon-->
                  <fa-icon *ngIf="invoice.klyantInvoiceId == null && invoice.invoiceNumber && !invoice.sendingToClient" [icon]="faShare" (click)="sendToKlyant(invoice)" class="clickable"></fa-icon>
              </div>
            </div>
        </div>
      </div>
</mat-dialog-content>
