<div>
    <div style="display: flex;  align-items: center;">
      <ng-content></ng-content>
      <div class="icon" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">
        <fa-icon [icon]="faFilter" *ngIf="!filterValue"></fa-icon>
        <fa-icon class="active" [icon]="fasFilter" *ngIf="filterValue"></fa-icon>
      </div>
    </div>
    <div *ngIf="filterName" class="active-value mat-elevation-z">
      <span>{{filterName}}</span>
      <fa-icon class="filter-clear" [icon]="faTimesCircle" (click)="clear()"></fa-icon>
    </div>
  </div>

  <div id="filter-panel">
    <mat-menu #menu="matMenu" [overlapTrigger]="false" class="mat-typography">
      <div (click)="$event.stopPropagation()" (keyup)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <form autocomplete="off" (ngSubmit)="onFilterSubmit()">
          <h3 *ngIf="title">{{title}}</h3>

          <mat-form-field class="example-full-width" *ngIf="options && options.length != 0">
              <input type="text" placeholder="Assignee" aria-label="Assignee" matInput [formControl]="equals" [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

          <div class="buttons">
            <button mat-stroked-button [disabled]="false" color="warn" type="button" (click)="clear()">Clear</button>
            <button mat-stroked-button [disabled]="false" color="primary" type="submit">Apply</button>
          </div>

        </form>
      </div>
    </mat-menu>
  </div>
