import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SearchRequest } from './interfaces/search-request';
import { SearchResult } from './interfaces/search-result';
import { FileTypes } from '../constants/constants';
import { faFile } from '@fortawesome/pro-light-svg-icons';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class CaseFilesService {

  private url = environment.apiUrl + 'casefiles/';

  public refreshUnassignedCaseFiles  = new EventEmitter();
  public refreshUnassignedCaseFilesObservable = this.refreshUnassignedCaseFiles.asObservable();

  faFile = faFile;

  constructor(private http: HttpClient, public router: Router) {

  }


  getCaseFile(fileId: number){

    return this.http.get(this.url + fileId);

  }

  getFile(caseFileId: number){
    return this.http.get(this.url + caseFileId + '/file', { observe: 'response', responseType: 'blob'});
  }

  getEmailAttachment(caseFileEmailAttachmentId: number){
    return this.http.get(this.url + 'emailattachment/' + caseFileEmailAttachmentId, { observe: 'response', responseType: 'blob'});
  }

  getThumbnail(caseFileId: number){

    return this.http.get(this.url + caseFileId + '/thumbnail', { observe: 'response', responseType: 'text'});

  }

  uploadFile(file: File, caseId: number, fileName: string, lastModified: string){

    let formData = new FormData();
    formData.append('files', file);
    formData.append('fileDate', lastModified.toString());
    formData.append('newFileName', fileName);
    formData.append('caseId', caseId.toString());

    return this.http.post(this.url, formData);

  }

  getUnassignedFiles(){
    return this.http.get<any>(this.url + 'unassigned/' );
  }

  assignFileToCase(caseFileId: number, caseId: number, newFileName: string){

    let formData = new FormData();
    formData.append('caseFileId', caseFileId.toString());
    formData.append('caseId', caseId.toString());
    formData.append('newFileName', newFileName);

    return this.http.post(this.url + 'assign/', formData);
  }

  getFileTypeIcon(format: any){

    if(!format) format = 0;

    var ft = FileTypes.find(ft => ft.id === format);
    if(!ft)
      return faFile;
    else
      return ft.icon;
  }
}
