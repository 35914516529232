<h1>Transfer Notification</h1>

<form autocomplete="off">

    <mat-form-field class="example-full-width" *ngIf="users && users.length != 0">
        <input type="text" placeholder="User" aria-label="User" matInput [formControl]="userSearchInput"
          [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="userSelectionUpdated()">
          <mat-option *ngFor="let option of filteredUsers | async" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div class="buttons">
        <button *ngIf="!isLoading" mat-stroked-button [disabled]="false" color="warn" type="button" (click)="cancel()">Cancel</button>
        <button *ngIf="!isLoading" mat-stroked-button [disabled]="invalidSelection" color="primary" (click)="transferNotifications()">OK</button>

        <button *ngIf="isLoading" mat-icon-button disabled color="primary" class="right" >
            <mat-icon>
                <mat-spinner color="primary" diameter="20">
                </mat-spinner>
            </mat-icon>
        </button>
      </div>

  </form>
