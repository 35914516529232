import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map, debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { SearchService } from '../../services/search.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchBarComponent implements OnInit {

  @Input() searchType?: string; // Cases/InternalUsers/ExternalUsers/CompanyGroups/Consultants
  @Input() caseStatusType: number = 0;
  @Input() inline?: boolean;


  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions?: Observable<string[]>;

  filteredItems: any[] | undefined = [];
  form = this.fb.group({
    search: null
  })
  isLoading = false;

  searchTypeID: number = 1;


  constructor(private fb: FormBuilder, private searchService: SearchService,
    public router: Router) {

  }

  ngOnInit() {


    var search = this.form.get('search');

    if (!search) return;

    if (this.searchType == "Cases") {


      if (this.caseStatusType > 0) {
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );



          search.valueChanges
          .pipe(
            debounceTime(300),
            tap(() => this.isLoading = true),
            switchMap(value => this.searchService.searchByCaseStatusType(this.caseStatusType, value)
              .pipe(
                finalize<any>(() => this.isLoading = false),
              )
            )
          )
          .subscribe((cases: any[]) => { this.filteredItems = cases; });
      }
      else {

        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );

          search.valueChanges
          .pipe(
            debounceTime(300),
            tap(() => this.isLoading = true),
            switchMap(value => this.searchService.searchCases(value)
              .pipe(
                finalize(() => this.isLoading = false),
              )
            )
          )
          .subscribe((cases: any[]) => { this.filteredItems = cases; });
      }

      this.searchTypeID = 1;

    }
    else if (this.searchType == "InternalUsers") {
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );

      search.valueChanges
        .pipe(
          debounceTime(300),
          tap(() => this.isLoading = true),
          switchMap(value => this.searchService.searchInternalUsers(value)
            .pipe(
              finalize(() => this.isLoading = false),
            )
          )
        )
        .subscribe((users: any[]) => { this.filteredItems = users; });

      this.searchTypeID = 2;
    }
    else if (this.searchType == "ExternalUsers") {
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );

      search.valueChanges
        .pipe(
          debounceTime(300),
          tap(() => this.isLoading = true),
          switchMap(value => this.searchService.searchExternalUsers(value)
            .pipe(
              finalize(() => this.isLoading = false),
            )
          )
        )
        .subscribe((users: any[]) => { this.filteredItems = users; });

      this.searchTypeID = 3;
    }
    else if (this.searchType == "CompanyGroups") {
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );

        search.valueChanges
        .pipe(
          debounceTime(300),
          tap(() => this.isLoading = true),
          switchMap(value => this.searchService.searchCompanyGroups(value)
            .pipe(
              finalize(() => this.isLoading = false),
            )
          )
        )
        .subscribe((companies: any[]) => { this.filteredItems = companies; });

      this.searchTypeID = 4;
    }
    else if (this.searchType == "Consultants") {
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );

      search.valueChanges
        .pipe(
          debounceTime(300),
          tap(() => this.isLoading = true),
          switchMap(value => this.searchService.searchConsultants(value)
            .pipe(
              finalize<any>(() => this.isLoading = false),
            )
          )
        )
        .subscribe(consultants => { this.filteredItems = consultants; });

      this.searchTypeID = 5;
    }

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  displayFn(user: any) {
    if (user) { return user.name; }
  }

  gotoResult(itemId:number){

    this.form.controls["search"].setValue("");
    this.options = [];

    if (this.searchType == "Cases") {
      this.router.navigateByUrl('/case/' + itemId);
    }
    else if (this.searchType == "InternalUsers" || this.searchType == "ExternalUsers") {
      this.router.navigateByUrl('/user/' + itemId + '/admin');
    }
    else if (this.searchType == "CompanyGroups") {
      this.router.navigateByUrl('/companygroup/' + itemId+ '/admin');
    }
    else if (this.searchType == "Consultants") {
      this.router.navigateByUrl('/consultants/' + itemId+ '/admin');
    }
  }


}
