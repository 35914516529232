<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Pre-Exchange Checklist</h2>

<h3 class="error" *ngIf="error">{{errorMessage}}</h3>

<mat-dialog-content *ngIf="!error">

    <div class="list" *ngIf="items.length > 0">
        <div class="box" >
            <span class="itemText heading">Check</span>
            <span class="itemResponse"></span>
            <span class="commentary heading">Commentary</span>
            <span class="whoAndWhen heading">Completed by / When</span>
            <span class="clearResponse"></span>
        </div>
        <div class="box" *ngFor="let item of items">
          <span class="itemText" [innerHTML]="item.preExchangeChecklistItem.itemText"></span>
          <span class="itemResponse">
              <mat-button-toggle-group aria-label="Font Style" [(ngModel)]="item.checklistResponseType" (change)="updateValue(item)">
                  <mat-button-toggle [value]="1">Yes</mat-button-toggle>
                  <mat-button-toggle [value]="2">No</mat-button-toggle>
                  <mat-button-toggle [value]="3">N/A</mat-button-toggle>
                </mat-button-toggle-group>

          </span>
          <span class="commentary">
              <textarea class="commentaryText" cdkTextareaAutosize style="resize: none;" matInput [(ngModel)]="item.commentary" maxlength="500" (change)="updateCommentary(item)"></textarea>
          </span>
          <span class="whoAndWhen">
              <span *ngIf="item.byUser">{{item.byUser.firstName.charAt(0)}}{{item.byUser.lastName.charAt(0)}} <br> {{item.dateEntered  | date:'dd/MM/yyyy HH:mm:ss'}}</span>
          </span>
          <span class="clearResponse"><button mat-icon-button (click)="clearValue(item)" *ngIf="item.checklistResponseType">x</button></span>
        </div>
      </div>

</mat-dialog-content>


 <div mat-dialog-actions align="end" *ngIf="!error">
  <button mat-stroked-button color="primary"  cdkFocusInitial (click)="generatePDF()">Generate PDF</button>
</div>

