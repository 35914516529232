
<div *ngIf="simpleCardForm">
  <app-card-label label="{{simpleCardLabel}}">
   
    <mat-form-field>
      <input [(ngModel)]="newDateValue" matInput [matDatepicker]="picker" (dateChange)="onDateChange()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>

    </mat-form-field>
  </app-card-label>

</div>
<div *ngIf="!simpleCardForm" class="date-picker-content">
  <h1>Add Date</h1>

  <div>
    <mat-form-field>
      <mat-label>Choose a date</mat-label>
      <input [(ngModel)]="newDateValue" matInput [matDatepicker]="picker">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

  </div>


  <div class="buttons">
    <button class="ms-2" mat-stroked-button (click)="clearDate()" *ngIf="newDateValue!=null && canClear">Clear</button>
    <button class="ms-2" mat-stroked-button [disabled]="false" color="warn" type="button" (click)="cancel()">Cancel</button>
    <button class="ms-2" mat-stroked-button color="primary" (click)="saveKeyDate()">Save</button>

  </div>
</div>


