import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject, interval, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const helper = new JwtHelperService();


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public errorMessage?: string;
  public loggingIn = false;
  private url = environment.apiUrl + 'auth/';
  public accessToken?: string;
  public refreshToken?: string;
  public refreshTokenExpiry: any;
  private refreshSubscription?: Subscription;
  refreshInProgress = false;

  public userID?: string;
  public firstName?: string;
  public lastName?: string;
  public role?: number;
  public isTeamLeader: boolean = false;
  public userCanAllocateCases: boolean = false;
  public userCanReallocateCases: boolean = false;
  public userCanSeeAdminActions: boolean = false;

  isLoginSubject = new BehaviorSubject<boolean | undefined>(undefined);
  isLogin = this.isLoginSubject.asObservable();
  isLoggedIn?: boolean = false;

  timer: any;

  constructor(private http: HttpClient,
    public router: Router,
    matDialog: MatDialog) {

    this.getData();
    this.isAuthenticated().then((result) => {
      if (result) {
        this.startRefresh()
      }
    });


    router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      tap(() => matDialog.closeAll())
    ).subscribe();


  }

  async isAuthenticated(): Promise<boolean | undefined> {

    if (!this.accessToken) {
      this.getData();
    }

    if (!this.accessToken || !this.refreshToken === null || !this.refreshTokenExpiry) {
      return false;
    }

    if (this.isLoggedIn == null || !this.isLoggedIn) {

      await this.refreshAccessToken2()

      this.isLoggedIn = this.accessToken != undefined && Date.now() <= this.refreshTokenExpiry;
      this.isLoginSubject.next(this.isLoggedIn);
    }

    return this.isLoggedIn;
  }

  login2(userDetails: any) {

    this.errorMessage = '';
    this.loggingIn = true;

    return this.http.post<any>(this.url + 'P2Login', userDetails, httpOptions)
      .pipe(
        catchError(error => {
          this.loggingIn = false;

          this.errorMessage = "Login Failed";
          if (error.error !== null && error.error.message !== undefined) {
            this.errorMessage = error.error.message;
          }

          this.isLoginSubject.next(false);

          return throwError(error, undefined);
        }),
        map(response => {
          this.setData(response, false);
          this.startRefresh();
          this.router.navigateByUrl('/');
          this.loggingIn = false;
        })
      )
  }

  logout() {
    this.clearData();
    this.router.navigateByUrl("/login");
  }

  getToken(): string | undefined {
    return this.accessToken;
  }

  setData(authResult: any, refresh: boolean) {

    if (!authResult && refresh) return;

    this.accessToken = authResult.token;
    this.refreshToken = authResult.refreshToken;
    this.firstName = authResult.firstName;
    this.lastName = authResult.lastName;
    this.role = authResult.role;
    this.isTeamLeader = authResult.isTeamLeader;
    this.userCanAllocateCases = authResult.canAllocateCases;
    this.userCanReallocateCases = authResult.canReallocateCases;
    this.userCanSeeAdminActions = authResult.canSeeAdminActions;

    this.refreshTokenExpiry = new Date(authResult.expires).getTime();

    localStorage.setItem('access_token', JSON.stringify(this.accessToken));
    localStorage.setItem('refresh_token', JSON.stringify(this.refreshToken));
    localStorage.setItem('refresh_token_expiry', JSON.stringify(this.refreshTokenExpiry));

    if (!refresh) {
      localStorage.setItem('first_name', JSON.stringify(this.firstName));
      localStorage.setItem('last_name', JSON.stringify(this.lastName));
      localStorage.setItem('role', JSON.stringify(this.role));
      localStorage.setItem('is_team_leader', JSON.stringify(this.isTeamLeader));
      localStorage.setItem('override_can_allocate_cases', JSON.stringify(this.userCanAllocateCases));
      localStorage.setItem('override_can_reallocate_cases', JSON.stringify(this.userCanReallocateCases));
      localStorage.setItem('override_can_see_admin_actions', JSON.stringify(this.userCanSeeAdminActions));
    }

    this.isLoginSubject.next(true);
  }

  getData() {

    this.accessToken = this.getFromStorage('access_token');
    this.refreshToken = this.getFromStorage('refresh_token');
    //console.log('Getting refresh token from storage: ', this.refreshToken)
    this.refreshTokenExpiry = this.getFromStorage('refresh_token_expiry');
    this.userID = this.getFromStorage('user_id');
  }

  clearData() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expiry');
    localStorage.removeItem('user_id');
    localStorage.removeItem('first_name');
    localStorage.removeItem('last_name');
    //localStorage.setItem('role', null);

    // clear down any stored search filters
    localStorage.removeItem('instructedCaseList');
    localStorage.removeItem('onHoldCaseList');
    localStorage.removeItem('completedCaseList');
    localStorage.removeItem('cancelledCaseList');
    localStorage.removeItem('uninstructedCaseList');

    localStorage.removeItem('adminTasksList');
    localStorage.removeItem('managementTasksList');
    localStorage.removeItem('paralegalTasksList');
    localStorage.removeItem('selectedTaskTab');

    this.accessToken = '';
    this.refreshToken = '';
    this.refreshTokenExpiry = '';
    this.isLoginSubject.next(false);
  }

  /*public unscheduleTokenRefresh() {
    if (!this.refreshSubscription) return;
    this.refreshSubscription.unsubscribe();
  }*/

  refreshAccessToken2() {

    this.getData();

    var refreshTokenData = {
      "Token": this.accessToken,
      "RefreshToken": this.refreshToken
    };

    //console.log('Refreshing with token(2): ', this.refreshToken)

    return this.http.post<any>(this.url + 'refresh', refreshTokenData, httpOptions).pipe(
      map(response => {
        this.setData(response, true);
        return response;
      })
    );
  }

  startRefresh() {
    this.refreshSubscription = interval(3000000)//interval(300000)
      .subscribe(() => {
        if (!this.refreshInProgress) {
          this.refreshInProgress = true;

          this.refreshAccessToken2().subscribe(() => {
            this.refreshInProgress = false;
          }, error => {
            this.refreshInProgress = false;
            this.stopRefresh();
            this.logout();
          });
        }
      });
  }

  stopRefresh() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }


  getName(): string | undefined {

    this.firstName = localStorage.hasOwnProperty('first_name') ? JSON.parse(localStorage.getItem('first_name') || '') : '';

    this.lastName = localStorage.hasOwnProperty('last_name') ? JSON.parse(localStorage.getItem('last_name') || '') : '';

    if (this.firstName != '') {
      if (this.lastName != '') {
        return `${this.firstName} ${this.lastName}`;
      }
      return this.firstName
    }
    return '';
  }

  getFromStorage(key: string): any | undefined {
    var value = localStorage.getItem(key);

    if (value == null) return undefined;

    return JSON.parse(value);

  }

  getBoolFromLocalStorage(key: string): boolean {
    let result = JSON.parse(localStorage.getItem(key) || '');

    if (result == true || result == 'true') {
      return true;
    }
    return false;
  }

  getNumberFromLocalStorage(key: string): number | undefined {
    let result = JSON.parse(localStorage.getItem(key) || '');

    if (isNaN(result)) {
      return undefined;
    }

    return parseInt(result);
  }


  getRole(): number | undefined {

    this.role = JSON.parse(localStorage.getItem('role') || '');

    return this.role;
  }

  getIsTeamLeader(): boolean {

    this.isTeamLeader = this.getBoolFromLocalStorage('is_team_leader');

    return this.isTeamLeader;
  }

  getUserCanAllocateCases(): boolean {

    this.userCanAllocateCases = this.getBoolFromLocalStorage('override_can_allocate_cases');

    return this.userCanAllocateCases;
  }

  getUserCanReallocateCases(): boolean {

    this.userCanReallocateCases = this.getBoolFromLocalStorage('override_can_reallocate_cases');

    return this.userCanReallocateCases;
  }

  getUserCanSeeAdminActions(): boolean {

    this.userCanSeeAdminActions = this.getBoolFromLocalStorage('override_can_see_admin_actions');

    return this.userCanSeeAdminActions;
  }

  hasRole(name: string): boolean {

    if (!this.accessToken) return false;

    var token = helper.decodeToken(this.accessToken);

    if (token.hasOwnProperty(name)) {
      return token[name] == '1' ? true : false;
    }

    return false;
  }

  hasAnyRoleId(ids: number[]): boolean {

    this.role = this.getNumberFromLocalStorage('role');

    for (const id of ids) {
      if (this.role == id) return true;
    }

    return false;
  }

  hasRoleId(id: number): boolean {

    this.role = this.getNumberFromLocalStorage('role');
    return (this.role == id);
  }

  isFA(): boolean {
    this.role = this.getNumberFromLocalStorage('role');
    return (this.role == 1);
  }

  isFAManager(): boolean {
    this.role = this.getNumberFromLocalStorage('role');
    return (this.role == 2);
  }

  isFAAdmin(): boolean {
    this.role = this.getNumberFromLocalStorage('role');
    return (this.role == 3);
  }

  isPortalAdmin(): boolean {
    this.role = this.getNumberFromLocalStorage('role');
    return (this.role == 4);
  }

  isPortalAdminTL(): boolean {
    this.role = this.getNumberFromLocalStorage('role');
    return (this.role == 4 && this.getIsTeamLeader());
  }

  isPortalParaLegal(): boolean {
    this.role = this.getNumberFromLocalStorage('role');
    return (this.role == 5);
  }

  isPortalParaLegalTL(): boolean {
    this.role = this.getNumberFromLocalStorage('role');
    return (this.role == 5 && this.getIsTeamLeader());
  }

  isPortalManager(): boolean {
    this.role = this.getNumberFromLocalStorage('role');
    return (this.role == 6);
  }

  isALStaff(): boolean {
    this.role = this.getNumberFromLocalStorage('role');
    return (this.role == 4 || this.role == 5 || this.role == 6);
  }

  currentUserID(): number {

    if (!this.accessToken) return -1;

    var token = helper.decodeToken(this.accessToken);

    if (token.hasOwnProperty('unique_name')) {
      return token['unique_name'];
    }

    return -1
  }

  canAddNewCases() {
    return this.isFA() || this.isFAAdmin() || this.isFAManager() || this.isPortalAdmin() || this.isPortalAdminTL();
  }

  canSeeAdminActionList() {
    return this.isPortalManager() || this.isPortalAdmin() || this.isPortalAdminTL() || this.overrideCanSeeAdminActionList();
  }

  canSeeParalegalActionList() {
    return this.isPortalManager() || this.isPortalParaLegal() || this.isPortalParaLegalTL();
  }

  overrideCanSeeAdminActionList() {
    return this.isPortalParaLegalTL() && this.getUserCanSeeAdminActions();
  }

  canSeeInstructedCases() {
    return true; // All users
  }

  canSeeUnallocatedCases() {
    return this.isPortalAdminTL() || this.isPortalManager() || (this.isPortalParaLegalTL() && this.getUserCanAllocateCases());
  }

  canAllocateCases() {
    return this.isPortalAdminTL() || this.isPortalManager() || (this.isPortalParaLegalTL() && this.getUserCanAllocateCases());
  }

  canReallocateCases() {
    return this.isPortalAdminTL() || this.isPortalManager() || (this.isPortalParaLegalTL() && this.getUserCanReallocateCases());
  }

  canReallocateTasks() {
    return this.isPortalAdminTL() || this.isPortalManager() || (this.isPortalParaLegalTL() && this.getUserCanReallocateCases());
  }

  canSeeCancelledCases() {
    //return this.isPortalAdmin();
    return true;
  }

  canSeeCompletedCases() {
    //return this.isPortalAdmin() || this.isPortalManager();
    return true;
  }

  canSeeUninstructedCases() {
    // Only should be seen by those that can create cases or have the ability to archive them
    return !this.isPortalParaLegal() && !this.isPortalParaLegalTL();
  }

  canAdminUsersAndGroups() {
    return this.isPortalAdmin() || this.isPortalAdminTL() || this.isPortalManager();
  }

  canAdminInternalUsers() {
    return this.isPortalManager() || this.isPortalAdminTL();
  }

  canAdminConsultants() {
    return this.isPortalAdminTL() || this.isPortalManager();
  }

  canDeleteFiles() {
    return this.isPortalAdminTL() || this.isPortalParaLegalTL() || this.isPortalManager();
  }

  canAdminLenders() {
    return  this.isPortalAdminTL() || this.isPortalManager();
  }

}
