<div class="wrapper" [ngClass]="{'email': details.correspondenceTypeId == 1,
'letter': details.correspondenceTypeId == 2,
'casefile': details.correspondenceTypeId == 3 || details.correspondenceTypeId == 7,
'phonecall': details.correspondenceTypeId == 4,
'textmessage': details.correspondenceTypeId == 5,
'fax': details.correspondenceTypeId == 6,
'note': details.correspondenceTypeId == 8,
'keydate': details.correspondenceTypeId == 9,
'onhold': details.correspondenceTypeId == 10,
'offhold': details.correspondenceTypeId == 11,
'highlighted': details.highlighted}">
  <div class="col-0" *ngIf="selectable">
    <mat-checkbox class="example-margin" [(ngModel)]="selected"></mat-checkbox>
  </div>
  <div class="col-1"  >
    <div class="body">


      <div class="icon" >
        <fa-layers [fixedWidth]="true">
          <fa-icon *ngIf="details.correspondenceTypeId == 1"  [icon]="faEnvelopeOpen" size="lg"></fa-icon>
          <fa-icon *ngIf="details.correspondenceTypeId == 1 && details.emailItem.attachments.length > 0" [icon]="faPaperclip" transform="right-20 down-2"></fa-icon>
          <fa-icon *ngIf="details.correspondenceTypeId == 1 && details.emailItem.isHighImportance" [icon]="faExclamation" transform="left-18" class="high-importance" size="lg"></fa-icon>
          <fa-icon *ngIf="details.correspondenceTypeId == 2" [icon]="faEnvelopeOpenText" size="lg"></fa-icon>
          <fa-icon *ngIf="details.correspondenceTypeId == 3 || details.correspondenceTypeId == 7" [icon]="getFileTypeIcon(details.caseFile?.format)" size="lg"></fa-icon>
          <fa-icon *ngIf="details.correspondenceTypeId == 4" [icon]="faPhone" size="lg"></fa-icon>
          <fa-icon *ngIf="details.correspondenceTypeId == 5" [icon]="faSms" size="lg"></fa-icon>
          <fa-icon *ngIf="details.correspondenceTypeId == 6" [icon]="faFax" size="lg"></fa-icon>
          <fa-icon *ngIf="details.correspondenceTypeId == 8" [icon]="faStickyNote" size="lg"></fa-icon>
          <fa-icon *ngIf="details.correspondenceTypeId == 9" [icon]="faCalendarDay" size="lg"></fa-icon>
          <fa-icon *ngIf="details.correspondenceTypeId == 10" [icon]="faPause" size="lg"></fa-icon>
          <fa-icon *ngIf="details.correspondenceTypeId == 11" [icon]="faPlay" size="lg"></fa-icon>
          <fa-icon *ngIf="details.correspondenceDirectionId == 1" class="outbound" [icon]="faArrowAltLeft" transform="left-12 down-4"></fa-icon>
          <fa-icon *ngIf="details.correspondenceDirectionId == 2" class="inbound" [icon]="faArrowAltRight" transform="left-12 up-4"></fa-icon>
        </fa-layers>
      </div>

      <div class="description">
        <div class="description-header">{{details.summary}}</div>
        <div class="description-sub">{{details.source}}</div>
      </div>


    </div>
    <div *ngIf="details.correspondenceTypeId == 1" > <!-- Email attachments -->
      <div class="attachment" *ngFor="let attachment of details.emailItem.attachments">
        <div class="angle"></div>
        <div class="attachment-icon">
          <fa-icon [icon]="faPaperclip"></fa-icon>
        </div>
        <div class="description">{{attachment.caseFile.fileName}}</div>
      </div>
    </div>

    <div *ngIf="details.correspondenceTypeId == 3 && details.caseFile.format == 3" > <!-- Case File - Type Email -->
      <div class="attachment" *ngFor="let attachment of details.caseFile.emailAttachments">
        <div class="angle"></div>
        <div class="attachment-icon">
          <fa-icon [icon]="faPaperclip"></fa-icon>
        </div>
        <div class="description" (click)="openAttachment(attachment.caseFileEmailAttachmentId)">{{attachment.fileName}} ({{attachment.fileSize / 1024 | number: '1.2-2'}}KB)</div>
      </div>
    </div>

    <div *ngIf="details.correspondenceTypeId == 6" > <!-- Fax attachments -->
      <div class="attachment" *ngFor="let attachment of details.faxItem.attachments">
        <div class="angle"></div>
        <div class="attachment-icon">
          <fa-icon [icon]="faPaperclip"></fa-icon>
        </div>
        <div class="description">{{attachment.caseFile.fileName}}</div>
      </div>
    </div>
  </div>
  <div class="col-2">
    <div>
      <!--div class="initials menu" mat-icon-button [matMenuTriggerFor]="menu"><fa-icon [icon]="faEllipsisH" size="lg"></fa-icon></div-->
      <div *ngIf="details.addedByUser" class="initials" [ngStyle]="{'background-color':getColourForUser(details.addedByUser)}">{{details.addedByUser.firstName.charAt(0)}}{{details.addedByUser.lastName.charAt(0)}}</div>
      <div *ngIf="!details.addedByUser" class="initials">SYS</div>
      <div class="date">{{details.dateCreated | date:'dd/MM/yyyy HH:mm:ss'}}</div>

    </div>
  </div>
</div>
