import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SearchRequest } from './interfaces/search-request';
import { SearchResult } from './interfaces/search-result';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class FaxService {

  private url = environment.apiUrl + 'fax/';

  constructor(private http: HttpClient, public router: Router) {

  }


  sendFax(caseId: number, fax: any){

    return this.http.put(this.url + caseId + '/sendfax/', fax, httpOptions);

  }




}
