<h1>Reallocate Tasks</h1>

<form autocomplete="off">


  <div *ngIf="taskType == 'paralegal'">
      <mat-form-field class="example-full-width" *ngIf="paralegalUsers && paralegalUsers.length != 0">
      <input type="text" placeholder="Paralegal" aria-label="Paralegal" matInput [formControl]="paralegalSearchInput"
        [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="paralegalSelectionUpdated()">
        <mat-option *ngFor="let option of filteredParalegalUsers | async" [value]="option">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="buttons">
        <button *ngIf="!isLoading" mat-stroked-button [disabled]="false" color="warn" type="button" (click)="cancel()">Cancel</button>
        <button *ngIf="!isLoading" mat-stroked-button [disabled]="invalidSelection" color="primary" (click)="reallocateParalegalTasks()">OK</button>

        <button *ngIf="isLoading" mat-icon-button disabled color="primary" class="right" >
            <mat-icon>
                <mat-spinner color="primary" diameter="20">
                </mat-spinner>
            </mat-icon>
        </button>
    </div>

  </div>

  <div *ngIf="taskType == 'admin'">
    <mat-form-field class="example-full-width" *ngIf="adminUsers && adminUsers.length != 0">
      <input type="text" placeholder="Admin" aria-label="Admin" matInput [formControl]="adminSearchInput"
        [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="adminSelectionUpdated()">
        <mat-option *ngFor="let option of filteredAdminUsers | async" [value]="option">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="buttons">
      <button *ngIf="!isLoading" mat-stroked-button [disabled]="false" color="warn" type="button" (click)="cancel()">Cancel</button>
      <button *ngIf="!isLoading" mat-stroked-button [disabled]="invalidSelection" color="primary" (click)="reallocateAdminTasks()">OK</button>

      <button *ngIf="isLoading" mat-icon-button disabled color="primary" class="right" >
          <mat-icon>
              <mat-spinner color="primary" diameter="20">
              </mat-spinner>
          </mat-icon>
      </button>
    </div>

  </div>


</form>
