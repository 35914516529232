<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>

<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Select Supplier</h2>

<div mat-dialog-content class="mat-typography">
    <app-card-label label="Supplier">
        <mat-form-field floatLabel="never">            
          <mat-select placeholder="Select Supplier" [(ngModel)]="supplierId">
            <mat-option *ngFor="let supplier of supplierList" [value]="supplier.id">{{supplier.name}}</mat-option>
          </mat-select> 
        </mat-form-field>
        </app-card-label>
</div>

<div mat-dialog-actions align="end" >
    <button mat-stroked-button (click)="setSupplier()" cdkFocusInitial color="primary" [disabled]="supplierId == null">Save</button>
</div>