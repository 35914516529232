
<mat-dialog-content class="edit-enquiry-dialog">
  <form [formGroup]="editEnquiry" autocomplete="off">

    <mat-form-field>
      <textarea matInput #enquiry placeholder="Enquiry" maxlength="300" formControlName="enquiry"></textarea>
      <mat-hint [align]="'end'">{{enquiry.value?.length || 0}}/300</mat-hint>
    </mat-form-field>

   
    <div class="buttons">
      <button mat-stroked-button [disabled]="false" color="warn" type="button" (click)="close()">Cancel</button>
      <button mat-stroked-button color="primary" (click)="save()">Save</button>
    </div>

  </form>

</mat-dialog-content>
