import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class CaseFundsService {

  private url = environment.apiUrl + 'casefunds/';

  constructor(private http: HttpClient, public router: Router) {



  }


  getCaseFunds(caseId: number){
    return this.http.get<any>(this.url + caseId );
  }


  addCaseFund(caseId: number,  caseFundDetails: any){

    let formDetails = {
      caseId : caseId,
      description: caseFundDetails.description,
      net: caseFundDetails.net
    };

    return this.http.put<any>(this.url + caseId, formDetails);
  }


  removeCaseFund(caseId: number, caseFundId: number){

    let formDetails = {
      caseId : caseId,
      caseFundId: caseFundId
    };

    return this.http.post<any>(this.url + caseId + '/remove/', formDetails);
  }

  emailParalegal(caseId: number){

    let formDetails = {
      caseId : caseId
    };

    return this.http.post<any>(this.url + 'email/', formDetails);
  }


}
