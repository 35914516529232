
<div class="body">

    <div class="sidebar">
      <app-dashboard-sidebar></app-dashboard-sidebar>
    </div>

    <div class="content">
        <h2>Scans</h2>
        <mat-list *ngFor="let file of files" >


            <mat-list-item (contextmenu)="onContextMenu($event, file)" (dblclick)="dblClickItem(file)" >

            <app-unassigned-file #fileitem [details]="file" [selectable]="allowMultiSelect"></app-unassigned-file>



              </mat-list-item>




  </mat-list>

  <div style="visibility: hidden; position: fixed"
            [style.left]="contextMenuPosition.x"
            [style.top]="contextMenuPosition.y"
            [matMenuTriggerFor]="contextMenu">
        </div>


        <mat-menu #contextMenu="matMenu" >
            <ng-template matMenuContent let-file="file">

               <span *ngIf="file">

                <button mat-menu-item (click)="openSendToCase()">
                <span>Send to Case</span>
                </button>

              </span>
            </ng-template>
        </mat-menu>

    </div>

  </div>

