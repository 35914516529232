import { Component, OnInit, Inject } from '@angular/core';
import { TransactionsService } from '../../../../shared/services/transactions-service';
import { Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { DateTime } from 'luxon';
import { MatSelectChange } from '@angular/material/select';
import { KlyantService } from 'src/app/shared/services/klyant.service';
import { CaseService } from 'src/app/shared/services/case.service';
import { environment } from 'src/environments/environment';
import { InvoiceService } from 'src/app/shared/services/invoice.service';

@Component({
  selector: 'app-add-transaction',
  templateUrl: './add-transaction.component.html',
  styleUrls: ['./add-transaction.component.scss']
})
export class AddTransactionComponent implements OnInit {

  caseId : number;
  caseTypeId : number;
  transactionTypeId : number = 0;
  case: any;
  invoice: any;

  officeAccountList: any[] = [];
  clientAccountList: any[] = [];
  transactionTypes: any[] = [];
  methodsOfVerification: any[] = [];
  descriptionTextOptions: any[] = [];
  selectedTransactionType: any = null;

  invoices: any[] = [];

  addingTransactions: boolean = false;
  selectedType: string = '';

  hasError: boolean = false;
  errorMessage: any[] = []; 

  defaultTransferToAccountId: number =0;
  chequeTransactionTypeId: number;

  newTransactionDetails = this.fb.group({
    type: [null, Validators.required],
    fromBankAccountId: [null],
    toBankAccountId: [null],
    fundsReceivedFrom: [null],
    fundsReceivedDate: [null],
    value: [null, Validators.required],
    narrative: ['', Validators.required],
    description: ['', Validators.required],
    transactionTypeId: [null],
    transactionTypeOther: [null],
    privateClientTypeId: [null],
    methodOfVerificationId: [null],
    thirdPartyFeeId: [null],
    payeeName: [null],
    transactionDateTime: [null],
    invoiceId: [null],
    chequeDetails: [null]
  }, { });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private transactionsService: TransactionsService,
              public dialogRef: MatDialogRef<AddTransactionComponent>,
              private klyantService: KlyantService,
              private caseService: CaseService,
              private invoiceService: InvoiceService) {
    this.caseId = data.caseId;
    this.caseTypeId = data.caseTypeId;

    this.caseService.getCase(this.caseId).subscribe((result) => {
      this.case = result;
      this.setDefaults();
    });

    /*this.invoiceService.getInvoice(this.caseId).subscribe((result) => {
      this.invoice = result;
    });*/

    this.klyantService.getOfficeAccounts().subscribe((results) => {
      this.officeAccountList = results;

      this.klyantService.getDefaultTransferToAccountId().subscribe((result:any) => {
        this.defaultTransferToAccountId = result;
      });

    }); 

    this.klyantService.getClientAccounts().subscribe((results) => {
      this.clientAccountList = results;
    }); 
    
    this.transactionsService.getTransactionTypes().subscribe((results:any) => {
      this.transactionTypes = results;
      this.setDefaults();
    });

    this.transactionsService.getMethodsOfVerification().subscribe((results:any) => {
      this.methodsOfVerification = results;      
      this.setDefaults();
    });

    this.invoiceService.getAllKlyantInvoices(this.caseId).subscribe((results : any) => {
      this.invoices = results.invoices;
      if(this.invoices.length == 1){
        this.invoice = this.invoices[0];
        this.newTransactionDetails.controls['invoiceId'].patchValue(this.invoices[0].id);
      }
    });

    this.klyantService.getChequeTransactionTypeId().subscribe((result:any) => {
      this.chequeTransactionTypeId = result;
    });

  }

  ngOnInit(): void {
  }

  hasKlyantInvoice() {

    if (this.invoices.length > 0){
      return true;
    }
    else {
      return false;
    }
  }

  addTransaction() {
    this.addingTransactions = true;
    this.transactionsService.addTransaction(this.caseId, this.newTransactionDetails.value).subscribe((response : any) => {      
      this.dialogRef.close(true);
      this.addingTransactions = false;
      this.hasError = false;
    }, (error: any) => {            
      this.addingTransactions = false;
      this.hasError = true;
      this.errorMessage = error.error;
    });
  }

  typeChange(event: MatRadioChange) {
    this.transactionTypeId = event.value;
    this.newTransactionDetails.value.type = event.value;

    if(this.transactionTypeId == 1)
      this.selectedType = "Monies In";
    else if(this.transactionTypeId == 2)
    this.selectedType = "Transfer";
    else if(this.transactionTypeId == 3)
      this.selectedType = "Monies Out";

    this.setDefaults();
  }

  showOtherTransactionType() {    
    let ttId = this.newTransactionDetails.value.transactionTypeId;
    let tt:any = this.transactionTypes.filter(x => x.transactionTypeId == ttId);    
    return tt[0] && tt[0].isOther;
  }

  fundsReceivedChange(event: MatSelectChange) { 
    this.newTransactionDetails.controls['fundsReceivedFrom'].patchValue(event.value);    
  }

  setDefaults() {

    let caseTypeId = this.caseTypeId;
    let typeId = this.newTransactionDetails.value.type;

    this.newTransactionDetails.controls['transactionTypeId'].patchValue(null);
    this.newTransactionDetails.controls['transactionTypeOther'].patchValue(null);
    
    // Set the default transaction type if we have one (if we have more than one for this case type, we'll end up with the last one)
    // this.transactionTypes.forEach((tt:any) => {      
    //   if (tt.transactionTypeDefaults && tt.transactionTypeDefaults.length > 0) {        
    //     if (tt.transactionTypeDefaults[0].caseTypeId == caseTypeId && tt.transactionTypeDefaults[0].transactionDirectionId == typeId) {
    //       this.newTransactionDetails.controls['transactionTypeId'].patchValue(tt.transactionTypeDefaults[0].transactionTypeId);
    //     }
    //   }
    // });

    this.newTransactionDetails.controls['methodOfVerificationId'].patchValue(environment.klyant.defaultMethodOfVerificationId);

    this.descriptionTextOptions = [];

    if(this.case) {
      this.newTransactionDetails.controls['narrative'].patchValue(this.case.caseData.caseReference);    
    }

    this.newTransactionDetails.controls['fundsReceivedFrom'].patchValue('');
    this.newTransactionDetails.controls['fundsReceivedDate'].patchValue(DateTime.local().toFormat('yyyy-MM-dd'));    

    // Default selectable text options for Narrative
    if (caseTypeId == 1) {
      if (typeId == 1) {
        this.descriptionTextOptions.push({text: 'Completion Funds', value: 'Completion Funds'})
        this.descriptionTextOptions.push({text: 'Shortfall Funds', value: 'Shortfall Funds'})
      }
    }
    else if (caseTypeId == 6) {
      if (typeId == 1) {        
        this.descriptionTextOptions.push({text: 'Completion Sale Funds', value: 'Completion Sale Funds'})
        this.descriptionTextOptions.push({text: 'Negative Equity', value: 'Negative Equity'})
      }
    }
    else if (caseTypeId == 8) {
      if (typeId == 1) {        
        this.descriptionTextOptions.push({text: 'Completion Purchase Funds', value: 'Completion Purchase Funds'})
        this.descriptionTextOptions.push({text: 'Negative Equity', value: 'Negative Equity'})
      }
    }

    // Default transactionDateTime to now
    this.newTransactionDetails.controls['transactionDateTime'].patchValue(DateTime.local().toFormat('yyyy-MM-dd'));

    if(typeId == 2) // Transfers must have an invoice selected
    {
      this.newTransactionDetails.controls['invoiceId'].setValidators([Validators.required]);
      this.newTransactionDetails.controls['toBankAccountId'].patchValue(this.defaultTransferToAccountId);
    }
    else
      this.newTransactionDetails.controls['invoiceId'].clearValidators();
  }

  showControl(controlName:string) {

    if (controlName == undefined) {
      return false;
    }

    if (this.newTransactionDetails.value.type == null) {
      return false;
    }

    let caseTypeId = this.caseTypeId;
    let typeId = this.newTransactionDetails.value.type;

    // if (caseTypeId != 1 && caseTypeId != 6 && caseTypeId != 8) { // Equity Release, Conv. Sale, Conv. Purchase
    //   return false;
    // }

    switch (controlName) {

      case 'fromBankAccountId':
        if (typeId == 1) // Monies In
          return false;
        else 
          return true;
        // if (caseTypeId == 1 && typeId == 1) return false; // Equity Release + Monies In
        // else if (caseTypeId == 1 && typeId == 2) return true; // Equity Release + Transfer
        // else if (caseTypeId == 1 && typeId == 3) return true; // Equity Release + Monies Out
        // else if (caseTypeId == 6 && typeId == 1) return false; // Conv. Sale + Monies In
        // else if (caseTypeId == 6 && typeId == 2) return true; // Conv. Sale + Transfer
        // else if (caseTypeId == 6 && typeId == 3) return true; // Conv. Sale + Monies Out
        // else if (caseTypeId == 8 && typeId == 1) return false; // Conv. Purchase + Monies In
        // else if (caseTypeId == 8 && typeId == 2) return true; // Conv. Purchase + Transfer
        // else if (caseTypeId == 8 && typeId == 3) return true; // Conv. Purchase + Monies Out
        // else return false;
      case 'toBankAccountId':
        if (typeId == 3) // Monies Out
          return false;
        else 
          return true;
        // if (caseTypeId == 1 && typeId == 1) return true; // Equity Release + Monies In
        // else if (caseTypeId == 1 && typeId == 2) return true; // Equity Release + Transfer
        // else if (caseTypeId == 1 && typeId == 3) return false; // Equity Release + Monies Out
        // else if (caseTypeId == 6 && typeId == 1) return true; // Conv. Sale + Monies In
        // else if (caseTypeId == 6 && typeId == 2) return true; // Conv. Sale + Transfer
        // else if (caseTypeId == 6 && typeId == 3) return false; // Conv. Sale + Monies Out
        // else if (caseTypeId == 8 && typeId == 1) return false; // Conv. Purchase + Monies In
        // else if (caseTypeId == 8 && typeId == 2) return true; // Conv. Purchase + Transfer
        // else if (caseTypeId == 8 && typeId == 3) return true; // Conv. Purchase + Monies Out
        // else return false;
      case 'transactionTypeId':
        if (typeId == 2) // Transfer (so we don't have BACS, Cheque, or whatever)
          return false;
        else 
          return true;
        // if (caseTypeId == 1 && typeId == 1) return true; // Equity Release + Monies In
        // else if (caseTypeId == 1 && typeId == 2) return false; // Equity Release + Transfer
        // else if (caseTypeId == 1 && typeId == 3) return true; // Equity Release + Monies Out
        // else if (caseTypeId == 6 && typeId == 1) return true; // Conv. Sale + Monies In
        // else if (caseTypeId == 6 && typeId == 2) return false; // Conv. Sale + Transfer
        // else if (caseTypeId == 6 && typeId == 3) return false; // Conv. Sale + Monies Out
        // else if (caseTypeId == 8 && typeId == 1) return true; // Conv. Purchase + Monies In
        // else if (caseTypeId == 8 && typeId == 2) return false; // Conv. Purchase + Transfer
        // else if (caseTypeId == 8 && typeId == 3) return false; // Conv. Purchase + Monies Out
        // else return false;
      case 'transactionTypeOther':
        if (typeId == 2) // Transfer (so we don't have BACS, Cheque, or whatever)
          return false;
        else 
          return true;
        // if (caseTypeId == 1 && typeId == 1) return true; // Equity Release + Monies In
        // else if (caseTypeId == 1 && typeId == 2) return false; // Equity Release + Transfer
        // else if (caseTypeId == 1 && typeId == 3) return true; // Equity Release + Monies Out
        // else if (caseTypeId == 6 && typeId == 1) return true; // Conv. Sale + Monies In
        // else if (caseTypeId == 6 && typeId == 2) return false; // Conv. Sale + Transfer
        // else if (caseTypeId == 6 && typeId == 3) return false; // Conv. Sale + Monies Out
        // else if (caseTypeId == 8 && typeId == 1) return true; // Conv. Purchase + Monies In
        // else if (caseTypeId == 8 && typeId == 2) return false; // Conv. Purchase + Transfer
        // else if (caseTypeId == 8 && typeId == 3) return false; // Conv. Purchase + Monies Out
        // else return false;
      case 'fundsReceivedFrom':
        if (typeId == 1) // Monies In
          return true;
        else 
          return false;
        // if (caseTypeId == 1 && typeId == 1) return true; // Equity Release + Monies In
        // else if (caseTypeId == 1 && typeId == 2) return false; // Equity Release + Transfer
        // else if (caseTypeId == 1 && typeId == 3) return false; // Equity Release + Monies Out
        // else if (caseTypeId == 6 && typeId == 1) return true; // Conv. Sale + Monies In
        // else if (caseTypeId == 6 && typeId == 2) return false; // Conv. Sale + Transfer
        // else if (caseTypeId == 6 && typeId == 3) return false; // Conv. Sale + Monies Out
        // else if (caseTypeId == 8 && typeId == 1) return true; // Conv. Purchase + Monies In
        // else if (caseTypeId == 8 && typeId == 2) return false; // Conv. Purchase + Transfer
        // else if (caseTypeId == 8 && typeId == 3) return false; // Conv. Purchase + Monies Out
        // else return false;
      case 'fundsReceivedDate':
        if (typeId == 1) // Monies In
          return true;
        else 
          return false;
        // if (caseTypeId == 1 && typeId == 1) return true; // Equity Release + Monies In
        // else if (caseTypeId == 1 && typeId == 2) return false; // Equity Release + Transfer
        // else if (caseTypeId == 1 && typeId == 3) return false; // Equity Release + Monies Out
        // else if (caseTypeId == 6 && typeId == 1) return true; // Conv. Sale + Monies In
        // else if (caseTypeId == 6 && typeId == 2) return false; // Conv. Sale + Transfer
        // else if (caseTypeId == 6 && typeId == 3) return false; // Conv. Sale + Monies Out
        // else if (caseTypeId == 8 && typeId == 1) return true; // Conv. Purchase + Monies In
        // else if (caseTypeId == 8 && typeId == 2) return false; // Conv. Purchase + Transfer
        // else if (caseTypeId == 8 && typeId == 3) return false; // Conv. Purchase + Monies Out
        // else return false;
      case 'value':
        return true;
      case 'payeeName':
        if (typeId == 3) // Monies Out
          return true;
        else 
          return false;
        // if (caseTypeId == 1 && typeId == 1) return false; // Equity Release + Monies In
        // else if (caseTypeId == 1 && typeId == 2) return false; // Equity Release + Transfer
        // else if (caseTypeId == 1 && typeId == 3) return true; // Equity Release + Monies Out
        // else if (caseTypeId == 6 && typeId == 1) return false; // Conv. Sale + Monies In
        // else if (caseTypeId == 6 && typeId == 2) return false; // Conv. Sale + Transfer
        // else if (caseTypeId == 6 && typeId == 3) return true; // Conv. Sale + Monies Out
        // else if (caseTypeId == 8 && typeId == 1) return false; // Conv. Purchase + Monies In
        // else if (caseTypeId == 8 && typeId == 2) return false; // Conv. Purchase + Transfer
        // else if (caseTypeId == 8 && typeId == 3) return true; // Conv. Purchase + Monies Out
        // else return false;
      case 'narrative':
        return true;
        // if (caseTypeId == 1 && typeId == 1) return true; // Equity Release + Monies In
        // else if (caseTypeId == 1 && typeId == 2) return true; // Equity Release + Transfer
        // else if (caseTypeId == 1 && typeId == 3) return true; // Equity Release + Monies Out
        // else if (caseTypeId == 6 && typeId == 1) return true; // Conv. Sale + Monies In
        // else if (caseTypeId == 6 && typeId == 2) return true; // Conv. Sale + Transfer
        // else if (caseTypeId == 6 && typeId == 3) return true; // Conv. Sale + Monies Out
        // else if (caseTypeId == 8 && typeId == 1) return true; // Conv. Purchase + Monies In
        // else if (caseTypeId == 8 && typeId == 2) return true; // Conv. Purchase + Transfer
        // else if (caseTypeId == 8 && typeId == 3) return true; // Conv. Purchase + Monies Out
        // else return false;
      case 'description':
        return true;
      case 'methodOfVerificationId':   
        if (typeId == 3) // Monies Out
          return true;
        else 
          return false;
        // if (caseTypeId == 1 && typeId == 1) return false; // Equity Release + Monies In
        // else if (caseTypeId == 1 && typeId == 2) return false; // Equity Release + Transfer
        // else if (caseTypeId == 1 && typeId == 3) return true; // Equity Release + Monies Out     
        // else if (caseTypeId == 6 && typeId == 1) return false; // Conv. Sale + Monies In
        // else if (caseTypeId == 6 && typeId == 2) return false; // Conv. Sale + Transfer
        // else if (caseTypeId == 6 && typeId == 3) return true; // Conv. Sale + Monies Out
        // else if (caseTypeId == 8 && typeId == 1) return false; // Conv. Purchase + Monies In
        // else if (caseTypeId == 8 && typeId == 2) return false; // Conv. Purchase + Transfer
        // else if (caseTypeId == 8 && typeId == 3) return true; // Conv. Purchase + Monies Out
        // else return false;
      case 'transactionDateTime':
        if (typeId == 1) // Monies In
          return false;
        else 
          return true;
        // if (caseTypeId == 1 && typeId == 1) return false; // Equity Release + Monies In
        // else if (caseTypeId == 1 && typeId == 2) return true; // Equity Release + Transfer
        // else if (caseTypeId == 1 && typeId == 3) return true; // Equity Release + Monies Out
        // else return false;  
      case 'invoiceId':
          if (typeId == 2) // Transfer
            return true;
          else 
            return false; 
      case 'chequeDetails':
        if (typeId == 2) // Transfer
          return false;
        else 
          return true; 
    }

    return false;
  }

  showChequeDetails() {    
    let ttId = this.newTransactionDetails.value.transactionTypeId;
    return ttId == this.chequeTransactionTypeId;
  }

}
