<button mat-icon-button class="close" tabindex="-1" *ngIf="!generatingLetter" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Ad Hoc Letter</h2>

<mat-dialog-content class="mat-typography" *ngIf="!generatingLetter">
    <app-card-label label="Letter Recipient">
        <mat-form-field floatLabel="never">
            <mat-select  [(ngModel)]="recipientType" *ngIf="recipients.length > 0">
                <mat-option *ngFor="let recipient of recipients" [value]="recipient.recipientId">{{recipient.recipientType}}</mat-option>
            </mat-select>
        </mat-form-field>
      </app-card-label>
</mat-dialog-content>

<mat-dialog-content class="mat-typography" style="text-align: center;" *ngIf="generatingLetter && !generatingError">
    <h3>Generating Letter - Please do not leave the page</h3>
    <div class="progress">
      <mat-spinner color="primary" diameter="50">
      </mat-spinner>
    </div>
</mat-dialog-content>

<mat-dialog-content class="mat-typography" style="text-align: center;" *ngIf="generatingError">
    <h3 class="error">An Error Occurred</h3>
    <div class="error">
      {{errorMessage}}
    </div>
  </mat-dialog-content>



<div mat-dialog-actions align="end">
    <button mat-stroked-button  cdkFocusInitial (click)="generateLetter()" *ngIf="!generatingLetter" color="primary" [disabled]="!recipientType">Generate Letter</button>
</div>

