import { Component, OnInit, Inject } from '@angular/core';
import { faPaperPlane, faPaperclip, faTrashAlt, faSave, faLongArrowRight, faExclamation, faArrowDown } from '@fortawesome/pro-light-svg-icons';
import { SelectEmailAddressComponent } from './select-email-address/select-email-address.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { EmailService } from '../../../shared/services/email.service';
import { CaseService } from '../../../shared/services/case.service';
import { CorrespondenceTimelineService } from '../../../shared/services/correspondence-timeline.service';
import { UserService } from '../../../shared/services/user.service';
import { BasicPopupYesNoComponent } from '../../../shared/components/basic-popup-yes-no/basic-popup-yes-no.component';
import { CaseFilesService } from '../../../shared/services/casefiles.service';
import { DateTime } from 'luxon';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { fal } from '@fortawesome/pro-light-svg-icons';
//import { fal } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {
  public Editor = DecoupledEditor;
  data: any = `<p class="ck-container">Hello, world!</p>`;
  config = {
    toolbar: ['bold', 'italic', '|', 'fontColor'],
    fontColor: {
      colors: [
        {
          color: '#FFFFFF',
          label: 'White',
          hasBorder: true
        },
        {
          color: '#C0C0C0',
          label: 'Silver'
        },
        {
          color: '#808080',
          label: 'Gray'
        },
        {
          color: '#000000',
          label: 'Black'
        },
        {
          color: '#FF0000',
          label: 'Red'
        },
        {
          color: '#800000',
          label: 'Maroon'
        },
        {
          color: '#808000',
          label: 'Olive'
        },
        {
          color: '#00FF00',
          label: 'Lime'
        },
        {
          color: '#008000',
          label: 'Green'
        },
        {
          color: '#00FFFF',
          label: 'Aqua'
        },
        {
          color: '#008080',
          label: 'Teal'
        },
        {
          color: '#0000FF',
          label: 'Blue'
        },
        {
          color: '#000080',
          label: 'Navy'
        },
        {
          color: '#FF00FF',
          label: 'Fuchsia'
        },
        {
          color: '#800080',
          label: 'Purple'
        }
      ]

    }
  }

  faPaperPlane = faPaperPlane;
  faPaperclip = faPaperclip;
  faTrashAlt = faTrashAlt;
  faSave = faSave;
  faLongArrowRight = faLongArrowRight;
  faExclamation = faExclamation;
  faArrowDown = faArrowDown;

  caseId: number = -1;
  emailObj: any;
  sendingEmail: boolean = false;
  readOnly = false;
  caseFiles: any[] = [];
  currentUserEmail: string = "";
  fromEmailAddress: string = "";
  isDraftEmail: boolean = false;
  draftEmailId: number = -1;
  forwardingEmail: boolean = false;
  forwardingBody: string = "";
  isHighImportance: boolean = false;

  emailDetails = this.fb.group({
    toAddresses: [, Validators.required],
    ccAddresses: [''],
    subject: ['', Validators.required],
    body: ['', Validators.required],
    caseFileIds: null,
    fromUserTypeId: 0,
    forwardingBody: null,
    isHighImportance: false
  }, {});

  emailBody: string = "";
  emailSignatureHtml: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
    public dialogRef: MatDialogRef<EmailComponent>,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private emailService: EmailService,
    private userService: UserService,
    private caseFilesService: CaseFilesService,
    private correspondenceTimelineService: CorrespondenceTimelineService) {

    this.emailObj = componentData.emailObj;

    this.caseId = componentData.caseId;

    if (componentData.caseFiles)
      this.caseFiles = componentData.caseFiles;

    if (this.emailObj == null) // New Email
    {
      this.caseId = componentData.caseId;

      // Check if there are any draft emails for this case and user
      this.emailService.getDraftEmail(this.caseId).subscribe((response: any) => {

        var draftEmail = response.draftEmail;
        if (draftEmail !== null) {
          this.draftEmailId = draftEmail.id;
          this.isDraftEmail = true;
          this.isHighImportance = draftEmail.isHighImportance

          this.emailDetails.controls['toAddresses'].patchValue(draftEmail.to);
          this.emailDetails.controls['ccAddresses'].patchValue(draftEmail.cc);
          this.emailDetails.controls['subject'].patchValue(draftEmail.subject);
          this.emailDetails.controls['body'].patchValue(draftEmail.body);
          this.emailDetails.controls['fromUserTypeId'].patchValue(draftEmail.fromUserTypeId);
          this.emailDetails.controls['isHighImportance'].patchValue(this.isHighImportance);

          this.emailService.getDraftEmailAttachments(this.caseId, this.draftEmailId).subscribe((response: any) => {

            if (response.attachments) {
              response.attachments.forEach((file: any) => {
                this.caseFiles.push(file.caseFile);
              });
            }
          });
        }

        this.initialiseEmail();
      });



    }
    else {
      this.caseId = this.emailObj.caseId;
      this.readOnly = true;
      this.isHighImportance = this.emailObj.isHighImportance;

      this.emailDetails.controls['toAddresses'].disable();
      this.emailDetails.controls['ccAddresses'].disable();
      this.emailDetails.controls['subject'].disable();
      this.emailDetails.controls['body'].disable();

      this.emailDetails.controls['subject'].patchValue(this.emailObj.subject);
      this.emailDetails.controls['body'].patchValue(this.emailObj.body);
      this.emailDetails.controls['isHighImportance'].patchValue(this.emailObj.isHighImportance);


      this.emailBody = this.emailObj.body;

      let toAddresses: string[] = [];
      let ccAddresses: string[] = [];

      toAddresses = this.emailObj.to.split(";");

      if (this.emailObj.cc)
        ccAddresses = this.emailObj.cc.split(";");

      this.fromEmailAddress = this.emailObj.from;

      this.emailDetails.controls['toAddresses'].patchValue(toAddresses);
      this.emailDetails.controls['ccAddresses'].patchValue(ccAddresses);

    }
  }

  ngOnInit() {
  }

  initialiseEmail() {
    this.emailService.getEmailSignature().subscribe((response: any) => { // used to show a preview of the email sig to the user when sending the email
      this.emailSignatureHtml = response.emailSignature;
    });

    this.emailService.getEmailSubject(this.caseId).subscribe((response: any) => {

      var subject = response.emailSubject;

      this.emailDetails.controls['subject'].patchValue(subject);
    });

    this.userService.currentUserEmail().subscribe((response: any) => {

      this.currentUserEmail = response.emailAddress; // this will be for display only - the email address will be checked in the backend for the current user
    });
  }

  selectAddress(addresstype: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      caseId: this.caseId,
      addressType: addresstype
    };

    var dialogRef = this.dialog.open(SelectEmailAddressComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {

      if (result) {
        this.emailDetails.controls['toAddresses'].patchValue(result.toAddresses);
        this.emailDetails.controls['ccAddresses'].patchValue(result.ccAddresses);

        this.validateEmails();
      }

    });
  }

  sendEmail() {

    let fileIds: any[] = [];

    this.caseFiles.forEach((file: any) => {
      fileIds.push(file.caseFileId);
    });

    this.sendingEmail = true;

    this.emailDetails.controls['caseFileIds'].patchValue(fileIds);

    var emailBody = this.emailDetails.controls['body'].value;

    let sendEmailDetails = {};
    if (!this.forwardingEmail) {
      var HTMLEmailBody = emailBody.replace(/\r\n|\r|\n/g, "\r\n<br />");

      sendEmailDetails = {
        toAddresses: this.emailDetails.controls['toAddresses'].value,
        ccAddresses: this.emailDetails.controls['ccAddresses'].value,
        subject: this.emailDetails.controls['subject'].value,
        body: HTMLEmailBody,
        textbody: emailBody,
        caseFileIds: fileIds,
        fromUserTypeId: this.emailDetails.controls['fromUserTypeId'].value,
        isHighImportance: this.emailDetails.controls['isHighImportance'].value
      };
    }
    else {

      sendEmailDetails = {
        toAddresses: this.emailDetails.controls['toAddresses'].value,
        ccAddresses: this.emailDetails.controls['ccAddresses'].value,
        subject: this.emailDetails.controls['subject'].value,
        body: emailBody,
        caseFileIds: fileIds,
        fromUserTypeId: this.emailDetails.controls['fromUserTypeId'].value,
        forwardEmailBody: this.emailDetails.controls['forwardingBody'].value,
        isHighImportance: this.emailDetails.controls['isHighImportance'].value

      };
    }

    this.emailService.sendEmail(this.caseId, sendEmailDetails).subscribe((response: any) => {

      if (this.draftEmailId > 0) {
        this.emailService.deleteDraft(this.caseId, this.draftEmailId).subscribe((response: any) => {
        });
      }

      this.dialogRef.close(true);
      this.correspondenceTimelineService.refreshCaseTimeline.next();
    });

  }

  removeAttachment(id: number) {
    this.caseFiles = this.caseFiles.filter(item => item.caseFileId !== id);
  }

  saveDraftEmail() {

    let fileIds: any[] = [];

    this.caseFiles.forEach((file: any) => {
      fileIds.push(file.caseFileId);
    });

    this.sendingEmail = true;

    this.emailDetails.controls['caseFileIds'].patchValue(fileIds);

    let emailData = {
      toAddresses: this.emailDetails.controls['toAddresses'].value,
      ccAddresses: this.emailDetails.controls['ccAddresses'].value,
      subject: this.emailDetails.controls['subject'].value,
      body: this.emailDetails.controls['body'].value,
      caseFileIds: fileIds,
      fromUserTypeId: this.emailDetails.controls['fromUserTypeId'].value,
      isHighImportance: this.emailDetails.controls['isHighImportance'].value
    };

    this.emailService.saveDraftEmail(this.caseId, emailData).subscribe((response: any) => {
      this.dialogRef.close(false);
    });
  }

  dismissDraft() {


    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Are you sure you want to delete this draft?', popupText1: '', popupText2: '' }
    });

    dialogRef.afterClosed().subscribe((result: any) => {

      if (result) {
        this.emailService.deleteDraft(this.caseId, this.draftEmailId).subscribe((response: any) => {

          this.isDraftEmail = false;
          this.draftEmailId = -1;
          this.clearEmailInfo();
        });
      }
    });

  }

  clearEmailInfo() {
    this.emailDetails.controls['toAddresses'].patchValue(null);
    this.emailDetails.controls['ccAddresses'].patchValue('');

    this.emailService.getEmailSubject(this.caseId).subscribe((response: any) => {
      var subject = response.emailSubject;
      this.emailDetails.controls['subject'].patchValue(subject);
    });
    this.emailDetails.controls['body'].patchValue('');
    this.emailDetails.controls['fromUserTypeId'].patchValue(0);
    this.emailDetails.controls['isHighImportance'].patchValue(false);
    this.isHighImportance = false;
    this.caseFiles = [];
  }

  openFile(file: any) {

    this.caseFilesService.getFile(file.caseFileId).subscribe(response => {
      var blob = response.body;
      if (blob != null) {
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL, '_blank');
      }
    });
  }

  forwardEmail() {

    this.forwardingEmail = true;

    this.emailDetails.controls['toAddresses'].patchValue("");
    this.emailDetails.controls['ccAddresses'].patchValue("");
    this.emailDetails.controls['subject'].patchValue("FW: " + this.emailDetails.controls['subject'].value);

    this.readOnly = false;
    this.fromEmailAddress = "";

    this.emailDetails.controls['toAddresses'].enable();
    this.emailDetails.controls['ccAddresses'].enable();
    this.emailDetails.controls['subject'].enable();
    this.emailDetails.controls['body'].enable();

    this.emailDetails.controls['forwardingBody'].patchValue(this.emailObj.body);

     var newBody = "<br><br>-------- Forwarded Message -------- <br>" +
      "Subject: " + this.emailObj.subject + "<br>" +
      "Date: " + this.formatDate(this.emailObj.queuedDate) + "<br>" +
      "To: " + this.emailObj.to + "<br>" +
      "From: " + this.emailObj.from + "<br>";

    this.emailDetails.controls['body'].patchValue(newBody);

  }


  formatDate(inDate: any) {

    var formattedDate = '';

    if (inDate !== null) {
      var dt = DateTime.fromISO(inDate);
      formattedDate = dt.toFormat("dd-MM-yyyy HH:mm:ss");

      if (formattedDate == 'Invalid date')
        formattedDate = '';
    }

    return formattedDate;
  }

  toggleHighImportance() {
    this.isHighImportance = !this.isHighImportance;
    this.emailDetails.controls['isHighImportance'].patchValue(this.isHighImportance);

  }

  validateEmails() {
    var emailString = this.emailDetails.controls['toAddresses'].value;
    var emails = emailString.split(";");

    var result = [];
    for (var i = 0; i <= emails.length; i++) {
      var email = emails[i]?.trim();

      if (email != null && email.length > 5) {
        result.push(this.isEmailValid(email));
      }
    }

    if (result.indexOf(false) != -1) {
      this.emailDetails.controls["toAddresses"].setErrors({ 'incorrect': true });
    }
  }

  isEmailValid = (email) => {
    var reg = /^[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}$/;
    return reg.test(email);
  };


  public onCkEditorReady(editor) {

    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    editor.editing.view.change(writer => {
      writer.setStyle('height', '250px', editor.editing.view.document.getRoot());
    });

    editor.focus();

  }

}
