import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SearchResult } from './interfaces/search-result';
import { SearchRequest } from './interfaces/search-request';
import { Observable } from 'rxjs/internal/Observable';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class CorrespondenceTimelineService {

  private url = environment.apiUrl + 'correspondencetimeline/';

  public refreshCaseTimeline  = new EventEmitter();
  public refreshCaseTimelineObservable = this.refreshCaseTimeline.asObservable();

  constructor(private http: HttpClient) { }


  getCorrespondenceTimelineItems(caseId: (number | undefined), search: any){
    return this.http.post<any>(this.url  + 'caseitems/' + caseId, search );
  }

  getArchivedCorrespondenceTimelineItems(caseId: (number | undefined)){
    return this.http.get<any>(this.url  + 'archivedcaseitems/' + caseId );
  }

  renameItem(caseId: number, itemId: number, newName: string){

    let formData = new FormData();
    formData.append('caseId', caseId.toString());
    formData.append('newName', newName);

    return this.http.post(this.url + itemId  + '/rename/', formData);
  }

  stitchItems(caseId: number, fileName:string, itemIds: number[]){

    let itemData: any = { caseId: caseId,
                          itemIds: itemIds,
                          fileName: fileName };

    return this.http.post<any>(this.url + 'stitch/', itemData);
  }

  generatePDF(caseId: number | undefined, itemId: number){

    let itemData: any = { caseId: caseId,
                          itemId: itemId};

    return this.http.post<any>(this.url + 'generatepdf/', itemData);
  }

  pinItem(id: number, caseId: number | undefined){

    let itemData: any = { id: id,
                          caseId: caseId };

    return this.http.post(this.url + 'pin/', itemData);
  }

  unpinItem(id: number, caseId: number | undefined){

    let itemData: any = { id: id,
                          caseId: caseId };

    return this.http.post(this.url + 'unpin/', itemData);
  }

  highlightItem(id: number, caseId: number | undefined){

    let itemData: any = { id: id,
                          caseId: caseId };

    return this.http.post(this.url + 'highlight/', itemData);
  }

  unhighlightItem(id: number, caseId: number | undefined){

    let itemData: any = { id: id,
                          caseId: caseId };

    return this.http.post(this.url + 'unhighlight/', itemData);
  }

  archiveItem(id: number, caseId: number | undefined, reason: string){

    let itemData: any = { id: id,
                          caseId: caseId,
                          archivedReason: reason };

    return this.http.post(this.url + 'archive/', itemData);
  }

  archiveItems(itemIds: number [], caseId: number | undefined, reason: string){

    let itemData: any = { itemIds: itemIds,
                          caseId: caseId,
                          archivedReason: reason };

    return this.http.post(this.url + 'archive/', itemData);
  }
}
