import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { BasicPopupOkComponent } from '../../shared/components/basic-popup-ok/basic-popup-ok.component';

@Component({
  selector: 'app-company-group-details',
  templateUrl: './company-group-details.component.html',
  styleUrls: ['./company-group-details.component.css']
})
export class CompanyGroupDetailsComponent implements OnInit {

  newCompanyGroup: boolean = true;
  companyGroupId?: number;
  updatingGroup: boolean = false;

  companyGroupDetails = this.fb.group({
    id: [],
    name: [],
    information: [],
    equityReleaseFee: [],
    accountName: [],
    accountNumber: [],
    sortCode: [],
    noAccessOrUpdatesForFAs: [],
    adminEmailAddress: [],
    managerEmailAddress: [],
    apiAccess: [],
    apiClientId: [],
    apiClientSecret: [],
    individualStatusId: new FormControl({ value: null, disabled: true })
  }, { });


  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private snackBar: MatSnackBar,
              public dialog: MatDialog) {
                this.route.params.subscribe(params => {
      this.companyGroupId = params['id'];

      if(this.companyGroupId !== undefined && this.companyGroupId !== null){
        this.newCompanyGroup = false;

        this.userService.companyGroupsDetails(this.companyGroupId).subscribe(result => {
          this.companyGroupDetails.patchValue(result);
        });
      }

    });
              }

  ngOnInit(): void {
  }

  saveCompanyGroup(){

    this.updatingGroup = true;

    this.userService.updateCompanyGroup(this.companyGroupDetails.value).subscribe(response => {

      this.router.navigate(['/companygroup/list']);
      this.updatingGroup = false;

      }, error => {

        var errorMessage = "Company Group Update Failed";
        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }

        this.updatingGroup = false;
        this.openSnackBar(errorMessage, 'Close');


      });
  }

  addCompanyGroup(){
    this.userService.addCompanyGroup(this.companyGroupDetails.value).subscribe(response => {

      this.router.navigate(['/companygroup/list']);

      }, error => {


        var errorMessage = "Company Group Creation Failed";
        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }

        this.openSnackBar(errorMessage, 'Close');
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        duration: 5000,
    });
  }

  checkRestrictFAValue(){

    console.log('checkRestrictFAValue', this.companyGroupDetails!.controls['noAccessOrUpdatesForFAs']!.value);

    if(this.companyGroupDetails!.controls['noAccessOrUpdatesForFAs']!.value)
    {
       this.dialog.open(BasicPopupOkComponent, {
         data: { messageHeader: '', popupText1: 'By clicking Yes, FAs from this company group will not be able to log in or receive update emails', popupText2: ''}
       });

    }

  }

}
