
<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<button mat-flat-button (click)="viewHistory()" color="primary" title="History" class="historyButton">
    <fa-icon [icon]="faHistory"></fa-icon>
  </button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Case Notes</h2>

<mat-spinner color="primary" diameter="20" *ngIf="!notesLoaded"></mat-spinner>

  <mat-dialog-content class="mat-typography">
<div class="caseNotes" *ngIf="notes" style="max-height : 300px;">



    <mat-list *ngFor="let note of notes.caseNotes; let lastItem = last;">
      <mat-list-item style="height: unset" *ngIf='note'>
        <div class="buttons">
          <button *ngIf='note' mat-icon-button (click)="deleteNote(note.id)" color="warn" title="Delete">
              <fa-icon [icon]="faTrashAlt" ></fa-icon>
          </button>
          <button *ngIf='note.canEdit' mat-icon-button (click)="editNote(note.id)" title="Edit">
              <fa-icon [icon]="faEdit" ></fa-icon>
          </button>
        </div>
        <app-case-note *ngIf='note' [caseNote]='note'></app-case-note>
      </mat-list-item>
    </mat-list>
  </div>
</mat-dialog-content>


  <div mat-dialog-actions align="start">
      <button mat-mini-fab (click)="addCaseNotes()" color="primary">
        <fa-icon [icon]="faPlus"></fa-icon>
      </button>
    </div>
