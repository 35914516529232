import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit {

  loading = false;
  newDateValue?: Date;
  canClear: boolean = true;

  @Input()
  simpleCardForm: boolean = false;
  @Input()
  simpleCardLabel: string = "Form";
  @Input()
  dateValue?: Date;

  @Output()
  onDateChangeEvent: EventEmitter<Date> = new EventEmitter<Date>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DatePickerComponent>,
    private dateAdapter: DateAdapter<Date>  ) {
    
    this.newDateValue = this.data.dateValue;
    this.canClear = this.data.canClear;
  }

  ngOnInit(): void {
    
    if (this.dateValue != null)
      this.newDateValue = this.dateValue;


  }

  onDateChange() {
    this.onDateChangeEvent.emit(this.newDateValue);
  }

  saveKeyDate() {
    this.dialogRef.close({ date: this.newDateValue });
  }

  cancel() {
    this.dialogRef.close(null);
  }

  clearDate() {
    this.dialogRef.close({ date: null });
  }
 
}
