import { Component, AfterViewInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { CaseService } from '../../shared/services/case.service';
import { UserService } from '../../shared/services/user.service';
import {  FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-allocate',
  templateUrl: './allocate.component.html',
  styleUrls: ['./allocate.component.css']
})

export class AllocateComponent implements AfterViewInit {

  private caseId: number;
  paralegalUsers: any[] = [];
  adminUsers: any[] = [];
  filteredParalegalUsers: Observable<any[]> = new Observable<any[]>();
  filteredAdminUsers: Observable<any[]> = new Observable<any[]>();
  paralegalSearchInput = new FormControl();
  adminSearchInput = new FormControl();
  invalidSelection: boolean = true;
  isLoading: boolean = false;

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
                  public dialogRef: MatDialogRef<AllocateComponent>,
                  private caseService: CaseService,
                  userService: UserService) {


        this.caseId = this.data['caseId'];

        userService.paralegals().subscribe(results => {

          for (const user of results) {
              this.paralegalUsers.push({ name: user.firstName + ' ' + user.lastName, id: user.id })
          }

          this.loadParalegals()
        });

        userService.admins().subscribe(results => {

          for (const user of results) {
              this.adminUsers.push({ name: user.firstName + ' ' + user.lastName, id: user.id })
          }

          this.loadAdmins();
        });
   }

  ngAfterViewInit(): void {
    setTimeout(() => this.loadParalegals());
    setTimeout(() => this.loadAdmins());
  }

  loadParalegals() {

    this.filteredParalegalUsers = this.paralegalSearchInput.valueChanges
      .pipe(
        startWith<string | any>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterParalegals(name) : this.paralegalUsers.slice())
      );

    }

  loadAdmins() {

    this.filteredAdminUsers = this.adminSearchInput.valueChanges
    .pipe(
      startWith<string | any>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filterAdmins(name) : this.adminUsers.slice())
    );

  }

  displayFn(user: any): string {
    return user ? user.name : '';
  }

  private _filterParalegals(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.paralegalUsers.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterAdmins(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.adminUsers.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  selectionUpdated(){

    this.invalidSelection = true;

    if(this.paralegalSearchInput.value != null && this.paralegalSearchInput.value != "" &&
      this.adminSearchInput.value != null && this.adminSearchInput.value != "")
      this.invalidSelection = false;

  }

  cancel(){
    this.dialogRef.close(null);
  }

  allocateCase(){

    this.isLoading = true;

    this.caseService.allocateCase(this.caseId, this.paralegalSearchInput.value.id, this.adminSearchInput.value.id).subscribe(response => {

      this.caseService.caseAllocated.next(true);
      this.dialogRef.close(true);

      }, error => {

      });

  }
}
