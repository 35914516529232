<h2 mat-dialog-title>Archive Case</h2>

<app-card-label label="Reason">
    <mat-form-field floatLabel="never">
      <mat-select placeholder="Select Reason"  [(ngModel)]="selectedReason" >
       <mat-option *ngFor="let reason of reasons" [value]="reason.id">{{reason.reason}}</mat-option>
     </mat-select>
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Details" [hidden]="selectedReason != 2">
    <mat-form-field floatLabel="never">
        <textarea autosize matInput [(ngModel)]="archiveReasonOther"  maxlength="500"></textarea>
    </mat-form-field>
  </app-card-label>

  <div class="buttons">
    <button *ngIf="!isLoading" mat-stroked-button color="warn" type="button" (click)="cancel()">Cancel</button>
    <button *ngIf="!isLoading" mat-stroked-button [disabled]="(selectedReason == null) || (selectedReason == 2 && archiveReasonOther.length == 0) " color="primary" type="button" (click)="save()">Save</button>

    <button *ngIf="isLoading" mat-icon-button disabled color="primary" class="right" >
        <mat-icon>
            <mat-spinner color="primary" diameter="20">
            </mat-spinner>
        </mat-icon>
    </button>
  </div>
