import { Component, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core';
import { ConsultantsService } from '../../shared/services/consultants.service';
import { SearchRequest } from '../../shared/services/interfaces/search-request';
import { BaseFilterComponent } from '../../shared/components/table-filters/base-filter.component';
import { Router } from '@angular/router';
import { faPlus, faCheck } from '@fortawesome/pro-light-svg-icons';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Observable, merge, of} from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-consultant-list',
  templateUrl: './consultant-list.component.html',
  styleUrls: ['./consultant-list.component.scss']
})
export class ConsultantListComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype);
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChildren('filter') filters!: QueryList<BaseFilterComponent>;

  consultants: any[] = [];
  faPlus = faPlus;
  faCheck = faCheck;
  resultsLength: number = 0;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  isLoadingResults: boolean = false;

  displayedColumns: string[] = ['name', 'emailAddress', 'postcode', 'phoneNumber', 'fees', 'active'];

  constructor(public router: Router, private consultantsService: ConsultantsService, public authService: AuthService) { }

  ngAfterViewInit() {

    const outputs = this.filters.map(button => button.filter);

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(...outputs).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page, ...outputs)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          let searchRequest: SearchRequest = {
            page: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize,
            orderBy: this.sort.active,
            orderDirection: this.sort.direction,
            filterColumns: this.filters.map(button => button.filterColumn),
            filterStrings: this.filters.map(button => button.filterValue)
          };

            return this.consultantsService.getConsultants(searchRequest);

        }),
        map((data: any) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.recordCount;
          return data.items;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return of([]);
        })
      ).subscribe(data => this.consultants = data);
  }

  addConsultant(){
    this.router.navigateByUrl('/consultants/new');
  }

  editConsultant(consultant: any){
    this.router.navigateByUrl('/consultants/' + consultant.id + '/admin');
  }

}
