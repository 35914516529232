import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SearchResult } from './interfaces/search-result';
import { SearchRequest } from './interfaces/search-request';
import { Observable } from 'rxjs/internal/Observable';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class FileClosureService {

  private url = environment.apiUrl + 'fileclosure/';

  public refreshCaseTimeline  = new EventEmitter();
  public refreshCaseTimelineObservable = this.refreshCaseTimeline.asObservable();

  constructor(private http: HttpClient) { }


  getFileClosureForm(caseId: (number | undefined)){
    return this.http.get<any>(this.url + caseId  );
  }


  update(caseId: number, formData: any){

    return this.http.post<any>(this.url + caseId, formData );
  }




}
