import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class AuditService {

  private auditFormUrl = environment.apiUrl + 'auditforms/';

  constructor(private http: HttpClient, public router: Router) {

  }


  auditForm(caseId: number, formName: string, oldValues: any, newValues : any, itemId?: number){

    let requestData = {
      caseId: caseId,
      formName: formName,
      oldValues: JSON.stringify(oldValues),
      newValues: JSON.stringify(newValues),
      itemId: itemId
    };

    return this.http.put(this.auditFormUrl, requestData, httpOptions);

  }

  auditFormItem(formName: string, oldValues: any, newValues : any, itemId?: number){

    let requestData = {
      itemId: itemId,
      formName: formName,
      oldValues: JSON.stringify(oldValues),
      newValues: JSON.stringify(newValues),
    };

    return this.http.put(this.auditFormUrl + "item", requestData, httpOptions);

  }

  getAuditItemsForCaseForm(formName: string, caseId: number){
    return this.http.get<any>(this.auditFormUrl + caseId + "/" + formName);
  }

  getAuditItemsForForm(formName: string, itemId: number){
    return this.http.get<any>(this.auditFormUrl + "item/" + itemId + "/" + formName);
  }



}
