import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConsultantsService } from '../../shared/services/consultants.service';
import { AuditService } from '../../shared/services/audit.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuditFormComponent } from '../../case/audit-form/audit-form.component';
import { faHistory} from '@fortawesome/pro-light-svg-icons';
import { ComparerService } from '../../shared/utility/comparer';


@Component({
  selector: 'app-consultant-details',
  templateUrl: './consultant-details.component.html',
  styleUrls: ['./consultant-details.component.css']
})
export class ConsultantDetailsComponent implements OnInit {

  newConsultant: boolean = true;
  consultantId?: number;
  updatingConsultant: boolean = false;
  faHistory= faHistory;

  consultantDetails = this.fb.group({
    id: [],
    name:  [],
    emailAddress: [],
    postcode: [],
    borough: [],
    numberDaysWorkedPerWeek: [],
    daysOfTheWeekWorked: [],
    milesWillingToTravel: [],
    phoneNumber: [],
    fees: [],
    active: [],
    individualStatusId: new FormControl({ value: null, disabled: true })
  }, { });

  initialForm = this.fb.group({
    id: [],
    name:  [],
    emailAddress: [],
    postcode: [],
    borough: [],
    numberDaysWorkedPerWeek: [],
    daysOfTheWeekWorked: [],
    milesWillingToTravel: [],
    phoneNumber: [],
    fees: [],
    active: [],
    individualStatusId: new FormControl({ value: null, disabled: true })
  }, { });

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private consultantsService: ConsultantsService,
              private snackBar: MatSnackBar,
              private auditService: AuditService,
              private comp: ComparerService,
              public dialog: MatDialog) {

    this.route.params.subscribe(params => {
      this.consultantId = params['id'];

      if(this.consultantId !== undefined && this.consultantId !== null){
        this.newConsultant = false;

        this.consultantsService.consultantDetails(this.consultantId).subscribe(result => {
          this.consultantDetails.patchValue(result);
          this.initialForm.patchValue(result);
        });
      }
      else{
          this.consultantDetails.controls['active'].patchValue(true);
      }

    });

  }

  ngOnInit(): void {
  }

  saveConsultant(){
    if(this.comp.objectsDifferent(this.initialForm.value, this.consultantDetails.value))
    {
      this.auditService.auditFormItem('ConsultantDetails', this.initialForm.value, this.consultantDetails.value, this.consultantId).subscribe((response : any) => {
        this.doSave();
      });
    }
    else{
      this.doSave();
    }
  }

  doSave(){

    this.updatingConsultant = true;

    this.consultantsService.updateConsultant(this.consultantId, this.consultantDetails.value).subscribe(response => {


      this.router.navigate(['/consultants/list']);
      this.updatingConsultant = false;

      }, error => {

        var errorMessage = "Consultant Update Failed";
        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }

        this.updatingConsultant = false;
        this.openSnackBar(errorMessage, 'Close');


      });
  }

  addConsultant(){


    this.consultantsService.addConsultant(this.consultantDetails.value).subscribe(response => {

      this.router.navigate(['/consultants/list']);

      }, error => {


        var errorMessage = "Consultant Creation Failed";
        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }

        this.openSnackBar(errorMessage, 'Close');


      });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        duration: 5000,
    });
  }


  openAuditForForm(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      itemId: this.consultantId,
      formName: "ConsultantDetails"
    };

    this.dialog.open(AuditFormComponent, dialogConfig);

  }

}
