<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Form Change History</h2>

<h3 *ngIf="auditFormItems.length == 0">No Changes</h3>

<mat-dialog-content *ngIf="auditFormItems.length > 0">
  <table mat-table [dataSource]="auditFormItems">

    <ng-container matColumnDef="enquiry">
      <th mat-header-cell *matHeaderCellDef > Enquiry. </th>
      <td mat-cell *matCellDef="let item"> {{item.enquiry}} </td>
    </ng-container>


    <ng-container matColumnDef="fieldName">
      <th mat-header-cell *matHeaderCellDef style="width: 100px"> Field. </th>
      <td mat-cell *matCellDef="let item"> {{item.fieldName}} </td>
    </ng-container>

    <ng-container matColumnDef="oldValue">
      <th mat-header-cell *matHeaderCellDef style="width: 160px"> Old Value. </th>
      <td mat-cell *matCellDef="let item"> {{item.oldValue}} </td>
    </ng-container>

    <ng-container matColumnDef="newValue">
      <th mat-header-cell *matHeaderCellDef  style="width: 160px"> New Value. </th>
      <td mat-cell *matCellDef="let item"> {{item.newValue}} </td>
    </ng-container>

    <ng-container matColumnDef="byUser">
      <th mat-header-cell *matHeaderCellDef style="width: 120px"> User. </th>
      <td mat-cell *matCellDef="let item"> {{item.byUser}} </td>
    </ng-container>

    <ng-container matColumnDef="dateChanged" >
      <th mat-header-cell *matHeaderCellDef style="width: 100px; padding-right: 0px;"> Date Updated. </th>
      <td mat-cell *matCellDef="let item"> {{item.dateChanged}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>  

  </table>
</mat-dialog-content>
