import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean>|boolean {

    const expectedRole = route.data.expectedRole;

    if (!this.auth.isAuthenticated()) {
      this.router.navigateByUrl('/login')
      return false;
    }
    else if (!this.auth.hasRole(expectedRole)) {

      this.router.navigateByUrl('/denied')
      return false;
    }

    return true;
  }
}
