import { Component, OnInit, Input } from '@angular/core';
import { faShareSquare } from '@fortawesome/pro-light-svg-icons';
import {SendToCaseComponent} from './../send-to-case/send-to-case.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CaseFilesService } from 'src/app/shared/services/casefiles.service';

@Component({
  selector: 'app-unassigned-file',
  templateUrl: './unassigned-file.component.html',
  styleUrls: ['./unassigned-file.component.css']
})
export class UnassignedFileComponent implements OnInit {

  @Input() details: any;
  @Input() selectable: boolean = false;
  @Input() selected: boolean = false;

  faShareSquare = faShareSquare;

  constructor(public caseFilesService: CaseFilesService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
  }


  sendToCase(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      file: this.details
    };

    const dialogRef = this.dialog.open(SendToCaseComponent, dialogConfig);
  }

}
