<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Credit Safecheck History For Client {{clientNo}}</h2>

<h3 *ngIf="historyData.length == 0">No Changes</h3>

<mat-dialog-content class="mat-typography" *ngIf="historyData.length > 0">
  <table mat-table [dataSource]="historyData">
    <ng-container matColumnDef="faStatus">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let item">
        <fa-icon *ngIf="item.creditCheckStatus == 'Error' || item.creditCheckStatus == 'Fail'"
                 [icon]="faExclamationCircle"
                 size="1x"
                 class="check-danger"></fa-icon>
        <fa-icon *ngIf="item.creditCheckStatus == 'Success'"
                 [icon]="faCheckCircle"
                 size="1x"
                 class="check-success"></fa-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="checkPerformedDate">
      <mat-header-cell *matHeaderCellDef> Date Check Performed. </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.checkPerformedDate | date: 'dd/MM/yyyy HH:mm'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="checkPerformedBy">
      <mat-header-cell *matHeaderCellDef> Check Performed By. </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.checkPerformedByUser.fullName}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="checkResult">
      <mat-header-cell *matHeaderCellDef> Result. </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.creditCheckStatus}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="isManual">
      <mat-header-cell *matHeaderCellDef> Manual Check. </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.isManualCheck ? "Yes" : "No"}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="checkResultString">
      <mat-header-cell *matHeaderCellDef> Result String. </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.checkResultString }}</mat-cell>
    </ng-container>
    <!--<ng-container matColumnDef="isCancelled">
    <mat-header-cell *matHeaderCellDef> Cancelled. </mat-header-cell>
    <mat-cell *matCellDef="let item"> {{item.cancelledDate != null ? "Yes" : "No"}}</mat-cell>
  </ng-container>-->



    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let item; columns: displayedColumns;">
    </mat-row>

  </table>
</mat-dialog-content>


<!--<table>
  <tbody>
    <tr *ngFor="let item of historyData">
      <td>{{item.id}}</td>
    </tr>
  </tbody>
</table>-->
