<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<div mat-dialog-title><h2><span *ngIf="!readOnly">New </span>SMS</h2></div>
<div *ngIf="!readOnly && !optedIn" class="error"><fa-icon [icon]="faExclamationCircle"  size="lg"></fa-icon> Client has not opted in to text messages</div>

<mat-dialog-content class="mat-typography">
  <form [formGroup]="smsDetails" autocomplete="off">

    <div class="wrapper">
      <div class="row">

        <div>
          <div class="to">
            <label>Phone Number: </label>
            <mat-form-field>
              <input matInput value="" formControlName="phoneNumber" >
            </mat-form-field>
          </div>
        </div>

      </div>

      <div class="row border">
        <mat-form-field>
            <mat-hint *ngIf="!readOnly" align="end">{{message.value?.length || 0}}/140</mat-hint>
          <textarea matInput #message formControlName="message" maxlength="140"></textarea>
        </mat-form-field>
      </div>

    </div>
  </form>

  <div *ngIf="readOnly">
      <div class="row" >
          Created : {{smsObj.queuedDate  | date:'dd/MM/yyyy HH:mm'}}
      </div>
    <div class="row" >
        Sent : {{smsObj.sentDate  | date:'dd/MM/yyyy HH:mm'}}
    </div>
</div>

</mat-dialog-content>

<div mat-dialog-actions align="end" *ngIf="!readOnly">
    <button mat-stroked-button  color="primary" cdkFocusInitial (click)="sendSMS()" [disabled]="sendingSMS || smsDetails.invalid">Send</button>
  </div>
