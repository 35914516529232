import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AllowancesService } from 'src/app/shared/services/allowances.service';

@Component({
  selector: 'app-add-allowance',
  templateUrl: './add-allowance.component.html',
  styleUrls: ['./add-allowance.component.css']
})
export class AddAllowanceComponent implements OnInit {

  caseId : number;

  newAllowanceDetails = this.fb.group({
    description: [null, Validators.required],    
    amount: [null, Validators.required]
  }, { });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private fb: FormBuilder,
  private allowancesService: AllowancesService,
  public dialogRef: MatDialogRef<AddAllowanceComponent>
  ) { 
    this.caseId = data.caseId;
  }

  ngOnInit(): void {    
  }

  addAllowance() {
    var allowance = {
      description: this.newAllowanceDetails.controls['description'].value,
      amount: this.newAllowanceDetails.controls['amount'].value
    };
    this.allowancesService.addAllowance(this.caseId, allowance).subscribe((result) => {
      this.dialogRef.close();
    });
  }

}
