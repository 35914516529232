import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth/auth.guard';
import { RoleGuardService } from './auth/role.guard';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { LoginComponent } from './auth/login/login.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CaseComponent } from './case/case.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompanyGroupsComponent } from './company-groups/company-groups.component';
import { CompanyGroupDetailsComponent } from './company-groups/company-group-details/company-group-details.component';
import { CompanyGroupListComponent } from './company-groups/company-group-list/company-group-list.component';
import { ConsultantsComponent } from './consultants/consultants.component';
import { ConsultantDetailsComponent } from './consultants/consultant-details/consultant-details.component';
import { ConsultantListComponent } from './consultants/consultant-list/consultant-list.component';
import { ActionsComponent } from './actions/actions.component';
import { UserAdminComponent } from './users/user-admin/user-admin.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UsersComponent } from './users/users.component';
import { NewCaseComponent } from './new-case/new-case.component';
import { SearchCaseComponent } from './search-case/search-case.component';
import {UnassignedFilesComponent} from './unassigned-files/unassigned-files.component';
import { LendersComponent } from './lenders/lenders.component';
import { LenderDetailsComponent } from './lenders/lender-details/lender-details.component';
import { LendersListComponent } from './lenders/lenders-list/lenders-list.component';
import { LenderSolicitorsComponent } from './lender-solicitors/lender-solicitors.component';
import { LenderSolicitorDetailsComponent } from './lender-solicitors/lender-solicitor-details/lender-solicitor-details.component';
import { LenderSolicitorsListComponent } from './lender-solicitors/lender-solicitors-list/lender-solicitors-list.component';
import { EmailQueueComponent } from './email-queue/email-queue.component';
import { KlyantComponent } from './klyant/klyant.component';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'resetpassword',
    component: ResetPasswordComponent
  },
  {
    path: 'case/:id',
    component: CaseComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'klyant/code/:code',
    component: KlyantComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'klyant/error/:error',
    component: KlyantComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'companygroup',
    component: CompanyGroupsComponent,
    canActivate: [RoleGuardService],
    data: {
      expectedRole: 'CanAdminUsers'
    },
    children: [
      {
        path: ':id/admin',
        component: CompanyGroupDetailsComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRole: 'CanAdminUsers'
        }
      }
      ,
      {
        path: 'list',
        component: CompanyGroupListComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRole: 'CanAdminUsers'
        }
      },
      {
        path: 'new',
        component: CompanyGroupDetailsComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRole: 'CanAdminUsers'
        }
      }
    ]
  },
  {
    path: 'consultants',
    component: ConsultantsComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: ':id/admin',
        component: ConsultantDetailsComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRole: 'CanAdminConsultants'
        }
      }
      ,
      {
        path: 'list',
        component: ConsultantListComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedRole: 'CanAdminConsultants'
        }
      },
      {
        path: 'new',
        component: ConsultantDetailsComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRole: 'CanAdminConsultants'
        }
      }
    ]
  },
  {
    path: 'actions',
    component: ActionsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'user',
    component: UsersComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: ':id/admin',
        component: UserAdminComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRole: 'CanAdminUsers'
        }
      }
      ,
      {
        path: 'list',
        component: UserListComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRole: 'CanAdminUsers'
        }
      },
      {
        path: ':userType/new',
        component: UserAdminComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRole: 'CanAdminUsers'
        }
      }
    ]
  },
  {
    path: 'newcase',
    component: NewCaseComponent,
    canActivate: [AuthGuardService]
  }
  ,
  {
    path: 'search',
    component: SearchCaseComponent,
    canActivate: [AuthGuardService]
  }
  ,
  {
    path: 'unassignedfiles',
    component: UnassignedFilesComponent,
    canActivate: [AuthGuardService]
  },
  {
  path: 'lenders',
    component: LendersComponent,
    canActivate: [RoleGuardService],
    data: {
      expectedRole: 'CanAdminLenders'
    },
    children: [
      {
        path: ':id/admin',
        component: LenderDetailsComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRole: 'CanAdminLenders'
        }
      }
      ,
      {
        path: 'list',
        component: LendersListComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRole: 'CanAdminLenders'
        }
      },
      {
        path: 'new',
        component: LenderDetailsComponent,
        canActivate: [RoleGuardService],
        data: {
          expectedRole: 'CanAdminLenders'
        }
      }
    ]
  },
  {
    path: 'lendersolicitors',
      component: LenderSolicitorsComponent,
      canActivate: [RoleGuardService],
      data: {
        expectedRole: 'CanAdminLenders'
      },
      children: [
        {
          path: ':id/admin',
          component: LenderSolicitorDetailsComponent,
          canActivate: [RoleGuardService],
          data: {
            expectedRole: 'CanAdminLenders'
          }
        }
        ,
        {
          path: 'list',
          component: LenderSolicitorsListComponent,
          canActivate: [RoleGuardService],
          data: {
            expectedRole: 'CanAdminLenders'
          }
        },
        {
          path: 'new',
          component: LenderSolicitorDetailsComponent,
          canActivate: [RoleGuardService],
          data: {
            expectedRole: 'CanAdminLenders'
          }
        }
      ]
    },
    {
      path: 'emailqueue',
      component: EmailQueueComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'notificationslist',
      component: NotificationsListComponent,
      canActivate: [AuthGuardService]
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
