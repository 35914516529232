import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CaseService } from '../../../../shared/services/case.service';
import { FormBuilder, Validators } from '@angular/forms';
import { LettersService } from '../../../../shared/services/letters.service';
import { UserService } from '../../../../shared/services/user.service';
import { ComparerService } from '../../../../shared/utility/comparer';
import { AuditService } from '../../../../shared/services/audit.service';
import { CorrespondenceTimelineService } from '../../../../shared/services/correspondence-timeline.service';
import { faHistory} from '@fortawesome/pro-light-svg-icons';
import { AuditFormComponent } from '../../../audit-form/audit-form.component';
import { AuthService } from '../../../../auth/auth.service';

@Component({
  selector: 'app-conveyancing-sale-estate-agent-details',
  templateUrl: './conveyancing-sale-estate-agent-details.component.html',
  styleUrls: ['./conveyancing-sale-estate-agent-details.component.css']
})
export class ConveyancingSaleEstateAgentDetailsComponent implements OnInit {

  caseId: number;
  viewInit = false;
  faUsers: any[] = [];
  faHistory = faHistory;

  generatingLetters: boolean = false;

  estateAgentDetails = this.fb.group({
    conveyancingSaleCaseId: [],
    caseId: [],
    addedByUser:[],
    addedByUserId: [],
    userCompanyId: [],
    userCompanyName: '',
    externalReference: '',
    contactNumber: '',
    estateAgentFee : [],
    estateAgentAddressLine1 : [],
    estateAgentAddressLine2 : [],
    estateAgentAddressLine3 : [],
    estateAgentAddressLine4 : [],
    estateAgentAddressLine5 : [],
    estateAgentPostcode : []
  }, { });

  initialForm = this.fb.group({
    conveyancingSaleCaseId: [],
    caseId: [],
    addedByUser:[],
    addedByUserId: [],
    userCompanyId: [],
    userCompanyName: '',
    externalReference: '',
    contactNumber: '',
    estateAgentFee : [],
    estateAgentAddressLine1 : [],
    estateAgentAddressLine2 : [],
    estateAgentAddressLine3 : [],
    estateAgentAddressLine4 : [],
    estateAgentAddressLine5 : [],
    estateAgentPostcode : []
  }, { });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private caseService: CaseService,
              private fb: FormBuilder,
              private userService: UserService,
              private lettersService: LettersService,
              private comp: ComparerService,
              private auditService: AuditService,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<ConveyancingSaleEstateAgentDetailsComponent>,
              private correspondenceTimelineService: CorrespondenceTimelineService,
              public authService: AuthService) {

    this.caseId = data.caseId;

    this.caseService.getConveyancingSaleEstateAgentDetails(this.caseId).subscribe(response => {

      this.estateAgentDetails.patchValue(response.estateAgentDetails);
      this.initialForm.patchValue(response.estateAgentDetails);

      this.userService.companyGroupUsers(response.estateAgentDetails.userCompanyId).subscribe(result => {
        this.faUsers = result;
      });
    });

    this.estateAgentDetails.get('addedByUserId')!.valueChanges.subscribe(value => {

      this.userService.basicUserDetails(value).subscribe(result => {
        this.estateAgentDetails.controls["addedByUser"].patchValue(result);
      });
    });

  }

  ngOnInit(): void {
  }

  updateField(){


    this.caseService.updateConveyancingSaleEstateAgentDetails(this.estateAgentDetails.value).subscribe((response : any) => {

    }, (error : any) => {


    });

  }

  createLetters(){

    this.generatingLetters = true;

    this.lettersService.generateLettersForGroup(this.caseId, 6).subscribe((response : any) => {

      this.correspondenceTimelineService.refreshCaseTimeline.next();
      this.close();


    }, (error : any) => {


    });
  }

  close(){

    if(this.comp.objectsDifferent(this.initialForm.value, this.estateAgentDetails.value))
    {
      this.auditService.auditForm(this.caseId, 'EstateAgentDetails', this.initialForm.value, this.estateAgentDetails.value).subscribe((response : any) => {
        this.dialogRef.close();
      });
    }
    else
      this.dialogRef.close();
  }

  openAuditForForm(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      caseId: this.caseId,
      formName: "EstateAgentDetails"
    };

    this.dialog.open(AuditFormComponent, dialogConfig);

  }

}
