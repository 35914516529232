import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { InvoiceService } from 'src/app/shared/services/invoice.service';
import { KlyantService } from 'src/app/shared/services/klyant.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PreInvoiceFeesService } from '../../../../shared/services/pre-invoice-fees.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pre-invoice-fee',
  templateUrl: './add-pre-invoice-fee.component.html',
  styleUrls: ['./add-pre-invoice-fee.component.css']
})
export class AddPreInvoiceFeeComponent implements OnInit {

  caseId : number;
  feeTypes: any;
  selectedFeeType: any;
  methodsOfVerification: any;
  supplierList: any = [];
  officeAccountList: any = [];
  vatRate: number = 0;
  addingFee: boolean = false;

  newFeeDetails = this.fb.group({
    feeId: [null],
    netAmount: [null, Validators.required],    
    methodOfVerificationId: [null, Validators.required],
    hasVat: [null],
    vatAmount: [null],
    description: [null],
    // Klyant-specific fields
    outlay_reference: [null, Validators.required],
    outlay_date: [null, Validators.required],
    nominal_code: [null, Validators.required],
    supplier_id: [null, Validators.required]
  }, { });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private preInvoiceFeesService: PreInvoiceFeesService,
              private klyantService: KlyantService,
              private invoiceService: InvoiceService,
              public dialogRef: MatDialogRef<AddPreInvoiceFeeComponent>,
              private snackBar: MatSnackBar) {
    this.caseId = data.caseId;
  }

  ngOnInit(): void {
    this.invoiceService.getFeeTypesForCase(this.caseId, 2).subscribe((feeTypes:any) => { // Just interested in disbursements
      this.feeTypes = feeTypes.fees;
      this.preInvoiceFeesService.getMethodsOfVerification().subscribe((results:any) => {
        this.methodsOfVerification = results.methodsOfVerification;  
        this.newFeeDetails.controls['methodOfVerificationId'].patchValue(environment.klyant.defaultMethodOfVerificationId);
        this.klyantService.getSuppliers().subscribe((results) => {
          this.supplierList = results;
          this.klyantService.getOfficeAccounts().subscribe((results) => {
            this.officeAccountList = results;
            this.newFeeDetails.controls['nominal_code'].patchValue(environment.klyant.defaultAccount);
            this.invoiceService.getVatRate().subscribe((rate) => {
              this.vatRate = rate;
              // Default date
              this.newFeeDetails.controls['outlay_date'].patchValue(DateTime.now());
              this.setDefaults();
            });
          }); 
        }); 
      });
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  setDefaults() {
    var thisFeeType = this.feeTypes.filter(x => x.id == this.newFeeDetails.controls['feeId'].value);
    if (thisFeeType && thisFeeType.length == 1) {
      this.selectedFeeType = thisFeeType;
      this.newFeeDetails.controls['supplier_id'].patchValue(thisFeeType[0].supplierId);
      this.newFeeDetails.controls['netAmount'].patchValue(thisFeeType[0].net);
      this.newFeeDetails.controls['hasVat'].patchValue(thisFeeType[0].includeVAT);  
      this.newFeeDetails.controls['description'].patchValue(thisFeeType[0].description);
      this.newFeeDetails.controls['outlay_reference'].patchValue(thisFeeType[0].description + '-' + this.newFeeDetails.controls['outlay_date'].value.toFormat('dd/MM/yyyy'));     
    }
    else {
      this.selectedFeeType = null;
      this.newFeeDetails.controls['supplier_id'].patchValue(null);
      this.newFeeDetails.controls['netAmount'].patchValue(null);
      this.newFeeDetails.controls['hasVat'].patchValue(null);  
      this.newFeeDetails.controls['description'].patchValue(null);
      this.newFeeDetails.controls['outlay_reference'].patchValue(null);
    }
  }

  addFee() {    

    this.addingFee = true;
    let netAmount = this.newFeeDetails.controls['netAmount'].value;
    let hasVat = this.newFeeDetails.controls['hasVat'].value;

    if (hasVat) {
      this.newFeeDetails.controls['vatAmount'].patchValue((netAmount / 100) * this.vatRate);
    }
    else {
      this.newFeeDetails.controls['vatAmount'].patchValue(0);
    }

    this.preInvoiceFeesService.addFee(this.caseId,  this.newFeeDetails.value).subscribe((response : any) => {

      let piFeeId = response.preInvoiceFeeId;

      let outlay = {
        organisation_id: 0, // Set by API
        outlay_reference: this.newFeeDetails.controls['outlay_reference'].value,
        outlay_date: this.newFeeDetails.controls['outlay_date'].value.toJSON(),
        outlay_description: this.newFeeDetails.controls['outlay_reference'].value,
        outlay_total: netAmount,
        nominal_code: this.newFeeDetails.controls['nominal_code'].value,
        supplier_id: this.newFeeDetails.controls['supplier_id'].value,
        line_items: [
          {
            matter_id: 0, // Set by API
            line_detail: this.newFeeDetails.controls['outlay_reference'].value,
            line_net: netAmount,
            line_vat_rate: hasVat ? this.vatRate : 0
          }
        ],
        preInvoiceFeeId: piFeeId
      };

      this.addingFee = false;
      this.dialogRef.close();

      /*
      this.klyantService.addOutlay(this.caseId, outlay).subscribe(response => { // Don't think it should be sent right away - needs to be assoiated with an invoice
        if(!isNaN(response)) {
          this.openSnackBar("Fee added and Outlay added to Klyant!", 'Close');
          this.dialogRef.close();
        }
        else {
          this.openSnackBar("There was a problem adding the Outlay to Klyant: " + response, 'Close');
        }
      }); */    
    });
  }
}
