import { Component, OnInit, Inject } from '@angular/core';
import { CaseService } from '../../../shared/services/case.service';
import { LookupsService } from '../../../shared/services/lookups.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { UserService } from '../../../shared/services/user.service';
import { ComparerService } from '../../../shared/utility/comparer';
import { AuditService } from '../../../shared/services/audit.service';
import { faHistory} from '@fortawesome/pro-light-svg-icons';
import { AuditFormComponent } from '../../audit-form/audit-form.component';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-broker-details',
  templateUrl: './broker-details.component.html',
  styleUrls: ['./broker-details.component.css']
})
export class BrokerDetailsComponent implements OnInit {

  caseId: number;
  viewInit = false;
  faUsers: any[] = [];

  faHistory = faHistory;

  brokerDetails = this.fb.group({
    equityReleaseCaseId: [],
    caseId: [],
    faUser:[],
    faUserId: [],
    financialAdvisorCompanyName: '',
    brokerReference: '',
    financialAdvisorFullName: '',
    financialAdvisorContactNumber: '',
    financialAdvisorFee: []
  }, { });

  initialForm = this.fb.group({
    equityReleaseCaseId: [],
    caseId: [],
    faUser:[],
    faUserId: [],
    financialAdvisorCompanyName: '',
    brokerReference: '',
    financialAdvisorFullName: '',
    financialAdvisorContactNumber: '',
    financialAdvisorFee: []
  }, { });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<BrokerDetailsComponent>,
  private caseService: CaseService,
  private userService: UserService,
  private fb: FormBuilder,
  private comp: ComparerService,
  public dialog: MatDialog,
  private auditService: AuditService,
  public authService: AuthService) {
    this.caseId = data.caseId;

    this.caseService.getEquityReleaseBrokerDetails(this.caseId).subscribe(response => {

      this.brokerDetails.patchValue(response.brokerDetails);
      this.initialForm.patchValue(response.brokerDetails);

      this.userService.companyGroupUsers(response.brokerDetails.financialAdvisorCompanyId).subscribe(result => {
        this.faUsers = result;
      });
    });


    this.brokerDetails.get('faUserId')!.valueChanges.subscribe(value => {

      this.userService.basicUserDetails(value).subscribe(result => {
        this.brokerDetails.controls["faUser"].patchValue(result);
      });

  });


  }

  ngOnInit(): void {
  }

  close(){

    if(this.comp.objectsDifferent(this.initialForm.value, this.brokerDetails.value))
    {
      this.auditService.auditForm(this.caseId, 'BrokerDetails', this.initialForm.value, this.brokerDetails.value).subscribe((response : any) => {
        this.dialogRef.close();
      });
    }
    else
      this.dialogRef.close();
  }

  openAuditForForm(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      caseId: this.caseId,
      formName: "BrokerDetails"
    };

    this.dialog.open(AuditFormComponent, dialogConfig);

  }

  updateField(){

    this.caseService.updateEquityReleaseBrokerDetails(this.brokerDetails.value).subscribe((response : any) => {

    }, (error : any) => {

        /*
        var errorMessage = "Failed to update case";
        if(error.error !== null && error.error.message !== undefined)
        {
        errorMessage = error.error.message;
        }*/

    });
  }


}
