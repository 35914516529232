<button mat-icon-button class="close" tabindex="-1" mat-dialog-close *ngIf="!generatingLetters">x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Generate Letters</h2>

<mat-dialog-content class="mat-typography" *ngIf="!generatingLetters">

    <app-card-label label="Letter Type" *ngIf="!otherLettersOnly">
        <mat-form-field floatLabel="never" class="lettersField" >
            <mat-select  [(ngModel)]="letterGroupType" (selectionChange)="loadLettersForType()">
                <mat-option value="1" >Case Letters</mat-option>
                <mat-option value="2" *ngIf="caseTypeId == 1 || caseTypeId == 6 || caseTypeId == 7 || caseTypeId == 8">Admin Letters</mat-option>
                <mat-option value="3" *ngIf="caseTypeId == 1">Mortgage Letters</mat-option>                
                <mat-option value="4">Other Letters</mat-option>
            </mat-select>
        </mat-form-field>
      </app-card-label>

      <app-card-label label="Search" *ngIf="letters.length > 0">
        <mat-form-field class="lettersField" floatLabel="never">
          <input type="text" matInput
                 [(ngModel)]="searchKeyword"
                 placeholder="Search...">
        </mat-form-field>
      </app-card-label>

      <div class="list" *ngIf="letters.length > 0">
          <div class="box" *ngFor="let letter of letters | letterSearch: searchKeyword">
            <app-letter-template [details]="letter" ></app-letter-template>
          </div>
        </div>

</mat-dialog-content>

<mat-dialog-content class="mat-typography" style="text-align: center;" *ngIf="generatingLetters && !generatingError">
    <h3>Generating Letters - Please do not leave the page</h3>
    <div class="progress">
      <mat-spinner color="primary" diameter="50">
      </mat-spinner>
    </div>
</mat-dialog-content>

<mat-dialog-content class="mat-typography" style="text-align: center;" *ngIf="generatingError">
  <h3 class="error">An Error Occurred</h3>
  <div class="error">
    {{errorMessage}}
  </div>
</mat-dialog-content>

<div mat-dialog-actions align="end">
    <button mat-stroked-button  cdkFocusInitial (click)="generateLetters()" *ngIf="!generatingLetters" color="primary" [disabled]="!atLeastOneItemSelected()">Generate Letters</button>
</div>
