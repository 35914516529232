
  <div class="body" [ngClass]="{'small': isSmall}">

    <div class="sidebar" *ngIf="!isSmall || (isSmall && showSidebar)">
      <app-case-sidebar *ngIf="caseLoaded" [caseId]="caseId" [caseData]="case.caseData"></app-case-sidebar>
    </div>



    <mat-spinner [diameter]="70" class="timelinespinner" color="primary" diameter="20" *ngIf="timelineItemsLoading"></mat-spinner>
    <div class="content"  (dragover)="dragOver($event)" (dragleave)="dragLeave($event)" (drop)="dragDrop($event)" *ngIf="!timelineItemsLoading">



      <div class="inner-content" #innerContent  [ngClass]="{'medium': isMedium}">


        <div class="inner-inner-content">
          <app-case-header *ngIf="showCaseHeader" class="header" [message]="caseHeaderMessage" [warning]="caseHeaderWarning" [info]="caseHeaderInfo" [alert]="caseHeaderAlert" [overrideBackgroundColour]="caseHeaderColour"></app-case-header>

          <div class="timeline-header">
            <button mat-icon-button (click)="allowMultiSelect = true" *ngIf="!allowMultiSelect"><fa-icon [icon]="faCheckSquare" size="lg"></fa-icon></button>
            <button mat-icon-button (click)="cancelMultiSelect()" *ngIf="allowMultiSelect"><fa-icon [icon]="faTimes" size="lg"></fa-icon></button>
            <button mat-icon-button *ngIf="allowMultiSelect" [disabled]="!atLeastTwoItemsSelected()" (click)="onContextMenu($event, null)"><fa-icon [icon]="faEllipsisH" size="lg"></fa-icon></button>


            <div class="search">
                <label class="searchlabel">Show Types:</label>
                    <mat-form-field floatLabel="never" class="searchtypefield">
                      <mat-select  [(ngModel)]="searchType" multiple>
                          <mat-option value="1" class="email">Email</mat-option>
                          <mat-option value="2" class="letter">Letter</mat-option>
                          <mat-option value="3" class="casefile">Case File</mat-option>
                          <mat-option value="4" class="phonecall">Phonecall</mat-option>
                          <mat-option value="5" class="textmessage">Text Message</mat-option>
                          <mat-option value="6"class="fax">Fax</mat-option>
                          <mat-option value="8"class="note">Note</mat-option>
                          <mat-option value="9"class="keydate">Key Date</mat-option>
                          <mat-option value="10"class="onhold">On Hold</mat-option>
                          <mat-option value="11"class="offhold">Off Hold</mat-option>
                      </mat-select>
                    </mat-form-field>

                  <label class="searchlabel">Search Text:</label>
                  <mat-form-field class="searchfield">
                      <input matInput value="" [(ngModel)]="searchString" >
                    </mat-form-field>
                    <button mat-icon-button (click)="searchTimeline()"><fa-icon [icon]="faSearch" size="lg"></fa-icon></button>
                    <button mat-icon-button (click)="clearSearch()"><fa-icon [icon]="faBackspace" size="lg"></fa-icon></button>
                </div>

                <div id="archivedItems" *ngIf="authService.isPortalManager()">
                  <button mat-icon-button id="archivedItemsButton" (click)="viewArchivedItems()"><fa-icon [icon]="faHistory" size="lg"></fa-icon></button>
                </div>

          </div>

          <div class="inner-inner-timeline-content">

          <div class="add-files" *ngIf="showDrop">Add Files</div>

          <div *ngIf="correspondenceTimelineItems" class="itemlist">
              <mat-list *ngFor="let item of correspondenceTimelineItems" >
                <span *ngIf="item.pinned" class="pinnedicon"><fa-icon [icon]="faThumbtack" size="lg"></fa-icon></span>
                  <mat-list-item (contextmenu)="onContextMenu($event, item)" (dblclick)="dblClickItem(item)" [ngClass]="{'selectedhighlight': selectedItem === item}">
                     <app-timeline-item #timelineitem [details]="item" [selectable]="allowMultiSelect"></app-timeline-item>
                  </mat-list-item>
              </mat-list>

        <div style="visibility: hidden; position: fixed"
            [style.left]="contextMenuPosition.x"
            [style.top]="contextMenuPosition.y"
            [matMenuTriggerFor]="contextMenu">
        </div>

        <mat-menu #contextMenu="matMenu" >
            <ng-template matMenuContent let-item="item">

               <span *ngIf="item">
                <button mat-menu-item (click)="previewDocument(item)" *ngIf="item.caseFile != null && (item.caseFile.format == 1 || item.caseFile.format == 2)">
                  <span>Preview</span>
                </button>
                <button mat-menu-item (click)="openRename(item)" *ngIf="item.correspondenceTypeId == 1 || item.correspondenceTypeId == 2 || item.correspondenceTypeId == 3 || item.correspondenceTypeId == 5 || item.correspondenceTypeId == 6">
                  <span>Rename</span>
                </button>
                <button mat-menu-item (click)="emailItems(item)" *ngIf="item.correspondenceTypeId == 2 || item.correspondenceTypeId == 3">
                  <span>Email Item</span>
                </button>
                <button mat-menu-item (click)="faxItems(item)" *ngIf="item.correspondenceTypeId == 2 || item.correspondenceTypeId == 3">
                  <span>Fax Item</span>
                </button>
                <button mat-menu-item (click)="generatePDF(item)" *ngIf="item.correspondenceTypeId == 2 || (item.caseFile != null &&  item.caseFile.format == 2) || item.correspondenceTypeId == 4 || item.correspondenceTypeId == 5">
                  <span>Generate PDF</span>
                </button>
                <button mat-menu-item (click)="pinItem(item)" *ngIf="!item.pinned">
                   <span>Pin Item</span>
                </button>
                <button mat-menu-item (click)="unpinItem(item)" *ngIf="item.pinned">
                  <span>Unpin</span>
                </button>
                <button mat-menu-item (click)="highlightItem(item)" *ngIf="!item.highlighted">
                  <span>Highlight Item</span>
               </button>
               <button mat-menu-item (click)="unhighlightItem(item)" *ngIf="item.highlighted">
                 <span>Unhighlight Item</span>
               </button>
                <button mat-menu-item (click)="archiveItems(item)" *ngIf="item.correspondenceTypeId != 9 && item.correspondenceTypeId != 10"> <!-- don't allow key date and to be archived - must be done from key dates screen -->
                    <span>Archive Item</span>
                </button>
              </span>

              <span *ngIf="!item">
                  <button mat-menu-item (click)="openPDFStitcher()">
                    <span>Generate PDF</span>
                  </button>
                  <button mat-menu-item (click)="emailItems(null)">
                    <span>Email Items</span>
                  </button>
                  <button mat-menu-item (click)="faxItems(null)" >
                    <span>Fax Items</span>
                  </button>
                  <button mat-menu-item (click)="archiveItems(null)">
                      <span>Archive Items</span>
                    </button>

                </span>


            </ng-template>
        </mat-menu>

      </div>
        </div>

      </div>

        <div class="info" *ngIf="caseLoaded">

          <app-info [case]="case" [isMedium]="isMedium" [isSmall]="isSmall"></app-info>
            <!--button *ngIf="case.caseData.typeId != 0" mat-flat-button (click)="openPortal1Case()" color="primary" >
              View In Portal 1
            </button-->
            <!--button *ngIf="!case.caseData.isCancelled && !case.caseData.onHold && !case.caseData.isCompleted && case.caseData.allocatedToParalegalUser" mat-raised-button color="primary" class="holdbutton"
              (click)=" putCaseOnHold()"><fa-icon [icon]="faPause"></fa-icon></button>
            <button *ngIf="case.caseData.onHold" mat-raised-button color="accent" class="holdbutton"
              (click)="offHoldCase()"><fa-icon [icon]="faPlay"></fa-icon></button>
              <br /><br /-->
              <!-- <button mat-flat-button (click)="redirectToKlyant()" color="primary" [disabled]="klyantEnabled == false">
                Save to Klyant
              </button> -->
              <!--button mat-flat-button color="primary" [disabled]="klyantEnabled == false || klyantDetails.klyantMatterId != null" (click)="loadKlyant()"-->

              <button *ngIf="case.caseData.klyantMatterId == null" mat-flat-button color="primary" [disabled]="klyantEnabled == false" (click)="loadKlyant()">
                Save to Klyant
              </button>
              <button *ngIf="case.caseData.klyantMatterId != null" mat-flat-button color="primary" [disabled]="klyantEnabled == false" (click)="viewInKlyant()">
                View in Klyant
              </button>
        </div>

      </div>

      <router-outlet></router-outlet>


    </div>
  </div>
