import { Component, OnInit, Inject } from '@angular/core';
import { faConstruction} from '@fortawesome/pro-light-svg-icons';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PreexchangeChecklistService } from 'src/app/shared/services/preexchange-checklist.service';
import { LettersService } from 'src/app/shared/services/letters.service';
import { CorrespondenceTimelineService } from 'src/app/shared/services/correspondence-timeline.service';


@Component({
  selector: 'app-preexchange-checklist',
  templateUrl: './preexchange-checklist.component.html',
  styleUrls: ['./preexchange-checklist.component.css']
})
export class PreexchangeChecklistComponent implements OnInit {

  faConstruction = faConstruction;

  caseId: number = -1;

  generatingPDF: boolean = false;
  error: boolean = false;
  errorMessage: string = "";

  items: any [] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
    public preexchangeChecklistService: PreexchangeChecklistService,
    public lettersService: LettersService,
    public dialogRef: MatDialogRef<PreexchangeChecklistComponent>,
    private correspondenceTimelineService: CorrespondenceTimelineService) {

      this.caseId = componentData.caseId;

       // Get the items
       this.preexchangeChecklistService.getChecklistItemsForCase(this.caseId).subscribe((response : any) => {

        this.items = response.items;

      }, (error: any) => {

        this.error = true;
        if(error.status == 999)
        this.errorMessage = error.error;
      else if(error.status == 500 )
        this.errorMessage = "Internal Error";
      else
        this.errorMessage = error.statusText;
      });
    }

  ngOnInit(): void {
  }

  generatePDF(){
    this.lettersService.generatePreExchangeChecklist(this.caseId).subscribe((response : any) => {
      this.dialogRef.close();
      this.correspondenceTimelineService.refreshCaseTimeline.next();
    }, (error: any) => {
      this.error = true;
      if(error.status == 999)
        this.errorMessage = error.error;
      else if(error.status == 500 )
        this.errorMessage = "Internal Error";
      else
        this.errorMessage = error.statusText;
    });  
  }

  updateValue(item: any){

    this.preexchangeChecklistService.updateItem(this.caseId, item.id, item.checklistResponseType).subscribe((response : any) => {

      this.items.forEach((i : any) => {

        if(response.id == i.id)
        {
          i.byUser = response.byUser;
          i.dateEntered = response.dateEntered;
        }
      });


    }, (error: any) => {

      this.error = true;
      if(error.status == 999)
        this.errorMessage = error.error;
      else if(error.status == 500 )
        this.errorMessage = "Internal Error";
      else
        this.errorMessage = error.statusText;
    });
  }

  updateCommentary(item: any){

    this.preexchangeChecklistService.updateCommentary(this.caseId, item.id, item.commentary).subscribe((response : any) => {


    }, (error: any) => {

      this.error = true;
      if(error.status == 999)
        this.errorMessage = error.error;
      else if(error.status == 500 )
        this.errorMessage = "Internal Error";
      else
        this.errorMessage = error.statusText;
    });
  }

  clearValue(item: any){
    item.checklistResponseType = null;
    this.updateValue(item);
  }

}
