<mat-tab-group animationDuration="0ms">

  <mat-tab label="Internal Users" *ngIf="authService.canAdminInternalUsers()">

    <ng-template matTabContent>
          <app-users-list [internalUsers]="true" [sectionTitle]="'Internal Users'" *ngIf="authService.canAdminInternalUsers()"></app-users-list>
         
    </ng-template>

  </mat-tab>

  <mat-tab label="External Users" >
    <ng-template matTabContent>
      <app-users-list [internalUsers]="false" [sectionTitle]="'External Users'"></app-users-list>
    </ng-template>
  </mat-tab>

</mat-tab-group>



