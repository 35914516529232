import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CaseBankDetailsService } from '../../../shared/services/case-bank-details.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss']
})
export class BankDetailsComponent implements OnInit {

  caseId : number;

  bankDetails = this.fb.group({
    id: null,
    nameOnCard: '',
    bankName: '',
    accountNumber: '',
    sortCode: ''
  }, { });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private caseBankDetailsService: CaseBankDetailsService,
              private fb: FormBuilder,
              public dialog: MatDialog) {

    this.caseId = data.caseId;
    this.loadDetails();
  }

  ngOnInit(): void {
  }

  loadDetails(){
    this.caseBankDetailsService.getBankDetails( this.caseId ).subscribe((response : any) => {
      this.bankDetails.patchValue(response.bankDetails);
    });
  }

  updateField(){

    this.caseBankDetailsService.updateBankDetails(this.caseId, this.bankDetails.value).subscribe((response : any) => {

    }, (error : any) => {


    });
  }
}
