import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-manual-check',
  templateUrl: './manual-check.component.html',
  styleUrls: ['./manual-check.component.css']
})
export class ManualCheckComponent implements OnInit {

  manualCheck = this.fb.group({
    date: new Date(),
    notes: null
  }, {});

  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ManualCheckComponent>) { }

  ngOnInit(): void {
  }

  onConfirm() {
    this.dialogRef.close(this.manualCheck.value);
  }

  onDateChange(newDate: Date) {
    this.manualCheck.controls['date'].setValue(newDate);
  }

  close() {
    this.dialogRef.close(null);
  }

}
