import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CaseService } from '../../../../shared/services/case.service';
import { FormBuilder } from '@angular/forms';
import { ComparerService } from 'src/app/shared/utility/comparer';
import { AuditService } from '../../../../shared/services/audit.service';
import { faHistory, faExternalLink} from '@fortawesome/pro-light-svg-icons';
import { AuditFormComponent } from 'src/app/case/audit-form/audit-form.component';

@Component({
  selector: 'app-conveyancing-purchase-property-details',
  templateUrl: './conveyancing-purchase-property-details.component.html',
  styleUrls: ['./conveyancing-purchase-property-details.component.css']
})
export class ConveyancingPurchasePropertyDetailsComponent implements OnInit {

  caseId: number;
  dataLoaded: boolean = false;
  viewInit = false;
  faHistory = faHistory;
  faExternalLink = faExternalLink;

  propertyDetails = this.fb.group({
    id: [],
    caseId: [],
    purchaseAddressLine1 : [],
    purchaseAddressLine2 : [],
    purchaseAddressLine3 : [],
    purchaseAddressLine4 : [],
    purchaseAddressLine5 : [],
    purchasePostcode : [],
    propertyIsUnregistered : [],
    titleNumber : [],
    propertyType : [],
    landlordName : [],
    landlordAddressLine1 : [],
    landlordAddressLine2 : [],
    landlordAddressLine3 : [],
    landlordAddressLine4 : [],
    landlordAddressLine5 : [],
    landlordPostcode : [],
    managementCompanyName : [],
    managementCompanyAddressLine1 : [],
    managementCompanyAddressLine2 : [],
    managementCompanyAddressLine3 : [],
    managementCompanyAddressLine4 : [],
    managementCompanyAddressLine5 : [],
    managementCompanyPostcode : [],
    solarPanelsPresent : [],
    solarPanelsOwnedOutright  : [],
    solarPanelsCompany  : [],
    solarPanelsOwnerAddressLine1 : [],
    solarPanelsOwnerAddressLine2 : [],
    solarPanelsOwnerAddressLine3 : [],
    solarPanelsOwnerAddressLine4 : [],
    solarPanelsOwnerAddressLine5 : [],
    solarPanelsOwnerPostcode : [],
    hasSepticTank : [],
    agreedPurchasePrice : [],
    sellerSolicitorName : [],
    sellerSolicitorEmailAddress : [],
    sellerSolicitorContactNumber : [],
    seller1Name : [],
    seller2Name : [],
    sellerSolicitorAddressLine1 : [],
    sellerSolicitorAddressLine2 : [],
    sellerSolicitorAddressLine3 : [],
    sellerSolicitorAddressLine4 : [],
    sellerSolicitorAddressLine5 : [],
    sellerSolicitorPostcode : [],
    sellerSolicitorReference : [],
    titleGuaranteeType: [],
    chattels: [],
    stampDuty : []
  }, { });

  initialDetails = this.fb.group({
    id: [],
    caseId: [],
    purchaseAddressLine1 : [],
    purchaseAddressLine2 : [],
    purchaseAddressLine3 : [],
    purchaseAddressLine4 : [],
    purchaseAddressLine5 : [],
    purchasePostcode : [],
    propertyIsUnregistered : [],
    titleNumber : [],
    propertyType : [],
    landlordName : [],
    landlordAddressLine1 : [],
    landlordAddressLine2 : [],
    landlordAddressLine3 : [],
    landlordAddressLine4 : [],
    landlordAddressLine5 : [],
    landlordPostcode : [],
    managementCompanyName : [],
    managementCompanyAddressLine1 : [],
    managementCompanyAddressLine2 : [],
    managementCompanyAddressLine3 : [],
    managementCompanyAddressLine4 : [],
    managementCompanyAddressLine5 : [],
    managementCompanyPostcode : [],
    solarPanelsPresent : [],
    solarPanelsOwnedOutright  : [],
    solarPanelsCompany  : [],
    solarPanelsOwnerAddressLine1 : [],
    solarPanelsOwnerAddressLine2 : [],
    solarPanelsOwnerAddressLine3 : [],
    solarPanelsOwnerAddressLine4 : [],
    solarPanelsOwnerAddressLine5 : [],
    solarPanelsOwnerPostcode : [],
    hasSepticTank : [],
    agreedPurchasePrice : [],
    sellerSolicitorName : [],
    sellerSolicitorEmailAddress : [],
    sellerSolicitorContactNumber : [],
    seller1Name : [],
    seller2Name : [],
    sellerSolicitorAddressLine1 : [],
    sellerSolicitorAddressLine2 : [],
    sellerSolicitorAddressLine3 : [],
    sellerSolicitorAddressLine4 : [],
    sellerSolicitorAddressLine5 : [],
    sellerSolicitorPostcode : [],
    sellerSolicitorReference : [],
    titleGuaranteeType: [],
    chattels: [],
    stampDuty : []
  }, { });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private caseService: CaseService,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<ConveyancingPurchasePropertyDetailsComponent>,
        public dialog: MatDialog,
        private comp: ComparerService,
        private auditService: AuditService) {

          this.caseId = data.caseId;

          this.caseService.getConveyancingPurchasePropertyDetails(this.caseId).subscribe((response : any) => {

            this.propertyDetails.patchValue(response.propertyDetails);
            this.initialDetails.patchValue(response.propertyDetails);
            this.dataLoaded = true;
          });

        }

  ngOnInit(): void {

  }

  updateField(){

    this.caseService.updateConveyancingPurchasePropertyDetails(this.propertyDetails.value).subscribe((response : any) => {

    }, (error : any) => {


    });
  }

  close(){

    if(this.comp.objectsDifferent(this.initialDetails.value, this.propertyDetails.value))
    {
      this.auditService.auditForm(this.caseId, 'PropertyDetails', this.initialDetails.value, this.propertyDetails.value).subscribe((response : any) => {
        this.dialogRef.close();
      });
    }
    else
      this.dialogRef.close();
  }

  openAuditForForm(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      caseId: this.caseId,
      formName: "PropertyDetails"
    };

    this.dialog.open(AuditFormComponent, dialogConfig);

  }
}
