import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CorrespondenceTimelineService } from '../../../../shared/services/correspondence-timeline.service';

@Component({
  selector: 'app-archive-timeline-item',
  templateUrl: './archive-timeline-item.component.html',
  styleUrls: ['./archive-timeline-item.component.scss']
})
export class ArchiveTimelineItemComponent implements OnInit {

  caseId: number;
  reason: string = "";
  isLoading: boolean = false;

  itemIds: number [] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ArchiveTimelineItemComponent>,
  private correspondenceTimelineService: CorrespondenceTimelineService) {
    this.caseId = this.data.caseId;
    this.itemIds = this.data.itemIds;

   }

  ngOnInit(): void {
  }


  archiveItem(){

    this.isLoading = true;

        this.correspondenceTimelineService.archiveItems( this.itemIds, this.caseId, this.reason).subscribe(response => {
          this.dialogRef.close(true);
        });

  }
}
