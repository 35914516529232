import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SearchResult } from './interfaces/search-result';
import { SearchRequest } from './interfaces/search-request';
import { Observable } from 'rxjs/internal/Observable';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class CaseService {

  private caseUrl = environment.apiUrl + 'cases/';

  public refreshCase  = new EventEmitter();
  public refreshCaseObservable = this.refreshCase.asObservable();

  public caseAllocated = new EventEmitter();
  public caseAllocatedObservable = this.caseAllocated.asObservable();

  public refreshCaseNotes  = new EventEmitter();
  public refreshCaseNotesObservable = this.refreshCaseNotes.asObservable();

  public refreshCaseOverview  = new EventEmitter();
  public refreshCaseOverviewObservable = this.refreshCaseOverview.asObservable();

  public refreshCaseTimeline  = new EventEmitter();
  public refreshCaseTimelineObservable = this.refreshCaseTimeline.asObservable();

  constructor(private http: HttpClient) { }

  getCase(id: number) {
    return this.http.get<any>(this.caseUrl + id);
  }

  cancelCase(caseId: number, caseDetails: any){
    return this.http.post<any>(this.caseUrl + caseId + '/Cancel/', caseDetails, httpOptions );
  }

  cases(request: SearchRequest): Observable<SearchResult> {

    if (request.filterStrings.join() == '') {
      return this.http.get<SearchResult>(`${this.caseUrl}?page=${request.page}&pageSize=
      ${request.pageSize}&orderBy=${request.orderBy}&orderDirection=${request.orderDirection}`);
    }

    var queryString = '';

    for (var i = 0; i < request.filterStrings.length; i++) {
      if (request.filterStrings[i] !== '' && request.filterStrings[i] !== null) {
        queryString += `&filterColumns=${request.filterColumns[i]}&filterStrings=${request.filterStrings[i]}`
      }
    }

    return this.http.get<SearchResult>(`${this.caseUrl}?page=${request.page}&pageSize=
    ${request.pageSize}&orderBy=${request.orderBy}&orderDirection=${request.orderDirection}${queryString}`);

  }

  onHoldCases(request: SearchRequest): Observable<SearchResult> {

    if (request.filterStrings.join() == '') {
      return this.http.get<SearchResult>(`${this.caseUrl + 'OnHold'}?page=${request.page}&pageSize=
      ${request.pageSize}&orderBy=${request.orderBy}&orderDirection=${request.orderDirection}`);
    }

    var queryString = '';

    for (var i = 0; i < request.filterStrings.length; i++) {
      if (request.filterStrings[i] !== '' && request.filterStrings[i] !== null) {
        queryString += `&filterColumns=${request.filterColumns[i]}&filterStrings=${request.filterStrings[i]}`
      }
    }

    return this.http.get<SearchResult>(`${this.caseUrl + 'OnHold'}?page=${request.page}&pageSize=
    ${request.pageSize}&orderBy=${request.orderBy}&orderDirection=${request.orderDirection}${queryString}`);

  }

  cancelledCases(request: SearchRequest): Observable<SearchResult> {

    if (request.filterStrings.join() == '') {
      return this.http.get<SearchResult>(`${this.caseUrl + 'cancelled'}?page=${request.page}&pageSize=
      ${request.pageSize}&orderBy=${request.orderBy}&orderDirection=${request.orderDirection}`);
    }

    var queryString = '';

    for (var i = 0; i < request.filterStrings.length; i++) {
      if (request.filterStrings[i] !== '' && request.filterStrings[i] !== null) {
        queryString += `&filterColumns=${request.filterColumns[i]}&filterStrings=${request.filterStrings[i]}`
      }
    }

    return this.http.get<SearchResult>(`${this.caseUrl + 'cancelled'}?page=${request.page}&pageSize=
    ${request.pageSize}&orderBy=${request.orderBy}&orderDirection=${request.orderDirection}${queryString}`);

  }

  completedCases(request: SearchRequest): Observable<SearchResult> {

    if (request.filterStrings.join() == '') {
      return this.http.get<SearchResult>(`${this.caseUrl + 'completed'}?page=${request.page}&pageSize=
      ${request.pageSize}&orderBy=${request.orderBy}&orderDirection=${request.orderDirection}`);
    }

    var queryString = '';

    for (var i = 0; i < request.filterStrings.length; i++) {
      if (request.filterStrings[i] !== '' && request.filterStrings[i] !== null) {
        queryString += `&filterColumns=${request.filterColumns[i]}&filterStrings=${request.filterStrings[i]}`
      }
    }

    return this.http.get<SearchResult>(`${this.caseUrl + 'completed'}?page=${request.page}&pageSize=
    ${request.pageSize}&orderBy=${request.orderBy}&orderDirection=${request.orderDirection}${queryString}`);

  }

  unallocatedCases(request: SearchRequest): Observable<SearchResult> {

    if (request.filterStrings.join() == '') {
      return this.http.get<SearchResult>(`${this.caseUrl + 'unallocated'}?page=${request.page}&pageSize=
      ${request.pageSize}&orderBy=${request.orderBy}&orderDirection=${request.orderDirection}`);
    }

    var queryString = '';

    for (var i = 0; i < request.filterStrings.length; i++) {
      if (request.filterStrings[i] !== '' && request.filterStrings[i] !== null) {
        queryString += `&filterColumns=${request.filterColumns[i]}&filterStrings=${request.filterStrings[i]}`
      }
    }

    return this.http.get<SearchResult>(`${this.caseUrl + 'unallocated'}?page=${request.page}&pageSize=
    ${request.pageSize}&orderBy=${request.orderBy}&orderDirection=${request.orderDirection}${queryString}`);
  }

  uninstructedCases(request: SearchRequest): Observable<SearchResult> {

    if (request.filterStrings.join() == '') {
      return this.http.get<SearchResult>(`${this.caseUrl + 'uninstructed'}?page=${request.page}&pageSize=
      ${request.pageSize}&orderBy=${request.orderBy}&orderDirection=${request.orderDirection}`);
    }

    var queryString = '';

    for (var i = 0; i < request.filterStrings.length; i++) {
      if (request.filterStrings[i] !== '' && request.filterStrings[i] !== null) {
        queryString += `&filterColumns=${request.filterColumns[i]}&filterStrings=${request.filterStrings[i]}`
      }
    }

    return this.http.get<SearchResult>(`${this.caseUrl + 'uninstructed'}?page=${request.page}&pageSize=
    ${request.pageSize}&orderBy=${request.orderBy}&orderDirection=${request.orderDirection}${queryString}`);

  }

  archiveCase(caseId: number, caseDetails: any){
    return this.http.post<any>(this.caseUrl + caseId + '/archive/', caseDetails, httpOptions );
  }

  putCaseOnHold(caseId: number, onHoldDetails: any){
    return this.http.post<any>(this.caseUrl + caseId + '/OnHold/', onHoldDetails, httpOptions );
  }

  offHoldCase(caseId: number, offHoldDetails: any){
    return this.http.post<any>(this.caseUrl + caseId + '/OffHold/', offHoldDetails, httpOptions );
  }

  allocateCase(caseId: number, paralegalUserId: number, adminUserId: number){
    return this.http.post<any>(this.caseUrl + caseId + '/AllocateToUsers/' + paralegalUserId + '/' + adminUserId, httpOptions );
  }


  reallocateCases(paralegalUserId: number, adminUserId: number, caseIds: number[]){

    let params = '';

    for (const caseId of caseIds) {
      params += `&caseIds=${caseId}`
    }

    params = params.substring(1);

    return this.http.post<any>(`${this.caseUrl}Reallocate/${paralegalUserId}/${adminUserId}`, caseIds,  httpOptions );
  }

  reallocateTasks(paralegalUserId : number, adminUserId : number, noOfDays : number, caseIds: number[]){

    let params = '';

    for (const caseId of caseIds) {
      params += `&caseIds=${caseId}`
    }

    params = params.substring(1);

    return this.http.post<any>(`${this.caseUrl}ReallocateTasks/${paralegalUserId}/${adminUserId}/${noOfDays}`, caseIds,  httpOptions );
  }

  reallocateAdminTasks(adminUserId : number, caseIds: number[]){

    let params = '';

    for (const caseId of caseIds) {
      params += `&caseIds=${caseId}`
    }

    params = params.substring(1);

    return this.http.post<any>(`${this.caseUrl}ReallocateAdminTasks/${adminUserId}`, caseIds,  httpOptions );
  }

  reallocateParalegalTasks(paralegalUserId: number, caseIds: number[]){

    let params = '';

    for (const caseId of caseIds) {
      params += `&caseIds=${caseId}`
    }

    params = params.substring(1);

    return this.http.post<any>(`${this.caseUrl}ReallocateParalegalTasks/${paralegalUserId}`, caseIds,  httpOptions );
  }

  getCaseNotes(caseId: (number | undefined)){
    return this.http.get<any>(this.caseUrl + caseId + '/internalcasenotes/' );
  }

  getCaseNote(caseId: (number | undefined), caseNoteId: number){
    return this.http.get<any>(this.caseUrl + caseId + '/casenotes/' + caseNoteId );
  }

  getAllCaseNotes(caseId: (number | undefined)){
    return this.http.get<any>(this.caseUrl + caseId + '/allinternalcasenotes/' );
  }

  deleteCaseNote(caseId: (number | undefined), caseNoteId: number){
    return this.http.get<any>(this.caseUrl + caseId + '/deleteinternalcasenote/' + caseNoteId , httpOptions );
  }

  addCaseNote(caseId : (number | undefined), caseNote: any){
    return this.http.put<any>(this.caseUrl + caseId + '/internalcasenotes/', caseNote, httpOptions);
  }

  updateCaseNote(caseId : (number | undefined), caseNoteId: number, caseNote: any){
    return this.http.post<any>(this.caseUrl + caseId + '/internalcasenotes/' + caseNoteId, caseNote, httpOptions);
  }

  addPhoneCall(caseId : (number | undefined), phoneCall: any){
    return this.http.put<any>(this.caseUrl + caseId + '/phonecall/', phoneCall, httpOptions);
  }

  editPhoneCall(caseId : (number | undefined), phoneCallId: number, phoneCall: any){
    return this.http.post<any>(this.caseUrl + caseId + '/phonecall/' + phoneCallId, phoneCall, httpOptions);
  }

  getCaseOverviewItems(caseId: (number | undefined)){
    return this.http.get<any>(this.caseUrl + caseId + '/caseoverviewitems/' );
  }

  getAllCaseOverviewItems(caseId: (number | undefined)){
    return this.http.get<any>(this.caseUrl + caseId + '/allcaseoverviewitems/' );
  }

  deleteCaseOverviewItem(caseId: (number | undefined), itemId: number){
    return this.http.get<any>(this.caseUrl + caseId + '/deletecaseoverviewitem/' + itemId , httpOptions );
  }

  addCaseOverviewItem(caseId : (number | undefined), item: any){
    return this.http.put<any>(this.caseUrl + caseId + '/caseoverviewitem/', item, httpOptions);
  }

  getCorrespondenceTimelineItems(caseId: (number | undefined)){
    return this.http.get<any>(this.caseUrl + caseId + '/timelineitems/' );
  }

  getEquityReleaseMortgageDetails(caseId: (number | undefined)){
    return this.http.get<any>(this.caseUrl + caseId + '/equityreleasemortgagedetails/' );
  }

  getEquityReleaseMortgageDetailsSpecialConditions(id: (number | undefined)){
    return this.http.get<any>(this.caseUrl + 'equityreleasemortgagedetails/' + id + '/specialconditions' );
  }

  getEquityReleaseMortgageDetailsOccupiers(id: (number | undefined)){
    return this.http.get<any>(this.caseUrl + 'equityreleasemortgagedetails/' + id + '/occupiers' );
  }

  updateEquityReleaseMortgageDetails(caseDetails: any) {
    return this.http.post<any>(this.caseUrl + 'equityreleasemortgagedetails', caseDetails, httpOptions);
  }

  getEquityReleaseBrokerDetails(caseId: (number | undefined)){
    return this.http.get<any>(this.caseUrl + caseId + '/equityreleasebrokerdetails/' );
  }

  updateEquityReleaseBrokerDetails(brokerDetails: any) {

    let formData = {
      equityReleaseCaseId: brokerDetails.equityReleaseCaseId,
      caseId : brokerDetails.caseId,
      faUserId : brokerDetails.faUserId,
      brokerReference: brokerDetails.brokerReference,
      financialAdvisorContactNumber : brokerDetails.financialAdvisorContactNumber,
      financialAdvisorFee : brokerDetails.financialAdvisorFee
    };

    return this.http.post<any>(this.caseUrl + 'equityreleasebrokerdetails', formData, httpOptions);
  }

  getEquityReleasePropertyDetails(caseId: (number | undefined)){
    return this.http.get<any>(this.caseUrl + caseId + '/equityreleasepropertydetails/' );
  }

  getEquityReleasePurchasePropertyDetails(caseId: (number | undefined)){
    return this.http.get<any>(this.caseUrl + caseId + '/equityreleasepurchasepropertydetails/' );
  }

  getConveyancingSalePropertyDetails(caseId: (number | undefined)){
    return this.http.get<any>(this.caseUrl + caseId + '/conveyancingsalepropertydetails/' );
  }

  getConveyancingPurchasePropertyDetails(caseId: (number | undefined)){
    return this.http.get<any>(this.caseUrl + caseId + '/conveyancingpurchasepropertydetails/' );
  }

  updateEquityReleasePropertyDetails(caseDetails: any) {
    return this.http.post<any>(this.caseUrl + 'equityreleasepropertydetails', caseDetails, httpOptions);
  }

  updateEquityReleasePurchasePropertyDetails(caseDetails: any) {
    return this.http.post<any>(this.caseUrl + 'equityreleasepurchasepropertydetails', caseDetails, httpOptions);
  }

  updateConveyancingSalePropertyDetails(caseDetails: any) {
    return this.http.post<any>(this.caseUrl + 'conveyancingsalepropertydetails', caseDetails, httpOptions);
  }

  updateConveyancingPurchasePropertyDetails(caseDetails: any) {
    return this.http.post<any>(this.caseUrl + 'conveyancingpurchasepropertydetails', caseDetails, httpOptions);
  }

  getEquityReleasePurchaseEstateAgentDetails(caseId: (number | undefined)){
    return this.http.get<any>(this.caseUrl + caseId + '/equityreleasepurchaseestateagentdetails/' );
  }

  getConveyancingSaleEstateAgentDetails(caseId: (number | undefined)){
    return this.http.get<any>(this.caseUrl + caseId + '/conveyancingsaleestateagentdetails/' );
  }

  updateEquityReleasePurchaseEstateAgentDetails(caseDetails: any) {
    return this.http.post<any>(this.caseUrl + 'equityreleasepurchaseestateagentdetails', caseDetails, httpOptions);
  }

  getConveyancingPurchaseEstateAgentDetails(caseId: (number | undefined)){
    return this.http.get<any>(this.caseUrl + caseId + '/conveyancingpurchaseestateagentdetails/' );
  }

  updateConveyancingSaleEstateAgentDetails(estateAgentDetails: any) {

    let formData = {
      conveyancingSaleCaseId: estateAgentDetails.conveyancingSaleCaseId,
      caseId : estateAgentDetails.caseId,
      addedByUserId : estateAgentDetails.addedByUserId,
      contactNumber : estateAgentDetails.contactNumber,
      estateAgentFee : estateAgentDetails.estateAgentFee,
      estateAgentAddressLine1 : estateAgentDetails.estateAgentAddressLine1,
      estateAgentAddressLine2 : estateAgentDetails.estateAgentAddressLine2,
      estateAgentAddressLine3 : estateAgentDetails.estateAgentAddressLine3,
      estateAgentAddressLine4 : estateAgentDetails.estateAgentAddressLine4,
      estateAgentAddressLine5 : estateAgentDetails.estateAgentAddressLine5,
      estateAgentPostcode : estateAgentDetails.estateAgentPostcode
    };

    return this.http.post<any>(this.caseUrl + 'conveyancingsaleestateagentdetails', formData, httpOptions);
  }

  updateConveyancingPurchaseEstateAgentDetails(caseDetails: any) {
    return this.http.post<any>(this.caseUrl + 'conveyancingpurchaseestateagentdetails', caseDetails, httpOptions);
  }

  createInternalCase(caseDetails: any) {
    return this.http.put<any>(this.caseUrl + "internalcase", caseDetails, httpOptions);
  }


  getRecentCases(){
    return this.http.get<any>(this.caseUrl + 'recentcases/' );
  }

  updateAuthorisedSignatory(caseId: (number | undefined), selectedUserId: (number | undefined)) {

    let formData = {
      caseId : caseId,
      selectedUserId : selectedUserId,
    };

    return this.http.post<any>(this.caseUrl + 'authorisedsignatory', formData, httpOptions);
  }
}
