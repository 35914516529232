import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { faPlus, faTrashAlt, faHistory } from '@fortawesome/pro-light-svg-icons';
import { AuthService } from '../../../auth/auth.service';
import { CaseFilesService } from 'src/app/shared/services/casefiles.service';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.css']
})
export class DocumentPreviewComponent implements OnInit {

  notes: any;
  viewInit = false;
  _caseFileId: number = 0;
  thumbnailLoaded = false;
  thumbnailData: string = "";

  @Input()
  set caseFileId(value: number) {
    this._caseFileId = value;
    this.loadThumbnail();
  }
  get caseFileId() : number  {
    return this._caseFileId;
  }

  @Input() readOnly = true;
  @Input() caseInstructed = true;

  faPlus = faPlus;
  faTrashAlt = faTrashAlt;
  faHistory = faHistory;  

    constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
              public dialog: MatDialog,
              public authService: AuthService,
              public caseFilesService: CaseFilesService)
               {

      if(componentData.caseFileId)
      {
        this.caseFileId = componentData.caseFileId;
      }

    }

  ngOnInit(): void {
  }

  ngAfterContentInit() : void {
    this.viewInit = true;
    this.loadThumbnail();
  }

  loadThumbnail(){

    if (!this.viewInit) return;

    this.caseFilesService.getThumbnail(this.caseFileId).subscribe(response => {
      var base64 = response.body;
      if(base64 != null) {
        this.thumbnailData = base64;
        this.thumbnailLoaded = true;
      }
      else {

      }      
    }, error => {

      var errorMessage = "Failed to get thumbnail";
      if (error.error !== null && error.error.message !== undefined) {
        errorMessage = error.error.message;
      }

    });
  }

  ngOnDestroy(){
  }
}
