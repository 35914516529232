<div class="body">  
  <div class="content">

    <span *ngIf="gettingCodeFromKlyant">
      <div [hidden]="!isError" class="error">
        An error occurred contacting Klyant: <strong>{{errorMessage}}</strong>
      </div>
      <div [hidden]="isError" class="message">
        Logged into Klyant. Please <input type="button" (click)="closeWindow()" value="Close" /> this window to continue.
      </div>
    </span>
    
    <span *ngIf="!gettingCodeFromKlyant">
      <h1>Save to Klyant</h1>

        <mat-form-field>
          <mat-select placeholder="Select Existing Client" [(ngModel)]="existingClientId">
            <mat-option [value]="0"></mat-option>
            <mat-option *ngFor="let client of existingClients" [value]="client.id">{{client.First_Name}} {{client.Last_Name}} - {{client.Client_Code}}</mat-option>
          </mat-select> 
        </mat-form-field>

        <div>
          <button *ngIf="!addingToKlyant" mat-flat-button color="primary" [disabled]="!isLoaded" (click)="addClientAndMatter()">Add Matter and NEW Client</button>
          <button *ngIf="addingToKlyant" mat-icon-button disabled color="primary" class="right" >
            <mat-icon>
                <mat-spinner color="primary" diameter="20">
                </mat-spinner>
            </mat-icon>
        </button>
          or
          <button mat-flat-button color="primary" [disabled]="existingClientId == null || existingClientId == 0 || !isLoaded" class="button-second" (click)="updateClientAndMatter()">Add/Update Matter with EXISTING Client</button>                
          <button mat-flat-button color="danger" class="button-cancel" mat-dialog-close>Cancel</button>
        </div>

        <div [hidden]="!isError" class="error">
          An error occurred contacting Klyant: <strong>{{errorMessage}}</strong>
        </div>
    </span>

  </div>

</div>