import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SearchResult } from './interfaces/search-result';
import { SearchRequest } from './interfaces/search-request';
import { Observable } from 'rxjs/internal/Observable';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class PreexchangeChecklistService {

  private url = environment.apiUrl + 'preexchangechecklist/';

  public refreshCaseTimeline  = new EventEmitter();
  public refreshCaseTimelineObservable = this.refreshCaseTimeline.asObservable();

  constructor(private http: HttpClient) { }


  getChecklistItemsForCase(caseId: number){
    return this.http.get<any>(this.url  + caseId );
  }


  updateItem(caseId: number, itemId: number, value: any){

    let formData = {
      responseItemId: itemId,
      value: value
    };

    return this.http.post<any>(this.url + caseId, formData );
  }

  updateCommentary(caseId: number, itemId: number, commentary: any){

    let formData = {
      responseItemId: itemId,
      commentary: commentary
    };

    return this.http.post<any>(this.url + caseId + '/commentary', formData );
  }


}
