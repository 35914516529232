import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { faBellOn } from '@fortawesome/pro-solid-svg-icons';
import { AuthService } from 'src/app/auth/auth.service';
import { SidebarService } from '../sidebar/sidebar.service';
import { CaseService } from '../../services/case.service';
import { NotificationsService } from '../../services/notifications.service';
import { Subscription, interval } from 'rxjs';
import { startWith, switchMap, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  isProd = environment.production;
  isStaging = environment.isStaging;
  isSmall = false;
  faBars = faBars;
  faBell = faBell;
  faBellOn = faBellOn;
  @Input() showMenu = true;
  loggedIn?: boolean = false;
  unacknowledgedNotifications: boolean = false;

  showNotifications: boolean = false;

  recentCases: any[] = [];

  private unacknowledgedNotificationsCheck?: Subscription;


  constructor(breakpointObserver: BreakpointObserver,
              private sidebarService: SidebarService,
              public authService: AuthService,
              private caseService: CaseService,
              private notificationsService: NotificationsService) {

    const layoutChanges = breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]);

    layoutChanges.subscribe(result => {
      this.isSmall = result.matches
    });

    this.authService.isLogin.subscribe(status => {
      this.loggedIn = status;

      if(status)
        this.startUnacknowledgedNotificationsCheck();
      else
        this.stopUnacknowledgedNotificationsCheck();

    });

  }

  loadRecentCases(){
    this.recentCases = [];
    this.caseService.getRecentCases().subscribe( (response : any) => {

      this.recentCases = response.recentCases;
    });
  }

  ngOnInit() {

  }

  clickMenu() {
    this.sidebarService.toggleSideBar();
  }

  startUnacknowledgedNotificationsCheck(){
    this.unacknowledgedNotificationsCheck = interval(30000)
    .pipe(
      startWith(0),
      switchMap(() => this.notificationsService.checkUnacknowledgedNotificationsForUser()),
    )
    .subscribe(res => this.unacknowledgedNotifications = res.unacknowledgedNotifications);
  }


  openNotifications(){

    if(!this.showNotifications)
      this.showNotifications = true;
    else
    {
      this.closeNotifications();
    }

  }

  closeNotifications(){
    this.showNotifications = false;
    // When closing flag all unread to read
    this.notificationsService.markAllAsAcknowledgedForUser().subscribe(response => {
      this.notificationsService.checkUnacknowledgedNotificationsForUser().subscribe( (res : any) => {
        this.unacknowledgedNotifications = res.unacknowledgedNotifications;
      });
    });

  }

  stopUnacknowledgedNotificationsCheck() {
    if (this.unacknowledgedNotificationsCheck) {
      this.unacknowledgedNotificationsCheck.unsubscribe();
    }

    this.showNotifications = false;
  }


  ngOnDestroy(){
    this.stopUnacknowledgedNotificationsCheck();
  }
}
