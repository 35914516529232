<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<div class="button-section">
  <button *ngIf="!readOnly" mat-stroked-button  cdkFocusInitial color="primary" (click)="refreshAutoLines()" >
    <fa-icon  [icon]="faSync"></fa-icon> Pull Invoice Items 
  </button>
  <button mat-stroked-button  cdkFocusInitial color="primary" (click)="openAddInvoice()">
    <fa-icon  [icon]="faPlus"></fa-icon> Additional Invoice 
  </button>
</div>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Invoice <span *ngIf="invoice && invoice.invoiceNumber"> - {{invoice.invoiceNumber}}</span> <span *ngIf="invoice && invoice.klyantInvoiceId"> - SENT TO KLYANT</span><span *ngIf="invoice && invoice.sentToKlyantDate"> - {{invoice.sentToKlyantDate | date:'dd/MM/yyyy HH:MM'}}</span></h2>

<mat-dialog-content class="mat-typography" >

  <div class="main">
    <div class="feessection">
      <div class="listheader">
      <h4>{{columnTitles[0]}}</h4>
        <div class="headeractions">
            <button mat-icon-button tabindex="-1" color="primary" (click)="addInvoiceItem(1)" *ngIf="!readOnly"><fa-icon [icon]="faPlus"></fa-icon></button>
        </div>
     </div>    
    <div class="list">
        <div class="box" *ngFor="let ii of professionalCharges">
            <div class="invoiceItem">
              <div class="description">{{ii.description}}</div>
              <div class="net">{{ii.net | currency:'£'}}</div>
              <div class="vat">{{ii.vat | currency:'£'}}</div>

              <div class="actions" *ngIf="!readOnly">
                  <button mat-icon-button><fa-icon [icon]="faTimes" (click)="removeInvoiceItem(ii.id)"></fa-icon></button>
                  <button mat-icon-button> <fa-icon [icon]="faPencil" (click)="editInvoiceItem(ii)"></fa-icon></button>
              </div>

            </div>
        </div>
      </div>
      <div class="totals">
          <div class="label">Total:</div>
          <div class="net">{{professionalChargesTotal | currency:'£'}}</div>
          <div class="vat">{{professionalChargesVatTotal | currency:'£'}}</div>
      </div>
    </div>
    <div class="feessection">
        <div class="listheader">
        <h4>{{columnTitles[1]}}</h4>
          <div class="headeractions">              
              <!-- <button mat-icon-button tabindex="-1" color="primary" (click)="addInvoiceItem(2)"><fa-icon [icon]="faPlus" ></fa-icon></button> -->
              <!--button mat-icon-button *ngIf="!readOnly" tabindex="-1" color="primary" (click)="openInvoiceUploader()" class="uploadInvoice"><fa-icon [icon]="faUpload" ></fa-icon></button-->
          </div>
       </div>
      <div class="list">
          <div class="box" *ngFor="let ii of disbursements" [ngClass]="{'no-outlay': ii.klyantOutlayId == null}" >
              <div class="invoiceItem">
                <div class="description">{{ii.description}}</div>
                <div class="net">{{ii.net | currency:'£'}}</div>
                <div class="vat">{{ii.vat | currency:'£'}}</div>

                <div class="actions">                  
                  <!-- ADB 2023-01-12: need to manage this through the pre-invoice screen now -->
                  <!-- <button mat-icon-button><fa-icon [icon]="faTimes" (click)="removeInvoiceItem(ii.id)"></fa-icon></button>
                  <button mat-icon-button> <fa-icon [icon]="faPencil" (click)="editInvoiceItem(ii)"></fa-icon></button> -->
                </div>

              </div>
          </div>
        </div>

        <div class="totals">
          <div class="label">Total:</div>
          <div class="net">{{disbursementsTotal | currency:'£'}}</div>
          <div class="vat">{{disbursementsVatTotal | currency:'£'}}</div>
      </div>
      </div>

  <div class="additionalChargesSection">
      <div class="listheader">
        <h4>{{columnTitles[2]}}</h4>
        <div class="headeractions">
          <button mat-icon-button *ngIf="!readOnly" tabindex="-1" color="primary" (click)="openInvoiceUploader()"><fa-icon [icon]="faUpload" ></fa-icon></button>
          <button *ngIf="!readOnly" mat-icon-button tabindex="-1" color="primary" (click)="addInvoiceItem(3)"><fa-icon [icon]="faPlus" ></fa-icon></button>
        </div>
      </div>
    <div class="list">
        <div class="box" *ngFor="let ii of additionalCharges">
            <div class="invoiceItem">
              <div class="description">{{ii.description}}</div>
              <div class="net">{{ii.net | currency:'£'}}</div>
              <div class="vat">{{ii.vat | currency:'£'}}</div>

              <div class="actions" *ngIf="!readOnly">                  
                  <button mat-icon-button><fa-icon [icon]="faTimes" (click)="removeInvoiceItem(ii.id)"></fa-icon></button>
                  <button mat-icon-button> <fa-icon [icon]="faPencil" (click)="editInvoiceItem(ii)"></fa-icon></button>
              </div>

            </div>
        </div>
      </div>
      <div class="totals">
        <div class="label">Total:</div>
        <div class="net">{{additionalChargesTotal | currency:'£'}}</div>
        <div class="vat">{{additionalChargesVatTotal | currency:'£'}}</div>
    </div>
    </div>


    <div class="thirdpartyfeessetion">
      <div class="listheader">
        <h4>{{columnTitles[3]}}</h4>
        <div class="headeractions">
            <button mat-icon-button tabindex="-1" color="primary" (click)="addInvoiceItem(4)"><fa-icon [icon]="faPlus" ></fa-icon></button>
        </div>
      </div>
    <div class="list">
        <div class="box" *ngFor="let ii of thirdPartyFees">
            <div class="invoiceItem">
              <div class="description">{{ii.description}}</div>
              <div class="net">{{ii.net | currency:'£'}}</div>
              <div class="vat">{{ii.vat | currency:'£'}}</div>

              <div class="actions">
                  <button mat-icon-button><fa-icon [icon]="faTimes" (click)="removeInvoiceItem(ii.id)"></fa-icon></button>
                  <button mat-icon-button> <fa-icon [icon]="faPencil" (click)="editInvoiceItem(ii)"></fa-icon></button>

              </div>

            </div>
        </div>
      </div>
      <div class="totals">
          <div class="label">Total:</div>
          <div class="net">{{thirdPartyFeesTotal | currency:'£'}}</div>
          <div class="vat">{{thirdPartyFeesVatTotal | currency:'£'}}</div>
      </div>
    </div>

  </div>

  
</mat-dialog-content>

<mat-spinner [diameter]="70" class="invoice-spinner" color="primary" diameter="20" *ngIf="!invoice || creatingInvoice || refreshingData"></mat-spinner>

<div mat-dialog-actions align="end" *ngIf="invoice">
    <div class="error" *ngIf="hasError">{{errorMessage}}</div>
    <button *ngIf="(caseTypeId == 1 || caseTypeId == 6 || caseTypeId == 7 || caseTypeId == 8) && !isGeneratingCompletionStatement" mat-stroked-button  cdkFocusInitial color="primary" (click)="generateCompletionStatement()" [disabled]="professionalCharges.length == 0 && additionalCharges.length == 0 && disbursements.length == 0 && thirdPartyFees.length == 0">Completion Statement</button>
    <button *ngIf="isGeneratingCompletionStatement" mat-icon-button disabled color="primary" class="right" >
      <mat-icon>
          <mat-spinner color="primary" diameter="20">
          </mat-spinner>
      </mat-icon>
    </button>
    <button mat-stroked-button  cdkFocusInitial color="primary" (click)="generateInvoice()" [disabled]="disableGenerateInvoice() || creatingInvoice">
      <span *ngIf="!invoice.invoiceNumber">Generate Invoice</span>
      <span *ngIf="invoice.invoiceNumber">Re-Print Invoice</span>
    </button>
    <button *ngIf="!sendingToKlyant && !invoice.klyantInvoiceId" mat-stroked-button  cdkFocusInitial color="primary" (click)="sendToKlyant()" [disabled]="!invoice.invoiceNumber">Send To Klyant</button>
    <button *ngIf="sendingToKlyant" mat-icon-button disabled color="primary" class="right" >
      <mat-icon>
          <mat-spinner color="primary" diameter="20">
          </mat-spinner>
      </mat-icon>
    </button>
</div>

