import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { MatTabGroup } from "@angular/material/tabs";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  isSmall = false;
  isMedium = false;

  taskTabIndex: number = 0; // 0 = management

  constructor(public authService: AuthService, breakpointObserver: BreakpointObserver) {

    const layoutChanges = breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall]);

    layoutChanges.subscribe(result => {

      this.isSmall = breakpointObserver.isMatched([Breakpoints.Small, Breakpoints.XSmall]);
      this.isMedium = breakpointObserver.isMatched([Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall]);

      /*if (this.isSmall) {
        sidebarService.closeSideBar();
      }*/
    });
  }

  ngOnInit() {

    let selectedTaskTab = localStorage.getItem('selectedTaskTab');

    if(selectedTaskTab != null)
    {
      var tabIndex = 0;
      if(selectedTaskTab == 'paralegal')
      this.taskTabIndex = 1;
      else if(selectedTaskTab == 'admin')
      this.taskTabIndex = 2;
      else {
        this.taskTabIndex = 0;
      }
    }

  }

}
