<button mat-icon-button class="close" tabindex="-1" (click)="close()">x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Funds on Account</h2>


<mat-dialog-content class="mat-typography">
    <div class="headeractions">
        <div class="buttons">
          <button mat-icon-button tabindex="-1" color="primary" (click)="addFund()"><fa-icon [icon]="faPlus" ></fa-icon></button>
        </div>
    </div>
    <div class="list">
        <div class="box" *ngFor="let fund of caseFunds">
            <div class="fundItem">

              <div class="description">{{fund.description}}</div>
              <div class="net">{{fund.net | currency:'£'}}</div>

              <div class="actions">
                  <button mat-icon-button><fa-icon [icon]="faTimes" (click)="removeFund(fund.id)"></fa-icon></button>
              </div>

            </div>
        </div>
      </div>
      <div class="totals">
          <div class="label">Total:</div>
          <div class="net">{{caseFundsTotal | currency:'£'}}</div>
      </div>
</mat-dialog-content>
