import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faPlus, faCheck } from '@fortawesome/pro-light-svg-icons';
import { LendersSolicitorService } from '../../shared/services/lenders-solicitor.service';

@Component({
  selector: 'app-lender-solicitors-list',
  templateUrl: './lender-solicitors-list.component.html',
  styleUrls: ['./lender-solicitors-list.component.scss']
})
export class LenderSolicitorsListComponent implements OnInit {

  displayedColumns: string[] = ['name', 'isActive'];
  lenderSolicitors: any[] = [];
  faPlus = faPlus;
  faCheck = faCheck;

  constructor(private lenderSolService: LendersSolicitorService,
    public router: Router) {

      this.lenderSolService.getAll().subscribe( (response : any) =>{

        this.lenderSolicitors = response;
      })
  }

  ngOnInit(): void {
  }

  editLenderSol(id :number){
    this.router.navigateByUrl('/lendersolicitors/' + id + '/admin');
  }

  addLenderSol(){
    this.router.navigateByUrl('/lendersolicitors/new');
  }


}
