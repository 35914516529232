<div class="body">

    <div class="sidebar">
        <app-dashboard-sidebar></app-dashboard-sidebar>
      </div>


    <div class="content">

      <div *ngIf="authService.isPortalManager() || authService.isPortalParaLegalTL()">

          <app-card-basic cardTitle="Management Actions" >
              <div card-body>
                <app-task-list  userType="management" tasksFor="user" [showCompleted]="true"></app-task-list>
              </div>
           </app-card-basic>


          <app-card-basic cardTitle="Paralegal Actions" >
            <div card-body>
              <app-task-list userType="paralegal" tasksFor="user" [showCompleted]="true"></app-task-list>
            </div>
          </app-card-basic>


          <div *ngIf="authService.canSeeAdminActionList()">
              <app-card-basic cardTitle="Admin Actions"  >
                  <div card-body>
                    <app-task-list  userType="admin" tasksFor="user" [showCompleted]="true"></app-task-list>
                  </div>
            </app-card-basic>
          </div>

    </div>

    <div *ngIf="!authService.isPortalManager() && !authService.isPortalParaLegalTL()">

        <div *ngIf="authService.canSeeParalegalActionList()">
            <app-card-basic cardTitle="Actions" >
                <div card-body>
          <app-task-list userType="paralegal" tasksFor="user" [showCompleted]="true"></app-task-list>
        </div>
      </app-card-basic>
        </div>

        <div *ngIf="authService.canSeeAdminActionList()">
            <app-card-basic cardTitle="Actions" >
                <div card-body>
                  <app-task-list  userType="admin" tasksFor="user" [showCompleted]="true"></app-task-list>
                </div>
          </app-card-basic>
        </div>

  </div>

    </div>


  </div>
