import { Component, AfterViewInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseService } from '../../shared/services/case.service';
import { UserService } from '../../shared/services/user.service';
import { TaskService } from '../../shared/services/task.service';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-reallocate-case-task',
  templateUrl: './reallocate-case-task.component.html',
  styleUrls: ['./reallocate-case-task.component.css']
})
export class ReallocateCaseTaskModalComponent implements AfterViewInit {

  paralegalUsers: any[] = [];
  adminUsers: any[] = [];
  filteredParalegalUsers: Observable<any[]> = new Observable<any[]>();
  filteredAdminUsers: Observable<any[]> = new Observable<any[]>();
  paralegalSearchInput = new FormControl();
  adminSearchInput = new FormControl();
  invalidSelection: boolean = true;
  isLoading: boolean = false;
  taskType: string = "Paralegal";
  noOfDays = new FormControl();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ReallocateCaseTaskModalComponent>,
  private caseService: CaseService,
  userService: UserService,
  private taskService: TaskService) {

    userService.paralegals().subscribe(results => {

      for (const user of results) {
          this.paralegalUsers.push({ name: user.firstName + ' ' + user.lastName, id: user.id })
      }

      this.loadParalegals()
    });

    userService.admins().subscribe(results => {

      for (const user of results) {
          this.adminUsers.push({ name: user.firstName + ' ' + user.lastName, id: user.id })
      }

      this.loadAdmins();
    });

  }

  ngAfterViewInit(): void {
  }

  paralegalSelectionUpdated(){

    this.invalidSelection = true;

    if(this.paralegalSearchInput.value != null && this.paralegalSearchInput.value != "" )
      this.invalidSelection = false;

  }

  adminSelectionUpdated(){

    this.invalidSelection = true;

    if(this.adminSearchInput.value != null && this.adminSearchInput.value != "")
      this.invalidSelection = false;

  }

  selectionUpdated(){

    this.invalidSelection = true;

    if(((this.paralegalSearchInput.value != null && this.paralegalSearchInput.value.id > 0) ||
      (this.adminSearchInput.value != null && this.adminSearchInput.value.id > 0)) &&
      this.noOfDays.value != null &&  this.noOfDays.value > 0 )
      this.invalidSelection = false;

  }

  reallocateParalegalTasks(){

    this.isLoading = true;

    this.caseService.reallocateParalegalTasks(this.paralegalSearchInput.value.id, this.data['selection'].map((x: any)  => x.id)).subscribe((response : any) => {

      this.dialogRef.close(true);
      this.taskService.refreshTasks.next();

      }, (error : any)  => {
      });

  }

  reallocateAdminTasks(){

    this.isLoading = true;

    this.caseService.reallocateAdminTasks(this.adminSearchInput.value.id, this.data['selection'].map((x: any) => x.id)).subscribe((response : any) => {

      this.dialogRef.close(true);
      this.taskService.refreshTasks.next();

      }, (error : any) => {
      });

  }

  reallocateTasks(){

    this.isLoading = true;

    var paralegalId = -1;
    var adminId = -1;

    console.log(this.paralegalSearchInput);
    console.log(this.paralegalSearchInput.value);
    console.log(this.adminSearchInput);
    console.log(this.adminSearchInput.value);

    if(this.paralegalSearchInput.value !== null && this.paralegalSearchInput.value.id > 0)
      paralegalId = this.paralegalSearchInput.value.id;

    if(this.adminSearchInput.value !== null && this.adminSearchInput.value.id > 0)
      adminId = this.adminSearchInput.value.id;

    this.caseService.reallocateTasks(paralegalId, adminId, this.noOfDays.value, this.data['selection'].map((x: any) => x.id)).subscribe((response : any) => {

      this.dialogRef.close(true);
      this.taskService.refreshTasks.next();

      }, error => {
      });

  }

  cancel(){
    this.dialogRef.close(null);
  }

  loadParalegals() {

    this.filteredParalegalUsers = this.paralegalSearchInput.valueChanges
      .pipe(
        startWith<string | any>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterParalegals(name) : this.paralegalUsers.slice())
      );

    }

    loadAdmins() {
    this.filteredAdminUsers = this.adminSearchInput.valueChanges
    .pipe(
      startWith<string | any>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filterAdmins(name) : this.adminUsers.slice())
    );
  }

  displayFn(user: any): string {
    return user ? user.name : '';
  }

  private _filterParalegals(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.paralegalUsers.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterAdmins(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.adminUsers.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

}
