
<div class="body">

    <div class="sidebar">
      <app-dashboard-sidebar></app-dashboard-sidebar>
    </div>

    <div class="content">
      <h2>New Case</h2>

      <form [formGroup]="newCaseDetails" autocomplete="off">

          <app-card-label label="Case Type">
            <mat-form-field floatLabel="never">
                <mat-select formControlName="typeId" >
                    <mat-option *ngFor="let type of caseTypes" [value]="type.id">{{type.caseTypeName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </app-card-label>

        <app-card-label label="Other Case Type" *ngIf="newCaseDetails.controls['typeId'].value == 0">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{typeOther.value?.length || 0}}/100</mat-hint>
            <input matInput #typeOther  maxlength="100"
              formControlName="typeOther" >
          </mat-form-field>
        </app-card-label>

        <h3 class="underlined">Client One</h3>

        <app-card-label label="Title">
          <mat-form-field floatLabel="never">
            <mat-select formControlName="partyOneTitleId">
              <mat-option [value]="0"></mat-option>
              <mat-option *ngFor="let title of titles" [value]="title.id">{{title.titleName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </app-card-label>

        <app-card-label label="First Name">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyOneFirstName.value?.length || 0}}/25</mat-hint>
            <input matInput #partyOneFirstName maxlength="25" formControlName="partyOneFirstName" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Middle Name(s)">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyOneMiddleNames.value?.length || 0}}/50</mat-hint>
            <input matInput #partyOneMiddleNames maxlength="50" formControlName="partyOneMiddleNames" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Last Name">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyOneLastName.value?.length || 0}}/25</mat-hint>
            <input matInput #partyOneLastName  maxlength="25" formControlName="partyOneLastName" >
          </mat-form-field>
        </app-card-label>

        <h3>Correspondence Address</h3>

        <app-card-label label="Postcode">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyOnePostcode.value?.length || 0}}/10</mat-hint>
            <input matInput #partyOnePostcode maxlength="10" formControlName="partyOnePostcode">
            <app-address-lookup-button matSuffix [for]="addressLookup1"></app-address-lookup-button>
          </mat-form-field>
        </app-card-label>

        <app-address-lookup #addressLookup1 lookupType="postcode" [searchField]="partyOnePostcode" [add1]="newCaseDetails.controls['partyOneAddressLine1']"
          [add2]="newCaseDetails.controls['partyOneAddressLine2']" [add3]="newCaseDetails.controls['partyOneAddressLine3']" [add4]="newCaseDetails.controls['partyOneAddressLine4']"
          [add5]="newCaseDetails.controls['partyOneAddressLine5']" [addPc]="newCaseDetails.controls['partyOnePostcode']" >
        </app-address-lookup>

        <app-card-label label="Address Line 1">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyOneAddressLine1.value?.length || 0}}/100</mat-hint>
            <input matInput #partyOneAddressLine1 maxlength="100" formControlName="partyOneAddressLine1" >
          </mat-form-field>
        </app-card-label>


        <app-card-label label="Address Line 2">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyOneAddressLine2.value?.length || 0}}/100</mat-hint>
            <input matInput #partyOneAddressLine2 maxlength="100" formControlName="partyOneAddressLine2" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Address Line 3">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyOneAddressLine3.value?.length || 0}}/100</mat-hint>
            <input matInput #partyOneAddressLine3  maxlength="100" formControlName="partyOneAddressLine3" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Address Line 4">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyOneAddressLine4.value?.length || 0}}/100</mat-hint>
            <input matInput #partyOneAddressLine4 maxlength="100" formControlName="partyOneAddressLine4" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Address Line 5">
          <mat-form-field floatLabel="never">
            <mat-hint  align="end">{{partyOneAddressLine5.value?.length || 0}}/100</mat-hint>
            <input matInput #partyOneAddressLine5 maxlength="100" formControlName="partyOneAddressLine5" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Contact Number 1">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyOneContactNumberOne.value?.length || 0}}/15</mat-hint>
            <input matInput #partyOneContactNumberOne maxlength="15" formControlName="partyOneContactNumberOne" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Contact Number 2">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyOneContactNumberTwo.value?.length || 0}}/15</mat-hint>
            <input matInput #partyOneContactNumberTwo maxlength="15" formControlName="partyOneContactNumberTwo" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Happy to receive updates by Text Message?">
          <app-yes-no formControlName="partyOneCorrespondenceSMS"></app-yes-no>
        </app-card-label>

        <app-card-label label="Has Email Address?" >
          <app-yes-no formControlName="partyOneHasEmailAddress">
          </app-yes-no>
        </app-card-label>

        <app-card-label label="Email Address" *ngIf="newCaseDetails.controls['partyOneHasEmailAddress'].value == 1">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyOneEmailAddress.value?.length || 0}}/50</mat-hint>
            <input matInput #partyOneEmailAddress maxlength="50" formControlName="partyOneEmailAddress">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Date of Birth">
          <mat-form-field floatLabel="never">
            <input matInput [matDatepicker]="picker1" formControlName="partyOneDateOfBirth" [min]="minDob" [max]="maxDob" >
            <mat-datepicker-toggle  matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1 startView="multi-year" [startAt]="dobStartDate"></mat-datepicker>
            <mat-hint align="end">dd/mm/yyyy</mat-hint>
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Receive Correspondence by email?"
            [hidden]="newCaseDetails.controls['partyOneHasEmailAddress'].value != 1">
            <app-yes-no formControlName="partyOneCorrespondenceEmail" (change)="correspondenceCheck('email');">
            </app-yes-no>
          </app-card-label>

          <app-card-label label="Receive Correspondence by letter?">
            <app-yes-no formControlName="partyOneCorrespondenceLetter" (change)="correspondenceCheck('letter');"></app-yes-no>
          </app-card-label>


        <h3 class="underlined">Client Two</h3>


        <app-card-label label="Title">
          <mat-form-field floatLabel="never">
            <mat-select placeholder="Select Title" formControlName="partyTwoTitleId">
              <mat-option [value]="0"></mat-option>
              <mat-option *ngFor="let title of titles" [value]="title.id">{{title.titleName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </app-card-label>

        <app-card-label label="First Name">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyTwoFirstName.value?.length || 0}}/25</mat-hint>
            <input matInput #partyTwoFirstName placeholder="First Name" maxlength="25"
              formControlName="partyTwoFirstName" >
          </mat-form-field>

        </app-card-label>

        <app-card-label label="Middle Name(s)">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyTwoMiddleNames.value?.length || 0}}/50</mat-hint>
            <input matInput #partyTwoMiddleNames placeholder="Middle Names" maxlength="50"
              formControlName="partyTwoMiddleNames">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Last Name">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyTwoLastName.value?.length || 0}}/25</mat-hint>
            <input matInput #partyTwoLastName placeholder="Last Name" maxlength="25" formControlName="partyTwoLastName">
          </mat-form-field>
        </app-card-label>

        <div class="flex buttonbar">
            <span class="spacer"></span>
          <button mat-raised-button color="primary" (click)="copyAddress();">Copy Address From Client One</button>
        </div>

        <h3>Correspondence Address</h3>

        <app-card-label label="Postcode">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyTwoPostcode.value?.length || 0}}/10</mat-hint>
            <input matInput #partyTwoPostcode placeholder="Postcode" maxlength="10" formControlName="partyTwoPostcode">
            <app-address-lookup-button matSuffix [for]="addressLookup2"></app-address-lookup-button>
          </mat-form-field>
        </app-card-label>

        <app-address-lookup #addressLookup2 lookupType="postcode" [searchField]="partyTwoPostcode" [add1]="newCaseDetails.controls['partyTwoAddressLine1']"
            [add2]="newCaseDetails.controls['partyTwoAddressLine2']" [add3]="newCaseDetails.controls['partyTwoAddressLine3']" [add4]="newCaseDetails.controls['partyTwoAddressLine4']"
            [add5]="newCaseDetails.controls['partyTwoAddressLine5']" [addPc]="newCaseDetails.controls['partyTwoPostcode']">
        </app-address-lookup>

        <app-card-label label="Address Line 1">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyTwoAddressLine1.value?.length || 0}}/100</mat-hint>
            <input matInput #partyTwoAddressLine1 placeholder="Address Line 1" maxlength="100"
              formControlName="partyTwoAddressLine1" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Address Line 2">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyTwoAddressLine2.value?.length || 0}}/100</mat-hint>
            <input matInput #partyTwoAddressLine2 placeholder="Address Line 2" maxlength="100"
              formControlName="partyTwoAddressLine2" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Address Line 3">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyTwoAddressLine3.value?.length || 0}}/100</mat-hint>
            <input matInput #partyTwoAddressLine3 placeholder="Address Line 3" maxlength="100"
              formControlName="partyTwoAddressLine3" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Address Line 4">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyTwoAddressLine4.value?.length || 0}}/100</mat-hint>
            <input matInput #partyTwoAddressLine4 placeholder="Address Line 4" maxlength="100"
              formControlName="partyTwoAddressLine4" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Address Line 5">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyTwoAddressLine5.value?.length || 0}}/100</mat-hint>
            <input matInput #partyTwoAddressLine5 placeholder="Address Line 5" maxlength="100"
              formControlName="partyTwoAddressLine5" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Contact Number 1">
            <mat-form-field floatLabel="never">
              <mat-hint align="end">{{partyTwoContactNumberOne.value?.length || 0}}/15</mat-hint>
              <input matInput #partyTwoContactNumberOne placeholder="Contact Number 1" maxlength="15"
                formControlName="partyTwoContactNumberOne" >
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Contact Number 2">
            <mat-form-field floatLabel="never">
              <mat-hint align="end">{{partyTwoContactNumberTwo.value?.length || 0}}/15</mat-hint>
              <input matInput #partyTwoContactNumberTwo placeholder="Contact Number 2" maxlength="15"
                formControlName="partyTwoContactNumberTwo" >
            </mat-form-field>
          </app-card-label>

        <app-card-label label="Has Email Address? (Only press Yes and enter details if this is different than client 1)">
          <app-yes-no formControlName="partyTwoHasEmailAddress" >
          </app-yes-no>
        </app-card-label>

        <app-card-label label="Email Address" *ngIf="newCaseDetails.controls['partyTwoHasEmailAddress'].value == 1">
          <mat-form-field floatLabel="never">
            <mat-hint align="end">{{partyTwoEmailAddress.value?.length || 0}}/50</mat-hint>
            <input matInput #partyTwoEmailAddress placeholder="Email Address" maxlength="50"
              formControlName="partyTwoEmailAddress" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Date of Birth">
          <mat-form-field floatLabel="never">
            <input matInput [matDatepicker]="picker2" placeholder="Choose a date" formControlName="partyTwoDateOfBirth"
              [min]="minDob" [max]="maxDob" >
            <mat-datepicker-toggle  matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 startView="multi-year" [startAt]="dobStartDate"></mat-datepicker>
            <mat-hint align="end">dd/mm/yyyy</mat-hint>
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Receive Correspondence by email?" *ngIf="newCaseDetails.controls['partyTwoHasEmailAddress'].value == 1">
            <app-yes-no formControlName="partyTwoCorrespondenceEmail" >
            </app-yes-no>
          </app-card-label>

          <app-card-label label="Receive Correspondence by letter?">
            <app-yes-no formControlName="partyTwoCorrespondenceLetter"></app-yes-no>
          </app-card-label>

    </form>

    <div class="bottom-button-bar">
      <button mat-stroked-button color="primary" (click)="createCase();" [disabled]="addingCase || newCaseDetails.invalid">Create Case</button></div>
    </div>

  </div>
