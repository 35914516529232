import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../shared/services/user.service';
import { Router } from '@angular/router';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-company-group-list',
  templateUrl: './company-group-list.component.html',
  styleUrls: ['./company-group-list.component.scss']
})
export class CompanyGroupListComponent implements OnInit {

  companyGroups: any;
  faPlus = faPlus;
  resultsLength: number = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype);
  @ViewChild(MatSort) sort: MatSort = new MatSort();

  displayedColumns: string[] = ['name', 'adminEmailAddress', 'additionalInformation'];

  constructor(private userService: UserService,
              public router: Router) {
    this.companyGroups = this.userService.companyGroups();
  }

  ngOnInit(): void {
  }

  addCompanyGroup(){
    this.router.navigateByUrl('/companygroup/new');
  }

  editCompanyGroup(companyGroup: any){
    this.router.navigateByUrl('/companygroup/' + companyGroup.id + '/admin');
  }

}
