<!-- <app-card cardTitle="Calendar" [open]="true" [disableClose]="true">
  <div card-body> -->

    <div>

      <div class="day-nav">
        <button mat-stroked-button (click)="prevMonth()"><fa-icon [icon]="faChevronLeft"></fa-icon></button>
        <div class="current">{{currMonth}}</div>
        <button mat-stroked-button (click)="nextMonth()"><fa-icon [icon]="faChevronRight"></fa-icon></button>
      </div>

      <div class="month">
        <div class="week-header">
          <div class="day-header">Mon</div>
          <div class="day-header">Tue</div>
          <div class="day-header">Wed</div>
          <div class="day-header">Thu</div>
          <div class="day-header">Fri</div>
          <div class="day-header">Sat</div>
          <div class="day-header">Sun</div>
        </div>
        <div class="week" *ngFor="let week of weeks; let i = index">

          <div class="day" *ngFor="let day of days; let j = index"
            [ngClass]="{'non-current-date': (i == 0 && getDate(week, day) > 7) || (i > 3 && getDate(week, day) <= 14), 'is-today': isToday(day, week)}">
            <div class="date" (click)="selectDay(day, week)">{{getDate(week, day)}}</div>
            <div class="event" (click)="selectEvent(event)" *ngFor="let event of getAppointments(j, i)">
              {{event.consultantName}}
            </div>
            <div class="event" (click)="selectDay(day, week)" *ngIf="getAppointmentCount(j, i) > 4">+ {{getAppointmentCount(j, i) - 4}} more </div>

          </div>

        </div>

      </div>
    </div>



  <!-- </div>
</app-card> -->
