<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>

<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Upload Invoice</h2>

<div mat-dialog-content class="mat-typography">
    <div *ngIf="files.length == 0" class="add-files" (dragover)="dragOver($event)" (dragleave)="dragLeave($event)" (drop)="dragDrop($event)" >Add File</div>

    <div *ngFor="let file of files; let i = index" class="file-row">            
                
            {{file.fileName}} <button mat-icon-button><fa-icon [icon]="faTimes" (click)="removeFile(i)"></fa-icon></button>   
    </div>


    <div>
        <app-card-label label="Invoice Fee Type" *ngIf="files.length > 0">
            <mat-form-field floatLabel="never">
            <mat-select placeholder="Select Type"  [(ngModel)]="feeType" >
            <mat-option *ngFor="let feeType of feeTypes" [value]="feeType.id">{{feeType.description}}</mat-option>
            </mat-select>
            </mat-form-field>
            <div class="error" *ngIf="hasError">{{errorMessage}}</div>
        </app-card-label>        
    </div>
</div>

<div mat-dialog-actions align="end" *ngIf="files.length > 0 && feeType != null" >
    <button mat-stroked-button  cdkFocusInitial (click)="uploadInvoice()" [disabled]="uploadingInvoice" color="primary">Upload</button>
</div>