import { Component } from '@angular/core';
import { BaseFilterComponent } from '../base-filter.component';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-string-filter',
  templateUrl: './string-filter.component.html',
  styleUrls: ['./string-filter.component.css']
})

export class StringFilterComponent extends BaseFilterComponent  {

  componentTypeName = "StringFilterComponent";

  filterForm = new FormGroup({
    equals: new FormControl()
  });

  onFilterSubmit() {

    const equals = this.filterForm.controls['equals'].value;

    if (equals) {
      this.filterValue = this.getValueTag() + equals;
      this.filterName = equals;
    }
    else {
      this.filterValue = '';
      this.filterName = '';
    }

    this.filter.next(true);
    this.menu.closeMenu();
  }

  clear() {
    this.filterForm.reset();
    this.filterValue = '';
    this.filterName = '';
    this.filter.next(true);

    this.menu.closeMenu();
  }

}
