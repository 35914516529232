import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../shared/services/user.service';
import { LookupsService } from '../../shared/services/lookups.service';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { NotificationsService } from '../../shared/services/notifications.service';

@Component({
  selector: 'app-transfer-notification',
  templateUrl: './transfer-notification.component.html',
  styleUrls: ['./transfer-notification.component.scss']
})
export class TransferNotificationComponent implements OnInit {

  users: any[] = [];
  filteredUsers: Observable<any[]> = new Observable<any[]>();
  userSearchInput = new FormControl();

  invalidSelection: boolean = true;
  isLoading: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<TransferNotificationComponent>,
  lookupsService: LookupsService,
  private notificationsService: NotificationsService) {
    lookupsService.getAllInternalUsers().subscribe(results => {

      for (const user of results) {
          this.users.push({ name: user.firstName + ' ' + user.lastName, id: user.id })
      }

      this.loadUsers();
    });
  }

  ngOnInit(): void {
  }

  loadUsers() {

    this.filteredUsers = this.userSearchInput.valueChanges
      .pipe(
        startWith<string | any>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterUsers(name) : this.users.slice())
      );

  }

  displayFn(user: any): string {
    return user ? user.name : '';
  }

  private _filterUsers(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.users.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  cancel(){
    this.dialogRef.close(null);
  }

  transferNotifications(){

    this.isLoading = true;


    this.notificationsService.transferNotifications(this.userSearchInput.value.id, this.data['selection'].map((x: any)  => x.id)).subscribe((response : any) => {

      this.dialogRef.close(true);

      }, (error : any)  => {
      });

  }


  userSelectionUpdated(){

    this.invalidSelection = true;

    if(this.userSearchInput.value != null && this.userSearchInput.value != "" )
      this.invalidSelection = false;

  }

}
