<div class="body">

    <div class="sidebar">
      <app-dashboard-sidebar></app-dashboard-sidebar>
    </div>

    <div class="content">

      <form [formGroup]="searchCriteria" autocomplete="off" ngClass.xs="size-xs">

        <app-card-basic [cardTitle]="sectionTitle">

          <div card-body>

            <div fxLayout="row wrap">

            <div fxFlex="14%">
            <app-card-label label="Case Status">
              <mat-form-field floatLabel="never">
                <mat-select placeholder="Select Case Status" formControlName="caseStatusType" >
                  <mat-option [value]=""></mat-option>
                  <mat-option *ngFor="let status of caseStatuses" [value]="status.id">{{status.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </app-card-label>
            </div>

            <div fxFlex="14%">
            <app-card-label label="Case Reference">
              <mat-form-field floatLabel="never">
                <input matInput #caseReference placeholder="Case Reference" maxlength="250" formControlName="caseReference">
              </mat-form-field>
            </app-card-label>
            </div>

            <div fxFlex="14%">
            <app-card-label label="Company Group">
              <mat-form-field floatLabel="never">
                <mat-select placeholder="Select Company Group" formControlName="companyGroupId" >
                  <mat-option [value]=""></mat-option>
                  <mat-option *ngFor="let group of companyGroups" [value]="group.id">{{group.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </app-card-label>
            </div>

            <div fxFlex="14%">
            <app-card-label label="Paralegal">
              <mat-form-field floatLabel="never">
                <mat-select placeholder="Select Person" formControlName="allocatedToParalegalUserId" >
                  <mat-option [value]=""></mat-option>
                  <mat-option *ngFor="let user of paralegalUsers" [value]="user.id">{{user.fullName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </app-card-label>
            </div>

            <div fxFlex="14%">
            <app-card-label label="Admin">
              <mat-form-field floatLabel="never">
                <mat-select placeholder="Select Person" formControlName="allocatedToAdminUserId" >
                  <mat-option [value]=""></mat-option>
                  <mat-option *ngFor="let user of adminUsers" [value]="user.id">{{user.fullName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </app-card-label>
            </div>

            <div fxFlex="14%">
                <app-card-label label="Case Type">
                  <mat-form-field floatLabel="never">
                    <mat-select placeholder="Select Case Type" formControlName="typeId" >
                      <mat-option [value]=""></mat-option>
                      <mat-option *ngFor="let type of caseTypes" [value]="type.id">{{type.caseTypeName}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </app-card-label>
            </div>

            <div fxFlex="14%">
              <app-card-label label="Property Address Line 1">
                <mat-form-field floatLabel="never">
                  <input matInput #propertyAddressLine1 placeholder="Property Address Line 1" maxlength="100" formControlName="propertyAddressLine1">
                </mat-form-field>
              </app-card-label>
            </div>

            <div fxFlex="14%">
              <app-card-label label="Property Postcode">
                <mat-form-field floatLabel="never">
                  <input matInput #propertyPostcode placeholder="Property Postcode" maxlength="10" formControlName="propertyPostcode">
                </mat-form-field>
              </app-card-label>
            </div>

            <!-- <div fxFlex="16%">
            <app-card-label label="Created By">
              <mat-form-field floatLabel="never">
                <mat-select placeholder="Select Person" formControlName="createdByUserId" >
                  <mat-option [value]="0"></mat-option>
                  <mat-option *ngFor="let user of createdByUsers" [value]="user.id">{{user.fullName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </app-card-label>
            </div> -->

            <div fxFlex="14%">
                <app-card-label label="Party One Last Name">
                  <mat-form-field floatLabel="never">
                    <input matInput #partyOneLastName placeholder="Party One Last Name" maxlength="100" formControlName="partyOneLastName">
                  </mat-form-field>
                </app-card-label>
                </div>

                <div fxFlex="14%">
                <app-card-label label="Party One Address Line 1">
                  <mat-form-field floatLabel="never">
                    <input matInput #partyOneAddressLine1 placeholder="Party One Address Line 1" maxlength="100" formControlName="partyOneAddressLine1">
                  </mat-form-field>
                </app-card-label>
                </div>

                <div fxFlex="14%">
                <app-card-label label="Party One Postcode">
                  <mat-form-field floatLabel="never">
                    <input matInput #partyOnePostcode placeholder="Party One Postcode" maxlength="10" formControlName="partyOnePostcode">
                  </mat-form-field>
                </app-card-label>
                </div>



            <div fxFlex="14%">
            <app-card-label label="Instructed Date From">
              <mat-form-field floatLabel="never" class="datepicker">
                <input matInput [matDatepicker]="picker1" formControlName="instructedDateFrom" placeholder="Date From" >
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 startView="multi-year"></mat-datepicker>
                <mat-hint align="end">dd/mm/yyyy</mat-hint>
              </mat-form-field>
            </app-card-label>
            </div>

            <div fxFlex="14%">
              <app-card-label label="Instructed Date To">
                <mat-form-field floatLabel="never" class="datepicker">
                  <input matInput [matDatepicker]="picker2" formControlName="instructedDateTo" placeholder="Date To">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2 startView="multi-year"></mat-datepicker>
                  <mat-hint align="end">dd/mm/yyyy</mat-hint>
                </mat-form-field>
              </app-card-label>
              </div>

            <!-- <div fxFlex="16%">
            <app-card-label label="Party One First Name">
              <mat-form-field floatLabel="never">
                <input matInput #partyOneFirstName placeholder="Party One First Name" maxlength="100" formControlName="partyOneFirstName">
              </mat-form-field>
            </app-card-label>
            </div> -->



            </div>

            <button mat-stroked-button type="button" color="primary" (click)="doSearch()">Search</button>
            <button mat-stroked-button type="button" color="warning" (click)="doClear()">Clear</button>

          </div>

          </app-card-basic>
        </form>

        <app-card-basic cardTitle="{{resultsTitle}}">
          <div card-body>

        <table mat-table [dataSource]="results" matSort matSortActive="caseReference" matSortDisableClear matSortDirection="desc" width="100%">

          <ng-container matColumnDef="caseReference">
            <mat-header-cell *matHeaderCellDef>
                <span mat-sort-header>Case Ref.</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let case"> {{case.caseReference}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="caseStatus">
            <mat-header-cell *matHeaderCellDef>
                <span>Status.</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let case"> {{case.caseStatusTypeText}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="paralegal">
              <mat-header-cell *matHeaderCellDef>
                  <span>Paralegal.</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let case">
                  <span>
                      {{case.paralegalUserFirstName}} {{case.paralegalUserLastName}}
                  </span>
                </mat-cell>
              </ng-container>

          <ng-container matColumnDef="companyGroup">
            <mat-header-cell *matHeaderCellDef>
                <span mat-sort-header="companyGroupName">Company.</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let case"> {{case.companyGroupName}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="instructedDate">
            <mat-header-cell *matHeaderCellDef>
                <span mat-sort-header>Instructed Date.</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let case"> {{case.instructedDate  | date:'dd/MM/yyyy'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="caseType">
            <mat-header-cell *matHeaderCellDef>
                <span mat-sort-header="caseTypeName">Type.</span>
              </mat-header-cell>
              <mat-cell *matCellDef="let case"> {{case.caseTypeName}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="partyOneFirstName">
              <mat-header-cell *matHeaderCellDef>
                  <span mat-sort-header>First Name.</span>
              </mat-header-cell>
              <mat-cell *matCellDef="let case"> {{case.partyOneFirstName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="partyOneLastName">
              <mat-header-cell *matHeaderCellDef>
                  <span mat-sort-header>Last Name.</span>
              </mat-header-cell>
              <mat-cell *matCellDef="let case"> {{case.partyOneLastName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="partyOneAddressLine1">
              <mat-header-cell *matHeaderCellDef>
                  <span mat-sort-header>Party 1 Add Line 1.</span>
              </mat-header-cell>
              <mat-cell *matCellDef="let case"> {{case.partyOneAddressLine1}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="partyOnePostCode">
              <mat-header-cell *matHeaderCellDef>
                  <span mat-sort-header>Party 1 Postcode.</span>
              </mat-header-cell>
              <mat-cell *matCellDef="let case"> {{case.partyOnePostcode}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="propertyPostcode">
                <mat-header-cell *matHeaderCellDef>
                    <span mat-sort-header>Prop Postcode.</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let case"> {{case.postcode}} </mat-cell>
              </ng-container>

            <ng-container matColumnDef="propertyAddressLine1">
                <mat-header-cell *matHeaderCellDef>
                    <span mat-sort-header>Prop Add Line 1.</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let case"> {{case.propertyAddressLine1}} </mat-cell>
              </ng-container>


          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
          <mat-row *matRowDef="let case; columns: displayedColumns;" [routerLink]="['/case', case.id]" class="clickable-list-item"></mat-row>

        </table>

        <mat-spinner color="primary" diameter="20" *ngIf="isLoadingResults"></mat-spinner>
        <mat-paginator [length]="resultsLength" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]" [showFirstLastButtons]="true">
          </mat-paginator>

        </div>
        </app-card-basic>

    </div>

  </div>
