import { Component, OnInit, Inject } from '@angular/core';
import { AuditService } from '../../shared/services/audit.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-audit-form',
  templateUrl: './audit-form.component.html',
  styleUrls: ['./audit-form.component.css']
})
export class AuditFormComponent implements OnInit {

  formName: string = '';
  caseId: number;
  itemId: number;
  auditFormItems: any []= [];

  displayedColumns: string[] = ['fieldName', 'oldValue', 'newValue', 'byUser', 'dateChanged'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private auditService: AuditService) {

    this.formName = data.formName;
    this.caseId = data.caseId;
    this.itemId = data.itemId;

    if(this.caseId != null && this.caseId > 0)
    {
      this.auditService.getAuditItemsForCaseForm( this.formName, this.caseId ).subscribe((response: any)=>{

        this.auditFormItems = response;

        console.log('this.getAuditItemsForCaseForm', this.auditFormItems);

        //[{"fieldName":"solarPanelsCompany","oldValue":"test","newValue":"Hello There","byUser":"Lisa Rich","dateChanged":"28-07-2021 21:26:19"}]

      });
    }
    else{
      this.auditService.getAuditItemsForForm( this.formName, this.itemId ).subscribe((response: any)=>{

        this.auditFormItems = response;

        console.log('this.getAuditItemsForForm', this.auditFormItems);


      });
    }


  }

  ngOnInit(): void {
  }

}
