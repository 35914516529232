<div class="wrapper" *ngIf="case" [ngClass]="{'small': isSmall, 'medium': isMedium}">
  <app-info-item *ngIf="!case.caseData.onHold" [isMedium]="isMedium" title="Case Status" [data]="case.caseData.caseStatusTypeText"></app-info-item>
  <app-info-item  *ngIf="case.caseData.onHold" [isMedium]="isMedium" title="Case Status" [data]="case.caseData.caseStatusTypeText + ' - ON HOLD'"></app-info-item>
  <app-info-item [isMedium]="isMedium" title="Case Type" [data]="case.caseData.type.caseTypeName"></app-info-item>
  <app-info-item *ngIf="case.caseData.type.id == 0" [isMedium]="isMedium" title="Other Type" [data]="case.caseData.caseTypeOther"></app-info-item>
  <app-info-item [isMedium]="isMedium" title="Case Reference" [data]="case.caseData.caseReference"></app-info-item>
  <app-info-item [isMedium]="isMedium" title="Client Name" [data]="case.caseData.partyOneFirstName + ' ' + case.caseData.partyOneLastName">
    <span style="margin-left: 5px; margin-top: -2px;">
      <fa-icon *ngIf="case.caseCreditCheckStatusClient1 == 'Unknown' || case.caseCreditCheckStatusClient1 == null"
               [icon]="faQuestionCircle"
               size="1x"
               class="clickable"
               (click)="editCreditSafe(1)"></fa-icon>
      <fa-icon *ngIf="case.caseCreditCheckStatusClient1 == 'Success'"
               [icon]="faCheckCircle"
               size="1x"
               class="clickable check-success"
               (click)="editCreditSafe(1)"></fa-icon>
      <fa-icon *ngIf="case.caseCreditCheckStatusClient1 == 'Error' || case.caseCreditCheckStatusClient1 == 'Fail'"
               [icon]="faExclamationCircle"
               size="1x"
               class="clickable check-danger"
               (click)="editCreditSafe(1)"></fa-icon>
    </span>
  </app-info-item>
  <app-info-item [isMedium]="isMedium" title="Client DOB" [data]="formatDate(case.caseData.partyOneDateOfBirth)"></app-info-item>
  <app-info-item *ngIf="case.caseData.partyTwoFirstName && case.caseData.partyTwoFirstName.length > 0" [isMedium]="isMedium" title="Client 2 Name" [data]="case.caseData.partyTwoFirstName + ' ' + case.caseData.partyTwoLastName">
    <span style="margin-left: 5px; margin-top: -2px;">
      <fa-icon *ngIf="case.caseCreditCheckStatusClient2 == 'Unknown' || case.caseCreditCheckStatusClient2 == null"
               [icon]="faQuestionCircle"
               size="1x"
               class="clickable"
               (click)="editCreditSafe(2)"></fa-icon>
      <fa-icon *ngIf="case.caseCreditCheckStatusClient2 == 'Success'"
               [icon]="faCheckCircle"
               size="1x"
               class="clickable check-success"
               (click)="editCreditSafe(2)"></fa-icon>
      <fa-icon *ngIf="case.caseCreditCheckStatusClient2 == 'Error' || case.caseCreditCheckStatusClient2 == 'Fail'"
               [icon]="faExclamationCircle"
               size="1x"
               class="clickable check-danger"
               (click)="editCreditSafe(2)"></fa-icon>
    </span>
  </app-info-item>
  <div [ngClass]="{'medium': isMedium}"><strong>Allocated Admin : </strong>
    <span *ngIf="case.caseData.allocatedToAdminUser">{{case.caseData.allocatedToAdminUser.firstName + ' ' + case.caseData.allocatedToAdminUser.lastName}} </span>
    <span *ngIf="!case.caseData.allocatedToAdminUser">UNALLOCATED</span>
  </div>
  <div [ngClass]="{'medium': isMedium}"><strong>Allocated Paralegal : </strong>
    <span *ngIf="case.caseData.allocatedToParalegalUser">{{case.caseData.allocatedToParalegalUser.firstName + ' ' + case.caseData.allocatedToParalegalUser.lastName}} </span>
    <span *ngIf="!case.caseData.allocatedToParalegalUser">UNALLOCATED</span>
  </div>
  <div [ngClass]="{'medium': isMedium}"><strong>Authorised Signatory : </strong>
    <span *ngIf="case.caseData.authorisedSignatoryUser">{{case.caseData.authorisedSignatoryUser.firstName + ' ' + case.caseData.authorisedSignatoryUser.lastName}}</span>
     <span style="margin-left: 5px; margin-top: -2px;"><fa-icon [icon]="faPencilAlt" size="1x" class="clickable" (click)="editAuthorisedSignatory()"></fa-icon></span>
  </div>
  <app-info-item [isMedium]="isMedium" title="Client Address">
    <div class="collapse" *ngIf="case.caseData.partyOneAddressLine1">{{case.caseData.partyOneAddressLine1}}</div>
    <div class="collapse" *ngIf="case.caseData.partyOneAddressLine2">{{case.caseData.partyOneAddressLine2}}</div>
    <div class="collapse" *ngIf="case.caseData.partyOneAddressLine3">{{case.caseData.partyOneAddressLine3}}</div>
    <div class="collapse" *ngIf="case.caseData.partyOneAddressLine4">{{case.caseData.partyOneAddressLine4}}</div>
    <div class="collapse" *ngIf="case.caseData.partyOneAddressLine5">{{case.caseData.partyOneAddressLine5}}</div>
    <div class="collapse" *ngIf="case.caseData.partyOnePostcode">{{case.caseData.partyOnePostcode}}</div>
  </app-info-item>
  <app-info-item [isMedium]="isMedium" title="Contact Number" [data]="case.caseData.partyOneContactNumberOne"> </app-info-item>
  <app-info-item *ngIf="case.caseData.partyOneCorrespondenceEmail == 1" [isMedium]="isMedium" title="Email Address" [data]="case.caseData.partyOneEmailAddress"></app-info-item>
  <app-info-item *ngIf="case.caseData.type.id == 1" [isMedium]="isMedium" title="Broker" [data]="case.caseData.addedByUser.firstName + ' ' + case.caseData.addedByUser.lastName"></app-info-item>
  <app-info-item *ngIf="case.caseData.type.id == 6" [isMedium]="isMedium" title="Estate Agent" [data]="case.caseData.addedByUser.firstName + ' ' + case.caseData.addedByUser.lastName"></app-info-item>
  <app-info-item *ngIf="case.caseData.type.id == 7" [isMedium]="isMedium" title="Estate Agent" [data]="case.caseData.addedByUser.firstName + ' ' + case.caseData.addedByUser.lastName"></app-info-item>
  <app-info-item *ngIf="(case.caseData.type.id == 1 || case.caseData.type.id == 7) && case.caseDetails.lender" [isMedium]="isMedium" title="Lender" [data]="case.caseDetails.lender.name"></app-info-item>
  <app-info-item *ngIf="(case.caseData.type.id == 1 || case.caseData.type.id == 7) && !case.caseDetails.lender" [isMedium]="isMedium" title="Lender" [data]="case.caseDetails.otherLender"></app-info-item>
  <app-info-item *ngIf="(case.caseData.type.id == 1 || case.caseData.type.id == 7) && case.caseDetails.lendersSolicitorId > 0" [isMedium]="isMedium" title="Lenders Solicitor" [data]="case.caseDetails.lendersSolicitor.name"></app-info-item>
  <app-info-item *ngIf="(case.caseData.type.id == 1 || case.caseData.type.id == 7) && !case.caseDetails.lendersSolicitorId" [isMedium]="isMedium" title="Lenders Solicitor" [data]="case.caseDetails.otherLendersSolicitor"></app-info-item>
  <div *ngIf="case.caseData.type.id == 1 || case.caseData.type.id == 7" [ngClass]="{'medium': isMedium}"><strong>Lenders Solicitor Email : </strong>
    <span *ngIf="case.caseDetails.lendersSolicitorEmailId > 0">{{case.caseDetails.lendersSolicitorEmail.emailAddress}} </span>
    <span *ngIf="!case.caseDetails.lendersSolicitorEmailId">{{case.caseDetails.otherLendersSolicitorEmail}} </span>
  </div>
  <app-info-item *ngIf="case.caseData.type.id == 1 || case.caseData.type.id == 7" [isMedium]="isMedium" title="Lenders Solicitor Reference" [data]="case.caseDetails.lendersSolicitorReference"></app-info-item>
  <div *ngIf="case.caseData.type.id == 1 || case.caseData.type.id == 7" [ngClass]="{'medium': isMedium}"><strong>Consultant : </strong>
    <span *ngIf="case.caseDetails.externalConsultant">{{case.caseDetails.externalConsultant.name}} </span>
  </div>
  <div *ngIf="case.caseData.type.id == 1 || case.caseData.type.id == 7" [ngClass]="{'medium': isMedium}"><strong>Consultant Phone Number : </strong>
    <span *ngIf="case.caseDetails.externalConsultant">{{case.caseDetails.externalConsultant.phoneNumber}} </span>
  </div>

</div>
  <div class="wrapper" *ngIf="case" [ngClass]="{'small': isSmall, 'medium': isMedium}">
  <div>
      <strong>Client Correspondence Options </strong>
      <div [ngClass]="{'medium': isMedium}"><strong>Post : </strong>
        <span *ngIf="case.caseData.partyOneCorrespondenceLetter == true">Yes </span>
        <span *ngIf="case.caseData.partyOneCorrespondenceLetter == false">No </span>
      </div>
      <div [ngClass]="{'medium': isMedium}"><strong>Email : </strong>
        <span *ngIf="case.caseData.partyOneCorrespondenceEmail == true">Yes </span>
        <span *ngIf="case.caseData.partyOneCorrespondenceEmail == false">No </span>
      </div>
      <div [ngClass]="{'medium': isMedium}"><strong>Text Message : </strong>
        <span *ngIf="case.caseData.partyOneCorrespondenceSMS == true">Yes </span>
        <span *ngIf="case.caseData.partyOneCorrespondenceSMS == false">No </span>
      </div>

  </div>

</div>
