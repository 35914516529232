import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { faSignOut, faPlus, faUsers, faFileChartLine, faUser, faBuilding, faCog, faHome, faSearch, faUserTie, faCalendarAlt,
  faTasks, faLongArrowAltRight, faLongArrowAltLeft, faFolders, faHouse, faPoundSign, faGavel, faEnvelope, faBell, faFileInvoice } from '@fortawesome/pro-light-svg-icons';
import { AuthService } from 'src/app/auth/auth.service';
import { SidebarService } from 'src/app/shared/components/sidebar/sidebar.service';

@Component({
  selector: 'app-dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  styleUrls: ['./dashboard-sidebar.component.scss']
})
export class DashboardSidebarComponent implements OnInit {

  faHome = faHome;
  faSignOut = faSignOut;
  faPlus = faPlus;
  faUsers = faUsers;
  faBuiling = faBuilding;
  faFileChartLine = faFileChartLine;
  faUser = faUser;
  faCog = faCog;
  faSearch = faSearch;
  faUserTie = faUserTie;
  faCalendarAlt = faCalendarAlt;
  faTasks = faTasks;
  faLongArrowAltRight = faLongArrowAltRight;
  faLongArrowAltLeft = faLongArrowAltLeft;
  faFolders= faFolders;
  faHouse = faHouse;
  faPoundSign = faPoundSign;
  faGavel = faGavel;
  faEnvelope= faEnvelope;
  faBell = faBell;
  faFileInvoice = faFileInvoice;

  isSmall = false;
  isMedium = false;
  showSidebar = false;
  hideContent = true;

  constructor(public authService: AuthService, breakpointObserver: BreakpointObserver, private sidebarService: SidebarService) {

    /*
    const layoutChanges = breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall]);

    layoutChanges.subscribe(result => {

      this.isSmall = breakpointObserver.isMatched([Breakpoints.Small, Breakpoints.XSmall]);
      this.isMedium = breakpointObserver.isMatched([Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall]);

      if (this.isSmall) {
        if (this.hideContent == false) {
          sidebarService.closeSideBar();
        }
        this.hideContent = true;
      } else {
        this.hideContent = false;
      }
    });*/

    sidebarService.showSideBarObservable.subscribe(result => {
      this.showSidebar = result;
    });
   }

  ngOnInit() {
  }

  showSideBarContent() {

  }

  toggleSideBar(){
    this.hideContent = true;
    this.isSmall = true;
    this.sidebarService.toggleSideBar();


  }

}
