<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Form Change History</h2>

<h3 *ngIf="auditFormItems.length == 0">No Changes</h3>

<mat-dialog-content class="mat-typography" *ngIf="auditFormItems.length > 0">
<table mat-table [dataSource]="auditFormItems" >

    <ng-container matColumnDef="fieldName">
      <mat-header-cell *matHeaderCellDef > Field. </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.fieldName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="oldValue">
        <mat-header-cell *matHeaderCellDef > Old Value. </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{item.oldValue}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="newValue">
        <mat-header-cell *matHeaderCellDef > New Value. </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{item.newValue}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="byUser">
        <mat-header-cell *matHeaderCellDef > User. </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{item.byUser}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dateChanged">
        <mat-header-cell *matHeaderCellDef > Date Updated. </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{item.dateChanged}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let item; columns: displayedColumns;" >
      </mat-row>

</table>
</mat-dialog-content>
