import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SearchResult } from './interfaces/search-result';
import { SearchRequest } from './interfaces/search-request';
import { Observable } from 'rxjs/internal/Observable';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class EnquiryChecklistService {

  private caseUrl = environment.apiUrl + 'enquirieschecklist/';

  constructor(private http: HttpClient) { } 

  getEnquiryChecklistForCase(caseId: number) {
    return this.http.get<any[]>(this.caseUrl + 'forcase/' + caseId);
  }


  getEnquiry(enquiryId: number) {
    return this.http.get<any>(this.caseUrl + enquiryId);
  }

  addEnquiry(caseId: number, value: any) {
    let formData = {
      caseId: caseId,
      enquiry: value.enquiry,
      receivedDate: value.receivedDate
    };
    return this.http.post<any>(this.caseUrl + caseId, formData);
  }


  updateEnquiry(caseId: number, enquiryId: number, value: any) {
    let formData = {
      caseId: caseId,
      enquiryId: enquiryId,
      value: value
    };

    return this.http.post<any>(this.caseUrl + caseId, formData);
  }

  archive(enquiryId: number) {
    return this.http.post<any>(this.caseUrl + enquiryId + '/Archive', enquiryId);
  }

  setDateReceived(enquiryId: number, dateReceived: Date) {

    return this.http.post<any>(this.caseUrl + enquiryId + '/SetDateReceived', dateReceived);
  }
  setDateReplied(enquiryId: number, dateReplied?: Date) {
    return this.http.post<any>(this.caseUrl + enquiryId + '/SetDateReplied', { date: dateReplied });
  }
  setDateSatisfied(enquiryId: number, dateSatisfied?: Date) {
   
    return this.http.post<any>(this.caseUrl + enquiryId + '/SetDateSatisfied', { date: dateSatisfied });
  }
  setEnquiry(enquiryId: number, enquiry: string) {

    return this.http.post<any>(this.caseUrl + enquiryId + '/SetEnquiry', { enquiry: enquiry });
  }

}
