import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SearchRequest } from './interfaces/search-request';
import { SearchResult } from './interfaces/search-result';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class EmailService {

  private url = environment.apiUrl + 'email/';

  constructor(private http: HttpClient, public router: Router) {

  }


  sendEmail(caseId: number, email: any){
    return this.http.put(this.url + caseId + '/sendemail/', email, httpOptions);
  }

  getEmailAddressesForCase(caseId: number){
    return this.http.get<any>(this.url + caseId + '/caseemailaddresses/');
  }

  getEmailSignature(){
    return this.http.get<any>(this.url + 'signature/');
  }

  getEmailSubject(caseId: number){
    return this.http.get<any>(this.url + caseId + '/emailsubject/');
  }

  saveDraftEmail(caseId: number, email: any){
    return this.http.put(this.url + caseId + '/savedraft/', email, httpOptions);
  }

  deleteDraft(caseId: number, draftId: number){
    return this.http.get<any>(this.url + caseId + '/deletedraft/' + draftId);
  }

  getDraftEmail(caseId: number){
    return this.http.get<any>(this.url + caseId + '/draftemail/');
  }

  getDraftEmailAttachments(caseId: number, draftId: number){
    return this.http.get<any>(this.url + caseId + '/draftemailattachments/' + draftId);
  }


}
