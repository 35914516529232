<div class="wrapper">
  <div class="quick-action" >
    <app-sidebar-button *ngIf="caseData.allocatedToParalegalUser" [icon]="faPhone" tooltip="Call In/Out" (click)="openPhone()"></app-sidebar-button>
    <app-sidebar-button  *ngIf="caseData.allocatedToParalegalUser" [icon]="faSms" tooltip="Send Text" (click)="openSms()"></app-sidebar-button>
    <app-sidebar-button *ngIf="caseData.allocatedToParalegalUser" [icon]="faAt" tooltip="Send Email" (click)="openEmail()"></app-sidebar-button>
    <app-sidebar-button *ngIf="caseData.allocatedToParalegalUser" [icon]="faEnvelopeOpenText" tooltip="Create Ad Hoc Letter" (click)="openAdhocLetter()"></app-sidebar-button>
    <app-sidebar-button *ngIf="caseData.allocatedToParalegalUser" [icon]="faFax" tooltip="Send Fax" (click)="openFax()"></app-sidebar-button>
  </div>

  <div class="menu-items">
    <!--app-sidebar-item [routerLink]="'/'" [icon]="faHome" title="Home"></app-sidebar-item-->
    <app-sidebar-item [icon]="faEye" [title]="'Case Overview'" (click)="openCaseOverview()"></app-sidebar-item>
    <app-sidebar-item [icon]="faPencilAlt" [title]="'Case Notes'" (click)="openCaseNotes()"></app-sidebar-item>
    <app-sidebar-item *ngIf="(caseData.type.id == 1 || caseData.type.id == 6 || caseData.type.id == 7 || caseData.type.id == 8) && caseData.allocatedToParalegalUser" [icon]="faSearchLocation" [title]="'Searches and Land Registry'" (click)="openSearches()"></app-sidebar-item>
    <app-sidebar-item *ngIf="caseData.allocatedToParalegalUser" [icon]="faIdCard" [title]="'ID Check'" (click)="openIDCheck()"></app-sidebar-item>
    <app-sidebar-item *ngIf="caseData.allocatedToParalegalUser" [icon]="faCalendarDay" [title]="'Key Dates'" (click)="openKeyDates()"></app-sidebar-item>
    <app-sidebar-item *ngIf="caseData.allocatedToParalegalUser" [icon]="faTasks" [title]="'Tasks'" (click)="openCaseTasks()"></app-sidebar-item>
    <app-sidebar-item *ngIf="(caseData.type.id == 1 || caseData.type.id == 6 || caseData.type.id == 7 || caseData.type.id == 8) && caseData.allocatedToParalegalUser" [icon]="faHouseUser" [title]="'Property Details'" (click)="openPropertyDetails()"></app-sidebar-item>
    <app-sidebar-item *ngIf="caseData.type.id == 1 && caseData.allocatedToParalegalUser" [title]="'Mortgage Details'" (click)="openMortageDetails()">
      <fa-layers [fixedWidth]="true">
        <fa-icon [icon]="faHouse" size="lg" transform="shrink-2 left-2 up-2"></fa-icon>
        <fa-icon [icon]="faCoins" size="lg" [inverse]="true" transform=" right-7 down-7"></fa-icon>
      </fa-layers>
    </app-sidebar-item>
    <app-sidebar-item *ngIf="(caseData.type.id == 6 || caseData.type.id == 7 || caseData.type.id == 8) && caseData.allocatedToParalegalUser" [icon]="faSign" [title]="'Estate Agent Details'" (click)="openEstateAgent()"></app-sidebar-item>
    <app-sidebar-item *ngIf="caseData.type.id == 1 && caseData.allocatedToParalegalUser" [title]="'Broker Details'" (click)="openBrokerDetails()">
      <fa-layers [fixedWidth]="true">
        <fa-icon [icon]="faUserTie" size="lg"></fa-icon>
        <fa-icon [icon]="faClipboard" size="sm" [inverse]="true" transform=" right-15 up-6 rotate-20"></fa-icon>
      </fa-layers>
    </app-sidebar-item>
    <app-sidebar-item *ngIf="caseData.allocatedToParalegalUser" [icon]="faEdit" [title]="'Letters'" (click)="openCreateLetters()"></app-sidebar-item>
    <!--app-sidebar-item *ngIf="caseData.type.id == 1 " [icon]="faBookReader" [title]="'File Cover Letters'" ></app-sidebar-item-->
    <!--app-sidebar-item [title]="'Offer Letters'">
    <fa-layers [fixedWidth]="true">
      <fa-icon [icon]="faFile" size="lg"></fa-icon>
      <fa-layers-text content="?" style="color: white;" transform="shrink-5 down-3"></fa-layers-text>
    </fa-layers>
  </app-sidebar-item>
  <app-sidebar-item [title]="'File Cover Letters'">
    <fa-layers [fixedWidth]="true">
      <fa-icon [icon]="faFile" size="lg"></fa-icon>
      <fa-layers-text content="?" style="color: white;" transform="shrink-5 down-3"></fa-layers-text>
    </fa-layers>
  </app-sidebar-item-->
    <!--app-sidebar-item title="Cashrooms">
    <fa-layers [fixedWidth]="true">
      <fa-icon [icon]="faHandHolding" size="lg"></fa-icon>
      <fa-layers-text content="£" style="color: white;" transform="shrink-2 up-4"></fa-layers-text>
    </fa-layers>
  </app-sidebar-item-->
    <app-sidebar-item *ngIf="caseData.type.id == 1 && caseData.allocatedToParalegalUser" [icon]="faListOl" [title]="'Pre-submission Checklist'" (click)="openPresubmissionChecklist()"> </app-sidebar-item>
    <app-sidebar-item *ngIf="(caseData.type.id == 6 || caseData.type.id == 7) && caseData.allocatedToParalegalUser" [icon]="faListOl" [title]="'Pre-Exchange Checklist'" (click)="openPreexchangeChecklist()"></app-sidebar-item>
    <!--app-sidebar-item *ngIf="caseData.isCompleted" [icon]="faDoorClosed" [title]="'File Closure Form'" (click)="openFileClosureForm()"></app-sidebar-item-->
    <app-sidebar-item *ngIf="caseData.allocatedToParalegalUser" [icon]="faWallet" [title]="'Bank Details'" (click)="openBankDetails()"></app-sidebar-item>
    <app-sidebar-item *ngIf="caseData.allocatedToParalegalUser" [icon]="faPoundSign" [title]="'Pre-Invoice Disbursements'" [disabled]="caseData.klyantMatterId == null" (click)="openPreInvoiceFees()"></app-sidebar-item>
    <app-sidebar-item *ngIf="caseData.allocatedToParalegalUser" [icon]="faFileInvoiceDollar" [title]="'Invoicing'" [disabled]="caseData.klyantMatterId == null" (click)="openInvoicing()"></app-sidebar-item>
    <app-sidebar-item *ngIf="caseData.allocatedToParalegalUser" [icon]="faPiggyBank" [title]="'Transactions'" [disabled]="caseData.klyantMatterId == null" (click)="openTransactions()">
      <!-- <fa-layers [fixedWidth]="true">
        <fa-icon [icon]="faPoundSign" size="1x" transform="left-3"></fa-icon>
        <fa-icon [icon]="faArrowRight" size="xs" [inverse]="true" transform=" right-17 up-9"></fa-icon>
        <fa-icon [icon]="faArrowLeft" size="xs" [inverse]="true" transform=" right-17 down-9"></fa-icon>
      </fa-layers> -->
    </app-sidebar-item>    
    <app-sidebar-item *ngIf="caseData.allocatedToParalegalUser" [icon]="faMoneyCheckAlt" [title]="'Funds on Account'" (click)="openCaseFunds()"></app-sidebar-item>
    <app-sidebar-item *ngIf="caseData.allocatedToParalegalUser" [icon]="faCommentAltCheck" [title]="'Enquiries Checklist'" (click)="openEnquiriesChecklist()"> </app-sidebar-item>
    <app-sidebar-item *ngIf="caseData.allocatedToParalegalUser && (caseData.type.id == 1 || caseData.type.id == 6 ||caseData.type.id == 7 || caseData.type.id == 8)" [icon]="faCrown" [title]="'SDLT HMRC'" (click)="openHMRCSDLTLink()"></app-sidebar-item>
    <!-- <app-sidebar-item *ngIf="caseData.allocatedToParalegalUser" [icon]="faFileInvoice" [title]="'Supplier Invoices'" (click)="openSupplierInvoices()"></app-sidebar-item> -->
    <app-sidebar-item *ngIf="caseData.typeId != 0" [icon]="faArrowToLeft" [title]="'View in Portal 1'" (click)="openPortal1Case()"></app-sidebar-item>
    <app-sidebar-item *ngIf="!caseData.isCancelled && !caseData.onHold && !caseData.isCompleted && caseData.allocatedToParalegalUser" [icon]="faPause" [title]="'Hold Case'" (click)="putCaseOnHold()"></app-sidebar-item>
    <app-sidebar-item *ngIf="caseData.onHold" [icon]="faPlay" [title]="'Off-hold Case'" (click)="offHoldCase()"></app-sidebar-item>
  </div>

</div>
