<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title>Notification History</h2>

<div mat-dialog-content class="mat-typography">
    <table mat-table [dataSource]="notificationHistory" >

        <ng-container matColumnDef="notificationHistoryTypeName">
          <mat-header-cell *matHeaderCellDef > History Type. </mat-header-cell>
          <mat-cell *matCellDef="let hist" > {{hist.notificationHistoryTypeName}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="byUser" >
          <mat-header-cell *matHeaderCellDef> By User</mat-header-cell>
          <mat-cell *matCellDef="let hist"> {{hist.byUser.firstName}} {{hist.byUser.lastName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateOccured">
          <mat-header-cell *matHeaderCellDef> Date. </mat-header-cell>
          <mat-cell *matCellDef="let hist"> {{formatDateWithTime(hist.dateOccured)}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="fromUser" >
            <mat-header-cell *matHeaderCellDef> From User</mat-header-cell>
            <mat-cell *matCellDef="let hist"> <span *ngIf="hist.fromUser">{{hist.fromUser.firstName}} {{hist.fromUser.lastName}}</span> </mat-cell>
          </ng-container>

          <ng-container matColumnDef="toUser" >
              <mat-header-cell *matHeaderCellDef> To User</mat-header-cell>
              <mat-cell *matCellDef="let hist"> <span *ngIf="hist.fromUser">{{hist.toUser.firstName}} {{hist.toUser.lastName}}</span> </mat-cell>
            </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
        <mat-row *matRowDef="let hist; columns: displayedColumns;" ></mat-row>
    </table>
</div>
