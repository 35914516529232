import { Component, OnInit, Inject } from '@angular/core';
import { FileTypes } from 'src/app/shared/constants/constants';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faCoffee, faEllipsisH, faPaperclip, faEnvelopeOpen, faEnvelopeOpenText, faPhone, faFax, faSms, faFile, faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { faArrowAltLeft, faArrowAltRight } from '@fortawesome/pro-solid-svg-icons';
import { CorrespondenceTimelineService } from '../../../shared/services/correspondence-timeline.service';

@Component({
  selector: 'app-pdf-creator',
  templateUrl: './pdf-creator.component.html',
  styleUrls: ['./pdf-creator.component.css']
})
export class PdfCreatorComponent {

  caseId: number;
  items: any[] = [];
  generatingPDF: boolean = false;
  createButtonDisabled: boolean = true;
  fileName: string = '';
  generatingError: boolean = false;
  errorMessage: string = "";

  faCoffee = faCoffee;
  faPaperclip = faPaperclip;
  faArrowAltRight = faArrowAltRight;
  faArrowAltLeft = faArrowAltLeft;
  faEllipsisH = faEllipsisH;
  faEnvelopeOpen = faEnvelopeOpen;
  faEnvelopeOpenText = faEnvelopeOpenText;
  faPhone = faPhone;
  faFax = faFax;
  faSms = faSms;
  faFile = faFile;
  faFilePdf = faFilePdf;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<PdfCreatorComponent>,
              private correspondenceTimelineService: CorrespondenceTimelineService) {

      this.caseId = data.caseId;
      this.items = data.items;

      this.validateForm();
   }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
  }

  getFileTypeIcon(format: any){

    if(!format) format = 0;

    var ft = FileTypes.find(ft => ft.id === format);
    if(!ft)
      return faFile;
    else
      return ft.icon;
  }

  createPDF(){
    this.generatingPDF = true;

    let itemIds: number[] = [];
    this.items.forEach(item => {
      itemIds.push(item.id);
    });

    this.correspondenceTimelineService.stitchItems( this.caseId , this.fileName, itemIds).subscribe(response => {

      if(response.success)
      {
        this.dialogRef.close();
        this.correspondenceTimelineService.refreshCaseTimeline.next();
      }
      else{
        this.generatingError = true;
        this.errorMessage = response.errorText;
      }

    }, error => {

      this.generatingError = true;
      if(error.status == 999)
        this.errorMessage = error.error;
      else if(error.status == 500 )
        this.errorMessage = "Internal Error";
      else
        this.errorMessage = error.statusText;

    });
  }

  validateForm(){
    if(this.fileName.length > 0)
      this.createButtonDisabled = false;
}

}
