
<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">File Closure</h2>

<mat-dialog-content class="mat-typography" style="text-align: center;" *ngIf="hasError">
  <h3 class="error">An Error Occurred</h3>
  <div class="error">
    {{errorMessage}}
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="formLoaded && !hasError">

  <form [formGroup]="fileClosureDetails" autocomplete="off">
      <div class="formRowItem">
          <span class="label">Client Name:</span><span class="itemText">{{fileClosureDetails.controls['clientName'].value}}</span>
       </div>

       <div class="formRowItem">
          <span class="label">Client Reference Number:</span><span class="itemText">{{fileClosureDetails.controls['caseReference'].value}}</span>
       </div>

       <div class="formRowItem">
          <span class="label">Paralegal:</span><span class="itemText">
            <span >
                {{fileClosureDetails.controls['paralegalUser'].value?.firstName}} {{fileClosureDetails.controls['paralegalUser'].value?.lastName}}
              </span>
            </span>
       </div>

       <div class="formRowItem">
          <span class="label">Colfr:</span><span class="itemText">
          <span >
              {{fileClosureDetails.controls['colfrUser'].value?.firstName}} {{fileClosureDetails.controls['colfrUser'].value?.lastName}}
            </span>
          </span>
       </div>

       <div class="formRowItem">
          <span class="label">Admin:</span><span class="itemText">
          <span>
              {{fileClosureDetails.controls['adminUser'].value?.firstName}} {{fileClosureDetails.controls['adminUser'].value?.lastName}}
            </span>
          </span>
       </div>

       <hr>

       <div class="checkboxgroup">
        <div class="label">Reason for Closure (tick as appropriate)</div>

          <mat-checkbox formControlName="workCompleted"  (change)="updateValue()">Work completed  </mat-checkbox>

        <mat-checkbox  formControlName="matterAborted" (change)="updateValue()">Matter Aborted  </mat-checkbox>

        <mat-checkbox  formControlName="noContactFromClient" (change)="updateValue()">No contact from client </mat-checkbox>
        <br>

        <mat-checkbox  formControlName="clientChangeSolicitor" (change)="updateValue()">Client change solicitor  </mat-checkbox>

        <mat-checkbox  formControlName="clientNoLongerWishesToProceed" (change)="updateValue()">Client not longer wishes to proceed  </mat-checkbox>

        <mat-checkbox  formControlName="closureReasonOther" (change)="updateValue()">Other </mat-checkbox>
      </div>


       <div class="formRowItem">
          <span class="label">Objectives met:</span>
            <mat-button-toggle-group aria-label="Font Style" formControlName="objectivesMet" (change)="updateValue()">
              <mat-button-toggle [value]="1">Yes</mat-button-toggle>
              <mat-button-toggle [value]="2">No</mat-button-toggle>
              <mat-button-toggle [value]="3">N/A</mat-button-toggle>
            </mat-button-toggle-group>
       </div>

       <div class="formRowItem">
          <span class="label">Future review date:</span>
            <mat-button-toggle-group aria-label="Font Style" formControlName="futureReviewDate" (change)="updateValue()">
              <mat-button-toggle [value]="1">Yes</mat-button-toggle>
              <mat-button-toggle [value]="2">No</mat-button-toggle>
              <mat-button-toggle [value]="3">N/A</mat-button-toggle>
            </mat-button-toggle-group>
       </div>

       <div class="formRowItem">
          <span class="label">Client advising regarding storage:</span>
            <mat-button-toggle-group aria-label="Font Style" formControlName="clientAdvisingRegardingStorage" (change)="updateValue()">
              <mat-button-toggle [value]="1">Yes</mat-button-toggle>
            </mat-button-toggle-group>
       </div>

       <div class="formRowItem">
          <span class="label">Further action notified:</span>
            <mat-button-toggle-group aria-label="Font Style" formControlName="furtherActionNotified" (change)="updateValue()">
              <mat-button-toggle [value]="1">Yes</mat-button-toggle>
              <mat-button-toggle [value]="2">No</mat-button-toggle>
              <mat-button-toggle [value]="3">N/A</mat-button-toggle>
            </mat-button-toggle-group>
       </div>

      <div class="formRowItem">
          <span class="label">Papers/items returned to client:</span>
            <mat-button-toggle-group aria-label="Font Style" formControlName="papersItemsReturnedToClient" (change)="updateValue()">
              <mat-button-toggle [value]="1">Yes</mat-button-toggle>
              <mat-button-toggle [value]="2">No</mat-button-toggle>
              <mat-button-toggle [value]="3">N/A</mat-button-toggle>
            </mat-button-toggle-group>
       </div>

       <span>Destroy date here</span>

       <div class="checkboxgroup">
       <mat-checkbox formControlName="ledgerClear"  (change)="updateValue()">Ledger Clear</mat-checkbox>

       <mat-checkbox  formControlName="writeOffs" (change)="updateValue()">Write Offs</mat-checkbox>


       <app-card-label label="Specify reasons" *ngIf="fileClosureDetails.controls['writeOffs'].value == 1">
          <mat-form-field floatLabel="never">
            <input matInput formControlName="writeOffsReason" (change)="updateValue()" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Amount Written Off" *ngIf="fileClosureDetails.controls['writeOffs'].value == 1">
            <mat-form-field floatLabel="never">
              <span matPrefix>£ &nbsp;</span>
              <input matInput formControlName="amountWrittenOff" (change)="updateValue()" type="number">
            </mat-form-field>
          </app-card-label>
       </div>

          <div class="checkboxgroup">
        <div class="label">Risk Management:</div>

          <mat-checkbox formControlName="billRaised"  (change)="updateValue()">Bill raised  </mat-checkbox>

          <mat-checkbox  formControlName="allDisinbursementsDischarged" (change)="updateValue()">All disinbursements discharged?  </mat-checkbox>
          <br>
          <mat-checkbox  formControlName="clientAccountedToForAllMonies" (change)="updateValue()">Client accounted to for all monies</mat-checkbox>

          <mat-checkbox  formControlName="clientSentFileClosingLetterAndAdviseReLimitation" (change)="updateValue()">Client sent file closing letter & advise re limitation</mat-checkbox>
          <br>
          <mat-checkbox  formControlName="idOnFile" (change)="updateValue()">ID on File </mat-checkbox>

          <mat-checkbox  formControlName="wasThereAPepAlertOnFile" (change)="updateValue()">Was there a PEP alert on File</mat-checkbox>
          <br>
          <mat-checkbox  formControlName="amlChecksCarriedOut" (change)="updateValue()">AML checks carried out?  </mat-checkbox>
          <mat-checkbox  formControlName="signedTermsOfBusinessOnFile" (change)="updateValue()">Signed terms of Business on File </mat-checkbox>
        </div>

       <div class="formRowItem">
          <span class="label">File Closure Date:</span><span class="itemText">
          <span>
              {{fileClosureDetails.controls['fileClosureDate'].value  | date:'dd/MM/yyyy'}}
            </span>
          </span>
       </div>

       <div class="formRowItem">
          <span class="label">Admin Accessed:</span><span class="itemText">
          <span>
              {{fileClosureDetails.controls['dateFormOpenedByAdmin'].value  | date:'dd/MM/yyyy'}}
            </span>
          </span>
       </div>

       <hr>
       <div class="label sectionheader">Management</div>

       <div class="formRowItem">
          <span class="label">Closing Risk Assessment:</span>
            <mat-button-toggle-group aria-label="Font Style" formControlName="closingRiskAssessment" (change)="updateValue()">
              <mat-button-toggle [value]="1">Low</mat-button-toggle>
              <mat-button-toggle [value]="2">Medium</mat-button-toggle>
              <mat-button-toggle [value]="3">High</mat-button-toggle>
            </mat-button-toggle-group>
       </div>

      <div class="formRowItem">
          <span class="label">Authorised By:</span><span class="itemText">
          <span>
            {{fileClosureDetails.controls['authorisedByManagementUser'].value?.firstName}} {{fileClosureDetails.controls['authorisedByManagementUser'].value?.lastName}}
            </span>
          </span>
       </div>

       <div class="formRowItem">
          <span class="label">Signed:</span><span class="itemText">
          <span>
              sig will go here
            </span>
          </span>
       </div>

       <div class="formRowItem">
          <span class="label">Dated:</span><span class="itemText">
          <span>
              {{fileClosureDetails.controls['dateFormOpenedByManagement'].value  | date:'dd/MM/yyyy'}}
            </span>
          </span>
       </div>

       <hr>

       <div class="formRowItem">
          <span class="label">Authorised By:</span><span class="itemText">
          <span>
              {{fileClosureDetails.controls['authorisedByPartnerUser'].value?.firstName}} {{fileClosureDetails.controls['authorisedByPartnerUser'].value?.lastName}}
            </span>
          </span>
       </div>

       <div class="formRowItem">
          <span class="label">Signed:</span><span class="itemText">
          <span>
              sig will go here
            </span>
          </span>
       </div>

       <div class="formRowItem">
          <span class="label">Dated:</span><span class="itemText">
          <span>
              {{fileClosureDetails.controls['riskAssessmentDateTime'].value  | date:'dd/MM/yyyy'}}
            </span>
          </span>
       </div>

  </form>
</mat-dialog-content>

