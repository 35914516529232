<h1>{{title}}</h1>

<form autocomplete="off" [formGroup]="taskForm">

  <mat-form-field class="example-full-width" *ngIf="!isNote && users && users.length != 0">
    <input type="text" placeholder="User" aria-label="user" matInput [formControl]="searchInput"
      [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" >
      <mat-option *ngFor="let option of filteredUsers | async" [value]="option" (optionSelected)="checkValid()" (closed)="checkValid()" (opened)="checkValid()" (optionActivated)="checkValid()">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field *ngIf="!isNote">
    <input matInput [matDatepicker]="dueDate" [max]="maxDate" [min]="minDate" placeholder="Due By Date" formControlName="dueByDate" (dateInput)="checkValid()" (dateChange)="checkValid()">
    <mat-datepicker-toggle matSuffix [for]="dueDate"></mat-datepicker-toggle>
    <mat-datepicker #dueDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <textarea matInput #entry placeholder="Entry" maxlength="300" formControlName="entry" (keyup)="checkValid()"></textarea>
    <mat-hint [align]="'end'">{{entry.value?.length || 0}}/300</mat-hint>
  </mat-form-field>
  <br/>
  <div class="buttons">
    <button mat-stroked-button [disabled]="false" color="warn" type="button" (click)="cancel()">Cancel</button>
    <button mat-stroked-button [disabled]="invalidSelection" color="primary" (click)="save()">Save</button>
  </div>

</form>
