<button mat-icon-button class="close" tabindex="-1" mat-dialog-close *ngIf="!generatingPDF">x</button>
<h2 mat-dialog-title>Create PDF</h2>
<h3 mat-dialog-title *ngIf="!generatingPDF">Select files and drag them into the correct order</h3>

<mat-dialog-content class="mat-typography" *ngIf="!generatingPDF">

    <div>
        <span>File Name:</span><span><mat-form-field floatLabel="never">
           <input matInput value="" [(ngModel)]="fileName" autocomplete="off" (keyup)="validateForm()" >
         </mat-form-field></span>
     </div>

<div>

  <div class="list" cdkDropList (cdkDropListDropped)="drop($event)">
    <div class="box" *ngFor="let item of items" cdkDrag>
      <div>
        <fa-icon *ngIf="item.correspondenceTypeId == 1"  [icon]="faEnvelopeOpen" size="lg"></fa-icon>
        <fa-icon *ngIf="item.correspondenceTypeId == 2" [icon]="faEnvelopeOpenText" size="lg"></fa-icon>
        <fa-icon *ngIf="item.correspondenceTypeId == 3" [icon]="getFileTypeIcon(item.caseFile.format)" size="lg"></fa-icon>
        <fa-icon *ngIf="item.correspondenceTypeId == 4" [icon]="faPhone" size="lg"></fa-icon>
        <fa-icon *ngIf="item.correspondenceTypeId == 5" [icon]="faSms" size="lg"></fa-icon>
        <fa-icon *ngIf="item.correspondenceTypeId == 6" [icon]="faFax" size="lg"></fa-icon>
        {{item.summary}}
      </div>
    </div>
  </div>


</div>

</mat-dialog-content>

<mat-dialog-content class="mat-typography" style="text-align: center;" *ngIf="generatingPDF && !generatingError">
    <h3>Generating PDF - Please do not leave the page</h3>
    <div class="progress">
      <mat-spinner color="primary" diameter="50">
      </mat-spinner>
    </div>
</mat-dialog-content>

<mat-dialog-content class="mat-typography" style="text-align: center;" *ngIf="generatingError">
    <h3 class="error">An Error Occurred</h3>
    <div class="error">
      {{errorMessage}}
    </div>
</mat-dialog-content>

<div mat-dialog-actions align="end">
  <button mat-stroked-button cdkFocusInitial (click)="createPDF()" color="primary" *ngIf="!generatingPDF" [disabled]="createButtonDisabled">Create PDF</button>
</div>
