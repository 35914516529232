<app-card-basic cardTitle="Lenders" >
    <div card-body>

      <div class="button-bar">
        <!--app-searchbar searchType="CompanyGroups" [inline]="true"></app-searchbar-->
        <button mat-mini-fab (click)="addLender()" color="primary"><fa-icon [icon]="faPlus"></fa-icon></button>
      </div>

      <div>
        <table mat-table [dataSource]="lenders" class="clickable-list-item">
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef > Name. </mat-header-cell>
            <mat-cell *matCellDef="let lender"> {{lender.name}} </mat-cell>
          </ng-container>

            <ng-container matColumnDef="isActive">
              <mat-header-cell *matHeaderCellDef > Active. </mat-header-cell>
              <mat-cell *matCellDef="let lender"> <span [hidden]="!lender.isActive"> <fa-icon [icon]="faCheck"></fa-icon></span> </mat-cell>
            </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
          <mat-row *matRowDef="let lender; columns: displayedColumns;" (click)="editLender(lender.id)">
          </mat-row>

        </table>

      </div>
    </div>
  </app-card-basic>
