<button mat-icon-button class="close" tabindex="-1" (click)="close()" *ngIf="!generatingLetters">x</button>
<button mat-icon-button class="audit" tabindex="-1" (click)="openAuditForForm()"><fa-icon [icon]="faHistory"></fa-icon></button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Mortgage Details</h2>


<mat-dialog-content class="mat-typography" >
    <form [formGroup]="mortgageDetails" autocomplete="off" *ngIf="mortgageDetails">


        <app-card-label label="Lifetime Mortgage Lender" >
            <mat-form-field floatLabel="never">
              <mat-select formControlName="lenderId" (selectionChange)="updateField();">
                <mat-option *ngFor="let lender of lenders" [value]="lender.id">{{lender.name}}</mat-option>
                <mat-option [value]="0">Other</mat-option>
              </mat-select>
            </mat-form-field>
          </app-card-label>

        <app-card-label label="Other Lender" [hidden]="mortgageDetails.controls['lenderId'].value != 0">
          <mat-form-field floatLabel="never">
              <textarea cdkTextareaAutosize matInput #otherLender maxlength="250" formControlName="otherLender" (change)="updateField()"></textarea>
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Lenders Solicitor" >
            <mat-form-field floatLabel="never">
              <mat-select formControlName="lendersSolicitorId" (selectionChange)="updateField(); updateSolEmails();">
                <mat-option *ngFor="let lendersSolicitor of lendersSolicitors" [value]="lendersSolicitor.id">{{lendersSolicitor.name}}</mat-option>
                <mat-option [value]="0">Other</mat-option>
              </mat-select>
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Other Lenders Solicitor" [hidden]="mortgageDetails.controls['lendersSolicitorId'].value != 0">
            <mat-form-field floatLabel="never">
              <input matInput #otherLendersSolicitor maxlength="50" formControlName="otherLendersSolicitor"
                (change)="updateField()">
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Lenders Solicitor Email" [hidden]="mortgageDetails.controls['lendersSolicitorId'].value == null || mortgageDetails.controls['lendersSolicitorId'].value == -1">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="lendersSolicitorEmailId" (selectionChange)="updateField()">
                  <mat-option *ngFor="let lendersSolicitorEmail of lendersSolicitorEmails" [value]="lendersSolicitorEmail.id">{{lendersSolicitorEmail.emailAddress}}</mat-option>
                  <mat-option [value]="0">Other</mat-option>
              </mat-select>
            </mat-form-field>
          </app-card-label>

      <app-card-label label="Other Lenders Solicitor Email" *ngIf="mortgageDetails.controls['lendersSolicitorEmailId'].value == 0">
        <mat-form-field floatLabel="never">
            <input matInput formControlName="otherLendersSolicitorEmail" (change)="updateField()" >
        </mat-form-field>
      </app-card-label>

      <app-card-label label="Lender Solicitor Reference">
        <mat-form-field floatLabel="never">
          <input matInput formControlName="lenderSolicitorReference" (change)="updateField()" >
        </mat-form-field>
      </app-card-label>

      <app-card-label label="Offer Expiry Date (Key Date)">
      <mat-form-field >
          <input matInput formControlName="offerExpiryDate" (dateInput)="updateField()" (dateChange)="updateField()" readonly>
          <!--input matInput [matDatepicker]="offerExpiryDate" formControlName="offerExpiryDate" (dateInput)="updateField()" (dateChange)="updateField()" readonly>
          <mat-datepicker-toggle matSuffix [for]="offerExpiryDate"></mat-datepicker-toggle>
          <mat-datepicker #offerExpiryDate></mat-datepicker-->
        </mat-form-field>
        <button mat-icon-button (click)="openKeyDates()"><fa-icon [icon]="faCalendarDay"></fa-icon></button>
      </app-card-label>


      <app-card-label label="Name of Product">
          <mat-form-field floatLabel="never">
            <input matInput formControlName="nameOfProduct" (change)="updateField()" >
          </mat-form-field>
      </app-card-label>

      <app-card-label label="Full Loan Amount">
        <mat-form-field floatLabel="never">
          <span matPrefix>£ &nbsp;</span>
          <input matInput formControlName="fullLoanAmount" (change)="updateField()" >
        </mat-form-field>
      </app-card-label>

      <app-card-label label="Final Amount Released">
        <mat-form-field floatLabel="never">
          <span matPrefix>£ &nbsp;</span>
          <input matInput formControlName="finalAmountReleased" (change)="updateField()" >
        </mat-form-field>
      </app-card-label>

      <app-card-label label="Drawdown Available">
          <app-yes-no formControlName="drawdownAvailable" (change)="updateField()" ></app-yes-no>
      </app-card-label>

      <app-card-label label="Details" [hidden]="mortgageDetails.controls['drawdownAvailable'].value != 1">
        <mat-form-field floatLabel="never">
            <span matPrefix>£ &nbsp;</span>
          <input matInput formControlName="drawdownAvailableDetails" (change)="updateField()" >
        </mat-form-field>
      </app-card-label>

      <app-card-label label="Cashback Available">
          <app-yes-no formControlName="cashbackAvailable" (change)="updateField()" ></app-yes-no>
      </app-card-label>

      <app-card-label label="Details" [hidden]="mortgageDetails.controls['cashbackAvailable'].value != 1">
        <mat-form-field floatLabel="never">
            <span matPrefix>£ &nbsp;</span>
          <input matInput formControlName="cashbackAvailableDetails" (change)="updateField()" >
        </mat-form-field>
      </app-card-label>

      <app-card-label label="Contribution to Legal Fees">
        <app-yes-no formControlName="contributionToLegalFees" (change)="updateField()" ></app-yes-no>
    </app-card-label>

    <app-card-label label="Details" [hidden]="mortgageDetails.controls['contributionToLegalFees'].value != 1">
      <mat-form-field floatLabel="never">
          <span matPrefix>£ &nbsp;</span>
        <input matInput formControlName="contributionToLegalFeesDetails" (change)="updateField()" >
      </mat-form-field>
    </app-card-label>

      <app-card-label label="Fixed Interest Rate">
        <mat-form-field floatLabel="never">
          <input matInput formControlName="fixedInterestRate" (change)="updateField()" >
        </mat-form-field>
      </app-card-label>

      <app-card-label label="How long can the property be vacated">
        <mat-form-field floatLabel="never">
          <mat-select formControlName="propertyVacatedLength" (selectionChange)="updateField()">
              <mat-option [value]="-1"></mat-option>
              <mat-option [value]="1">30 days</mat-option>
              <mat-option [value]="2">2 months</mat-option>
              <mat-option [value]="3">3 months</mat-option>
              <mat-option [value]="4">6 months</mat-option>
            </mat-select>
        </mat-form-field>
      </app-card-label>


      <app-card-label label="Term of Mortgage">
        <mat-form-field floatLabel="never">
          <input matInput formControlName="termOfMortgage" (change)="updateField()" >
        </mat-form-field>
      </app-card-label>

      <app-card-label label="Agreed interest repayment details">
          <app-yes-no formControlName="agreedInterestRepayment" (change)="updateField()" ></app-yes-no>
      </app-card-label>

      <app-card-label label="Details" [hidden]="mortgageDetails.controls['agreedInterestRepayment'].value != 1">
        <mat-form-field floatLabel="never">
          <input matInput formControlName="agreedInterestRepaymentDetails" (change)="updateField()" >
        </mat-form-field>
      </app-card-label>

      <app-card-label label="Any Occupiers?">
          <app-yes-no formControlName="anyOccupiers" (change)="updateField()" ></app-yes-no>
      </app-card-label>

      <span [hidden]="mortgageDetails.controls['anyOccupiers'].value != 1">

          <span formArrayName="occupiers">

            <div *ngFor="let occupier of occupiers.controls; let i = index; "
              class="case-entity">

              <div class="remove-entity-button">
                  <strong>Occupier {{i + 1}}</strong>
                <button mat-button color="primary" (click)="removeOcc(i)" class="removeButton">
                  <fa-icon [icon]="faTrashAlt" size="lg"> </fa-icon>
                </button>
              </div>
              <div [formGroupName]="i">
                  <app-card-label label="Name">
                      <mat-form-field floatLabel="never">
                          <input matInput maxlength="100" formControlName="occupierName" (change)="updateField()">
                      </mat-form-field>
                    </app-card-label>

                  <app-card-label label="DOB">
                      <mat-form-field >
                          <input matInput [matDatepicker]="occupierDOB" formControlName="occupierDOB" (dateInput)="updateField()" (dateChange)="updateField()">
                          <mat-datepicker-toggle matSuffix [for]="occupierDOB"></mat-datepicker-toggle>
                          <mat-datepicker #occupierDOB></mat-datepicker>
                        </mat-form-field>
                      </app-card-label>

                      <app-card-label label="Relationship to Client">
                        <mat-form-field floatLabel="never">
                            <input matInput maxlength="100" formControlName="occupierRelationshipToClient" (change)="updateField()">
                        </mat-form-field>
                      </app-card-label>
                </div>
            </div>
          </span>
          <div class="flex">
            <span class="spacer "></span>
            <button mat-raised-button color="primary" (click)="addOcc()">
              <fa-icon [icon]="faPlus" size="lg"> </fa-icon> Occupier
            </button>
          </div>
        </span>

      <app-card-label label="Building Insurance Sum Required">
        <mat-form-field floatLabel="never">
          <span matPrefix>£ &nbsp;</span>
          <input matInput formControlName="buildingInsuranceSumRequired" (change)="updateField()" >
        </mat-form-field>
      </app-card-label>

      <app-card-label label="Lenders Interest Noted Required">
          <app-yes-no formControlName="lendersInterestNotedRequired" (change)="updateField()" ></app-yes-no>
      </app-card-label>

      <app-card-label label="Details" [hidden]="mortgageDetails.controls['lendersInterestNotedRequired'].value != 1">
        <mat-form-field floatLabel="never">
          <input matInput formControlName="lendersInterestNotedRequiredDetails" (change)="updateField()" >
        </mat-form-field>
      </app-card-label>

      <app-card-label label="Has Special Conditions?">
          <app-yes-no formControlName="hasSpecialConditions" (change)="updateField()" ></app-yes-no>
      </app-card-label>


      <span [hidden]="mortgageDetails.controls['hasSpecialConditions'].value != 1">

        <span formArrayName="specialConditions">

          <div *ngFor="let specialCondition of specialConditions.controls; let i = index;"
            class="case-entity">

            <div class="remove-entity-button">
              <button mat-button color="primary" (click)="removeSC(i)" class="removeButton">
                <fa-icon [icon]="faTrashAlt" size="lg"> </fa-icon>
              </button>
            </div>
            <span>
            <div [formGroupName]="i">
                <app-card-label label="Special Condition {{i + 1}}">
                    <mat-form-field floatLabel="never">
                        <textarea cdkTextareaAutosize matInput #specialCondition formControlName="specialCondition" (change)="updateField()"></textarea>
                    </mat-form-field>
                  </app-card-label>
            </div>
            </span>
          </div>
        </span>
        <div class="flex">
          <span class="spacer "></span>
          <button mat-raised-button color="primary" (click)="addSC()">
            <fa-icon [icon]="faPlus" size="lg"> </fa-icon> Special Condition
          </button>
        </div>
      </span>

     <app-card-label label="Consultant">
        <mat-form-field floatLabel="never">
          <mat-select formControlName="externalConsultantId" (selectionChange)="updateField()" [disabled]="true">
            <mat-option *ngFor="let externalConsultant of externalConsultants" [value]="externalConsultant.id">{{externalConsultant.name}}</mat-option>
          </mat-select>
          <button matSuffix mat-raised-button color="primary" (click)="selectConsultant()">Select Consultant</button>
        </mat-form-field>
      </app-card-label>

    </form>

  </mat-dialog-content>

  <div mat-dialog-actions align="end">
    <button *ngIf="!generatingLetters" mat-stroked-button color="primary" (click)="createLetters()" cdkFocusInitial>Generate Letters</button>
    <button *ngIf="generatingLetters" mat-icon-button disabled color="primary" class="right" >
        <mat-icon>
            <mat-spinner color="primary" diameter="20">
            </mat-spinner>
        </mat-icon>
      </button>
  </div>
