
<button mat-icon-button class="close" tabindex="-1" (click)="close()">x</button>
<button mat-icon-button class="audit" tabindex="-1" (click)="openAuditForForm()"><fa-icon [icon]="faHistory"></fa-icon></button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Estate Agent Details</h2>

<mat-dialog-content class="mat-typography">
  <form [formGroup]="estateAgentDetails" autocomplete="off">

    <app-card-label label="Estate Agent Company">
      <mat-form-field floatLabel="never">
        <input matInput formControlName="estateAgentCompany" (change)="updateField()" >
      </mat-form-field>
    </app-card-label>

    <app-card-label label="Individual Financial Advisor">
        <mat-form-field floatLabel="never">
          <input matInput formControlName="individualFinancialAdvisor" (change)="updateField()" >
        </mat-form-field>
      </app-card-label>


    <app-card-label label="Estate Agent Contact Number">
      <mat-form-field floatLabel="never">
        <input matInput formControlName="estateAgentContactNumber" (change)="updateField()" >
      </mat-form-field>
    </app-card-label>

    <app-card-label label="Estate Agent Email">
      <mat-form-field floatLabel="never">
        <input matInput formControlName="estateAgentEmailAddress" (change)="updateField()" >
      </mat-form-field>
    </app-card-label>

    <app-card-label label="Estate Agent Postcode">
      <mat-form-field floatLabel="never">
        <input matInput #estateAgentPostcode maxlength="10" formControlName="estateAgentPostcode"
          (change)="updateField()">
        <app-address-lookup-button matSuffix [for]="addressLookup1"></app-address-lookup-button>
      </mat-form-field>
    </app-card-label>
    
    <app-address-lookup #addressLookup1 lookupType="postcode" [searchField]="estateAgentPostcode" [add1]="estateAgentDetails.controls['estateAgentAddressLine1']"
      [add2]="estateAgentDetails.controls['estateAgentAddressLine2']" [add3]="estateAgentDetails.controls['estateAgentAddressLine3']" [add4]="estateAgentDetails.controls['estateAgentAddressLine4']"
      [add5]="estateAgentDetails.controls['estateAgentAddressLine5']" [addPc]="estateAgentDetails.controls['estateAgentPostcode']" (change)="updateField();">
    </app-address-lookup>
    
    <app-card-label label="Estate Agent Address Line 1">
      <mat-form-field floatLabel="never">
        <input matInput #estateAgentAddressLine1  maxlength="100"
          formControlName="estateAgentAddressLine1" (change)="updateField()">
      </mat-form-field>
    </app-card-label>
    
    <app-card-label label="Estate Agent Address Line 2">
      <mat-form-field floatLabel="never">
        <input matInput #estateAgentAddressLine2 maxlength="100"
          formControlName="estateAgentAddressLine2" (change)="updateField()">
      </mat-form-field>
    </app-card-label>
    
    <app-card-label label="Estate Agent Address Line 3">
      <mat-form-field floatLabel="never">
        <input matInput #estateAgentAddressLine3 maxlength="100"
          formControlName="estateAgentAddressLine3" (change)="updateField()">
      </mat-form-field>
    </app-card-label>
    
    <app-card-label label="Estate Agent Address Line 4">
      <mat-form-field floatLabel="never">
        <input matInput #estateAgentAddressLine4 maxlength="100"
          formControlName="estateAgentAddressLine4" (change)="updateField()">
      </mat-form-field>
    </app-card-label>
    
    <app-card-label label="Estate Agent Address Line 5">
      <mat-form-field floatLabel="never">
        <input matInput #estateAgentAddressLine5 maxlength="100"
          formControlName="estateAgentAddressLine5" (change)="updateField()">
      </mat-form-field>
    </app-card-label>
    

    <!--app-card-label label="Estate Agent Fee">
      <mat-form-field floatLabel="never">
        <input matInput formControlName="estateAgentFee" (change)="updateField()" >
      </mat-form-field>
    </app-card-label-->

  </form>

</mat-dialog-content>

<div mat-dialog-actions align="end">
    <button *ngIf="!generatingLetters"  mat-stroked-button color="primary"(click)="createLetters()">Create Initial Letters</button>
    <button *ngIf="generatingLetters" mat-icon-button disabled color="primary" class="right" >
        <mat-icon>
            <mat-spinner color="primary" diameter="20">
            </mat-spinner>
        </mat-icon>
      </button>
</div>

