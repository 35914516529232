import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CaseService } from '../shared/services/case.service';

@Component({
  selector: 'app-on-hold',
  templateUrl: './on-hold.component.html',
  styleUrls: ['./on-hold.component.css']
})
export class OnHoldComponent implements OnInit {

  private caseId: number;
  isLoading: boolean = false;

  onHoldDetails= new FormGroup({
    onHoldReason: new FormControl()
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<OnHoldComponent>,
  private caseService: CaseService) {
    this.caseId = this.data['caseId'];
  }

  ngOnInit(): void {
  }

  putCaseOnHold(){

    this.isLoading = true;
    this.caseService.putCaseOnHold(this.caseId, this.onHoldDetails.value).subscribe(response => {

      this.dialogRef.close(true);

      }, error => {

        var errorMessage = "Failed to put case on hold";
        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }

      });

  }

  cancel(){
    this.dialogRef.close(null);
  }


}
