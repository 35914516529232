<!--mat-spinner ngif*="dataLoaded"></mat-spinner-->

      <div class="button-bar">
          <app-searchbar *ngIf="internalUsers" searchType="InternalUsers" [inline]="true"></app-searchbar>
          <app-searchbar *ngIf="!internalUsers" searchType="ExternalUsers" [inline]="true"></app-searchbar>
          <button mat-mini-fab (click)="addUser()" color="primary"><fa-icon [icon]="faPlus"></fa-icon></button>
        </div>

      <div>
        <table mat-table [dataSource]="userData" matSort matSortActive="firstName" matSortDisableClear matSortDirection="asc" class="clickable-list-item">

          <ng-container matColumnDef="firstName">
            <mat-header-cell *matHeaderCellDef>
              <app-string-filter #filter title="Filter First Name." filterColumn="firstName">
                <span mat-sort-header>First Name.</span>
              </app-string-filter>
            </mat-header-cell>
            <mat-cell *matCellDef="let user"> {{user.firstName}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef>
              <app-string-filter #filter title="Filter Last Name." filterColumn="lastName">
                <span mat-sort-header>Last Name.</span>
              </app-string-filter>
            </mat-header-cell>
            <mat-cell *matCellDef="let user"> {{user.lastName}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="emailAddress">
            <mat-header-cell *matHeaderCellDef>
              <app-string-filter #filter title="Filter Email Address." filterColumn="emailAddress">
                <span mat-sort-header>Email Address.</span>
              </app-string-filter>
            </mat-header-cell>
            <mat-cell *matCellDef="let user"> {{user.emailAddress}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="companyGroup">
            <mat-header-cell *matHeaderCellDef>
              <app-string-filter #filter title="Filter Company Group." filterColumn="companyGroup.name">
                <span mat-sort-header="companyGroup.name">Company Group.</span>
              </app-string-filter>
            </mat-header-cell>
            <mat-cell *matCellDef="let user"> {{user.companyGroup?.name}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Role. </mat-header-cell>
            <mat-cell *matCellDef="let user"> {{getRoleName(user.role)}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="isTeamLeader">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Are they a Team Leader? </mat-header-cell>
            <mat-cell *matCellDef="let user"> <span [hidden]="!user.isTeamLeader"> <fa-icon [icon]="faCheck"></fa-icon></span> </mat-cell>
          </ng-container>

          <ng-container matColumnDef="teamLeader">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Team Leader. </mat-header-cell>
            <mat-cell *matCellDef="let user"> {{user.teamLeaderUser?.firstName}} {{user.teamLeaderUser?.lastName}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="isAMLOfficer">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Are they an AML Officer? </mat-header-cell>
            <mat-cell *matCellDef="let user"> <span [hidden]="!user.isAMLOfficer"> <fa-icon [icon]="faCheck"></fa-icon></span> </mat-cell>
          </ng-container>

          <ng-container matColumnDef="lastLogin">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Last Login. </mat-header-cell>
            <mat-cell *matCellDef="let user"> {{formatDate(user.lastLogin)}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="accountDisabled">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Account Disabled </mat-header-cell>
            <mat-cell *matCellDef="let user"> <span [hidden]="!user.accountDisabled"> <fa-icon [icon]="faCheck"></fa-icon></span> </mat-cell>
          </ng-container>


          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
          <mat-row *matRowDef="let user; columns: displayedColumns;" (click)="editUser(user)"></mat-row>

        </table>

        <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
      </div>


