import { Component, Inject, OnInit } from '@angular/core';
import { faHistory } from '@fortawesome/pro-light-svg-icons';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-add-enquiry',
  templateUrl: './add-enquiry.component.html',
  styleUrls: ['./add-enquiry.component.css']
})
export class AddEnquiryComponent implements OnInit {


  enquiryDetails = this.fb.group({
    caseId: null,
    enquiry: '',
    receivedDate: new Date()
  }, {});

  dateReceivedDefaultValue?: Date;

  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
    //public presubmissionChecklistService: PresubmissionChecklistService,
    public dialogRef: MatDialogRef<AddEnquiryComponent>,
    private fb: FormBuilder,
  ) {
    this.enquiryDetails.controls["caseId"].setValue(this.componentData.caseId);
    
  }

  ngOnInit(): void {
    
  }

  close() {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(this.enquiryDetails.value);
  }


  onDateReceivedChange(newDate: Date) {
    this.enquiryDetails.controls['receivedDate'].setValue(newDate);
  }
  onDateRepliedChange(newDate: Date) {
    this.enquiryDetails.controls['repliedDate'].setValue(newDate);
  }
  onDateSatisfiedChange(newDate: Date) {
    this.enquiryDetails.controls['satisfiedDate'].setValue(newDate);
  }
 

}
