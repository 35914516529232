<div class="body">

  <div class="sidebar">
    <app-dashboard-sidebar></app-dashboard-sidebar>
  </div>

  <div class="content">

      <app-card-basic cardTitle="Notifications">

          <div card-body>

      <div class="edit">
          <button mat-raised-button [disabled]="selection.selected.length == 0" (click)="transferNotification()" color="primary">Transfer</button>
        </div>
      <table mat-table [dataSource]="data" matSort matSortActive="dateCreated" matSortDisableClear matSortDirection="desc">

          <ng-container matColumnDef="select" >

              <mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)" [disabled]="row.isRead">
                </mat-checkbox>
              </mat-cell>

            </ng-container>

          <ng-container matColumnDef="dateCreated">
            <mat-header-cell *matHeaderCellDef>
              <app-date-filter #filter title="Date Created" filterColumn="dateCreated">
                <span mat-sort-header>Date.</span>
              </app-date-filter>
            </mat-header-cell>
            <mat-cell *matCellDef="let notification"> {{notification.dateCreated  | date:'dd/MM/yyyy HH:mm:ss'}} </mat-cell>
          </ng-container>

            <ng-container matColumnDef="forUser" >

                <mat-header-cell *matHeaderCellDef>
                  <app-autocomplete-filter #filter title="Filter User." filterColumn="forUser.id" [options]="users"
                    nameField="name" valueField="id" valueType="int">
                    <span mat-sort-header="forUser.id">For User.</span>
                  </app-autocomplete-filter>
                </mat-header-cell>

                <mat-cell *matCellDef="let notification"> {{notification.forUser.firstName}} {{notification.forUser.lastName}} </mat-cell>
              </ng-container>

            <ng-container matColumnDef="caseReference">
                <mat-header-cell *matHeaderCellDef>
                  <app-string-filter #filter title="Case Ref." filterColumn="caseReference">
                    <span mat-sort-header>Case Ref.</span>
                  </app-string-filter>
                </mat-header-cell>
                <mat-cell *matCellDef="let notification">{{notification.caseReference}} </mat-cell>
              </ng-container>


              <ng-container matColumnDef="notificationType">
                <mat-header-cell *matHeaderCellDef>
                  <app-string-filter #filter title="Notification Type." filterColumn="notificationType">
                    <span mat-sort-header>Notification Type.</span>
                  </app-string-filter>
                </mat-header-cell>
                <mat-cell *matCellDef="let notification"> {{notification.notificationTypeName}}</mat-cell>
              </ng-container>

                <ng-container matColumnDef="notificationText">
                  <mat-header-cell *matHeaderCellDef>
                    <app-string-filter #filter title="Notification Text." filterColumn="notificationText">
                      <span mat-sort-header>Notification Text.</span>
                    </app-string-filter>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let notification"> {{notification.notificationText}}</mat-cell>
                </ng-container>

          <ng-container matColumnDef="acknowledgedDate">
            <mat-header-cell *matHeaderCellDef>
              <app-date-filter #filter title="Acknowledged" filterColumn="acknowledgedDate">
                <span mat-sort-header>Acknowledged.</span>
              </app-date-filter>
            </mat-header-cell>
            <mat-cell *matCellDef="let notification"> {{notification.acknowledgedDate  | date:'dd/MM/yyyy HH:mm:ss'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="readDate">
              <mat-header-cell *matHeaderCellDef>
                <app-date-filter #filter title="Read" filterColumn="readDate">
                  <span mat-sort-header>Read.</span>
                </app-date-filter>
              </mat-header-cell>
              <mat-cell *matCellDef="let notification"> {{notification.readDate  | date:'dd/MM/yyyy HH:mm:ss'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="dismissedDate">
                <mat-header-cell *matHeaderCellDef>
                  <app-date-filter #filter title="Dismissed" filterColumn="dismissedDate">
                    <span mat-sort-header>Dismissed.</span>
                  </app-date-filter>
                </mat-header-cell>
                <mat-cell *matCellDef="let notification"> {{notification.dismissedDate  | date:'dd/MM/yyyy HH:mm:ss'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef>
                    <span mat-sort-header></span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let notification">
                    <button mat-button color="primary" (click)="viewHistory(notification.id)"><fa-icon [icon]="faHistory"></fa-icon></button>
                  </mat-cell>
                </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
          <mat-row *matRowDef="let notification; columns: displayedColumns;"></mat-row>

      </table>

      <mat-spinner color="primary" diameter="20" *ngIf="isLoadingResults"></mat-spinner>
      <mat-paginator [length]="resultsLength" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]" [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
      </app-card-basic>


  </div>
</div>
