import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SmsComponent } from '../sms/sms.component';
import { FaxService } from '../../../shared/services/fax.service';
import { CaseService } from '../../../shared/services/case.service';
import { CorrespondenceTimelineService } from '../../../shared/services/correspondence-timeline.service';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-fax',
  templateUrl: './fax.component.html',
  styleUrls: ['./fax.component.css']
})
export class FaxComponent implements OnInit {

  faPaperclip = faPaperclip;
  caseId: number = -1;
  faxObj: any;
  sendingFax: boolean = false;
  readOnly = false;
  caseFiles: any [] = [];

  faxDetails = this.fb.group({
    faxNumber: ['', Validators.required],
    messageBody: ['', Validators.required],
    caseFileIds: null
  });

  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
  public dialogRef: MatDialogRef<FaxComponent>,
  private fb: FormBuilder,
  private faxService: FaxService,
  private correspondenceTimelineService: CorrespondenceTimelineService) {
    this.faxObj = componentData.faxObj;

    if(this.faxObj == null)
    {
      this.caseId = componentData.caseId;

      if(componentData.caseFiles)
        this.caseFiles = componentData.caseFiles;
    }
    else{
      this.readOnly = true;
      this.faxDetails.controls['faxNumber'].patchValue(this.faxObj.to);
      this.faxDetails.controls['faxNumber'].disable();
      this.faxDetails.controls['messageBody'].patchValue(this.faxObj.message);
      this.faxDetails.controls['messageBody'].disable();

      if(componentData.caseFiles)
        this.caseFiles = componentData.caseFiles;

    }
  }

  ngOnInit() {
  }

  sendFax(){

    this.sendingFax = true;

    let fileIds: any [] = [];

    this.caseFiles.forEach((file:any) => {
        fileIds.push(file.caseFileId);
    });

    this.faxDetails.controls['caseFileIds'].patchValue(fileIds);

    this.faxService.sendFax(this.caseId, this.faxDetails.value).subscribe((response : any) => {
      this.dialogRef.close(true);
      this.correspondenceTimelineService.refreshCaseTimeline.next();
    });

  }

  removeAttachment(id: number){
    this.caseFiles = this.caseFiles.filter(item => item.caseFileId !== id);
  }
}
