<div class="toolbar">
  <button mat-icon-button tabindex="-1" color="primary" (click)="refresh()" *ngIf="creditCheckData.checkResult==null"><fa-icon [icon]="faSync"></fa-icon></button>
  <button mat-icon-button tabindex="-1" (click)="openHistory()"><fa-icon [icon]="faHistory"></fa-icon></button>
  <button mat-icon-button tabindex="-1" (click)="close()"><fa-icon [icon]="faTimes"></fa-icon></button>

</div>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">AML Check Client {{clientNo}}</h2>


<mat-dialog-content class="credit-safe-check-dialog" *ngIf="dataLoaded && creditCheckData!=null">

  <div class="response-box" *ngIf="creditCheckData.checkResult!=null">
    <div class="response">
      <table>
        <tbody>
          <tr *ngIf="creditCheckData.isManualCheck">
            <th>Manually Checked</th>
            <td>Yes</td>
          </tr>
          <tr *ngIf="creditCheckData.isManualCheck">
            <th>Checked Date</th>
            <td>{{creditCheckData.manualCheckDate | date: 'dd/MM/yyyy'}}</td>
          </tr>
          <tr *ngIf="!creditCheckData.isManualCheck">
            <th>Checked Date</th>
            <td>{{creditCheckData.checkPerformedDate | date: 'dd/MM/yyyy'}}</td>
          </tr>
          <tr>
            <th>By User</th>
            <td>{{creditCheckData.checkPerformedByUser.fullName}}</td>
          </tr>
          <tr *ngIf="creditCheckData.notes != null">
            <th>Notes</th>
            <td>{{creditCheckData.notes}}</td>
          </tr>
          <tr *ngIf="!creditCheckData.isManualCheck">
            <th>Result</th>
            <td>
              <fa-icon *ngIf="creditCheckData.checkResultString == 'PASS'" [icon]="faCheckCircle" class="check-success" size="1x"></fa-icon> 
              <fa-icon *ngIf="creditCheckData.checkResultString != 'PASS'" [icon]="faExclamationCircle" class="check-danger" size="1x"></fa-icon> 
               {{creditCheckData.checkResultString}} 
            </td>
          </tr>
        </tbody>
      </table>
      <span *ngIf="!creditCheckData.isManualCheck && creditCheckData.hasErrors != true" >
        <hr />
        <h3 class="response-title">Alerts</h3>
        <div>
          <span *ngIf="!creditCheckData.pepAlert"><fa-icon [icon]="faCheckCircle" class="check-success" size="1x"></fa-icon> No PEP Alerts</span>
          <span *ngIf="creditCheckData.pepAlert"><fa-icon [icon]="faExclamationCircle" class="check-danger" size="1x"></fa-icon> International PEP (Enhanced)</span>
        </div>
      </span>
      <div *ngIf="creditCheckData.hasErrors == true" class="check-danger" >
        <hr />
        <h3 class="response-title">Errors</h3>
        <span><fa-icon [icon]="faExclamationCircle" size="1x"></fa-icon> {{creditCheckData.errorMessage}}</span>

      </div>

      <hr />
      <h3 class="response-title">Search Details</h3>

      <table>
        <tbody>
          <tr>
            <th>Forename</th>
            <td>{{creditCheckData.firstName}}</td>
          </tr>
          <tr>
            <th>Other Names</th>
            <td>{{creditCheckData.otherNames}} <span *ngIf="creditCheckData.otherNames==null">-</span></td>
          </tr>
          <tr>
            <th>Surname</th>
            <td>{{creditCheckData.surname}}</td>
          </tr>
          <tr>
            <th>Date of Birth</th>
            <td>{{creditCheckData.dateOfBirth | date: 'dd-MM-yyyy'}}</td>
          </tr>
          <tr>
            <th>Building No</th>
            <td>{{creditCheckData.buildingNo}}</td>
          </tr>
          <tr>
            <th>Building Name</th>
            <td>{{creditCheckData.buildingName}}</td>
          </tr>
          <tr>
            <th>Street</th>
            <td>{{creditCheckData.street}}</td>
          </tr>
          <tr>
            <th>City</th>
            <td>{{creditCheckData.city}}</td>
          </tr>
          <tr>
            <th>Postcode</th>
            <td>{{creditCheckData.postCode}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="buttons" style="text-align: right" *ngIf="authService.isPortalManager() || authService.isPortalAdmin()">
      <button *ngIf="creditCheckData.checkResult!=null || creditCheckData.cancelledByUserId" mat-stroked-button color="secondary" type="button" (click)="unlock()">
        <mat-icon>lock</mat-icon>
        Unlock
      </button>

    </div>
  </div>

  <form [formGroup]="creditSafeCheck" autocomplete="off" *ngIf="creditCheckData.checkResult==null">

    <app-card-label label="Title">
      <mat-form-field floatLabel="never">
        <mat-select formControlName="titleId" (selectionChange)="updateField()">
          <mat-option *ngFor="let title of titleList" [value]="title.id">{{title.titleName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </app-card-label>
    <app-card-label label="Forename">
      <mat-form-field floatLabel="never">
        <input matInput formControlName="firstName" (change)="updateField()">
      </mat-form-field>
    </app-card-label>
    <app-card-label label="Other Names">
      <mat-form-field floatLabel="never">
        <input matInput formControlName="otherNames" (change)="updateField()">
      </mat-form-field>
    </app-card-label>
    <app-card-label label="Surname">
      <mat-form-field floatLabel="never">
        <input matInput formControlName="surname" (change)="updateField()">
      </mat-form-field>
    </app-card-label>

    <app-card-label label="Date of Birth">
      <mat-form-field>
        <input formControlName="dob" matInput [matDatepicker]="picker" (dateChange)="onDateChange()" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>

      </mat-form-field>
    </app-card-label>


    <app-card-label label="Building No">
      <mat-form-field floatLabel="never">
        <input matInput formControlName="buildingNo" (change)="updateField()">
      </mat-form-field>
    </app-card-label>
    <app-card-label label="Building Name">
      <mat-form-field floatLabel="never">
        <input matInput formControlName="buildingName" (change)="updateField()">
      </mat-form-field>
    </app-card-label>
    <app-card-label label="Street">
      <mat-form-field floatLabel="never">
        <input matInput formControlName="street" (change)="updateField()">
      </mat-form-field>
    </app-card-label>
    <app-card-label label="City">
      <mat-form-field floatLabel="never">
        <input matInput formControlName="city" (change)="updateField()">
      </mat-form-field>
    </app-card-label>
    <app-card-label label="Postcode">
      <mat-form-field floatLabel="never">
        <input matInput formControlName="postCode" (change)="updateField()">
      </mat-form-field>
    </app-card-label>


    <div class="buttons">
      <div>
        <button *ngIf="(authService.isPortalManager() || authService.isPortalAdmin())" mat-stroked-button color="secondary" type="button" (click)="runManualCreditSafecheck()">Manually Checked</button>
      </div>
      <div style="text-align: right">
        <button mat-stroked-button color="primary"
                (click)="runCreditSafecheck()"
                [disabled]="creditSafeCheck.valid==false"
                *ngIf="!showSpinner"
                >
          Check
        </button>
        <mat-spinner color="primary" diameter="20" style="float:right" *ngIf="showSpinner"></mat-spinner>

      </div>

    </div>


  </form>

</mat-dialog-content>

<mat-spinner color="primary" diameter="20" style="float:right" *ngIf="!dataLoaded"></mat-spinner>
