import { Component, OnInit, Inject } from '@angular/core';
import { faPlus, faTimes, faShare } from '@fortawesome/pro-light-svg-icons';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TransactionsService } from '../../../shared/services/transactions-service';
import { AddTransactionComponent } from './add-transaction/add-transaction.component';
import { DateTime } from 'luxon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicPopupYesNoComponent } from 'src/app/shared/components/basic-popup-yes-no/basic-popup-yes-no.component';
import { KlyantService } from '../../../shared/services/klyant.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {

  caseId : number;
  caseTypeId : number;
  transactions: any[] = [];
  transactionsAdded: any[] = [];
  transactionsRemoved: any[] = [];
  officeAccountList: any[] = [];
  clientAccountList: any[] = [];
  balance: number = 0;
  faPlus = faPlus;
  faTimes = faTimes;
  faShare = faShare;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private transactionService: TransactionsService,
  public dialog: MatDialog,
  private klyantService: KlyantService,
  private snackBar: MatSnackBar) {
    this.caseId = data.caseId;
    this.caseTypeId = data.caseTypeId;

    this.klyantService.getOfficeAccounts().subscribe((results) => {
      this.officeAccountList = results;

      this.klyantService.getClientAccounts().subscribe((results) => {
        this.clientAccountList = results;

        this.loadTransactions();
      }); 
    }); 
    
  }

  ngOnInit(): void {
  }

  loadTransactions() {

    this.transactions = [];
    this.transactionsAdded = [];
    this.transactionsRemoved = [];

    this.transactionService.getTransactions(this.caseId).subscribe((results) => {
      this.balance = results.balance;
      results.addedAndCurrent.forEach((t:any) => {
        if (t.transactionDirection == 1) {          
          t.type = 'Receipt';
          this.transactionsAdded.push(t);
        }
        else if (t.transactionDirection == 2) {
          t.type = 'Transfer';
          this.transactionsAdded.push(t);
        }
        else if (t.transactionDirection == 3) {          
          t.type = 'Payment';
          this.transactionsAdded.push(t);
        }
        else {
          console.log('Got transaction with Id=' + t.matterLedgerId + ' with unknown type');
        }
      });
    });
  }

  getOfficeAccountName(id:number) {
    var account = this.officeAccountList.filter(x => x.id == id);
    if(account && account.length == 1) {
      return account[0].bank;
    }
    else {
      return null;
    }
  }

  getClientAccountName(id:number) {
    var account = this.clientAccountList.filter(x => x.id == id);
    if(account && account.length == 1) {
      return account[0].name;
    }
    else {
      return null;
    }
  }

  addTransaction(){

    const dialogConfig = new MatDialogConfig();

      dialogConfig.data = {
        caseId: this.caseId,
        caseTypeId: this.caseTypeId
    };

    const dialogRef = this.dialog.open(AddTransactionComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result == true){ 
        this.loadTransactions();
      }
    });
  }

  removeTransaction(matterLedger:any) {

    var popupText = '';
    if(matterLedger.klyantId)
    {
      popupText = 'THIS ITEM HAS BEEN SENT TO KLYANT. YOU MUST VOID THE TRANSACTION IN KLYANT MANUALLY.';
    }
    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Are you sure you want to remove this transaction?', popupText1: popupText, popupText2: ''}
    });

    dialogRef.afterClosed().subscribe((result : any) => {

      if (result)
      {
        this.transactionService.removeTransaction(this.caseId, matterLedger.matterLedgerId).subscribe(() => {
          this.loadTransactions();
        });
      }
    });
  
  }

  sendToKlyant(matterLedger:any) {   
    matterLedger.sendingToKlyant = true;
    this.transactionService.sendToKlyant(this.caseId, matterLedger.matterLedgerId).subscribe((response : any) => {      
      
      matterLedger.sendingToKlyant = true;
      this.loadTransactions();
      this.openSnackBar("Transaction Sent to Klyant", 'Close');
    }, (error: any) => {            
      matterLedger.sendingToKlyant = false;
      this.openSnackBar("ERROR: " + error.error, 'Close');
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
