
<!-- <div mat-dialog-content class="mat-typography"> -->
  <!-- <h1 mat-dialog-title >Appointment</h1> -->
  <div class="calendar-wrapper">

    <form [formGroup]="eventForm">

      <div class="row">
        <div class="title">Case Reference</div>
        <div class="value">{{event.caseRef}}</div>
      </div>

      <div class="row">
        <div class="title">Clients Name</div>
        <div class="value">{{event.client}}</div>
      </div>

      <div class="row">
        <div class="title">Clients Postcode</div>
        <div class="value">{{event.clientPostcode}}</div>
      </div>

      <!-- <div class="row">
        <div class="title">Appointment Date</div>
        <div class="value">
          <mat-form-field floatLabel="never" class="full-width">
            <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="appointmentDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker startView="multi-year"></mat-datepicker>
            <mat-hint align="end">dd/mm/yyyy</mat-hint>
          </mat-form-field>
        </div>
      </div> -->

      <!-- <div class="row">
        <div class="title">Consultant</div>
        <div class="value">
          <mat-form-field floatLabel="never" class="full-width">
            <mat-select placeholder="Attorney Type" formControlName="consultantName">
              <mat-option [value]="1">Principle</mat-option>
              <mat-option [value]="2">Substitute</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div> -->

      <div class="row">
        <div class="title">Appointment Date</div>
        <!-- <div class="value">{{event.appointmentDate | date:'dd/MM/yyyy'}}</div> -->
      </div>

      <div class="row">
        <div class="title">Consultant</div>
        <div class="value">{{event.consultantName}}</div>
      </div>

      <div class="row">
        <div class="title">Distance from Client</div>
        <div class="value">{{event.distanceToClient}}</div>
      </div>

      <div class="row">
        <div class="title">Appointment Created Date</div>
        <!-- <div class="value">{{event.appointmentCreatedDate | date:'dd/MM/yyyy HH:MM'}}</div> -->
      </div>

      <div class="row">
        <div class="title">Appointment Created By</div>
        <div class="value">{{event.appointmentCreatedUser}}</div>
      </div>

      <div class="row">
        <button mat-raised-button (click)="onNoClick()">Cancel</button>
        <div class="spacer"></div>
        <!-- <button mat-raised-button color="primary" [routerLink]="['/case', event.caseId]">View Case</button> -->

      </div>
    </form>


  <!-- </div>
</div> -->
