<div class="case-list-actions">
    <app-searchbar searchType="Cases" [caseStatusType]="5"  [inline]="true"></app-searchbar>
</div>

<table  mat-table [dataSource]="data" matSort matSortActive="dateCreated" matSortDisableClear  matSortDirection="asc">

  <ng-container matColumnDef="dateCreated">

    <mat-header-cell *matHeaderCellDef>
      <app-date-filter #filter title="Filter Date Created" filterColumn="dateCreated">
        <span mat-sort-header>Date Created.</span>
      </app-date-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let case"> {{case.dateCreated  | date:'dd/MM/yyyy'}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="caseType">
    <mat-header-cell *matHeaderCellDef>
      <span mat-sort-header="type.caseTypeName">Type</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let case"> {{case.type.caseTypeName}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="financialAdviserName" >
    <mat-header-cell *matHeaderCellDef>
      <app-string-filter #filter title="Filter FA." filterColumn="addedByUser.fullName">
        <span mat-sort-header="addedByUser.fullName">FA.</span>
      </app-string-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let case"> <span *ngIf="case.addedByUser">{{case.addedByUser.fullName}} </span></mat-cell>
  </ng-container>

  <ng-container matColumnDef="companyGroup">
      <mat-header-cell *matHeaderCellDef>
        <app-string-filter #filter title="Company Group." filterColumn="addedByUser.companyGroup.name">
          <span mat-sort-header="addedByUser.companyGroup.name">Company.</span>
        </app-string-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let case"> {{case.addedByUser.companyGroup.name}} </mat-cell>
    </ng-container>

  <ng-container matColumnDef="partyOneFirstName">
    <mat-header-cell *matHeaderCellDef>
      <app-string-filter #filter title="Filter First Name." filterColumn="partyOneFirstName">
        <span mat-sort-header>First Name.</span>
      </app-string-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let case"> {{case.partyOneFirstName}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="partyOneLastName">
    <mat-header-cell *matHeaderCellDef>
      <app-string-filter #filter title="Filter Last Name." filterColumn="partyOneLastName">
        <span mat-sort-header>Last Name.</span>
      </app-string-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let case"> {{case.partyOneLastName}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="partyOneAddressLine1">
    <mat-header-cell *matHeaderCellDef>
      <app-string-filter #filter title="Address Line 1." filterColumn="partyOneAddressLine1">
        <span mat-sort-header>Address Line 1.</span>
      </app-string-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let case"> {{case.partyOneAddressLine1}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="partyOneAddressLine2">
    <mat-header-cell *matHeaderCellDef>
      <app-string-filter #filter title="Address Line 2." filterColumn="partyOneAddressLine2">
        <span mat-sort-header>Address Line 2.</span>
      </app-string-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let case"> {{case.partyOneAddressLine2}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="partyOneAddressLine3">
    <mat-header-cell *matHeaderCellDef>
      <app-string-filter #filter title="Address Line 3." filterColumn="partyOneAddressLine3">
        <span mat-sort-header>Address Line 3.</span>
      </app-string-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let case"> {{case.partyOneAddressLine3}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="partyOnePostCode">
    <mat-header-cell *matHeaderCellDef>
      <app-string-filter #filter title="Post Code." filterColumn="partyOnePostCode">
        <span mat-sort-header>Post Code.</span>
      </app-string-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let case"> {{case.partyOnePostcode}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="caseReference">
    <mat-header-cell *matHeaderCellDef>
      <app-string-filter #filter title="Filter Case Ref." filterColumn="caseReference">
        <span mat-sort-header>Case Ref.</span>
      </app-string-filter>
    </mat-header-cell>
    <mat-cell *matCellDef="let case"> {{case.caseReference}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef>
      <span mat-sort-header></span>
    </mat-header-cell>
    <mat-cell *matCellDef="let case">
      <button *ngIf="!authService.isFAManager() || (authService.isFAManager() && case.addedByUserId == authService.currentUserID())" mat-raised-button color="warn" (click)="$event.stopPropagation(); archiveCase(case.id)">
        <fa-icon [icon]="faArchive"></fa-icon> Archive
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
  <mat-row *matRowDef="let case; columns: displayedColumns;" [routerLink]="['case', case.id]"
    class="clickable-list-item"></mat-row>

</table>
<mat-spinner color="primary" diameter="20" *ngIf="isLoadingResults"></mat-spinner>
<mat-paginator [length]="resultsLength" [pageSize]="25" [pageSizeOptions]="[10, 25, 50]" [showFirstLastButtons]="true">
</mat-paginator>
