import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { CaseService } from '../shared/services/case.service';
import { LookupsService } from '../shared/services/lookups.service';

@Component({
  selector: 'app-archive-case',
  templateUrl: './archive-case.component.html',
  styleUrls: ['./archive-case.component.css']
})
export class ArchiveCaseComponent implements OnInit {

  private caseId: number;
  public reasons: any[] = [];
  isLoading: boolean = false;

  public selectedReason: any;
  public archiveReasonOther: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ArchiveCaseComponent>,
  private caseService: CaseService,
  private lookupsService: LookupsService) {
    this.caseId = this.data['caseId'];

    this.lookupsService.getArchiveReasons().subscribe(result => {
      this.reasons = result;
      this.selectedReason == 1;
    });

  }

  ngOnInit(): void {
  }

  cancel(){
    this.dialogRef.close(null);
  }

  save(){
    this.isLoading = true;
    var reasonOther = null;
    if(this.selectedReason == 2)
      reasonOther = this.archiveReasonOther

    var archiveDetails = {archiveReasonId: this.selectedReason, archiveReasonOther: reasonOther };

    this.caseService.archiveCase(this.caseId, archiveDetails).subscribe((response : any) => {

      this.dialogRef.close(true);
      this.caseService.refreshCase.next();

      }, error => {

        var errorMessage = "Failed to archive case";
        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }

      });
  }

}
