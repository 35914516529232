<div class="container mat-typography notifications">
  <div class="close clickable" (click)="closePopup()">x</div>
  <div class="header">
    Notifications
  </div>

  <div class="notification-list">
    <div class="no-notifications" *ngIf="allLoaded && notifications.length == 0">You have no notifications</div>
    <div class="notifications-top-actions" *ngIf="allLoaded && notifications.length > 0">
      <div class="toggle-unread clickable" *ngIf="showAll" (click)="showUnread()">Show unread</div>
      <div class="toggle-unread clickable" *ngIf="!showAll" (click)="showAllNotifications()">Show All</div>
      <div class="mark-all-read clickable" (click)="markAllRead()">Mark all read</div>
    </div>

    <ng-container *ngFor="let n of notifications" >
      <div class="notification-item-wrapper" *ngIf="showAll || (!showAll && !n.isRead)" [ngClass]="{'unread-notification': !n.isRead}">

          <div class="notification-item" [ngClass]="{'clickable': n.caseId !== null}"  (click)="clickNotification(n)">
              <span >{{n.notificationText}}</span>
              <span class="date">{{n.dateCreated  | date:'dd/MM/yyyy HH:mm:ss'}} </span>
          </div>
          <div class="clickable" *ngIf="n.isRead" (click)="markAsUnread(n)">
            <fa-icon [icon]="faUndoAlt" ></fa-icon>
          </div>
          <div class="clickable" (click)="dismiss(n)">
              <fa-icon [icon]="faTrashAlt" ></fa-icon>
          </div>
      </div>

    </ng-container>

  </div>

</div>
