import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const httpOptions = {
headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};  
  
@Injectable()
export class AllowancesService {

    private url = environment.apiUrl + 'allowance/';

    constructor(private http: HttpClient, public router: Router) {  
    }

    getAllowances(caseId: number) {
        return this.http.get<any>(this.url + caseId);
    }

    addAllowance(caseId: number, allowance: any) {
        return this.http.put<any>(this.url + caseId, allowance, httpOptions);
    }

    deleteAllowance(caseId: number, allowanceId: number) {
        return this.http.delete<any>(this.url + caseId + '/' + allowanceId);
    }

}