import { Component, Input, OnInit } from '@angular/core';
import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCoffee } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-sidebar-button',
  templateUrl: './sidebar-button.component.html',
  styleUrls: ['./sidebar-button.component.css']
})
export class SidebarButtonComponent implements OnInit {

  @Input() icon: IconProp = faCoffee;
  @Input() tooltip: string = ''

  constructor() { }

  ngOnInit() {
  }

}
