<div class="wrapper">
    <div class="col-0" *ngIf="selectable">
      <mat-checkbox class="example-margin" [(ngModel)]="selected"></mat-checkbox>
    </div>

    <div class="col-1" >
      <div class="body">

        <div class="button" >
          <button mat-icon-button (click)="sendToCase()"><fa-icon [icon]="faShareSquare"size="lg"></fa-icon></button>
        </div>

        <div class="icon" >
          <fa-layers [fixedWidth]="true">
            <!--fa-icon [icon]="getDocumentType(details.format)"size="lg"></fa-icon-->
            <fa-icon [icon]="caseFilesService.getFileTypeIcon(details.format)"size="lg"></fa-icon>
          </fa-layers>
        </div>

        <div class="description">
          <div class="description-header">{{details.fileName}}</div>
          <div class="description-sub">{{details.createdDateTime | date:'dd/MM/yyyy HH:mm:ss'}}</div>
        </div>


      </div>
    </div>
    <div class="col-2">
      <div>
          <!--div class="initials menu" mat-icon-button ><fa-icon [icon]="faEllipsisH" size="lg"></fa-icon></div>
          <div *ngIf="details.addedByUser" class="initials" >{{details.addedByUser.firstName.charAt(0)}}{{details.addedByUser.lastName.charAt(0)}}</div>
          <div *ngIf="!details.addedByUser" class="initials">SYS</div>
        <div class="date">{{details.createdDateTime | date:'dd/MM/yyyy HH:mm:ss'}}</div-->

      </div>
      <div>

      </div>
    </div>
  </div>
