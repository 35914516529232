import { Directive, HostListener, ElementRef, Input } from '@angular/core';
@Directive({
  selector: '[timechars]'
})
export class TimecharDirective {

  regexStr = '^[0-9||:]*$';

  constructor(private el: ElementRef) { }


  @HostListener('keypress', ['$event']) onKeyPress(event: any) {
    return new RegExp(this.regexStr).test(event.key);
  }

}
