import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faPlus, faPen, faMinusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-key-date',
  templateUrl: './key-date.component.html',
  styleUrls: ['./key-date.component.scss']
})
export class KeyDateComponent implements OnInit {

  @Input() label?: string;
  @Input() keyDate?: Date;
  @Input() keyDateId?: number;
  @Input() keyDateTypeId?: number;
  @Input() enteredByUser?: any;
  @Input() readOnly?: boolean;
  @Input() canAdd?: boolean;
  @Input() canEdit?: boolean;
  @Input() canView?: boolean;
  @Input() includeTime?: boolean = false;

  @Output() keyDateChange = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() delete = new EventEmitter();


  faPlus = faPlus;
  faPen = faPen;
  faMinusCircle = faMinusCircle;

  constructor() { }

  ngOnInit() {
  }

  change(newValue: any) {

    this.keyDate = newValue;
    this.keyDateChange.emit(newValue);
  }

  addDate() {
    console.log('addDate', this.keyDateTypeId, this.includeTime);
    this.add.next({typeId:this.keyDateTypeId, keyDateName: this.label, includeTime: this.includeTime});
  }

  editDate() {
    this.edit.next({typeId: this.keyDateTypeId, keyDateId: this.keyDateId, keyDate: this.keyDate, keyDateName: this.label})
  }

  deleteDate() {
    this.delete.next({typeId: this.keyDateTypeId, keyDateId: this.keyDateId, keyDate: this.keyDate, keyDateName: this.label});
  }


}
