<div class="case-list-actions">
    <button mat-raised-button [disabled]="selection.selected.length == 0" (click)="reAllocateCases()" color="primary"
      *ngIf="authService.canReallocateCases()">Reallocate Cases</button>
      <button mat-raised-button [disabled]="selection.selected.length == 0" (click)="reAllocateTasks()" color="primary"
      *ngIf="authService.canReallocateTasks()">Reallocate Tasks</button>
      <app-searchbar searchType="Cases" [caseStatusType]="1"  [inline]="true"></app-searchbar>
  </div>


  <table mat-table [dataSource]="data" matSort matSortActive="instructedDate" matSortDisableClear matSortDirection="asc">

      <ng-container matColumnDef="select" >

          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </mat-cell>

        </ng-container>

        <ng-container matColumnDef="allocatedToParalegalUser" >

            <mat-header-cell *matHeaderCellDef>
              <app-autocomplete-filter #filter title="Filter Paralegal." filterColumn="allocatedToParalegalUser.id" [options]="paralegals"
                nameField="name" valueField="id" valueType="int">
                <span mat-sort-header="allocatedToParalegalUser.id">Paralegal.</span>
              </app-autocomplete-filter>
            </mat-header-cell>

            <mat-cell *matCellDef="let case"> {{case.allocatedToParalegalUser.firstName}} {{case.allocatedToParalegalUser.lastName}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="financialAdviserName" >
              <mat-header-cell *matHeaderCellDef>
                <app-string-filter #filter title="Filter FA." filterColumn="addedByUser.fullName">
                  <span mat-sort-header="addedByUser.fullName">FA.</span>
                </app-string-filter>
              </mat-header-cell>
              <mat-cell *matCellDef="let case"> {{case.addedByUser.fullName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="allocatedToAdminUser" >

              <mat-header-cell *matHeaderCellDef>
                <app-autocomplete-filter #filter title="Filter Admin." filterColumn="allocatedToAdminUser.id" [options]="admins"
                  nameField="name" valueField="id" valueType="int">
                  <span mat-sort-header="allocatedToAdminUser.id">Admin.</span>
                </app-autocomplete-filter>
              </mat-header-cell>

              <mat-cell *matCellDef="let case"> {{case.allocatedToAdminUser.firstName}} {{case.allocatedToAdminUser.lastName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="instructedDate">

              <mat-header-cell *matHeaderCellDef>
                <app-date-filter #filter title="Filter Instructed Date" filterColumn="instructedDate">
                  <span mat-sort-header>Instructed Date.</span>
                </app-date-filter>
              </mat-header-cell>

              <mat-cell *matCellDef="let case"> {{case.instructedDate  | date:'dd/MM/yyyy'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="caseType">
              <mat-header-cell *matHeaderCellDef>
                <span mat-sort-header="type.caseTypeName">Type</span>
              </mat-header-cell>
              <mat-cell *matCellDef="let case"> {{case.type.caseTypeName}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="companyGroup">
                <mat-header-cell *matHeaderCellDef>
                  <app-string-filter #filter title="Company Group." filterColumn="addedByUser.companyGroup.name">
                    <span mat-sort-header="addedByUser.companyGroup.name">Company.</span>
                  </app-string-filter>
                </mat-header-cell>
                <mat-cell *matCellDef="let case"> {{case.addedByUser.companyGroup.name}} </mat-cell>
              </ng-container>

            <ng-container matColumnDef="partyOneFirstName">
              <mat-header-cell *matHeaderCellDef>
                <app-string-filter #filter title="Filter First Name." filterColumn="partyOneFirstName">
                  <span mat-sort-header>First Name.</span>
                </app-string-filter>
              </mat-header-cell>
              <mat-cell *matCellDef="let case"> {{case.partyOneFirstName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="partyOneLastName">
              <mat-header-cell *matHeaderCellDef>
                <app-string-filter #filter title="Filter Last Name." filterColumn="partyOneLastName">
                  <span mat-sort-header>Last Name.</span>
                </app-string-filter>
              </mat-header-cell>
              <mat-cell *matCellDef="let case"> {{case.partyOneLastName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="partyOneAddressLine1">
              <mat-header-cell *matHeaderCellDef>
                <app-string-filter #filter title="Address Line 1." filterColumn="partyOneAddressLine1">
                  <span mat-sort-header>Address Line 1.</span>
                </app-string-filter>
              </mat-header-cell>
              <mat-cell *matCellDef="let case"> {{case.partyOneAddressLine1}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="partyOnePostCode">
              <mat-header-cell *matHeaderCellDef>
                <app-string-filter #filter title="Filter Post Code." filterColumn="partyOnePostCode">
                  <span mat-sort-header>Post Code.</span>
                </app-string-filter>
              </mat-header-cell>
              <mat-cell *matCellDef="let case"> {{case.partyOnePostcode}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="caseReference">
              <mat-header-cell *matHeaderCellDef>
                <app-string-filter #filter title="Case Ref." filterColumn="caseReference">
                  <span mat-sort-header>Case Ref.</span>
                </app-string-filter>
              </mat-header-cell>
              <mat-cell *matCellDef="let case"> {{case.caseReference}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lastWorkedOn">

              <mat-header-cell *matHeaderCellDef>
                <app-date-filter #filter title="Filter Last Worked On" filterColumn="lastWorkedOn">
                  <span mat-sort-header>Last Worked On.</span>
                </app-date-filter>
              </mat-header-cell>

              <mat-cell *matCellDef="let case"> {{case.lastWorkedOn  | date:'dd/MM/yyyy'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="caseSeverity">
              <mat-header-cell *matHeaderCellDef>
                <span mat-sort-header="caseSeverity">Tier</span>
              </mat-header-cell>
              <mat-cell *matCellDef="let case">
                <span class="tier" [ngClass]="{'green': case.caseSeverity == 1, 'orange': case.caseSeverity == 2, 'red': case.caseSeverity == 3 }">&nbsp;</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="percentageComplete">
                <mat-header-cell *matHeaderCellDef>
                  <span mat-sort-header="percentageComplete">Completeness</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let case">
                  {{case.percentageComplete}}%
                </mat-cell>
              </ng-container>


            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>
                <span mat-sort-header></span>
              </mat-header-cell>
              <mat-cell *matCellDef="let case">
                <button *ngIf="!case.onHold" mat-raised-button color="primary"
                  (click)="$event.stopPropagation(); putCaseOnHold(case.id)"><fa-icon [icon]="faPause"></fa-icon></button>
              </mat-cell>
            </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
        <mat-row *matRowDef="let case; columns: displayedColumns;" [routerLink]="['case', case.id]" [ngClass]="{'blue': case.onHold}" class="clickable-list-item"></mat-row>

  </table>

  <mat-spinner color="primary" diameter="20" *ngIf="isLoadingResults"></mat-spinner>
  <mat-paginator [length]="resultsLength" [pageSize]="25" [pageSizeOptions]="[10, 25, 50]" [showFirstLastButtons]="true">
  </mat-paginator>
