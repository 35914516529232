<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Select Email Addresses</h2>

<mat-dialog-content>


  <div class="listHeader">
    <span class="recipientType">Recipient Type</span><span class="emailAddress">Email Address</span>
  </div>
  <mat-list>
      <mat-list-item  class="emailAddressItem" *ngFor="let address of emailAddresses" (click)="selectAddress(address)" [class.selected]="address==selectedAddress">
        <span class="recipientType">{{address.recipientType}}</span><span class="emailAddress">{{address.emailAddress}}</span>
      </mat-list-item>

  </mat-list>

  <button mat-stroked-button (click)="addToAddress(selectedAddress)" class="selectButton">To</button>
    <mat-form-field>
        <input matInput value="" [(ngModel)]="selectedToAddresses">
      </mat-form-field>

      <br>
      <button mat-stroked-button (click)="addCCAddress(selectedAddress)" class="selectButton">CC</button>
      <mat-form-field>
          <input matInput value="" [(ngModel)]="selectedCCAddresses">
        </mat-form-field>

</mat-dialog-content>


<div mat-dialog-actions align="end">
    <button mat-stroked-button cdkFocusInitial (click)="selectAndClose()" color="primary">Ok</button>
  </div>
