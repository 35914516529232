import { Component, OnInit, Inject } from '@angular/core';
import { faConstruction} from '@fortawesome/pro-light-svg-icons';
import { PresubmissionChecklistService } from 'src/app/shared/services/presubmission-checklist.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LettersService } from 'src/app/shared/services/letters.service';
import { CorrespondenceTimelineService } from 'src/app/shared/services/correspondence-timeline.service';

@Component({
  selector: 'app-presubmission-checklist',
  templateUrl: './presubmission-checklist.component.html',
  styleUrls: ['./presubmission-checklist.component.css']
})
export class PresubmissionChecklistComponent implements OnInit {

  faConstruction = faConstruction;

  caseId: number = -1;

  generatingPDF: boolean = false;
  error: boolean = false;
  errorMessage: string = "";

  items: any [] = [];


  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
              public presubmissionChecklistService: PresubmissionChecklistService,
              public lettersService: LettersService,
              public dialogRef: MatDialogRef<PresubmissionChecklistComponent>,
              private correspondenceTimelineService: CorrespondenceTimelineService) {

      this.caseId = componentData.caseId;

      /*
      this.items.push({ id: 1, itemText: 'Any name and address discrepancy between title and security documents has been dealt with according to the instructions in the Information for Conveyancers. Certified image of ID documents.' });
      this.items.push({ id: 2, itemText: 'Mortgage Deed (also send original in post)' });
      this.items.push({ id: 3, itemText: 'Signed plan of all land owned by Customer and/or all land to be secured (please send a plan signed showing the extent of what the Customer believes they are securing and told the Lender’s valuer they own)' });
      this.items.push({ id: 3, itemText: 'Solicitors Certificate (also send original in post)' });
      this.items.push({ id: 3, itemText: 'Buildings Insurance Declaration (also send original in post)' });
      this.items.push({ id: 3, itemText: 'Special Conditions – information / certified copy documents / undertakings' });
      this.items.push({ id: 3, itemText: 'Buildings Insurance Declaration (also send original in post)' });
      this.items.push({ id: 3, itemText: 'Standard Undertakings form' });
      */

      // Get the items
      this.presubmissionChecklistService.getChecklistItemsForCase(this.caseId).subscribe((response : any) => {

        this.items = response.items;

      }, (error: any) => {

        this.error = true;
        if(error.status == 999)
        this.errorMessage = error.error;
      else if(error.status == 500 )
        this.errorMessage = "Internal Error";
      else
        this.errorMessage = error.statusText;
      });


  }

  ngOnInit(): void {
  }

  generatePDF() {
    this.generatingPDF = true;
    this.lettersService.generatePreSubmissionChecklist(this.caseId).subscribe((response : any) => {
      this.dialogRef.close();
      this.correspondenceTimelineService.refreshCaseTimeline.next();
    }, (error: any) => {
      this.generatingPDF = false;
      this.error = true;
      if(error.status == 999)
        this.errorMessage = error.error;
      else if(error.status == 500 )
        this.errorMessage = "Internal Error";
      else
        this.errorMessage = error.statusText;
    });  
  }

  updateValue(item: any){

    this.presubmissionChecklistService.updateItem(this.caseId, item.id, item.checklistResponseType).subscribe((response : any) => {


    }, (error: any) => {

      this.error = true;
      if(error.status == 999)
        this.errorMessage = error.error;
      else if(error.status == 500 )
        this.errorMessage = "Internal Error";
      else
        this.errorMessage = error.statusText;
    });
  }

  clearValue(item: any){
    item.checklistResponseType = null;
    this.updateValue(item);
  }

}
