import { Component, OnInit, Inject } from '@angular/core';
import { LookupsService } from 'src/app/shared/services/lookups.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-consultant',
  templateUrl: './select-consultant.component.html',
  styleUrls: ['./select-consultant.component.scss']
})
export class SelectConsultantComponent implements OnInit {

  externalConsultants: any[] = [];
  caseId: number;
  showTravelling: boolean = false;
  title = 'Select Consultant';
  loading = true;


  constructor(private lookupsService: LookupsService, public dialogRef: MatDialogRef<SelectConsultantComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.caseId = data.caseId;
    this.showTravelling = data.showTravelling;

    if (this.showTravelling) {
      this.title = 'Travelling Consultants in next 7 days'
    }

    this.loadData()
  }

  ngOnInit() {
  }

  loadData() {

    this.loading = true;

    if (this.showTravelling) {
      this.lookupsService.getTravellingConsultantsForCase(this.caseId).subscribe((result : any) => {
        this.externalConsultants = result;
        this.loading = false;
      });
    } else {
      this.lookupsService.getExternalConsultantsForCase(this.caseId).subscribe((result : any)  => {
        this.externalConsultants = result;
        this.loading = false;
      });
    }
  }

  close(id: number, name: string) {
    this.dialogRef.close({ externalConsultantId: id, externalConsultantName: name });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
