import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class KeyDatesService {

    private url = environment.apiUrl;

    constructor( private http: HttpClient, public router: Router) {

    }

  public refreshKeyDates  = new EventEmitter();
  public refreshKeyDatesObservable = this.refreshKeyDates.asObservable();

    getKeyDatesForCase(caseId: number) {
        return this.http.get<any>(this.url + 'cases/' + caseId + "/keydates" );
    }

    getAllKeyDatesForCase(caseId: number) {
      return this.http.get<any>(this.url + 'cases/' + caseId + "/allkeydates" );
    }

    addKeyDate(keyEventDetails: any) {
      return this.http.put<any>(this.url + 'keydates', keyEventDetails, httpOptions);
    }

    deleteKeyDate(id: number, details: any) {
      return this.http.post<any>(this.url + 'keydates/' + id + '/delete', details, httpOptions);
    }

    updateKeyDate(keyId: number, keyEventDetails: any) {
        return this.http.post<any>(this.url + 'keydates/' + keyId, keyEventDetails, httpOptions);
    }

    getKeyDateTypes(caseId: number) {
      return this.http.get<any>(`${this.url}cases/${caseId}/KeyDateTypes`);
    }

    bankStatementReceived(caseId: number | undefined) {
      return this.http.post<any>(this.url + 'keydates/bankstatementreceived/', { caseId : caseId}, httpOptions);
  }

  feeAgreementNotReceived(caseId: number | undefined) {
      return this.http.post<any>(this.url + 'keydates/feeagreementnotreceived/', { caseId : caseId}, httpOptions);
  }

  getKeyDateValue(caseId: number, keyDateTypeId: number) {

    let data = {
      caseId: caseId,
      KeyDateType: keyDateTypeId
    }

    return this.http.post<any>(this.url + 'keydates/getvalueforkeydate', data, httpOptions);
  }



}
