import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { SearchService } from '../../shared/services/search.service';
import { CaseFilesService } from '../../shared/services/casefiles.service';
import { Observable } from 'rxjs';
import { startWith, map, debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-send-to-case',
  templateUrl: './send-to-case.component.html',
  styleUrls: ['./send-to-case.component.scss']
})
export class SendToCaseComponent implements OnInit {

  file : any;
  myControl = new FormControl();
  options: string[] = [];
  filteredOptions?: Observable<string[]>;

  filteredItems: any[] | undefined = [];
  form = this.fb.group({
    search: null
  })
  isSearching = false;
  sendToCaseDisabled = true;
  sendingToCase = false;

  selectedCase: any;
  newFileName: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<SendToCaseComponent>,
              private fb: FormBuilder,
              private searchService: SearchService,
              private caseFiles: SearchService,
              private caseFilesService: CaseFilesService) {
                this.file = data.file;
              }

  ngOnInit(): void {

    var search = this.form.get('search');

    if (!search) return;

    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    search.valueChanges
    .pipe(
      debounceTime(300),
      tap(() => this.isSearching = true),
      switchMap(value => this.searchService.searchCases(value)
        .pipe(
          finalize(() => this.isSearching = false),
        )
      )
    )
    .subscribe((cases: any[]) => { this.filteredItems = cases; });
  }



  sendToCase(){

    this.caseFilesService.assignFileToCase( this.file.caseFileId, this.selectedCase.id, this.newFileName).subscribe(response => {
      this.dialogRef.close();
      this.caseFilesService.refreshUnassignedCaseFiles.next();

    });

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  displayFn(user: any) {
    if (user) { return user.name; }
  }

  selectCase(selectedCase: any){
    this.selectedCase = selectedCase;
    this.validateForm();
  }

  validateForm(){

    if(this.selectedCase && this.newFileName.length > 0)
      this.sendToCaseDisabled = false;
  }
}
