import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faCheck, faPlus, faEye, faCommentAltDots } from '@fortawesome/pro-light-svg-icons';
import { TaskService } from '../../shared/services/task.service';
import { AuthService } from '../../auth/auth.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddTaskComponent } from '../add-task/add-task.component';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-task-panel',
  templateUrl: './task-panel.component.html',
  styleUrls: ['./task-panel.component.scss']
})
export class TaskPanelComponent implements OnInit {

  @Input() task: any;
  @Input() tasksFor: string = '';
  @Input() userType: string = '';
  @Output() replyAdded = new EventEmitter<boolean>();
  @Output() valueChanged = new EventEmitter<any>();

  @Input() itemSelected: boolean = false;

  @Input() changing: Subject<boolean> = new Subject<boolean>();
  @Input() showCompleted: boolean = false;

  faPlus = faPlus;
  faCheck = faCheck;
  faEye = faEye;
  faCommentAltDots = faCommentAltDots;

  constructor(public authService: AuthService,
              private taskService: TaskService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.changing.subscribe(v => {
      this.itemSelected = v;
   });
  }

  getClassForTask(task: any) {

    if (task.completedDate) return 'done';

    let checkDate = DateTime.fromISO(task.dueByDate).toJSDate();

    let startOfToday = DateTime.local().startOf('day').toJSDate();
    let endOfToday = DateTime.local().endOf('day').toJSDate();

    if (checkDate >= startOfToday && checkDate <= endOfToday) return 'today';

    if (checkDate < startOfToday) return 'overdue';

    return 'future';
  }

  addTaskReply() {


    var dialogRef = this.dialog.open(AddTaskComponent, {
        data: {
          caseId: this.task.caseId,
          isNote: true,
          taskId: this.task.id
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == true)
          this.replyAdded.next(true);
      });

  }

  completeTask() {

    this.taskService.complete(this.task.id, this.task).subscribe(() => {
      this.replyAdded.next(true);
    })
  }

  selectItem(item: any){
    this.valueChanged.next({ taskId: this.task.id, selected: item.checked});
  }


}
