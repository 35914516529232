import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SearchRequest } from './interfaces/search-request';
import { SearchResult } from './interfaces/search-result';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class LendersService {

  private url = environment.apiUrl + 'lenders/';

  constructor(private http: HttpClient, public router: Router) {

  }


  getAll() {
    return this.http.get<any>(this.url);
  }

  lenderDetails(id: number) {
    return this.http.get<any>(this.url  + id);
  }

  addLender(lenderDetails: any) {
    return this.http.put<any>(this.url , lenderDetails, httpOptions);
  }

  updateLender(lenderDetails: any) {
    return this.http.post<any>(this.url, lenderDetails, httpOptions);
  }




}
