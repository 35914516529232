import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CorrespondenceTimelineService } from '../../services/correspondence-timeline.service';

@Component({
  selector: 'app-rename-item',
  templateUrl: './rename-item.component.html',
  styleUrls: ['./rename-item.component.scss']
})
export class RenameItemComponent implements OnInit {

  timelineItem: any = null;
  newName: string = '';
  renameDisabled: boolean = true;
  loading: boolean = false;
  hasError: boolean = false;
  errorMessage: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<RenameItemComponent>,
              private correspondenceTimelineService: CorrespondenceTimelineService) {

    this.timelineItem = data.timelineItem;

    if(this.timelineItem.correspondenceTypeId == 2 || this.timelineItem.correspondenceTypeId == 3 )
        this.newName = this.timelineItem.caseFile.fileNameWithoutExtension;
    else
        this.newName = this.timelineItem.summary;

    this.validateForm();
  }

  ngOnInit(): void {
  }

  validateForm(){
      if(this.newName.length > 0)
      this.renameDisabled = false;
  }

  renameItem(){
    this.loading = true;
    this.correspondenceTimelineService.renameItem( this.timelineItem.caseId, this.timelineItem.id, this.newName).subscribe(response => {
      this.loading = false;
      this.dialogRef.close();
      this.correspondenceTimelineService.refreshCaseTimeline.next();

    }, (error : any) => {

      this.hasError = true;
      this.loading = false;
      this.errorMessage = "Renaming Failed";
      if(error.status == 999)
        this.errorMessage = error.error;
      else
      {
        if (error.error !== null && error.error.message !== undefined) {
          this.errorMessage = error.error.message;
        }
      }


    });
  }

}
