import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LendersService } from '../../shared/services/lenders.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-lender-details',
  templateUrl: './lender-details.component.html',
  styleUrls: ['./lender-details.component.css']
})
export class LenderDetailsComponent implements OnInit {

  newLender: boolean = true;
  lenderId?: number;
  updatingLender: boolean = false;

  lenderDetails = this.fb.group({
    id: null,
    name: ['', Validators.required],
    isActive: []
  }, { });

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private lenderService: LendersService,
    private snackBar: MatSnackBar) {

      this.route.params.subscribe(params => {
        this.lenderId = params['id'];

        if(this.lenderId !== undefined && this.lenderId !== null){
          this.newLender = false;

          this.lenderService.lenderDetails(this.lenderId).subscribe(result => {
            this.lenderDetails.patchValue(result);
          });
        }
        else{
          this.lenderDetails.controls["isActive"].patchValue(true);
        }

      });

  }

  ngOnInit(): void {
  }

  saveLender(){

    this.updatingLender = true;

    this.lenderService.updateLender(this.lenderDetails.value).subscribe(response => {


      this.router.navigate(['/lenders/list']);
      this.updatingLender = false;

      }, (error : any) => {

        var errorMessage = "Update Failed";
        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }

        this.updatingLender = false;
        this.openSnackBar(errorMessage, 'Close');


      });
  }

  addLender(){
    this.lenderService.addLender(this.lenderDetails.value).subscribe(response => {

      this.router.navigate(['/lenders/list']);

      }, error => {


        var errorMessage = "Creation Failed";
        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }

        this.openSnackBar(errorMessage, 'Close');
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        duration: 5000,
    });
  }

}
