


    <button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>

    <button mat-flat-button (click)="viewHistory()" color="primary" title="History" class="historyButton">
        <fa-icon [icon]="faHistory"></fa-icon>
      </button>
      <h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Case Overview</h2>
<mat-dialog-content class="mat-typography">
    <div class="caseNotes" *ngIf="items" >



        <mat-list *ngFor="let item of items.caseOverviewItems; let lastItem = last;" dense>
          <mat-list-item ><!-- style="height: unset"-->

              <mat-icon matListIcon  (click)="removeOverviewItem(item.id)" color="warn" class="clickable"><fa-icon [icon]="faTrashAlt" ></fa-icon></mat-icon>
              <!--p matLine style="white-space: normal;">{{item.text}} <span class="addedBy" >({{item.addedBy.firstName}} {{item.addedBy.lastName}} - {{item.addedDateTime  | date:'dd/MM/yyyy HH:mm'}})</span>  <fa-icon class="fromCaseNotes" *ngIf="item.fromCaseNotes" [icon]="faClipboard" ></fa-icon> </p-->
              <p matLine style="word-wrap: break-word; white-space: pre-wrap;">{{item.text}} <span class="addedBy" >({{item.addedBy.firstName}} {{item.addedBy.lastName}} - {{item.addedDateTime  | date:'dd/MM/yyyy HH:mm'}})</span>  <fa-icon class="fromCaseNotes" *ngIf="item.fromCaseNotes" [icon]="faClipboard" ></fa-icon> </p>

          </mat-list-item>
        </mat-list>
      </div>
</mat-dialog-content>

<div mat-dialog-actions align="start">
    <button mat-mini-fab (click)="addOverviewItem()" color="primary">
      <fa-icon [icon]="faPlus"></fa-icon>
    </button>
</div>


