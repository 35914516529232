import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../auth/auth.service';
import { TaskService } from '../../shared/services/task.service';
import { UserService } from '../../shared/services/user.service';
import { DateTime } from 'luxon';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.css']
})
export class AddTaskComponent implements OnInit {


  taskForm = new FormGroup({
    dueByDate: new FormControl('', Validators.required),
    entry: new FormControl('', Validators.required),
  });

  maxDate: Date = new Date();
  minDate: Date = new Date();
  caseId: number;
  isNote = false;
  title = '';
  taskId: number;
  invalidSelection: boolean = true;

  users: any[] = [];
  filteredUsers: Observable<any[]> = new Observable<any[]>();
  searchInput = new FormControl();

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<AddTaskComponent>,
  private authService: AuthService,
  private taskService: TaskService,
  private userService: UserService) {

    this.caseId = data['caseId'];
    this.isNote = data['isNote'];
    this.taskId = data['taskId'];

    if (this.isNote) {
      this.title = 'Add Note to task'
      this.taskForm.get('dueByDate')!.clearValidators();
      this.invalidSelection = false;
    } else {
      this.title = 'Add Task'
    }

    this.minDate = DateTime.local().toJSDate();
    this.maxDate = DateTime.local().plus({months : 3}).toJSDate();

    userService.adminsAndParalegals().subscribe((results : any) => {

      for (const user of results) {
          this.users.push({ name: user.firstName + ' ' + user.lastName, id: user.id })
      }

      this.loadUsers()
    });
  }

  save() {

    if (this.isNote) {
      this.addNote();
    } else {
      this.addTask();
    }

  }

  addNote() {
    var reply = {
      entry: this.taskForm.get('entry')!.value,
    }

    this.taskService.addTaskReply(this.taskId, reply).subscribe(() => {
      this.dialogRef.close(true);
    });
  }

  addTask() {

    this.checkValid();

    if(!this.invalidSelection){

    var task = {
      caseId: this.caseId,
      createdByUserId: this.authService.currentUserID(),
      createdForUserId: this.searchInput.value.id,
      entry: this.taskForm.get('entry')!.value,
      dueByDate: this.taskForm.get('dueByDate')!.value
    }

    this.taskService.addTask(task).subscribe(() => {
      this.dialogRef.close(true);
    });
  }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  loadUsers(){
    this.filteredUsers = this.searchInput.valueChanges
      .pipe(
        startWith<string | any>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterUsers(name) : this.users.slice())
      );
  }

  displayFn(user: any): string {
    return user ? user.name : '';
  }

  checkValid(){
    this.invalidSelection = true;

    if (this.isNote) {
      this.invalidSelection = false;
    } else {
      if(this.searchInput.value.id > 0 && this.taskForm.get('entry')!.value && this.taskForm.get('dueByDate')!.value)
      this.invalidSelection = false;
    }


  }

  private _filterUsers(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.users.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit(): void {
  }

}
