import { Component, ViewChild, Self, Optional, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NgControl, FormGroupDirective, ControlContainer } from '@angular/forms';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.scss'],
  viewProviders: [
      {
        provide: ControlContainer,
        useExisting: FormGroupDirective
      }],
   host: {
       '[class.mat-form-field-invalid]': 'ngControl.invalid',
       '[class.mat-form-field-disabled]': 'ngControl.disabled',
       '[class.mat-form-field-autofilled]': 'ngControl.autofilled',
       '[class.mat-focused]': 'ngControl.focused',
   }
})
export class YesNoComponent implements ControlValueAccessor {

  faCheck = faCheck;
  faTimes = faTimes;

  @Output() change = new EventEmitter<boolean>();
  @Input() isDisabled: boolean = false;

  @ViewChild("group") group : any;

  _value = false;
  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
      return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
}


constructor(@Self() @Optional() public ngControl: NgControl, public parent: FormGroupDirective) {
  if (this.ngControl) {
      // Note: we provide the value accessor through here, instead of
      // the `providers` to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
}

registerOnChange(fn: any) {
  this.onChange = fn;
}

writeValue(value: any) {

  if(this.group)
    this.group.value = value;
    this.value = value;
}

registerOnTouched(fn: any) {
  this.onTouched = fn;
}

switch() {
  this.value = this.group.value;
  this.change.next(this.value);
}

}
