
<button *ngIf="tasksFor == 'case'" mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 *ngIf="tasksFor == 'case'" mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Actions</h2>

<div class="edit" *ngIf="tasksFor == 'user' && userType !== 'management'">
  <button mat-raised-button [disabled]="itemsSelected.length == 0" (click)="reAllocateTasks()" color="primary"
      *ngIf="authService.canReallocateTasks()">Reallocate Tasks</button>
</div>

<mat-accordion displayMode="flat" multi class="mat-table">
    <section matSort class="mat-header-row">


        <span class="mat-header-cell mat-column-select" *ngIf="tasksFor == 'user' && authService.canReallocateTasks() && userType !== 'management'">
            <mat-checkbox (change)="masterToggle($event)" [indeterminate]="itemsSelected.length > 0 && !isAllSelected()">
             </mat-checkbox>
       </span>


      <span class="mat-header-cell" *ngIf="tasksFor == 'user'">
        <app-string-filter #filter #ref title="Filter Case Ref" filterColumn="caseRef">
          <span mat-sort-header="caseRef"> Case Ref.</span>
        </app-string-filter>
      </span>

      <span class="mat-header-cell" *ngIf="tasksFor == 'user'">
        <span mat-sort-header="caseName"> Name.</span>
        <!-- <app-date-filter #filter title="Filter Case Name" filterColumn="caseName"></app-date-filter> -->
      </span>

      <span class="mat-header-cell" *ngIf="tasksFor == 'user'">
        <span mat-sort-header="caseType.caseTypeName"> Type.</span>
      </span>

      <span class="mat-header-cell">
        <app-date-filter #filter title="Filter Due Date" filterColumn="dueByDate">
          <span mat-sort-header="dueBydate"> Due By Date.</span>
        </app-date-filter>
      </span>


      <span class="mat-header-cell">
        <app-autocomplete-filter #filter title="Filter Created For User." filterColumn="createdForUser.id" [options]="staff"
          nameField="name" valueField="id" valueType="int">
          <span mat-sort-header="createdForUser.id"> For User.</span>
        </app-autocomplete-filter>
      </span>



      <span class="mat-header-cell action">
        <app-string-filter #filter title="Filter Action" filterColumn="action">
          <span mat-sort-header="action"> Action.</span>
        </app-string-filter>
      </span>

      <span class="mat-header-cell">
        <app-date-filter #filter title="Filter Due Date" filterColumn="createdDate">
          <span mat-sort-header="createdDate"> Created Date.</span>
        </app-date-filter>
      </span>

      <span class="mat-header-cell">
        <app-autocomplete-filter #filter title="Filter Created By User." filterColumn="createdByUser.id"
          [options]="staff" nameField="name" valueField="id" valueType="int">
          <span mat-sort-header="createdByUser"> Created By User.</span>
        </app-autocomplete-filter>
      </span>

      <span class="mat-header-cell" *ngIf="showCompleted">
        <app-date-filter #filter title="Filter Completed Date" filterColumn="completedDate">
          <span mat-sort-header="completedDate"> Completed Date.</span>
        </app-date-filter>
      </span>

      <span class=" mat-small-header-icon-cell"> 
      </span>

    </section>


  <app-task-panel *ngFor="let task of data" [task]="task" [tasksFor]="tasksFor" (replyAdded)="load()" [changing]="changingValue" (valueChanged)="grabbingValues($event)" [userType]="userType" [showCompleted]="showCompleted"></app-task-panel>

</mat-accordion>
<mat-spinner color="primary" diameter="20" *ngIf="isLoadingResults"></mat-spinner>
<mat-paginator [length]="resultsLength" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]"
  [showFirstLastButtons]="true">
</mat-paginator>

<button mat-mini-fab class="add" *ngIf=" showAdd && !readOnly" (click)="addTask()" color="primary">
    <fa-icon [icon]="faPlus"></fa-icon>
</button>

