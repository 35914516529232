<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<button mat-flat-button (click)="viewHistory()" color="primary" title="History" class="historyButton">
    <fa-icon [icon]="faHistory"></fa-icon>
  </button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Key Dates</h2>

<mat-spinner color="primary" diameter="20" *ngIf="!keyDatesLoaded"></mat-spinner>

<mat-dialog-content class="mat-typography">
  <div *ngFor="let keydate of caseKeyDates" >

      <app-key-date *ngIf="keydate.keyDateTypeId == 1" [label]="keydate.keyDateName" [readOnly]="readOnly"
      [(keyDate)]="keydate.keyDateValue"
      [keyDateId]="keydate.keyDateId"
      [keyDateTypeId]="keydate.keyDateTypeId"
      [canView]="true"
      [canAdd]="false"
      [canEdit]="false"
      (add)="addKeyDate($event)" (edit)="editKeyDate($event)" (delete)="deleteKeyDate($event)"></app-key-date>

      <app-key-date *ngIf="keydate.keyDateTypeId != 1 && keydate.keyDateTypeId != 2 && keydate.keyDateTypeId != 22" [label]="keydate.keyDateName" [readOnly]="readOnly"
      [(keyDate)]="keydate.keyDateValue"
      [keyDateId]="keydate.keyDateId"
      [keyDateTypeId]="keydate.keyDateTypeId"
      [enteredByUser]="keydate.enteredByUser"
      [canView]="true"
      [canAdd]="authService.isALStaff() && keydate.canEdit"
      [canEdit]="authService.isALStaff() && keydate.canEdit"
      [includeTime]="keydate.includeTime"
      (add)="addKeyDate($event)" (edit)="editKeyDate($event)" (delete)="deleteKeyDate($event)"></app-key-date>

      <!-- Cancelled -->
      <app-key-date *ngIf="keydate.keyDateTypeId == 2" [label]="keydate.keyDateName" [readOnly]="readOnly"
      [(keyDate)]="keydate.keyDateValue"
      [keyDateId]="keydate.keyDateId"
      [keyDateTypeId]="keydate.keyDateTypeId"
      [enteredByUser]="keydate.enteredByUser"
      [canView]="true"
      [canAdd]="authService.isALStaff() && keydate.canEdit"
      [canEdit]="false"
      (add)="cancelCase()"></app-key-date>

      <span *ngIf="keydate.keyDateTypeId == 22" > <!-- Completion Date -->
          <app-key-date [label]="keydate.keyDateName"
          [readOnly]="readOnly"
          [(keyDate)]="keydate.keyDateValue"
          [keyDateId]="keydate.keyDateId"
          [keyDateTypeId]="keydate.keyDateTypeId"
          [enteredByUser]="keydate.enteredByUser"
          [canView]="true"
          [canAdd]="authService.isALStaff() && keydate.canEdit"
          [canEdit]="authService.isALStaff() && keydate.canEdit"
          (add)="addKeyDate($event)" (edit)="editKeyDate($event)" (delete)="deleteKeyDate($event)"></app-key-date>

          <app-card-label label="Completion Month" >
            <mat-form-field floatLabel="never">
                <input matInput [ngModel]="getMonthForDate(keydate.keyDateValue)" disabled>
            </mat-form-field>
          </app-card-label>
      </span>
  </div>
</mat-dialog-content>
