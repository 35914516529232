<app-card-basic cardTitle="Company Groups" >
    <div card-body>

      <div class="button-bar">
        <app-searchbar searchType="CompanyGroups" [inline]="true"></app-searchbar>
        <button mat-mini-fab (click)="addCompanyGroup()" color="primary"><fa-icon [icon]="faPlus"></fa-icon></button>
      </div>

      <div>
        <table mat-table [dataSource]="companyGroups" matSort matSortActive="name" matSortDisableClear
          matSortDirection="asc" class="clickable-list-item">
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Name. </mat-header-cell>
            <mat-cell *matCellDef="let companyGroup"> {{companyGroup.name}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="adminEmailAddress">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Admin Email Address. </mat-header-cell>
              <mat-cell *matCellDef="let companyGroup"> {{companyGroup.adminEmailAddress}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="additionalInformation">
              <mat-header-cell *matHeaderCellDef > Additional Information. </mat-header-cell>
              <mat-cell *matCellDef="let companyGroup"> {{companyGroup.information}} </mat-cell>
            </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
          <mat-row *matRowDef="let companyGroup; columns: displayedColumns;" (click)="editCompanyGroup(companyGroup)">
          </mat-row>

        </table>

        <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
      </div>
    </div>
  </app-card-basic>
