import { Component, Input, AfterViewInit, ViewChild, ViewChildren, QueryList, ChangeDetectorRef, Inject } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { BaseFilterComponent } from '../shared/components/table-filters/base-filter.component';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { Subscription } from 'rxjs';
import { merge, of, Subject } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { TaskService } from '../shared/services/task.service';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../shared/services/user.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchRequest } from '../shared/services/interfaces/search-request';
import { ReallocateTaskModalComponent } from './reallocate-task/reallocate-task.component';
import { AddTaskComponent } from './add-task/add-task.component';
import { BasicPopupOkComponent } from '../shared/components/basic-popup-ok/basic-popup-ok.component';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements AfterViewInit {

  viewInit = false;
  _caseId?: (number | undefined);

  @Input() readOnly: boolean  = false;
  @Input() userType: string  = '';

  @Input() showCompleted: boolean = false;

  @Input() caseCompleted: boolean  = false;

  @Input()
  set caseId(value: (number | undefined)) {
    this._caseId = value;
    this.load();
  }
  get caseId() : (number | undefined)  {
    return this._caseId;
  }

  _tasksFor: string = '';

  @Input()
  set tasksFor(value: string) {

    this._tasksFor = value;

    if (value != "user") {
      this.showAdd = true;
    }
  }
  get tasksFor(): string {
    return this._tasksFor;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype);
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChildren('filter') filters: QueryList<BaseFilterComponent> = new QueryList<BaseFilterComponent>();

  data: any[] = [];
  isLoadingResults = true;
  resultsLength = 0;
  showAdd = false;
  faPlus = faPlus;
  staff: any[] = [];
  isMobile = false;
  listHeader: string = '';
  refreshSubscription?: Subscription;

  itemsSelected: any[] =[];

  changingValue: Subject<boolean> = new Subject();
  tellChild(allItemsSelected : any){
    this.changingValue.next(allItemsSelected);
  }

  userTaskMode: boolean = false;
  taskTableName: string = "";

  searchCriteria: SearchRequest = {page: 1, pageSize: 25, orderBy: "dueByDate", orderDirection: "asc", filterColumns: [], filterStrings: []};

  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
              public dialog: MatDialog,
              private taskService: TaskService,
              public authService: AuthService,
              usersService: UserService) {


   if(componentData.caseId)
    {
      this.caseId = componentData.caseId;
      this.tasksFor = componentData.tasksFor;
    }


    usersService.staffLookup.subscribe((results : any) => {
      this.staff = results;
    });

    this.refreshSubscription = this.taskService.refreshTasksObservable.subscribe(() => {
      this.load();
    });
  }

  ngAfterViewInit(): void {
    this.viewInit = true;



    this.load();
   // this.taskService.refreshTasks.next();
  }

  load() {

    this.itemsSelected =[];

    if(!this.viewInit) return;

    this.listHeader = "Actions";

    if (this.tasksFor == "user")
    {
      this.userTaskMode = true;

      if(this.authService.isPortalManager() || this.authService.overrideCanSeeAdminActionList() || this.authService.isPortalParaLegalTL()) // these users will have a split action list
      {
        if(this.userType == "admin")
        {
          this.listHeader = "Admin Actions";
          this.taskTableName = "adminTasksList";
        }

        if(this.userType == "paralegal")
        {
          this.listHeader = "Paralegal Actions";
          this.taskTableName = "paralegalTasksList";
        }
      }

      if(this.userType == "management")
      {
        this.listHeader = "Management Actions";
        this.taskTableName = "managementTasksList";
      }

      localStorage.setItem('selectedTaskTab', this.userType );

      let storedSearchCriteria = localStorage.getItem(this.taskTableName);
      let searchRequest = {page: 1, pageSize: 25, orderBy: this.sort.active || 'dueByDate', orderDirection: this.sort.direction || 'asc', filterNames: [], filterColumns: [], filterStrings: []};

      if(storedSearchCriteria != null)
      {
        searchRequest = JSON.parse(storedSearchCriteria);

        this.filters.forEach((item : any) => {

          if(item.componentTypeName == "StringFilterComponent" || item.componentTypeName == "DateFilterComponent" || item.componentTypeName == "AutocompleteFilterComponent")
          {
            var columnIndex = searchRequest.filterColumns.findIndex(element => element == item.filterColumn);
            var filterName = searchRequest.filterNames[columnIndex];
            var storedValue = searchRequest.filterStrings[columnIndex];
            item.filterName = filterName;
            item.filterValue = storedValue;
          }

        });

        this.searchCriteria.filterStrings = searchRequest.filterStrings;
        this.sort.active = searchRequest.orderBy;
        this.sort.direction = searchRequest.orderDirection as SortDirection;
        this.sort.sortChange.emit();
      }

    }

    const outputs = this.filters.map(button => button.filter);

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => { this.paginator.pageIndex = 0 });
    merge(...outputs).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page, ...outputs)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;



          if (this.tasksFor == "user")
          {



            this.searchCriteria.page = this.paginator.pageIndex + 1;
            this.searchCriteria.pageSize = this.paginator.pageSize;
            this.searchCriteria.orderBy = this.sort.active || 'dueByDate';
            this.searchCriteria.orderDirection = this.sort.direction || 'asc';

            this.searchCriteria.filterStrings = this.filters.map(button => button.filterValue);
            this.searchCriteria.filterColumns = this.filters.map(button => button.filterColumn);

            let saveSearch = {
              orderBy: this.sort.active || 'dueByDate',
              orderDirection: this.sort.direction || 'asc',
              filterNames: this.filters.map(button => button.filterName),
              filterColumns: this.filters.map(button => button.filterColumn),
              filterStrings: this.filters.map(button => button.filterValue)
            };

            localStorage.setItem(this.taskTableName, JSON.stringify(saveSearch));



            let searchRequest: SearchRequest = {
              page: this.paginator.pageIndex + 1,
              pageSize: this.paginator.pageSize,
              orderBy: this.sort.active || 'dueByDate',
              orderDirection: this.sort.direction || 'asc',
              filterColumns: this.filters.map(button => button.filterColumn),
              filterStrings: this.filters.map(button => button.filterValue)
            };

            console.log(this.searchCriteria);

            if(this.userType == "admin")
              return this.taskService.adminUserTasks(this.searchCriteria, this.showCompleted);
            else if(this.userType == "management")
              return this.taskService.managementUserTasks(this.searchCriteria, this.showCompleted);
            else
              return this.taskService.paralegalUserTasks(this.searchCriteria, this.showCompleted);




          }
          else
          {
            let caseTasksSearchRequest: SearchRequest = {
              page: this.paginator.pageIndex + 1,
              pageSize: this.paginator.pageSize,
              orderBy: this.sort.active || 'dueByDate',
              orderDirection: this.sort.direction || 'asc',
              filterColumns: this.filters.map(button => button.filterColumn),
              filterStrings: this.filters.map(button => button.filterValue)
            };

            return this.taskService.caseTasks(this.caseId, caseTasksSearchRequest);
          }
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.recordCount;
          return data.items;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return of([]);
        })
      ).subscribe(data => { this.data = data; });

  }

  isAllSelected() {

    const numSelected = this.itemsSelected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle(allSelected: any) {

    this.itemsSelected = [];
    this.tellChild(allSelected.checked);

    if(allSelected.checked){
      this.data.forEach(item => this.itemsSelected.push({ taskId: item.id, selected: true}));
    }
  }

  grabbingValues(val: any){

    if(val.selected)
      this.itemsSelected.push(val);
    else
    {
      this.itemsSelected = this.itemsSelected.filter(item => item.taskId !== val.taskId);
    }
  }

  ngOnDestroy() {

    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }


  reAllocateTasks(){

    var dialogRef = this.dialog.open(ReallocateTaskModalComponent, {
      data: {
        selection: this.itemsSelected,
        taskType: this.userType
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true)
        this.load();
    });

  }

  addTask() {

    if(!this.caseCompleted)
    {

      var dialogRef = this.dialog.open(AddTaskComponent, {
        data: {
          caseId: this.caseId,
          isNote: false,
          taskId: null
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == true)
          this.load();
      });

    }
    else if(this.caseCompleted){

      this.dialog.open(BasicPopupOkComponent, {
        data: { messageHeader: '', popupText1: 'Unable to add an Action as the case is Completed', popupText2: ''}
      });

    }

  }

}
