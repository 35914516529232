<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<div mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane"><h2><span *ngIf="!editNote">Add </span>Call In/Out</h2></div>


<mat-dialog-content class="mat-typography">
  <form [formGroup]="phoneCallDetails" autocomplete="off">

    <app-card-label label="Call Direction">
      <mat-form-field floatLabel="never">
          <mat-select placeholder="" formControlName="incoming">
              <mat-option [value]="true">Incoming</mat-option>
              <mat-option [value]="false">Outgoing</mat-option>
          </mat-select>
      </mat-form-field>
    </app-card-label>


    <app-card-label label="Caller Type">
        <mat-form-field floatLabel="never" >
            <mat-select placeholder="" formControlName="callerType">
                    <mat-option [value]="1">Lender Solicitor</mat-option>
                    <mat-option [value]="2">Broker</mat-option>
                    <mat-option [value]="3">Estate Agent</mat-option>
                    <mat-option [value]="4">Client</mat-option>
                    <mat-option [value]="5">Current Lender</mat-option>
                    <mat-option [value]="6">Landlord</mat-option>
                    <mat-option [value]="7">Land Registry</mat-option>
                    <mat-option [value]="0">Other</mat-option>
            </mat-select>
        </mat-form-field>
    </app-card-label>

    <app-card-label label="Notes">
      <mat-form-field floatLabel="never">
          <textarea autosize matInput #message formControlName="notes"></textarea>
      </mat-form-field>
    </app-card-label>

  </form>

</mat-dialog-content>

<div mat-dialog-actions align="end" *ngIf="!editNote">
  <button *ngIf="!addingPhoneCall"  mat-stroked-button  color="primary" cdkFocusInitial (click)="addPhoneCall()" [disabled]="addingPhoneCall || phoneCallDetails.invalid">Add</button>
  <button *ngIf="addingPhoneCall" mat-icon-button disabled color="primary" class="right" >
      <mat-icon>
          <mat-spinner color="primary" diameter="20">
          </mat-spinner>
      </mat-icon>
  </button>
</div>

<div mat-dialog-actions align="end" *ngIf="editNote">
    <button *ngIf="!editingPhoneCall"  mat-stroked-button  color="primary" cdkFocusInitial (click)="updatePhoneCall()" [disabled]="editingPhoneCall">Update</button>
    <button *ngIf="editingPhoneCall" mat-icon-button disabled color="primary" class="right" >
        <mat-icon>
            <mat-spinner color="primary" diameter="20">
            </mat-spinner>
        </mat-icon>
    </button>
  </div>
