<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title>Key Dates History</h2>

<div mat-dialog-content class="mat-typography">
    <div>

        <table mat-table [dataSource]="keyDates" >

        <ng-container matColumnDef="keyDateType">
          <mat-header-cell *matHeaderCellDef  style="min-width: 30%;"> Key Date. </mat-header-cell>
          <mat-cell *matCellDef="let keyDate"  style="min-width: 30%;"> {{keyDate.keyDateTypeName}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="value">
          <mat-header-cell *matHeaderCellDef> Value. </mat-header-cell>
          <mat-cell *matCellDef="let keyDate"> {{formatDateOnly(keyDate.value)}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="enteredByUser">
          <mat-header-cell *matHeaderCellDef> Added By. </mat-header-cell>
          <mat-cell *matCellDef="let keyDate"> <span *ngIf="keyDate.enteredByUser">{{keyDate.enteredByUser.firstName}} {{keyDate.enteredByUser.lastName}} </span></mat-cell>
        </ng-container>

        <ng-container matColumnDef="enteredOn">
            <mat-header-cell *matHeaderCellDef> Date Added. </mat-header-cell>
            <mat-cell *matCellDef="let keyDate"> {{formatDateWithTime(keyDate.enteredOn)}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="removedByUser">
            <mat-header-cell *matHeaderCellDef> Removed By. </mat-header-cell>
            <mat-cell *matCellDef="let keyDate"><span *ngIf="keyDate.removedByUser">{{keyDate.removedByUser.firstName}} {{keyDate.removedByUser.lastName}}</span></mat-cell>
          </ng-container>

        <ng-container matColumnDef="removedDate">
          <mat-header-cell *matHeaderCellDef> Removed Date. </mat-header-cell>
          <mat-cell *matCellDef="let keyDate"> {{formatDateWithTime(keyDate.removedDate)}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="removalReason">
          <mat-header-cell *matHeaderCellDef> Reason. </mat-header-cell>
          <mat-cell *matCellDef="let keyDate"> {{keyDate.removalReason}} </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
        <mat-row *matRowDef="let keyDate; columns: displayedColumns;" ></mat-row>

      </table>

      </div>



  </div>
