import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { CaseService } from '../../../shared/services/case.service';
import { CorrespondenceTimelineService } from 'src/app/shared/services/correspondence-timeline.service';
import { BasicPopupYesNoComponent } from '../../../shared/components/basic-popup-yes-no/basic-popup-yes-no.component';

@Component({
  selector: 'app-phonecall',
  templateUrl: './phonecall.component.html',
  styleUrls: ['./phonecall.component.css']
})
export class PhonecallComponent implements OnInit {

  caseId: number = -1;
  caseTypeId?: number;
  addingPhoneCall: boolean = false;
  phonecallObj: any;
  editNote = false;
  editingPhoneCall: boolean = false;

  phoneCallDetails = this.fb.group({
    callerType: [null, Validators.required],
    incoming: [true, Validators.required] ,
    notes: ['', Validators.required],
    addToOverview: [false]
  })


  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
              private fb: FormBuilder,
              public dialog: MatDialog,
              private caseService: CaseService,
              public dialogRef: MatDialogRef<PhonecallComponent>,
              private correspondenceTimelineService: CorrespondenceTimelineService) {

    this.phonecallObj = componentData.phonecallObj;

    console.log(this.phonecallObj);

    if(this.phonecallObj == null)
    {
      this.caseId = componentData.caseId;
      this.caseTypeId = componentData.caseTypeId;

      Object.keys(this.phoneCallDetails.controls).forEach(key => {
        this.phoneCallDetails.get(key)!.markAsTouched();
        this.phoneCallDetails.get(key)!.updateValueAndValidity();
      });
    }
    else{
      this.editNote = true;
      this.caseId = this.phonecallObj.caseId;

      this.phoneCallDetails.controls['callerType'].patchValue(this.phonecallObj.callerType);
      this.phoneCallDetails.controls['callerType'].disable();
      this.phoneCallDetails.controls['incoming'].patchValue(this.phonecallObj.incoming);
      this.phoneCallDetails.controls['incoming'].disable();
      this.phoneCallDetails.controls['notes'].patchValue(this.phonecallObj.notes);
      //this.phoneCallDetails.controls['notes'].disable();
    }

  }

  ngOnInit() :void {
  }

  addPhoneCall(){

    this.addingPhoneCall = true;

    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Is this very important information required in the Case Overview Screen?', popupText1: '', popupText2: ''}
    });


    dialogRef.afterClosed().subscribe((result : any) => {

      if(result)
      {
        this.phoneCallDetails.controls['addToOverview'].patchValue(true);
      }

      this.caseService.addPhoneCall(this.caseId, this.phoneCallDetails.value).subscribe((response : any) => {

        this.dialogRef.close(true);
        this.correspondenceTimelineService.refreshCaseTimeline.next();

        }, (error : any) => {

          var errorMessage = "Failed to add phone call";
          if(error.error !== null && error.error.message !== undefined)
          {
            errorMessage = error.error.message;
          }

        });

    });
  }

  updatePhoneCall(){

    this.editingPhoneCall = true;

    this.caseService.editPhoneCall(this.caseId, this.phonecallObj.id, this.phoneCallDetails.value).subscribe((response : any) => {

      this.dialogRef.close(true);
      this.correspondenceTimelineService.refreshCaseTimeline.next();

      }, (error : any) => {

        var errorMessage = "Failed to update phone call";
        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }

      });
  }

  cancel(){
    this.dialogRef.close(null);
  }

}
