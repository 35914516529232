import { Component, OnInit, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { SendToCaseComponent } from './send-to-case/send-to-case.component';
import { UnassignedFileComponent } from './unassigned-file/unassigned-file.component';
import { CaseFilesService } from '../shared/services/casefiles.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-unassigned-files',
  templateUrl: './unassigned-files.component.html',
  styleUrls: ['./unassigned-files.component.css']
})
export class UnassignedFilesComponent implements OnInit {

  files: any[] = [];
  selectedFile: any;
  allowMultiSelect: boolean = false;
  selectedItems: any[] = [];

  @ViewChild(MatMenuTrigger)  contextMenu!: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };

  @ViewChildren(UnassignedFileComponent) unassignedFiles!: QueryList<UnassignedFileComponent>;

  refreshSubscription: Subscription;

  constructor(private caseFilesService: CaseFilesService,
              public dialog: MatDialog) {

    this.loadFiles();

    this.refreshSubscription = this.caseFilesService.refreshUnassignedCaseFilesObservable.subscribe(() => {
      this.loadFiles();
    });
  }

  ngOnInit(): void {
  }

  loadFiles(){

    this.caseFilesService.getUnassignedFiles().subscribe(response => {
      this.files = response.files;
    });

  }

  onContextMenu(event: MouseEvent, file: any) {
    event.preventDefault();
    event.stopPropagation();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';

    this.selectedFile = file;
    this.contextMenu.menuData = { 'file': file };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  dblClickItem(item: any){

    this.caseFilesService.getFile(item.caseFileId).subscribe(response => {

      // For PDFs and images
      const fileURL = URL.createObjectURL(response.body);
      window.open(fileURL, '_blank');

    });
  }

  openSendToCase(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      file: this.selectedFile
    };

    const dialogRef = this.dialog.open(SendToCaseComponent, dialogConfig);
  }

  ngOnDestroy(){
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }


}
