<button mat-icon-button class="close" tabindex="-1" (click)="close()">x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Enquiry Details</h2>

<mat-dialog-content class="mat-typography">
  <form [formGroup]="enquiryDetails" autocomplete="off">



    <mat-form-field>
      <textarea matInput #enquiry placeholder="Enquiry" maxlength="300" formControlName="enquiry"></textarea>
      <mat-hint [align]="'end'">{{enquiry.value?.length || 0}}/300</mat-hint>
    </mat-form-field>


    <app-date-picker [simpleCardLabel]="'Date Received'" [simpleCardForm]="true" [dateValue]="enquiryDetails.value.receivedDate" (onDateChangeEvent)="onDateReceivedChange($event)"></app-date-picker>
    <!--<app-date-picker [simpleCardLabel]="'Date Replied'" [simpleCardForm]="true" (onDateChangeEvent)="onDateRepliedChange($event)"></app-date-picker>
    <app-date-picker [simpleCardLabel]="'Date Satisfied'" [simpleCardForm]="true" (onDateChangeEvent)="onDateSatisfiedChange($event)"></app-date-picker>-->

    <div class="buttons">
      <button mat-stroked-button [disabled]="false" color="warn" type="button" (click)="close()">Cancel</button>
      <button mat-stroked-button color="primary" (click)="save()">Save</button>
    </div>

  </form>

</mat-dialog-content>
