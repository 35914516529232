<div>
  <div class="caseHeader mat-elevation-z">
    <!--h1>{{headingText}} </h1-->
    <div [ngClass]="{'warning':warning, 'alert': alert , 'info': info}" [ngStyle]="{ 'background-color' : getBackgroundColour(overrideBackgroundColour)}">
      <div class="message" *ngIf="warning && !alert">
        <fa-icon class="warning-icon" [icon]="faQuestionCircle"></fa-icon> <span> {{message}}</span>
      </div>
      <div class="message" *ngIf="alert">
        <fa-icon class="alert-icon" [icon]="faExclamationTriangle"></fa-icon> <span> {{message}}</span>
      </div>
      <div class="message" *ngIf="!alert && !warning"><span> {{message}}</span></div>
    </div>

  </div>
</div>
