import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { faPlus, faTimes, faExternalLink, faShare, faPencil } from '@fortawesome/pro-light-svg-icons';
import { BasicPopupYesNoComponent } from 'src/app/shared/components/basic-popup-yes-no/basic-popup-yes-no.component';
import { AddPreInvoiceFeeComponent } from  './add-fee/add-pre-invoice-fee.component';
import { PreInvoiceFeesService } from '../../../shared/services/pre-invoice-fees.service';
import { SelectPreInvoiceFeeSupplierComponent } from './select-pre-invoice-fee-supplier/select-pre-invoice-fee-supplier.component';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { KlyantService } from 'src/app/shared/services/klyant.service';

@Component({
  selector: 'pre-invoice-fees',
  templateUrl: './pre-invoice-fees.component.html',
  styleUrls: ['./pre-invoice-fees.component.scss']
})
export class PreInvoiceFeesComponent implements OnInit {

  caseId : number;
  preInvoiceFees: any[] = [];
  supplierList: any[] = [];
  faPlus = faPlus;
  faTimes = faTimes;
  faShare = faShare;
  faPencil = faPencil;
  faExternalLink = faExternalLink;

  preInvoiceFeesNetTotal: number = 0;
  preInvoiceFeesVatTotal: number = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private preInvoiceFeesService: PreInvoiceFeesService,
              private klyantService: KlyantService,
              public dialog: MatDialog,
              private snackBar: MatSnackBar) {

    this.caseId = data.caseId;
    
    this.loadPreInvoiceFees();
   
  }

  ngOnInit(): void {
    this.klyantService.getSuppliers().subscribe((results) => {
      this.supplierList = results;
    });
  }

  getSupplierName(supplierId:number) {
    var supplier = this.supplierList.filter(x => x.id == supplierId);
    if(supplier && supplier.length == 1) {
      return supplier[0].name;
    }
    else {
      return null;
    }
  }

  loadPreInvoiceFees(){
    this.preInvoiceFeesService.getPreInvoiceFees(this.caseId).subscribe((response : any) => {
      this.preInvoiceFees = response.preInvoiceFees;
      this.preInvoiceFeesNetTotal = 0;
      this.preInvoiceFeesVatTotal = 0;
      this.preInvoiceFees.forEach((fee:any) => {
        this.preInvoiceFeesNetTotal += fee.netAmount;
        this.preInvoiceFeesVatTotal += fee.vatAmount;
      });
    });
  }

  addFee(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
        caseId: this.caseId
    };

    const dialogRef = this.dialog.open(AddPreInvoiceFeeComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.loadPreInvoiceFees();
    });

  }

  editInKlyant(outlayId) {
    window.open(environment.klyant.guiBaseUrl + '/outlays/' + outlayId + '/edit', '_blank');
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  sendToKlyant(fee:any) {    
    var preInvoiceFeeId = fee.preInvoiceFeeId;
    fee.sendingToKlyant = true;
    this.klyantService.resendPreInvoiceFeeToKlyant(preInvoiceFeeId).subscribe((response) => {
      if(!isNaN(response)) {
        fee.sendingToKlyant = false;
        this.openSnackBar("Outlay added to Klyant", 'Close');
        this.loadPreInvoiceFees();
      }
      else {
        fee.sendingToKlyant = false;
        this.openSnackBar("There was a problem adding the Outlay to Klyant: " + response, 'Close');
      }
    });
  }

  selectSupplierForFeeRow(preInvoiceFeeId:number) {
    const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        preInvoiceFeeId: preInvoiceFeeId
    };
    const dialogRef = this.dialog.open(SelectPreInvoiceFeeSupplierComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadPreInvoiceFees();
    });
  }

  removeFee(fee: any) {

    var popupText = '';
    if(fee.klyantOutlayId)
    {
      popupText = 'THIS ITEM HAS BEEN SENT TO KLYANT. YOU MUST VOID THE OUTLAY IN KLYANT MANUALLY.';
    }
    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Are you sure you want to remove this fee?', popupText1: popupText, popupText2: 'Any associated Invoice Items will also be deleted.'}
    });

    dialogRef.afterClosed().subscribe((result : any) => {

      if (result)
      {
        this.preInvoiceFeesService.removePreInvoiceFee( this.caseId, fee.preInvoiceFeeId ).subscribe((response : any) => {
          this.loadPreInvoiceFees();
        });
      }
    });

  }

}
