import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faChevronRight, faChevronLeft, faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import { CalendarService } from '../calendar-service.service';
import { CalendarEventComponent } from '../calendar-event/calendar-event.component';
import { MatDialog } from '@angular/material/dialog';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-calendar-day',
  templateUrl: './calendar-day.component.html',
  styleUrls: ['./calendar-day.component.scss']
})
export class CalendarDayComponent implements OnInit {

  currDay?: string;
  currDayName?: string;
  today = DateTime.local().toJSDate();

  faChevronRight= faChevronRight;
  faChevronLeft= faChevronLeft;
  faCalendarAlt = faCalendarAlt;

  @Input() isMobile: boolean = false;

  @Input() monthViewEnabled: boolean = false;
  @Output() monthViewEnabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  _selectedDay: Date = this.today;

  @Input() set selectedDay(date: Date) {
    console.log(date)
    this._selectedDay = date;
    this.currDayName = DateTime.fromJSDate(date).toFormat('cccc');
    this.loadData();
  };
  get selectedDay(): Date {
    return this._selectedDay;
  }
  @Output() selectedDayChange: EventEmitter<Date> = new EventEmitter<Date>();


  //appointments1 = [];

  appointments: any[] = [];

  constructor(private calendarService: CalendarService, public dialog: MatDialog) {
  }

  loadData() {
    let date = DateTime.fromJSDate(this.selectedDay);
    this.calendarService.getDayEvents(date.day, date.month, date.year).subscribe(result => {
      this.appointments = result;
    });
  }

  ngOnInit() {
  }

  /*getAppointments() {

    let date = moment(this.selectedDay).format('YYYY-MM-DD');

    this.appointments = this.appointments1.filter(x => x.appointmentDate == date).slice(0, 4);
    console.log(date, this.appointments)
  }*/

  nextDay() {
    this.selectedDay = DateTime.fromJSDate(this._selectedDay).plus({days: 1}).toJSDate();
  }

  prevDay() {
    this.selectedDay = DateTime.fromJSDate(this._selectedDay).plus({days: -1}).toJSDate();
  }

  isToday() {

    if (DateTime.fromJSDate(this.selectedDay).toISODate() == DateTime.fromJSDate(this.today).toISODate()) return true;
    return false;
  }

  monthView() {
    this.monthViewEnabledChange.next(true);
  }

  selectEvent(event: any) {

    const dialogRef = this.dialog.open(CalendarEventComponent, {
      width: '450px',
      data: {event: event}
    });
  }



}
