<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Authorised Signatory</h2>

<mat-dialog-content>

    <div class="formRowItem">
       <span class="label newFileNameLabel">User:</span><span class="itemText">
         <mat-form-field floatLabel="never">
         <mat-select (selectionChange)="validate()" [(ngModel)]="selectedUserId">
         <mat-option [value]="null"></mat-option>
          <mat-option *ngFor="let user of users" [value]="user.id">
          {{user.firstName}} {{user.lastName}}
        </mat-option>
      </mat-select>
        </mat-form-field></span>
    </div>

</mat-dialog-content>

<div mat-dialog-actions align="end">
    <button *ngIf="!loading" mat-stroked-button cdkFocusInitial (click)="updateAuthorisedSignatory()" color="primary" [disabled]="updateDisabled">Update</button>
    <button *ngIf="loading" mat-icon-button disabled color="primary" class="right" >
        <mat-icon>
            <mat-spinner color="primary" diameter="20">
            </mat-spinner>
        </mat-icon>
    </button>
  </div>
