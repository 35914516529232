<div class="case-list-actions">
    <app-searchbar searchType="Cases" [caseStatusType]="4"  [inline]="true"></app-searchbar>
</div>

<table mat-table [dataSource]="data" matSort matSortActive="cancelledDate" matSortDisableClear matSortDirection="desc">

    <ng-container matColumnDef="allocatedToParalegalUser" >

      <mat-header-cell *matHeaderCellDef>
        <app-autocomplete-filter #filter title="Filter Paralegal." filterColumn="allocatedToParalegalUser.id" [options]="paralegals"
          nameField="name" valueField="id" valueType="int">
          <span mat-sort-header="allocatedToParalegalUser.id">Paralegal.</span>
        </app-autocomplete-filter>
      </mat-header-cell>

      <mat-cell *matCellDef="let case"> <span *ngIf="case.allocatedToParalegalUser">{{case.allocatedToParalegalUser.firstName}} {{case.allocatedToParalegalUser.lastName}}</span> </mat-cell>
    </ng-container>

    <ng-container matColumnDef="financialAdviserName" >
      <mat-header-cell *matHeaderCellDef>
        <app-string-filter #filter title="Filter FA." filterColumn="addedByUser.fullName">
          <span mat-sort-header="addedByUser.fullName">FA.</span>
        </app-string-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let case"> <span *ngIf="case.addedByUser">{{case.addedByUser.fullName}} </span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="allocatedToAdminUser" >

      <mat-header-cell *matHeaderCellDef>
        <app-autocomplete-filter #filter title="Filter Admin." filterColumn="allocatedToAdminUser.id" [options]="admins"
          nameField="name" valueField="id" valueType="int">
          <span mat-sort-header="allocatedToAdminUser.id">Admin.</span>
        </app-autocomplete-filter>
      </mat-header-cell>

      <mat-cell *matCellDef="let case"> <span *ngIf="case.allocatedToAdminUser">{{case.allocatedToAdminUser.firstName}} {{case.allocatedToAdminUser.lastName}}</span> </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cancelledDate">

      <mat-header-cell *matHeaderCellDef>
        <app-date-filter #filter title="Filter Cancelled Date" filterColumn="cancelledDate">
          <span mat-sort-header>Cancelled Date.</span>
        </app-date-filter>
      </mat-header-cell>

      <mat-cell *matCellDef="let case"> {{case.cancelledDate  | date:'dd/MM/yyyy'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="caseType">
        <mat-header-cell *matHeaderCellDef>
          <span mat-sort-header="type.caseTypeName">Type</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let case"> {{case.type.caseTypeName}}
        </mat-cell>
      </ng-container>

    <ng-container matColumnDef="cancellationReason">
      <mat-header-cell *matHeaderCellDef>
        <span mat-sort-header>Reason</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let case">
        <span *ngIf="case.cancellationReason">
          <span *ngIf="case.cancellationReasonId != 1">{{case.cancellationReason.reason}}</span>
        <span *ngIf="case.cancellationReasonId == 1">{{case.cancellationReason.reason}} - {{case.cancellationReasonOther}}</span>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="partyOneFirstName">
      <mat-header-cell *matHeaderCellDef>
        <app-string-filter #filter title="Filter First Name." filterColumn="partyOneFirstName">
          <span mat-sort-header>First Name.</span>
        </app-string-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let case"> {{case.partyOneFirstName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="partyOneLastName">
      <mat-header-cell *matHeaderCellDef>
        <app-string-filter #filter title="Filter Last Name." filterColumn="partyOneLastName">
          <span mat-sort-header>Last Name.</span>
        </app-string-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let case"> {{case.partyOneLastName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="partyOneAddressLine1">
      <mat-header-cell *matHeaderCellDef>
        <app-string-filter #filter title="Address Line 1." filterColumn="partyOneAddressLine1">
          <span mat-sort-header>Address Line 1.</span>
        </app-string-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let case"> {{case.partyOneAddressLine1}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="partyOneAddressLine2">
      <mat-header-cell *matHeaderCellDef>
        <app-string-filter #filter title="Filter Address Line 2." filterColumn="partyOneAddressLine2">
          <span mat-sort-header>Address Line 2.</span>
        </app-string-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let case"> {{case.partyOneAddressLine2}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="partyOneAddressLine3">
      <mat-header-cell *matHeaderCellDef>
        <app-string-filter #filter title="Filter Address Line 3." filterColumn="partyOneAddressLine3">
          <span mat-sort-header>Address Line 3.</span>
        </app-string-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let case"> {{case.partyOneAddressLine3}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="partyOnePostCode">
      <mat-header-cell *matHeaderCellDef>
        <app-string-filter #filter title="Filter Post Code." filterColumn="partyOnePostCode">
          <span mat-sort-header>Post Code.</span>
        </app-string-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let case"> {{case.partyOnePostcode}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="caseReference">
      <mat-header-cell *matHeaderCellDef>
        <app-string-filter #filter title="Case Ref." filterColumn="caseReference">
          <span mat-sort-header>Case Ref.</span>
        </app-string-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let case"> {{case.caseReference}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="companyGroup">
        <mat-header-cell *matHeaderCellDef>
          <app-string-filter #filter title="Company Group." filterColumn="addedByUser.companyGroup.name">
            <span mat-sort-header="addedByUser.companyGroup.name">Company.</span>
          </app-string-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let case"> {{case.addedByUser.companyGroup.name}} </mat-cell>
      </ng-container>



    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let case; columns: displayedColumns;" [routerLink]="['case', case.id]" class="clickable-list-item"></mat-row>

  </table>
  <mat-spinner color="primary" diameter="20" *ngIf="isLoadingResults"></mat-spinner>
  <mat-paginator [length]="resultsLength" [pageSize]="25" [pageSizeOptions]="[10, 25, 50]" [showFirstLastButtons]="true">
  </mat-paginator>
