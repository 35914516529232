import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { NotificationsService } from '../shared/services/notifications.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-notification-history',
  templateUrl: './notification-history.component.html',
  styleUrls: ['./notification-history.component.scss']
})
export class NotificationHistoryComponent implements OnInit {

  private notificationId: number;
  notificationHistory: any;

  displayedColumns: string[] = ['notificationHistoryTypeName', 'byUser', 'dateOccured', 'fromUser', 'toUser'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NotificationHistoryComponent>,
    private notificationsService: NotificationsService,
    public dialog: MatDialog) {
      this.notificationId = this.data['notificationId'];
    }

    ngOnInit(): void {
      this.loadHistory();
    }

    loadHistory(){

      this.notificationsService.notificationHistory(this.notificationId).subscribe(result => {

        this.notificationHistory = result.notificationHistory;

      }, error => {

        var errorMessage = "Failed to get notification history";
        if (error.error !== null && error.error.message !== undefined) {
          errorMessage = error.error.message;
        }

      });
    }

    formatDateWithTime(inDate : any){

      var formattedDate = '';

       if(inDate !== null)
       {
         var dt = DateTime.fromISO(inDate);
         formattedDate = dt.toFormat("dd-MM-yyyy HH:mm:ss");
          if(formattedDate == 'Invalid date')
         formattedDate = '';
       }
       return formattedDate;
   }

    cancel(){
      this.dialogRef.close(null);
    }

}
