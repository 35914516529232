<button mat-icon-button class="close" tabindex="-1" (click)="close()" >x</button>
<button mat-icon-button class="audit" tabindex="-1" (click)="openAuditForForm()"><fa-icon [icon]="faHistory"></fa-icon></button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Property Details</h2>

<mat-dialog-content class="mat-typography">

    <!--mat-spinner color="primary" diameter="20" *ngIf="!dataLoaded"></mat-spinner-->

  <form [formGroup]="propertyDetails" autocomplete="off">

<app-card-label label="Purchase Postcode">
  <mat-form-field floatLabel="never">
    <input matInput #purchasePostcode maxlength="10" formControlName="purchasePostcode"
      (change)="updateField()">
    <app-address-lookup-button matSuffix [for]="addressLookup1"></app-address-lookup-button>
  </mat-form-field>
</app-card-label>

<app-address-lookup #addressLookup1 lookupType="postcode" [searchField]="purchasePostcode" [add1]="propertyDetails.controls['purchaseAddressLine1']"
  [add2]="propertyDetails.controls['purchaseAddressLine2']" [add3]="propertyDetails.controls['purchaseAddressLine3']" [add4]="propertyDetails.controls['purchaseAddressLine4']"
  [add5]="propertyDetails.controls['purchaseAddressLine5']" [addPc]="propertyDetails.controls['purchasePostcode']" (change)="updateField();">
</app-address-lookup>

<app-card-label label="Purchase Address Line 1">
  <mat-form-field floatLabel="never">
    <input matInput #purchaseAddressLine1  maxlength="100"
      formControlName="purchaseAddressLine1" (change)="updateField()">
  </mat-form-field>
</app-card-label>

<app-card-label label="Purchase Address Line 2">
  <mat-form-field floatLabel="never">
    <input matInput #purchaseAddressLine2 maxlength="100"
      formControlName="purchaseAddressLine2" (change)="updateField()">
  </mat-form-field>
</app-card-label>

<app-card-label label="Purchase Address Line 3">
  <mat-form-field floatLabel="never">
    <input matInput #purchaseAddressLine3 maxlength="100"
      formControlName="purchaseAddressLine3" (change)="updateField()">
  </mat-form-field>
</app-card-label>

<app-card-label label="Purchase Address Line 4">
  <mat-form-field floatLabel="never">
    <input matInput #purchaseAddressLine4 maxlength="100"
      formControlName="purchaseAddressLine4" (change)="updateField()">
  </mat-form-field>
</app-card-label>

<app-card-label label="Purchase Address Line 5">
  <mat-form-field floatLabel="never">
    <input matInput #purchaseAddressLine5 maxlength="100"
      formControlName="purchaseAddressLine5" (change)="updateField()">
  </mat-form-field>
</app-card-label>

<app-card-label label="Is property Unregistered?">
  <app-yes-no formControlName="propertyIsUnregistered" (change)="updateField()" ></app-yes-no>
</app-card-label>

<app-card-label label="Title Number">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="titleNumber" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Property Type">
    <mat-form-field floatLabel="never">
        <mat-select placeholder="Select" formControlName="propertyType" (selectionChange)="updateField()">
                <mat-option [value]="1">Leasehold</mat-option>
                <mat-option [value]="2">Freehold</mat-option>
        </mat-select>
    </mat-form-field>
</app-card-label>

<app-card-label label="Title Guarantee Type">
  <mat-form-field floatLabel="never">
      <mat-select placeholder="Select" formControlName="titleGuaranteeType" (selectionChange)="updateField()">
              <mat-option [value]="1">Full Title Guarantee</mat-option>
              <mat-option [value]="2">Limited Title Guarantee</mat-option>
      </mat-select>
  </mat-form-field>
</app-card-label>


<span [hidden]="propertyDetails.controls['propertyType'].value != 1">

  <app-card-label label="Landlord Full Name">
    <mat-form-field floatLabel="never">
      <input matInput #landlordName maxlength="100"
        formControlName="landlordName" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Landlord Postcode">
    <mat-form-field floatLabel="never">
      <input matInput #landlordPostcode maxlength="10" formControlName="landlordPostcode"
        (change)="updateField()">
      <app-address-lookup-button matSuffix [for]="addressLookup4"></app-address-lookup-button>
    </mat-form-field>
  </app-card-label>

  <app-address-lookup #addressLookup4 lookupType="postcode" [searchField]="landlordPostcode" [add1]="propertyDetails.controls['landlordAddressLine1']"
    [add2]="propertyDetails.controls['landlordAddressLine2']" [add3]="propertyDetails.controls['landlordAddressLine3']" [add4]="propertyDetails.controls['landlordAddressLine4']"
    [add5]="propertyDetails.controls['landlordAddressLine5']" [addPc]="propertyDetails.controls['landlordPostcode']" (change)="updateField();">
  </app-address-lookup>

  <app-card-label label="Landlord Address Line 1">
    <mat-form-field floatLabel="never">
      <input matInput #landlordAddressLine1 maxlength="100"
        formControlName="landlordAddressLine1" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Landlord Address Line 2">
    <mat-form-field floatLabel="never">
      <input matInput #landlordAddressLine2 maxlength="100"
        formControlName="landlordAddressLine2" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Landlord Address Line 3">
    <mat-form-field floatLabel="never">
      <input matInput #landlordAddressLine3 maxlength="100"
        formControlName="landlordAddressLine3" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Landlord Address Line 4">
    <mat-form-field floatLabel="never">
      <input matInput #landlordAddressLine4 maxlength="100"
        formControlName="landlordAddressLine4" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Landlord Address Line 5">
    <mat-form-field floatLabel="never">
      <input matInput #landlordAddressLine5 maxlength="100"
        formControlName="landlordAddressLine5" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Name">
    <mat-form-field floatLabel="never">
      <input matInput formControlName="managementCompanyName" (change)="updateField()" >
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Postcode">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyPostcode maxlength="10" formControlName="managementCompanyPostcode"
        (change)="updateField()">
      <app-address-lookup-button matSuffix [for]="addressLookup5"></app-address-lookup-button>
    </mat-form-field>
  </app-card-label>

  <app-address-lookup #addressLookup5 lookupType="postcode" [searchField]="managementCompanyPostcode" [add1]="propertyDetails.controls['managementCompanyAddressLine1']"
    [add2]="propertyDetails.controls['managementCompanyAddressLine2']" [add3]="propertyDetails.controls['managementCompanyAddressLine3']" [add4]="propertyDetails.controls['managementCompanyAddressLine4']"
    [add5]="propertyDetails.controls['managementCompanyAddressLine5']" [addPc]="propertyDetails.controls['managementCompanyPostcode']" (change)="updateField();">
  </app-address-lookup>

  <app-card-label label="Management Company Address Line 1">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyAddressLine1 maxlength="100"
        formControlName="managementCompanyAddressLine1" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Address Line 2">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyAddressLine2 maxlength="100"
        formControlName="managementCompanyAddressLine2" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Address Line 3">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyAddressLine3 maxlength="100"
        formControlName="managementCompanyAddressLine3" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Address Line 4">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyAddressLine4  maxlength="100"
        formControlName="managementCompanyAddressLine4" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Address Line 5">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyAddressLine5 maxlength="100"
        formControlName="managementCompanyAddressLine5" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

</span>

<app-card-label label="Solar Panels">
  <app-yes-no formControlName="solarPanelsPresent" (change)="updateField()" ></app-yes-no>
</app-card-label>

<span [hidden]="propertyDetails.controls['solarPanelsPresent'].value != 1">

    <app-card-label label="Are the solar panels owned outright?">
        <app-yes-no formControlName="solarPanelsOwnedOutright" (change)="updateField()" ></app-yes-no>
      </app-card-label>

      <span [hidden]="propertyDetails.controls['solarPanelsOwnedOutright'].value != 0">

          <app-card-label label="Solar Panels Company Name">
              <mat-form-field floatLabel="never">
                <input matInput formControlName="solarPanelsCompany" (change)="updateField()" >
              </mat-form-field>
            </app-card-label>


            <app-card-label label="Solar Panel Owner Postcode">
                <mat-form-field floatLabel="never">
                  <input matInput #solarPanelsOwnerPostcode maxlength="10" formControlName="solarPanelsOwnerPostcode"
                    (change)="updateField()">
                  <app-address-lookup-button matSuffix [for]="addressLookup2"></app-address-lookup-button>
                </mat-form-field>
              </app-card-label>

          <app-address-lookup #addressLookup2 lookupType="postcode" [searchField]="solarPanelsOwnerPostcode" [add1]="propertyDetails.controls['solarPanelsOwnerAddressLine1']"
          [add2]="propertyDetails.controls['solarPanelsOwnerAddressLine2']" [add3]="propertyDetails.controls['solarPanelsOwnerAddressLine3']" [add4]="propertyDetails.controls['solarPanelsOwnerAddressLine4']"
          [add5]="propertyDetails.controls['solarPanelsOwnerAddressLine5']" [addPc]="propertyDetails.controls['solarPanelsOwnerPostcode']" (change)="updateField();">
        </app-address-lookup>

        <app-card-label label="Solar Panel Owner Address Line 1">
          <mat-form-field floatLabel="never">
            <input matInput #solarPanelsOwnerAddressLine1 maxlength="100"
              formControlName="solarPanelsOwnerAddressLine1" (change)="updateField()">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Solar Panel Owner Address Line 2">
          <mat-form-field floatLabel="never">
            <input matInput #solarPanelsOwnerAddressLine2 maxlength="100"
              formControlName="solarPanelsOwnerAddressLine2" (change)="updateField()">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Solar Panel Owner Address Line 3">
          <mat-form-field floatLabel="never">
            <input matInput #solarPanelsOwnerAddressLine3 maxlength="100"
              formControlName="solarPanelsOwnerAddressLine3" (change)="updateField()">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Solar Panel Owner Address Line 4">
          <mat-form-field floatLabel="never">
            <input matInput #solarPanelsOwnerAddressLine4  maxlength="100"
              formControlName="solarPanelsOwnerAddressLine4" (change)="updateField()">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Solar Panel Owner Address Line 5">
          <mat-form-field floatLabel="never">
            <input matInput #solarPanelsOwnerAddressLine5 maxlength="100"
              formControlName="solarPanelsOwnerAddressLine5" (change)="updateField()">
          </mat-form-field>
        </app-card-label>
      </span>
    </span>

<app-card-label label="Septic Tank">
  <app-yes-no formControlName="hasSepticTank" (change)="updateField()" ></app-yes-no>
</app-card-label>

<app-card-label label="Agreed Purchase Price">
  <mat-form-field floatLabel="never">
      <span matPrefix>£ &nbsp;</span>
    <input matInput formControlName="agreedPurchasePrice" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Chattels">
  <mat-form-field floatLabel="never">
    <span matPrefix>£ &nbsp;</span>
    <input matInput formControlName="chattels" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-allowance></app-allowance>

<app-card-label label="Stamp Duty/Land Tax">
  <mat-form-field floatLabel="never">
    <span matPrefix>£ &nbsp;</span>
    <input matInput formControlName="stampDuty" (change)="updateField()" >
  </mat-form-field>
  <a href="https://www.gov.uk/stamp-duty-land-tax/residential-property-rates" target="_blank"><fa-icon [icon]="faExternalLink"></fa-icon></a>
</app-card-label>

<app-card-label label="Seller Solicitor Name">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="sellerSolicitorName" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Seller Solicitor Email Address">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="sellerSolicitorEmailAddress" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Seller Solicitor Contact Number">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="sellerSolicitorContactNumber" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Seller Solicitor Postcode">
    <mat-form-field floatLabel="never">
      <input matInput #sellerSolicitorPostcode maxlength="10" formControlName="sellerSolicitorPostcode"
        (change)="updateField()">
      <app-address-lookup-button matSuffix [for]="addressLookup6"></app-address-lookup-button>
    </mat-form-field>
  </app-card-label>

  <app-address-lookup #addressLookup6 lookupType="postcode" [searchField]="sellerSolicitorPostcode" [add1]="propertyDetails.controls['sellerSolicitorAddressLine1']"
    [add2]="propertyDetails.controls['sellerSolicitorAddressLine2']" [add3]="propertyDetails.controls['sellerSolicitorAddressLine3']" [add4]="propertyDetails.controls['sellerSolicitorAddressLine4']"
    [add5]="propertyDetails.controls['sellerSolicitorAddressLine5']" [addPc]="propertyDetails.controls['sellerSolicitorPostcode']" (change)="updateField();">
  </app-address-lookup>

  <app-card-label label="Seller Solicitor Address Line 1">
    <mat-form-field floatLabel="never">
      <input matInput #sellerSolicitorAddressLine1 maxlength="100"
        formControlName="sellerSolicitorAddressLine1" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Seller Solicitor Address Line 2">
    <mat-form-field floatLabel="never">
      <input matInput #sellerSolicitorAddressLine2 maxlength="100"
        formControlName="sellerSolicitorAddressLine2" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Seller Solicitor Address Line 3">
    <mat-form-field floatLabel="never">
      <input matInput #sellerSolicitorAddressLine3 maxlength="100"
        formControlName="sellerSolicitorAddressLine3" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Seller Solicitor Address Line 4">
    <mat-form-field floatLabel="never">
      <input matInput #sellerSolicitorAddressLine4 maxlength="100"
        formControlName="sellerSolicitorAddressLine4" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Seller Solicitor Address Line 5">
    <mat-form-field floatLabel="never">
      <input matInput #sellerSolicitorAddressLine5 maxlength="100"
        formControlName="sellerSolicitorAddressLine5" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Seller Solicitor Reference">
    <mat-form-field floatLabel="never">
      <input matInput formControlName="sellerSolicitorReference" (change)="updateField()" >
    </mat-form-field>
  </app-card-label>

<app-card-label label="Seller 1 Name">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="seller1Name" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Seller 2 Name">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="seller2Name" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

</form>
</mat-dialog-content>


