import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BaseFilterComponent } from '../base-filter.component';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.css']
})
export class DateFilterComponent extends BaseFilterComponent {

  componentTypeName = "DateFilterComponent";

  minDate: Date = new Date();
  maxDate: Date = new Date();

  filterForm = new FormGroup({
    from: new FormControl(),
    to: new FormControl(),
  });

  onFilterSubmit() {
    const from = this.filterForm.controls['from'].value;
    const to = this.filterForm.controls['to'].value;

    if (from && to) {
      this.filterValue =  `between ${from.toFormat('yyyy-MM-dd')}T00:00:00 and ${to.endOf('day').toFormat('yyyy-MM-dd')}T23:59:59`;
      this.filterName = `${from.toFormat('dd/MM/yy')} - ${to.endOf('day').toFormat('dd/MM/yy')}`;
    }
    else if (from) {
      this.filterValue =  `between ${from.toFormat('yyyy-MM-dd')}T00:00:00 and ${from.toFormat('9999-MM-dd')}T00:00:00`;
      this.filterName = `>= ${from.toFormat('dd/MM/yy')}`;
    }
    else if (to) {
      this.filterValue =  `between ${to.toFormat('0000-MM-dd')}T00:00:00 and ${to.endOf('day').toFormat('yyyy-MM-dd')}T23:59:59`;
      this.filterName = `<= ${to.toFormat('dd/MM/yy')}`
    } else {
      this.filterValue = '';
      this.filterName = '';
    }

    this.filter.next(true);
    this.menu.closeMenu();
  }

  addToEvent(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.maxDate = event.value;
    }
    else {
      this.maxDate = new Date();
    }
  }

  addFromEvent(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.minDate = event.value;
    }
    else {
      this.minDate = new Date();
    }
  }


  clear() {
    this.filterForm.reset();
    this.filterValue = '';
    this.filterName = '';
    this.filter.next(true);

    this.menu.closeMenu();
  }
}
