import { Component, AfterViewInit, Input, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CaseService } from '../shared/services/case.service';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../shared/services/user.service';
import { BaseFilterComponent } from '../shared/components/table-filters/base-filter.component';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { SearchRequest } from '../shared/services/interfaces/search-request';
import { DateTime } from 'luxon';
import { MatDialog } from '@angular/material/dialog';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { AllocateComponent } from './allocate/allocate.component';

@Component({
  selector: 'app-unallocated-case-list',
  templateUrl: './unallocated-case-list.component.html',
  styleUrls: ['./unallocated-case-list.component.scss']
})
export class UnallocatedCaseListComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype);
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChildren('filter') filters!: QueryList<BaseFilterComponent>;

  resultsLength: number = 0;
  displayedColumns: string[] = ['financialAdviserName', 'companyGroup', 'instructedDate', 'caseType', 'caseSeverity', 'partyOneFirstName', 'partyOneLastName', 'partyOneAddressLine1', 'partyOnePostCode', 'caseReference'];
  isLoadingResults = true;
  data: any[] = [];
  isMobile = false;
  faUserPlus = faUserPlus;

  constructor(private caseService: CaseService,
              public dialog: MatDialog,
              public authService: AuthService,
              usersService: UserService) {

      if (authService.canAllocateCases()) {
        this.displayedColumns = ['financialAdviserName', 'companyGroup', 'instructedDate', 'caseType', 'caseSeverity', 'partyOneFirstName', 'partyOneLastName', 'partyOneAddressLine1',  'partyOnePostCode', 'caseReference', 'actions'];
      }
  }

  ngAfterViewInit() {
    this.loadCaseData();
  }

  loadCaseData() {

    const outputs = this.filters.map(button => button.filter);

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(...outputs).subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page, ...outputs)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          let searchRequest: SearchRequest = {
            page: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize,
            orderBy: this.sort.active,
            orderDirection: this.sort.direction,
            filterColumns: this.filters.map(button => button.filterColumn),
            filterStrings: this.filters.map(button => button.filterValue)
          };

          return this.caseService.unallocatedCases(searchRequest);

        }),
        map(data => {

          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.recordCount;
          return data.items;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  allocateUser(caseId:  number) {


    var dialogRef = this.dialog.open(AllocateComponent, {
      data: {
        caseId: caseId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true)
        this.loadCaseData();
    });

  }



}
