<app-card-label [label]="label" >
  <mat-form-field floatLabel="never">
      <input *ngIf="keyDate && !includeTime" matInput [ngModel]="keyDate | date: 'dd/MM/yyyy'" (ngModelChange)="change($event)" disabled>
      <input *ngIf="keyDate && includeTime" matInput [ngModel]="keyDate | date: 'dd/MM/yyyy HH:mm'" (ngModelChange)="change($event)" disabled>
      <input *ngIf="!keyDate" matInput [ngModel]="keyDate " disabled>
      <span *ngIf="keyDate && enteredByUser" matSuffix> ({{enteredByUser.firstName.charAt(0)}}{{enteredByUser.lastName.charAt(0)}})</span>
  </mat-form-field>
  <!--fa-icon [icon]="faPlus" size="lg" (click)="addDate()" class="addEdit"></fa-icon-->
  <fa-icon *ngIf="canAdd && !keyDate && !readOnly" [icon]="faPlus" size="lg" (click)="addDate()" class="addEdit"></fa-icon>

  <!--fa-icon [icon]="faMinusCircle" size="lg" (click)="deleteDate()" class="delete"></fa-icon-->
  <fa-icon *ngIf="canEdit && keyDate && !readOnly" [icon]="faMinusCircle" size="lg" (click)="deleteDate()" class="delete"></fa-icon>
</app-card-label>
