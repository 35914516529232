import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from "rxjs"
import { environment } from 'src/environments/environment';
import { KlyantUpdate } from '../services/interfaces/klyant-update'
import { DateTime } from 'luxon';
import { mergeMap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class KlyantService {

  private klyantUrl = environment.apiUrl + 'Klyant/';

  constructor(private http: HttpClient, public router: Router) {    
  }

  public getAuthToken() {

    const tokenObservable = new Observable((observer:any) => {

      var klyant = localStorage.getItem('klyant');

      if (klyant == null || DateTime.fromISO(JSON.parse(klyant).expires) < DateTime.local()) {
        // It doesn't exist or has expired, persist the current page + state and redirect to Klyant for login
        this.clearTokenData();
        // Open the login to Klyant, at which point it should bounce back to the callback method in the KlyantComponent
        this.http.get<any>(this.klyantUrl + "Auth").subscribe(response => {
            const authWin = window.open(response.url, "KlyantWindow", "height=600,width=800,modal=yes,alwaysRaised=yes");
            const timer = setInterval(() => {
              if (authWin && authWin.closed) {
                clearInterval(timer);
                klyant = localStorage.getItem('klyant');              
                if(klyant) {                
                  observer.next(JSON.parse(klyant).token);
                }
                else {
                  observer.next(null);
                }
              }
            }, 500);
        }); 
      }
      else {
        observer.next(JSON.parse(klyant).token);
      }
    });

    return tokenObservable;
  }

  setTokenAfterCallback(code:string) {

    const tokenObservable = new Observable((observer:any) => {
      let data = {
        code: code
      };
      this.http.post<any>(this.klyantUrl + "Token", data).subscribe((response) => {      
        this.setAuthToken(response.token, response.expires_in);
        observer.next(response.token);
      });    
    });

    return tokenObservable;
  }

  setAuthToken(token:string, expiresIn?:number) {
    if(expiresIn == null) {
      expiresIn = (60000 * 30); // As of writing, Klyant don't expire tokens, so we will
    }
    localStorage.setItem('klyant', JSON.stringify({
      token: token,
      expires: DateTime.local().plus({milliseconds: expiresIn}).toJSDate()
    }));
  }

  clearTokenData() {
    localStorage.removeItem('klyant');
  }

  klyantEnabled() {    
    return this.http.get<any>(this.klyantUrl + "Enabled");
  }

  getKlyantDetails(caseId:number) {
    return this.http.get<any>(this.klyantUrl + "Details/" + caseId);
  }

  getClients(): Observable<any> { 
    return this.getAuthToken()
      .pipe(
        mergeMap((token:any) => {
          return this.http.get<any>(this.klyantUrl + "Clients", {
            headers: {'X-Klyant-Token': token}
          });
        })
    ); 
  }

  addClientAndMatter(update: KlyantUpdate): Observable<any> {
    return this.getAuthToken()
      .pipe(
        mergeMap((token:any) => {
          return this.http.post<any>(this.klyantUrl + "Matters", update, {
            headers: {'X-Klyant-Token': token}
          });
      })
    ); 
  }

  updateClientAndMatter(update: KlyantUpdate): Observable<any> {
    return this.getAuthToken()
      .pipe(
        mergeMap((token:any) => {
          return this.http.put<any>(this.klyantUrl + "Matters", update, {
            headers: {'X-Klyant-Token': token}
        });
      })
    ); 
  }

  getSuppliers(): Observable<any> {
    return this.getAuthToken()
    .pipe(
      mergeMap((token:any) => {
          return this.http.get<any>(this.klyantUrl + "Suppliers", {
            headers: {'X-Klyant-Token': token}
          });
        })
    ); 
  }

  getOfficeAccounts(): Observable<any> {
    return this.getAuthToken()
    .pipe(
      mergeMap((token:any) => {
          return this.http.get<any>(this.klyantUrl + "Accounts/Office", {
            headers: {'X-Klyant-Token': token}
          });
        })
    ); 
  }

  getClientAccounts(): Observable<any> {
    return this.getAuthToken()
    .pipe(
      mergeMap((token:any) => {
          return this.http.get<any>(this.klyantUrl + "Accounts/Client", {
            headers: {'X-Klyant-Token': token}
          });
        })
    ); 
  }

  getIncomeNominalAccounts(): Observable<any> {
    return this.getAuthToken()
    .pipe(
      mergeMap((token:any) => {
          return this.http.get<any>(this.klyantUrl + "Accounts/Nominal/Income", {
            headers: {'X-Klyant-Token': token}
          });
        })
    ); 
  }


  addOutlay(caseId: number, outlay: any): Observable<any> {
    return this.getAuthToken()
      .pipe(
        mergeMap((token:any) => {
          return this.http.put<any>(this.klyantUrl + "Outlays/" + caseId, outlay, {
            headers: {'X-Klyant-Token': token}
          });
        })
      );      
  }

  resendPreInvoiceFeeToKlyant(preInvoiceFeeId:number): Observable<any> {
    return this.getAuthToken()
      .pipe(
        mergeMap((token:any) => {
          return this.http.post<any>(this.klyantUrl + "Outlays/Resend/" + preInvoiceFeeId, null, {
            headers: {'X-Klyant-Token': token}
          });
        })
      );   
  }

  sendInvoiceToKlyant(caseId: number, invoiceId:number): Observable<any> {
    return this.getAuthToken()
      .pipe(
        mergeMap((token:any) => {
          console.log('token', token);

          return this.http.post<any>(this.klyantUrl + caseId + "/invoice/" + invoiceId, null, {
            headers: {'X-Klyant-Token': token}
          });
        })
      );   
  }

  getDefaultTransferToAccountId(){
    return this.http.get<any>(this.klyantUrl + "Default/Transfer/ToAccountId" );
  }

  getChequeTransactionTypeId(){
    return this.http.get<any>(this.klyantUrl + "Default/ChequeTransactionTypeId" );
  }

  checkCaseDataPopulatedForKlyant(caseId: number | undefined){
    return this.http.get<any>(this.klyantUrl + "Check/CaseDataPopulated/" + caseId );
  }
}
