
<!-- <app-card cardTitle="Calendar" [open]="true" >
  <div card-body> -->

    <div>
      <div class="day-nav">
        <button mat-stroked-button (click)="prevDay()"><fa-icon [icon]="faChevronLeft"></fa-icon></button>
        <div class="current">{{selectedDay | date: 'dd EEEE MMMM'}}</div>
        <button mat-stroked-button (click)="nextDay()"><fa-icon [icon]="faChevronRight"></fa-icon></button>
        <button mat-stroked-button (click)="monthView()" *ngIf="!isMobile"><fa-icon [icon]="faCalendarAlt"></fa-icon></button>
      </div>

      <div class="day">
        <div class="day-header" [ngClass]="{'today': isToday()}">{{currDayName}}</div>

        <div class="day-body">
          <div class="event" (click)="selectEvent(event)" *ngFor="let event of appointments">
            {{event.consultantName}}
          </div>
        </div>

      </div>


    </div>


<!--
  </div>
</app-card> -->
