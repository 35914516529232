import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseService } from 'src/app/shared/services/case.service';
import { LookupsService } from 'src/app/shared/services/lookups.service';

@Component({
  selector: 'app-cancel-case',
  templateUrl: './cancel-case.component.html',
  styleUrls: ['./cancel-case.component.css']
})
export class CancelCaseComponent implements OnInit {

  private caseId: number;
  public reasons?: any[];

  public selectedReason?: any;
  public cancellationReasonOther?: any = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<CancelCaseComponent>,
              private caseService: CaseService,
              private lookupsService: LookupsService) {

    this.caseId = this.data['caseId'];

    this.lookupsService.getCancellationReasons().subscribe((result : any) => {
      this.reasons = result;
    });
  }

  ngOnInit(): void {
  }

  cancel(){
    this.dialogRef.close(null);
  }

  save(){

    var reasonOther = null;

    if(this.selectedReason == 1)
      reasonOther = this.cancellationReasonOther

      var cancelDetails = {cancellationReasonId: this.selectedReason, cancellationReasonOther: reasonOther };

      this.caseService.cancelCase(this.caseId, cancelDetails).subscribe(response => {

        this.dialogRef.close(true);
        this.caseService.refreshCase.next();

        }, error => {

          var errorMessage = "Failed to cancel case";
          if(error.error !== null && error.error.message !== undefined)
          {
            errorMessage = error.error.message;
          }

        });
  }

}
