<!--button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button-->
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Rename Item</h2>

<mat-dialog-content *ngIf="!hasError">
    <div>The following characters will be removed from the name: \ / : * ? " < > |</div>
</mat-dialog-content>

<mat-dialog-content *ngIf="hasError">
  <div class="error">{{errorMessage}}</div>
</mat-dialog-content>

<mat-dialog-content>

    <div class="formRowItem">
       <span class="label newFileNameLabel">New Name:</span><span class="itemText"><mat-form-field floatLabel="never">
          <input matInput value="" [(ngModel)]="newName" (change)="validateForm()" autocomplete="off">
        </mat-form-field></span>
    </div>

</mat-dialog-content>

<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button *ngIf="!loading" mat-stroked-button cdkFocusInitial (click)="renameItem()" color="primary" [disabled]="renameDisabled">OK</button>
    <button *ngIf="loading" mat-icon-button disabled color="primary" class="right" >
        <mat-icon>
            <mat-spinner color="primary" diameter="20">
            </mat-spinner>
        </mat-icon>
    </button>
  </div>
