import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SearchRequest } from './interfaces/search-request';
import { SearchResult } from './interfaces/search-result';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class LettersService {

  private url = environment.apiUrl + 'letters/';

  constructor(private http: HttpClient, public router: Router) {



  }

  editletter(caseId: number, caseFileId: number, filename: string) {
    return this.http.get(this.url + 'edit/' + caseId + '/' + caseFileId + '/' + filename, { observe: 'response', responseType: 'blob'});
  }

  getLettersForCaseType(caseTypeId: number){
    return this.http.get<any>(this.url  + 'casetypeletters/' + caseTypeId );
  }

  getAdminLettersForCaseType(caseTypeId: number){
    return this.http.get<any>(this.url  + 'admincasetypeletters/' + caseTypeId );
  }

  getMortgageLetters(){
    return this.http.get<any>(this.url  + 'mortgageletters/');
  }

  getAllOtherLetters(){
    return this.http.get<any>(this.url  + 'allother/');
  }

  generateLetters(caseId: number, letterTemplates: any[]){

    let formData = {
      caseId: caseId,
      letterTemplates: letterTemplates
    }

    return this.http.post<any>(this.url  + 'generate/', formData );
  }

  generateLettersForGroup(caseId: number, groupId: number){

    let formData = {
      caseId: caseId,
      groupId: groupId
    }

    return this.http.post<any>(this.url  + 'generategroup/', formData );
  }

  getLetterRecipientsForCase(caseId: number){
    return this.http.get<any>(this.url + caseId + '/recipients/');
  }

  generateAdHocLetterForRecipient(caseId: number, recipientTypeId: number){
    let formData = {
      caseId: caseId,
      recipientTypeId: recipientTypeId
    }

    return this.http.post<any>(this.url  + 'generateadhoc/', formData );
  }

  generatePreSubmissionChecklist(caseId: number){
    let formData = {
      caseId: caseId,
      template: 'PreSubmissionChecklist'
    }

    return this.http.post<any>(this.url  + 'PDF/FromTemplate/', formData );
  }

  generatePreExchangeChecklist(caseId: number){
    let formData = {
      caseId: caseId,
      template: 'PreExchangeChecklist'
    }

    return this.http.post<any>(this.url  + 'PDF/FromTemplate/', formData );
  }
}
