import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SMSService } from '../../../shared/services/sms.service';
import { CaseService } from '../../../shared/services/case.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CorrespondenceTimelineService } from '../../../shared/services/correspondence-timeline.service';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.css']
})
export class SmsComponent implements OnInit {

  caseId: number = -1;
  smsObj: any;
  sendingSMS: boolean = false;
  readOnly = false;
  optedIn: boolean = false;

  faExclamationCircle = faExclamationCircle;

  smsDetails = this.fb.group({
    phoneNumber: ['', Validators.required],
    message: ['', Validators.required],
  });

  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
              public dialogRef: MatDialogRef<SmsComponent>,
              private fb: FormBuilder,
              private smsService: SMSService,
              private correspondenceTimelineService: CorrespondenceTimelineService) {

      this.smsObj = componentData.smsObj;

      if(this.smsObj == null)
      {
        this.caseId = componentData.caseId;
        this.optedIn = componentData.optedIn;
      }
      else{
        this.readOnly = true;
        this.smsDetails.controls['phoneNumber'].patchValue(this.smsObj.to);
        this.smsDetails.controls['phoneNumber'].disable();
        this.smsDetails.controls['message'].patchValue(this.smsObj.message);
        this.smsDetails.controls['message'].disable();
      }
  }

  ngOnInit() {
  }

  sendSMS(){

    this.sendingSMS = true;

    this.smsService.sendSMS(this.caseId, this.smsDetails.value).subscribe((response : any) => {
      this.dialogRef.close(true);
      this.correspondenceTimelineService.refreshCaseTimeline.next();
    });

  }

}
