import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { Router } from '@angular/router';
import { BehaviorSubject, throwError } from 'rxjs';
import { filter, take, switchMap, finalize } from 'rxjs/operators';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {


  private token = "secrettoken";
  private refreshTokenInProgress = false;
  private refreshTokenSubject = new BehaviorSubject<boolean | null>(null);

  constructor(public auth: AuthService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = this.addAuthenticationToken(request);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          return this.handle401(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  handle401(request: HttpRequest<any>, next: HttpHandler) {
    // 401 errors are most likely going to be because we have an expired token that we need to refresh.
    if (this.refreshTokenInProgress) {
      // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
      // which means the new token is ready and we can retry the request again
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap(() => next.handle(this.addAuthenticationToken(request)))
      );
    } else {
      this.refreshTokenInProgress = true;

      // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
      this.refreshTokenSubject.next(null);

      return this.auth.refreshAccessToken2().pipe(
        switchMap((success: boolean) => {
          this.refreshTokenSubject.next(success);
          return next.handle(this.addAuthenticationToken(request));
        }),
        // When the call to refreshToken completes we reset the refreshTokenInProgress to false
        // for the next time the token needs to be refreshed
        finalize(() => this.refreshTokenInProgress = false)

      )
    }
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {

    if (!this.token) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`
      }
    });
  }
}
