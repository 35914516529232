import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faPlus, faCheck } from '@fortawesome/pro-light-svg-icons';
import { LendersService } from '../../shared/services/lenders.service';

@Component({
  selector: 'app-lenders-list',
  templateUrl: './lenders-list.component.html',
  styleUrls: ['./lenders-list.component.scss']
})
export class LendersListComponent implements OnInit {

  displayedColumns: string[] = ['name', 'isActive'];
  lenders: any[] = [];
  faPlus = faPlus;
  faCheck = faCheck;

  constructor(private lenderService: LendersService,
    public router: Router) {

      this.lenderService.getAll().subscribe( (response : any) =>{

        this.lenders = response;
      })
  }

  ngOnInit(): void {
  }

  editLender(id :number){
    this.router.navigateByUrl('/lenders/' + id + '/admin');
  }

  addLender(){
    this.router.navigateByUrl('/lenders/new');
  }

}
