<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Send to Case</h2>

<mat-dialog-content>

    <div class="formRowItem">
       <span class="label">Selected File:</span><span class="itemText">{{file.fileName}}</span>
    </div>

    <div class="formRowItem">
       <span class="label newFileNameLabel">New File Name:</span><span class="itemText"><mat-form-field floatLabel="never">
          <input matInput value="" [(ngModel)]="newFileName" (change)="validateForm()" autocomplete="off">
        </mat-form-field></span>
    </div>

    <!--app-card-label label="Document Name">
        <mat-form-field floatLabel="never">
          <input matInput value="" [(ngModel)]="newFileName">
        </mat-form-field>
      </app-card-label-->

    <form class="form inline" [formGroup]="form">
        <mat-form-field class="form-field" appearance="outline">
          <input type="text" placeholder="Search Cases" aria-label="Search" matInput formControlName="search"
            [matAutocomplete]="auto">

          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">

            <mat-option *ngIf="isSearching" class="is-loading">
              <mat-spinner diameter="20"></mat-spinner>
            </mat-option>
            <ng-container *ngIf="!isSearching">
              <mat-option *ngFor="let case of filteredItems" [value]="case" (click)="selectCase(case)">
                <span>{{ case.type.caseTypeName }} - {{ case.caseReference }} - {{ case.partyOneFirstName }} {{
                  case.partyOneLastName }} - {{case.partyOnePostcode}} - {{ case.caseStatusTypeText }}</span>

              </mat-option>
            </ng-container>
          </mat-autocomplete>
          </mat-form-field>
          </form>


    <div *ngIf="selectedCase" class="formRowItem">
    <span class="label">Selected Case:</span><span class="itemText">{{ selectedCase.type.caseTypeName }} - {{ selectedCase.caseReference }} - {{ selectedCase.partyOneFirstName }} {{
        selectedCase.partyOneLastName }} - {{selectedCase.partyOnePostcode}} - {{ selectedCase.caseStatusTypeText }}</span>

    </div>

  </mat-dialog-content>


  <div mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button *ngIf="!sendingToCase" mat-stroked-button cdkFocusInitial (click)="sendToCase()" color="primary" [disabled]="sendToCaseDisabled">Send to Case</button>
      <button *ngIf="sendingToCase" mat-icon-button disabled color="primary" class="right" >
          <mat-icon>
              <mat-spinner color="primary" diameter="20">
              </mat-spinner>
          </mat-icon>
      </button>
    </div>
