import { Component, AfterViewInit, Input, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { faPause, faPlay } from '@fortawesome/pro-light-svg-icons';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { CaseService } from '../shared/services/case.service';
import { AuthService } from '../auth/auth.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../shared/services/user.service';
import { BaseFilterComponent } from '../shared/components/table-filters/base-filter.component';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { SearchRequest } from '../shared/services/interfaces/search-request';
import { ReallocateCaseModalComponent } from './reallocate-case/reallocate-case.component';
import { ReallocateCaseTaskModalComponent } from './reallocate-case-task/reallocate-case-task.component';
import { OnHoldComponent } from '../on-hold/on-hold.component';
import { TaskService } from '../shared/services/task.service';

@Component({
  selector: 'app-case-list',
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.scss']
})
export class CaseListComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype);
  @ViewChild(MatSort) sort: MatSort = new MatSort();

  @ViewChildren('filter') filters!: QueryList<BaseFilterComponent>;

  public faPause = faPause;
  public faPlay = faPlay;

  selection = new SelectionModel<any>(true, []);
  resultsLength: number = 0;
  displayedColumns: string[] = [];
  isLoadingResults = true;
  data: any[] = [];
  paralegals: any[] = [];
  admins: any[] = [];

  tableName: string = "instructedCaseList";

  searchCriteria: SearchRequest = {page: 1, pageSize: 25, orderBy: "instructedDate", orderDirection: "asc", filterColumns: [], filterStrings: []};

  constructor(private caseService: CaseService,
    public dialog: MatDialog,
    public authService: AuthService,
    userService: UserService,
    private taskService: TaskService) {

      if(authService.isALStaff()){
        userService.paralegals().subscribe((results : any ) => {
          for (const user of results) {
            this.paralegals.push({ name: user.firstName + ' ' + user.lastName, id: user.id })
          }
        });

        userService.admins().subscribe((results : any ) => {
          for (const user of results) {
            this.admins.push({ name: user.firstName + ' ' + user.lastName, id: user.id })
          }
        });
      }

      if (authService.canReallocateCases()) {
        this.displayedColumns = ['select', 'allocatedToParalegalUser', 'allocatedToAdminUser', 'financialAdviserName', 'companyGroup', 'instructedDate', 'caseType', 'caseSeverity', 'partyOneFirstName', 'partyOneLastName', 'partyOneAddressLine1', 'partyOnePostCode', 'caseReference', 'lastWorkedOn', 'percentageComplete', 'actions'];
      }
      else if(authService.isALStaff())
      {
        this.displayedColumns = ['allocatedToParalegalUser', 'allocatedToAdminUser', 'financialAdviserName', 'companyGroup', 'instructedDate', 'caseType', 'caseSeverity', 'partyOneFirstName', 'partyOneLastName', 'partyOneAddressLine1', 'partyOnePostCode', 'caseReference', 'lastWorkedOn', 'percentageComplete', 'actions'];
      }
    }

  ngAfterViewInit(): void {
    this.loadCaseData();
  }

  loadCaseData() {

    let storedSearchCriteria = localStorage.getItem(this.tableName);
    let searchRequest = {page: 1, pageSize: 25, orderBy: this.sort.active, orderDirection: this.sort.direction, filterNames: [], filterColumns: [], filterStrings: []};

    if(storedSearchCriteria != null)
    {
      searchRequest = JSON.parse(storedSearchCriteria);

      this.filters.forEach((item : any) => {

        if(item.componentTypeName == "StringFilterComponent" || item.componentTypeName == "DateFilterComponent" || item.componentTypeName == "AutocompleteFilterComponent")
        {
          var columnIndex = searchRequest.filterColumns.findIndex(element => element == item.filterColumn);
          var filterName = searchRequest.filterNames[columnIndex];
          var storedValue = searchRequest.filterStrings[columnIndex];
          item.filterName = filterName;
          item.filterValue = storedValue;
        }

      });

      this.searchCriteria.filterStrings = searchRequest.filterStrings;
      this.sort.active = searchRequest.orderBy;
      this.sort.direction = searchRequest.orderDirection as SortDirection;
      this.sort.sortChange.emit();
    }

    const outputs = this.filters.map(button => button.filter);

    this.selection.clear();

    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.selection.clear();
    });

    merge(...outputs).subscribe(() => {
      this.paginator.pageIndex = 0;
      this.selection.clear();
    });

    merge(this.sort.sortChange, this.paginator.page, ...outputs)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;

        this.searchCriteria.page = this.paginator.pageIndex + 1;
        this.searchCriteria.pageSize = this.paginator.pageSize;
        this.searchCriteria.orderBy = this.sort.active;
        this.searchCriteria.orderDirection = this.sort.direction;

        this.searchCriteria.filterStrings = this.filters.map(button => button.filterValue);
        this.searchCriteria.filterColumns = this.filters.map(button => button.filterColumn);

        let saveSearch = {
          page: this.paginator.pageIndex + 1,
          pageSize: this.paginator.pageSize,
          orderBy: this.sort.active,
          orderDirection: this.sort.direction,
          filterNames: this.filters.map(button => button.filterName),
          filterColumns: this.filters.map(button => button.filterColumn),
          filterStrings: this.filters.map(button => button.filterValue)
        };

       localStorage.setItem(this.tableName, JSON.stringify(saveSearch));

        return this.caseService.cases(this.searchCriteria);

      }),
      map((data : any) => {

        // Flip flag to show that loading has finished.
        this.isLoadingResults = false;
        this.resultsLength = data.recordCount;
        return data.items;
      }),
      catchError(() => {
        this.isLoadingResults = false;
        return observableOf([]);
      })
    ).subscribe(data => this.data = data);


  }

  putCaseOnHold(id: number) {
    var dialogRef = this.dialog.open(OnHoldComponent, {
      data: {
        caseId: id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true){
        this.loadCaseData();
        this.taskService.refreshTasks.next();
      }
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {

    this.isAllSelected() ?
      this.selection.clear() :
      this.data.forEach(row => this.selection.select(row));

  }

  checkboxLabel(row?: any): string {

    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;

  }

  reAllocateCases() {

    var dialogRef = this.dialog.open(ReallocateCaseModalComponent, {
      data: {
        selection: this.selection.selected
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true)
        this.loadCaseData();
    });
  }

  reAllocateTasks() {

    var dialogRef = this.dialog.open(ReallocateCaseTaskModalComponent, {
      data: {
        selection: this.selection.selected
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true)
        this.loadCaseData();
    });

  }

  export(){

  }

}
