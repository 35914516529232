import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AllowancesService } from 'src/app/shared/services/allowances.service';
import { AddAllowanceComponent } from './add-allowance/add-allowance.component';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-allowance',
  templateUrl: './allowance.component.html',
  styleUrls: ['./allowance.component.css']
})
export class AllowanceComponent implements OnInit {

  public caseId: number;
  public allowances: any[];

  faTimes = faTimes;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private allowancesService: AllowancesService, public dialog: MatDialog,) { 
    this.caseId = data.caseId;
    this.allowances = [];
  }

  ngOnInit(): void {
    this.getAllowances();
  }

  openAddAllowance() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
      dialogConfig.data = {
      caseId: this.caseId
    };

    const dialogRef = this.dialog.open(AddAllowanceComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.getAllowances();
    });
  }

  getAllowances() {
    this.allowancesService.getAllowances(this.caseId).subscribe((response:any) => {
      this.allowances = response;
    });
  }

  deleteAllowance(allowanceId: number) {
    this.allowancesService.deleteAllowance(this.caseId, allowanceId).subscribe(() => {
      this.getAllowances();
    });
  }


}
