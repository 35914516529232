
<div class="body">

  <div class="sidebar">
    <app-dashboard-sidebar></app-dashboard-sidebar>
  </div>

  <div class="content">
  <router-outlet></router-outlet>

  </div>

</div>
