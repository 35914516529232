import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { KeyDatesService } from 'src/app/shared/services/keydates.service';
import { faPlus, faPen, faHistory } from '@fortawesome/free-solid-svg-icons';
import { MatDialog, MatDialogConfig,MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddKeyDateComponent } from './add-key-date/add-key-date.component';
import { CancelCaseComponent } from './cancel-case/cancel-case.component';
import { AuthService } from 'src/app/auth/auth.service';
import { DeleteKeyDateComponent } from './delete-key-date/delete-key-date.component';
import { SelectConsultantComponent } from '../../../calendar/select-consultant/select-consultant.component';
import { CaseService } from 'src/app/shared/services/case.service';
import { BasicPopupOkComponent } from '../../../shared/components/basic-popup-ok/basic-popup-ok.component';
import { BasicPopupYesNoComponent } from '../../../shared/components/basic-popup-yes-no/basic-popup-yes-no.component';
import { KeyDatesHistoryComponent } from './key-dates-history/key-dates-history.component';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-key-dates',
  templateUrl: './key-dates.component.html',
  styleUrls: ['./key-dates.component.scss']
})
export class KeyDatesComponent implements OnInit {

  caseId: number;
  readOnly: boolean = true;
  caseTypeId?: number;
  keyDateTypes: any[] = [];
  caseKeyDates: any[] = [];
  viewInit = false;

  faPlus = faPlus;
  faPen = faPen;
  faHistory = faHistory;
  isMobile = false;

  keyDatesLoaded = false;

  refreshSubscription: Subscription;

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
               public dialog: MatDialog,
               private keyDatesService: KeyDatesService,
               public authService: AuthService,
               public dialogRef: MatDialogRef<KeyDatesComponent>,
               private caseService: CaseService
              ) {
                this.caseId = data.caseId;
                this.readOnly = data.readOnly;
                this.caseTypeId = data.caseTypeId;

      this.refreshSubscription = this.keyDatesService.refreshKeyDatesObservable.subscribe(() => {
        this.loadKeyDates();
      });
  }


  ngOnInit() {

  }

  ngAfterContentInit() {
    this.viewInit = true;
    this.loadKeyDates();
  }

  loadKeyDates() {
    if (!this.viewInit) return;

    this.keyDatesService.getKeyDatesForCase(this.caseId).subscribe((response: any) => {

      var keyDatesForCase = response.keyDates;

      this.keyDatesService.getKeyDateTypes(this.caseId).subscribe((result: any) => {

        this.keyDateTypes = result.keyDatesTypes;

        type KeyDateObjType = Array<{ keyDateTypeId: number, keyDateName: string, canEdit: boolean, keyDateId: number, keyDateValue: string, enteredByUser: any, includeTime: boolean }>;
        var keyDatesArray: KeyDateObjType = [];

        this.keyDateTypes.forEach(keyDateType => {

          var keyDateObj: any;

          var matchFound = false;

          keyDatesForCase.forEach((keyDate: any)  => {

            if (keyDate.keyDateType == keyDateType.keyDateTypeId) {
              matchFound = true;
              keyDateObj = { keyDateTypeId: keyDateType.keyDateTypeId, keyDateName: keyDateType.keyDateType.name, canEdit: keyDateType.keyDateType.canEdit, keyDateId: keyDate.id, keyDateValue: keyDate.value, enteredByUser: keyDate.enteredByUser, includeTime: keyDateType.keyDateType.includeTime };
            }

          });

          if (matchFound)
            keyDatesArray.push(keyDateObj);
          else
            keyDatesArray.push({ keyDateTypeId: keyDateType.keyDateTypeId, keyDateName: keyDateType.keyDateType.name, canEdit: keyDateType.keyDateType.canEdit, keyDateId: -1, keyDateValue: '', enteredByUser: null, includeTime: keyDateType.keyDateType.includeTime });

        });

        this.caseKeyDates = keyDatesArray;

        this.keyDatesLoaded = true;


      });

    });
  }

  ctpCheck(event:any) {
    var ctpCheck = this.dialog.open(BasicPopupOkComponent, {
      data: { messageHeader: 'Mortgage Details', popupText1: 'Please check the mortgage details have been updated on the case', popupText2: ''}
    });

    ctpCheck.afterClosed().subscribe(result => {
    });

  }

  addKeyDate(event:any) {

    if(this.canAddKeyDate(event))
      this.showKeyDateAddPopup(event);
  }

  showKeyDateAddPopup(event:any){

    var dialogRef = this.dialog.open(AddKeyDateComponent, {
      data: { caseId: this.caseId, keyDateTypeId: event.typeId, keyDateId: null, keyDateValue: null, keyDateName: event.keyDateName, includeTime: event.includeTime }
    });

    dialogRef.afterClosed().subscribe((result : any)  => {
      if (result == true)
      {
        this.loadKeyDates();

        this.caseService.refreshCase.next();

        // Offer Received or Scheduled Prepared Pack
        if (event.typeId == 13 || event.typeId == 16) {
          this.showTravellingConsultants();
        }

        // Shortfall Identified
        if (event.typeId == 97)
        {
          this.dialog.open(BasicPopupOkComponent, {
            data: { messageHeader: '', popupText1: 'Please obtain source of funds from the client, the Shortfall Value approximate amount must be immediately completed on the ‘Main Case’ details screen', popupText2: ''}
          });

          //this.caseService.refreshCase.next();
          //this.caseService.refreshShortfall.next();
        }

        //Provisional Completion Date or Exchange
        if (event.typeId == 22 || event.typeId == 59)
        {
          var okbox = this.dialog.open(BasicPopupOkComponent, {
            data: { messageHeader: '', popupText1: 'Please correct if necessary the Shortfall Amount details on the ‘Main Case’ details screen', popupText2: ''}
          });
          
          okbox.afterClosed().subscribe( (result : any) => {

            if(event.typeId == 59)
            {
              this.keyDatesService.getKeyDateValue(this.caseId, 109).subscribe(response => {
                
                if(response.keyDateValue == null){
                  this.keyDatesService.getKeyDateValue(this.caseId, 59).subscribe(response => {
 
                    var agreedCompletionNewDateDialog = this.dialog.open(AddKeyDateComponent, {
                      data: { caseId: this.caseId, keyDateTypeId: 109, keyDateId: null, keyDateValue: response.keyDateValue, keyDateName: "Agreed Completion Date", includeTime: false }
                    });

                    agreedCompletionNewDateDialog.afterClosed().subscribe((result : any)  => {
                      if (result == true)
                        this.keyDatesService.refreshKeyDates.next();
                    });

                  });
                }
              });
            }
          });
        }

        if(event.typeId == 26) // fileClosure
          this.dialogRef.close("fileClosed");
      }
    });

  }

  canAddKeyDate(event:any){


    if(event.typeId != 13) // Offer Received
    {
      return true;
    }
    else{
      var lenderCheckRef = this.dialog.open(BasicPopupYesNoComponent, {
        data: { messageHeader: '', popupText1: 'Has the Lender, Lenders Solicitor including e-mail and their reference number been completed for the automation e-mails to work properly?', popupText2: ''}
      });

      lenderCheckRef.afterClosed().subscribe(result => {

        if(!result) // call to trigger event to fire auto email
        {
          var dialogRef = this.dialog.open(BasicPopupOkComponent, {
            data: { messageHeader: '', popupText1: 'Please Complete', popupText2: ''}
          });
          return false;
        }
        else{
          this.showKeyDateAddPopup(event);
          return true;
        }
      });

      return false;
    }



  }

  editKeyDate(event:any) {
    //No longer needed

  }

  deleteKeyDate(event:any) {

    var dialogRef = this.dialog.open(DeleteKeyDateComponent, {
      data: { caseId: this.caseId, keyDateTypeId: event.typeId, keyDateId: event.keyDateId, keyDateValue: event.keyDate, keyDateName: event.keyDateName }
    });

    event.keyDate = null;

    dialogRef.afterClosed().subscribe(result => {
      if (result == true)
        this.loadKeyDates();
    });

  }

  showTravellingConsultants() {

    if (this.caseTypeId == 1 || this.caseTypeId == 7) {

      var consultantDialogRef = this.dialog.open(SelectConsultantComponent, {
        data: { caseId: this.caseId, showTravelling: true }
      });

      consultantDialogRef.afterClosed().subscribe(result => {

        if (result) {

          if (this.caseTypeId == 1) {
           // this.caseService.updateEquityReleaseConsultant({id: this.caseId, externalConsultantId: result.externalConsultantId}).subscribe();
          } else if (this.caseTypeId == 7) {
            //this.caseService.updateEquityReleasePurchaseConsultant({id: this.caseId, externalConsultantId: result.externalConsultantId}).subscribe();
          }

          //this.caseService.refreshCase.next();
        }
      });
    }
  }

  cancelCase() {
    var dialogRef = this.dialog.open(CancelCaseComponent, {
      data: { caseId: this.caseId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true)
        this.loadKeyDates();
    });
  }

  checkKeyDateVisible(keyId: number) {
    if (!this.keyDateTypes) return false;

    var key = this.keyDateTypes.find(x => x.keyDateTypeId == keyId);

    if (key) return true;

    return false;
  }

  getMonthForDate(date: any) {

    if (date) {
      var d = new Date(date);
      var curr_month = d.getMonth();
      var months = new Array("January", "February", "March",
        "April", "May", "June", "July", "August", "September",
        "October", "November", "December");
      return months[curr_month];
    }
    else
      return '';

  }


  viewHistory(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = false;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      caseId: this.caseId
    };
    dialogConfig.maxWidth = '75vw';
    dialogConfig.maxHeight = '75vh';
    dialogConfig.width = '75vw';
    dialogConfig.height = '75vh';

    var dialogRef = this.dialog.open(KeyDatesHistoryComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

    });

  }

  ngOnDestroy(){
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }
}
