import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CaseService } from '../../../../shared/services/case.service';
import { FormBuilder, Validators } from '@angular/forms';
import { LettersService } from '../../../../shared/services/letters.service';
import { ComparerService } from '../../../../shared/utility/comparer';
import { AuditService } from '../../../../shared/services/audit.service';
import { CorrespondenceTimelineService } from '../../../../shared/services/correspondence-timeline.service';
import { faHistory} from '@fortawesome/pro-light-svg-icons';
import { AuditFormComponent } from '../../../audit-form/audit-form.component';

@Component({
  selector: 'app-equity-release-purchase-estate-agent-details',
  templateUrl: './equity-release-purchase-estate-agent-details.component.html',
  styleUrls: ['./equity-release-purchase-estate-agent-details.component.css']
})
export class EquityReleasePurchaseEstateAgentDetailsComponent implements OnInit {

  caseId: number;
  dataLoaded: boolean = false;
  viewInit = false;
  generatingLetters: boolean = false;
  faHistory = faHistory;

  estateAgentDetails = this.fb.group({
    equityReleasePurchaseCaseId: [],
    caseId: [],
    estateAgentCompany : [],
    individualFinancialAdvisor: [],
    estateAgentContactNumber : [],
    estateAgentEmailAddress : [{value: '', disabled: false}, Validators.email],
    estateAgentFee : [],
    estateAgentAddressLine1 : [],
    estateAgentAddressLine2 : [],
    estateAgentAddressLine3 : [],
    estateAgentAddressLine4 : [],
    estateAgentAddressLine5 : [],
    estateAgentPostcode : []
  }, { });

  initialForm = this.fb.group({
    equityReleasePurchaseCaseId: [],
    caseId: [],
    estateAgentCompany : [],
    individualFinancialAdvisor: [],
    estateAgentContactNumber : [],
    estateAgentEmailAddress : [{value: '', disabled: false}, Validators.email],
    estateAgentFee : [],
    estateAgentAddressLine1 : [],
    estateAgentAddressLine2 : [],
    estateAgentAddressLine3 : [],
    estateAgentAddressLine4 : [],
    estateAgentAddressLine5 : [],
    estateAgentPostcode : []
  }, { });


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private caseService: CaseService,
              private fb: FormBuilder,
              private lettersService: LettersService,
              public dialogRef: MatDialogRef<EquityReleasePurchaseEstateAgentDetailsComponent>,
              private comp: ComparerService,
              private auditService: AuditService,
              public dialog: MatDialog,
              private correspondenceTimelineService: CorrespondenceTimelineService) {

    this.caseId = data.caseId;



  }

  ngOnInit(): void {
  }

  ngAfterContentInit() : void {
    this.viewInit = true;
    this.loadData();
  }

  loadData(){
    this.caseService.getEquityReleasePurchaseEstateAgentDetails(this.caseId).subscribe((response : any) => {

      this.estateAgentDetails.patchValue(response.estateAgentDetails);
      this.initialForm.patchValue(response.estateAgentDetails);
      this.dataLoaded = true;
    });
  }

  updateField(){

    this.caseService.updateEquityReleasePurchaseEstateAgentDetails(this.estateAgentDetails.value).subscribe((response : any) => {

    }, (error : any) => {


    });
  }

  createLetters(){
    this.generatingLetters = true;

    this.lettersService.generateLettersForGroup(this.caseId, 4).subscribe((response : any) => {
      this.correspondenceTimelineService.refreshCaseTimeline.next();
      this.close();
    }, (error : any) => {


    });
  }

  close(){

    if(this.comp.objectsDifferent(this.initialForm.value, this.estateAgentDetails.value))
    {
      this.auditService.auditForm(this.caseId, 'EstateAgentDetails', this.initialForm.value, this.estateAgentDetails.value).subscribe((response : any) => {
        this.dialogRef.close();
      });
    }
    else
      this.dialogRef.close();
  }

  openAuditForForm(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      caseId: this.caseId,
      formName: "EstateAgentDetails"
    };

    this.dialog.open(AuditFormComponent, dialogConfig);

  }

}
