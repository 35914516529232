<h1>Add Key Date</h1>


<h2>{{keyDateName}}</h2>
      <div>
        <mat-form-field >
            <input [(ngModel)]="keyDateValue" matInput [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker startView="multi-year"></mat-datepicker>
        </mat-form-field>
      </div>
      <div *ngIf="includeTime" >
          <label>Time (HH:MM): </label>
        <mat-form-field class="time" >
            <input matInput [(ngModel)]="keyDateTime" maxlength="5" timechars (keyup)="validateTime()">
        </mat-form-field>

      </div>

      <div class="buttons">
        <button mat-stroked-button [disabled]="false" color="warn" type="button" (click)="cancel()">Cancel</button>
        <button class="right" *ngIf="!loading" mat-stroked-button [disabled]="keyDateValue == null || saveButtonDisabled" color="primary" (click)="saveKeyDate()">Save</button>
        <button *ngIf="loading" mat-icon-button disabled color="primary" class="right" >
          <mat-icon>
              <mat-spinner color="primary" diameter="20">
              </mat-spinner>
          </mat-icon>
      </button>
      </div>
