import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseService } from 'src/app/shared/services/case.service';
import { FormGroup, FormControl } from '@angular/forms';
import { KeyDatesService } from 'src/app/shared/services/keydates.service';
import { CorrespondenceTimelineService } from '../../../../shared/services/correspondence-timeline.service';

@Component({
  selector: 'app-delete-key-date',
  templateUrl: './delete-key-date.component.html',
  styleUrls: ['./delete-key-date.component.scss']
})
export class DeleteKeyDateComponent implements OnInit {

  private caseId: number;
  private keyDateTypeId: number;
  public keyDateName: string;
  private notes: string = '';

  public keyDateValue : any;
  private keyDateId : number;

  isLoading: boolean = false;

  deleteKeyDateDetails= new FormGroup({
    reason: new FormControl()
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<DeleteKeyDateComponent>,
  private caseService: CaseService,
  private keyDatesService: KeyDatesService,
  private correspondenceTimelineService: CorrespondenceTimelineService) {

    this.caseId = this.data['caseId'];
    this.keyDateTypeId = this.data['keyDateTypeId'];
    this.keyDateId = this.data['keyDateId'];
    this.keyDateName = this.data['keyDateName'];

    if(this.keyDateId != null)
    {
      this.keyDateValue = this.data['keyDateValue'];
    }

  }

  ngOnInit(): void {
  }

  cancel(){
    this.dialogRef.close(null);
  }


  deleteKeyEvent(){

    this.isLoading = true;

    this.keyDatesService.deleteKeyDate(this.keyDateId, this.deleteKeyDateDetails.value).subscribe(response => {
      this.correspondenceTimelineService.refreshCaseTimeline.next();

      this.dialogRef.close(true);

      }, (error : any ) => {

        var errorMessage = "Deleting Key Event Failed";

        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }
      });
  }
}
