import { Component, AfterViewInit, Input, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { CaseService } from '../shared/services/case.service';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../shared/services/user.service';
import { BaseFilterComponent } from '../shared/components/table-filters/base-filter.component';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { SearchRequest } from '../shared/services/interfaces/search-request';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-cancelled-case-list',
  templateUrl: './cancelled-case-list.component.html',
  styleUrls: ['./cancelled-case-list.component.scss']
})
export class CancelledCaseListComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype);
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChildren('filter') filters!: QueryList<BaseFilterComponent>;

  resultsLength: number = 0;
  displayedColumns: string[] = [];
  isLoadingResults = true;
  data: any[] = [];
  paralegals: any[] = [];
  admins: any[] = [];

  tableName: string = "cancelledCaseList";
  searchCriteria: SearchRequest = {page: 1, pageSize: 25, orderBy: "cancelledDate", orderDirection: "desc", filterColumns: [], filterStrings: []};

  constructor(private caseService: CaseService,
    public authService: AuthService,
    private userService: UserService) {

        userService.paralegals().subscribe(results => {
          for (const user of results) {
            this.paralegals.push({ name: user.firstName + ' ' + user.lastName, id: user.id })
          }
        });

        userService.admins().subscribe(results => {
          for (const user of results) {
            this.admins.push({ name: user.firstName + ' ' + user.lastName, id: user.id })
          }
        });

        this.displayedColumns = ['allocatedToParalegalUser', 'allocatedToAdminUser', 'financialAdviserName', 'companyGroup', 'caseType', 'partyOneFirstName', 'partyOneLastName', 'partyOneAddressLine1', 'partyOnePostCode', 'caseReference', 'cancelledDate', 'cancellationReason'];
    }

    ngAfterViewInit(): void {
      this.loadCaseData();
    }

    loadCaseData() {

      let storedSearchCriteria = localStorage.getItem(this.tableName);
      let searchRequest = {page: 1, pageSize: 25, orderBy: this.sort.active, orderDirection: this.sort.direction, filterNames: [], filterColumns: [], filterStrings: []};

      if(storedSearchCriteria != null)
      {
        searchRequest = JSON.parse(storedSearchCriteria);

        this.filters.forEach((item : any) => {

          if(item.componentTypeName == "StringFilterComponent" || item.componentTypeName == "DateFilterComponent" || item.componentTypeName == "AutocompleteFilterComponent")
          {
            var columnIndex = searchRequest.filterColumns.findIndex(element => element == item.filterColumn);
            var filterName = searchRequest.filterNames[columnIndex];
            var storedValue = searchRequest.filterStrings[columnIndex];
            item.filterName = filterName;
            item.filterValue = storedValue;
          }

        });

        this.searchCriteria.filterStrings = searchRequest.filterStrings;
        this.sort.active = searchRequest.orderBy;
        this.sort.direction = searchRequest.orderDirection as SortDirection;
        this.sort.sortChange.emit();
      }

      const outputs = this.filters.map(button => button.filter);

      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
      });

      merge(...outputs).subscribe(() => {
        this.paginator.pageIndex = 0;
      });

      merge(this.sort.sortChange, this.paginator.page, ...outputs)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          this.searchCriteria.page = this.paginator.pageIndex + 1;
          this.searchCriteria.pageSize = this.paginator.pageSize;
          this.searchCriteria.orderBy = this.sort.active;
          this.searchCriteria.orderDirection = this.sort.direction;

          this.searchCriteria.filterStrings = this.filters.map(button => button.filterValue);
          this.searchCriteria.filterColumns = this.filters.map(button => button.filterColumn);

          let saveSearch = {
            page: this.paginator.pageIndex + 1,
            pageSize: this.paginator.pageSize,
            orderBy: this.sort.active,
            orderDirection: this.sort.direction,
            filterNames: this.filters.map(button => button.filterName),
            filterColumns: this.filters.map(button => button.filterColumn),
            filterStrings: this.filters.map(button => button.filterValue)
          };

          localStorage.setItem(this.tableName, JSON.stringify(saveSearch));

          return this.caseService.cancelledCases(this.searchCriteria);

        }),
        map((data : any) => {

          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.recordCount;
          return data.items;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);

    }

    formatDate(inDate: any){

      var formattedDate = '';

      if(inDate !== null)
      {
        var dt = DateTime.fromISO(inDate);
        formattedDate = dt.toFormat("dd-MM-yyyy");

      if(formattedDate == 'Invalid date')
        formattedDate = '';
      }

      return formattedDate;

    }
}
