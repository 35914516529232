import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { audit } from 'rxjs/operators';
import { AuditService } from '../../../../shared/services/audit.service';
import { EnquiryChecklistService } from '../../../../shared/services/enquiry-checklist.service';

@Component({
  selector: 'app-enquiries-audit-form',
  templateUrl: './enquiries-audit-form.component.html',
  styleUrls: ['./enquiries-audit-form.component.css']
})
export class EnquiriesAuditFormComponent implements OnInit {


  formName: string = '';
  caseId: number;
  itemId: number;
  auditFormItems: MatTableDataSource<any>[] = [];

  displayedColumns: string[] = ['enquiry', 'fieldName', 'oldValue', 'newValue', 'byUser', 'dateChanged'];


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private auditService: AuditService,
    private enquiryService: EnquiryChecklistService  ) {

    this.formName = data.formName;
    this.caseId = data.caseId;
    this.itemId = data.itemId;

    if (this.caseId != null && this.caseId > 0) {
      this.auditService.getAuditItemsForCaseForm(this.formName, this.caseId).subscribe((response: any) => {

        this.mapEnquiryData(response);

      });
    }

  }

  ngOnInit(): void {
   
  }

  private mapEnquiryData(auditData: any[]) {
    this.enquiryService.getEnquiryChecklistForCase(this.caseId)
      .subscribe(data => {
        auditData.forEach(function (item, idx) {
          var enquiry = data.find(x => x.id == item.itemId);
          if (enquiry != null) {
            item.enquiry = enquiry.enquiry;
          }
        });

        this.auditFormItems = auditData.sort((a, b) => {
          return (a.itemId > b.itemId) ? 1 : -1;
        });

      });
  }

}
