import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BaseFilterComponent } from '../shared/components/table-filters/base-filter.component';
import { SelectionModel } from '@angular/cdk/collections';
import { merge, of as observableOf } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { NotificationsService } from '../shared/services/notifications.service';
import { UserService } from '../shared/services/user.service';
import { LookupsService } from '../shared/services/lookups.service';
import { faHistory } from '@fortawesome/pro-light-svg-icons';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotificationHistoryComponent } from '../notification-history/notification-history.component';
import {TransferNotificationComponent} from './transfer-notification/transfer-notification.component'

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype);
  @ViewChild(MatSort) sort: MatSort = new MatSort();

  @ViewChildren('filter') filters!: QueryList<BaseFilterComponent>;

  selection = new SelectionModel<any>(true, []);
  resultsLength: number = 0;
  displayedColumns: string[] = [];
  isLoadingResults = true;
  data: any[] = [];
  users: any[] = [];

  faHistory = faHistory;

  constructor(
    private notificationsService: NotificationsService,
    private lookupsService: LookupsService,
    userService: UserService,
    public dialog: MatDialog) {
    this.displayedColumns = ['select', 'dateCreated', 'forUser', 'caseReference', 'notificationType', 'notificationText', 'acknowledgedDate', 'readDate', 'dismissedDate', 'actions'];

    this.lookupsService.getAllInternalUsers().subscribe(result => {

      for (const user of result) {
        this.users.push({ name: user.firstName + ' ' + user.lastName, id: user.id })
      }
    });

  }

  checkboxLabel(row?: any): string {

    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;

  }


  masterToggle() {

    this.isAllSelected() ?
      this.selection.clear() :
      this.data.forEach(row => this.selection.select(row));

  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  ngAfterViewInit(): void {
    this.loadNotifications();
  }


  loadNotifications(){
    const outputs = this.filters.map(button => button.filter);

    this.selection.clear();

    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.selection.clear();
    });

    merge(...outputs).subscribe(() => {
      this.paginator.pageIndex = 0;
      this.selection.clear();
    });

    merge(this.sort.sortChange, this.paginator.page, ...outputs)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;

                let searchCriteria = {
          page: this.paginator.pageIndex + 1,
          pageSize: this.paginator.pageSize,
          orderBy: this.sort.active,
          orderDirection: this.sort.direction,
          filterNames: this.filters.map(button => button.filterName),
          filterColumns: this.filters.map(button => button.filterColumn),
          filterStrings: this.filters.map(button => button.filterValue)
        };


        return this.notificationsService.getNotifications(searchCriteria);

      }),
      map((data : any) => {

        // Flip flag to show that loading has finished.
        this.isLoadingResults = false;
        this.resultsLength = data.recordCount;
        return data.items;
      }),
      catchError(() => {
        this.isLoadingResults = false;
        return observableOf([]);
      })
    ).subscribe(data => this.data = data);
  }

  viewHistory(notificationId: number){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = false;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      notificationId: notificationId
    };
    dialogConfig.maxWidth = '75vw';
    dialogConfig.maxHeight = '75vh';
    dialogConfig.width = '75vw';
    dialogConfig.height = '75vh';

    var dialogRef = this.dialog.open(NotificationHistoryComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  transferNotification(){
    var dialogRef = this.dialog.open(TransferNotificationComponent, {
      data: {
        selection: this.selection.selected
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true)
        this.loadNotifications();
    });
  }

}
