import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SearchRequest } from './interfaces/search-request';
import { SearchResult } from './interfaces/search-result';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class SMSService {

  private url = environment.apiUrl + 'sms/';

  constructor(private http: HttpClient, public router: Router) {

  }


  sendSMS(caseId: number, sms: any){

    return this.http.put(this.url + caseId + '/sendsms/', sms, httpOptions);

  }




}
