import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { CaseService } from '../shared/services/case.service';
import { LookupsService } from '../shared/services/lookups.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-case',
  templateUrl: './new-case.component.html',
  styleUrls: ['./new-case.component.css']
})
export class NewCaseComponent implements OnInit {

  caseTypes: any[] = [];
  titles: any[] = [];
  addingCase: boolean = false;

  newCaseDetails = this.fb.group({
    typeId: [, Validators.required],
    typeOther: [''],
    partyOneTitleId: ['', Validators.required],
    partyOneFirstName: ['', Validators.required],
    partyOneMiddleNames: [''],
    partyOneLastName: ['', Validators.required],
    partyOneDateOfBirth: [],
    partyOneAddressLine1: ['', Validators.required],
    partyOneAddressLine2: [''],
    partyOneAddressLine3: [''],
    partyOneAddressLine4: [''],
    partyOneAddressLine5: [''],
    partyOnePostcode: ['', [Validators.required, Validators.pattern('([A-PR-UWYZa-pr-uwyz]([0-9]{1,2}|([A-HK-Ya-hk-y][0-9]|[A-HK-Ya-hk-y][0-9]([0-9]|[ABEHMNPRV-Yabehmnprv-y]))|[0-9][A-HJKS-UWa-hjks-uw])\ {0,1}[0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}|([Gg][Ii][Rr]\ 0[Aa][Aa])|([Ss][Aa][Nn]\ {0,1}[Tt][Aa]1)|([Bb][Ff][Pp][Oo]\ {0,1}([Cc]\/[Oo]\ )?[0-9]{1,4})|(([Aa][Ss][Cc][Nn]|[Bb][Bb][Nn][Dd]|[BFSbfs][Ii][Qq][Qq]|[Pp][Cc][Rr][Nn]|[Ss][Tt][Hh][Ll]|[Tt][Dd][Cc][Uu]|[Tt][Kk][Cc][Aa])\ {0,1}1[Zz][Zz]))')]],
    partyOneContactNumberOne: ['', Validators.required],
    partyOneContactNumberTwo: [''], //
    partyOneEmailAddress: [''], //
    partyOneHasEmailAddress: [, Validators.required], //
    partyOneCorrespondenceEmail: [],
    partyOneCorrespondenceLetter: ['', Validators.required],
    partyOneCorrespondenceSMS: [],
    partyTwoTitleId: [],
    partyTwoFirstName: [''],
    partyTwoMiddleNames: [''],
    partyTwoLastName: [''],
    partyTwoDateOfBirth: [],
    partyTwoAddressLine1: [''],
    partyTwoAddressLine2: [''],
    partyTwoAddressLine3: [''],
    partyTwoAddressLine4: [''],
    partyTwoAddressLine5: [''],
    partyTwoPostcode: ['', Validators.pattern('([A-PR-UWYZa-pr-uwyz]([0-9]{1,2}|([A-HK-Ya-hk-y][0-9]|[A-HK-Ya-hk-y][0-9]([0-9]|[ABEHMNPRV-Yabehmnprv-y]))|[0-9][A-HJKS-UWa-hjks-uw])\ {0,1}[0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}|([Gg][Ii][Rr]\ 0[Aa][Aa])|([Ss][Aa][Nn]\ {0,1}[Tt][Aa]1)|([Bb][Ff][Pp][Oo]\ {0,1}([Cc]\/[Oo]\ )?[0-9]{1,4})|(([Aa][Ss][Cc][Nn]|[Bb][Bb][Nn][Dd]|[BFSbfs][Ii][Qq][Qq]|[Pp][Cc][Rr][Nn]|[Ss][Tt][Hh][Ll]|[Tt][Dd][Cc][Uu]|[Tt][Kk][Cc][Aa])\ {0,1}1[Zz][Zz]))')],
    partyTwoContactNumberOne: [''],
    partyTwoContactNumberTwo: [''], //
    partyTwoHasEmailAddress: [],
    partyTwoEmailAddress: [''],
    partyTwoCorrespondenceEmail: [],
    partyTwoCorrespondenceLetter: [],
  }, { });

  minDob: Date;
  maxDob: Date;
  dobStartDate: Date;

  constructor(private caseService: CaseService,
    private lookupsService: LookupsService,
    private fb: FormBuilder,
    public router: Router) {

      this.lookupsService.getCaseTypes().subscribe(result => {
        this.caseTypes = result;
      });

      this.lookupsService.getTitles().subscribe(result => {
        this.titles = result;
      });

      var date = new Date();

      this.minDob = new Date(date.getFullYear() - 120, date.getMonth(), date.getDate());
      this.maxDob = date;
      this.dobStartDate = new Date(date.getFullYear() - 18, date.getMonth(), date.getDate());

      Object.keys(this.newCaseDetails.controls).forEach(key => {
        this.newCaseDetails.get(key)!.markAsTouched();
        this.newCaseDetails.get(key)!.updateValueAndValidity();
      });

      this.onPartyOneHasEmailAddressChange();
      this.caseTypeChange();
  }

  ngOnInit(): void {
  }

  addCase(){

  }

  copyAddress(){
    this.newCaseDetails.controls['partyTwoPostcode'].patchValue(this.newCaseDetails.get('partyOnePostcode')!.value);
    this.newCaseDetails.controls['partyTwoAddressLine1'].patchValue(this.newCaseDetails.get('partyOneAddressLine1')!.value);
    this.newCaseDetails.controls['partyTwoAddressLine2'].patchValue(this.newCaseDetails.get('partyOneAddressLine2')!.value);
    this.newCaseDetails.controls['partyTwoAddressLine3'].patchValue(this.newCaseDetails.get('partyOneAddressLine3')!.value);
    this.newCaseDetails.controls['partyTwoAddressLine4'].patchValue(this.newCaseDetails.get('partyOneAddressLine4')!.value);
    this.newCaseDetails.controls['partyTwoAddressLine5'].patchValue(this.newCaseDetails.get('partyOneAddressLine5')!.value);
  }

  createCase(){

    this.addingCase = true;

    this.caseService.createInternalCase(this.newCaseDetails.value).subscribe(response => {

      this.addingCase = false;
      var caseId = response.caseId;


      console.log(this.router);

      this.router.navigateByUrl('/case/' + caseId);

    }, error => {

      var errorMessage = "Case Creation Failed";
      if (error.error !== null && error.error.message !== undefined) {
        errorMessage = error.error.message;
      }
      this.addingCase = false;
    });

  }

  correspondenceCheck(type: string){

    console.log('correspondenceCheck', type);

    if(type == 'email'){

      if(this.newCaseDetails.get('partyOneCorrespondenceEmail')!.value == true)
          this.newCaseDetails.controls['partyOneCorrespondenceLetter'].patchValue(false);
      else
          this.newCaseDetails.controls['partyOneCorrespondenceLetter'].patchValue(true);
    }
    else{ //letter
      if(this.newCaseDetails.get('partyOneCorrespondenceLetter')!.value == true)
        this.newCaseDetails.controls['partyOneCorrespondenceEmail'].patchValue(false);
       else
        this.newCaseDetails.controls['partyOneCorrespondenceEmail'].patchValue(true);
    }

  }

  updateAddressValidation(){
    this.newCaseDetails.get('partyOneAddressLine1')!.markAsTouched();
    this.newCaseDetails.get('partyOneAddressLine2')!.markAsTouched();
    this.newCaseDetails.get('partyOneAddressLine3')!.markAsTouched();
    this.newCaseDetails.get('partyOnePostcode')!.markAsTouched();
  }


  caseTypeChange(){

    this.newCaseDetails.get('typeId')!.valueChanges.subscribe((answer: number) => {
      if (answer == 0) {
        this.newCaseDetails.get('typeOther')!.setValidators([Validators.required]);
        this.newCaseDetails.get('typeOther')!.markAsTouched();
      }
      else{
        this.newCaseDetails.controls['typeOther'].patchValue('');
        this.newCaseDetails.get('typeOther')!.clearValidators();
        this.newCaseDetails.get('typeOther')!.reset();
        this.newCaseDetails.updateValueAndValidity();
      }
    });
  }

  onPartyOneHasEmailAddressChange() {



    this.newCaseDetails.get('partyOneHasEmailAddress')!.valueChanges.subscribe(

     (answer: boolean) => {

          if (answer == true) {
            this.newCaseDetails.get('partyOneEmailAddress')!.setValidators([Validators.required]);
            this.newCaseDetails.get('partyOneCorrespondenceEmail')!.setValidators([Validators.required]);
            this.newCaseDetails.get('partyOneEmailAddress')!.markAsTouched();
            this.newCaseDetails.get('partyOneCorrespondenceEmail')!.markAsTouched();

            // default to email if has an address
            this.newCaseDetails.controls['partyOneCorrespondenceEmail'].patchValue(true);
            this.newCaseDetails.controls['partyOneCorrespondenceLetter'].patchValue(false);
          } else {
              this.newCaseDetails.get('partyOneEmailAddress')!.clearValidators();
              this.newCaseDetails.get('partyOneCorrespondenceEmail')!.clearValidators();
              this.newCaseDetails.get('partyOneEmailAddress')!.reset();
              this.newCaseDetails.get('partyOneCorrespondenceEmail')!.reset();

              // default to letter if no email address
            this.newCaseDetails.controls['partyOneCorrespondenceLetter'].patchValue(true);
            this.newCaseDetails.controls['partyOneCorrespondenceEmail'].patchValue(false);
          }
          this.newCaseDetails.controls['partyOneEmailAddress'].updateValueAndValidity();
          this.newCaseDetails.controls['partyOneCorrespondenceEmail'].updateValueAndValidity();
          this.newCaseDetails.updateValueAndValidity();
      });
  }



}
