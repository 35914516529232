import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { faPlus, faTrashAlt, faHistory, faEdit } from '@fortawesome/pro-light-svg-icons';
import { CaseService } from '../../../shared/services/case.service';
import { AuthService } from '../../../auth/auth.service';
import { Subscription } from 'rxjs';
import { AddCaseNotesComponent } from './add-case-notes/add-case-notes.component';
import { CaseNotesHistoryComponent } from './case-notes-history/case-notes-history.component';
import { BasicPopupYesNoComponent } from '../../../shared/components/basic-popup-yes-no/basic-popup-yes-no.component';
import { CorrespondenceTimelineService } from '../../../shared/services/correspondence-timeline.service';

@Component({
  selector: 'app-case-notes',
  templateUrl: './case-notes.component.html',
  styleUrls: ['./case-notes.component.css']
})
export class CaseNotesComponent implements OnInit {

  notes: any;
  viewInit = false;
  _caseId?: (number | undefined);

  @Input()
  set caseId(value: (number | undefined)) {
    this._caseId = value;
    this.loadCaseNotes();
  }
  get caseId() : (number | undefined)  {
    return this._caseId;
  }

  @Input() readOnly = true;
  @Input() caseInstructed = true;

  faPlus = faPlus;
  faTrashAlt = faTrashAlt;
  faHistory = faHistory;
  faEdit= faEdit;

  notesLoaded: boolean = false;

  refreshSubscription: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
              public dialog: MatDialog,
              private caseService: CaseService,
              public authService: AuthService,
              private correspondenceTimelineService: CorrespondenceTimelineService ) {

      if(componentData.caseId)
      {
        this.caseId = componentData.caseId;
      }

      this.refreshSubscription = this.caseService.refreshCaseNotesObservable.subscribe(() => {
        this.loadCaseNotes();
      });
    }

  ngOnInit(): void {
  }

  ngAfterContentInit() : void {
    this.viewInit = true;
    this.loadCaseNotes();
  }

  loadCaseNotes(){

    if (!this.viewInit) return;

    this.caseService.getCaseNotes(this.caseId).subscribe(result => {
      this.notes = result;
      this.notesLoaded = true;
    }, error => {

      var errorMessage = "Failed to get notes";
      if (error.error !== null && error.error.message !== undefined) {
        errorMessage = error.error.message;
      }

    });
  }

  addCaseNotes(){


    var dialogRef = this.dialog.open(AddCaseNotesComponent, {
      data: { caseId: this.caseId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true)
      {
        this.loadCaseNotes();
      }
    });

  }

  deleteNote(noteId : number){

    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Are you sure you want to delete this note?', popupText1: '', popupText2: ''}
    });

    dialogRef.afterClosed().subscribe(result => {

      if(!result) // call to trigger event to fire auto email
      {}
      else{

        this.caseService.deleteCaseNote(this.caseId, noteId).subscribe(result => {
          this.loadCaseNotes();
          this.correspondenceTimelineService.refreshCaseTimeline.next();
        });
      }
    });

  }

  editNote(noteId : number){

    var dialogRef = this.dialog.open(AddCaseNotesComponent, {
      data: {
        caseId: this.caseId,
        caseNoteId: noteId
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true)
      {
        this.loadCaseNotes();
      }
    });
  }

  viewHistory(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      caseId: this.caseId
    };
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '60vh';
    dialogConfig.width = '60vw';
    dialogConfig.height = '60vh';

    var dialogRef = this.dialog.open(CaseNotesHistoryComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

    });

  }

  ngOnDestroy(){
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }
}
