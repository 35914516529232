import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { KlyantService } from './klyant.service';
import { Observable } from "rxjs"
import { mergeMap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class InvoiceService {

  private url = environment.apiUrl + 'invoice/';

  constructor(private http: HttpClient, public router: Router, private klyantService: KlyantService) {
  }

  getVatRate(){
    return this.http.get<any>(this.url + "VATRate");
  }


  getInvoiceItems(caseId: number, invoiceId: (number | undefined), feeType: number){
    return this.http.get<any>(this.url + caseId + '/invoiceitems/' + invoiceId + '/' + feeType);
  }

  getInvoice(caseId: number){
    return this.http.get<any>(this.url + caseId);
  }

  getInvoiceId(caseId: number | undefined){
    return this.http.get<any>(this.url + caseId + '/invoiceId');
  }

  getInvoiceById(caseId: number, invoiceId: number){
    return this.http.get<any>(this.url + caseId + '/' + invoiceId);
  }

  getFeeTypes() {
    return this.http.get<any>(this.url + 'FeeTypes');
  }

  getFeeTypesForCase(caseId: number, feeType: (number | undefined)){
    return this.http.get<any>(this.url + caseId + '/fees/' + feeType);
  }


  addInvoiceItem(caseId: number, invoiceId: number, invoiceItemDetails: any){

    let formDetails = {
      invoiceId : invoiceId,
      feeType: invoiceItemDetails.feeType,
      description: invoiceItemDetails.description,
      net: invoiceItemDetails.net,
      addVat: invoiceItemDetails.addVat,
      outlayId: invoiceItemDetails.outlayId,
      nominalCode: invoiceItemDetails.nominalCode,
      feeId: invoiceItemDetails.feeId
    };

    return this.http.put<any>(this.url + caseId + '/invoiceitem/', formDetails);
  }

  editInvoiceItem(caseId: number, invoiceId: number, invoiceItemDetails: any){

    let formDetails = {
      invoiceId : invoiceId,
      invoiceItemId : invoiceItemDetails.invoiceItemId,
      description: invoiceItemDetails.description,
      net: invoiceItemDetails.net,
      outlayId: invoiceItemDetails.outlayId,
      nominalCode: invoiceItemDetails.nominalCode,
      addVat: invoiceItemDetails.addVat
    };

    return this.http.post<any>(this.url + caseId + '/invoiceitem/', formDetails);
  }

  removeInvoiceItem(caseId: number, invoiceId: (number | undefined), invoiceItemId: number){

    let formDetails = {
      invoiceId : invoiceId,
      invoiceItemId: invoiceItemId
    };

    return this.http.post<any>(this.url + caseId + '/removeinvoiceitem/', formDetails);
  }

  generateCompletionStatement(caseId: number, invoiceId: number){
    return this.http.get<any>(this.url + caseId + '/completionstatement/' + invoiceId);
  }

  generateInvoice(caseId: number): Observable<any> {
    return this.klyantService.getAuthToken()
      .pipe(
        mergeMap((token:any) => {
          return this.http.get<any>(this.url + caseId + '/invoice/', {
            headers: {'X-Klyant-Token': token}
          });
      })
    ); 
  }

  generateInvoiceNumberAndPrintInvoice(caseId: number, invoiceId: number) {
      return this.http.get<any>(this.url + caseId + '/invoice/' + invoiceId);

  }

  refreshAutoLines(caseId: number, invoiceId: (number | undefined)): Observable<any> {
      return this.http.get<any>(this.url + caseId + '/refreshautolines/' + invoiceId);
  }

  getFeeTypesWithSuppliers() {
    return this.http.get<any>(this.url + 'FeeTypesWithSuppliers');
  }

  uploadInvoice(caseId: number, file: File, feeType: number): Observable<any> {

    let formData = new FormData();
    formData.append('feeType', feeType.toString());
    formData.append('files', file);
    
    return this.klyantService.getAuthToken()
      .pipe(
        mergeMap((token:any) => {
          return this.http.post(this.url + caseId + '/uploadInvoice/', formData, {
            headers: {'X-Klyant-Token': token}
          });
        })
      );
  }

  syncInvoiceItemsToPreInvoiceFees(caseId: number, invoiceId: number): Observable<any> {
     return this.http.get<any>(this.url + caseId + '/preinvoicefees/' + invoiceId);
  }

  setSupplierOnPreInvoiceFee(preInvoiceFeeId:number, supplierId:number) {
    let formData = new FormData();
    formData.append('supplierId', supplierId.toString());
    return this.http.post(this.url + 'preinvoicefee/' + preInvoiceFeeId, formData);
  }

  getInvoices(caseId: number | undefined){
    return this.http.get<any>(this.url + 'all/'+ caseId);
  }

  addInvoice(caseId: number | undefined, description: string){

    let formDetails = {
      description: description,
    };

    return this.http.put<any>(this.url + 'create/' + caseId , formDetails);
  }

  archiveInvoice(caseId: number, invoiceId: number) {
    return this.http.get<any>(this.url + caseId + '/archive/' + invoiceId);
  }

  getAllKlyantInvoices(caseId: number | undefined){
    return this.http.get<any>(this.url + 'allklyant/'+ caseId);
  }

}
