import { Component, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormBuilder } from '@angular/forms';
import { LookupsService } from '../shared/services/lookups.service';
import { SearchService } from '../shared/services/search.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-email-queue',
  templateUrl: './email-queue.component.html',
  styleUrls: ['./email-queue.component.scss']
})
export class EmailQueueComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype);
  @ViewChild(MatSort) sort: MatSort = new MatSort();

  resultsTitle: string = 'Emails';
  results: any[] = [];
  isLoadingResults: boolean = false;
  resultsLength: number = 0;

  internalUsers: any[] = [];

  searchCriteria = this.fb.group({
    caseReference: [null],
    toEmail: [null],
    queuedDateFrom: [null],
    queuedDateTo: [null],
    fromUserId: [null],
    pageNo: [0],
    itemsPerPage: [100],
    orderByColumn: [null],
    orderByDirection: [null]
  }, {});

  displayedColumns: string[] = ['caseReference', 'queuedDate', 'user', 'to', 'cc', 'subject', 'sentDate', 'error'];


  constructor(private fb: FormBuilder, private searchService: SearchService, private lookupsService: LookupsService) {
    this.lookupsService.getAllInternalUsers().subscribe(result => {
      this.internalUsers = result;
    });

    // Default queued date to be the last couple of days so don't pull back loads of results on first search
    var queuedDateFrom = DateTime.local().minus({days: 2});
    var queuedDateTo = DateTime.local();

    this.searchCriteria.controls["queuedDateFrom"].patchValue(queuedDateFrom);
    this.searchCriteria.controls["queuedDateTo"].patchValue(queuedDateTo);

  }

  ngAfterViewInit() {
    this.doSearch();
    this.paginator.page.subscribe(() => this.doSearch());
    this.sort.sortChange.subscribe(() => this.doSearch());
  }

  doClear() {

  }

  doSearch() {
    this.isLoadingResults = true;

    this.searchCriteria.controls["pageNo"].patchValue(this.paginator.pageIndex);
    this.searchCriteria.controls["itemsPerPage"].patchValue(this.paginator.pageSize);
    this.searchCriteria.controls["orderByColumn"].patchValue(this.sort.active);
    this.searchCriteria.controls["orderByDirection"].patchValue(this.sort.direction);

    this.searchService.emailSearch(this.searchCriteria.value).subscribe((data: any) => {
      this.results = data.pagedResults;
      this.resultsLength = data.totalResults;
      this.resultsTitle = 'Emails (' + this.resultsLength + ' matches)';
      this.isLoadingResults = false;
    });



  }

}
