import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { faPlus, faTimes, faExternalLink, faShare, faPencil } from '@fortawesome/pro-light-svg-icons';
import { InvoiceService } from '../../../../shared/services/invoice.service';
import { InvoiceComponent } from '../invoice/invoice.component';
import { AddInvoiceComponent } from '../add-invoice/add-invoice.component';
import { Subscription } from 'rxjs';
import { KlyantService } from '../../../../shared/services/klyant.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicPopupYesNoComponent } from '../../../../shared/components/basic-popup-yes-no/basic-popup-yes-no.component';


@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {

  caseId : number;
  caseTypeId : number;
  invoices: any[] = []; 

  faPlus = faPlus;
  faTimes = faTimes;
  faShare = faShare;
  faPencil = faPencil;
  faExternalLink = faExternalLink;

  reloadInvoicesSubscription: Subscription;

  constructor(public dialog: MatDialog,
    private invoiceService: InvoiceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InvoiceListComponent>,
    private klyantService: KlyantService,
    private snackBar: MatSnackBar) { 
      this.caseId = data.caseId;
      this.caseTypeId = data.caseTypeId;

    }

  ngOnInit(): void {
    this.loadInvoices();
  }

  loadInvoices(){
    this.invoiceService.getInvoices(this.caseId).subscribe((response : any) => {
      console.log(response);
      this.invoices = response.invoices;
    });
  }

  editInKlyant(invoiceId: number) {
    //window.open(environment.klyant.guiBaseUrl + '/invoices/' + invoiceId + '/edit', '_blank'); ?? what link?
    alert('TODO - work out which link to use');
  }


  archiveInvoice(invoiceId: number, klyantInvoiceId: number) {

    var popupText = '';
    if(klyantInvoiceId)
    {
      popupText = 'THIS INVOICE HAS BEEN SENT TO KLYANT. YOU MUST ALSO VOID IN KLYANT';
    }

    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Are you sure you want to archive this invoice?', popupText1: popupText, popupText2: ''}
    });

    dialogRef.afterClosed().subscribe((result : any) => {

      if(result)
      {
        this.invoiceService.archiveInvoice(this.caseId,invoiceId).subscribe((response : any) => {
          this.loadInvoices();
        });      
      }
    });
   
  }


  openInvoice(invoiceId: number){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId,
      invoiceId: invoiceId,
      caseTypeId : this.caseTypeId
    };

    const dialogRef = this.dialog.open(InvoiceComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadInvoices();
    });
  }

  addInvoice(){
    if(this.caseTypeId == 1 || // Equity Release
      this.caseTypeId == 6 || // Conveyancing Sale
      this.caseTypeId == 7 || // Equity Release Including Purchase
      this.caseTypeId == 8)	// Conveyancing Purchase
    {
      this.invoiceService.getInvoices(this.caseId).subscribe((response : any) => {
        var invoices = response.invoices;
        if(invoices.length == 0){
          var okDialogRef = this.dialog.open(BasicPopupYesNoComponent, {
            data: { messageHeader: 'Are all property details completed?', popupText1: '', popupText2: '' }
          });
      
          okDialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              this.openAddInvoice();
            }
          });
        }
        else{
          this.openAddInvoice();
        }
  
      });
    }
    else{
      this.openAddInvoice();
    }
  }

  openAddInvoice(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId
    };

    const dialogRef = this.dialog.open(AddInvoiceComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      console.log('add invoice response', result);

       if(result > 0)
      {
        this.openInvoice(result);
        this.loadInvoices();
      }

      
    });
  }


  sendToKlyant(invoice: any)
  {
    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Are you sure you want to send the invoice to Klyant?', popupText1: 'THE INVOICE WILL NOT BE EDITABLE IN THE PORTAL ONCE SENT TO KLYANT', popupText2: ''}
    });

    dialogRef.afterClosed().subscribe((result : any) => {

    if(result)
    {
      invoice.sendingToClient = true;
      this.klyantService.sendInvoiceToKlyant(this.caseId, invoice.id).subscribe((response) => {
        this.openSnackBar("Invoice Saved to Klyant", 'Close');
        this.loadInvoices();
      }, (error: any) => {      
        this.openSnackBar(error.error, 'Close');
          invoice.sendingToClient = false;      
      });
    }});
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
