import { Component, OnInit, Input } from '@angular/core';
import { faQuestionCircle, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-case-header',
  templateUrl: './case-header.component.html',
  styleUrls: ['./case-header.component.scss']
})
export class CaseHeaderComponent implements OnInit {

  @Input() headingText: string = '';
  @Input() warning = false;
  @Input() alert = false;
  @Input() info = false;
  @Input() overrideBackgroundColour: string = '';
  @Input() message = '';

  faQuestionCircle = faQuestionCircle;
  faExclamationTriangle = faExclamationTriangle;

  constructor() { }

  ngOnInit(): void {
  }

  getBackgroundColour(overrideBackgroundColour: string){

    if(overrideBackgroundColour)
      return overrideBackgroundColour;
    else
      return "";
  }

}
