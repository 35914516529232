import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faHistory, faCheckCircle, faSync, faTimes, faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { DateTime } from 'luxon';
import { AuthService } from '../../../auth/auth.service';
import { BasicPopupYesNoComponent } from '../../../shared/components/basic-popup-yes-no/basic-popup-yes-no.component';
import { CaseService } from '../../../shared/services/case.service';
import { CreditSafecheckService } from '../../../shared/services/credit-safecheck.service';
import { LookupsService } from '../../../shared/services/lookups.service';
import { CreditSafeCheckHistoryComponent } from './credit-safe-check-history/credit-safe-check-history.component';
import { ManualCheckComponent } from './manual-check/manual-check.component';

@Component({
  selector: 'app-credit-safe-check',
  templateUrl: './credit-safe-check.component.html',
  styleUrls: ['./credit-safe-check.component.css']
})
export class CreditSafeCheckComponent implements OnInit {

  faHistory = faHistory;
  faCheckCircle = faCheckCircle;
  faExclamationCircle = faExclamationCircle;
  faSync = faSync;
  faTimes = faTimes;
  caseId: number = 0;
  creditCheckData: any = {};
  clientNo: number = 1;
  titleList: any[] = [];
  showSpinner: boolean = false;
  dataLoaded: boolean = false;

  creditSafeCheck = this.fb.group({
    caseId: null,
    titleId: '',
    tite: '',
    firstName: '',
    otherNames: '',
    surname: '',
    dob: '',
    buildingNo: 0,
    buildingName: '',
    street: '',
    city: '',
    postCode: '',
    clientNo: 0
  }, {});

  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
    public dialogRef: MatDialogRef<CreditSafeCheckComponent>,
    private fb: FormBuilder,
    private caseService: CaseService,
    public dialog: MatDialog,
    private creditSafecheckService: CreditSafecheckService,
    private lookupService: LookupsService,
    public authService: AuthService  ) { }

  ngOnInit(): void {
    this.clientNo = this.componentData.creditCheckData.clientNo;
    this.caseId = this.componentData.creditCheckData.caseId;


    this.loadTitles();
    this.loadCreditCheck();
    
    Object.keys(this.creditSafeCheck.controls).forEach(key => {
      this.creditSafeCheck.get(key)!.markAsTouched();
      this.creditSafeCheck.get(key)!.updateValueAndValidity();
    });
  }

  loadCreditCheck(refreshCase: boolean = false) {

    this.creditSafecheckService.getCreditSafecheckInfo(this.caseId, this.clientNo)
      .subscribe(data => {
        if (data != null) {
          this.creditCheckData = data;
          this.setForm();
          if (refreshCase) {
            this.caseService.refreshCase.next();
          }
        } else {
          // add if doesnt exist
          this.creditCheckData = this.componentData.creditCheckData;
          this.setForm();
          this.creditSafecheckService.addCreditCheck(this.caseId, this.creditSafeCheck.value)
            .subscribe(data => {
              this.loadCreditCheck(refreshCase); // reload yourself :) 
            });
        }

        this.dataLoaded = true;
        
      });
  }

  loadTitles() {
    this.lookupService.getTitles()
      .subscribe(data => {
        this.titleList = data;
      });
  }

  runCreditSafecheck() {
    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Are you sure you want to run AML check against this client?', popupText1: '', popupText2: '' }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.showSpinner = true;

        this.creditSafecheckService.runCreditSafecheck(this.caseId, this.creditSafeCheck.value)
          .subscribe(data => {
            this.showSpinner = false;
            this.loadCreditCheck(true);
          });
      }
    });

    
  }

  runManualCreditSafecheck() {
    const dialogConfig = new MatDialogConfig();

    //dialogConfig.disableClose = true;
    dialogConfig.data = {
      
    };

    const dialogRef = this.dialog.open(ManualCheckComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.creditSafecheckService.addManualCreditSafecheck(this.caseId, this.clientNo, result)
        .subscribe(data => {
          if (data) {
            this.loadCreditCheck();
            this.caseService.refreshCase.next();
          }
        });
    });
  }

  unlock() {
    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Are you sure you want to unlock this client?', popupText1: '', popupText2: '' }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.creditSafecheckService.unlockCreditSafecheck(this.caseId, this.clientNo)
          .subscribe(data => {
            if (data) {
              this.loadCreditCheck(true);
            }
          });
      }
    });

   
  }

  refresh() {
    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Are you sure you want to reset to the original client data?', popupText1: '', popupText2: '' }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.creditCheckData = this.componentData.creditCheckData;
        this.setForm();
      }
    });

   
  }

  updateField() {
    this.creditSafecheckService.editCreditCheck(this.caseId, this.creditSafeCheck.value)
      .subscribe(data => {
        
      });
  }

  openHistory() {
    const dialogConfig = new MatDialogConfig();

    //dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId,
      clientNo: this.clientNo
    };

    const dialogRef = this.dialog.open(CreditSafeCheckHistoryComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }


  close() {
    this.dialogRef.close(null);
  }

  private setForm() {
    this.creditSafeCheck.controls["clientNo"].setValue(this.clientNo);

    this.creditSafeCheck.controls["titleId"].setValue(this.creditCheckData.titleId);
    this.creditSafeCheck.controls["firstName"].setValue(this.creditCheckData.firstName);
    this.creditSafeCheck.controls["otherNames"].setValue(this.creditCheckData.otherNames);
    this.creditSafeCheck.controls["surname"].setValue(this.creditCheckData.surname);
    this.creditSafeCheck.controls["buildingNo"].setValue(this.creditCheckData.buildingNo);
    this.creditSafeCheck.controls["buildingName"].setValue(this.creditCheckData.buildingName);
    this.creditSafeCheck.controls["street"].setValue(this.creditCheckData.street);
    this.creditSafeCheck.controls["city"].setValue(this.creditCheckData.city);
    this.creditSafeCheck.controls["postCode"].setValue(this.creditCheckData.postCode);
    this.creditSafeCheck.controls["dob"].setValue(this.creditCheckData.dateOfBirth);
  }

  onDateChange() {
    this.updateField();
  }

 
  formatDate(inDate: any) {
    var formattedDate = '';


    if (inDate !== null) {
      
      var dt = DateTime.fromISO(inDate);
     
      formattedDate = dt.toFormat("dd-MM-yyyy");

      if (formattedDate == 'Invalid date' || formattedDate == 'Invalid DateTime')
        formattedDate = '';

    }


    return formattedDate;

  }

}
