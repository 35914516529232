import { Component, OnInit, Input } from '@angular/core';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-case-note',
  templateUrl: './case-note.component.html',
  styleUrls: ['./case-note.component.css']
})
export class CaseNoteComponent implements OnInit {

  @Input() caseNote: any;

  faLockAlt = faLockAlt;

  constructor() { }

  ngOnInit(): void {
  }

}
