import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CaseService } from '../../../../shared/services/case.service';
import { FormBuilder } from '@angular/forms';
import { EquityReleasePurchaseEstateAgentDetailsComponent } from '../../estate-agent-details/equity-release-purchase-estate-agent-details/equity-release-purchase-estate-agent-details.component';
import { ComparerService } from '../../../../shared/utility/comparer';
import { AuditService } from '../../../../shared/services/audit.service';
import { faHistory, faExternalLink} from '@fortawesome/pro-light-svg-icons';
import {AuditFormComponent} from '../../../audit-form/audit-form.component'

@Component({
  selector: 'app-equity-release-purchase-property-details',
  templateUrl: './equity-release-purchase-property-details.component.html',
  styleUrls: ['./equity-release-purchase-property-details.component.css']
})
export class EquityReleasePurchasePropertyDetailsComponent implements OnInit {

  caseId: number;
  dataLoaded: boolean = false;
  viewInit = false;
  faHistory = faHistory;
  faExternalLink = faExternalLink;

  propertyDetails = this.fb.group({
    id: [],
    caseId: [],
    purchaseAddressLine1 : [],
    purchaseAddressLine2 : [],
    purchaseAddressLine3 : [],
    purchaseAddressLine4 : [],
    purchaseAddressLine5 : [],
    purchasePostcode : [],
    propertyIsUnregistered : [],
    titleNumber : [],
    propertyType : [],
    landlordName : [],
    landlordAddressLine1 : [],
    landlordAddressLine2 : [],
    landlordAddressLine3 : [],
    landlordAddressLine4 : [],
    landlordAddressLine5 : [],
    landlordPostcode : [],
    managementCompanyName : [],
    managementCompanyAddressLine1 : [],
    managementCompanyAddressLine2 : [],
    managementCompanyAddressLine3 : [],
    managementCompanyAddressLine4 : [],
    managementCompanyAddressLine5 : [],
    managementCompanyPostcode : [],
    solarPanelsPresent : [],
    solarPanelsOwnedOutright  : [],
    solarPanelsCompany  : [],
    solarPanelsOwnerAddressLine1 : [],
    solarPanelsOwnerAddressLine2 : [],
    solarPanelsOwnerAddressLine3 : [],
    solarPanelsOwnerAddressLine4 : [],
    solarPanelsOwnerAddressLine5 : [],
    solarPanelsOwnerPostcode : [],
    hasSepticTank : [],
    agreedPurchasePrice : [],
    stampDuty : [],
    sellerSolicitorName : [],
    sellerSolicitorEmailAddress : [],
    sellerSolicitorContactNumber : [],
    seller1Name : [],
    seller2Name : [],
    sellerSolicitorAddressLine1 : [],
    sellerSolicitorAddressLine2 : [],
    sellerSolicitorAddressLine3 : [],
    sellerSolicitorAddressLine4 : [],
    sellerSolicitorAddressLine5 : [],
    sellerSolicitorPostcode : [],
    sellerSolicitorReference : [],
    titleGuaranteeType: [],
    chattels: []
  }, { });

  initialDetails = this.fb.group({
    id: [],
    caseId: [],
    purchaseAddressLine1 : [],
    purchaseAddressLine2 : [],
    purchaseAddressLine3 : [],
    purchaseAddressLine4 : [],
    purchaseAddressLine5 : [],
    purchasePostcode : [],
    propertyIsUnregistered : [],
    titleNumber : [],
    propertyType : [],
    landlordName : [],
    landlordAddressLine1 : [],
    landlordAddressLine2 : [],
    landlordAddressLine3 : [],
    landlordAddressLine4 : [],
    landlordAddressLine5 : [],
    landlordPostcode : [],
    managementCompanyName : [],
    managementCompanyAddressLine1 : [],
    managementCompanyAddressLine2 : [],
    managementCompanyAddressLine3 : [],
    managementCompanyAddressLine4 : [],
    managementCompanyAddressLine5 : [],
    managementCompanyPostcode : [],
    solarPanelsPresent : [],
    solarPanelsOwnedOutright  : [],
    solarPanelsCompany  : [],
    solarPanelsOwnerAddressLine1 : [],
    solarPanelsOwnerAddressLine2 : [],
    solarPanelsOwnerAddressLine3 : [],
    solarPanelsOwnerAddressLine4 : [],
    solarPanelsOwnerAddressLine5 : [],
    solarPanelsOwnerPostcode : [],
    hasSepticTank : [],
    agreedPurchasePrice : [],
    stampDuty : [],
    sellerSolicitorName : [],
    sellerSolicitorEmailAddress : [],
    sellerSolicitorContactNumber : [],
    seller1Name : [],
    seller2Name : [],
    sellerSolicitorAddressLine1 : [], 
    sellerSolicitorAddressLine2 : [],
    sellerSolicitorAddressLine3 : [],
    sellerSolicitorAddressLine4 : [],
    sellerSolicitorAddressLine5 : [],
    sellerSolicitorPostcode : [],
    sellerSolicitorReference : [],
    titleGuaranteeType: [],
    chattels: []
  }, { });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private caseService: CaseService,
              private fb: FormBuilder,
              public dialogRef: MatDialogRef<EquityReleasePurchasePropertyDetailsComponent>,
              public dialog: MatDialog,
              private comp: ComparerService,
              private auditService: AuditService) {

    this.caseId = data.caseId;

    this.caseService.getEquityReleasePurchasePropertyDetails(this.caseId).subscribe((response : any) => {

      console.log(response.propertyDetails);
      this.propertyDetails.patchValue(response.propertyDetails);
      this.initialDetails.patchValue(response.propertyDetails);
      this.dataLoaded = true;
    });
  }

  ngOnInit(): void {
  }

  ngAfterContentInit() : void {
    this.viewInit = true;
  }


  updateField(){

    this.caseService.updateEquityReleasePurchasePropertyDetails(this.propertyDetails.value).subscribe((response : any) => {

    }, (error : any) => {


    });
  }

  openEstateAgent(){
    //this.dialogRef.close(null);
    this.close();

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
      dialogConfig.data = {
      caseId: this.caseId
    };

    this.dialog.open(EquityReleasePurchaseEstateAgentDetailsComponent, dialogConfig);
  }

  close(){

    if(this.comp.objectsDifferent(this.initialDetails.value, this.propertyDetails.value))
    {
      this.auditService.auditForm(this.caseId, 'PropertyDetails', this.initialDetails.value, this.propertyDetails.value).subscribe((response : any) => {
        this.dialogRef.close();
      });
    }
    else
      this.dialogRef.close();
  }

  openAuditForForm(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      caseId: this.caseId,
      formName: "PropertyDetails"
    };

    this.dialog.open(AuditFormComponent, dialogConfig);

  }

}
