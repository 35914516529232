import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  emailAddress?: string;
  password?: string;
  subscription?: Subscription;
  loggingIn: boolean = false;
  errorMessage?: string;


  constructor( public authService: AuthService,public route: ActivatedRoute, public router: Router, private snackBar: MatSnackBar) {

    this.subscription = authService.isLogin.subscribe(loggedIn => {

      if (!loggedIn) {
        this.loggingIn = false;
      } else if (loggedIn) {

        this.loggingIn = true;
        if(this.router.url == '/login') {
          this.router.navigateByUrl('/');
        }
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe;
    }
  }

  login() {

    this.loggingIn = true;

    this.authService.login2({ emailAddress: this.emailAddress, password: this.password }).subscribe( result => {

      this.loggingIn = true;
      this.router.navigateByUrl('/');
    }, error => {
      this.errorMessage = "Login Failed";
      this.loggingIn = false;
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
