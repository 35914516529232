import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { CaseService } from '../shared/services/case.service';

@Component({
  selector: 'app-off-hold',
  templateUrl: './off-hold.component.html',
  styleUrls: ['./off-hold.component.css']
})
export class OffHoldComponent implements OnInit {

  private caseId: number;
  isLoading: boolean = false;

  offHoldDetails= new FormGroup({
    offHoldReason: new FormControl()
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<OffHoldComponent>,
  private caseService: CaseService) {
    this.caseId = this.data['caseId'];
  }

  ngOnInit(): void {
  }

  takeCaseOffHold(){

    this.isLoading = true;
    this.caseService.offHoldCase(this.caseId, this.offHoldDetails.value).subscribe(response => {

      this.dialogRef.close(true);

      }, error => {

        var errorMessage = "Failed to take case off hold";
        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }

      });

  }

  cancel(){
    this.dialogRef.close(null);
  }
}
