<div class="body">

    <div class="sidebar">
      <app-dashboard-sidebar></app-dashboard-sidebar>
    </div>

    <div class="content">
        <app-calendar-month *ngIf="monthViewEnabled && !isMobile" [(monthViewEnabled)]="monthViewEnabled" [(selectedDay)]="currentDate"></app-calendar-month>
        <app-calendar-day *ngIf="!monthViewEnabled || isMobile" [(monthViewEnabled)]="monthViewEnabled" [isMobile]="isMobile" [(selectedDay)]="currentDate"></app-calendar-day>

    </div>

  </div>



