import { Component, OnInit, Inject } from '@angular/core';
import { faPlus, faTrashAlt, faHistory, faClipboard } from '@fortawesome/pro-light-svg-icons';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CaseService } from '../../../shared/services/case.service';
import { AuthService } from '../../../auth/auth.service';
import { Subscription } from 'rxjs';
import { AddCaseOverviewItemComponent } from './add-case-overview-item/add-case-overview-item.component';
import { CaseOverviewHistoryComponent } from './case-overview-history/case-overview-history.component';
import { BasicPopupYesNoComponent } from '../../../shared/components/basic-popup-yes-no/basic-popup-yes-no.component';

@Component({
  selector: 'app-case-overview',
  templateUrl: './case-overview.component.html',
  styleUrls: ['./case-overview.component.css']
})
export class CaseOverviewComponent implements OnInit {

  viewInit = false;
  items: any;
  caseId: number;

  faPlus = faPlus;
  faTrashAlt = faTrashAlt;
  faHistory = faHistory;
  faClipboard = faClipboard;

  refreshSubscription: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
  public dialog: MatDialog,
  private caseService: CaseService,
  public authService: AuthService) {

    this.caseId = componentData.caseId;

    this.refreshSubscription = this.caseService.refreshCaseOverviewObservable.subscribe(() => {
      this.loadCaseOverview();
    });

  }


  ngOnInit(): void {
  }

  ngAfterContentInit() : void {
    this.viewInit = true;
    this.loadCaseOverview();
  }

  loadCaseOverview(){

    if (!this.viewInit) return;

    this.caseService.getCaseOverviewItems(this.caseId).subscribe(result => {
      this.items = result;
    }, error => {

      var errorMessage = "Failed to get items";
      if (error.error !== null && error.error.message !== undefined) {
        errorMessage = error.error.message;
      }

    });
  }

  addOverviewItem(){
    var dialogRef = this.dialog.open(AddCaseOverviewItemComponent, {
      data: { caseId: this.caseId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true)
      {
        this.loadCaseOverview();
      }
    });
  }

  removeOverviewItem(id: number){

    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Are you sure you want to remove this item?', popupText1: '', popupText2: ''}
    });

    dialogRef.afterClosed().subscribe(result => {

      if(!result) // call to trigger event to fire auto email
      {}
      else{

        this.caseService.deleteCaseOverviewItem(this.caseId, id).subscribe(result => {
          this.loadCaseOverview();
        });
      }
    });


  }

  viewHistory(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      caseId: this.caseId
    };
    dialogConfig.maxWidth = '60vw';
    dialogConfig.maxHeight = '60vh';
    dialogConfig.width = '60vw';
    dialogConfig.height = '60vh';

    var dialogRef = this.dialog.open(CaseOverviewHistoryComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnDestroy(){
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

}
