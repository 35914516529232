import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faHistory, faCheckCircle, faQuestionCircle, faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { DateTime } from 'luxon';
import { CaseService } from '../../../../shared/services/case.service';
import { CreditSafecheckService } from '../../../../shared/services/credit-safecheck.service';

@Component({
  selector: 'app-credit-safe-check-history',
  templateUrl: './credit-safe-check-history.component.html',
  styleUrls: ['./credit-safe-check-history.component.css']
})
export class CreditSafeCheckHistoryComponent implements OnInit {

  historyData: any[] = [];
  clientNo: number = 1;
  caseId: number = 0;
  faExclamationCircle = faExclamationCircle;
  faCheckCircle = faCheckCircle;

  displayedColumns: string[] = ['faStatus', 'checkResult', 'checkPerformedDate', 'checkPerformedBy', 'isManual', 'checkResultString'];


  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
    public dialogRef: MatDialogRef<CreditSafeCheckHistoryComponent>,
    private fb: FormBuilder,
    private caseService: CaseService,
    public dialog: MatDialog,
    private creditSafecheckService: CreditSafecheckService) { }

  ngOnInit(): void {
    this.clientNo = this.componentData.clientNo;
    this.caseId = this.componentData.caseId;

    this.loadHistory();
  }

  loadHistory() {
    this.creditSafecheckService.getCreditSafecheckHistory(this.caseId, this.clientNo)
      .subscribe(data => {
        this.historyData = data.filter(x => x.cancelledDate != null);
      });
  }

}
