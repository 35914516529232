<div class="container mat-typography mat-elevation-z1">

  <div class="title-wrapper" *ngIf="cardTitle">
    <h2>{{cardTitle}}</h2>
  </div>

  <div class="body">
    <div class="main">
      <ng-content select="[card-body]"></ng-content>
    </div>
  </div>

  <div class="footer">
    <ng-content select="[card-footer]"></ng-content>
  </div>

</div>
