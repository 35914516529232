<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Archived Items</h2>

<mat-dialog-content class="mat-typography" >

    <div *ngIf="archivedItems.length == 0">
      No archived items
    </div>


    <div *ngIf="archivedItems.length > 0">

        <table mat-table [dataSource]="archivedItems" >

            <ng-container matColumnDef="correspondenceType">
              <mat-header-cell *matHeaderCellDef > Type. </mat-header-cell>
              <mat-cell *matCellDef="let item" > {{item.correspondenceType}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="source">
              <mat-header-cell *matHeaderCellDef > Source. </mat-header-cell>
              <mat-cell *matCellDef="let item" > {{item.source}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="summary">
                <mat-header-cell *matHeaderCellDef style="min-width: 200px;"> Summary. </mat-header-cell>
                <mat-cell *matCellDef="let item" style="min-width: 200px;"> {{item.summary}}</mat-cell>
              </ng-container>

            <ng-container matColumnDef="dateCreated">
              <mat-header-cell *matHeaderCellDef> Date Created. </mat-header-cell>
              <mat-cell *matCellDef="let item"> {{formatDateWithTime(item.dateCreated)}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="addedByUser">
                <mat-header-cell *matHeaderCellDef> Added By. </mat-header-cell>
                <mat-cell *matCellDef="let item"> <span *ngIf="item.addedByUser">{{item.addedByUser.firstName}} {{item.addedByUser.lastName}} </span></mat-cell>
              </ng-container>

            <ng-container matColumnDef="archivedDate">
                <mat-header-cell *matHeaderCellDef> Date Archived. </mat-header-cell>
                <mat-cell *matCellDef="let item"> {{formatDateWithTime(item.archivedDate)}} </mat-cell>
              </ng-container>

               <ng-container matColumnDef="archivedByUser">
                <mat-header-cell *matHeaderCellDef> Archived By. </mat-header-cell>
                <mat-cell *matCellDef="let item"> <span *ngIf="item.archivedByUser">{{item.archivedByUser.firstName}} {{item.archivedByUser.lastName}} </span></mat-cell>
              </ng-container>

              <ng-container matColumnDef="archivedReason">
                  <mat-header-cell *matHeaderCellDef > Reason. </mat-header-cell>
                  <mat-cell *matCellDef="let item" > {{item.archivedReason}}</mat-cell>
                </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
            <mat-row *matRowDef="let keyDate; columns: displayedColumns;" ></mat-row>

        </table>
  </div>
</mat-dialog-content>
