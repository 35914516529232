<form class="form" [formGroup]="form" [ngClass]="{'inline': inline}">
  <mat-form-field class="form-field" appearance="outline">
    <input type="text" placeholder="Search" aria-label="Search" matInput formControlName="search"
      [matAutocomplete]="auto">

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">

      <mat-option *ngIf="isLoading" class="is-loading">
        <mat-spinner diameter="20"></mat-spinner>
      </mat-option>
      <ng-container *ngIf="!isLoading && searchTypeID == 1">
        <mat-option *ngFor="let case of filteredItems" [value]="case" (click)="gotoResult(case.id)">
          <span>
              <span *ngIf="!inline"> {{ case.caseTypeName }} -</span>
              <span *ngIf="inline"> {{ case.type.caseTypeName }} -</span>
             {{ case.caseReference }} - {{ case.partyOneFirstName }} {{case.partyOneLastName }}
            <span *ngIf="case.partyTwoFirstName">& {{ case.partyTwoFirstName }} {{case.partyTwoLastName }} </span>
            ({{ case.paralegalUserFirstName }} {{ case.paralegalUserLastName }}, {{
            case.financialAdviserFirstName }} {{ case.financialAdviserLastName }})
            <span *ngIf="!inline"> - {{case.postcode}}</span>
            <span *ngIf="inline"> - {{case.partyOnePostcode}}</span>
          </span>
          <span *ngIf="!inline"> - {{ case.caseStatusTypeText }}</span>
        </mat-option>
      </ng-container>

      <ng-container *ngIf="!isLoading && searchTypeID == 2 ">
        <mat-option *ngFor="let user of filteredItems" [value]="user" (click)="gotoResult(user.id)">
          <span>{{ user.firstName }} {{ user.lastName }} - {{user.emailAddress}} - {{user.roleName}} </span>
        </mat-option>
      </ng-container>

      <ng-container *ngIf="!isLoading && searchTypeID == 3">
        <mat-option *ngFor="let user of filteredItems" [value]="user" (click)="gotoResult(user.id)">
          <span>{{ user.firstName }} {{ user.lastName }} - {{ user.companyGroup.name }} - {{user.emailAddress}} -
            {{user.roleName}} </span>
        </mat-option>
      </ng-container>

      <ng-container *ngIf="!isLoading && searchTypeID == 4">
        <mat-option *ngFor="let company of filteredItems" [value]="company"
        (click)="gotoResult(company.id)">
          <span>{{ company.name }}</span>
        </mat-option>
      </ng-container>

      <ng-container *ngIf="!isLoading && searchTypeID == 5">
        <mat-option *ngFor="let consultant of filteredItems" [value]="consultant"
        (click)="gotoResult(consultant.id)">
          <span>{{ consultant.name }} - {{ consultant.emailAddress }} - {{ consultant.postcode }} - {{
            consultant.borough }}</span>
        </mat-option>
      </ng-container>

    </mat-autocomplete>

  </mat-form-field>
</form>
