import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SearchRequest } from './interfaces/search-request';
import { SearchResult } from './interfaces/search-result';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class ConsultantsService {

  private url = environment.apiUrl;

  constructor(private http: HttpClient, public router: Router) {

  }

  getActiveConsultants() {
    return this.http.get<any>(this.url + 'externalconsultants/activeconsultants');
  }

  getConsultants(request: SearchRequest) {

    if (request.filterStrings.join() == '') {
      return this.http.get<SearchResult>(`${this.url}externalconsultants?page=${request.page}&pageSize=${request.pageSize}&orderBy=
        ${request.orderBy}&orderDirection=${request.orderDirection}`);
    }

    var queryString = '';

    for (var i = 0; i < request.filterStrings.length; i++) {
      if (request.filterStrings[i] !== '' && request.filterStrings[i] !== null) {
        queryString += `&filterColumns=${request.filterColumns[i]}&filterStrings=${request.filterStrings[i]}`
      }
    }

    return this.http.get<SearchResult>(`${this.url}externalconsultants?page=${request.page}&pageSize=${request.pageSize}&orderBy=
      ${request.orderBy}&orderDirection=${request.orderDirection}${queryString}`);

  }



  addConsultant(consultantDetails: any) {
    return this.http.put<any>(this.url + 'externalconsultants', consultantDetails, httpOptions);
  }

  updateConsultant(id: (number | undefined), consultantDetails: any) {
    return this.http.post<any>(this.url + 'externalconsultants/' + id, consultantDetails, httpOptions);
  }

  consultantDetails(id: number) {
    return this.http.get<any>(this.url + 'externalconsultants/' + id);
  }

}
