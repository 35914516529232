import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { InvoiceService } from '../../../../shared/services/invoice.service';
import { InvoiceComponent } from '../invoice/invoice.component';


@Component({
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.scss']
})
export class AddInvoiceComponent implements OnInit {

  caseId : number;
  caseTypeId : number;
  isLoading: boolean = false;
  invoiceDescription: string = 'Client Invoice';

  constructor(public dialog: MatDialog,
    private invoiceService: InvoiceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddInvoiceComponent>) { 
      this.caseId = data.caseId;
      this.caseTypeId = data.caseTypeId;
    }


  ngOnInit(): void {
  }

  addInvoice(){

    this.isLoading = true;
    this.invoiceService.addInvoice( this.caseId, this.invoiceDescription ).subscribe((response : any) => {
 
      this.dialogRef.close(response.invoice.id);

    });


  }



}
