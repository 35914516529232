<app-card-label label="Allowances">
    <button mat-button color="primary" (click)="openAddAllowance()">Add</button>
</app-card-label>
<br />
<div *ngFor="let allowance of allowances" class="allowance-item">
    <app-card-label label="{{allowance.description}}">
        <span matPrefix>£ &nbsp;</span>
        <mat-form-field floatLabel="never">
            <input matInput type="number" value="{{allowance.amount}}" readonly />            
        </mat-form-field>
        <button mat-icon-button><fa-icon [icon]="faTimes" (click)="deleteAllowance(allowance.allowanceId)"></fa-icon></button>
    </app-card-label>
</div>
