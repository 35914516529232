<div class="wrap" *ngIf="addresses.length > 0 || searching || postcodeAddresses.length > 0">

  <div *ngIf="searching">
    <mat-spinner color="primary" diameter="20" style="margin: auto;"></mat-spinner>
  </div>

  <div *ngIf="addresses.length > 0">
    <div *ngFor="let address of addresses">
      <a matRipple (click)="select(address.id)">
        <div class="address">
          {{address.building_Name + address.number + ' ' + address.street + ', ' + address.addressLine2 + ', ' + address.addressLine3 + ', ' + address.addressLine4 + ', ' + address.addressLine5}}
        </div>
      </a>
    </div>
  </div>

  <div *ngIf="postcodeAddresses.length > 0">
    <div *ngFor="let address of postcodeAddresses">
      <a matRipple (click)="selectPostcode(address)">
        <div matRipple class="address">
          {{address.building_Name + address.number + ' ' + address.street + ', ' + address.addressLine2 + ', ' + address.addressLine3 + ', ' + address.addressLine4 + ', ' + address.addressLine5}}</div>
      </a>
    </div>
  </div>

</div>
