<form [formGroup]="userDetails" autocomplete="off" ngClass.xs="size-xs" >
    <app-card-basic [cardTitle]="sectionTitle">
      <div card-body>

        <app-card-label label="First Name">
          <mat-form-field floatLabel="never">
            <input matInput #firstName placeholder="First Name" formControlName="firstName">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Last Name">
          <mat-form-field floatLabel="never">
            <input matInput #lastName placeholder="Last Name" formControlName="lastName">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Email Address">
          <mat-form-field floatLabel="never">
            <input matInput #emailAddress placeholder="Email Address" formControlName="emailAddress">
          </mat-form-field>
        </app-card-label>

        <span [hidden]="internalUser">
          <app-card-label label="Company Group">
            <mat-form-field floatLabel="never">
              <mat-select placeholder="Select Group" formControlName="companyGroupId" >
                <mat-option [value]="0"></mat-option>
                <mat-option *ngFor="let group of companyGroups" [value]="group.id">{{group.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </app-card-label>
        </span>

        <app-card-label label="Role">
          <mat-form-field floatLabel="never">
            <mat-select placeholder="Select Role" formControlName="role" (selectionChange)="updateTeamLeaders()">
              <mat-option [value]="0"></mat-option>
              <mat-option *ngFor="let role of roles" [value]="role.roleID">{{role.roleName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </app-card-label>

        <span [hidden]="!internalUser || userDetails.controls['role'].value ==  6">

          <app-card-label label="Are they a Team Leader?">
            <app-yes-no formControlName="isTeamLeader"></app-yes-no>
          </app-card-label>

          <app-card-label label="Select their Team Leader" [hidden]="userDetails.controls['isTeamLeader'].value ==  1">
            <mat-form-field floatLabel="never">
              <mat-select placeholder="Select Team Leader" formControlName="teamLeaderUserId">
                <mat-option *ngFor="let teamLeader of teamLeaders" [value]="teamLeader.id">{{teamLeader.firstName}} {{teamLeader.lastName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </app-card-label>

          <span [hidden]="userDetails.controls['role'].value != 5 || userDetails.controls['isTeamLeader'].value != 1">
            <app-card-label label="Can Allocate Cases?">
              <app-yes-no formControlName="canAllocateCases"></app-yes-no>
            </app-card-label>

            <app-card-label label="Can Reallocate Cases?">
              <app-yes-no formControlName="canReallocateCases"></app-yes-no>
            </app-card-label>

            <app-card-label label="Can See Admin Actions?">
              <app-yes-no formControlName="canSeeAdminActions"></app-yes-no>
            </app-card-label>
          </span>


        </span>

        <span [hidden]="!internalUser">
          <app-card-label label="Are they an AML Officer?">
            <app-yes-no formControlName="isAMLOfficer"></app-yes-no>
          </app-card-label>
        </span>

        <span [hidden]="!internalUser">
            <app-card-label label="Position">
                <mat-form-field floatLabel="never">
                  <input matInput #position placeholder="Position" maxlength="50" formControlName="position">
                </mat-form-field>
              </app-card-label>
          </span>

          <span [hidden]="!internalUser">
            <app-card-label label="Klyant Client ID">
                <mat-form-field floatLabel="never">
                  <input matInput #klyantApiClientId type="number" placeholder="Client ID" formControlName="klyantApiClientId">
                </mat-form-field>
              </app-card-label>
          </span>

        <span [hidden]="!internalUser">
            <app-card-label label="Klyant API Secret">
                <mat-form-field floatLabel="never">
                  <input matInput #klyantApiSecret placeholder="API Secret" formControlName="klyantApiSecret">
                </mat-form-field>
              </app-card-label>
          </span>



        <span [hidden]="internalUser">
          <app-card-label label="Has API Access">
            <app-yes-no formControlName="hasAPIAccess"></app-yes-no>
          </app-card-label>

          <app-card-label label="Application Key" [hidden]="userDetails.controls['hasAPIAccess'].value != 1">
            <mat-form-field floatLabel="never">
              <input matInput #applicationKey placeholder="Key" maxlength="250" formControlName="applicationKey">
            </mat-form-field>
          </app-card-label>
        </span>

        <span [hidden]="!internalUser">
            <app-card-label label="Initial Colour on Case Timeline">
        <mat-form-field>
            <input matInput [ngxMatColorPicker]="picker" [formControl]="colorCtr" readonly>
            <ngx-mat-color-toggle matSuffix [for]="picker" [style.background-color]="colorCtr.value?.rgba"></ngx-mat-color-toggle>
            <ngx-mat-color-picker #picker [touchUi]="touchUi" [color]="color"></ngx-mat-color-picker>
          </mat-form-field>
        </app-card-label>
      </span>

        <app-card-label label="Account Disabled">
          <app-yes-no formControlName="accountDisabled"></app-yes-no>
        </app-card-label>

        <app-card-label label="Last Login:">
          <app-card-label [label]="lastLoginTime">
          </app-card-label>
        </app-card-label>

      </div>
    </app-card-basic>
  </form>

  <div class="flex" *ngIf="!newUser">
    <span class="spacer"></span>
    <button *ngIf="!sendingPasswordReset" mat-raised-button (click)="resetPassword()" color="primary">Reset
      Password</button>
    <button *ngIf="sendingPasswordReset" mat-icon-button disabled color="primary" style="float: right;">
      <mat-icon>
        <mat-spinner color="primary" diameter="20">
        </mat-spinner>
      </mat-icon>
    </button>
    <button mat-raised-button (click)="saveUser()" color="primary">Update</button>
  </div>

  <div class="flex" *ngIf="newUser">
    <span class="spacer"></span>
    <button *ngIf="!sendingInvitation" mat-raised-button (click)="addUser()" color="primary" [disabled]="userDetails.invalid">Send Email Invitation
      <fa-icon [icon]="faEnvelope"></fa-icon></button>
    <button *ngIf="sendingInvitation" mat-icon-button disabled color="primary" style="float: right;">
      <mat-icon>
        <mat-spinner color="primary" diameter="20">
        </mat-spinner>
      </mat-icon>
    </button>
  </div>


