import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class SupplierInvoiceService {

  private url = environment.apiUrl + 'supplierinvoices/';

  constructor(private http: HttpClient, public router: Router) {
  }


  uploadFile(file: File, matterNumber: string) {

    let formData = new FormData();
    formData.append('files', file);
    formData.append('matterNumber', matterNumber);

    return this.http.post(this.url, formData);

  }

}