<h1><span *ngIf="!editMode">Add</span><span *ngIf="editMode">Edit</span> Case Note</h1>

<form autocomplete="off" [formGroup]="notesForm">

    <mat-form-field>
      <textarea autosize matInput #caseNoteText placeholder="Notes" rows="10" formControlName="caseNoteText"></textarea>
    </mat-form-field>

    <div>
      <mat-checkbox  [checked]="showOnPortal1" (change)="toggle(!showOnPortal1)"> Add to Portal 1
      </mat-checkbox>
    </div>

    <div class="buttons">

      <button *ngIf="!isLoading" mat-stroked-button [disabled]="false" color="warn" type="button" (click)="cancel()">Cancel</button>
      <button *ngIf="!isLoading && editMode"  mat-stroked-button [disabled]="false" color="primary" class="edit" (click)="updateCaseNote()">Update</button>
      <button *ngIf="!isLoading && !editMode" mat-stroked-button [disabled]="false" color="primary" class="save" (click)="saveNotes()">Save</button>

      <button *ngIf="isLoading" mat-icon-button disabled color="primary" class="right" >
        <mat-icon>
            <mat-spinner color="primary" diameter="20">
            </mat-spinner>
        </mat-icon>
      </button>

    </div>

  </form>
