<div ngClass.xs="size-xs" class="container mat-typography">
  <div class="main">

    <div [ngClass]="{'logo': isProd, 'logo-training': !isProd && !isStaging, 'logo-staging': !isProd && isStaging}" routerLink="/"></div> 
    <span class="cms">CMS</span>
    <div class="spacer"></div>

    <!--  -->
    <div *ngIf="!isSmall && loggedIn" class="search">
      <app-searchbar searchType="Cases"></app-searchbar>
    </div>

    <span *ngIf="loggedIn" >
      <button mat-button [matMenuTriggerFor]="caseList" [matMenuTriggerData]="recentCases" (click)="loadRecentCases()">Recent Cases</button>
      <mat-menu #caseList="matMenu" yPosition="below" >
          <ng-template matMenuContent>
            <ng-container *ngFor="let case of recentCases" >
                <button mat-menu-item  [routerLink]="['case', case.caseId]">{{case.caseReference}} | {{case.partyOneFullName}} | {{case.typeName}}</button>
            </ng-container>

          </ng-template>

      </mat-menu>
    </span>
    <div class="spacer"></div>
    <div class="username" *ngIf="loggedIn">
      <button mat-button (click)="openNotifications()" class="notificationbutton">
          <fa-icon [icon]="faBell" size="lg" *ngIf="!unacknowledgedNotifications"></fa-icon>
          <fa-icon [icon]="faBellOn" size="lg" *ngIf="unacknowledgedNotifications" class="unacknowledgedNotifications"></fa-icon>
      </button>
      {{authService.getName()}}
    </div>
    <!--div class="menu">
      <button mat-button *ngIf="isSmall" (click)="clickMenu()">
        <fa-icon [icon]="faBars" size="lg"></fa-icon>
      </button>
    </div-->

  </div>
  <div class="foot bg-accent">

  </div>

</div>

<app-notifications *ngIf="showNotifications" (closeNotifications)="closeNotifications()">

</app-notifications>
