import { Component, OnInit, Inject } from '@angular/core';
import { CorrespondenceTimelineService } from '../../../../shared/services/correspondence-timeline.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-archived-timeline-item-list',
  templateUrl: './archived-timeline-item-list.component.html',
  styleUrls: ['./archived-timeline-item-list.component.css']
})
export class ArchivedTimelineItemListComponent implements OnInit {

  caseId: number;
  archivedItems: any[] = [];

  displayedColumns: string[] = ['correspondenceType', 'source', 'summary', 'dateCreated', 'addedByUser', 'archivedDate', 'archivedByUser', 'archivedReason'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private correspondenceTimelineService: CorrespondenceTimelineService) {

    this.caseId = this.data.caseId;

    this.correspondenceTimelineService.getArchivedCorrespondenceTimelineItems(this.caseId).subscribe(response => {
      this.archivedItems = response.items;
    });
  }

  ngOnInit(): void {
  }

  formatDateWithTime(inDate : any){

    var formattedDate = '';


     if(inDate !== null)
     {

       var dt = DateTime.fromISO(inDate);

       formattedDate = dt.toFormat("dd-MM-yyyy HH:mm:ss");

     if(formattedDate == 'Invalid date')
       formattedDate = '';

     }


     return formattedDate;

   }
}
