<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Transactions
  <button mat-icon-button tabindex="-1" color="primary" (click)="addTransaction()"><fa-icon [icon]="faPlus" ></fa-icon></button>
</h2>


<mat-dialog-content class="mat-typography">
    <div class="list">
      <div class="box">
        <div class="transaction">
          <div class="transactionItem">Type</div>          
          <div class="transactionItem">Method</div>          
          <div class="transactionItem">Date</div>
          <div class="transactionItem">A/c From</div>
          <div class="transactionItem">A/c To</div>
          <div class="transactionItem">Received Date</div>
          <div class="transactionItem">Received From</div>
          <div class="transactionItem">Narrative</div>
          <div class="transactionItem">Invoice No</div>
          <div class="net">Value</div>          
          <div class="actions">&nbsp;</div>
        </div>
      </div>
      <div class="box" *ngFor="let transaction of transactionsAdded; let i = index" [ngClass]="{'transaction-sent': transaction.klyantId != null, 'transaction-ready': transaction.klyantId == null }">
        <div class="transaction">
          <div class="transactionItem">{{transaction.type}}</div>
          <div class="transactionItem" *ngIf="transaction.transactionTypeId != null">{{transaction.transactionType.type}} <span *ngIf="transaction.transactionTypeOther != null">({{transaction.transactionTypeOther}})</span><span *ngIf="transaction.transactionType.type =='Cheque' && transaction.chequeDetails != null && transaction.chequeDetails.length > 0">({{transaction.chequeDetails}})</span></div>
          <div class="transactionItem" *ngIf="transaction.transactionTypeId == null">&nbsp;</div>
          <div class="transactionItem">{{transaction.transactionDateTime | date:'dd/MM/yyyy'}}</div>
          <div class="transactionItem"><span *ngIf="transaction.transactionDirection == 2">{{getClientAccountName(transaction.fromBankAccountId)}}</span><span *ngIf="transaction.transactionDirection == 3">{{getClientAccountName(transaction.fromBankAccountId)}}</span></div>
          <div class="transactionItem"><span *ngIf="transaction.transactionDirection == 1">{{getClientAccountName(transaction.toBankAccountId)}}</span><span *ngIf="transaction.transactionDirection == 2">{{getOfficeAccountName(transaction.toBankAccountId)}}</span></div>
          <div class="transactionItem">{{transaction.fundsReceivedDate | date:'dd/MM/yyyy'}}</div>
          <div class="transactionItem">{{transaction.fundsReceivedFrom}}</div>
          <div class="transactionItem">{{transaction.narrative}}</div>
          <div class="transactionItem">{{transaction.invoice?.invoiceNumber}}</div>
          <div class="net">{{transaction.value | currency:'£'}}</div>          
          <div class="actions">
              <button mat-icon-button><fa-icon [icon]="faTimes" (click)="removeTransaction(transaction)"></fa-icon></button>
              <fa-icon *ngIf="transaction.klyantId == null && !transaction.sendingToKlyant" [icon]="faShare" (click)="sendToKlyant(transaction)"></fa-icon>
          </div>
        </div>
      </div>
    </div>
    <div><strong>Balance: {{balance | currency:'£'}}</strong></div>

</mat-dialog-content>
