<div mat-dialog-content class="mat-typography">

  <h1 mat-dialog-title>{{title}}</h1>
  <div mat-dialog-content>
    <div class="header-row">
      <div class="name">Name</div>
      <div class="name">Postcode</div>
      <div class="name">County</div>
      <div class="name">Days/wk</div>
      <div class="name">Days</div>
      <div class="name">Miles Willing to Travel</div>
      <div class="dist">Distance To Client</div>
    </div>
    <div class="rows" *ngIf="externalConsultants.length > 0 && loading == false">
      <div class="row" *ngFor="let consultant of externalConsultants"
        (click)="close(consultant.id, consultant.name)">
        <div class="name">{{consultant.name}}</div>
        <div class="name">{{consultant.postcode}}</div>
        <div class="name">{{consultant.borough}}</div>
        <div class="name">{{consultant.numberDaysWorkedPerWeek}}</div>
        <div class="name">{{consultant.daysOfTheWeekWorked}}</div>
        <div class="name">{{consultant.milesWillingToTravel}}</div>
        <div class="dist">{{consultant.distanceToClient}} <span>mile</span><span
            *ngIf="consultant.distanceToClient > 1">s</span>&nbsp;<span
            *ngIf="consultant.distanceToClientDate">({{consultant.distanceToClientDate | date:'dd/MM/yyyy'}})</span>
        </div>
      </div>
    </div>
    <div class="header-row" *ngIf="externalConsultants.length == 0 || loading == true">
      <div class="info" *ngIf="externalConsultants.length == 0 && loading == false">No consultants nearby</div>
      <div class="info" *ngIf="loading == true">
        <mat-spinner color="primary" diameter="20"></mat-spinner>
      </div>
    </div>
    <div class="buttons">
      <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
      <div class="spacer"></div>
    </div>
  </div>

</div>
