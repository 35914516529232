<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>

<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Edit {{feeTypeName}}</h2>

<mat-dialog-content class="mat-typography">

    <mat-dialog-content class="mat-typography">



          <!--div class="itemRow">
              <label class="labelItem">Item Type</label>
              <label class="itemString">{{invoiceItem.invoiceItemType.description}}</label>
            </div-->

          <form [formGroup]="editInvoiceItemDetails" autocomplete="off">
          <app-card-label label="Description (as it appears on invoice)">
              <mat-form-field floatLabel="never">
                <textarea matInput formControlName="description" > </textarea>
              </mat-form-field>
            </app-card-label>

          <app-card-label label="Net">
              <mat-form-field floatLabel="never">
               <span matPrefix>£ &nbsp;</span>
               <input matInput formControlName="net" type="number" >
              </mat-form-field>
            </app-card-label>

            <app-card-label label="Add VAT">
              <mat-checkbox formControlName="addVat" > </mat-checkbox>
            </app-card-label>     
            
          </form>

    </mat-dialog-content>

</mat-dialog-content>

<div mat-dialog-actions align="end">
    <button mat-stroked-button  cdkFocusInitial color="primary" (click)="editInvoiceItem()">Edit</button>
</div>
