<div class="body">

  <div class="sidebar">
    <app-dashboard-sidebar></app-dashboard-sidebar>
  </div>

  <div class="content">

      <form [formGroup]="searchCriteria" autocomplete="off" ngClass.xs="size-xs">

          <app-card-basic cardTitle="Search">

            <div card-body>

              <div fxLayout="row wrap">


                <div fxFlex="16%">
                  <app-card-label label="Case Reference">
                    <mat-form-field floatLabel="never">
                      <input matInput #caseReference placeholder="Case Reference" maxlength="250" formControlName="caseReference">
                    </mat-form-field>
                  </app-card-label>
                </div>

                <div fxFlex="16%">
                  <app-card-label label="To Email">
                    <mat-form-field floatLabel="never">
                      <input matInput #toEmail placeholder="To Email" maxlength="250" formControlName="toEmail">
                    </mat-form-field>
                  </app-card-label>
                </div>

                <div fxFlex="16%">
                  <app-card-label label="Queued Date From">
                    <mat-form-field floatLabel="never">
                      <input matInput [matDatepicker]="picker1" formControlName="queuedDateFrom" placeholder="Date From" >
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1 startView="multi-year"></mat-datepicker>
                      <mat-hint align="end">dd/mm/yyyy</mat-hint>
                    </mat-form-field>
                  </app-card-label>
                </div>

                <div fxFlex="16%">
                  <app-card-label label="Queued Date To">
                    <mat-form-field floatLabel="never">
                      <input matInput [matDatepicker]="picker2" formControlName="queuedDateTo" placeholder="Date To">
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-datepicker #picker2 startView="multi-year"></mat-datepicker>
                      <mat-hint align="end">dd/mm/yyyy</mat-hint>
                    </mat-form-field>
                  </app-card-label>
                </div>

                <div fxFlex="16%">
                  <app-card-label label="From User">
                    <mat-form-field floatLabel="never">
                      <mat-select placeholder="Select Person" formControlName="fromUserId" >
                        <mat-option [value]=""></mat-option>
                        <mat-option *ngFor="let user of internalUsers" [value]="user.id">{{user.fullName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </app-card-label>
                </div>

              </div>

              <button mat-stroked-button type="button" color="primary" (click)="doSearch()">Search</button>
              <!--button mat-stroked-button type="button" color="warning" (click)="doClear()">Clear</button-->

            </div>
          </app-card-basic>

        </form>

        <app-card-basic cardTitle="{{resultsTitle}}">
          <div card-body>

            <table mat-table [dataSource]="results" matSort matSortActive="queuedDate" matSortDisableClear matSortDirection="desc" width="100%">

              <ng-container matColumnDef="caseReference" >
                <mat-header-cell *matHeaderCellDef class="caseref">
                    <span>Case Ref.</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let email" class="caseref"> <span *ngIf="email.case"> {{email.case.caseReference}} </span> </mat-cell>
              </ng-container>

              <ng-container matColumnDef="to" >
                  <mat-header-cell *matHeaderCellDef class="toaddress">
                      <span>To.</span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let email" class="toaddress"> {{email.to}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="cc" >
                    <mat-header-cell *matHeaderCellDef class="ccaddress">
                        <span>Cc.</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let email" class="ccaddress"> {{email.cc}} </mat-cell>
                  </ng-container>

              <ng-container matColumnDef="subject">
                  <mat-header-cell *matHeaderCellDef >
                      <span>Subject.</span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let email" > {{email.subject}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="user" >
                    <mat-header-cell *matHeaderCellDef class="username">
                        <span>User.</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let email" class="username"> <span *ngIf="email.user">{{email.user.firstName}} {{email.user.lastName}} </span></mat-cell>
                  </ng-container>

              <ng-container matColumnDef="queuedDate" >
                  <mat-header-cell *matHeaderCellDef class="datecol">
                      <span mat-sort-header>Created Date.</span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let email" class="datecol"> {{email.queuedDate  | date:'dd/MM/yyyy HH:mm'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="sentDate" >
                    <mat-header-cell *matHeaderCellDef class="datecol">
                        <span mat-sort-header>Sent Date.</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let email" class="datecol"> {{email.sentDate  | date:'dd/MM/yyyy HH:mm'}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="error">
                      <mat-header-cell *matHeaderCellDef>
                        <span >Error</span>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let email">
                      <span *ngIf="email.errorCount == 5">{{email.lastErrorMessage}}</span>
                      </mat-cell>
                    </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
              <mat-row *matRowDef="let email; columns: displayedColumns;" [ngClass]="{'error': email.errorCount == 5}"></mat-row>

            </table>

            <mat-spinner color="primary" diameter="20" *ngIf="isLoadingResults"></mat-spinner>
            <mat-paginator [length]="resultsLength" [pageSize]="100" [pageSizeOptions]="[100, 200, 300]" [showFirstLastButtons]="true"></mat-paginator>

          </div>

        </app-card-basic>


  </div>

</div>
