import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'letterSearch'
})
export class LetterSearchPipe implements PipeTransform {

  transform(data: any[], keyword: string): any[] {
    if (keyword.length > 0) {
      data = data.filter(x => x.fileName.toLowerCase().indexOf(keyword.toLowerCase()) != -1);
    }

    return data;
  }

}
