export class ComparerService {

  /*
  callbackFunctionExample(key: any, value1: any, value2: any) {
    console.log(key, value1, value2);
  }

  this.deepComparison(object1, object2, this.callbackFunctionExample)


  */

  deepComparison(object1: any, object2: any, callback?: (key: any, value1: any, value2: any) => void): boolean {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      if (callback) { callback('KeyLength', keys1.length, keys2.length) };
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        areObjects && !this.deepComparison(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        if (callback) { callback(key, val1, val2) };
        return false;
      }
    }

    return true;
  }

  objectsDifferent(object1: any, object2: any): boolean {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return true;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        areObjects && this.objectsDifferent(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        return true;
      }
    }

    return false;
  }

  private isObject(object: any): boolean {
    return object != null && typeof object === 'object';
  }
}
