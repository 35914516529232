import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InvoiceService } from 'src/app/shared/services/invoice.service';
import { KlyantService } from 'src/app/shared/services/klyant.service';

@Component({
  selector: 'app-select-pre-invoice-fee-supplier',
  templateUrl: './select-pre-invoice-fee-supplier.component.html',
  styleUrls: ['./select-pre-invoice-fee-supplier.component.css']
})
export class SelectPreInvoiceFeeSupplierComponent implements OnInit {

  preInvoiceFeeId: number;
  supplierList: any = [];
  supplierId: number = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<SelectPreInvoiceFeeSupplierComponent>,
  private klyantService: KlyantService,
  private invoiceService: InvoiceService) { 
    this.preInvoiceFeeId = data.preInvoiceFeeId;
  }

  ngOnInit(): void {
    this.klyantService.getSuppliers().subscribe((results) => {
      this.supplierList = results;
    });
  }


  setSupplier() {
    if (this.supplierId) {
      this.invoiceService.setSupplierOnPreInvoiceFee(this.preInvoiceFeeId, this.supplierId).subscribe((result) => {
        this.dialogRef.close();
      });
    }
  }
}
