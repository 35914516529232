<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>

<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Add Transaction <span *ngIf="selectedType.length > 0"> - {{selectedType}}</span></h2>

<mat-dialog-content class="mat-typography">

    <form [formGroup]="newTransactionDetails" autocomplete="off">

        <app-card-label label="Type" *ngIf="transactionTypeId == 0">
            <mat-radio-group aria-label="Type" formControlName="type">
              <mat-radio-button value="1" (change)="typeChange($event)">Monies In</mat-radio-button>
              <mat-radio-button value="2" (change)="typeChange($event)" [disabled]="hasKlyantInvoice() == false">Transfer</mat-radio-button>
              <mat-radio-button value="3" (change)="typeChange($event)">Monies Out</mat-radio-button>
            </mat-radio-group>
        </app-card-label>

        <!-- The transfer needs to be associated with an invoice - if there are multiple, this must be selected-->
        <app-card-label label="Invoice" *ngIf="showControl('invoiceId') && transactionTypeId == 2">
          <mat-form-field floatLabel="never">
            <mat-select formControlName="invoiceId" placeholder="Select Invoice">
              <mat-option *ngFor="let inv of invoices" [value]="inv.id">
                <span>
                  {{inv.invoiceNumber}}
                </span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </app-card-label>


        <!-- From CLIENT Account occurs during Transfers and Monies Out -->
        <app-card-label label="From Account" *ngIf="showControl('fromBankAccountId') && (transactionTypeId == 2 || transactionTypeId == 3)">
          <mat-form-field floatLabel="never">
            <mat-select formControlName="fromBankAccountId" placeholder="Select Account">
              <mat-option *ngFor="let account of clientAccountList" [value]="account.id">
                <span>
                  {{account.name}}
                </span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </app-card-label>

        <!-- To CLIENT Account occurs during Monies In -->
        <app-card-label label="To Account" *ngIf="showControl('toBankAccountId') && transactionTypeId == 1">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="toBankAccountId" placeholder="Select Account">
                <mat-option *ngFor="let account of clientAccountList" [value]="account.id">
                  <span>
                    {{account.name}}
                  </span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </app-card-label>

        <!-- To OFFICE Account occurs during Transfers -->
        <app-card-label label="To Account" *ngIf="showControl('toBankAccountId') && transactionTypeId == 2">
          <mat-form-field floatLabel="never">
            <mat-select formControlName="toBankAccountId" placeholder="Select Account">
              <mat-option *ngFor="let account of officeAccountList" [value]="account.id">
                <span>
                  {{account.bank}}
                </span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Transaction Type" *ngIf="showControl('transactionTypeId')">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="transactionTypeId" placeholder="Select Type">
                <mat-option *ngFor="let type of transactionTypes" [value]="type.transactionTypeId">{{type.type}}</mat-option>
              </mat-select>
            </mat-form-field>
        </app-card-label>

        <app-card-label label="Transaction Type (Other)" *ngIf="showControl('transactionTypeOther') && showOtherTransactionType()">
            <mat-form-field floatLabel="never">
              <input type="text" matInput formControlName="transactionTypeOther" />
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Paying in Number" *ngIf="showControl('chequeDetails') && transactionTypeId == 1 && showChequeDetails()">
            <mat-form-field floatLabel="never">
              <input type="text" matInput formControlName="chequeDetails" />
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Cheque Number" *ngIf="showControl('chequeDetails') && transactionTypeId == 3 && showChequeDetails()">
            <mat-form-field floatLabel="never">
              <input type="text" matInput formControlName="chequeDetails" />
            </mat-form-field>
          </app-card-label>

        <app-card-label label="Funds Received From" *ngIf="showControl('fundsReceivedFrom')">
            <mat-form-field floatLabel="never">
              <input type="text" matInput formControlName="fundsReceivedFrom" />
            </mat-form-field>
          </app-card-label>

          <app-card-label *ngIf="showControl('fundsReceivedFrom') && caseTypeId == 8">
            <mat-form-field floatLabel="never">
              <mat-select placeholder="Select Text" (selectionChange)="fundsReceivedChange($event)">
                <mat-option value="">Free Text</mat-option>
                <mat-option value="Search Funds">Search Funds</mat-option>
                <mat-option value="Client Shortfall">Client Shortfall</mat-option>
                <mat-option value="Equity Release Funds">Equity Release Funds</mat-option>
                <mat-option value="Sale Funds">Sale Funds</mat-option>
                <mat-option value="Gift Funds - FROM WHOM">Gift Funds - FROM WHOM</mat-option>
              </mat-select>
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Funds Received Date" *ngIf="showControl('fundsReceivedDate')">             
              <mat-form-field floatLabel="never">
                <input matInput [matDatepicker]="picker1" formControlName="fundsReceivedDate" placeholder="Funds Received" >
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 startView="multi-year"></mat-datepicker>
                <mat-hint align="end">dd/mm/yyyy</mat-hint>
              </mat-form-field>
          </app-card-label>

        <app-card-label label="Value" *ngIf="showControl('value')">
            <mat-form-field floatLabel="never">
            <span matPrefix>£ &nbsp;</span>
            <input matInput formControlName="value" type="number" >
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Payee Name" *ngIf="showControl('payeeName')">
            <mat-form-field floatLabel="never">
              <textarea matInput formControlName="payeeName"></textarea>
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Reference" *ngIf="showControl('narrative')">            
            <mat-form-field floatLabel="never">
              <input matInput formControlName="narrative" placeholder="Klyant Reference">
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Description" *ngIf="showControl('description')">
              <mat-form-field floatLabel="never">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let opt of descriptionTextOptions" [value]="opt.value">
                    {{opt.text}}
                  </mat-option>
                </mat-autocomplete>
                <input matInput formControlName="description" placeholder="Klyant Description" [matAutocomplete]="auto">
              </mat-form-field>
            </app-card-label>

          <app-card-label label="Method of Verification" *ngIf="showControl('methodOfVerificationId')">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="methodOfVerificationId" placeholder="Select Method">
                <mat-option *ngFor="let method of methodsOfVerification" [value]="method.methodOfVerificationId">{{method.method}}</mat-option>
              </mat-select>
            </mat-form-field>
            </app-card-label>

            <app-card-label label="Payment Date" *ngIf="showControl('transactionDateTime')">             
              <mat-form-field floatLabel="never">
                <input matInput [matDatepicker]="picker2" formControlName="transactionDateTime" placeholder="Payment Date" >
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 startView="multi-year"></mat-datepicker>
                <mat-hint align="end">dd/mm/yyyy</mat-hint>
              </mat-form-field>
          </app-card-label>

      </form>

</mat-dialog-content>

<div mat-dialog-actions align="end">
  <div class="error" *ngIf="hasError">{{errorMessage}}</div>
    <button mat-stroked-button cdkFocusInitial color="primary" *ngIf="!addingTransactions" (click)="addTransaction()" [disabled]="newTransactionDetails.invalid">Add</button>
    <button *ngIf="addingTransactions" mat-icon-button disabled color="primary" class="right" >
      <mat-icon>
          <mat-spinner color="primary" diameter="20">
          </mat-spinner>
      </mat-icon>
  </button>
</div>
