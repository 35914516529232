import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmailService } from '../../../../shared/services/email.service';

@Component({
  selector: 'app-select-email-address',
  templateUrl: './select-email-address.component.html',
  styleUrls: ['./select-email-address.component.css']
})
export class SelectEmailAddressComponent implements OnInit {

  selectedAddress: any;
  selectedToAddresses: string = '';
  selectedCCAddresses: string = '';
  caseId: number = -1;

  emailAddresses: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
  private emailService: EmailService,
  public dialogRef: MatDialogRef<SelectEmailAddressComponent>) {
    this.caseId = componentData.caseId;

    this.getEmailAddressesForCase();
  }

  ngOnInit(): void {
  }

  getEmailAddressesForCase(){
    this.emailService.getEmailAddressesForCase(this.caseId).subscribe((response : any) => {
      this.emailAddresses = response.emailRecipients;
    });
  }

  selectAddress(address: any){
    this.selectedAddress = address;
  }

  addToAddress(address: any){
    if(address)
      this.selectedToAddresses += address.emailAddress + '; ';
  }

  addCCAddress(address: any){
    if(address)
      this.selectedCCAddresses += address.emailAddress + '; ';
  }

  selectAndClose()
  {
    let selectedAddress = {
      toAddresses: this.selectedToAddresses,
      ccAddresses: this.selectedCCAddresses
    };

   this.dialogRef.close(selectedAddress);
  }
}
