export const environment = {
  production: false,
  isStaging: true,
  /*
  apiUrl: 'https://staging.adlingtonlaw.co.uk/api/',
  portal1Url: 'http://staging.adlingtonlaw.co.uk',
  servicesApi: 'https://api.staging.adlingtonlaw.co.uk/'
  */
  apiUrl: 'https://staging.cms.adlingtonlaw.co.uk/api/',
  portal1Url: 'https://staging02.adlingtonlaw.co.uk',
  servicesApi: 'https://api.staging.adlingtonlaw.co.uk/',
  klyant: {
    guiBaseUrl: 'https://stagingnova.klyant.com',
    defaultNominalCode: 'TOBOE',
    defaultAccount: 'OFFBANK',
    defaultMethodOfVerificationId: 1
  }
};
