import { Component, AfterViewInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseService } from '../../shared/services/case.service';
import { UserService } from '../../shared/services/user.service';
import { TaskService } from '../../shared/services/task.service';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-reallocate-task',
  templateUrl: './reallocate-task.component.html',
  styleUrls: ['./reallocate-task.component.css']
})
export class ReallocateTaskModalComponent implements AfterViewInit {

  paralegalUsers: any[] = [];
  adminUsers: any[] = [];
  filteredParalegalUsers: Observable<any[]> = new Observable<any[]>();
  filteredAdminUsers: Observable<any[]> = new Observable<any[]>();
  paralegalSearchInput = new FormControl();
  adminSearchInput = new FormControl();
  invalidSelection: boolean = true;
  isLoading: boolean = false;
  taskType: string = "Paralegal";
  noOfDays = new FormControl();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ReallocateTaskModalComponent>,
  private caseService: CaseService,
  userService: UserService,
  private taskService: TaskService) {
    userService.paralegals().subscribe(results => {

      for (const user of results) {
          this.paralegalUsers.push({ name: user.firstName + ' ' + user.lastName, id: user.id })
      }

      this.loadParalegals()
    });

    userService.admins().subscribe(results => {

      for (const user of results) {
          this.adminUsers.push({ name: user.firstName + ' ' + user.lastName, id: user.id })
      }

      this.loadAdmins();
    });

    this.taskType = this.data['taskType'];
  }

  ngAfterViewInit(): void {
  }

  paralegalSelectionUpdated(){

    this.invalidSelection = true;

    if(this.paralegalSearchInput.value != null && this.paralegalSearchInput.value != "" )
      this.invalidSelection = false;

  }

  adminSelectionUpdated(){

    this.invalidSelection = true;

    if(this.adminSearchInput.value != null && this.adminSearchInput.value != "")
      this.invalidSelection = false;

  }

  reallocateParalegalTasks(){

    this.isLoading = true;

    this.taskService.reallocateTasks(this.paralegalSearchInput.value.id, this.data['selection'].map((x : any) => x.taskId)).subscribe((response : any) => {

      this.dialogRef.close(true);
      this.taskService.refreshTasks.next();

      }, error => {
      });

  }

  reallocateAdminTasks(){

    this.isLoading = true;

    this.taskService.reallocateTasks(this.adminSearchInput.value.id, this.data['selection'].map((x : any) => x.taskId)).subscribe((response : any) => {

      this.dialogRef.close(true);
      this.taskService.refreshTasks.next();

      }, error => {
      });

  }

  cancel(){
    this.dialogRef.close(null);
  }

  displayFn(user: any): string {
    return user ? user.name : '';
  }

  loadParalegals() {

    this.filteredParalegalUsers = this.paralegalSearchInput.valueChanges
      .pipe(
        startWith<string | any>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterParalegals(name) : this.paralegalUsers.slice())
      );

    }

    loadAdmins() {
    this.filteredAdminUsers = this.adminSearchInput.valueChanges
    .pipe(
      startWith<string | any>(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filterAdmins(name) : this.adminUsers.slice())
    );
  }

  private _filterParalegals(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.paralegalUsers.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterAdmins(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.adminUsers.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

}
