<button mat-icon-button class="close" tabindex="-1" (click)="close()"  *ngIf="!generatingLetters">x</button>
<button mat-icon-button class="audit" tabindex="-1" (click)="openAuditForForm()"><fa-icon [icon]="faHistory"></fa-icon></button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Property Details</h2>

<mat-dialog-content class="mat-typography">

    <!--mat-spinner color="primary" diameter="20" *ngIf="!dataLoaded"></mat-spinner-->

<form [formGroup]="propertyDetails" autocomplete="off">

  <app-card-label label="Is the property in the client's name?">
    <app-yes-no (change)="updateField()" formControlName="propertyInClientsName"></app-yes-no>
 </app-card-label>

<app-card-label label="Equity Release Postcode">
  <mat-form-field floatLabel="never">
    <input matInput #equityReleasePostcode maxlength="10" formControlName="equityReleasePostcode"
      (change)="updateField()">
    <app-address-lookup-button matSuffix [for]="addressLookup1"></app-address-lookup-button>
  </mat-form-field>
</app-card-label>

<app-address-lookup #addressLookup1 lookupType="postcode" [searchField]="equityReleasePostcode" [add1]="propertyDetails.controls['equityReleaseAddressLine1']"
  [add2]="propertyDetails.controls['equityReleaseAddressLine2']" [add3]="propertyDetails.controls['equityReleaseAddressLine3']" [add4]="propertyDetails.controls['equityReleaseAddressLine4']"
  [add5]="propertyDetails.controls['equityReleaseAddressLine5']" [addPc]="propertyDetails.controls['equityReleasePostcode']" (change)="updateField();">
</app-address-lookup>

<app-card-label label="Equity Release Address Line 1">
  <mat-form-field floatLabel="never">
    <input matInput #equityReleaseAddressLine1  maxlength="100"
      formControlName="equityReleaseAddressLine1" (change)="updateField()">
  </mat-form-field>
</app-card-label>

<app-card-label label="Equity Release Address Line 2">
  <mat-form-field floatLabel="never">
    <input matInput #equityReleaseAddressLine2 maxlength="100"
      formControlName="equityReleaseAddressLine2" (change)="updateField()">
  </mat-form-field>
</app-card-label>

<app-card-label label="Equity Release Address Line 3">
  <mat-form-field floatLabel="never">
    <input matInput #equityReleaseAddressLine3 maxlength="100"
      formControlName="equityReleaseAddressLine3" (change)="updateField()">
  </mat-form-field>
</app-card-label>

<app-card-label label="Equity Release Address Line 4">
  <mat-form-field floatLabel="never">
    <input matInput #equityReleaseAddressLine4 maxlength="100"
      formControlName="equityReleaseAddressLine4" (change)="updateField()">
  </mat-form-field>
</app-card-label>

<app-card-label label="Equity Release Address Line 5">
  <mat-form-field floatLabel="never">
    <input matInput #equityReleaseAddressLine5 maxlength="100"
      formControlName="equityReleaseAddressLine5" (change)="updateField()">
  </mat-form-field>
</app-card-label>

<app-card-label label="What is/are the reason(s) for obtaining the Equity Release">
  <mat-form-field floatLabel="never">
  <textarea cdkTextareaAutosize matInput #equityReleaseReason maxlength="250"
    formControlName="equityReleaseReason" (change)="updateField()"></textarea>
  </mat-form-field>
</app-card-label>

<app-card-label label="Is property Unregistered?">
  <app-yes-no formControlName="propertyIsUnregistered" (change)="updateField()" ></app-yes-no>
</app-card-label>

<app-card-label label="Title Number">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="titleNumber" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Do the names on the Mortgage Offer and Title Deeds match?">
    <app-yes-no formControlName="namesOnMortgageMatchTitleDeeds" (change)="updateField()" ></app-yes-no>
  </app-card-label>


<span [hidden]="propertyDetails.controls['namesOnMortgageMatchTitleDeeds'].value != 0">

<app-card-label label="Full Legal Name of Client 1">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="clientFullLegalName1" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Full Legal Name of Client 2">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="clientFullLegalName2" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Statement of Truth Regarding Name Discrepancy Required?">
    <app-yes-no formControlName="statementOfTruthRegardingNameDiscrepencyRequired" (change)="updateField()" ></app-yes-no>
  </app-card-label>
</span>

<app-card-label label="Transfer of Title into Joint Names">
    <app-yes-no formControlName="propertySoleNameTransferIntoJointNames" (change)="updateField()" ></app-yes-no>
  </app-card-label>

  <app-card-label label="Reason" [hidden]="propertyDetails.controls['propertySoleNameTransferIntoJointNames'].value != 1">
    <mat-form-field floatLabel="never">
      <input matInput formControlName="propertySoleNameTransferIntoJointNamesReason" (change)="updateField()" >
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Removing A Name From Title Required">
    <app-yes-no formControlName="propertyJointNameTransferIntoSoleName" (change)="updateField()" ></app-yes-no>
  </app-card-label>

  <app-card-label label="Details/Reason" [hidden]="propertyDetails.controls['propertyJointNameTransferIntoSoleName'].value != 1">
    <mat-form-field floatLabel="never">
      <input matInput formControlName="propertyJointNameTransferIntoSoleNameReason" (change)="updateField()" >
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Removal of restriction">
    <app-yes-no formControlName="removalOfRestriction" (change)="updateField()" ></app-yes-no>
  </app-card-label>

  <app-card-label label="Details/Reason" [hidden]="propertyDetails.controls['removalOfRestriction'].value != 1">
    <mat-form-field floatLabel="never">
      <input matInput formControlName="removalOfRestrictionReason" (change)="updateField()" >
    </mat-form-field>
  </app-card-label>



<app-card-label label="Is property Leasehold? ">
  <app-yes-no formControlName="propertyIsLeasehold" (change)="updateField()" ></app-yes-no>
</app-card-label>

<span [hidden]="propertyDetails.controls['propertyIsLeasehold'].value != 1">

  <app-card-label label="Landlord Full Name">
    <mat-form-field floatLabel="never">
      <input matInput #landlordName maxlength="100"
        formControlName="landlordName" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Landlord Postcode">
    <mat-form-field floatLabel="never">
      <input matInput #landlordPostcode maxlength="10" formControlName="landlordPostcode"
        (change)="updateField()">
      <app-address-lookup-button matSuffix [for]="addressLookup4"></app-address-lookup-button>
    </mat-form-field>
  </app-card-label>

  <app-address-lookup #addressLookup4 lookupType="postcode" [searchField]="landlordPostcode" [add1]="propertyDetails.controls['landlordAddressLine1']"
    [add2]="propertyDetails.controls['landlordAddressLine2']" [add3]="propertyDetails.controls['landlordAddressLine3']" [add4]="propertyDetails.controls['landlordAddressLine4']"
    [add5]="propertyDetails.controls['landlordAddressLine5']" [addPc]="propertyDetails.controls['landlordPostcode']" (change)="updateField();">
  </app-address-lookup>

  <app-card-label label="Landlord Address Line 1">
    <mat-form-field floatLabel="never">
      <input matInput #landlordAddressLine1 maxlength="100"
        formControlName="landlordAddressLine1" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Landlord Address Line 2">
    <mat-form-field floatLabel="never">
      <input matInput #landlordAddressLine2 maxlength="100"
        formControlName="landlordAddressLine2" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Landlord Address Line 3">
    <mat-form-field floatLabel="never">
      <input matInput #landlordAddressLine3 maxlength="100"
        formControlName="landlordAddressLine3" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Landlord Address Line 4">
    <mat-form-field floatLabel="never">
      <input matInput #landlordAddressLine4 maxlength="100"
        formControlName="landlordAddressLine4" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Landlord Address Line 5">
    <mat-form-field floatLabel="never">
      <input matInput #landlordAddressLine5 maxlength="100"
        formControlName="landlordAddressLine5" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Name">
    <mat-form-field floatLabel="never">
      <input matInput formControlName="managementCompanyName" (change)="updateField()" >
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Postcode">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyPostcode maxlength="10" formControlName="managementCompanyPostcode"
        (change)="updateField()">
      <app-address-lookup-button matSuffix [for]="addressLookup5"></app-address-lookup-button>
    </mat-form-field>
  </app-card-label>

  <app-address-lookup #addressLookup5 lookupType="postcode" [searchField]="managementCompanyPostcode" [add1]="propertyDetails.controls['managementCompanyAddressLine1']"
    [add2]="propertyDetails.controls['managementCompanyAddressLine2']" [add3]="propertyDetails.controls['managementCompanyAddressLine3']" [add4]="propertyDetails.controls['managementCompanyAddressLine4']"
    [add5]="propertyDetails.controls['managementCompanyAddressLine5']" [addPc]="propertyDetails.controls['managementCompanyPostcode']" (change)="updateField();">
  </app-address-lookup>

  <app-card-label label="Management Company Address Line 1">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyAddressLine1 maxlength="100"
        formControlName="managementCompanyAddressLine1" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Address Line 2">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyAddressLine2 maxlength="100"
        formControlName="managementCompanyAddressLine2" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Address Line 3">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyAddressLine3 maxlength="100"
        formControlName="managementCompanyAddressLine3" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Address Line 4">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyAddressLine4  maxlength="100"
        formControlName="managementCompanyAddressLine4" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Address Line 5">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyAddressLine5 maxlength="100"
        formControlName="managementCompanyAddressLine5" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

</span>

<app-card-label label="Solar Panels">
  <app-yes-no formControlName="solarPanelsPresent" (change)="updateField()" ></app-yes-no>
</app-card-label>

<span [hidden]="propertyDetails.controls['solarPanelsPresent'].value != 1">

<app-card-label label="Are the solar panels owned outright?">
    <app-yes-no formControlName="solarPanelsOwnedOutright" (change)="updateField()" ></app-yes-no>
  </app-card-label>

  <span [hidden]="propertyDetails.controls['solarPanelsOwnedOutright'].value != 0">

      <app-card-label label="Solar Panels Company Name">
          <mat-form-field floatLabel="never">
            <input matInput formControlName="solarPanelsCompany" (change)="updateField()" >
          </mat-form-field>
        </app-card-label>


        <app-card-label label="Solar Panel Owner Postcode">
            <mat-form-field floatLabel="never">
              <input matInput #solarPanelsOwnerPostcode maxlength="10" formControlName="solarPanelsOwnerPostcode"
                (change)="updateField()">
              <app-address-lookup-button matSuffix [for]="addressLookup2"></app-address-lookup-button>
            </mat-form-field>
          </app-card-label>

      <app-address-lookup #addressLookup2 lookupType="postcode" [searchField]="solarPanelsOwnerPostcode" [add1]="propertyDetails.controls['solarPanelsOwnerAddressLine1']"
      [add2]="propertyDetails.controls['solarPanelsOwnerAddressLine2']" [add3]="propertyDetails.controls['solarPanelsOwnerAddressLine3']" [add4]="propertyDetails.controls['solarPanelsOwnerAddressLine4']"
      [add5]="propertyDetails.controls['solarPanelsOwnerAddressLine5']" [addPc]="propertyDetails.controls['solarPanelsOwnerPostcode']" (change)="updateField();">
    </app-address-lookup>

    <app-card-label label="Solar Panel Owner Address Line 1">
      <mat-form-field floatLabel="never">
        <input matInput #solarPanelsOwnerAddressLine1 maxlength="100"
          formControlName="solarPanelsOwnerAddressLine1" (change)="updateField()">
      </mat-form-field>
    </app-card-label>

    <app-card-label label="Solar Panel Owner Address Line 2">
      <mat-form-field floatLabel="never">
        <input matInput #solarPanelsOwnerAddressLine2 maxlength="100"
          formControlName="solarPanelsOwnerAddressLine2" (change)="updateField()">
      </mat-form-field>
    </app-card-label>

    <app-card-label label="Solar Panel Owner Address Line 3">
      <mat-form-field floatLabel="never">
        <input matInput #solarPanelsOwnerAddressLine3 maxlength="100"
          formControlName="solarPanelsOwnerAddressLine3" (change)="updateField()">
      </mat-form-field>
    </app-card-label>

    <app-card-label label="Solar Panel Owner Address Line 4">
      <mat-form-field floatLabel="never">
        <input matInput #solarPanelsOwnerAddressLine4  maxlength="100"
          formControlName="solarPanelsOwnerAddressLine4" (change)="updateField()">
      </mat-form-field>
    </app-card-label>

    <app-card-label label="Solar Panel Owner Address Line 5">
      <mat-form-field floatLabel="never">
        <input matInput #solarPanelsOwnerAddressLine5 maxlength="100"
          formControlName="solarPanelsOwnerAddressLine5" (change)="updateField()">
      </mat-form-field>
    </app-card-label>


  </span>


</span>

<app-card-label label="Septic Tank">
  <app-yes-no formControlName="hasSepticTank" (change)="updateField()" ></app-yes-no>
</app-card-label>

<app-card-label label="Redeem Mortgage">
  <app-yes-no formControlName="redeemMortgage" (change)="updateField()" ></app-yes-no>
</app-card-label>

<span [hidden]="propertyDetails.controls['redeemMortgage'].value != 1">  

  <app-card-label label="Lenders Solicitors Shortfall">
    <app-yes-no formControlName="lendersSolicitorsShortfall" (change)="updateField()" ></app-yes-no>
  </app-card-label>

  <span [hidden]="propertyDetails.controls['lendersSolicitorsShortfall'].value != 1">
    <app-card-label label="Shortfall Amount">
      <mat-form-field floatLabel="never">
        <span matPrefix>£ &nbsp;</span>
        <input matInput formControlName="lendersSolicitorsShortfallAmount" (change)="updateField()" type="number" >
      </mat-form-field>
    </app-card-label>
  </span>

  <app-card-label label="Current Lender Name">
    <mat-form-field floatLabel="never">
      <input matInput #lenderName maxlength="100"
        formControlName="lenderName" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Lender Postcode">
    <mat-form-field floatLabel="never">
      <input matInput #lenderPostcode maxlength="10" formControlName="lenderPostcode"
        (change)="updateField()">
      <app-address-lookup-button matSuffix [for]="addressLookup6"></app-address-lookup-button>
    </mat-form-field>
  </app-card-label>

  <app-address-lookup #addressLookup6 lookupType="postcode" [searchField]="lenderPostcode" [add1]="propertyDetails.controls['lenderAddressLine1']"
    [add2]="propertyDetails.controls['lenderAddressLine2']" [add3]="propertyDetails.controls['lenderAddressLine3']" [add4]="propertyDetails.controls['lenderAddressLine4']"
    [add5]="propertyDetails.controls['lenderAddressLine5']" [addPc]="propertyDetails.controls['lenderPostcode']" (change)="updateField();">
  </app-address-lookup>

  <app-card-label label="Lender Address Line 1">
    <mat-form-field floatLabel="never">
      <input matInput #lenderAddressLine1 maxlength="100"
        formControlName="lenderAddressLine1" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Lender Address Line 2">
    <mat-form-field floatLabel="never">
      <input matInput #lenderAddressLine2 maxlength="100"
        formControlName="lenderAddressLine2" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Lender Address Line 3">
    <mat-form-field floatLabel="never">
      <input matInput #lenderAddressLine3 maxlength="100"
        formControlName="lenderAddressLine3" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Lender Address Line 4">
    <mat-form-field floatLabel="never">
      <input matInput #lenderAddressLine4 maxlength="100"
        formControlName="lenderAddressLine4" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Lender Address Line 5">
    <mat-form-field floatLabel="never">
      <input matInput #lenderAddressLine5 maxlength="100"
        formControlName="lenderAddressLine5" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Redemption Amount">
    <mat-form-field floatLabel="never">
      <span matPrefix>£ &nbsp;</span>
      <input matInput formControlName="redemptionAmount" (change)="updateField()"type="number"  >
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Adlington Law Redeem Mortgage?">
    <app-yes-no formControlName="adlRedeemMortgageLender1" (change)="updateField()" ></app-yes-no>
  </app-card-label>

  <span [hidden]="propertyDetails.controls['adlRedeemMortgageLender1'].value != 1">


    <app-card-label label="Lender Sort Code">
      <mat-form-field floatLabel="never">
        <input matInput #lender1SortCode maxlength="100"
          formControlName="lender1SortCode" (change)="updateField()">
      </mat-form-field>
    </app-card-label>

    <app-card-label label="Lender Account Number">
      <mat-form-field floatLabel="never">
        <input matInput #lender1AccountNumber maxlength="100"
          formControlName="lender1AccountNumber" (change)="updateField()">
      </mat-form-field>
    </app-card-label>
  </span>

  <app-card-label label="Is there a second lender?">
      <app-yes-no formControlName="hasSecondLender" (change)="updateField()" ></app-yes-no>
    </app-card-label>

    <span [hidden]="propertyDetails.controls['hasSecondLender'].value != 1">

        <app-card-label label="Lender 2 Name">
          <mat-form-field floatLabel="never">
            <input matInput #lender2Name maxlength="100"
              formControlName="lender2Name" (change)="updateField()">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Lender 2 Postcode">
          <mat-form-field floatLabel="never">
            <input matInput #lender2Postcode maxlength="10" formControlName="lender2Postcode"
              (change)="updateField()">
            <app-address-lookup-button matSuffix [for]="addressLookup7"></app-address-lookup-button>
          </mat-form-field>
        </app-card-label>

        <app-address-lookup #addressLookup7 lookupType="postcode" [searchField]="lender2Postcode" [add1]="propertyDetails.controls['lender2AddressLine1']"
          [add2]="propertyDetails.controls['lender2AddressLine2']" [add3]="propertyDetails.controls['lender2AddressLine3']" [add4]="propertyDetails.controls['lender2AddressLine4']"
          [add5]="propertyDetails.controls['lender2AddressLine5']" [addPc]="propertyDetails.controls['lender2Postcode']" (change)="updateField();">
        </app-address-lookup>

        <app-card-label label="Lender 2 Address Line 1">
          <mat-form-field floatLabel="never">
            <input matInput #lender2AddressLine1 maxlength="100"
              formControlName="lender2AddressLine1" (change)="updateField()">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Lender 2 Address Line 2">
          <mat-form-field floatLabel="never">
            <input matInput #lender2AddressLine2 maxlength="100"
              formControlName="lender2AddressLine2" (change)="updateField()">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Lender 2 Address Line 3">
          <mat-form-field floatLabel="never">
            <input matInput #lender2AddressLine3 maxlength="100"
              formControlName="lender2AddressLine3" (change)="updateField()">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Lender 2 Address Line 4">
          <mat-form-field floatLabel="never">
            <input matInput #lender2AddressLine4 maxlength="100"
              formControlName="lender2AddressLine4" (change)="updateField()">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Lender 2 Address Line 5">
          <mat-form-field floatLabel="never">
            <input matInput #lender2AddressLine5 maxlength="100"
              formControlName="lender2AddressLine5" (change)="updateField()">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Lender 2 Redemption Amount">
          <mat-form-field floatLabel="never">
            <span matPrefix>£ &nbsp;</span>
            <input matInput formControlName="redemptionAmount2" (change)="updateField()"type="number"  >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Adlington Law Redeem Mortgage?">
          <app-yes-no formControlName="adlRedeemMortgageLender2" (change)="updateField()" ></app-yes-no>
        </app-card-label>
      
        <span [hidden]="propertyDetails.controls['adlRedeemMortgageLender2'].value != 1">
      
      
          <app-card-label label="Lender Sort Code">
            <mat-form-field floatLabel="never">
              <input matInput #lender2SortCode maxlength="100"
                formControlName="lender2SortCode" (change)="updateField()">
            </mat-form-field>
          </app-card-label>
      
          <app-card-label label="Lender Account Number">
            <mat-form-field floatLabel="never">
              <input matInput #lender2AccountNumber maxlength="100"
                formControlName="lender2AccountNumber" (change)="updateField()">
            </mat-form-field>
          </app-card-label>
        </span>


        <app-card-label label="Is there a third lender?">
            <app-yes-no formControlName="hasThirdLender" (change)="updateField()" ></app-yes-no>
          </app-card-label>

          <span [hidden]="propertyDetails.controls['hasThirdLender'].value != 1">

              <app-card-label label="Lender 3 Name">
                <mat-form-field floatLabel="never">
                  <input matInput #lender3Name maxlength="100"
                    formControlName="lender3Name" (change)="updateField()">
                </mat-form-field>
              </app-card-label>

              <app-card-label label="Lender 3 Postcode">
                <mat-form-field floatLabel="never">
                  <input matInput #lender3Postcode maxlength="10" formControlName="lender3Postcode"
                    (change)="updateField()">
                  <app-address-lookup-button matSuffix [for]="addressLookup8"></app-address-lookup-button>
                </mat-form-field>
              </app-card-label>

              <app-address-lookup #addressLookup8 lookupType="postcode" [searchField]="lender3Postcode" [add1]="propertyDetails.controls['lender3AddressLine1']"
                [add2]="propertyDetails.controls['lender3AddressLine2']" [add3]="propertyDetails.controls['lender3AddressLine3']" [add4]="propertyDetails.controls['lender3AddressLine4']"
                [add5]="propertyDetails.controls['lender3AddressLine5']" [addPc]="propertyDetails.controls['lender3Postcode']" (change)="updateField();">
              </app-address-lookup>

              <app-card-label label="Lender 3 Address Line 1">
                <mat-form-field floatLabel="never">
                  <input matInput #lender3AddressLine1 maxlength="100"
                    formControlName="lender3AddressLine1" (change)="updateField()">
                </mat-form-field>
              </app-card-label>

              <app-card-label label="Lender 3 Address Line 2">
                <mat-form-field floatLabel="never">
                  <input matInput #lender3AddressLine2 maxlength="100"
                    formControlName="lender3AddressLine2" (change)="updateField()">
                </mat-form-field>
              </app-card-label>

              <app-card-label label="Lender 3 Address Line 3">
                <mat-form-field floatLabel="never">
                  <input matInput #lender3AddressLine3 maxlength="100"
                    formControlName="lender3AddressLine3" (change)="updateField()">
                </mat-form-field>
              </app-card-label>

              <app-card-label label="Lender 3 Address Line 4">
                <mat-form-field floatLabel="never">
                  <input matInput #lender3AddressLine4 maxlength="100"
                    formControlName="lender3AddressLine4" (change)="updateField()">
                </mat-form-field>
              </app-card-label>

              <app-card-label label="Lender 3 Address Line 5">
                <mat-form-field floatLabel="never">
                  <input matInput #lender3AddressLine5 maxlength="100"
                    formControlName="lender3AddressLine5" (change)="updateField()">
                </mat-form-field>
              </app-card-label>

              <app-card-label label="Lender 3 Redemption Amount">
                <mat-form-field floatLabel="never">
                  <span matPrefix>£ &nbsp;</span>
                  <input matInput formControlName="redemptionAmount3" (change)="updateField()"type="number"  >
                </mat-form-field>
              </app-card-label>

              <app-card-label label="Adlington Law Redeem Mortgage?">
                <app-yes-no formControlName="adlRedeemMortgageLender3" (change)="updateField()" ></app-yes-no>
              </app-card-label>
            
              <span [hidden]="propertyDetails.controls['adlRedeemMortgageLender3'].value != 1">
            
            
                <app-card-label label="Lender Sort Code">
                  <mat-form-field floatLabel="never">
                    <input matInput #lender3SortCode maxlength="100"
                      formControlName="lender3SortCode" (change)="updateField()">
                  </mat-form-field>
                </app-card-label>
            
                <app-card-label label="Lender Account Number">
                  <mat-form-field floatLabel="never">
                    <input matInput #lender3AccountNumber maxlength="100"
                      formControlName="lender3AccountNumber" (change)="updateField()">
                  </mat-form-field>
                </app-card-label>
              </span>
         </span>
   </span>
</span>

    <app-card-label label="Is the client going through divorce or separation proceedings?">
      <app-yes-no (change)="updateField()" formControlName="clientGoingThroughDivorceSeparationProceedings"></app-yes-no>
    </app-card-label>

    <app-card-label label="Is the client under an LPA?">
      <app-yes-no (change)="updateField()" formControlName="clientUnderLPA"></app-yes-no>
    </app-card-label>

    <app-card-label label="Is there a Trust situation?">
      <app-yes-no (change)="updateField()" formControlName="isThereATrustSituation"></app-yes-no>
    </app-card-label>

    <app-card-label label="Has the client ever been declared bankrupt?">
      <app-yes-no (change)="updateField()" formControlName="clientEverBeenDeclaredBankrupt"></app-yes-no>
    </app-card-label>

</form>
</mat-dialog-content>

<div mat-dialog-actions align="end">
    <button *ngIf="!generatingLetters" mat-stroked-button (click)="createLetters()" color="primary">Create Admin Letters</button>
    <button *ngIf="generatingLetters" mat-icon-button disabled color="primary" class="right" >
        <mat-icon>
            <mat-spinner color="primary" diameter="20">
            </mat-spinner>
        </mat-icon>
      </button>
</div>
