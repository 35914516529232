import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-case-note',
  templateUrl: './view-case-note.component.html',
  styleUrls: ['./view-case-note.component.css']
})
export class ViewCaseNoteComponent implements OnInit {

  caseNoteObj: any;
  noteText: string = "";
  byUserName: string = "";
  dateCreated: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,) {

    this.caseNoteObj = componentData.caseNoteObj;

    if(this.caseNoteObj != null)
    {
      this.noteText = this.caseNoteObj.notes;
      this.byUserName = this.caseNoteObj.addedBy.firstName + " " + this.caseNoteObj.addedBy.lastName;
      this.dateCreated = this.caseNoteObj.addedDateTime;
    }

  }

  ngOnInit(): void {
  }

}
