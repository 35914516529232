import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  faAt, faBookReader, faCalendarDay, faCoffee, faCoins, faEnvelopeOpenText, faFax, faFile,
  faFileInvoiceDollar, faFileSignature, faHandHolding, faHome, faHouse, faHouseUser, faIdCard,
  faPencilAlt, faPhone, faSearchLocation, faSign, faSms, faTasks, faUserInjured, faUserTie, faEye,
  faListOl, faClipboard, faDoorClosed, faEdit, faMoneyCheckAlt, faWallet, faArrowToLeft, faPause, faPlay, faPoundSign, faFileInvoice,
  faArrowRight, faArrowLeft, faCrown, faCommentAltCheck, faUserCheck, faPiggyBank
} from '@fortawesome/pro-light-svg-icons';
import { AdhocLetterComponent } from '../adhoc-letter/adhoc-letter.component';
import { EmailComponent } from '../email/email.component';
import { FaxComponent } from '../fax/fax.component';
import { PhonecallComponent } from '../phonecall/phonecall.component';
import { SmsComponent } from '../sms/sms.component';
import { KeyDatesComponent } from '../key-dates/key-dates.component';
import { CaseNotesComponent } from '../case-notes/case-notes.component';
import { CaseOverviewComponent } from '../case-overview/case-overview.component';
import { FileClosureComponent } from '../file-closure/file-closure.component';
import { PresubmissionChecklistComponent } from '../presubmission-checklist/presubmission-checklist.component';
import { PreexchangeChecklistComponent } from '../preexchange-checklist/preexchange-checklist.component';
import { MortgageDetailsComponent } from '../mortgage-details/mortgage-details.component';
import { EquityReleasePropertyDetailsComponent } from '../property-details/equity-release-property-details/equity-release-property-details.component';
import { EquityReleasePurchasePropertyDetailsComponent } from '../property-details/equity-release-purchase-property-details/equity-release-purchase-property-details.component';
import { ConveyancingSalePropertyDetailsComponent } from '../property-details/conveyancing-sale-property-details/conveyancing-sale-property-details.component';
import { InvoiceComponent } from '../invoicing/invoice/invoice.component';
import { BrokerDetailsComponent } from '../broker-details/broker-details.component';
import { TaskListComponent } from '../../../tasks/task-list.component';
import { EquityReleasePurchaseEstateAgentDetailsComponent } from '../estate-agent-details/equity-release-purchase-estate-agent-details/equity-release-purchase-estate-agent-details.component';
import { ConveyancingSaleEstateAgentDetailsComponent } from '../estate-agent-details/conveyancing-sale-estate-agent-details/conveyancing-sale-estate-agent-details.component';
import { LettersComponent } from '../letters/letters.component';
import { CaseService } from 'src/app/shared/services/case.service';
import { ConveyancingPurchasePropertyDetailsComponent } from '../property-details/conveyancing-purchase-property-details/conveyancing-purchase-property-details.component';
import { ConveyancingPurchaseEstateAgentDetailsComponent } from '../estate-agent-details/conveyancing-purchase-estate-agent-details/conveyancing-purchase-estate-agent-details.component';
import { CaseFundsComponent } from '../case-funds/case-funds.component';
import { PreInvoiceFeesComponent } from '../pre-invoice-fees/pre-invoice-fees.component';
import { BankDetailsComponent } from '../bank-details/bank-details.component';
import { environment } from 'src/environments/environment';
import { OnHoldComponent } from 'src/app/on-hold/on-hold.component';
import { OffHoldComponent } from 'src/app/off-hold/off-hold.component';
import { CorrespondenceTimelineService } from 'src/app/shared/services/correspondence-timeline.service';
import { EnquiriesChecklistComponent } from '../enquiries-checklist/enquiries-checklist.component';
import { TransactionsComponent } from '../transactions/transactions.component';
import { KlyantService } from 'src/app/shared/services/klyant.service';
import { InvoiceService } from '../../../shared/services/invoice.service';
import { BasicPopupYesNoComponent } from '../../../shared/components/basic-popup-yes-no/basic-popup-yes-no.component';
import { InvoiceListComponent } from '../invoicing/invoice-list/invoice-list.component';

@Component({
  selector: 'app-case-sidebar',
  templateUrl: './case-sidebar.component.html',
  styleUrls: ['./case-sidebar.component.css']
})
export class CaseSidebarComponent implements OnInit {

  @Input() caseId?: number;
  @Input() caseData?: any;

  klyantDetails: any = [];

  faCoffee = faCoffee;
  faPhone = faPhone;
  faSms = faSms;
  faAt = faAt;
  faEnvelopeOpenText = faEnvelopeOpenText;
  faFax = faFax;

  faHome = faHome;
  faSearchLocation = faSearchLocation;
  faPencilAlt = faPencilAlt;
  faIdCard = faIdCard;
  faCalendarDay = faCalendarDay;
  faTasks = faTasks;
  faHouseUser = faHouseUser;
  faSign = faSign;
  faFileInvoiceDollar = faFileInvoiceDollar;
  faCoins = faCoins;
  faHouse = faHouse;
  faHandHolding = faHandHolding;
  faFile = faFile;
  faFileSignature = faFileSignature;
  faBookReader = faBookReader;
  faUserTie = faUserTie;
  faUserInjured = faUserInjured;
  faEye = faEye;
  faListOl = faListOl;
  faClipboard = faClipboard;
  faDoorClosed = faDoorClosed;
  faEdit = faEdit;
  faMoneyCheckAlt = faMoneyCheckAlt;
  faWallet = faWallet;
  faArrowToLeft = faArrowToLeft;
  faPause = faPause;
  faPlay = faPlay;
  faCrown = faCrown;
  faCommentAltCheck = faCommentAltCheck;
  faUserCheck = faUserCheck;
  faPoundSign = faPoundSign;
  faFileInvoice = faFileInvoice;
  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  faPiggyBank = faPiggyBank;

  constructor(public dialog: MatDialog,
    private caseService: CaseService,
    private correspondenceTimelineService: CorrespondenceTimelineService,
    private klyantService: KlyantService,
    private invoiceService: InvoiceService) {

  }

  ngOnInit() {
    //this.loadKlyantDetails();
  }
  openEmail() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId
    };

    const dialogRef = this.dialog.open(EmailComponent, dialogConfig);
  }

  openPhone() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId,
      caseTypeId: this.caseData.caseTypeId
    };

    const dialogRef = this.dialog.open(PhonecallComponent, dialogConfig);
  }

  openSms() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId,
      optedIn: this.caseData.partyOneCorrespondenceSMS
    };

    const dialogRef = this.dialog.open(SmsComponent, dialogConfig);
  }

  openAdhocLetter() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId
    };

    const dialogRef = this.dialog.open(AdhocLetterComponent, dialogConfig);
  }

  openFax() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId
    };

    const dialogRef = this.dialog.open(FaxComponent, dialogConfig);
  }

  openKeyDates() {
    const dialogRef = this.dialog.open(KeyDatesComponent, {
      data: {
        caseId: this.caseId,
        readOnly: this.caseData.isCancelled || this.caseData.onHold, // has to be instructed and allocated and not on hold
        caseTypeId: this.caseData.type.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "fileClosed")
        window.location.reload();
    });
  }

  openEstateAgent() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId
    };

    if (this.caseData.type.id == 6)
      this.dialog.open(ConveyancingSaleEstateAgentDetailsComponent, dialogConfig);
    else if (this.caseData.type.id == 7)
      this.dialog.open(EquityReleasePurchaseEstateAgentDetailsComponent, dialogConfig);
    else if (this.caseData.type.id == 8)
      this.dialog.open(ConveyancingPurchaseEstateAgentDetailsComponent, dialogConfig);
  }

  openBrokerDetails() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId
    };

    const dialogRef = this.dialog.open(BrokerDetailsComponent, dialogConfig);
  }

  openCaseOverview() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    //dialogConfig.hasBackdrop = false;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      caseId: this.caseId
    };
    dialogConfig.maxWidth = '75vw';
    //dialogConfig.maxHeight = '75vh';
    dialogConfig.width = '75vw';
    //dialogConfig.height = '75vh';

    const dialogRef = this.dialog.open(CaseOverviewComponent, dialogConfig);

  }

  openCaseNotes() {
    const dialogRef = this.dialog.open(CaseNotesComponent, {
      data: { caseId: this.caseId }
    });
  }

  openSearches() {
    //const dialogRef = this.dialog.open(SearchesComponent);
    window.open(
      "https://go.searchflow.co.uk/auth/login");
  }

  openIDCheck() {
    //const dialogRef = this.dialog.open(IdCheckComponent);
    window.open(
      "https://go.searchflow.co.uk/auth/login");
  }

  openPropertyDetails() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId
    };

    if (this.caseData.type.id == 1)
      this.dialog.open(EquityReleasePropertyDetailsComponent, dialogConfig);
    else if (this.caseData.type.id == 6)
      this.dialog.open(ConveyancingSalePropertyDetailsComponent, dialogConfig);
    else if (this.caseData.type.id == 7)
      this.dialog.open(EquityReleasePurchasePropertyDetailsComponent, dialogConfig);
    else if (this.caseData.type.id == 8)
      this.dialog.open(ConveyancingPurchasePropertyDetailsComponent, dialogConfig);


  }

  openMortageDetails() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId,
      caseOnHold: this.caseData.onHold
    };

    const dialogRef = this.dialog.open(MortgageDetailsComponent, dialogConfig);

  }

  openPresubmissionChecklist() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId
    };

    const dialogRef = this.dialog.open(PresubmissionChecklistComponent, dialogConfig);
  }

  openPreexchangeChecklist() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId
    };

    const dialogRef = this.dialog.open(PreexchangeChecklistComponent, dialogConfig);
  }

  openInvoicing(){

    this.invoiceService.getInvoices(this.caseId).subscribe((response : any) => {
      var invoices = response.invoices;
      if(invoices.length == 0)
      {
        if(this.caseData.type.id == 1 || // Equity Release
        this.caseData.type.id == 6 || // Conveyancing Sale
        this.caseData.type.id == 7 || // Equity Release Including Purchase
        this.caseData.type.id == 8)	// Conveyancing Purchase
        {
          var okDialogRef = this.dialog.open(BasicPopupYesNoComponent, {
            data: { messageHeader: 'Are all property details completed?', popupText1: '', popupText2: '' }
          });
      
          okDialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              this.invoiceService.addInvoice( this.caseId, "Client Invoice" ).subscribe((response : any) => {
                this.openInvoiceScreen(response.invoice.id);
              });
            }
          });
        }
        else{
          this.invoiceService.addInvoice( this.caseId, "Client Invoice" ).subscribe((response : any) => {
            this.openInvoiceScreen(response.invoice.id);
          });
        }        
      }
      else if (invoices.length == 1)
      {
        this.openInvoiceScreen(invoices[0].id);
      }
      else if (invoices.length > 1)
      {
        this.openInvoiceList();
      }

    });

  }

  openInvoiceList() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId,
      caseTypeId : this.caseData.type.id
    };

    const dialogRef = this.dialog.open(InvoiceListComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      //this.loadKlyantDetails();
    });
  }

  openInvoiceScreen(invoiceId: number){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId,
      invoiceId: invoiceId,
      caseTypeId : this.caseData.type.id
    };

    const dialogRef = this.dialog.open(InvoiceComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      //this.loadKlyantDetails();
    });
  }

  openInvoice() {


        // For ER, ER Purchase, Purchase and Sales cases - prompt user to check property details on the first load
        if(this.caseData.type.id == 1 || // Equity Release
        this.caseData.type.id == 6 || // Conveyancing Sale
        this.caseData.type.id == 7 || // Equity Release Including Purchase
        this.caseData.type.id == 8)	// Conveyancing Purchase
        {
          this.invoiceService.getInvoiceId(this.caseId).subscribe((response : any) => {
            if(response.invoiceId == 0 )   
            {
              var okDialogRef = this.dialog.open(BasicPopupYesNoComponent, {
                data: { messageHeader: 'Are all property details completed?', popupText1: '', popupText2: '' }
              });
          
              okDialogRef.afterClosed().subscribe((result: any) => {
                if (result) {
                  this.launchInvoiceScreen();
                }
              });
            }
            else{
              this.launchInvoiceScreen();
            }
      
          });
        }
        else{
          this.launchInvoiceScreen();
        }


   
  }

  launchInvoiceScreen(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId
    };

    const dialogRef = this.dialog.open(InvoiceComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      //this.loadKlyantDetails();
    });
  }

  openFileClosureForm() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId
    };

    const dialogRef = this.dialog.open(FileClosureComponent, dialogConfig);
  }

  openCaseTasks() {
    const dialogRef = this.dialog.open(TaskListComponent, {
      data: { caseId: this.caseId, tasksFor: "case" }
    });
  }

  openCreateLetters() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId,
      caseTypeId: this.caseData.type.id
    };

    const dialogRef = this.dialog.open(LettersComponent, dialogConfig);
  }

  openCaseFunds() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId
    };

    const dialogRef = this.dialog.open(CaseFundsComponent, dialogConfig);
  }

  openPreInvoiceFees() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
      dialogConfig.data = {
      caseId: this.caseId
    };

    const dialogRef = this.dialog.open(PreInvoiceFeesComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      //this.loadKlyantDetails();
    });
  }

  openBankDetails() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId
    };

    const dialogRef = this.dialog.open(BankDetailsComponent, dialogConfig);
  }

  openPortal1Case() {

    //alert('Coming soon');
    window.open(
      environment.portal1Url + '/case/' + this.caseId, "_self");

  }
  openEnquiriesChecklist() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId
    };

    const dialogRef = this.dialog.open(EnquiriesChecklistComponent, dialogConfig);
  }

  offHoldCase() {
    var dialogRef = this.dialog.open(OffHoldComponent, {
      data: {
        caseId: this.caseId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.caseService.refreshCaseOverview.next();
        window.location.reload();
      }
    });
  }

  putCaseOnHold() {

    var dialogRef = this.dialog.open(OnHoldComponent, {
      data: {
        caseId: this.caseId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.correspondenceTimelineService.refreshCaseTimeline.next();
        this.caseService.refreshCaseOverview.next();
        window.location.reload();
      }
    });
  }

  openHMRCSDLTLink() {
    window.open("https://www.gov.uk/sdlt-online", "_blank");
  }

  openTransactions() {

    const dialogConfig = new MatDialogConfig(); 

    dialogConfig.disableClose = true;
      dialogConfig.data = {
      caseId: this.caseId,
      caseTypeId: this.caseData.typeId
    };

    const dialogRef = this.dialog.open(TransactionsComponent, dialogConfig);
  }

  public loadKlyantDetails () {
    this.klyantService.getKlyantDetails(this.caseId).subscribe(response => {
      this.klyantDetails = response;      
    });
  }

}
