import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../../shared/services/user.service';
import { CaseService } from '../../../shared/services/case.service';

@Component({
  selector: 'app-edit-authorised-signatory',
  templateUrl: './edit-authorised-signatory.component.html',
  styleUrls: ['./edit-authorised-signatory.component.css']
})
export class EditAuthorisedSignatoryComponent implements OnInit {

  caseId?: number;
  selectedUserId?: number;
  users: any [] = [];
  updateDisabled: boolean = false;
  loading: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<EditAuthorisedSignatoryComponent>,
              private caseService: CaseService,
              private userService: UserService) {

    this.caseId = data.caseId;
    this.selectedUserId = data.authorisedSignatoryUserId;

    this.userService.authorisedSignatories().subscribe(result => {
      this.users = result;
    });

  }

  ngOnInit(): void {
  }

  updateAuthorisedSignatory(){



    this.caseService.updateAuthorisedSignatory(this.caseId, this.selectedUserId).subscribe((result: any) => {
      this.dialogRef.close(true);
    });

  }

  validate(){

    /*
    if(this.selectedUserId !== null)
      this.updateDisabled = false;
    else
      this.updateDisabled = true;
      */
  }

}
