<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>

<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Add Invoice</h2>

<mat-dialog-content>
    <app-card-label label="Invoice Type">
        <mat-form-field floatLabel="never">
            <input matInput [(ngModel)]="invoiceDescription"  maxlength="200"/>
        </mat-form-field>
      </app-card-label>
</mat-dialog-content>

<div mat-dialog-actions align="end">
    <button *ngIf="!isLoading" mat-stroked-button  cdkFocusInitial color="primary" (click)="addInvoice()">Add</button>
    <button *ngIf="isLoading" mat-icon-button disabled color="primary" class="right" >
        <mat-icon>
            <mat-spinner color="primary" diameter="20">
            </mat-spinner>
        </mat-icon>
    </button>
</div>
