import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { KlyantService } from './klyant.service';
import { Observable } from "rxjs"
import { mergeMap } from 'rxjs/operators';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class TransactionsService {

  private url = environment.apiUrl + 'transactions/';

  constructor(private http: HttpClient, public router: Router, private klyantService: KlyantService) {
  }

  getTransactionTypes() {
    return this.http.get<any>(this.url + 'transactiontypes');
  }

  getMethodsOfVerification() {
    return this.http.get<any>(this.url + 'methodsofverification');
  }

  getTransactions(caseId: number){
    return this.http.get<any>(this.url + caseId );
  }

  addTransaction(caseId: number, transactionDetails: any){

    return this.http.put<any>(this.url + caseId, transactionDetails);

  }

  sendToKlyant(caseId: number, matterLedgerId: number): Observable<any> {
    return this.klyantService.getAuthToken()
      .pipe(
        mergeMap((token:any) => {
          return this.http.get<any>(this.url + caseId + '/klyant/' + matterLedgerId, {
            headers: {'X-Klyant-Token': token}
          });
      })
    ); 
  }

  // addTransaction(caseId: number, transactionDetails: any) {    
  //   return this.http.put<any>(this.url + caseId, transactionDetails);
  // }

  removeTransaction(caseId: number, transactionId: number) {
    return this.http.delete<any>(this.url + caseId + '/' + transactionId);
  }


}