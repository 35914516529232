import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CaseService } from '../../../../shared/services/case.service';
import { FormBuilder } from '@angular/forms';
import { ConveyancingSaleEstateAgentDetailsComponent } from '../../estate-agent-details/conveyancing-sale-estate-agent-details/conveyancing-sale-estate-agent-details.component';
import { ComparerService } from 'src/app/shared/utility/comparer';
import { AuditService } from '../../../../shared/services/audit.service';
import { faHistory, faPlus } from '@fortawesome/pro-light-svg-icons';
import { AuditFormComponent } from 'src/app/case/audit-form/audit-form.component';
import { AddAllowanceComponent } from '../../allowance/add-allowance/add-allowance.component';

@Component({
  selector: 'app-conveyancing-sale-property-details',
  templateUrl: './conveyancing-sale-property-details.component.html',
  styleUrls: ['./conveyancing-sale-property-details.component.css']
})
export class ConveyancingSalePropertyDetailsComponent implements OnInit {

  caseId: number;
  dataLoaded: boolean = false;
  viewInit = false;
  faHistory = faHistory;
  faPlus = faPlus;
  chattels = [1, 2, 3];

  propertyDetails = this.fb.group({
    id: [],
    caseId: [],
    saleAddressSameAsCorrespondenceAddress : [],
    saleAddressLine1 : [],
    saleAddressLine2 : [],
    saleAddressLine3 : [],
    saleAddressLine4 : [],
    saleAddressLine5 : [],
    salePostcode : [],
    propertyIsUnregistered : [],
    titleNumber : [],
    clientFullNameMatchTitleDeeds : [],
    clientFullNameMatchTitleDeedsReason : [],
    statementOfTruthRegardingNameDiscrepencyRequired : [],
    propertyType : [],
    landlordName : [],
    landlordAddressLine1 : [],
    landlordAddressLine2 : [],
    landlordAddressLine3 : [],
    landlordAddressLine4 : [],
    landlordAddressLine5 : [],
    landlordPostcode : [],
    managementCompanyName : [],
    managementCompanyAddressLine1 : [],
    managementCompanyAddressLine2 : [],
    managementCompanyAddressLine3 : [],
    managementCompanyAddressLine4 : [],
    managementCompanyAddressLine5 : [],
    managementCompanyPostcode : [],
    solarPanelsPresent : [],
    solarPanelsOwnedOutright  : [],
    solarPanelsCompany  : [],
    solarPanelsOwnerAddressLine1 : [],
    solarPanelsOwnerAddressLine2 : [],
    solarPanelsOwnerAddressLine3 : [],
    solarPanelsOwnerAddressLine4 : [],
    solarPanelsOwnerAddressLine5 : [],
    solarPanelsOwnerPostcode : [],
    hasSepticTank : [],
    redeemMortgage : [],
    lenderName : [],
    lenderAddressLine1 : [],
    lenderAddressLine2 : [],
    lenderAddressLine3 : [],
    lenderAddressLine4 : [],
    lenderAddressLine5 : [],
    lenderPostcode : [],
    propertySoleNameTransferIntoJointNames : [],
    propertySoleNameTransferIntoJointNamesReason : [],
    propertyJointNameTransferIntoSoleName : [],
    propertyJointNameTransferIntoSoleNameReason : [],
    removalOfRestriction : [],
    removalOfRestrictionReason : [],
    agreedSalePrice : [],
    buyerSolicitorName : [],
    buyerSolicitorEmailAddress : [],
    buyerSolicitorContactNumber : [],
    buyer1Name : [],
    buyer2Name : [],
    buyerSolicitorReference : [],
    buyerSolicitorAddressLine1 : [],
    buyerSolicitorAddressLine2 : [],
    buyerSolicitorAddressLine3 : [],
    buyerSolicitorAddressLine4 : [],
    buyerSolicitorAddressLine5 : [],
    buyerSolicitorPostcode : [],
    hasSecondLender : [],
    lender2Name : [],
    lender2AddressLine1 : [],
    lender2AddressLine2 : [],
    lender2AddressLine3 : [],
    lender2AddressLine4 : [],
    lender2AddressLine5 : [],
    lender2Postcode : [],
    hasThirdLender : [],
    lender3Name : [],
    lender3AddressLine1 : [],
    lender3AddressLine2 : [],
    lender3AddressLine3 : [],
    lender3AddressLine4 : [],
    lender3AddressLine5 : [],
    lender3Postcode : [],
    redemptionAmount: [],
    redemptionAmount2: [],
    redemptionAmount3: [],
    currentMortgageAccountNumber: [],
    titleGuaranteeType: [],
    chattels: [],
    adlRedeemMortgageLender1: [],
    lender1SortCode: [],
    lender1AccountNumber: [],
    adlRedeemMortgageLender2: [],
    lender2SortCode: [],
    lender2AccountNumber: [],
    adlRedeemMortgageLender3: [],
    lender3SortCode: [],
    lender3AccountNumber: []
  }, { });

  initialDetails = this.fb.group({
    id: [],
    caseId: [],
    saleAddressSameAsCorrespondenceAddress : [],
    saleAddressLine1 : [],
    saleAddressLine2 : [],
    saleAddressLine3 : [],
    saleAddressLine4 : [],
    saleAddressLine5 : [],
    salePostcode : [],
    propertyIsUnregistered : [],
    titleNumber : [],
    clientFullNameMatchTitleDeeds : [],
    clientFullNameMatchTitleDeedsReason : [],
    statementOfTruthRegardingNameDiscrepencyRequired : [],
    propertyType : [],
    landlordName : [],
    landlordAddressLine1 : [],
    landlordAddressLine2 : [],
    landlordAddressLine3 : [],
    landlordAddressLine4 : [],
    landlordAddressLine5 : [],
    landlordPostcode : [],
    managementCompanyName : [],
    managementCompanyAddressLine1 : [],
    managementCompanyAddressLine2 : [],
    managementCompanyAddressLine3 : [],
    managementCompanyAddressLine4 : [],
    managementCompanyAddressLine5 : [],
    managementCompanyPostcode : [],
    solarPanelsPresent : [],
    solarPanelsOwnedOutright  : [],
    solarPanelsCompany  : [],
    solarPanelsOwnerAddressLine1 : [],
    solarPanelsOwnerAddressLine2 : [],
    solarPanelsOwnerAddressLine3 : [],
    solarPanelsOwnerAddressLine4 : [],
    solarPanelsOwnerAddressLine5 : [],
    solarPanelsOwnerPostcode : [],
    hasSepticTank : [],
    redeemMortgage : [],
    lenderName : [],
    lenderAddressLine1 : [],
    lenderAddressLine2 : [],
    lenderAddressLine3 : [],
    lenderAddressLine4 : [],
    lenderAddressLine5 : [],
    lenderPostcode : [],
    propertySoleNameTransferIntoJointNames : [],
    propertySoleNameTransferIntoJointNamesReason : [],
    propertyJointNameTransferIntoSoleName : [],
    propertyJointNameTransferIntoSoleNameReason : [],
    removalOfRestriction : [],
    removalOfRestrictionReason : [],
    agreedSalePrice : [],
    buyerSolicitorName : [],
    buyerSolicitorEmailAddress : [],
    buyerSolicitorContactNumber : [],
    buyer1Name : [],
    buyer2Name : [],
    buyerSolicitorReference : [],
    buyerSolicitorAddressLine1 : [],
    buyerSolicitorAddressLine2 : [],
    buyerSolicitorAddressLine3 : [],
    buyerSolicitorAddressLine4 : [],
    buyerSolicitorAddressLine5 : [],
    buyerSolicitorPostcode : [],
    hasSecondLender : [],
    lender2Name : [],
    lender2AddressLine1 : [],
    lender2AddressLine2 : [],
    lender2AddressLine3 : [],
    lender2AddressLine4 : [],
    lender2AddressLine5 : [],
    lender2Postcode : [],
    hasThirdLender : [],
    lender3Name : [],
    lender3AddressLine1 : [],
    lender3AddressLine2 : [],
    lender3AddressLine3 : [],
    lender3AddressLine4 : [],
    lender3AddressLine5 : [],
    lender3Postcode : [],
    redemptionAmount: [],
    redemptionAmount2: [],
    redemptionAmount3: [],
    currentMortgageAccountNumber: [],
    titleGuaranteeType: [],
    chattels: [],
    adlRedeemMortgageLender1: [],
    lender1SortCode: [],
    lender1AccountNumber: [],
    adlRedeemMortgageLender2: [],
    lender2SortCode: [],
    lender2AccountNumber: [],
    adlRedeemMortgageLender3: [],
    lender3SortCode: [],
    lender3AccountNumber: []
  }, { });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private caseService: CaseService,
              private fb: FormBuilder,
              public dialogRef: MatDialogRef<ConveyancingSalePropertyDetailsComponent>,
              public dialog: MatDialog,
              private comp: ComparerService,
              private auditService: AuditService) {

    this.caseId = data.caseId;
    this.caseService.getConveyancingSalePropertyDetails(this.caseId).subscribe((response : any) => {

      this.propertyDetails.patchValue(response.propertyDetails);
      this.initialDetails.patchValue(response.propertyDetails);
      this.dataLoaded = true;
    });
  }

  ngOnInit(): void {
  }

  ngAfterContentInit() : void {
    this.viewInit = true;
    //this.loadData();
  }

  loadData(){
    this.caseService.getConveyancingSalePropertyDetails(this.caseId).subscribe((response : any) => {

      console.log(response.propertyDetails);
      this.propertyDetails.patchValue(response.propertyDetails);
      this.dataLoaded = true;
    });
  }

  updateField(){

    this.caseService.updateConveyancingSalePropertyDetails(this.propertyDetails.value).subscribe((response : any) => {

    }, (error : any) => {


    });
  }

  openEstateAgent(){
    //this.dialogRef.close(null);

    this.close();

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
      dialogConfig.data = {
      caseId: this.caseId
    };

    this.dialog.open(ConveyancingSaleEstateAgentDetailsComponent, dialogConfig);
  }

  close(){

    if(this.comp.objectsDifferent(this.initialDetails.value, this.propertyDetails.value))
    {
      this.auditService.auditForm(this.caseId, 'PropertyDetails', this.initialDetails.value, this.propertyDetails.value).subscribe((response : any) => {
        this.dialogRef.close();
      });
    }
    else
      this.dialogRef.close();
  }

  openAuditForForm(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      caseId: this.caseId,
      formName: "PropertyDetails"
    };

    this.dialog.open(AuditFormComponent, dialogConfig);

  }

}
