<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Case Note</h2>

<mat-dialog-content class="mat-typography">
  <div [innerHTML]="noteText"></div>
  <div class="notedetails">
  <strong>Added By:</strong> {{byUserName}}<br>
    <strong> Added Date: </strong>{{dateCreated | date:'dd/MM/yyyy hh:mm:ss'}}
  </div>
</mat-dialog-content>
