import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { CaseService } from '../../../../shared/services/case.service';
import { LettersService } from '../../../../shared/services/letters.service';
import { ComparerService } from 'src/app/shared/utility/comparer';
import { AuditService } from 'src/app/shared/services/audit.service';
import { CorrespondenceTimelineService } from '../../../../shared/services/correspondence-timeline.service';
import { AuditFormComponent } from '../../../audit-form/audit-form.component';
import { faHistory} from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-equity-release-property-details',
  templateUrl: './equity-release-property-details.component.html',
  styleUrls: ['./equity-release-property-details.component.css']
})
export class EquityReleasePropertyDetailsComponent implements OnInit {

  caseId: number;
  dataLoaded: boolean = false;
  viewInit = false;
  faHistory = faHistory;

  generatingLetters: boolean = false;

  propertyDetails = this.fb.group({
    id: [],
    caseId: [],
    equityReleaseAddressLine1 : [],
    equityReleaseAddressLine2 : [],
    equityReleaseAddressLine3 : [],
    equityReleaseAddressLine4 : [],
    equityReleaseAddressLine5 : [],
    equityReleasePostcode : [],
    propertyIsUnregistered : [],
    titleNumber : [],
    namesOnMortgageMatchTitleDeeds : [],
    clientFullLegalName1 : [],
    clientFullLegalName2 : [],
    statementOfTruthRegardingNameDiscrepencyRequired : [],
    propertyIsLeasehold : [],
    landlordName : [],
    landlordAddressLine1 : [],
    landlordAddressLine2 : [],
    landlordAddressLine3 : [],
    landlordAddressLine4 : [],
    landlordAddressLine5 : [],
    landlordPostcode : [],
    managementCompanyName : [],
    managementCompanyAddressLine1 : [],
    managementCompanyAddressLine2 : [],
    managementCompanyAddressLine3 : [],
    managementCompanyAddressLine4 : [],
    managementCompanyAddressLine5 : [],
    managementCompanyPostcode : [],
    solarPanelsPresent : [],
    solarPanelsOwnedOutright  : [],
    solarPanelsCompany  : [],
    solarPanelsOwnerAddressLine1 : [],
    solarPanelsOwnerAddressLine2 : [],
    solarPanelsOwnerAddressLine3 : [],
    solarPanelsOwnerAddressLine4 : [],
    solarPanelsOwnerAddressLine5 : [],
    solarPanelsOwnerPostcode : [],
    hasSepticTank : [],
    redeemMortgage : [],
    lendersSolicitorsShortfall : [],
    lendersSolicitorsShortfallAmount : [],
    lenderName : [],
    lenderAddressLine1 : [],
    lenderAddressLine2 : [],
    lenderAddressLine3 : [],
    lenderAddressLine4 : [],
    lenderAddressLine5 : [],
    lenderPostcode : [],
    propertySoleNameTransferIntoJointNames : [],
    propertySoleNameTransferIntoJointNamesReason : [],
    propertyJointNameTransferIntoSoleName : [],
    propertyJointNameTransferIntoSoleNameReason : [],
    removalOfRestriction : [],
    removalOfRestrictionReason : [],
    hasSecondLender : [],
    lender2Name : [],
    lender2AddressLine1 : [],
    lender2AddressLine2 : [],
    lender2AddressLine3 : [],
    lender2AddressLine4 : [],
    lender2AddressLine5 : [],
    lender2Postcode : [],
    hasThirdLender : [],
    lender3Name : [],
    lender3AddressLine1 : [],
    lender3AddressLine2 : [],
    lender3AddressLine3 : [],
    lender3AddressLine4 : [],
    lender3AddressLine5 : [],
    lender3Postcode : [],
    redemptionAmount: [],
    redemptionAmount2: [],
    redemptionAmount3: [],
    adlRedeemMortgageLender1: [],
    lender1SortCode: [],
    lender1AccountNumber: [],
    adlRedeemMortgageLender2: [],
    lender2SortCode: [],
    lender2AccountNumber: [],
    adlRedeemMortgageLender3: [],
    lender3SortCode: [],
    lender3AccountNumber: [],
    propertyInClientsName: [],
    clientGoingThroughDivorceSeparationProceedings: [],
    clientUnderLPA: [],
    isThereATrustSituation: [],
    clientEverBeenDeclaredBankrupt: [],
    equityReleaseReason: []
  }, { });

  initialDetails = this.fb.group({
    id: [],
    caseId: [],
    equityReleaseAddressLine1 : [],
    equityReleaseAddressLine2 : [],
    equityReleaseAddressLine3 : [],
    equityReleaseAddressLine4 : [],
    equityReleaseAddressLine5 : [],
    equityReleasePostcode : [],
    propertyIsUnregistered : [],
    titleNumber : [],
    namesOnMortgageMatchTitleDeeds : [],
    clientFullLegalName1 : [],
    clientFullLegalName2 : [],
    statementOfTruthRegardingNameDiscrepencyRequired : [],
    propertyIsLeasehold : [],
    landlordName : [],
    landlordAddressLine1 : [],
    landlordAddressLine2 : [],
    landlordAddressLine3 : [],
    landlordAddressLine4 : [],
    landlordAddressLine5 : [],
    landlordPostcode : [],
    managementCompanyName : [],
    managementCompanyAddressLine1 : [],
    managementCompanyAddressLine2 : [],
    managementCompanyAddressLine3 : [],
    managementCompanyAddressLine4 : [],
    managementCompanyAddressLine5 : [],
    managementCompanyPostcode : [],
    solarPanelsPresent : [],
    solarPanelsOwnedOutright  : [],
    solarPanelsCompany  : [],
    solarPanelsOwnerAddressLine1 : [],
    solarPanelsOwnerAddressLine2 : [],
    solarPanelsOwnerAddressLine3 : [],
    solarPanelsOwnerAddressLine4 : [],
    solarPanelsOwnerAddressLine5 : [],
    solarPanelsOwnerPostcode : [],
    hasSepticTank : [],
    redeemMortgage : [],
    lenderName : [],
    lenderAddressLine1 : [],
    lenderAddressLine2 : [],
    lenderAddressLine3 : [],
    lenderAddressLine4 : [],
    lenderAddressLine5 : [],
    lenderPostcode : [],
    propertySoleNameTransferIntoJointNames : [],
    propertySoleNameTransferIntoJointNamesReason : [],
    propertyJointNameTransferIntoSoleName : [],
    propertyJointNameTransferIntoSoleNameReason : [],
    removalOfRestriction : [],
    removalOfRestrictionReason : [],
    hasSecondLender : [],
    lender2Name : [],
    lender2AddressLine1 : [],
    lender2AddressLine2 : [],
    lender2AddressLine3 : [],
    lender2AddressLine4 : [],
    lender2AddressLine5 : [],
    lender2Postcode : [],
    hasThirdLender : [],
    lender3Name : [],
    lender3AddressLine1 : [],
    lender3AddressLine2 : [],
    lender3AddressLine3 : [],
    lender3AddressLine4 : [],
    lender3AddressLine5 : [],
    lender3Postcode : [],
    redemptionAmount: [],
    redemptionAmount2: [],
    redemptionAmount3: [],
    adlRedeemMortgageLender1: [],
    lender1SortCode: [],
    lender1AccountNumber: [],
    adlRedeemMortgageLender2: [],
    lender2SortCode: [],
    lender2AccountNumber: [],
    adlRedeemMortgageLender3: [],
    lender3SortCode: [],
    lender3AccountNumber: [],
    propertyInClientsName: [],
    clientGoingThroughDivorceSeparationProceedings: [],
    clientUnderLPA: [],
    isThereATrustSituation: [],
    clientEverBeenDeclaredBankrupt: [],
    equityReleaseReason: []
  }, { });


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private caseService: CaseService,
              private fb: FormBuilder,
              private lettersService: LettersService,
              public dialogRef: MatDialogRef<EquityReleasePropertyDetailsComponent>,
              private comp: ComparerService,
              private auditService: AuditService,
              public dialog: MatDialog,
              private correspondenceTimelineService: CorrespondenceTimelineService) {

    this.caseId = data.caseId;

    this.caseService.getEquityReleasePropertyDetails(this.caseId).subscribe((response : any) => {      
      this.propertyDetails.patchValue(response.propertyDetails);
      this.initialDetails.patchValue(response.propertyDetails);
      this.dataLoaded = true;
    });
  }

  ngOnInit(): void {
  }

  ngAfterContentInit() : void {
    this.viewInit = true;
    //this.loadData();
  }

  loadData(){
    this.caseService.getEquityReleasePropertyDetails(this.caseId).subscribe((response : any) => {
      this.propertyDetails.patchValue(response.propertyDetails);
      this.dataLoaded = true;
    });
  }

  updateField(){

    this.caseService.updateEquityReleasePropertyDetails(this.propertyDetails.value).subscribe((response : any) => {

    }, (error : any) => {


    });
  }

  createLetters(){

    this.generatingLetters = true;

    this.lettersService.generateLettersForGroup(this.caseId, 1).subscribe((response : any) => {

      this.correspondenceTimelineService.refreshCaseTimeline.next();
      this.close();

    }, (error : any) => {


    });
  }

  close(){

    if(this.comp.objectsDifferent(this.initialDetails.value, this.propertyDetails.value))
    {
      this.auditService.auditForm(this.caseId, 'PropertyDetails', this.initialDetails.value, this.propertyDetails.value).subscribe((response : any) => {
        this.dialogRef.close();
      });
    }
    else
      this.dialogRef.close();
  }

  openAuditForForm(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      caseId: this.caseId,
      formName: "PropertyDetails"
    };

    this.dialog.open(AuditFormComponent, dialogConfig);


  }
}
