<button mat-icon-button class="close" tabindex="-1" (click)="close()">x</button>
<button mat-icon-button class="audit" tabindex="-1" (click)="openAuditForForm()"><fa-icon [icon]="faHistory"></fa-icon></button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Broker Details</h2>

<mat-dialog-content class="mat-typography">
    <form [formGroup]="brokerDetails" autocomplete="off">


      <app-card-label label="Individual Financial Advisor">
          <mat-form-field floatLabel="never">
            <mat-select  formControlName="faUserId" (selectionChange)="updateField()" [disabled]="!authService.isPortalManager() && !authService.isPortalAdmin()" >
              <mat-option *ngFor="let user of faUsers" [value]="user.id">{{user.firstName}} {{user.lastName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </app-card-label>


      <div class="itemRow">
          <label class="labelItem">Financial Advisor Company</label>
          <label class="itemString">{{brokerDetails.controls['financialAdvisorCompanyName'].value}}</label>
        </div>


      <app-card-label label="Financial Advisor Contact Number">
        <mat-form-field floatLabel="never">
          <input matInput formControlName="financialAdvisorContactNumber" (change)="updateField()" >
        </mat-form-field>
      </app-card-label>


      <div class="itemRow">
        <label class="labelItem">Financial Advisor Email Address</label>
        <label class="itemString">{{brokerDetails.controls['faUser'].value?.emailAddress}}</label>
      </div>

      <app-card-label label="Broker Reference">
        <mat-form-field floatLabel="never">
          <input matInput formControlName="brokerReference" (change)="updateField()" >
        </mat-form-field>
      </app-card-label>

      <app-card-label label="Financial Advisor Fee">
        <mat-form-field floatLabel="never">
          <span matPrefix>£ &nbsp;</span>
          <input matInput formControlName="financialAdvisorFee" (change)="updateField()" type="number" >
        </mat-form-field>
      </app-card-label>


    </form>

  </mat-dialog-content>
