<h2 mat-dialog-title>File Upload</h2>

<mat-dialog-content class="mat-typography">
  <!--div>
    <app-card-label label="New File Name">
        <mat-form-field floatLabel="never">
          <input matInput [(ngModel)]="newFileName" >
        </mat-form-field>
      </app-card-label>

  </div>
  <br/-->
  <div *ngIf="files && !uploadFailed">
    <div *ngFor="let file of files">
      {{file.name}} <!--{{file.size}} {{file.type}}-->
    </div>
  </div>

  <div *ngIf="failedFiles && uploadFailed">
      <h3>The following files failed to upload:</h3>
      <div *ngFor="let file of failedFiles">
        {{file.name}} - <span class="error">{{file.error}}</span><!--{{file.size}} {{file.type}}-->
      </div>
    </div>    

    <div *ngIf="existingFiles.length > 0" class="file-overwrite">
      <br />
      <div><fa-icon [icon]="faExclamationCircle" class="error" size="lg"></fa-icon> The following files will be overwritten (may include archived files):</div>
      <br />
      <div *ngFor="let file of existingFiles" class="file-list">
        {{file}}
      </div>
    </div>

</mat-dialog-content>

<div mat-dialog-actions align="end">
    <button *ngIf="!uploading && !uploadFailed" mat-button (click)="uploadFiles()">Upload Files</button>
    <button *ngIf="uploading && !uploadFailed" mat-icon-button disabled color="primary" style="float: right;">
        <mat-spinner color="primary" diameter="20"></mat-spinner>
    </button>
    <button mat-button mat-dialog-close>Close</button>
</div>

