import { Component, OnInit } from '@angular/core';
import { LendersSolicitorService } from '../../shared/services/lenders-solicitor.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-lender-solicitor-details',
  templateUrl: './lender-solicitor-details.component.html',
  styleUrls: ['./lender-solicitor-details.component.css']
})
export class LenderSolicitorDetailsComponent implements OnInit {

  newLenderSol: boolean = true;
  lenderSolicitorId?: number;
  updatingLenderSol: boolean = false;

  lenderSolDetails = this.fb.group({
    id: null,
    name: ['', Validators.required],
    addressLine1: [],
    addressLine2: [],
    addressLine3: [],
    addressLine4: [],
    addressLine5: [],
    postcode: [],
    isActive: []
  }, { });

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private lenderSolservice: LendersSolicitorService,
    private snackBar: MatSnackBar) {

      this.route.params.subscribe(params => {
        this.lenderSolicitorId = params['id'];

        if(this.lenderSolicitorId !== undefined && this.lenderSolicitorId !== null){
          this.newLenderSol = false;

          this.lenderSolservice.lenderSolDetails(this.lenderSolicitorId).subscribe(result => {
            this.lenderSolDetails.patchValue(result);
          });
        }

      });

  }

  ngOnInit(): void {
  }

  saveLenderSol(){

    this.updatingLenderSol = true;

    this.lenderSolservice.updateLenderSol(this.lenderSolDetails.value).subscribe(response => {


      this.router.navigate(['/lendersolicitors/list']);
      this.updatingLenderSol = false;

      }, (error : any) => {

        var errorMessage = "Update Failed";
        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }

        this.updatingLenderSol = false;
        this.openSnackBar(errorMessage, 'Close');


      });
  }

  addLenderSol(){
    this.lenderSolservice.addLenderSol(this.lenderSolDetails.value).subscribe(response => {

      this.router.navigate(['/lendersolicitors/list']);

      }, error => {


        var errorMessage = "Creation Failed";
        if(error.error !== null && error.error.message !== undefined)
        {
          errorMessage = error.error.message;
        }

        this.openSnackBar(errorMessage, 'Close');
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        duration: 5000,
    });
  }
}
