<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Archive Item</h2>

<mat-dialog-content class="mat-typography">

    <app-card-label label="Reason" >
        <mat-form-field floatLabel="never">
            <textarea autosize matInput [(ngModel)]="reason"  maxlength="500"></textarea>
        </mat-form-field>
      </app-card-label>

</mat-dialog-content>



<div mat-dialog-actions align="end">

    <button *ngIf="!isLoading" mat-stroked-button [disabled]="reason.length == 0" color="primary" type="button" (click)="archiveItem()">Archive</button>

    <button *ngIf="isLoading" mat-icon-button disabled color="primary" class="right" >
        <mat-icon>
            <mat-spinner color="primary" diameter="20">
            </mat-spinner>
        </mat-icon>
    </button>

</div>
