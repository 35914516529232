<h1>Add Case Overview Item</h1>

<form autocomplete="off" (ngSubmit)="addItem()" [formGroup]="form">

    <mat-form-field>
      <textarea autosize matInput #text rows="10" formControlName="text"></textarea>
    </mat-form-field>


    <div class="buttons">

      <button *ngIf="!isLoading" mat-stroked-button [disabled]="false" color="warn" type="button" (click)="cancel()">Cancel</button>
      <button *ngIf="!isLoading" mat-stroked-button [disabled]="false" color="primary" type="submit">Save</button>

      <button *ngIf="isLoading" mat-icon-button disabled color="primary" class="right" >
        <mat-icon>
            <mat-spinner color="primary" diameter="20">
            </mat-spinner>
        </mat-icon>
      </button>

    </div>

  </form>
