import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SearchRequest } from './interfaces/search-request';
import { SearchResult } from './interfaces/search-result';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class LendersSolicitorService {

  private url = environment.apiUrl + 'lenderssolicitors/';

  constructor(private http: HttpClient, public router: Router) {

  }

  getAll() {
    return this.http.get<any>(this.url);
  }

  lenderSolDetails(id: number) {
    return this.http.get<any>(this.url  + id);
  }

  addLenderSol(lenderSolDetails: any) {
    return this.http.put<any>(this.url , lenderSolDetails, httpOptions);
  }

  updateLenderSol(lenderSolDetails: any) {
    return this.http.post<any>(this.url, lenderSolDetails, httpOptions);
  }


}
