import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private showSidebar = false;
  private showSideBarSubject = new BehaviorSubject(false);
  showSideBarObservable = this.showSideBarSubject.asObservable();

  constructor() { }

  toggleSideBar() {

    this.showSidebar = !this.showSidebar;
    this.showSideBarSubject.next(this.showSidebar);
  }

  closeSideBar() {
    if (this.showSidebar) {
      this.showSidebar = false;
      this.showSideBarSubject.next(false);
    }
  }

}
