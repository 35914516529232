<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>

<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Add Disbursement</h2>

<!--div>
  You can manually add a disbursement using this form. This will also create a linked outlay in Klyant.
  <br />Where possible, a fee type will be matched to a Supplier in Klyant.
</div-->

<mat-dialog-content class="mat-typography">

      <form [formGroup]="newFeeDetails" autocomplete="off">

        <app-card-label label="Fee Type">
          <mat-form-field floatLabel="never">            
            <mat-select formControlName="feeId" placeholder="Select Type" (selectionChange)="setDefaults()">
              <mat-option *ngFor="let fee of feeTypes" [value]="fee.id">{{fee.description}}</mat-option>

            </mat-select> 
          </mat-form-field>
          </app-card-label>

          <app-card-label label="Description" [hidden]="newFeeDetails.controls['feeId'].value != 0">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="description">
            </mat-form-field>
          </app-card-label>


          <app-card-label label="Reference">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="outlay_reference" required>
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Date">
            <mat-form-field>
              <input formControlName="outlay_date" matInput [matDatepicker]="picker" required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>      
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Net Amount">
            <mat-form-field floatLabel="never">
              <span matPrefix>£ &nbsp;</span>
              <input matInput formControlName="netAmount" type="number" >
              <br />              
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Supplier">
            <mat-form-field floatLabel="never">            
              <mat-select formControlName="supplier_id" placeholder="Select Supplier">
                <mat-option *ngFor="let supplier of supplierList" [value]="supplier.id">{{supplier.name}}</mat-option>
              </mat-select> 
            </mat-form-field>
            </app-card-label>

          <app-card-label label="Account">
            <mat-form-field floatLabel="never">            
              <mat-select formControlName="nominal_code" placeholder="Select Account">
                <mat-option *ngFor="let account of officeAccountList" [value]="account.bank">{{account.bank}}</mat-option>
              </mat-select> 
            </mat-form-field>
            </app-card-label>

          <app-card-label label="Method of Verification">
            <mat-form-field floatLabel="never">            
              <mat-select formControlName="methodOfVerificationId" placeholder="Select Method">
                <mat-option *ngFor="let method of methodsOfVerification" [value]="method.methodOfVerificationId">{{method.method}}</mat-option>
              </mat-select> 
            </mat-form-field>
            </app-card-label>

      </form>

</mat-dialog-content>

<div mat-dialog-actions align="end">
    <button *ngIf="!addingFee" mat-stroked-button  cdkFocusInitial color="primary" (click)="addFee()" [disabled]="newFeeDetails.invalid">Add</button>
    <button *ngIf="addingFee" mat-icon-button disabled color="primary" class="right" >
      <mat-icon>
          <mat-spinner color="primary" diameter="20">
          </mat-spinner>
      </mat-icon>
  </button>
</div>
