<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>

<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Add Fund</h2>

<mat-dialog-content class="mat-typography">



      <form [formGroup]="newFundDetails" autocomplete="off">
      <app-card-label label="Description">
          <mat-form-field floatLabel="never">
            <textarea matInput formControlName="description" > </textarea>
          </mat-form-field>
        </app-card-label>

      <app-card-label label="Value">
          <mat-form-field floatLabel="never">
           <span matPrefix>£ &nbsp;</span>
           <input matInput formControlName="net" type="number" >
          </mat-form-field>
        </app-card-label>

      </form>

</mat-dialog-content>

<div mat-dialog-actions align="end">
    <button mat-stroked-button  cdkFocusInitial color="primary" (click)="addFund()" [disabled]="newFundDetails.invalid">Add</button>
</div>
