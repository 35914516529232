<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<div mat-dialog-title><h2><span *ngIf="!readOnly">New </span>Fax</h2></div>

<mat-dialog-content class="mat-typography">
  <form [formGroup]="faxDetails" autocomplete="off">

    <div class="wrapper">
      <div class="row">

        <div>
          <div class="to">
            <label>Fax Number: </label>
            <mat-form-field>
              <input matInput value="" formControlName="faxNumber" >
            </mat-form-field>
          </div>
        </div>

      </div>

      <div class="row" *ngIf="caseFiles.length > 0">
          <div class="files-label" [ngClass]="{'no-lmargin': readOnly}">
              <fa-icon [icon]="faPaperclip" size="lg"></fa-icon>
          </div>
          <div class="filenames">
            <span *ngFor="let file of caseFiles">{{file.fileName}} <button *ngIf="!readOnly" mat-icon-button title="Remove" class="removeButton" (click)="removeAttachment(file.caseFileId)">X</button> </span>
          </div>
        </div>

      <div class="row border">
        <mat-form-field>
          <textarea matInput #messageBody formControlName="messageBody"></textarea>
        </mat-form-field>
      </div>

    </div>
  </form>

  <div *ngIf="readOnly">
      <div class="row" >
          Created : {{faxObj.queuedDate  | date:'dd/MM/yyyy HH:mm'}}
      </div>
    <div class="row" >
        Sent : {{faxObj.sentDate  | date:'dd/MM/yyyy HH:mm'}}
    </div>
</div>

</mat-dialog-content>

<div mat-dialog-actions align="end" *ngIf="!readOnly">
    <button mat-button cdkFocusInitial (click)="sendFax()" [disabled]="sendingFax || faxDetails.invalid">Send</button>
  </div>
