import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faConstruction} from '@fortawesome/pro-light-svg-icons';
import { LettersService } from 'src/app/shared/services/letters.service';
import { CorrespondenceTimelineService } from 'src/app/shared/services/correspondence-timeline.service';

@Component({
  selector: 'app-adhoc-letter',
  templateUrl: './adhoc-letter.component.html',
  styleUrls: ['./adhoc-letter.component.css']
})
export class AdhocLetterComponent implements OnInit {

  caseId: number;
  recipients: any[] = [];
  recipientType: any = null;
  generatingLetter: boolean = false;
  generatingError: boolean = false;
  errorMessage: string = "";

  faConstruction = faConstruction;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<AdhocLetterComponent>,
              private lettersService: LettersService,
              private correspondenceTimelineService: CorrespondenceTimelineService
              ) {

    this.caseId = data.caseId;

    this.lettersService.getLetterRecipientsForCase(this.caseId).subscribe((response : any) => {

      this.recipients = response.letterRecipients;
    });
  }

  ngOnInit() {
  }

  generateLetter(){
    this.generatingLetter = true;
    this.lettersService.generateAdHocLetterForRecipient(this.caseId, this.recipientType).subscribe((response : any) => {
      this.generatingLetter = false;
      this.dialogRef.close();
      this.correspondenceTimelineService.refreshCaseTimeline.next();
    }, (error : any) => {

      this.generatingError = true;
      if(error.status == 999)
        this.errorMessage = error.error;
      else if(error.status == 500 )
        this.errorMessage = "Internal Error";
      else
        this.errorMessage = error.statusText;

    });
  }
}
