import { faAt, faFile, faFileExcel, faFileImage, faFilePdf, faFilePowerpoint, faFileWord } from "@fortawesome/pro-light-svg-icons";

export const DocumentTypes = {
  docx: { extension: 'docx', icon: faFileWord },
  doc: { extension: 'doc', icon: faFileWord },
  msg: { extension: 'msg', icon: faAt },
  pdf: { extension: 'pdf', icon: faFilePdf },
  bmp: { extension: 'bmp', icon: faFileImage },
  png: { extension: 'png', icon: faFileImage },
  jpg: { extension: 'jpg', icon: faFileImage },
  ppt: { extension: 'ppt', icon: faFilePowerpoint },
  pptx: { extension: 'pptx', icon: faFilePowerpoint },
  xls: { extension: 'xls', icon: faFileExcel },
  xlsx: { extension: 'xlsx', icon: faFileExcel },
  other: { extension: '', icon: faFile },
}

export const DocumentTypes3 = {
  1: { extension: 'docx', icon: faFileWord },
  2: { extension: 'doc', icon: faFileWord },
  3: { extension: 'msg', icon: faAt },
  4: { extension: 'pdf', icon: faFilePdf },
  5: { extension: 'bmp', icon: faFileImage },
  6: { extension: 'png', icon: faFileImage },
  7: { extension: 'jpg', icon: faFileImage },
  8: { extension: 'ppt', icon: faFilePowerpoint },
  9: { extension: 'pptx', icon: faFilePowerpoint },
  10: { extension: 'xls', icon: faFileExcel },
  11: { extension: 'xlsx', icon: faFileExcel },
  12: { extension: '', icon: faFile },
}


export const DocumentTypes2 = [
  { extension: '', icon: faFile },
  { extension: 'docx', icon: faFileWord },
  { extension: 'doc', icon: faFileWord },
  { extension: 'msg', icon: faAt },
  { extension: 'pdf', icon: faFilePdf },
  { extension: 'bmp', icon: faFileImage },
  { extension: 'png', icon: faFileImage },
  { extension: 'jpg', icon: faFileImage },
  { extension: 'ppt', icon: faFilePowerpoint },
  { extension: 'pptx', icon: faFilePowerpoint },
  { extension: 'xls', icon: faFileExcel },
  { extension: 'xlsx', icon: faFileExcel }]


  export const FileTypes = [
    { id: 0, extension: '', icon: faFile },
    { id: 1, extension: 'pdf', icon: faFilePdf },
    { id: 2, extension: 'docx', icon: faFileWord },
    { id: 3, extension: 'msg', icon: faAt },
    { id: 4, extension: 'xlsx', icon: faFileExcel },
    { id: 5, extension: 'bmp', icon: faFileImage },
    { id: 6, extension: 'doc', icon: faFileWord },
    { id: 7, extension: 'xls', icon: faFileExcel },
    ]

/*
  PDF = 1,
  DocX = 2,
  EML = 3,
  xlsX = 4,
  IMG = 5,
  Doc = 6,
  xls = 7
*/
