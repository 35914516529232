<div>
    <div style="display: flex;  align-items: center;">
      <ng-content></ng-content>
      <div class="icon" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">
        <fa-icon [icon]="faFilter" *ngIf="!filterValue"></fa-icon>
        <fa-icon class="active" [icon]="fasFilter" *ngIf="filterValue"></fa-icon>
      </div>
    </div>
    <div *ngIf="filterName" class="active-value mat-elevation-z">
      <span>{{filterName}}</span>
      <fa-icon class="filter-clear" [icon]="faTimesCircle" (click)="clear()"></fa-icon>
    </div>
  </div>

  <!--
  <span class="icon" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">
    <fa-icon [icon]="faFilter" *ngIf="!filterValue"></fa-icon>
    <fa-icon class="active" [icon]="fasFilter" *ngIf="filterValue"></fa-icon>
  </span> -->

  <span id="filter-panel">
    <mat-menu #menu="matMenu" [overlapTrigger]="false" class="mat-typography">
      <div (click)="$event.stopPropagation()" (keyup)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <form autocomplete="off" (ngSubmit)="onFilterSubmit()" [formGroup]="filterForm">
          <h3 *ngIf="title">{{title}}</h3>

          <mat-form-field>
            <input matInput [matDatepicker]="pickerstart" (dateInput)="addFromEvent($event)"
              (dateChange)="addFromEvent($event)" [max]="maxDate" placeholder="From" formControlName="from">
            <mat-datepicker-toggle matSuffix [for]="pickerstart"></mat-datepicker-toggle>
            <mat-datepicker #pickerstart></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <input matInput [matDatepicker]="pickerend" (dateInput)="addToEvent($event)" (dateChange)="addToEvent($event)"
              placeholder="To" [min]="minDate" formControlName="to">
            <mat-datepicker-toggle matSuffix [for]="pickerend"></mat-datepicker-toggle>
            <mat-datepicker #pickerend></mat-datepicker>
          </mat-form-field>

          <div class="buttons">
            <button mat-stroked-button [disabled]="false" color="warn" type="button" (click)="clear()">Clear</button>
            <button mat-stroked-button [disabled]="false" color="primary" type="submit">Apply</button>
          </div>

        </form>
      </div>
    </mat-menu>
  </span>
