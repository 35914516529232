<form [formGroup]="consultantDetails" autocomplete="off">
    <app-card-basic cardTitle="Consultant Details">
        <div card-body>

            <button mat-icon-button class="audit" tabindex="-1" (click)="openAuditForForm()"><fa-icon [icon]="faHistory"></fa-icon></button>

            <app-card-label label="Name">
                <mat-form-field floatLabel="never">
                    <mat-hint align="end">{{name.value?.length || 0}}/50</mat-hint>
                    <input matInput #name placeholder="Name" maxlength="50" formControlName="name">
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Email Address">
              <mat-form-field floatLabel="never">
                    <mat-hint align="end">{{emailAddress.value?.length || 0}}/100</mat-hint>
                    <input matInput #emailAddress placeholder="Email Address" maxlength="100" formControlName="emailAddress">
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Postcode">
                <mat-form-field floatLabel="never">
                    <mat-hint align="end">{{postcode.value?.length || 0}}/25</mat-hint>
                    <input matInput #postcode placeholder="Postcode" maxlength="25" formControlName="postcode">
                </mat-form-field>
            </app-card-label>

            <app-card-label label="County">
                <mat-form-field floatLabel="never">
                    <mat-hint align="end">{{borough.value?.length || 0}}/25</mat-hint>
                    <input matInput #borough placeholder="County" maxlength="250" formControlName="borough">
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Phone Number">
              <mat-form-field floatLabel="never">
                  <mat-hint align="end">{{phoneNumber.value?.length || 0}}/25</mat-hint>
                  <input matInput #phoneNumber placeholder="Phone Number" maxlength="250" formControlName="phoneNumber">
              </mat-form-field>
          </app-card-label>

          <app-card-label label="Fees">
            <mat-form-field floatLabel="never">
                <mat-hint align="end">{{fees.value?.length || 0}}/100</mat-hint>
                <input matInput #fees placeholder="Fees" maxlength="250" formControlName="fees">
            </mat-form-field>
        </app-card-label>

            <app-card-label label="Number of Days Worked Per Week">
                <mat-form-field floatLabel="never">
                    <mat-hint align="end">{{numberDaysWorkedPerWeek.value?.length || 0}}/100</mat-hint>
                    <input matInput #numberDaysWorkedPerWeek placeholder="Number of Days" maxlength="100" formControlName="numberDaysWorkedPerWeek">
                </mat-form-field>
            </app-card-label>

          <app-card-label label="Days of the Week Worked">
              <mat-form-field floatLabel="never">
                  <mat-hint align="end">{{daysOfTheWeekWorked.value?.length || 0}}/100</mat-hint>
                  <input matInput #daysOfTheWeekWorked placeholder="Days of the Week" maxlength="100" formControlName="daysOfTheWeekWorked">
              </mat-form-field>
          </app-card-label>

          <app-card-label label="Distance Willing to Travel (Miles)">
            <mat-form-field floatLabel="never">
                <input matInput #milesWillingToTravel placeholder="Miles" type="number" maxlength="3" min="0" formControlName="milesWillingToTravel">
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Active" *ngIf="!newConsultant">
            <app-yes-no formControlName="active" ></app-yes-no>
          </app-card-label>

          </div>
    </app-card-basic>
</form>

<div class="flex" *ngIf="!newConsultant">
    <span class="spacer"></span>
    <button mat-icon-button disabled color="primary" style="float: right;" *ngIf="updatingConsultant">
        <mat-icon>
            <mat-spinner color="primary" diameter="20">
            </mat-spinner>
        </mat-icon>
    </button>
    <button mat-raised-button (click)="saveConsultant()" color="primary" *ngIf="!updatingConsultant">Update</button>
</div>

<div class="flex" *ngIf="newConsultant">
    <span class="spacer"></span>
    <button mat-raised-button (click)="addConsultant()" color="primary">Save</button>
</div>
