import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CorrespondenceTimelineService } from '../../../shared/services/correspondence-timeline.service';

@Component({
  selector: 'app-item-pdf-generator',
  templateUrl: './item-pdf-generator.component.html',
  styleUrls: ['./item-pdf-generator.component.css']
})
export class ItemPdfGeneratorComponent implements OnInit {

  caseId: number | undefined;
  item: any | undefined;

  generatingPDF: boolean = false;
  createButtonDisabled: boolean = true;
  generatingError: boolean = false;
  errorMessage: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ItemPdfGeneratorComponent>,
              private correspondenceTimelineService: CorrespondenceTimelineService) {
    this.caseId = data.caseId;
    this.item = data.item;

    this.generatingPDF = true;

    this.correspondenceTimelineService.generatePDF( this.caseId, this.item.id).subscribe(response => {

      if(response.success)
      {
        this.dialogRef.close();
        this.correspondenceTimelineService.refreshCaseTimeline.next();
      }
      else{
        this.generatingError = true;
        this.errorMessage = response.errorText;
      }

    }, error => {


      this.generatingError = true;
      if(error.status == 999)
        this.errorMessage = error.error;
      else if(error.status == 500 )
        this.errorMessage = "Internal Error";
      else
        this.errorMessage = error.statusText;


    });
  }

  ngOnInit(): void {
  }

}
