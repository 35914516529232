import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SearchResult } from './interfaces/search-result';
import { SearchRequest } from './interfaces/search-request';
import { Observable } from 'rxjs/internal/Observable';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CreditSafecheckService {

  private caseUrl = environment.apiUrl + 'creditsafecheck/';

  constructor(private http: HttpClient) { }

  getCreditSafecheckInfo(caseId: number, clientNo: number) {
    return this.http.get<any[]>(this.caseUrl + 'forcase/' + caseId + '/creditsafecheck/' + clientNo);
  }
  getCreditSafecheckHistory(caseId: number, clientNo: number) {
    return this.http.get<any[]>(this.caseUrl + 'forcase/' + caseId + '/creditsafecheckhistory/' + clientNo);
  }

  runCreditSafecheck(caseId: number, clientDetails: any) {
   
    let formData = {
      caseId: caseId,
      titleId: clientDetails.titleId,
      firstName: clientDetails.firstName,
      otherNames: clientDetails.otherNames,
      surname: clientDetails.surname,
      dateOfBirth: clientDetails.dob,
      buildingNo: clientDetails.buildingNo,
      buildingName: clientDetails.buildingName,
      street: clientDetails.street,
      city: clientDetails.city,
      postCode: clientDetails.postCode,
      clientNo: clientDetails.clientNo
    };
   
    return this.http.post<any>(this.caseUrl + 'forcase/' + caseId + '/runcreditcheck', formData);
  }

  addManualCreditSafecheck(caseId: number, clientNo: number, manualCheckDetails: any) {
    let formData = {
      caseId: caseId,
      clientNo: clientNo,
      date: manualCheckDetails.date,
      notes: manualCheckDetails.notes
    };

    return this.http.post<any>(this.caseUrl + 'forcase/' + caseId + '/addmanualcreditcheck', formData);

  }

  unlockCreditSafecheck(caseId: number, clientNo: number) {
    let formData = {
      clientNo: clientNo
    };

    return this.http.post<any>(this.caseUrl + 'forcase/' + caseId + '/unlock', formData);
  }

  addCreditCheck(caseId: number, clientDetails: any) {

    let formData = {
      caseId: caseId,
      titleId: clientDetails.titleId,
      firstName: clientDetails.firstName,
      otherNames: clientDetails.otherNames,
      surname: clientDetails.surname,
      dateOfBirth: clientDetails.dob,
      buildingNo: clientDetails.buildingNo,
      buildingName: clientDetails.buildingName,
      street: clientDetails.street,
      city: clientDetails.city,
      postCode: clientDetails.postCode,
      clientNo: clientDetails.clientNo
    };

    return this.http.post<any>(this.caseUrl + 'forcase/' + caseId + '/addcreditcheck', formData);
  }
  editCreditCheck(caseId: number, clientDetails: any) {

    let formData = {
      caseId: caseId,
      titleId: clientDetails.titleId,
      firstName: clientDetails.firstName,
      otherNames: clientDetails.otherNames,
      surname: clientDetails.surname,
      dateOfBirth: clientDetails.dob,
      buildingNo: clientDetails.buildingNo,
      buildingName: clientDetails.buildingName,
      street: clientDetails.street,
      city: clientDetails.city,
      postCode: clientDetails.postCode,
      clientNo: clientDetails.clientNo
    };

    return this.http.post<any>(this.caseUrl + 'forcase/' + caseId + '/editcreditcheck', formData);
  }
}
