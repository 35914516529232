import { Component, OnInit, Inject } from '@angular/core';
import { CaseService } from '../../../shared/services/case.service';
import { LookupsService } from '../../../shared/services/lookups.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators, FormArray, FormGroup } from '@angular/forms';
import { faPlus, faTrashAlt, faCalendarDay, faHistory } from '@fortawesome/pro-light-svg-icons';
import { DateTime } from 'luxon';
import { LettersService } from '../../../shared/services/letters.service';
import { ComparerService } from 'src/app/shared/utility/comparer';
import { AuditService } from '../../../shared/services/audit.service';
import { CorrespondenceTimelineService } from '../../../shared/services/correspondence-timeline.service';
import { AuditFormComponent } from '../../audit-form/audit-form.component';
import { SelectConsultantComponent } from '../../../calendar/select-consultant/select-consultant.component';
import { KeyDatesComponent } from '../key-dates/key-dates.component';

@Component({
  selector: 'app-mortgage-details',
  templateUrl: './mortgage-details.component.html',
  styleUrls: ['./mortgage-details.component.css']
})
export class MortgageDetailsComponent implements OnInit {

  caseId: number;
  viewInit = false;

  maxDate: Date = new Date();
  minDate: Date = new Date();

  faPlus = faPlus;
  faTrashAlt = faTrashAlt;
  faHistory = faHistory;
  faCalendarDay = faCalendarDay;

  externalConsultants:any[] = [];
  lenders:any[] = [];
  lendersSolicitors:any[] = [];
  lendersSolicitorEmails:any[] = [];

  generatingLetters: boolean = false;

  caseOnHold: boolean = false;

  occupierIndex: number = 0;

  //occupiersGroup: any;

  mortgageDetails = this.fb.group({
    id: [],
    caseId: [],
    lenderId: [],
    lendersSolicitorId: [],
    lenderSolicitorReference: '',
    otherLendersSolicitor: [],
    otherLendersSolicitorEmail: [],
    lendersSolicitorEmailId: [],
    otherLender: [],
    offerExpiryDate: [],
    nameOfProduct: [],
    fullLoanAmount: [],
    drawdownAvailable: [],
    drawdownAvailableDetails: [],
    cashbackAvailable: [],
    cashbackAvailableDetails: [],
    fixedInterestRate: [],
    propertyVacatedLength: [],
    termOfMortgage: [],
    agreedInterestRepayment: [],
    agreedInterestRepaymentDetails: [],
    anyOccupiers: [],
    occupiers: this.fb.array([]),
    buildingInsuranceSumRequired: [],
    lendersInterestNotedRequired: [],
    lendersInterestNotedRequiredDetails: [],
    hasSpecialConditions: [],
    specialConditions: this.fb.array([]),
    externalConsultantId: [],
    contributionToLegalFees: [],
    contributionToLegalFeesDetails: [],
    finalAmountReleased: []
  }, { });

  initialDetails = this.fb.group({
    id: [],
    caseId: [],
    lenderId: [],
    lendersSolicitorId: [],
    lenderSolicitorReference: '',
    otherLendersSolicitor: [],
    otherLendersSolicitorEmail: [],
    lendersSolicitorEmailId: [],
    otherLender: [],
    offerExpiryDate: [],
    nameOfProduct: [],
    fullLoanAmount: [],
    drawdownAvailable: [],
    drawdownAvailableDetails: [],
    cashbackAvailable: [],
    cashbackAvailableDetails: [],
    fixedInterestRate: [],
    propertyVacatedLength: [],
    termOfMortgage: [],
    agreedInterestRepayment: [],
    agreedInterestRepaymentDetails: [],
    anyOccupiers: [],
    occupiers: this.fb.array([]),
    buildingInsuranceSumRequired: [],
    lendersInterestNotedRequired: [],
    lendersInterestNotedRequiredDetails: [],
    hasSpecialConditions: [],
    specialConditions: this.fb.array([]),
    externalConsultantId: [],
    contributionToLegalFees: [],
    contributionToLegalFeesDetails: [],
    finalAmountReleased: []
  }, { });



  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private caseService: CaseService,
              private fb: FormBuilder,
              private lookupsService: LookupsService,
              private lettersService: LettersService,
              public dialogRef: MatDialogRef<MortgageDetailsComponent>,
              private comp: ComparerService,
              private auditService: AuditService,
              public dialog: MatDialog,
              private correspondenceTimelineService: CorrespondenceTimelineService) {

    this.caseId = data.caseId;
    this.caseOnHold = data.caseOnHold;

    this.loadDetails();

  }

  ngOnInit(): void {
  }

  ngAfterContentInit() : void {
    this.viewInit = true;
    //this.loadDetails();
  }

  loadDetails(): void{
    this.caseService.getEquityReleaseMortgageDetails(this.caseId).subscribe(response => {

      this.mortgageDetails.patchValue(response.mortgageDetails);

      this.lookupsService.getExternalConsultants().subscribe(result => {
        this.externalConsultants = result;
      });

      this.lookupsService.getLenders().subscribe(result => {
        this.lenders = result;
      });

      this.lookupsService.getLendersSolicitors().subscribe(result => {
        this.lendersSolicitors = result;
      });

      this.lookupsService.getLendersSolicitorEmails().subscribe(result => {
        this.lendersSolicitorEmails = result;

        this.lendersSolicitorEmails = this.lendersSolicitorEmails.filter( ls => ls.lendersSolicitorId ==  this.mortgageDetails.controls['lendersSolicitorId'].value);
      });

      response.mortgageDetails.occupiers.forEach((element : any) => {
        const occupier = this.newOcc(element.idx);
        occupier.patchValue(element);
        this.occupiers.push(occupier);
      });

      response.mortgageDetails.specialConditions.forEach((element : any) => {
        const sc = this.newSC();
        sc.patchValue(element);
        this.specialConditions.push(sc);
      });

      this.minDate = DateTime.local().toJSDate();
      this.maxDate = DateTime.local().plus({years : 10}).toJSDate();

      this.initialDetails.patchValue(response.mortgageDetails);

    });
  }



  get occupiers() : FormArray {
   return this.mortgageDetails.get('occupiers') as FormArray
  }

  get specialConditions () {
    return this.mortgageDetails.get('specialConditions') as FormArray
  }

  newOcc(idx: number) : FormGroup{
    return this.fb.group({
      id : [],
      occupierName:'',
      occupierDOB: null,
      occupierRelationshipToClient: '',
      idx: idx
    })
  }

  addOcc() {

    var idx = 0;

    this.occupiers.controls.forEach((element, index) => {

      if(element?.get("idx")?.value !== null && element?.get("idx")?.value > idx)
        idx = element?.get("idx")?.value;
    });

    this.occupiers.push(this.newOcc(idx + 1));
  }

  removeOcc(i:number) {
    this.occupiers.removeAt(i);
    this.updateField();
  }

  newSC() : FormGroup{
    return this.fb.group({
      id : [],
      specialCondition:''
    })
  }

  addSC() {
    this.specialConditions.push(this.newSC());
  }

  removeSC(i:number) {
    this.specialConditions.removeAt(i);
    this.updateField();
  }

  updateField(reloadEntityType? : string){

    this.caseService.updateEquityReleaseMortgageDetails(this.mortgageDetails.value).subscribe((response : any) => {

    }, (error : any) => {

        /*
        var errorMessage = "Failed to update case";
        if(error.error !== null && error.error.message !== undefined)
        {
        errorMessage = error.error.message;
        }*/

    });
  }

  createLetters(){

    this.generatingLetters = true;

    this.lettersService.generateLettersForGroup(this.caseId, 2).subscribe((response : any) => {
      this.correspondenceTimelineService.refreshCaseTimeline.next();
      this.close();
    }, (error : any) => {


    });
  }

  close(){

    if(this.comp.objectsDifferent(this.initialDetails.value, this.mortgageDetails.value))
    {
      this.auditService.auditForm(this.caseId, 'MortgageDetails', this.initialDetails.value, this.mortgageDetails.value).subscribe((response : any) => {
        this.dialogRef.close();
        this.caseService.refreshCase.next();
      });
    }
    else
      this.dialogRef.close();
  }

  openAuditForForm(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      caseId: this.caseId,
      formName: "MortgageDetails"
    };

    this.dialog.open(AuditFormComponent, dialogConfig);

  }

  selectConsultant(){
    var dialogRef = this.dialog.open(SelectConsultantComponent, {
      data: { caseId: this.caseId}
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.mortgageDetails.controls['externalConsultantId'].patchValue(result.externalConsultantId);
        this.updateField();
      }
    });
  }

  openKeyDates() {
    const dialogRef = this.dialog.open(KeyDatesComponent, {
      data: {
              caseId: this.caseId,
              readOnly: this.caseOnHold,
              caseTypeId : 1
            }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result == "fileClosed")
        window.location.reload();
      else
        this.loadDetails();
    });
  }

  updateSolEmails(){

    this.lookupsService.getLendersSolicitorEmails().subscribe(result => {
      this.lendersSolicitorEmails = result;

      this.lendersSolicitorEmails = this.lendersSolicitorEmails.filter( ls => ls.lendersSolicitorId ==  this.mortgageDetails.controls['lendersSolicitorId'].value);
    });


  }

}
