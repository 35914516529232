import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CaseService } from '../../../../shared/services/case.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-case-overview-history',
  templateUrl: './case-overview-history.component.html',
  styleUrls: ['./case-overview-history.component.scss']
})
export class CaseOverviewHistoryComponent implements OnInit {

  private caseId: number;
  items: any;

  displayedColumns: string[] = ['text','addedBy', 'addedDate', 'removedBy', 'removedDate'];


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<CaseOverviewHistoryComponent>,
  private caseService: CaseService,
  public dialog: MatDialog) {
    this.caseId = data.caseId;
  }

  ngOnInit(): void {
    this.loadItems();
  }

  loadItems(){

    this.caseService.getAllCaseOverviewItems(this.caseId).subscribe(result => {

      this.items = result.caseOverviewItems;

    }, error => {

      var errorMessage = "Failed to get case notes history";
      if (error.error !== null && error.error.message !== undefined) {
        errorMessage = error.error.message;
      }

    });
  }

  formatDate(inDate : any){

    var formattedDate = '';

    if(inDate !== null)
    {
       var dt = DateTime.fromISO(inDate);

       formattedDate = dt.toFormat("dd-MM-yyyy HH:mm:ss");

     if(formattedDate == 'Invalid date')
       formattedDate = '';

    }


     return formattedDate;

   }
}
