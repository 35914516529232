import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SearchResult } from './interfaces/search-result';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { SearchRequest } from './interfaces/search-request';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class TaskService {

  private url = environment.apiUrl + 'tasks/';

  public refreshTasks  = new EventEmitter();
  public refreshTasksObservable = this.refreshTasks.asObservable();

  constructor(private http: HttpClient) { }

  tasks(request: SearchRequest) {

    if (request.filterStrings.join() == '') {
      return this.http.get<SearchResult>(`${this.url}?page=${request.page}&pageSize=${request.pageSize}&orderBy=
      ${request.orderBy}&orderDirection=${request.orderDirection}`);
    }

    var queryString = '';

    for (var i = 0; i < request.filterStrings.length; i++) {
      if (request.filterStrings[i] != '' && request.filterStrings[i] != null) {
        queryString += `&filterColumns=${request.filterColumns[i]}&filterStrings=${request.filterStrings[i]}`
      }
    }

    return this.http.get<SearchResult>(`${this.url}?page=${request.page}&pageSize=${request.pageSize}&orderBy=
    ${request.orderBy}&orderDirection=${request.orderDirection}${queryString}`);

  }

  paralegalUserTasks(request: SearchRequest, includeCompleted: boolean) {

    if (request.filterStrings.join('') == '') {
      return this.http.get<SearchResult>(`${this.url}ParalegalTasks?page=${request.page}&pageSize=${request.pageSize}&orderBy=
      ${request.orderBy}&orderDirection=${request.orderDirection}&includeCompleted=${includeCompleted}`);
    }

    var queryString = '';

    for (var i = 0; i < request.filterStrings.length; i++) {
      if (request.filterStrings[i] != '' && request.filterStrings[i] != null) {
        queryString += `&filterColumns=${request.filterColumns[i]}&filterStrings=${request.filterStrings[i]}`
      }
    }

    return this.http.get<SearchResult>(`${this.url}ParalegalTasks?page=${request.page}&pageSize=${request.pageSize}&orderBy=
    ${request.orderBy}&orderDirection=${request.orderDirection}&includeCompleted=${includeCompleted}${queryString}`);

  }

  adminUserTasks(request: SearchRequest, includeCompleted: boolean) {

    if (request.filterStrings.join('') == '') {
      return this.http.get<SearchResult>(`${this.url}AdminTasks?page=${request.page}&pageSize=${request.pageSize}&orderBy=
      ${request.orderBy}&orderDirection=${request.orderDirection}&includeCompleted=${includeCompleted}`);
    }

    var queryString = '';

    for (var i = 0; i < request.filterStrings.length; i++) {
      if (request.filterStrings[i] != '' && request.filterStrings[i] != null) {
        queryString += `&filterColumns=${request.filterColumns[i]}&filterStrings=${request.filterStrings[i]}`
      }
    }

    return this.http.get<SearchResult>(`${this.url}AdminTasks?page=${request.page}&pageSize=${request.pageSize}&orderBy=
    ${request.orderBy}&orderDirection=${request.orderDirection}&includeCompleted=${includeCompleted}${queryString}`);

  }

  managementUserTasks(request: SearchRequest, includeCompleted: boolean) {

    if (request.filterStrings.join('') == '') {
      return this.http.get<SearchResult>(`${this.url}ManagementTasks?page=${request.page}&pageSize=${request.pageSize}&orderBy=
      ${request.orderBy}&orderDirection=${request.orderDirection}&includeCompleted=${includeCompleted}`);
    }

    var queryString = '';

    for (var i = 0; i < request.filterStrings.length; i++) {
      if (request.filterStrings[i] != '' && request.filterStrings[i] != null) {
        queryString += `&filterColumns=${request.filterColumns[i]}&filterStrings=${request.filterStrings[i]}`
      }
    }

    return this.http.get<SearchResult>(`${this.url}ManagementTasks?page=${request.page}&pageSize=${request.pageSize}&orderBy=
    ${request.orderBy}&orderDirection=${request.orderDirection}&includeCompleted=${includeCompleted}${queryString}`);

  }

  caseTasks(caseId: (number | undefined), request: SearchRequest) {

    if (request.filterStrings.join('') == '') {
      return this.http.get<SearchResult>(`${this.url + 'ForCase/' + caseId}/?page=${request.page}&pageSize=${request.pageSize}&orderBy=
      ${request.orderBy}&orderDirection=${request.orderDirection}`);
    }

    var queryString = '';

    for (var i = 0; i < request.filterStrings.length; i++) {
      if (request.filterStrings[i] != '' && request.filterStrings[i] != null) {
        queryString += `&filterColumns=${request.filterColumns[i]}&filterStrings=${request.filterStrings[i]}`
      }
    }

    return this.http.get<SearchResult>(`${this.url + 'ForCase/' + caseId}/?page=${request.page}&pageSize=${request.pageSize}&orderBy=
    ${request.orderBy}&orderDirection=${request.orderDirection}${queryString}`);

  }

  reallocateTasks(userId: number, taskIds: number[]){

    let params = '';

    for (const taskId of taskIds) {
      params += `&taskIds=${taskId}`
    }

    params = params.substring(1);

    return this.http.post<any>(this.url +`ReallocateTasks/${userId}`, taskIds,  httpOptions );
  }


  addTask(task: any) {
    return this.http.put<any>(this.url, task, httpOptions);
  }

  addTaskReply(id:number, reply: any) {
    return this.http.put<any>(this.url + id + '/reply', reply, httpOptions);
  }

  update(id: number, task: any) {
    return this.http.post<any>(this.url + id, task, httpOptions);
  }

  complete(id: number, task: any) {
    return this.http.post<any>(this.url + id + '/complete', task, httpOptions);
  }

}
