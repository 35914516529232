import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class CalendarService {

  private calendarUrl = environment.apiUrl + 'calendar/';

  constructor(private http: HttpClient) {

  }

  getMonthEvents(month: number, year: number): Observable<any> {

    return this.http.get<any>(`${this.calendarUrl}month/${month}/${year}`);

  }

  getDayEvents(day: number, month: number, year: number): Observable<any> {

    return this.http.get<any>(`${this.calendarUrl}day/${day}/${month}/${year}`);

  }

  getEvent(caseId: number): Observable<any> {

    return this.http.get<any>(`${this.calendarUrl}${caseId}`);

  }




}
