import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { faConstruction, faPlus, faTimes, faPencil, faSync, faHistory, fal } from '@fortawesome/pro-light-svg-icons';
import { BasicPopupYesNoComponent } from '../../../shared/components/basic-popup-yes-no/basic-popup-yes-no.component';
import { DatePickerComponent } from '../../../shared/components/date-picker/date-picker.component';
import { AuditService } from '../../../shared/services/audit.service';
import { EnquiryChecklistService } from '../../../shared/services/enquiry-checklist.service';
import { ComparerService } from '../../../shared/utility/comparer';
import { AuditFormComponent } from '../../audit-form/audit-form.component';
import { AddEnquiryComponent } from './add-enquiry/add-enquiry.component';
import { EditEnquiryComponent } from './edit-enquiry/edit-enquiry.component';
import { EnquiriesAuditFormComponent } from './enquiries-audit-form/enquiries-audit-form.component';


//export interface EnquiryItem {
//  id: number,
//  caseId: number,
//  enquiry: string,
//  receivedDate: string,
//  repliedDate?: string,
//  satisfiedDate?: string,
//  isArchived: boolean
//}

@Component({
  selector: 'app-enquiries-checklist',
  templateUrl: './enquiries-checklist.component.html',
  styleUrls: ['./enquiries-checklist.component.css']
})
export class EnquiriesChecklistComponent implements OnInit {

  faPlus = faPlus;
  faConstruction = faConstruction;
  faHistory = faHistory;

  displayedColumns: string[] = ['enquiry', 'receivedDate', 'repliedDate', 'satisfiedDate', 'actions'];

  caseId: number = -1;
  error: boolean = false;
  errorMessage: string = "";
  showSpinner: boolean = false;

  items: MatTableDataSource<any>[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public componentData: any,
    //public presubmissionChecklistService: PresubmissionChecklistService,
    public dialogRef: MatDialogRef<EnquiriesChecklistComponent>,
    public dialog: MatDialog,
    private enquiryService: EnquiryChecklistService,
    private comp: ComparerService,
    private auditService: AuditService  ) {
    this.caseId = this.componentData.caseId;
  }

  ngOnInit(): void {
    this.loadEnquires();
  }

  loadEnquires() {
    this.toggleSpinner();
    // call service to load enquires
    this.enquiryService.getEnquiryChecklistForCase(this.caseId)
      .subscribe(data => {
        this.items = data.filter(x => x.isArchived == false);
        this.toggleSpinner(false);

      });
  }

  loadEnquiry(id: number) {
   
   
  }
  openAuditForForm() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      caseId: this.caseId,
      formName: "EnquiryChecklist"
    };

    this.dialog.open(EnquiriesAuditFormComponent, dialogConfig);
  }

  addEnquiry() {
    var dialogRef = this.dialog.open(AddEnquiryComponent, {
      data: { caseId: this.caseId }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        this.enquiryService.addEnquiry(this.caseId, result)
          .subscribe(data => {
            this.loadEnquires();
          });
      }

    });
  }
  editEnquiry(enquiryId: number, enquiry: string) {
    var dialogRef = this.dialog.open(EditEnquiryComponent, {
      data: {
        enquiryId: enquiryId,
        enquiry: enquiry
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        this.enquiryService.setEnquiry(enquiryId, result)
          .subscribe(data => {
            this.addAudit(this.caseId, enquiryId, "enquiry", enquiry, result);

            this.loadEnquires();
          });
      }

    });
  }

  archiveEnquiry(enquiryId: number) {

    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Are you sure you want to remove this enquiry?', popupText1: '', popupText2: '' }
    });

    dialogRef.afterClosed().subscribe((result: any) => {

      if (result) {
        this.enquiryService.archive(enquiryId)
          .subscribe(data => {
            this.addAudit(this.caseId, enquiryId, "isArchived", false, true);

            this.loadEnquires();
          });
      }
    });

  }

  showDatePicker(enquiryId: number, type: string, dateValue?: Date) {
   
    var dialogRef = this.dialog.open(DatePickerComponent, {
      data: { dateValue: dateValue, canClear: (type!="received") }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null && result.date != dateValue) {
        
        // call service to update date.
        switch (type) {
          case "receivedDate":
            this.enquiryService.setDateReceived(enquiryId, result.date)
              .subscribe(data => {
                this.loadEnquires();
              });
            break;
          case "repliedDate":
            this.enquiryService.setDateReplied(enquiryId, result.date)
              .subscribe(data => {
                this.loadEnquires();
              });
            break;
          case "satisfiedDate":
            this.enquiryService.setDateSatisfied(enquiryId, result.date)
              .subscribe(data => {
                this.loadEnquires();
              });
            break;
        }

       
        this.addAudit(this.caseId, enquiryId, type, dateValue, result.date);
      }

    });
  }

  addAudit(caseId: number, enquiryId: number, field: string, oldValue: any, newValue: any) {
    var _old: any = {};
    _old[field] = oldValue;
    var _new: any = {};
    _new[field] = newValue;

    if (this.comp.objectsDifferent(_old, _new)) {
      this.auditService.auditForm(this.caseId, 'EnquiryChecklist', _old, _new, enquiryId).subscribe((response: any) => {
      });
    }
  }

  private toggleSpinner(show: boolean = true) {
    var _this = this;

    if (show) {
      _this.showSpinner = true;
    } else {
      setTimeout(function () {
        _this.showSpinner = false;    
      }, 100)
    }
  }
}
