import { Component, OnInit, Inject, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { faTimes} from '@fortawesome/pro-light-svg-icons';
import { CorrespondenceTimelineService } from '../../../../shared/services/correspondence-timeline.service';
import { InvoiceService } from '../../../../shared/services/invoice.service';

@Component({
  selector: 'app-invoice-uploader',
  templateUrl: './invoice-uploader.component.html',
  styleUrls: ['./invoice-uploader.component.scss']
})
export class InvoiceUploaderComponent implements OnInit {

  files : any = [];
  uploadingInvoice: boolean = false;
  faTimes= faTimes;
  feeTypes: any = [];
  feeType: any;
  caseId: number;
  hasError: boolean = false;
  errorMessage: string = "";

  @ViewChild('innerContent') innerContent: ElementRef | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,              
  public dialog: MatDialog,
  private renderer: Renderer2,
  private invoiceService: InvoiceService,
  public dialogRef: MatDialogRef<InvoiceUploaderComponent>,
  private correspondenceTimelineService: CorrespondenceTimelineService) { 

    this.caseId = data.caseId;
    //this.invoiceTypes = [{ id: 1, name: "SDLT" }, { id: 2, name: "AML" }, { id: 3, name: "Something else" }];

    this.invoiceService.getFeeTypesWithSuppliers().subscribe((response : any) => {
      this.feeTypes = response.feeTypes;

      //this.invoiceTypes.push({id: 0, description: "Other", net: 0});
    });

  }

  ngOnInit(): void {
  }

  dragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    //this.renderer.setStyle(this.innerContent?.nativeElement, 'pointer-events', 'none');
  }

  dragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

  dragDropOLD(event: any) {
    event.preventDefault();
    event.stopPropagation();    

    let files = event.dataTransfer.files;

    if (files.length > 0) {      
      for (var i = 0; i < files.length; i++) {
        var fileName = files[i].name;  
        this.files.push({
          fileName: fileName,
          file: files[i],
          uploading: false,
          success: false,
          error: null
        });
      }
    }

  }

  dragDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();    

    let files = event.dataTransfer.files;

    // We only want to allow one file at a time - if multiple are dragged - get first one

    if (files.length > 0) {      
        var fileName = files[0].name;  
        this.files.push({
          fileName: fileName,
          file: files[0],
          uploading: false,
          success: false,
          error: null
        });
    }
  }

  removeFile (index:number) {
    this.files.splice(index, 1);
  }

  uploadInvoice(){
    this.uploadingInvoice = true;
    this.invoiceService.uploadInvoice(this.caseId, this.files[0].file, this.feeType).subscribe((response : any) => {
      this.correspondenceTimelineService.refreshCaseTimeline.next();
      this.dialogRef.close(true);
    }, (error : any) => {
      this.hasError = true;
      this.errorMessage = error.error;
    });
  }

  /*
  uploadFile(file: any): Promise<boolean>{

    return new Promise<boolean>((resolve, reject) => {

    this.invoiceService.uploadInvoice(this.caseId, file ).subscribe((response : any) => {
      this.correspondenceTimelineService.refreshCaseTimeline.next();
      resolve(true);

    }, (error : any) => {

      resolve(false);

    });

  });

  }
  */

}


