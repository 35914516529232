<button mat-icon-button class="close" tabindex="-1" mat-dialog-close *ngIf="!generatingPDF">x</button>

<mat-dialog-content class="mat-typography" style="text-align: center;" *ngIf="generatingPDF && !generatingError">
  <h3>Generating PDF - Please do not leave the page</h3>
  <div class="progress">
    <mat-spinner color="primary" diameter="50">
    </mat-spinner>
  </div>
</mat-dialog-content>

<mat-dialog-content class="mat-typography" style="text-align: center;" *ngIf="generatingError">
  <h3 class="error">An Error Occurred</h3>
  <div class="error">
    {{errorMessage}}
  </div>
</mat-dialog-content>
