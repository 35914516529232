import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CaseComponent } from './case/case.component';
import { CaseSidebarComponent } from './case/shared/case-sidebar/case-sidebar.component';
import { SidebarButtonComponent } from './shared/components/sidebar/sidebar-button/sidebar-button.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { SidebarItemComponent } from './shared/components/sidebar/sidebar-item/sidebar-item.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DashboardSidebarComponent } from './dashboard/components/dashboard-sidebar/dashboard-sidebar.component';
import { InfoItemComponent } from './case/shared/info/info-item/info-item.component';
import { InfoComponent } from './case/shared/info/info.component';
import { TimelineItemComponent } from './case/timeline-item/timeline-item.component';
import { FiledropDirective } from './shared/components/file-drop/filedrop.directive';
import { EmailComponent } from './case/shared/email/email.component';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FileUploaderComponent } from './case/shared/file-uploader/file-uploader.component';
import { PhonecallComponent } from './case/shared/phonecall/phonecall.component';
import { FaxComponent } from './case/shared/fax/fax.component';
import { SmsComponent } from './case/shared/sms/sms.component';
import { AdhocLetterComponent } from './case/shared/adhoc-letter/adhoc-letter.component';
import { PdfCreatorComponent } from './case/shared/pdf-creator/pdf-creator.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CardBasicComponent } from './shared/components/cards/card-basic/card-basic.component';
import { LoginComponent } from './auth/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './auth/request.interceptor';
import { SearchBarComponent } from './shared/components/searchbar/searchbar.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { KeyDatesComponent } from './case/shared/key-dates/key-dates.component';
import { KeyDateComponent } from './case/shared/key-dates/key-date/key-date.component';
import { CardLabelComponent } from './shared/components/cards/card-label/card-label.component';
import { KeyDatesService } from 'src/app/shared/services/keydates.service';
import { CaseNotesComponent } from './case/shared/case-notes/case-notes.component';
import { CaseOverviewComponent } from './case/shared/case-overview/case-overview.component';
import { PresubmissionChecklistComponent } from './case/shared/presubmission-checklist/presubmission-checklist.component';
import { PreexchangeChecklistComponent } from './case/shared/preexchange-checklist/preexchange-checklist.component';
import { FileClosureComponent } from './case/shared/file-closure/file-closure.component';
import { IdCheckComponent } from './case/shared/id-check/id-check.component';
import { SearchesComponent } from './case/shared/searches/searches.component';
import { MortgageDetailsComponent } from './case/shared/mortgage-details/mortgage-details.component';
import { BrokerDetailsComponent } from './case/shared/broker-details/broker-details.component';
import { AddKeyDateComponent } from './case/shared/key-dates/add-key-date/add-key-date.component';
import { DeleteKeyDateComponent } from './case/shared/key-dates/delete-key-date/delete-key-date.component';
import { CancelCaseComponent } from './case/shared/key-dates/cancel-case/cancel-case.component';
import { KeyDatesHistoryComponent } from './case/shared/key-dates/key-dates-history/key-dates-history.component';
import { InvoiceComponent } from './case/shared/invoicing/invoice/invoice.component';
import { MatMenuModule } from '@angular/material/menu';
import { LookupsService } from 'src/app/shared/services/lookups.service';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarMonthComponent } from './calendar/calendar-month/calendar-month.component';
import { CalendarDayComponent } from './calendar/calendar-day/calendar-day.component';
import { CalendarEventComponent } from './calendar/calendar-event/calendar-event.component';
import { CalendarService } from './calendar/calendar-service.service';
import { CompanyGroupsComponent } from './company-groups/company-groups.component';
import { ConsultantsComponent } from './consultants/consultants.component';
import { UsersComponent } from './users/users.component';
import { BasicPopupOkComponent } from './shared/components/basic-popup-ok/basic-popup-ok.component';
import { BasicPopupYesNoComponent } from './shared/components/basic-popup-yes-no/basic-popup-yes-no.component';
import { SelectConsultantComponent } from './calendar/select-consultant/select-consultant.component';
import { CaseListComponent } from './case-list/case-list.component';
import { CompletedCaseListComponent } from './completed-case-list/completed-case-list.component';
import { CancelledCaseListComponent } from './cancelled-case-list/cancelled-case-list.component';
import { UninstructedCaseListComponent } from './uninstructed-case-list/uninstructed-case-list.component';
import { UnallocatedCaseListComponent } from './unallocated-case-list/unallocated-case-list.component';
import { StringFilterComponent } from './shared/components/table-filters/string-filter/string-filter.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { ArchiveCaseComponent } from './archive-case/archive-case.component';
import { AllocateComponent } from './unallocated-case-list/allocate/allocate.component';
import { CompanyGroupListComponent } from './company-groups/company-group-list/company-group-list.component';
import { CompanyGroupDetailsComponent } from './company-groups/company-group-details/company-group-details.component';
import { UserAdminComponent } from './users/user-admin/user-admin.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { ConsultantDetailsComponent } from './consultants/consultant-details/consultant-details.component';
import { ConsultantListComponent } from './consultants/consultant-list/consultant-list.component';
import { ActionsComponent } from './actions/actions.component';
import { TaskListComponent } from './tasks/task-list.component';
import { TaskService } from 'src/app/shared/services/task.service';
import { TaskPanelComponent } from './tasks/task-panel/task-panel.component';
import { MatExpansionModule } from '@angular/material/expansion/';
import { AutocompleteFilterComponent } from './shared/components/table-filters/autocomplete-filter/autocomplete-filter.component';
import { BooleanFilterComponent } from './shared/components/table-filters/boolean-filter/boolean-filter.component';
import { DateFilterComponent } from './shared/components/table-filters/date-filter/date-filter.component';
import { DropdownFilterComponent } from './shared/components/table-filters/dropdown-filter/dropdown-filter.component';
import { YesNoComponent } from './shared/components/yes-no/yes-no.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { UserListComponent } from './users/user-list/user-list.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLuxonDateModule } from 'ngx-material-luxon';
import { ConsultantsService } from './shared/services/consultants.service';
import { ReallocateTaskModalComponent } from './tasks/reallocate-task/reallocate-task.component';
import { ReallocateCaseTaskModalComponent } from './case-list/reallocate-case-task/reallocate-case-task.component';
import { ReallocateCaseModalComponent } from './case-list/reallocate-case/reallocate-case.component';
import { AddTaskComponent } from './tasks/add-task/add-task.component';
import { NewCaseComponent } from './new-case/new-case.component';
import { SearchCaseComponent } from './search-case/search-case.component';
import { AddCaseNotesComponent } from './case/shared/case-notes/add-case-notes/add-case-notes.component';
import { CaseNotesHistoryComponent } from './case/shared/case-notes/case-notes-history/case-notes-history.component';
import { CaseNoteComponent } from './case/shared/case-notes/case-note/case-note.component';
import { MatListModule } from '@angular/material/list';
import { OnHoldComponent } from './on-hold/on-hold.component';
import { OffHoldComponent } from './off-hold/off-hold.component';
import { BankstatementCheckComponent } from './case/shared/key-dates/bankstatement-check/bankstatement-check.component';
import { TimecharDirective } from './case/shared/key-dates/add-key-date/timechar.directive';
import { CaseOverviewHistoryComponent } from './case/shared/case-overview/case-overview-history/case-overview-history.component';
import { AddCaseOverviewItemComponent } from './case/shared/case-overview/add-case-overview-item/add-case-overview-item.component';
import { EquityReleasePropertyDetailsComponent } from './case/shared/property-details/equity-release-property-details/equity-release-property-details.component';
import { EquityReleasePurchasePropertyDetailsComponent } from './case/shared/property-details/equity-release-purchase-property-details/equity-release-purchase-property-details.component';
import { ConveyancingSalePropertyDetailsComponent } from './case/shared/property-details/conveyancing-sale-property-details/conveyancing-sale-property-details.component';
import { AddressLookupComponent } from './shared/components/address-lookup/address-lookup.component';
import { AddressLookupButtonComponent } from './shared/components/address-lookup/address-lookup-button/address-lookup-button.component';
import { ConveyancingSaleEstateAgentDetailsComponent } from './case/shared/estate-agent-details/conveyancing-sale-estate-agent-details/conveyancing-sale-estate-agent-details.component';
import { EquityReleasePurchaseEstateAgentDetailsComponent } from './case/shared/estate-agent-details/equity-release-purchase-estate-agent-details/equity-release-purchase-estate-agent-details.component';
import { SelectEmailAddressComponent } from './case/shared/email/select-email-address/select-email-address.component';
import { CaseFilesService } from './shared/services/casefiles.service';
import { EmailService } from './shared/services/email.service';
import { SMSService } from './shared/services/sms.service';
import { FaxService } from './shared/services/fax.service';
import { CtrlClickDirective } from './shared/directives/ctrl-click.directive';
import { UnassignedFilesComponent } from './unassigned-files/unassigned-files.component';
import { RenameItemComponent } from './shared/components/rename-item/rename-item.component';
import { SendToCaseComponent } from './unassigned-files/send-to-case/send-to-case.component';
import { UnassignedFileComponent } from './unassigned-files/unassigned-file/unassigned-file.component';
import { CorrespondenceTimelineService } from './shared/services/correspondence-timeline.service';
import { PresubmissionChecklistService } from './shared/services/presubmission-checklist.service';
import { PreexchangeChecklistService } from './shared/services/preexchange-checklist.service';
import { LettersComponent } from './case/shared/letters/letters.component';
import { LettersService } from './shared/services/letters.service';
import { LetterTemplateComponent } from './case/shared/letters/letter-template/letter-template.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { FileClosureService } from './shared/services/file-closure.service';
import { CardInstructionComponent } from './shared/components/cards/card-instruction/card-instruction.component';
import { ConveyancingPurchasePropertyDetailsComponent } from './case/shared/property-details/conveyancing-purchase-property-details/conveyancing-purchase-property-details.component';
import { ConveyancingPurchaseEstateAgentDetailsComponent } from './case/shared/estate-agent-details/conveyancing-purchase-estate-agent-details/conveyancing-purchase-estate-agent-details.component';
import { ComparerService } from './shared/utility/comparer';
import { AuditService } from './shared/services/audit.service';
import { AuditFormComponent } from './case/audit-form/audit-form.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ViewCaseNoteComponent } from './case/shared/case-notes/view-case-note/view-case-note.component';
import { LendersComponent } from './lenders/lenders.component';
import { LenderSolicitorsComponent } from './lender-solicitors/lender-solicitors.component';
import { LenderSolicitorsListComponent } from './lender-solicitors/lender-solicitors-list/lender-solicitors-list.component';
import { LenderSolicitorDetailsComponent } from './lender-solicitors/lender-solicitor-details/lender-solicitor-details.component';
import { LendersListComponent } from './lenders/lenders-list/lenders-list.component';
import { LenderDetailsComponent } from './lenders/lender-details/lender-details.component';
import { LendersSolicitorService } from './shared/services/lenders-solicitor.service';
import { LendersService } from './shared/services/lenders.service';
import { EditAuthorisedSignatoryComponent } from './case/shared/edit-authorised-signatory/edit-authorised-signatory.component';
import { AddInvoiceItemComponent } from './case/shared/invoicing/add-invoice-item/add-invoice-item.component';
import { EditInvoiceItemComponent } from './case/shared/invoicing/edit-invoice-item/edit-invoice-item.component';
import { InvoiceService } from './shared/services/invoice.service';
import { ArchiveTimelineItemComponent } from './case/shared/archiving-items/archive-timeline-item/archive-timeline-item.component';
import { ArchivedTimelineItemListComponent } from './case/shared/archiving-items/archived-timeline-item-list/archived-timeline-item-list.component';
import { CaseFundsComponent } from './case/shared/case-funds/case-funds.component';
import { AddFundComponent } from './case/shared/case-funds/add-fund/add-fund.component';
import { CaseFundsService } from './shared/services/case-funds.service';
import { DocumentPreviewComponent } from './case/shared/document-preview/document-preview.component';
import { EmailQueueComponent } from './email-queue/email-queue.component';
import { CaseBankDetailsService } from './shared/services/case-bank-details.service';
import { BankDetailsComponent } from './case/shared/bank-details/bank-details.component';
import { KlyantComponent } from './klyant/klyant.component';
import { KlyantService } from './shared/services/klyant.service';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsService } from './shared/services/notifications.service';
import { CaseHeaderComponent } from './case/shared/case-header/case-header.component';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { ItemPdfGeneratorComponent } from './case/shared/item-pdf-generator/item-pdf-generator.component';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { NotificationHistoryComponent } from './notification-history/notification-history.component';
import { TransferNotificationComponent } from './notifications-list/transfer-notification/transfer-notification.component';
import { LetterSearchPipe } from './case/shared/letters/letter-search.pipe';
import { EnquiriesChecklistComponent } from './case/shared/enquiries-checklist/enquiries-checklist.component';
import { DatePickerComponent } from './shared/components/date-picker/date-picker.component';
import { AddEnquiryComponent } from './case/shared/enquiries-checklist/add-enquiry/add-enquiry.component';
import { EditEnquiryComponent } from './case/shared/enquiries-checklist/edit-enquiry/edit-enquiry.component';
import { EnquiriesAuditFormComponent } from './case/shared/enquiries-checklist/enquiries-audit-form/enquiries-audit-form.component';
import { OnholdCaseListComponent } from './onhold-case-list/onhold-case-list.component';
import { CreditSafeCheckComponent } from './case/shared/credit-safe-check/credit-safe-check.component';
import { ManualCheckComponent } from './case/shared/credit-safe-check/manual-check/manual-check.component';
import { CreditSafeCheckHistoryComponent } from './case/shared/credit-safe-check/credit-safe-check-history/credit-safe-check-history.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EmailBodyHtmlPipe } from './case/shared/email/email-bodyhtmlstyle.pipe';
import { PreInvoiceFeesComponent } from './case/shared/pre-invoice-fees/pre-invoice-fees.component';
import { AddPreInvoiceFeeComponent } from './case/shared/pre-invoice-fees/add-fee/add-pre-invoice-fee.component';
import { PreInvoiceFeesService } from './shared/services/pre-invoice-fees.service';
import { TransactionsComponent } from './case/shared/transactions/transactions.component';
import { AddTransactionComponent } from './case/shared/transactions/add-transaction/add-transaction.component'
import { TransactionsService } from './shared/services/transactions-service';
import { SupplierInvoiceService } from './shared/services/supplier-invoice.service'
import { MatRadioModule } from '@angular/material/radio';
import { AllowanceComponent } from './case/shared/allowance/allowance.component';
import { AllowancesService } from './shared/services/allowances.service';
import { AddAllowanceComponent } from './case/shared/allowance/add-allowance/add-allowance.component';
import { InvoiceUploaderComponent } from './case/shared/invoicing/invoice-uploader/invoice-uploader.component';
import { SelectPreInvoiceFeeSupplierComponent } from './case/shared/pre-invoice-fees/select-pre-invoice-fee-supplier/select-pre-invoice-fee-supplier.component';
import { InvoiceListComponent } from './case/shared/invoicing/invoice-list/invoice-list.component';
import { AddInvoiceComponent } from './case/shared/invoicing/add-invoice/add-invoice.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    CaseComponent,
    CaseSidebarComponent,
    SidebarButtonComponent,
    SidebarItemComponent,
    DashboardSidebarComponent,
    InfoComponent,
    InfoItemComponent,
    TimelineItemComponent,
    FiledropDirective,
    EmailComponent,
    FileUploaderComponent,
    PhonecallComponent,
    FaxComponent,
    SmsComponent,
    AdhocLetterComponent,
    PdfCreatorComponent,
    CardBasicComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SearchBarComponent,
    KeyDatesComponent,
    KeyDateComponent,
    // CardComponent,
    CardLabelComponent,
    CaseNotesComponent,
    CaseOverviewComponent,
    PresubmissionChecklistComponent,
    PreexchangeChecklistComponent,
    FileClosureComponent,
    IdCheckComponent,
    SearchesComponent,
    MortgageDetailsComponent,
    BrokerDetailsComponent,
    AddKeyDateComponent,
    DeleteKeyDateComponent,
    CancelCaseComponent,
    KeyDatesHistoryComponent,
    InvoiceComponent,
    CalendarComponent,
    CalendarMonthComponent,
    CalendarDayComponent,
    CalendarEventComponent,
    CompanyGroupsComponent,
    ConsultantsComponent,
    UsersComponent,
    BasicPopupOkComponent,
    BasicPopupYesNoComponent,
    SelectConsultantComponent,
    CaseListComponent,
    CompletedCaseListComponent,
    CancelledCaseListComponent,
    UninstructedCaseListComponent,
    UnallocatedCaseListComponent,
    StringFilterComponent,
    ArchiveCaseComponent,
    AllocateComponent,
    CompanyGroupListComponent,
    CompanyGroupDetailsComponent,
    UserAdminComponent,
    UsersListComponent,
    ConsultantDetailsComponent,
    ConsultantListComponent,
    ActionsComponent,
    TaskListComponent,
    TaskPanelComponent,
    AutocompleteFilterComponent,
    BooleanFilterComponent,
    DateFilterComponent,
    DropdownFilterComponent,
    YesNoComponent,
    UserListComponent,
    ReallocateTaskModalComponent,
    ReallocateCaseTaskModalComponent,
    ReallocateCaseModalComponent,
    AddTaskComponent,
    NewCaseComponent,
    SearchCaseComponent,
    AddCaseNotesComponent,
    CaseNotesHistoryComponent,
    CaseNoteComponent,
    OnHoldComponent,
    OffHoldComponent,
    BankstatementCheckComponent,
    TimecharDirective,
    CaseOverviewHistoryComponent,
    AddCaseOverviewItemComponent,
    EquityReleasePropertyDetailsComponent,
    EquityReleasePurchasePropertyDetailsComponent,
    ConveyancingSalePropertyDetailsComponent,
    AddressLookupComponent,
    AddressLookupButtonComponent,
    ConveyancingSaleEstateAgentDetailsComponent,
    EquityReleasePurchaseEstateAgentDetailsComponent,
    SelectEmailAddressComponent,
    CtrlClickDirective,
    UnassignedFilesComponent,
    RenameItemComponent,
    SendToCaseComponent,
    UnassignedFileComponent,
    LettersComponent,
    LetterTemplateComponent,
    ResetPasswordComponent,
    CardInstructionComponent,
    ConveyancingPurchasePropertyDetailsComponent,
    ConveyancingPurchaseEstateAgentDetailsComponent,
    AuditFormComponent,
    ViewCaseNoteComponent,
    LendersComponent,
    LenderSolicitorsComponent,
    LenderSolicitorsListComponent,
    LenderSolicitorDetailsComponent,
    LendersListComponent,
    LenderDetailsComponent,
    EditAuthorisedSignatoryComponent,
    AddInvoiceItemComponent,
    EditInvoiceItemComponent,
    ArchiveTimelineItemComponent,
    ArchivedTimelineItemListComponent,
    CaseFundsComponent,
    AddFundComponent,
    DocumentPreviewComponent,
    EmailQueueComponent,
    BankDetailsComponent,
    KlyantComponent,
    NotificationsComponent,
    CaseHeaderComponent,
    ItemPdfGeneratorComponent,
    NotificationsListComponent,
    NotificationHistoryComponent,
    TransferNotificationComponent,
    LetterSearchPipe,
    EnquiriesChecklistComponent,
    DatePickerComponent,
    AddEnquiryComponent,
    EditEnquiryComponent,
    EnquiriesAuditFormComponent,
    OnholdCaseListComponent,
    CreditSafeCheckComponent,
    ManualCheckComponent,
    CreditSafeCheckHistoryComponent,
    EmailBodyHtmlPipe,
    PreInvoiceFeesComponent,
    AddPreInvoiceFeeComponent,    
    TransactionsComponent,
    AddTransactionComponent,
    AllowanceComponent,
    AddAllowanceComponent,
    InvoiceUploaderComponent,
    SelectPreInvoiceFeeSupplierComponent,
    InvoiceListComponent,
    AddInvoiceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    MatRippleModule,
    MatTooltipModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    CommonModule,
    DragDropModule,
    MatCheckboxModule,
    FormsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSortModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatLuxonDateModule,
    MatListModule,
    FlexLayoutModule,
    NgxMatColorPickerModule,
    CKEditorModule,
    MatRadioModule
  ],
  exports: [

  ],
  providers: [
    KlyantService,
    KeyDatesService,
    LookupsService,
    CalendarService,
    TaskService,
    ConsultantsService,
    CaseFilesService,
    EmailService,
    SMSService,
    FaxService,
    CorrespondenceTimelineService,
    LettersService,
    PresubmissionChecklistService,
    PreexchangeChecklistService,
    FileClosureService,
    ComparerService,
    AuditService,
    LendersSolicitorService,
    LendersService,
    InvoiceService,
    CaseFundsService,
    CaseBankDetailsService,
    NotificationsService,
    PreInvoiceFeesService,
    TransactionsService,
    SupplierInvoiceService,
    AllowancesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    /*{
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: { firstDayOfWeek },
    },*/
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
