import { Component, OnInit, Input } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { AddressLookupComponent } from '../address-lookup.component';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-address-lookup-button',
  templateUrl: './address-lookup-button.component.html',
  styleUrls: ['./address-lookup-button.component.css'],
  host: {
    // Always set the tabindex to -1 so that it doesn't overlap with any custom tabindex the
    // consumer may have provided, while still being able to receive focus.
    '[attr.tabindex]': 'disabled ? null : -1',
    //'[class.mat-datepicker-toggle-active]': 'datepicker && datepicker.opened',
    '[class.mat-accent]': 'datepicker && datepicker.color === "accent"',
    '[class.mat-warn]': 'datepicker && datepicker.color === "warn"',
    '(focus)': '_button.focus()',
  },
})
export class AddressLookupButtonComponent implements OnInit {

  faSearch = faSearch;


  @Input('for') addressLookup: any;//AddressLookupComponent;

  constructor() { }

  ngOnInit(): void {
  }

  search() {

    if (this.addressLookup) {
      this.addressLookup.search();
    }
  }

}
