import { Component, OnInit, Inject } from '@angular/core';
import { InvoiceService } from '../../../../shared/services/invoice.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-edit-invoice-item',
  templateUrl: './edit-invoice-item.component.html',
  styleUrls: ['./edit-invoice-item.component.css']
})
export class EditInvoiceItemComponent implements OnInit {

  invoiceItem: any;
  invoiceId: number;
  caseId: number;
  feeType: number = 1;
  feeTypes: any = [];
  feeTypeName: string = "Professional Charge";

  editInvoiceItemDetails = this.fb.group({
    invoiceItemId: [],
    description: [],
    net: [],
    addVat: false,
    outlayId: [],
    nominalCode: []
  }, { });

  constructor(private invoiceService: InvoiceService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              public dialogRef: MatDialogRef<EditInvoiceItemComponent>) {

    this.caseId = data.caseId;
    this.invoiceItem = data.invoiceItem;
    this.invoiceId = data.invoiceId;
    this.feeType = this.invoiceItem.feeType;

    // Set the title based on the API enum description
    this.invoiceService.getFeeTypes().subscribe((response : any) => {      
      this.feeTypes = response;
      this.feeTypeName = this.feeTypes.filter(x => x.key == this.feeType)[0].value;
    });

    this.editInvoiceItemDetails.controls["invoiceItemId"].patchValue(this.invoiceItem.id);
    this.editInvoiceItemDetails.controls["description"].patchValue(this.invoiceItem.description);
    this.editInvoiceItemDetails.controls["net"].patchValue(this.invoiceItem.net);

    if(this.invoiceItem.vat > 0)
      this.editInvoiceItemDetails.controls["addVat"].patchValue(true);
  }

  ngOnInit(): void {
  }

  editInvoiceItem(){
    this.invoiceService.editInvoiceItem( this.caseId, this.invoiceId, this.editInvoiceItemDetails.value ).subscribe((response : any) => {
      this.dialogRef.close(true);
    });
  }
}
