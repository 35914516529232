import { Component, Input, OnInit } from '@angular/core';
import {faPencilAlt, faQuestionCircle, faCheckCircle, faExclamationCircle} from '@fortawesome/pro-light-svg-icons';
import { EditAuthorisedSignatoryComponent } from './../edit-authorised-signatory/edit-authorised-signatory.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CaseService } from '../../../shared/services/case.service';
import { DateTime } from 'luxon';
import { CreditSafeCheckComponent } from './../credit-safe-check/credit-safe-check.component';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  @Input() case: any;
  @Input() isMedium: boolean = false;
  @Input() isSmall: boolean = false;

  faPencilAlt = faPencilAlt;
  faQuestionCircle = faQuestionCircle;
  faCheckCircle = faCheckCircle;
  faExclamationCircle = faExclamationCircle;

  constructor(public dialog: MatDialog,
              private caseService: CaseService) { }

  ngOnInit() {
   
  }

  editAuthorisedSignatory(){

    console.log(this.case.caseData.id);

    const dialogConfig = new MatDialogConfig();

    //dialogConfig.disableClose = true;
      dialogConfig.data = {
        caseId: this.case.caseData.id,
        authorisedSignatoryUserId: this.case.caseData.authorisedSignatoryUserId
    };

    const dialogRef = this.dialog.open(EditAuthorisedSignatoryComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result == true){
        this.caseService.refreshCase.next();
      }
    });

  }

  editCreditSafe(clientNo: number) {
    const dialogConfig = new MatDialogConfig();

    //dialogConfig.disableClose = true;
    var client1City = this.isNullOrEmpty(this.case.caseData.partyOneAddressLine2) ? this.case.caseData.partyOneAddressLine3 : this.case.caseData.partyOneAddressLine2;
    var client2City = this.isNullOrEmpty(this.case.caseData.partyTwoAddressLine2) ? this.case.caseData.partyTwoAddressLine3 : this.case.caseData.partyTwoAddressLine2;

    dialogConfig.data = {
      creditCheckData: {
        caseId: this.case.caseData.id,
        titleId: clientNo == 1 ? this.case.caseData.partyOneTitleId : this.case.caseData.partyTwoTitleId,
        firstName: clientNo == 1 ? this.case.caseData.partyOneFirstName : this.case.caseData.partyTwoFirstName,
        otherNames: clientNo == 1 ? this.case.caseData.partyOneMiddleNames : this.case.caseData.partyTwoMiddleNames,
        surname: clientNo == 1 ? this.case.caseData.partyOneLastName : this.case.caseData.partyTwoLastName,
        dateOfBirth: clientNo == 1 ? this.case.caseData.partyOneDateOfBirth : this.case.caseData.partyTwoDateOfBirth,
        buildingNo: clientNo == 1 ? this.getAddressBuildingNo(this.case.caseData.partyOneAddressLine1) : this.getAddressBuildingNo(this.case.caseData.partyTwoAddressLine1),
        buildingName: '',
        street: clientNo == 1 ? this.getAddressStreet(this.case.caseData.partyOneAddressLine1) : this.getAddressStreet(this.case.caseData.partyTwoAddressLine1),
        city: clientNo == 1 ? client1City : client2City,
        postCode: clientNo == 1 ? this.case.caseData.partyOnePostcode : this.case.caseData.partyTwoPostcode,
        clientNo: clientNo
      }
    };
   
    const dialogRef = this.dialog.open(CreditSafeCheckComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.caseService.refreshCase.next();
      }
    });
  }

  formatDate(inDate: any){

    var formattedDate = '';


    if(inDate !== null)
    {

      var dt = DateTime.fromISO(inDate);

      formattedDate = dt.toFormat("dd-MM-yyyy");

    if(formattedDate == 'Invalid date' || formattedDate == 'Invalid DateTime')
      formattedDate = '';

    }


    return formattedDate;

  }
  private getAddressBuildingNo(address: string): any {
    if (address != null) {
      if (address.split(" ").length > 0) {
        var no = address.split(" ")[0].charAt(0);
       
        if (Number(no)) {
          return address.split(" ")[0];
        }
      } else {
        if (Number(address)) {
          return address;
        }
      }
     
    }

    return null;
  }
  private getAddressStreet(address: string): any {
    if (address != null) {
      if (address.split(" ").length > 0) {
        var row = address.split(" ");
        var no = row[0].charAt(0);

        if (Number(no)) {
          row.shift();
          return row.join(" ").trim();
        } else {
          return row.join(" ").trim();
        }
      } else {
        if (!Number(address)) {
          return address.trim();
        }
      }

    }

    return null;
  }

  private isNullOrEmpty(stringToCheck: string) {
    if (stringToCheck == null) return true;
    if (stringToCheck == "") return true;
    return false;
  }
}
