<button mat-icon-button class="close" tabindex="-1" (click)="close()">x</button>
<button mat-icon-button class="audit" tabindex="-1" (click)="openAuditForForm()"><fa-icon [icon]="faHistory"></fa-icon></button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Property Details</h2>

<mat-dialog-content class="mat-typography">

    <!--mat-spinner color="primary" diameter="20" *ngIf="!dataLoaded"></mat-spinner-->

  <form [formGroup]="propertyDetails" autocomplete="off" >

      <app-card-label label="Is the Sale address the same as the correspondance address?" >
          <app-yes-no formControlName="saleAddressSameAsCorrespondenceAddress" (change)="updateField()" ></app-yes-no>
        </app-card-label>

<span [hidden]="propertyDetails.controls['saleAddressSameAsCorrespondenceAddress'].value != 0">

  <app-card-label label="Sale Postcode">
    <mat-form-field floatLabel="never">
      <input matInput #salePostcode maxlength="10" formControlName="salePostcode"
        (change)="updateField()">
      <app-address-lookup-button matSuffix [for]="addressLookup1"></app-address-lookup-button>
    </mat-form-field>
  </app-card-label>

  <app-address-lookup #addressLookup1 lookupType="postcode" [searchField]="salePostcode" [add1]="propertyDetails.controls['saleAddressLine1']"
    [add2]="propertyDetails.controls['saleAddressLine2']" [add3]="propertyDetails.controls['saleAddressLine3']" [add4]="propertyDetails.controls['saleAddressLine4']"
    [add5]="propertyDetails.controls['saleAddressLine5']" [addPc]="propertyDetails.controls['salePostcode']" (change)="updateField();">
  </app-address-lookup>

  <app-card-label label="Sale Address Line 1">
    <mat-form-field floatLabel="never">
      <input matInput #saleAddressLine1  maxlength="100"
        formControlName="saleAddressLine1" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Sale Address Line 2">
    <mat-form-field floatLabel="never">
      <input matInput #saleAddressLine2 maxlength="100"
        formControlName="saleAddressLine2" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Sale Address Line 3">
    <mat-form-field floatLabel="never">
      <input matInput #saleAddressLine3 maxlength="100"
        formControlName="saleAddressLine3" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Sale Address Line 4">
    <mat-form-field floatLabel="never">
      <input matInput #saleAddressLine4 maxlength="100"
        formControlName="saleAddressLine4" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Sale Address Line 5">
    <mat-form-field floatLabel="never">
      <input matInput #saleAddressLine5 maxlength="100"
        formControlName="saleAddressLine5" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

</span>

<app-card-label label="Is property Unregistered?">
  <app-yes-no formControlName="propertyIsUnregistered" (change)="updateField()" ></app-yes-no>
</app-card-label>

<app-card-label label="Title Number">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="titleNumber" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Does the the client full legal name match the Title deeds?">
  <app-yes-no formControlName="clientFullNameMatchTitleDeeds" (change)="updateField()" ></app-yes-no>
</app-card-label>

<app-card-label label="Reason" [hidden]="propertyDetails.controls['clientFullNameMatchTitleDeeds'].value != 0">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="clientFullNameMatchTitleDeedsReason" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Statement of Truth Regarding Name Discrepancy Required?" [hidden]="propertyDetails.controls['clientFullNameMatchTitleDeeds'].value != 0">
  <app-yes-no formControlName="statementOfTruthRegardingNameDiscrepencyRequired" (change)="updateField()" ></app-yes-no>
</app-card-label>

<app-card-label label="Property Type">
    <mat-form-field floatLabel="never">
        <mat-select placeholder="Select" formControlName="propertyType" (selectionChange)="updateField()">
                <mat-option [value]="1">Leasehold</mat-option>
                <mat-option [value]="2">Freehold</mat-option>
        </mat-select>
    </mat-form-field>
</app-card-label>

<app-card-label label="Title Guarantee Type">
  <mat-form-field floatLabel="never">
      <mat-select placeholder="Select" formControlName="titleGuaranteeType" (selectionChange)="updateField()">
              <mat-option [value]="1">Full Title Guarantee</mat-option>
              <mat-option [value]="2">Limited Title Guarantee</mat-option>
      </mat-select>
  </mat-form-field>
</app-card-label>


<span [hidden]="propertyDetails.controls['propertyType'].value != 1">

  <app-card-label label="Landlord Full Name">
    <mat-form-field floatLabel="never">
      <input matInput #landlordName maxlength="100"
        formControlName="landlordName" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Landlord Postcode">
    <mat-form-field floatLabel="never">
      <input matInput #landlordPostcode maxlength="10" formControlName="landlordPostcode"
        (change)="updateField()">
      <app-address-lookup-button matSuffix [for]="addressLookup4"></app-address-lookup-button>
    </mat-form-field>
  </app-card-label>

  <app-address-lookup #addressLookup4 lookupType="postcode" [searchField]="landlordPostcode" [add1]="propertyDetails.controls['landlordAddressLine1']"
    [add2]="propertyDetails.controls['landlordAddressLine2']" [add3]="propertyDetails.controls['landlordAddressLine3']" [add4]="propertyDetails.controls['landlordAddressLine4']"
    [add5]="propertyDetails.controls['landlordAddressLine5']" [addPc]="propertyDetails.controls['landlordPostcode']" (change)="updateField();">
  </app-address-lookup>

  <app-card-label label="Landlord Address Line 1">
    <mat-form-field floatLabel="never">
      <input matInput #landlordAddressLine1 maxlength="100"
        formControlName="landlordAddressLine1" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Landlord Address Line 2">
    <mat-form-field floatLabel="never">
      <input matInput #landlordAddressLine2 maxlength="100"
        formControlName="landlordAddressLine2" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Landlord Address Line 3">
    <mat-form-field floatLabel="never">
      <input matInput #landlordAddressLine3 maxlength="100"
        formControlName="landlordAddressLine3" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Landlord Address Line 4">
    <mat-form-field floatLabel="never">
      <input matInput #landlordAddressLine4 maxlength="100"
        formControlName="landlordAddressLine4" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Landlord Address Line 5">
    <mat-form-field floatLabel="never">
      <input matInput #landlordAddressLine5 maxlength="100"
        formControlName="landlordAddressLine5" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Name">
    <mat-form-field floatLabel="never">
      <input matInput formControlName="managementCompanyName" (change)="updateField()" >
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Postcode">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyPostcode maxlength="10" formControlName="managementCompanyPostcode"
        (change)="updateField()">
      <app-address-lookup-button matSuffix [for]="addressLookup5"></app-address-lookup-button>
    </mat-form-field>
  </app-card-label>

  <app-address-lookup #addressLookup5 lookupType="postcode" [searchField]="managementCompanyPostcode" [add1]="propertyDetails.controls['managementCompanyAddressLine1']"
    [add2]="propertyDetails.controls['managementCompanyAddressLine2']" [add3]="propertyDetails.controls['managementCompanyAddressLine3']" [add4]="propertyDetails.controls['managementCompanyAddressLine4']"
    [add5]="propertyDetails.controls['managementCompanyAddressLine5']" [addPc]="propertyDetails.controls['managementCompanyPostcode']" (change)="updateField();">
  </app-address-lookup>

  <app-card-label label="Management Company Address Line 1">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyAddressLine1 maxlength="100"
        formControlName="managementCompanyAddressLine1" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Address Line 2">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyAddressLine2 maxlength="100"
        formControlName="managementCompanyAddressLine2" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Address Line 3">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyAddressLine3 maxlength="100"
        formControlName="managementCompanyAddressLine3" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Address Line 4">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyAddressLine4  maxlength="100"
        formControlName="managementCompanyAddressLine4" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Management Company Address Line 5">
    <mat-form-field floatLabel="never">
      <input matInput #managementCompanyAddressLine5 maxlength="100"
        formControlName="managementCompanyAddressLine5" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

</span>

<app-card-label label="Solar Panels">
  <app-yes-no formControlName="solarPanelsPresent" (change)="updateField()" ></app-yes-no>
</app-card-label>

<span [hidden]="propertyDetails.controls['solarPanelsPresent'].value != 1">

    <app-card-label label="Are the solar panels owned outright?">
        <app-yes-no formControlName="solarPanelsOwnedOutright" (change)="updateField()" ></app-yes-no>
      </app-card-label>

      <span [hidden]="propertyDetails.controls['solarPanelsOwnedOutright'].value != 0">

          <app-card-label label="Solar Panels Company Name">
              <mat-form-field floatLabel="never">
                <input matInput formControlName="solarPanelsCompany" (change)="updateField()" >
              </mat-form-field>
            </app-card-label>


            <app-card-label label="Solar Panel Owner Postcode">
                <mat-form-field floatLabel="never">
                  <input matInput #solarPanelsOwnerPostcode maxlength="10" formControlName="solarPanelsOwnerPostcode"
                    (change)="updateField()">
                  <app-address-lookup-button matSuffix [for]="addressLookup2"></app-address-lookup-button>
                </mat-form-field>
              </app-card-label>

          <app-address-lookup #addressLookup2 lookupType="postcode" [searchField]="solarPanelsOwnerPostcode" [add1]="propertyDetails.controls['solarPanelsOwnerAddressLine1']"
          [add2]="propertyDetails.controls['solarPanelsOwnerAddressLine2']" [add3]="propertyDetails.controls['solarPanelsOwnerAddressLine3']" [add4]="propertyDetails.controls['solarPanelsOwnerAddressLine4']"
          [add5]="propertyDetails.controls['solarPanelsOwnerAddressLine5']" [addPc]="propertyDetails.controls['solarPanelsOwnerPostcode']" (change)="updateField();">
        </app-address-lookup>

        <app-card-label label="Solar Panel Owner Address Line 1">
          <mat-form-field floatLabel="never">
            <input matInput #solarPanelsOwnerAddressLine1 maxlength="100"
              formControlName="solarPanelsOwnerAddressLine1" (change)="updateField()">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Solar Panel Owner Address Line 2">
          <mat-form-field floatLabel="never">
            <input matInput #solarPanelsOwnerAddressLine2 maxlength="100"
              formControlName="solarPanelsOwnerAddressLine2" (change)="updateField()">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Solar Panel Owner Address Line 3">
          <mat-form-field floatLabel="never">
            <input matInput #solarPanelsOwnerAddressLine3 maxlength="100"
              formControlName="solarPanelsOwnerAddressLine3" (change)="updateField()">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Solar Panel Owner Address Line 4">
          <mat-form-field floatLabel="never">
            <input matInput #solarPanelsOwnerAddressLine4  maxlength="100"
              formControlName="solarPanelsOwnerAddressLine4" (change)="updateField()">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Solar Panel Owner Address Line 5">
          <mat-form-field floatLabel="never">
            <input matInput #solarPanelsOwnerAddressLine5 maxlength="100"
              formControlName="solarPanelsOwnerAddressLine5" (change)="updateField()">
          </mat-form-field>
        </app-card-label>


      </span>


    </span>

<app-card-label label="Septic Tank">
  <app-yes-no formControlName="hasSepticTank" (change)="updateField()" ></app-yes-no>
</app-card-label>

<app-card-label label="Redeem Mortgage">
  <app-yes-no formControlName="redeemMortgage" (change)="updateField()" ></app-yes-no>
</app-card-label>

<span [hidden]="propertyDetails.controls['redeemMortgage'].value != 1">

  <app-card-label label="Current Lender Name">
    <mat-form-field floatLabel="never">
      <input matInput #landlordName maxlength="100"
        formControlName="lenderName" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Lender Postcode">
    <mat-form-field floatLabel="never">
      <input matInput #lenderPostcode maxlength="10" formControlName="lenderPostcode"
        (change)="updateField()">
      <app-address-lookup-button matSuffix [for]="addressLookup6"></app-address-lookup-button>
    </mat-form-field>
  </app-card-label>

  <app-address-lookup #addressLookup6 lookupType="postcode" [searchField]="lenderPostcode" [add1]="propertyDetails.controls['lenderAddressLine1']"
    [add2]="propertyDetails.controls['lenderAddressLine2']" [add3]="propertyDetails.controls['lenderAddressLine3']" [add4]="propertyDetails.controls['lenderAddressLine4']"
    [add5]="propertyDetails.controls['lenderAddressLine5']" [addPc]="propertyDetails.controls['lenderPostcode']" (change)="updateField();">
  </app-address-lookup>

  <app-card-label label="Lender Address Line 1">
    <mat-form-field floatLabel="never">
      <input matInput #lenderAddressLine1 maxlength="100"
        formControlName="lenderAddressLine1" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Lender Address Line 2">
    <mat-form-field floatLabel="never">
      <input matInput #lenderAddressLine2 maxlength="100"
        formControlName="lenderAddressLine2" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Lender Address Line 3">
    <mat-form-field floatLabel="never">
      <input matInput #lenderAddressLine3 maxlength="100"
        formControlName="lenderAddressLine3" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Lender Address Line 4">
    <mat-form-field floatLabel="never">
      <input matInput #lenderAddressLine4 maxlength="100"
        formControlName="lenderAddressLine4" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Lender Address Line 5">
    <mat-form-field floatLabel="never">
      <input matInput #lenderAddressLine5 maxlength="100"
        formControlName="lenderAddressLine5" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Redemption Amount">
    <mat-form-field floatLabel="never">
      <span matPrefix>£ &nbsp;</span>
      <input matInput formControlName="redemptionAmount" (change)="updateField()" type="number" >
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Current Mortgage Account Number">
    <mat-form-field floatLabel="never">
      <input matInput #currentMortgageAccountNumber maxlength="100"
        formControlName="currentMortgageAccountNumber" (change)="updateField()">
    </mat-form-field>
  </app-card-label>

  <app-card-label label="Adlington Law Redeem Mortgage?">
    <app-yes-no formControlName="adlRedeemMortgageLender1" (change)="updateField()" ></app-yes-no>
  </app-card-label>

  <span [hidden]="propertyDetails.controls['adlRedeemMortgageLender1'].value != 1">


    <app-card-label label="Lender Sort Code">
      <mat-form-field floatLabel="never">
        <input matInput #lender1SortCode maxlength="100"
          formControlName="lender1SortCode" (change)="updateField()">
      </mat-form-field>
    </app-card-label>

    <app-card-label label="Lender Account Number">
      <mat-form-field floatLabel="never">
        <input matInput #lender1AccountNumber maxlength="100"
          formControlName="lender1AccountNumber" (change)="updateField()">
      </mat-form-field>
    </app-card-label>
  </span>


  <app-card-label label="Is there a second lender?">
      <app-yes-no formControlName="hasSecondLender" (change)="updateField()" ></app-yes-no>
    </app-card-label>

    <span [hidden]="propertyDetails.controls['hasSecondLender'].value != 1">

        <app-card-label label="Lender 2 Name">
          <mat-form-field floatLabel="never">
            <input matInput #lender2Name maxlength="100"
              formControlName="lender2Name" (change)="updateField()">
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Lender 2 Postcode">
            <mat-form-field floatLabel="never">
              <input matInput #lender2Postcode maxlength="10" formControlName="lender2Postcode"
                (change)="updateField()">
              <app-address-lookup-button matSuffix [for]="addressLookup7"></app-address-lookup-button>
            </mat-form-field>
          </app-card-label>

          <app-address-lookup #addressLookup7 lookupType="postcode" [searchField]="lender2Postcode" [add1]="propertyDetails.controls['lender2AddressLine1']"
            [add2]="propertyDetails.controls['lender2AddressLine2']" [add3]="propertyDetails.controls['lender2AddressLine3']" [add4]="propertyDetails.controls['lender2AddressLine4']"
            [add5]="propertyDetails.controls['lender2AddressLine5']" [addPc]="propertyDetails.controls['lender2Postcode']" (change)="updateField();">
          </app-address-lookup>

          <app-card-label label="Lender 2 Address Line 1">
            <mat-form-field floatLabel="never">
              <input matInput #lender2AddressLine1 maxlength="100"
                formControlName="lender2AddressLine1" (change)="updateField()">
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Lender 2 Address Line 2">
            <mat-form-field floatLabel="never">
              <input matInput #lender2AddressLine2 maxlength="100"
                formControlName="lender2AddressLine2" (change)="updateField()">
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Lender 2 Address Line 3">
            <mat-form-field floatLabel="never">
              <input matInput #lender2AddressLine3 maxlength="100"
                formControlName="lender2AddressLine3" (change)="updateField()">
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Lender 2 Address Line 4">
            <mat-form-field floatLabel="never">
              <input matInput #lender2AddressLine4 maxlength="100"
                formControlName="lender2AddressLine4" (change)="updateField()">
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Lender 2 Address Line 5">
            <mat-form-field floatLabel="never">
              <input matInput #lender2AddressLine5 maxlength="100"
                formControlName="lender2AddressLine5" (change)="updateField()">
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Lender 2 Redemption Amount">
            <mat-form-field floatLabel="never">
              <span matPrefix>£ &nbsp;</span>
              <input matInput formControlName="redemptionAmount2" (change)="updateField()" type="number" >
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Adlington Law Redeem Mortgage?">
            <app-yes-no formControlName="adlRedeemMortgageLender2" (change)="updateField()" ></app-yes-no>
          </app-card-label>
        
          <span [hidden]="propertyDetails.controls['adlRedeemMortgageLender2'].value != 1">
        
        
            <app-card-label label="Lender Sort Code">
              <mat-form-field floatLabel="never">
                <input matInput #lender2SortCode maxlength="100"
                  formControlName="lender2SortCode" (change)="updateField()">
              </mat-form-field>
            </app-card-label>
        
            <app-card-label label="Lender Account Number">
              <mat-form-field floatLabel="never">
                <input matInput #lender2AccountNumber maxlength="100"
                  formControlName="lender2AccountNumber" (change)="updateField()">
              </mat-form-field>
            </app-card-label>
          </span>

          <app-card-label label="Is there a third lender?">
              <app-yes-no formControlName="hasThirdLender" (change)="updateField()" ></app-yes-no>
            </app-card-label>

            <span [hidden]="propertyDetails.controls['hasThirdLender'].value != 1">

                <app-card-label label="Lender 3 Name">
                  <mat-form-field floatLabel="never">
                    <input matInput #lender3Name maxlength="100"
                      formControlName="lender3Name" (change)="updateField()">
                  </mat-form-field>
                </app-card-label>


                <app-card-label label="Lender 3 Postcode">
                    <mat-form-field floatLabel="never">
                      <input matInput #lender3Postcode maxlength="10" formControlName="lender3Postcode"
                        (change)="updateField()">
                      <app-address-lookup-button matSuffix [for]="addressLookup8"></app-address-lookup-button>
                    </mat-form-field>
                  </app-card-label>

                  <app-address-lookup #addressLookup8 lookupType="postcode" [searchField]="lender3Postcode" [add1]="propertyDetails.controls['lender3AddressLine1']"
                    [add2]="propertyDetails.controls['lender3AddressLine2']" [add3]="propertyDetails.controls['lender3AddressLine3']" [add4]="propertyDetails.controls['lender3AddressLine4']"
                    [add5]="propertyDetails.controls['lender3AddressLine5']" [addPc]="propertyDetails.controls['lender3Postcode']" (change)="updateField();">
                  </app-address-lookup>

                  <app-card-label label="Lender 3 Address Line 1">
                    <mat-form-field floatLabel="never">
                      <input matInput #lender3AddressLine1 maxlength="100"
                        formControlName="lender3AddressLine1" (change)="updateField()">
                    </mat-form-field>
                  </app-card-label>

                  <app-card-label label="Lender 3 Address Line 2">
                    <mat-form-field floatLabel="never">
                      <input matInput #lender3AddressLine2 maxlength="100"
                        formControlName="lender3AddressLine2" (change)="updateField()">
                    </mat-form-field>
                  </app-card-label>

                  <app-card-label label="Lender 3 Address Line 3">
                    <mat-form-field floatLabel="never">
                      <input matInput #lender3AddressLine3 maxlength="100"
                        formControlName="lender3AddressLine3" (change)="updateField()">
                    </mat-form-field>
                  </app-card-label>

                  <app-card-label label="Lender 3 Address Line 4">
                    <mat-form-field floatLabel="never">
                      <input matInput #lender3AddressLine4 maxlength="100"
                        formControlName="lender3AddressLine4" (change)="updateField()">
                    </mat-form-field>
                  </app-card-label>

                  <app-card-label label="Lender 3 Address Line 5">
                    <mat-form-field floatLabel="never">
                      <input matInput #lender3AddressLine5 maxlength="100"
                        formControlName="lender3AddressLine5" (change)="updateField()">
                    </mat-form-field>
                  </app-card-label>

                  <app-card-label label="Lender 3 Redemption Amount">
                    <mat-form-field floatLabel="never">
                      <span matPrefix>£ &nbsp;</span>
                      <input matInput formControlName="redemptionAmount3" (change)="updateField()" type="number" >
                    </mat-form-field>
                  </app-card-label>

                  <app-card-label label="Adlington Law Redeem Mortgage?">
                    <app-yes-no formControlName="adlRedeemMortgageLender3" (change)="updateField()" ></app-yes-no>
                  </app-card-label>
                
                  <span [hidden]="propertyDetails.controls['adlRedeemMortgageLender3'].value != 1">
                
                
                    <app-card-label label="Lender Sort Code">
                      <mat-form-field floatLabel="never">
                        <input matInput #lender3SortCode maxlength="100"
                          formControlName="lender3SortCode" (change)="updateField()">
                      </mat-form-field>
                    </app-card-label>
                
                    <app-card-label label="Lender Account Number">
                      <mat-form-field floatLabel="never">
                        <input matInput #lender3AccountNumber maxlength="100"
                          formControlName="lender3AccountNumber" (change)="updateField()">
                      </mat-form-field>
                    </app-card-label>
                  </span>

          </span>
    </span>



</span>

<app-card-label label="Transfer of Title into Joint Names">
  <app-yes-no formControlName="propertySoleNameTransferIntoJointNames" (change)="updateField()" ></app-yes-no>
</app-card-label>

<app-card-label label="Reason" [hidden]="propertyDetails.controls['propertySoleNameTransferIntoJointNames'].value != 1">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="propertySoleNameTransferIntoJointNamesReason" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Removing A Name From Title Required">
  <app-yes-no formControlName="propertyJointNameTransferIntoSoleName" (change)="updateField()" ></app-yes-no>
</app-card-label>

<app-card-label label="Details/Reason" [hidden]="propertyDetails.controls['propertyJointNameTransferIntoSoleName'].value != 1">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="propertyJointNameTransferIntoSoleNameReason" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Removal of restriction">
  <app-yes-no formControlName="removalOfRestriction" (change)="updateField()" ></app-yes-no>
</app-card-label>

<app-card-label label="Details/Reason" [hidden]="propertyDetails.controls['removalOfRestriction'].value != 1">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="removalOfRestrictionReason" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Agreed Sale Price">
  <mat-form-field floatLabel="never">
    <span matPrefix>£ &nbsp;</span>
    <input matInput formControlName="agreedSalePrice" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Chattels">
  <mat-form-field floatLabel="never">
    <span matPrefix>£ &nbsp;</span>
    <input matInput formControlName="chattels" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-allowance></app-allowance>

<app-card-label label="Buyer Solicitor Name">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="buyerSolicitorName" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Buyer Solicitor Email Address">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="buyerSolicitorEmailAddress" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Buyer Solicitor Contact Number">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="buyerSolicitorContactNumber" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Buyer Solicitor Reference">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="buyerSolicitorReference" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Buyer Solicitor Postcode">
  <mat-form-field floatLabel="never">
    <input matInput #buyerSolicitorPostcode maxlength="10" formControlName="buyerSolicitorPostcode"
      (change)="updateField()">
    <app-address-lookup-button matSuffix [for]="addressLookup9"></app-address-lookup-button>
  </mat-form-field>
</app-card-label>

<app-address-lookup #addressLookup9 lookupType="postcode" [searchField]="buyerSolicitorPostcode" [add1]="propertyDetails.controls['buyerSolicitorAddressLine1']"
  [add2]="propertyDetails.controls['buyerSolicitorAddressLine2']" [add3]="propertyDetails.controls['buyerSolicitorAddressLine3']" [add4]="propertyDetails.controls['buyerSolicitorAddressLine4']"
  [add5]="propertyDetails.controls['buyerSolicitorAddressLine5']" [addPc]="propertyDetails.controls['buyerSolicitorPostcode']" (change)="updateField();">
</app-address-lookup>

<app-card-label label="Buyer Solicitor Address Line 1">
  <mat-form-field floatLabel="never">
    <input matInput #buyerSolicitorAddressLine1 maxlength="100"
      formControlName="buyerSolicitorAddressLine1" (change)="updateField()">
  </mat-form-field>
</app-card-label>

<app-card-label label="Buyer Solicitor Address Line 2">
  <mat-form-field floatLabel="never">
    <input matInput #buyerSolicitorAddressLine2 maxlength="100"
      formControlName="buyerSolicitorAddressLine2" (change)="updateField()">
  </mat-form-field>
</app-card-label>

<app-card-label label="Buyer Solicitor Address Line 3">
  <mat-form-field floatLabel="never">
    <input matInput #buyerSolicitorAddressLine3 maxlength="100"
      formControlName="buyerSolicitorAddressLine3" (change)="updateField()">
  </mat-form-field>
</app-card-label>

<app-card-label label="Buyer Solicitor Address Line 4">
  <mat-form-field floatLabel="never">
    <input matInput #buyerSolicitorAddressLine4 maxlength="100"
      formControlName="buyerSolicitorAddressLine4" (change)="updateField()">
  </mat-form-field>
</app-card-label>

<app-card-label label="Buyer Solicitor Address Line 5">
  <mat-form-field floatLabel="never">
    <input matInput #buyerSolicitorAddressLine5 maxlength="100"
      formControlName="buyerSolicitorAddressLine5" (change)="updateField()">
  </mat-form-field>
</app-card-label>

<app-card-label label="Buyer 1 Name">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="buyer1Name" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

<app-card-label label="Buyer 2 Name">
  <mat-form-field floatLabel="never">
    <input matInput formControlName="buyer2Name" (change)="updateField()" >
  </mat-form-field>
</app-card-label>

</form>
</mat-dialog-content>

<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close (click)="openEstateAgent()">Next</button>
</div>
