<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Pre-Invoice Disbursements
  <button mat-icon-button tabindex="-1" color="primary" (click)="addFee()"><fa-icon [icon]="faPlus" ></fa-icon></button>
</h2>

<mat-dialog-content class="mat-typography">
    <div class="list">
        <div class="box">
          <div class="feeItem">
            <div class="fee-type">Type</div>
            <div class="fee-cell">Entered By</div>  
            <div class="fee-cell-large">Supplier</div>            
            <div class="net">Net</div>
            <div class="vat">VAT</div>
            <div class="fee-cell">Klyant</div>
            <div class="fee-cell">Invoice</div>
            <div class="actions">&nbsp;</div>
            <div class="actions">&nbsp;</div>
          </div>
        </div>
        <div class="box" *ngFor="let fee of preInvoiceFees; let i = index" [ngClass]="{'feeItem-sent': fee.klyantOutlayId != null, 'feeItem-ready': fee.klyantOutlayId == null && fee.supplierId != null, 'feeItem-missingdata': fee.supplierId == null}">
            <div class="feeItem">
              <div class="fee-type"><span *ngIf="fee.description != null">{{fee.description}}</span><span *ngIf="fee.description == null">{{fee.fee.description}}</span></div>              
              <div class="fee-cell">{{fee.enteredByUser.fullName}}</div>     
              <div *ngIf="fee.supplierId != null" class="fee-cell-large">{{getSupplierName(fee.supplierId)}}</div>
              <div *ngIf="fee.supplierId == null" class="fee-cell-large missing-supplier">
                Select Supplier... <fa-icon [icon]="faPencil" (click)="selectSupplierForFeeRow(fee.preInvoiceFeeId)"></fa-icon>
              </div>
              <div class="net">{{fee.netAmount | currency:'£'}}</div>
              <div class="vat">{{fee.vatAmount | currency:'£'}} </div>
              <div class="fee-cell">{{fee.klyantStatus}}</div>
              <div class="fee-cell"><span *ngIf="fee.invoiceItem != null && fee.invoiceItem.invoice != null && fee.invoiceItem.invoice.invoiceNumber != null"> {{fee.invoiceItem.invoice.invoiceNumber}}</span>&nbsp;</div>
              <div class="actions">&nbsp;<fa-icon *ngIf="fee.klyantStatus != 'BILLED'" [icon]="faTimes" (click)="removeFee(fee)"></fa-icon></div>
              <div class="actions">             
                  &nbsp;
                  <fa-icon *ngIf="fee.klyantOutlayId != null" [icon]="faExternalLink" (click)="editInKlyant(fee.klyantOutlayId)"></fa-icon>
                  <fa-icon *ngIf="fee.supplierId != null && fee.klyantOutlayId == null && !fee.sendingToKlyant" [icon]="faShare" (click)="sendToKlyant(fee)"></fa-icon>
              </div>
            </div>
        </div>
        <!--div class="box">
          <div class="feeItem">
            <div class="fee-type">&nbsp;</div>
            <div class="fee-cell">&nbsp;</div>
            <div class="fee-cell-large">&nbsp;</div>              
            <div class="net"><strong>{{preInvoiceFeesNetTotal | currency:'£'}}</strong></div>
            <div class="vat"><strong>{{preInvoiceFeesVatTotal | currency:'£'}}</strong></div>
            <div class="fee-cell">&nbsp;</div>
            <div class="actions">&nbsp;</div>
          </div>
        </div-->
      </div>
</mat-dialog-content>
