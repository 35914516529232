import {  Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatMenuTrigger} from '@angular/material/menu';

import { faFilter as fasFilter} from '@fortawesome/free-solid-svg-icons';
import { faFilter, faTimesCircle } from '@fortawesome/pro-light-svg-icons';

@Component({
 template: ''
})

export abstract class BaseFilterComponent
{

  @ViewChild('menuTrigger') menu: any;//MatMenuTrigger;
  @Input() title?: string;
  @Input() filterColumn?: string;
  @Output() filter = new EventEmitter<boolean>();
  @Input() valueType: string = 'string';

  faFilter = faFilter;
  fasFilter = fasFilter;
  faTimesCircle = faTimesCircle;

  public filterValue: string ='';
  public filterName: string = '';
  public componentTypeName: string = '';

  constructor() { }

  abstract onFilterSubmit(): void;

  abstract clear(): void;

  getValueTag() {
    if (this.valueType == 'string') return '';

    if (this.valueType == 'int') return '{{int}}';

    else return '';
  }

}
