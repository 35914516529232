import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatInput } from '@angular/material/input';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-address-lookup',
  templateUrl: './address-lookup.component.html',
  styleUrls: ['./address-lookup.component.css']
})
export class AddressLookupComponent implements OnInit {

  private addressUrl = environment.apiUrl + 'addresssearch/';
  private _searchTerm = '';

  @Input() lookupType = 'AddressLine1';
  @Input()
  set searchTerm(value: string) {
    this._searchTerm = value;
  }
  get searchTerm() {
    return this._searchTerm;
  };

  //@Input() searchField: MatInput = new MatInput
  @Input() searchField: any;
  @Input() add1: any;//FormControl = new FormControl();
  @Input() add2: any;//FormControl = new FormControl();
  @Input() add3: any;//FormControl = new FormControl();
  @Input() add4: any;//FormControl = new FormControl();
  @Input() add5: any;//FormControl = new FormControl();
  @Input() addPc: any;//FormControl = new FormControl();

  @Output() change = new EventEmitter();

  addresses: any[] = [];
  postcodeAddresses: any[] = [];
  searching = false;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  public search() {

    if (this.searchField && this.searchField.value && !this.searchField.disabled) {

      this.addresses = [];
      this.postcodeAddresses = []
      this.searching = true;

      if (this.lookupType == 'AddressLine1') {
        this.searchAddressLine1().subscribe((result : any) => {
          for (let [key, value] of Object.entries(result)) {
            this.addresses.push({ id: key, address: value })
          }
          this.searching = false;
        });
      } else {
        this.searchPostcode().subscribe((result : any) => {
          /*for (let [key, value] of Object.entries(result)) {
            this.addresses.push({id: key, address: value})
          }*/
          this.postcodeAddresses = result;
          this.searching = false;
        });
      }
    }
    else {
      this.searching = false;
      this.addresses = [];
      this.postcodeAddresses = [];
    }
  }

  searchAddressLine1() {
    return this.http.get<any>(`${this.addressUrl}address/${this.searchField.value}`);
  }

  searchPostcode() {
    return this.http.get<any>(`${this.addressUrl}postcode/${this.searchField.value}`);
  }

  searchById(id: number) {
    return this.http.get<any>(`${this.addressUrl}deliverypoint/${id}`);
  }

  select(id : number) {

    if (id) {
      this.searchById(id).subscribe((result : any) => {
        if (result) {

          this.add1.setValue(result.building_Name + result.number + ' ' + result.street);
          this.add2.setValue(result.addressLine2);
          this.add3.setValue(result.addressLine3);
          this.add4.setValue(result.addressLine4);
          this.add5.setValue(result.addressLine5);
          this.addPc.setValue(result.postcode);

          this.addresses = [];
          this.postcodeAddresses = [];

          this.change.next();
        }
      });
    }
  }

  selectPostcode(item : any) {

    if (item) {

      this.add1.setValue(item.building_Name + item.number + ' ' + item.street);
      this.add2.setValue(item.addressLine2);
      this.add3.setValue(item.addressLine3);
      this.add4.setValue(item.addressLine4);
      this.add5.setValue(item.addressLine5);
      this.addPc.setValue(item.postcode);

      this.addresses = [];
      this.postcodeAddresses = [];

      this.change.next();

    }
  }
}
