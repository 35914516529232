<div>
    <div style="display: flex;  align-items: center;">
      <ng-content></ng-content>
      <div class="icon" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">
        <fa-icon [icon]="faFilter" *ngIf="!filterValue"></fa-icon>
        <fa-icon class="active" [icon]="fasFilter" *ngIf="filterValue"></fa-icon>
      </div>
    </div>
    <div *ngIf="filterName" class="active-value mat-elevation-z">
      <span>{{filterName}}</span>
      <fa-icon class="filter-clear" [icon]="faTimesCircle" (click)="clear()"></fa-icon>
    </div>
  </div>


  <span id="filter-panel">
  <mat-menu #menu="matMenu" [overlapTrigger]="false" class="mat-typography">
    <div (click)="$event.stopPropagation()" (keyup)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <form autocomplete="off" (ngSubmit)="onFilterSubmit()" [formGroup]="filterForm">
        <h3 *ngIf="title">{{title}}</h3>

        <mat-form-field>
          <input matInput placeholder="Filter" formControlName="equals">
        </mat-form-field>

        <div class="buttons">
          <button mat-stroked-button [disabled]="false" color="warn" type="button" (click)="clear()">Clear</button>
          <button mat-stroked-button [disabled]="false" color="primary" type="submit">Apply</button>
        </div>

      </form>
    </div>
  </mat-menu>
</span>
