import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CaseFundsService } from '../../../../shared/services/case-funds.service';

@Component({
  selector: 'app-add-fund',
  templateUrl: './add-fund.component.html',
  styleUrls: ['./add-fund.component.css']
})
export class AddFundComponent implements OnInit {

  caseId : number;

  newFundDetails = this.fb.group({
    description: ['', Validators.required],
    net: [null, Validators.required],
  }, { });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private caseFundsService: CaseFundsService,
              public dialogRef: MatDialogRef<AddFundComponent>) {
    this.caseId = data.caseId;
  }

  ngOnInit(): void {
  }

  addFund(){


    this.caseFundsService.addCaseFund( this.caseId,  this.newFundDetails.value ).subscribe((response : any) => {

      this.dialogRef.close(true);
    });


  }

}
