import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NotificationsService } from '../shared/services/notifications.service';
import { Router } from '@angular/router';
import { faTrashAlt, faUndoAlt} from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications: any[] = [];
  faTrashAlt = faTrashAlt;
  faUndoAlt = faUndoAlt;
  allLoaded = false;

  showAll: boolean = true;

  @Output() closeNotifications = new EventEmitter();

  constructor(
    private notificationsService: NotificationsService,
    private router: Router) {

      this.loadNotifications();

  }

  ngOnInit(): void {
  }

  loadNotifications(){
    this.notificationsService.getNotificationsForUser().subscribe(response => {
      this.notifications = response.notifications;
      this.allLoaded = true;
    });
  }

  clickNotification(n: any){

    this.notificationsService.readNotification(n.id).subscribe(response => {

      if(n.caseId !== null)
      {
        this.router.navigate(['/case', n.caseId])
        this.closePopup();
      }

    });

  }

  dismiss(n: any){
    this.notificationsService.dismissNotification(n.id).subscribe(response => {

      this.loadNotifications();

    });
  }

  closePopup(){
    this.closeNotifications.next();
  }

  showUnread(){
    this.showAll = false;
  }

  showAllNotifications(){
    this.showAll = true;
  }

  markAllRead(){
    this.notificationsService.markAllAsReadForUser().subscribe(response => {
      this.loadNotifications();
    });
  }

  markAsUnread(n: any){
    this.notificationsService.unreadNotification(n.id).subscribe(response => {

      this.loadNotifications();

    });
  }

}
