

<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title >Case Notes History</h2>
<div mat-dialog-content class="mat-typography">

  <div>

      <table mat-table [dataSource]="notes" >

      <ng-container matColumnDef="notes">
        <mat-header-cell *matHeaderCellDef  style="min-width: 30%;"> Note. </mat-header-cell>
        <mat-cell *matCellDef="let note"  style="min-width: 30%;"> {{note.notes}}</mat-cell>
      </ng-container>

            <ng-container matColumnDef="addedBy">
        <mat-header-cell *matHeaderCellDef> Added By. </mat-header-cell>
        <mat-cell *matCellDef="let note"> <span *ngIf="note.addedBy">{{note.addedBy.firstName}} {{note.addedBy.lastName}} </span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="addedDate">
          <mat-header-cell *matHeaderCellDef> Date Added. </mat-header-cell>
          <mat-cell *matCellDef="let note"> {{formatDate(note.addedDateTime)}} </mat-cell>
        </ng-container>

      <ng-container matColumnDef="removedBy">
          <mat-header-cell *matHeaderCellDef> Removed By. </mat-header-cell>
          <mat-cell *matCellDef="let note"><span *ngIf="note.removedBy">{{note.removedBy.firstName}} {{note.removedBy.lastName}}</span></mat-cell>
        </ng-container>

      <ng-container matColumnDef="removedDate">
        <mat-header-cell *matHeaderCellDef> Removed Date. </mat-header-cell>
        <mat-cell *matCellDef="let note"> {{formatDate(note.removedDate)}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastUpdated">
          <mat-header-cell *matHeaderCellDef> Last Updated. </mat-header-cell>
          <mat-cell *matCellDef="let note"> {{formatDate(note.lastUpdated)}} </mat-cell>
        </ng-container>


      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
      <mat-row *matRowDef="let note; columns: displayedColumns;" ></mat-row>

    </table>

    </div>
  </div>
