import { Component, OnInit, Inject } from '@angular/core';
import { InvoiceService } from '../../../../shared/services/invoice.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { PreInvoiceFeesService } from 'src/app/shared/services/pre-invoice-fees.service';
import { KlyantService } from 'src/app/shared/services/klyant.service';
import { ConsultantListComponent } from 'src/app/consultants/consultant-list/consultant-list.component';

@Component({
  selector: 'app-add-invoice-item',
  templateUrl: './add-invoice-item.component.html',
  styleUrls: ['./add-invoice-item.component.css']
})
export class AddInvoiceItemComponent implements OnInit {

  caseId : number;
  invoiceId: number;
  fees: any[] = [];
  selectedItem: any;
  feeType: number = 1;
  feeTypes: any = [];
  feeTypeName: string = "Professional Charge";

  newInvoiceItemDetails = this.fb.group({
    feeType: [],
    description: ['', Validators.required],
    net: [null, Validators.required],
    addVat: false,
    outlayId: null,
    nominalCode: null,
    feeId: null
  }, { });

  constructor(private invoiceService: InvoiceService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              public dialogRef: MatDialogRef<AddInvoiceItemComponent>,
              private preInvoiceFeesService: PreInvoiceFeesService,
              private klyantService: KlyantService)
  {

      this.caseId = data.caseId;
      this.invoiceId = data.invoiceId;
      this.feeType = data.feeType;
      this.newInvoiceItemDetails.controls["feeType"].patchValue(this.feeType);
      this.loadFees();
  }

  ngOnInit(): void {
  }

  loadFees(){

    // Set the title based on the API enum description
    this.invoiceService.getFeeTypes().subscribe((response : any) => {      
      this.feeTypes = response;
      this.feeTypeName = this.feeTypes.filter(x => x.key == this.feeType)[0].value;
    });
    
    this.invoiceService.getFeeTypesForCase( this.caseId, this.feeType).subscribe((response : any) => {
      this.fees = response.fees;
    });
  }

  addInvoiceItem(){
    this.invoiceService.addInvoiceItem( this.caseId, this.invoiceId, this.newInvoiceItemDetails.value ).subscribe((response : any) => {
      this.dialogRef.close(true);
    });
  }

  feeChange(){
    this.newInvoiceItemDetails.controls["description"].patchValue(this.selectedItem.description);
    this.newInvoiceItemDetails.controls["net"].patchValue(this.selectedItem.net);
    this.newInvoiceItemDetails.controls["addVat"].patchValue(this.selectedItem.includeVAT);
    this.newInvoiceItemDetails.controls["feeId"].patchValue(this.selectedItem.id);
  }
}
