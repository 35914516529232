<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<button mat-icon-button class="audit" tabindex="-1" (click)="openAuditForForm()"><fa-icon [icon]="faHistory"></fa-icon></button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Enquiries Checklist</h2>

<h3 class="error" *ngIf="error">{{errorMessage}}</h3>
<mat-spinner color="primary" diameter="20" *ngIf="showSpinner"></mat-spinner>

<mat-dialog-content *ngIf="!error">
  <div class="headeractions">
    <div class="buttons">
      <button mat-icon-button tabindex="-1" color="primary" (click)="addEnquiry()"><fa-icon [icon]="faPlus"></fa-icon></button>
    </div>
  </div>

  <div class="list">
    <div *ngIf="items.length === 0">No enquiries</div>
    <table mat-table *ngIf="items.length > 0" [dataSource]="items">

     
      <ng-container matColumnDef="enquiry">
        <th mat-header-cell *matHeaderCellDef>Enquiry</th>
        <td mat-cell *matCellDef="let element">
          <span (click)="editEnquiry(element.id, element.enquiry)" class="enquiry-link">
            {{element.enquiry}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="receivedDate">
        <th mat-header-cell *matHeaderCellDef style="width: 120px"> Received </th>
        <td mat-cell *matCellDef="let element" style="font-size: 14px">

          {{element.receivedDate | date: 'dd/MM/yyyy'}}
          <small *ngIf="element.receivedDate==null"><i>add date...</i></small>

          <mat-icon color="primary" (click)="showDatePicker(element.id, 'receivedDate', element.receivedDate)">edit</mat-icon>

        </td>
      </ng-container>

      <ng-container matColumnDef="repliedDate">
        <th mat-header-cell *matHeaderCellDef style="width: 120px"> Replied </th>
        <td mat-cell *matCellDef="let element">
          {{element.repliedDate | date: 'dd/MM/yyyy'}}
          <small *ngIf="element.repliedDate==null"><i>add date...</i></small>
          <mat-icon [color]="element.repliedDate==null ? 'warn' :'primary'" (click)="showDatePicker(element.id, 'repliedDate', element.repliedDate)">edit</mat-icon>

        </td>
      </ng-container>

      <ng-container matColumnDef="satisfiedDate">
        <th mat-header-cell *matHeaderCellDef style="width: 120px"> Satisfied </th>
        <td mat-cell *matCellDef="let element">
          {{element.satisfiedDate | date: 'dd/MM/yyyy'}}
          <small *ngIf="element.satisfiedDate==null"><i>add date...</i></small>
          <mat-icon [color]="element.satisfiedDate==null ? 'warn' :'primary'" (click)="showDatePicker(element.id, 'satisfiedDate', element.satisfiedDate)">edit</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="width: 20px">  </th>
        <td mat-cell *matCellDef="let element" style="text-align: right; padding-right: 15px;">
          <mat-icon color="warn" (click)="archiveEnquiry(element.id)" *ngIf="!element.isCompleted">delete_forever</mat-icon>
          <mat-icon color="primary" *ngIf="element.isCompleted">check</mat-icon>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>


</mat-dialog-content>

