import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lender-solicitors',
  templateUrl: './lender-solicitors.component.html',
  styleUrls: ['./lender-solicitors.component.css']
})
export class LenderSolicitorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
