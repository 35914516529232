import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { ThemePalette } from '@angular/material/core';
import { Color } from '@angular-material-components/color-picker';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.scss']
})
export class UserAdminComponent implements OnInit {

  userId?: number;
  companyGroups: any[] = [];
  roles: any[] = [];
  teamLeaders: any[] = [];
  inTeamleaderList = false;

  newUser: boolean = true;

  sendingPasswordReset: boolean = false;
  sendingInvitation: boolean = false;

  internalUser: boolean = true;
  sectionTitle: string = 'Internal User';

  //for the colour picker
  public disabled = false;
  public color: ThemePalette = 'primary';
  public touchUi = false;

  public faEnvelope = faEnvelope;

  colorCtr: FormControl = new FormControl(null);

  userLoaded = false;

  lastLoginTime: string = '';

  userDetails = this.fb.group({
    id: [],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    emailAddress: ['', Validators.required],
    companyGroup: [''],
    companyGroupId: ['', Validators.required],
    role: ['', Validators.required],
    accountDisabled: [null],
    isTeamLeader: [false, Validators.required],
    teamLeaderUserId: null,
    hasAPIAccess: [''],
    applicationKey: [''],
    canAllocateCases: [null],
    canReallocateCases: [null],
    canSeeAdminActions: [null],
    isAMLOfficer: [null],
    position: [''],
    klyantApiClientId: null,
    klyantApiSecret: [null],
    colour: [null],
    individualStatusId: new FormControl({ value: null, disabled: true })
  }, {});

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private snackBar: MatSnackBar) {

        this.route.params.subscribe(params => {
          this.userId = params['id'];

          if (this.userId) {

            this.userService.userDetails(this.userId).subscribe(result => {
              this.userDetails.patchValue(result);

              console.log(result);

              this.newUser = false;
              this.inTeamleaderList = true;

              if(this.userDetails.value.companyGroupId == 1)
                this.internalUser = true;
              else{
                this.internalUser = false;
                this.sectionTitle = 'External User';
              }

              this.userService.teamLeaders().subscribe(result => {
                this.teamLeaders = result;

                this.teamLeaders = this.teamLeaders.filter( tl => tl.role ==  this.userDetails.controls['role'].value);
              });

              this.populateRoles();

              // Initialise the colour picker
              if(this.internalUser && this.userDetails.get('colour')!.value != null && this.userDetails.get('colour')!.value != "")
              {
                const temp = this.hexToRgb('#' + this.userDetails.get('colour')!.value);
                this.colorCtr.setValue(new Color(temp!.r, temp!.g, temp!.b));
              }

              this.lastLoginTime = this.formatDate(result!.lastLogin);
              this.userLoaded = true;

            }, error => {


            });



          }
          else {

            if(params['userType'] == 'internal'){
              this.internalUser = true;
              this.userDetails.controls['companyGroupId'].patchValue(1);
            }
            else{
              this.internalUser = false;
              this.sectionTitle = 'External User';
            }

            // default values for new users
            this.userDetails.controls['hasAPIAccess'].patchValue(false);
            this.userDetails.controls['accountDisabled'].patchValue(false);

            // Show requried fields
            Object.keys(this.userDetails.controls).forEach(key => {

              this.userDetails.get(key)!.markAsTouched();
              this.userDetails.get(key)!.updateValueAndValidity();
            });

            this.userService.teamLeaders().subscribe(result => {
              this.teamLeaders = result;
              this.teamLeaders = this.teamLeaders.filter( tl => tl.role ==  this.userDetails.controls['role'].value);
            });

            this.populateRoles();
            this.userLoaded = true;
          }


        });

        this.userService.companyGroups().subscribe(result => {
          this.companyGroups = result;
          this.companyGroups.splice(0, 1); //Remove the first item (which will be Ad Law)
        });

        this.colorCtr!.valueChanges.subscribe((newColour: any) => {
          this.userDetails.controls['colour'].patchValue(newColour.hex);
        });

  }

  ngOnInit(): void {
  }

  saveUser() {

    this.userService.updateUser(this.userId, this.userDetails.value).subscribe(response => {

      this.router.navigate(['/user/list']);

    }, error => {

      var errorMessage = "User Update Failed";
      if (error.error !== null && error.error.message !== undefined) {
        errorMessage = error.error.message;
      }

      this.openSnackBar(errorMessage, 'Close');


    });
  }

  addUser() {

    this.sendingInvitation = true;
    this.userService.addUser(this.userDetails.value).subscribe(response => {

      this.sendingInvitation = false;
      this.router.navigate(['/user/list']);

    }, error => {

      console.log(error);

      var errorMessage = "User Creation Failed";
      if(error.status == 999)
      errorMessage = error.error;
      else
      {

      if (error.error !== null && error.error.message !== undefined) {
        errorMessage = error.error.message;
      }
    }


      this.openSnackBar(errorMessage, 'Close');

      this.sendingInvitation = false;
    });
  }

  updateTeamLeaders(){

    this.userService.teamLeaders().subscribe(result => {
      this.teamLeaders = result;
      this.teamLeaders = this.teamLeaders.filter( tl => tl.role ==  this.userDetails.controls['role'].value);
      this.userDetails.controls['teamLeaderUserId'].patchValue(-1);
    });
  }

  get teamLeadersForRole() {
    return this.teamLeaders.filter( tl => tl.role ==  this.userDetails.controls['role'].value);
  }

  populateRoles(){
    if(this.internalUser) {
      this.userService.internalRoles().subscribe(result => {
        this.roles = result;
      });
    }
    else{
      this.userService.externalRoles().subscribe(result => {
        this.roles = result;
      });
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  resetPassword() {

    this.sendingPasswordReset = true;
    this.userService.resetPassword({ emailAddress: this.userDetails.controls['emailAddress'].value }).subscribe(response => {

      this.sendingPasswordReset = false;
      this.openSnackBar("Password Reset Email Sent", 'Close');

    }, error => {
      this.sendingPasswordReset = false;

      var errorMessage = "Password Reset Failed";
      if (error.error !== null) {
        errorMessage = error.error.message
      }

      this.openSnackBar(errorMessage, 'Close');

    });

  }

  formatDate(inDate : any){

    var formattedDate = '';

     if(inDate !== null)
     {
       var dt = DateTime.fromISO(inDate);
       formattedDate = dt.toFormat("dd-MM-yyyy HH:mm:ss");

     if(formattedDate == 'Invalid date')
       formattedDate = '';
     }

     return formattedDate;
   }

  hexToRgb(hex: string) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

}
