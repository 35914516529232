<!--div class="wrapper" [ngClass]="{'isSmall': isSmall && !showSidebar}"-->
<div class="wrapper" [ngClass]="{'isSmall': !showSidebar}">
  <div>
    <!--app-sidebar-item [routerLink]="'/'" [hideContent]="hideContent && !showSidebar" [icon]="faHome" [title]="'Home'"></app-sidebar-item>
    <app-sidebar-item [routerLink]="'/case/1'" [hideContent]="hideContent && !showSidebar" [icon]="faHome" [title]="'Example Case'"></app-sidebar-item-->
    <div class="sidebar-spacer"></div>
    <app-sidebar-item [routerLink]="['/search']" [hideContent]="hideContent && !showSidebar" [icon]="faSearch" [title]="'Search'"></app-sidebar-item>
    <app-sidebar-item [routerLink]="'/newcase'" [hideContent]="hideContent && !showSidebar" [icon]="faPlus" [title]="'New Case'"></app-sidebar-item>
    <app-sidebar-item *ngIf="authService.canAdminUsersAndGroups()" [routerLink]="['/user','list']" [hideContent]="hideContent && !showSidebar" [icon]="faUsers" [title]="'Users'"></app-sidebar-item>
    <app-sidebar-item *ngIf="authService.canAdminUsersAndGroups()" [routerLink]="['/companygroup','list']" [hideContent]="hideContent && !showSidebar" [icon]="faBuiling" [title]="'Company Groups'"></app-sidebar-item>
    <app-sidebar-item [routerLink]="['/consultants','list']" [hideContent]="hideContent && !showSidebar" [icon]="faUserTie" [title]="'Consultants'"></app-sidebar-item>

    <app-sidebar-item *ngIf="authService.canAdminLenders()" [routerLink]="['/lenders','list']"  [title]="'Lenders'" [hideContent]="hideContent && !showSidebar" >
        <fa-layers [fixedWidth]="true">
          <fa-icon [icon]="faHouse" size="lg" transform="shrink-3 left-2 up-2"></fa-icon>
          <fa-icon [icon]="faPoundSign" size="lg" [inverse]="true" transform=" shrink-3 right-7 down-7"></fa-icon>
        </fa-layers>
      </app-sidebar-item>

      <app-sidebar-item *ngIf="authService.canAdminLenders()" [routerLink]="['/lendersolicitors','list']" [hideContent]="hideContent && !showSidebar" [icon]="faGavel" [title]="'Lender Solicitor'"></app-sidebar-item>

    <!-- <app-sidebar-item [icon]="faFileInvoice" [title]="'Supplier Invoices'" [routerLink]="['/supplier-invoices']" [hideContent]="hideContent && !showSidebar"></app-sidebar-item> -->

    <app-sidebar-item [routerLink]="['/calendar']" [hideContent]="hideContent && !showSidebar" [icon]="faCalendarAlt" [title]="'Calendar'"></app-sidebar-item>
    <app-sidebar-item [routerLink]="['/actions']" [hideContent]="hideContent && !showSidebar" [icon]="faTasks" [title]="'Actions'"></app-sidebar-item>
    <app-sidebar-item [routerLink]="['/unassignedfiles']" [hideContent]="hideContent && !showSidebar" [icon]="faFolders" [title]="'Scans'"></app-sidebar-item>
    <app-sidebar-item *ngIf="authService.isPortalManager() || authService.isPortalAdminTL() || authService.isPortalParaLegalTL()" [routerLink]="['/emailqueue']" [hideContent]="hideContent && !showSidebar" [icon]="faEnvelope" [title]="'Emails'"></app-sidebar-item>
    <app-sidebar-item *ngIf="authService.isPortalManager() || authService.isPortalAdminTL() || authService.isPortalParaLegalTL()" [routerLink]="['/notificationslist']"  [hideContent]="hideContent && !showSidebar" [icon]="faBell" [title]="'Notifications'"></app-sidebar-item>
    <app-sidebar-item (click)="authService.logout()" [hideContent]="hideContent && !showSidebar" [icon]="faSignOut" [title]="'Log Out'"></app-sidebar-item>

    <div class="sidebartoggle" (click)="toggleSideBar()">
      <!--fa-icon *ngIf="showSidebar" [icon]="faLongArrowAltLeft"></fa-icon>
      <fa-icon *ngIf="!showSidebar" [icon]="faLongArrowAltRight"></fa-icon-->
      <span *ngIf="showSidebar"><<</span>
      <span class="rightarrow" *ngIf="!showSidebar">>></span>
    </div>
  </div>
</div>
