import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CaseFilesService } from '../../../shared/services/casefiles.service';
import { CaseService } from '../../../shared/services/case.service';
import { CorrespondenceTimelineService } from '../../../shared/services/correspondence-timeline.service';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent implements OnInit {

  faExclamationCircle = faExclamationCircle;

  caseId: number;
  files: any = [];
  existingFiles: any = [];
  failedFiles: any = [];
  newFileName: string = '';
  uploading: boolean = false;
  uploadFailed: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<FileUploaderComponent>,
              private caseService: CaseService,
              private caseFilesService: CaseFilesService,
              private correspondenceTimelineService: CorrespondenceTimelineService) {

    this.caseId = data.caseId;
    this.files = data.files;
    this.existingFiles = data.existingFiles;

    if(this.files.length == 1)
    {
      this.newFileName = this.files[0].name;
    }

  }

  ngOnInit() {
  }



  uploadFiles() {

    this.uploadingFiles().then(
      () => {

        if(!this.uploadFailed)
          this.dialogRef.close(true);
    });


  }

  uploadingFiles() {

    const promises = [];

    this.uploading = true;
    var fileFailed = false

    for(var i = 0; i < this.files.length; i++){

      promises.push(this.uploadFile(this.files[i]));

    }


    return Promise.all(promises);

  }



  uploadFile(file: any): Promise<boolean>{


    return new Promise<boolean>((resolve, reject) => {

      var fileDate = new Date(file.lastModifiedDate);

      this.caseFilesService.uploadFile(file, this.caseId, '', DateTime.fromJSDate(fileDate).toFormat("dd-MM-yyyy HH:mm:ss")).subscribe((response : any) => {
        // Note that this can now result in UPDATED files, not just added -- ADB 2021-08-27
        this.correspondenceTimelineService.refreshCaseTimeline.next();
        resolve(true);

      }, (error : any) => {

        this.uploadFailed = true;

        let failedFile = { name: file.name, error: error.error};

        this.failedFiles.push(failedFile);
        resolve(false);

      });


   });


  }



}
