<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>

<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Add {{feeTypeName}}</h2>

<mat-dialog-content class="mat-typography">

    <app-card-label label="Item">
        <mat-form-field floatLabel="never">
          <mat-select placeholder="Select Fee" (selectionChange)="feeChange()" [(ngModel)]="selectedItem" >
           <mat-option *ngFor="let f of fees" [value]="f">{{f.description}}</mat-option>
         </mat-select>
        </mat-form-field>
      </app-card-label>

      <form [formGroup]="newInvoiceItemDetails" autocomplete="off">
        <app-card-label label="Description (as it appears on invoice)">
          <mat-form-field floatLabel="never">
            <textarea matInput formControlName="description" > </textarea>
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Net">
          <mat-form-field floatLabel="never">
          <span matPrefix>£ &nbsp;</span>
          <input matInput formControlName="net" type="number" >
          </mat-form-field>
        </app-card-label>

        <app-card-label label="Add VAT">
          <mat-checkbox formControlName="addVat" > </mat-checkbox>
        </app-card-label>       

      </form>

</mat-dialog-content>

<div mat-dialog-actions align="end">
    <button mat-stroked-button  cdkFocusInitial color="primary" (click)="addInvoiceItem()" [disabled]="newInvoiceItemDetails.invalid">Add</button>
</div>
