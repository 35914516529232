import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Subscription, interval, Observable } from 'rxjs';
import { SearchRequest } from './interfaces/search-request';
import { SearchResult } from './interfaces/search-result';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class NotificationsService {

  private url = environment.apiUrl + 'notifications/';

  constructor(private http: HttpClient, public router: Router) {


  }


  getNotificationsForUser(){
    return this.http.get<any>(this.url);
  }

  checkUnReadNotificationsForUser(){
    return this.http.get<any>(this.url + 'unread');
  }

  checkUnacknowledgedNotificationsForUser(){
    return this.http.get<any>(this.url + 'unacknowledged');
  }

  markAllAsReadForUser(){
    return this.http.get<any>(this.url + 'readall' );
  }

  markAllAsAcknowledgedForUser(){
    return this.http.get<any>(this.url + 'acknowledgeall' );
  }

  dismissNotification(notificationId: number){
    return this.http.post<any>(this.url + 'dismiss', { notificationId: notificationId }, httpOptions );
  }

  readNotification(notificationId: number){
    return this.http.post<any>(this.url + 'read', { notificationId: notificationId }, httpOptions );
  }

  unreadNotification(notificationId: number){
    return this.http.post<any>(this.url + 'unread', { notificationId: notificationId }, httpOptions );
  }

  notificationHistory(notificationId: number){
    return this.http.get<any>(this.url + 'history/' + notificationId );
  }

  getNotifications(request: SearchRequest): Observable<SearchResult> {

    if (request.filterStrings.join() == '') {
      return this.http.get<SearchResult>(`${this.url + 'all'}?page=${request.page}&pageSize=
      ${request.pageSize}&orderBy=${request.orderBy}&orderDirection=${request.orderDirection}`);
    }

    var queryString = '';

    for (var i = 0; i < request.filterStrings.length; i++) {
      if (request.filterStrings[i] !== '' && request.filterStrings[i] !== null) {
        queryString += `&filterColumns=${request.filterColumns[i]}&filterStrings=${request.filterStrings[i]}`
      }
    }

    return this.http.get<SearchResult>(`${this.url + 'all'}?page=${request.page}&pageSize=
    ${request.pageSize}&orderBy=${request.orderBy}&orderDirection=${request.orderDirection}${queryString}`);

  }

  transferNotifications(userId: number, notificationIds: number[]){

    let params = '';

    for (const notificationId of notificationIds) {
      params += `&notificationIds=${notificationId}`
    }

    params = params.substring(1);

    return this.http.post<any>(`${this.url}transfer/${userId}`, notificationIds,  httpOptions );
  }
}
