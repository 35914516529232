<form [formGroup]="companyGroupDetails" autocomplete="off">
    <app-card-basic cardTitle="Company Group Details" >
        <div card-body>

            <app-card-label label="Name">
                <mat-form-field floatLabel="never">
                    <mat-hint align="end">{{name.value?.length || 0}}/75</mat-hint>
                    <input matInput #name placeholder="Name" maxlength="75" formControlName="name">
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Additional Information">
                <mat-form-field floatLabel="never">
                    <mat-hint align="end">{{information.value?.length || 0}}/250</mat-hint>
                    <textarea cdkTextareaAutosize matInput #information placeholder="Information" maxlength="250" formControlName="information"></textarea>
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Admin Email Address">
                <mat-form-field floatLabel="never">
                    <mat-hint align="end">{{adminEmailAddress.value?.length || 0}}/50</mat-hint>
                    <input matInput #adminEmailAddress placeholder="Email Address" maxlength="50" formControlName="adminEmailAddress">
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Manager Email Address">
              <mat-form-field floatLabel="never">
                  <mat-hint align="end">{{managerEmailAddress.value?.length || 0}}/50</mat-hint>
                  <input matInput #managerEmailAddress placeholder="Email Address" maxlength="50" formControlName="managerEmailAddress">
              </mat-form-field>
            </app-card-label>

            <app-card-label label="Equity Release Fee" >
              <mat-form-field floatLabel="never">
                <span matPrefix>£ &nbsp;</span>
                <input matInput #equityReleaseFee placeholder="Equity Release Fee" formControlName="equityReleaseFee" type="number">
              </mat-form-field>
            </app-card-label>

            <app-card-label label="Bank Account Name">
                <mat-form-field floatLabel="never">                    
                    <input matInput #accountName placeholder="Account Name" maxlength="100" formControlName="accountName">
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Bank Account Number">
                <mat-form-field floatLabel="never">                    
                    <input matInput #accountNumber placeholder="Account Number" maxlength="20" formControlName="accountNumber">
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Bank Sort Code">
                <mat-form-field floatLabel="never">                    
                    <input matInput #sortCode placeholder="Sort Code" maxlength="8" formControlName="sortCode">
                </mat-form-field>
            </app-card-label>

            <app-card-label label="Restrict FA Access and Emails">
              <app-yes-no formControlName="noAccessOrUpdatesForFAs" (change)="checkRestrictFAValue()"></app-yes-no>
            </app-card-label>

            <app-card-label label="Has API Access">
                <app-yes-no formControlName="apiAccess"></app-yes-no>
              </app-card-label>

            <span [hidden]="!companyGroupDetails.controls['apiAccess'].value">
                <app-card-label label="API Client Secret">
                    <mat-form-field floatLabel="never">                    
                        <input matInput #accountNumber placeholder="Client ID" formControlName="apiClientId">
                    </mat-form-field>
                </app-card-label>

                <app-card-label label="API Client Secret">
                    <mat-form-field floatLabel="never">                    
                        <input matInput #sortCode placeholder="Client Secret" formControlName="apiClientSecret">
                    </mat-form-field>
                </app-card-label>
            </span>

        </div>
    </app-card-basic>
</form>

<div class="flex" *ngIf="!newCompanyGroup">
    <span class="spacer"></span>
    <button mat-icon-button disabled color="primary" style="float: right;" *ngIf="updatingGroup">
        <mat-icon>
            <mat-spinner color="primary" diameter="20">
            </mat-spinner>
        </mat-icon>
    </button>
    <button mat-raised-button (click)="saveCompanyGroup()" color="primary" *ngIf="!updatingGroup">Update</button>
</div>

<div class="flex" *ngIf="newCompanyGroup">
    <span class="spacer"></span>
    <button mat-raised-button (click)="addCompanyGroup()" color="primary">Save</button>
</div>
