import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { faPlus, faTimes} from '@fortawesome/pro-light-svg-icons';
import { BasicPopupYesNoComponent } from 'src/app/shared/components/basic-popup-yes-no/basic-popup-yes-no.component';
import {AddFundComponent} from  '../case-funds/add-fund/add-fund.component';
import { CaseFundsService } from '../../../shared/services/case-funds.service';
import { ComparerService } from '../../../shared/utility/comparer';

@Component({
  selector: 'app-case-funds',
  templateUrl: './case-funds.component.html',
  styleUrls: ['./case-funds.component.scss']
})
export class CaseFundsComponent implements OnInit {

  caseId : number;
  caseFunds: any[] = [];
  initialCaseFunds: any[] = []; // To be used to check for any changes
  faPlus = faPlus;
  faTimes= faTimes;
  firstLoad = true;

  caseFundsTotal: number = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<CaseFundsComponent>,
              private caseFundsService: CaseFundsService,
              private comp: ComparerService,
              public dialog: MatDialog) {

    this.caseId = data.caseId;
    this.loadCaseFunds();
  }

  ngOnInit(): void {
  }

  loadCaseFunds(){
    this.caseFundsService.getCaseFunds( this.caseId).subscribe((response : any) => {
      this.caseFunds = response.caseFunds;

      if(this.firstLoad)
        this.initialCaseFunds = this.caseFunds;

      this.firstLoad = false;

      this.caseFundsTotal = 0;
      this.caseFunds.forEach( (fund:any) => {
        this.caseFundsTotal += fund.net;
      });
    });
  }

  addFund(){

    const dialogConfig = new MatDialogConfig();

    //dialogConfig.disableClose = true;
      dialogConfig.data = {
        caseId: this.caseId
    };

    const dialogRef = this.dialog.open(AddFundComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result == true){
        this.loadCaseFunds();
      }
    });

  }

  removeFund(id: number){

    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Are you sure you want to remove this fund?', popupText1: '', popupText2: ''}
    });

    dialogRef.afterClosed().subscribe((result : any) => {

      if(result)
      {
        this.caseFundsService.removeCaseFund( this.caseId, id ).subscribe((response : any) => {
          this.loadCaseFunds();
        });
      }
    });

  }

  close(){


    console.log(this.caseFunds, this.initialCaseFunds);

    if(this.comp.objectsDifferent(this.caseFunds, this.initialCaseFunds)) // check if objs are different
    {
      this.caseFundsService.emailParalegal(this.caseId).subscribe((response : any) => {

        this.dialogRef.close(true);
      });
    }
    else{
      this.dialogRef.close();
    }
     
  }

}
