import { Component, OnInit, Inject } from '@angular/core';
import { faPlus, faTimes, faPencil, faSync, faUpload } from '@fortawesome/pro-light-svg-icons';
import { AddInvoiceItemComponent } from './../add-invoice-item/add-invoice-item.component';
import { EditInvoiceItemComponent } from './../edit-invoice-item/edit-invoice-item.component';
import { MatDialogConfig, MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CaseService } from '../../../../shared/services/case.service';
import { InvoiceService } from '../../../../shared/services/invoice.service';
import { BasicPopupYesNoComponent } from 'src/app/shared/components/basic-popup-yes-no/basic-popup-yes-no.component';
import { BasicPopupOkComponent } from 'src/app/shared/components/basic-popup-ok/basic-popup-ok.component';
import { CorrespondenceTimelineService } from 'src/app/shared/services/correspondence-timeline.service';
import { InvoiceUploaderComponent } from './../invoice-uploader/invoice-uploader.component';
import { AddInvoiceComponent } from '../add-invoice/add-invoice.component';
import { KlyantService } from '../../../../shared/services/klyant.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  faPlus = faPlus;
  faPencil= faPencil;
  faTimes= faTimes;
  faSync= faSync;
  faUpload = faUpload;

  caseId : number;
  invoiceId: number;
  caseTypeId : number;
  invoice: any;

  caseCompleted: boolean;
  hasError: boolean = false;
  errorMessage: any[] = []; 
  feeTypes: any[] = [];
  columnTitles: any[] = [];
  refreshingData: boolean = false;

  invoiceItems: any[] = [];
  professionalCharges: any[] = [];
  additionalCharges: any[] = [];
  disbursements: any[] = [];
  thirdPartyFees: any[] = [];

  professionalChargesTotal: number = 0;
  additionalChargesTotal: number = 0;
  disbursementsTotal: number = 0;
  thirdPartyFeesTotal: number = 0;

  professionalChargesVatTotal: number = 0;
  additionalChargesVatTotal: number = 0;
  disbursementsVatTotal: number = 0;
  thirdPartyFeesVatTotal: number = 0;

  isGeneratingCompletionStatement: boolean = false;
  readOnly: boolean = false;
  sendingToKlyant: boolean = false;

  public creatingInvoice: boolean = false;

  constructor(public dialog: MatDialog,
              private invoiceService: InvoiceService,
              private klyantService: KlyantService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<InvoiceComponent>,
              private correspondenceTimelineService: CorrespondenceTimelineService) {

      this.invoiceId = data.invoiceId;
      this.caseId = data.caseId;
      this.caseTypeId = data.caseTypeId;

  }

  ngOnInit(): void {    

    this.loadInvoiceAndFeeTypes();
   
  }


  loadInvoiceAndFeeTypes(){
    this.invoiceService.getInvoiceById(this.caseId, this.invoiceId).subscribe((response : any) => {
      this.invoice = response.invoice;   
      this.caseCompleted = response.caseCompleted;     

      if(this.invoice.klyantInvoiceId)
      {
        this.readOnly = true;
        this.loadInvoiceItems();
      }
      else
      {
        this.readOnly = false;
        this.generateInvoiceItemsForMissingPreInvoiceFees(); // Also loads invoice items
      }
    });

    // Set the titles based on the API enum description
    this.invoiceService.getFeeTypes().subscribe((response : any) => {      
      this.feeTypes = response;
      this.columnTitles[0] = this.feeTypes.filter(x => x.key == 1)[0].value;
      this.columnTitles[1] = this.feeTypes.filter(x => x.key == 2)[0].value;
      this.columnTitles[2] = this.feeTypes.filter(x => x.key == 3)[0].value;
      this.columnTitles[3] = this.feeTypes.filter(x => x.key == 4)[0].value;
    });
  }

  loadInvoiceAndFeeTypes2(){
    this.invoiceService.getInvoice(this.caseId).subscribe((response : any) => {
      this.invoiceId = response.invoiceId;   
      this.caseCompleted = response.caseCompleted;        
      this.generateInvoiceItemsForMissingPreInvoiceFees(); // Also loads invoice items
    });

    // Set the titles based on the API enum description
    this.invoiceService.getFeeTypes().subscribe((response : any) => {      
      this.feeTypes = response;
      this.columnTitles[0] = this.feeTypes.filter(x => x.key == 1)[0].value;
      this.columnTitles[1] = this.feeTypes.filter(x => x.key == 2)[0].value;
      this.columnTitles[2] = this.feeTypes.filter(x => x.key == 3)[0].value;
      this.columnTitles[3] = this.feeTypes.filter(x => x.key == 4)[0].value;
    });
  }

  disableGenerateInvoice() {
    
    if(this.professionalCharges.length == 0 && this.additionalCharges.length == 0 && this.disbursements.length == 0)
      return true;

    return false;
    
  }

  generateInvoiceItemsForMissingPreInvoiceFees() {
    this.refreshingData = true;
    this.invoiceService.syncInvoiceItemsToPreInvoiceFees(this.caseId, this.invoiceId).subscribe((response : any) => {
      this.loadInvoiceItems();
      this.refreshingData = false;
    });
  }

  loadInvoiceItems() {
    this.invoiceService.getInvoiceItems( this.caseId, this.invoiceId, 1).subscribe((response : any) => {
      this.professionalCharges = response.invoiceItems;

      this.professionalChargesTotal = 0;
      this.professionalChargesVatTotal = 0;
        this.professionalCharges.forEach( (charge:any) => {
        this.professionalChargesTotal += charge.net;
        if(charge.vat)
          this.professionalChargesVatTotal += charge.vat;
      });
    });

    this.invoiceService.getInvoiceItems( this.caseId, this.invoiceId, 2).subscribe((response : any) => {
      this.disbursements = response.invoiceItems;

       this.disbursementsTotal = 0;
       this.disbursementsVatTotal = 0;
        this.disbursements.forEach( (charge:any) => {
        this.disbursementsTotal += charge.net;
        if(charge.vat)
          this.disbursementsVatTotal += charge.vat;
      });
    });

    this.invoiceService.getInvoiceItems( this.caseId, this.invoiceId, 3).subscribe((response : any) => {
      this.additionalCharges = response.invoiceItems;

       this.additionalChargesTotal = 0;
       this.additionalChargesVatTotal = 0;
        this.additionalCharges.forEach( (charge:any) => {
        this.additionalChargesTotal += charge.net;
        if(charge.vat)
          this.additionalChargesVatTotal += charge.vat;
      });
    });

    this.invoiceService.getInvoiceItems( this.caseId, this.invoiceId, 4).subscribe((response : any) => {
      this.thirdPartyFees = response.invoiceItems;

       this.thirdPartyFeesTotal = 0;
       this.thirdPartyFeesVatTotal = 0;
        this.thirdPartyFees.forEach( (charge:any) => {
        this.thirdPartyFeesTotal += charge.net;
        if(charge.vat)
          this.thirdPartyFeesVatTotal += charge.vat;
      });
    });
  }

  addInvoiceItem(feeType: number){

    const dialogConfig = new MatDialogConfig();

    //dialogConfig.disableClose = true;
      dialogConfig.data = {
        caseId: this.caseId,
        invoiceId: this.invoiceId,
        feeType: feeType
    };

    const dialogRef = this.dialog.open(AddInvoiceItemComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result == true){
        this.loadInvoiceItems();
      }
    });

  }

  editInvoiceItem(item: any){

    const dialogConfig = new MatDialogConfig();

    //dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId,
      invoiceId: this.invoiceId,
      invoiceItem: item
  };

    const dialogRef = this.dialog.open(EditInvoiceItemComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result == true){
        this.loadInvoiceItems();
      }
    });
  }

  removeInvoiceItem(itemId: number){
    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Are you sure you want to remove this item?', popupText1: '', popupText2: ''}
    });

    dialogRef.afterClosed().subscribe((result : any) => {

      if(result)
      {
        this.invoiceService.removeInvoiceItem( this.caseId, this.invoiceId, itemId ).subscribe((response : any) => {
          this.loadInvoiceItems();
        });
      }
    });
  }

  generateInvoiceOld() {

    var assignedOutlays = true;

    this.disbursements.forEach((item: any) => {
      if(item.klyantOutlayId == null) {
        assignedOutlays = false;        
      }
    });

    if(!assignedOutlays) {
      var dialogRefOk = this.dialog.open(BasicPopupOkComponent, {
        data: { messageHeader: 'Please send all Pre-Invoice Disbursements to Klyant!', popupText1: 'One or more disbursements have not been sent to  Klyant.', popupText2: ''}
      });  
      dialogRefOk.afterClosed().subscribe((result : any) => { 
        return;
      });
    }
    else {
      var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
        data: { messageHeader: 'Are you sure you want to generate the invoice?', popupText1: 'IF YOU HAVE ALREADY GENERATED AN INVOICE IN KLYANT, YOU MUST ENSURE ALL TRANSACTIONS AND THE INVOICE HAVE BEEN VOIDED IN KLYANT BEFORE CONTINUING.', popupText2: ''}
      });

      dialogRef.afterClosed().subscribe((result : any) => {

        if(result)
        {
          this.creatingInvoice = true;
          this.hasError = false;
          this.invoiceService.generateInvoice(this.caseId).subscribe((response : any) => {
            this.creatingInvoice = false;
            this.dialogRef.close();
            this.correspondenceTimelineService.refreshCaseTimeline.next();
          }, (error: any) => {            
            this.creatingInvoice = false;
            this.hasError = true;
            this.errorMessage = error.error;
          });
        }
      });
    }
  }


  generateInvoice() {

    var assignedOutlays = true;

    this.disbursements.forEach((item: any) => {
      if(item.klyantOutlayId == null) {
        assignedOutlays = false;        
      }
    });

    if(!assignedOutlays) {
      var dialogRefOk = this.dialog.open(BasicPopupOkComponent, {
        data: { messageHeader: 'Please send all Pre-Invoice Disbursements to Klyant!', popupText1: 'One or more disbursements have not been sent to  Klyant.', popupText2: ''}
      });  
      dialogRefOk.afterClosed().subscribe((result : any) => { 
        return;
      });
    }
    else {
      var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
        data: { messageHeader: 'Are you sure you want to generate the invoice?', popupText1: '', popupText2: ''}
      });

      dialogRef.afterClosed().subscribe((result : any) => {

        if(result)
        {
          this.creatingInvoice = true;
          this.hasError = false;
          this.invoiceService.generateInvoiceNumberAndPrintInvoice(this.caseId, this.invoiceId).subscribe((response : any) => {
            this.creatingInvoice = false;
            //this.dialogRef.close();
            this.loadInvoiceAndFeeTypes();
            
            this.correspondenceTimelineService.refreshCaseTimeline.next();
          }, (error: any) => {            
            this.creatingInvoice = false;
            this.hasError = true;
            this.errorMessage = error.error;
          });
        }
      });
    }
  }



  generateCompletionStatement(){

    this.isGeneratingCompletionStatement = true;

    this.invoiceService.generateCompletionStatement( this.caseId, this.invoiceId).subscribe((response : any) => {
      const dialogRef = this.dialog;
      this.dialogRef.close();
      this.correspondenceTimelineService.refreshCaseTimeline.next();
    });

  }

  refreshAutoLines() {

    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Are you sure you want to regenerate the default invoice items?', popupText1: '', popupText2: ''}
    });

    dialogRef.afterClosed().subscribe((result : any) => {

      if(result)
      {
        this.refreshingData = true;
        this.invoiceService.refreshAutoLines( this.caseId, this.invoiceId).subscribe((response : any) => {
          this.loadInvoiceItems();
          this.refreshingData = false;
        });
      }
    });
  }

  openInvoiceUploader(){
    const dialogConfig = new MatDialogConfig();

      dialogConfig.data = {
        caseId: this.caseId
    };

    const dialogRef = this.dialog.open(InvoiceUploaderComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result == true){
        this.generateInvoiceItemsForMissingPreInvoiceFees();
      }
    });
   
  }

  openAddInvoice(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      caseId: this.caseId
    };

    const dialogRef = this.dialog.open(AddInvoiceComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if(result > 0)
      {
        this.invoiceId = result;
        this.loadInvoiceAndFeeTypes();
      }
      
    });
  }

  sendToKlyant()
  {
    this.errorMessage[0] = "";
    var dialogRef = this.dialog.open(BasicPopupYesNoComponent, {
      data: { messageHeader: 'Are you sure you want to send the invoice to Klyant?', popupText1: 'THE INVOICE WILL NOT BE EDITABLE IN THE PORTAL ONCE SENT TO KLYANT', popupText2: ''}
    });

    dialogRef.afterClosed().subscribe((result : any) => {

    if(result)
    {
      this.sendingToKlyant = true;
      this.klyantService.sendInvoiceToKlyant(this.caseId, this.invoiceId).subscribe((response) => {
        this.loadInvoiceAndFeeTypes();
          this.sendingToKlyant = false;
      }, (error: any) => {            
        this.hasError = true;
        this.errorMessage[0] = error.error;
        this.sendingToKlyant = false;
      });

    }});
  }
}
