<div mat-dialog-content class="mat-typography">

    <h1>Delete Key Date - {{keyDateName}}</h1>

    <h2>Are you sure you want to delete this date?</h2>

      <form autocomplete="off" [formGroup]="deleteKeyDateDetails">

        <mat-form-field>
          <textarea autosize matInput #reason placeholder="Please enter the reason" maxlength="200" formControlName="reason"></textarea>
          <mat-hint align="end">{{reason.value?.length || 0}}/200</mat-hint>
        </mat-form-field>

        <div class="buttons">
          <button *ngIf="!isLoading" mat-stroked-button [disabled]="false" color="primary" type="button" (click)="cancel()">Cancel</button>
          <button class="right" *ngIf="!isLoading" mat-stroked-button [disabled]="reason.value?.length ==0" color="warn" (click)="deleteKeyEvent()">Delete</button>

          <button *ngIf="isLoading" mat-icon-button disabled color="primary" class="right" >
              <mat-icon>
                  <mat-spinner color="primary" diameter="20">
                  </mat-spinner>
              </mat-icon>
          </button>
        </div>

      </form>

</div>
