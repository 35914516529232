<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Pre-Submission Checklist</h2>

<h3 class="error" *ngIf="error">{{errorMessage}}</h3>

<mat-dialog-content *ngIf="!error">

    <div class="list" *ngIf="items.length > 0">
        <div class="box" *ngFor="let item of items">
          <span class="itemText" [innerHTML]="item.preSubmissionChecklistItem.itemText"></span>
          <span class="itemResponse">
              <mat-button-toggle-group aria-label="Font Style" [(ngModel)]="item.checklistResponseType" (change)="updateValue(item)">
                  <mat-button-toggle [value]="1">Yes</mat-button-toggle>
                  <mat-button-toggle [value]="2">No</mat-button-toggle>
                  <mat-button-toggle [value]="3">N/A</mat-button-toggle>
                  <mat-button-toggle [value]="4">To Follow</mat-button-toggle>
                </mat-button-toggle-group>

          </span>
          <span class="clearResponse"><button mat-icon-button (click)="clearValue(item)" *ngIf="item.checklistResponseType">x</button></span>
        </div>
      </div>

</mat-dialog-content>


 <div mat-dialog-actions align="end" *ngIf="!error">
  <button mat-stroked-button color="primary"  cdkFocusInitial (click)="generatePDF()" *ngIf="!generatingPDF" >Generate PDF</button>
  <button *ngIf="generatingPDF" mat-icon-button disabled color="primary" class="right" >
    <mat-icon>
        <mat-spinner color="primary" diameter="20">
        </mat-spinner>
    </mat-icon>
</button>
</div>
