<h1>Take Case Off Hold</h1>

<form autocomplete="off" [formGroup]="offHoldDetails">

    <mat-form-field>
      <textarea autosize matInput #offHoldReason placeholder="Off Hold Reason" maxlength="200" formControlName="offHoldReason"></textarea>
      <mat-hint align="end">{{offHoldReason.value?.length || 0}}/200</mat-hint>
    </mat-form-field>

    <div class="buttons">
      <button *ngIf="!isLoading" mat-stroked-button [disabled]="false" color="warn" type="button" (click)="cancel()">Cancel</button>
      <button *ngIf="!isLoading" mat-stroked-button [disabled]="offHoldReason.value?.length ==0"  color="primary" (click)="takeCaseOffHold()">OK</button>

      <button *ngIf="isLoading" mat-icon-button disabled color="primary" class="right" >
          <mat-icon>
              <mat-spinner color="primary" diameter="20">
              </mat-spinner>
          </mat-icon>
      </button>
    </div>

  </form>
