<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>
<div mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
  <h2>
    <span *ngIf="!readOnly && !forwardingEmail">New </span><span *ngIf="forwardingEmail">Forwarding </span>Email
    <span *ngIf="isDraftEmail">
      <span class="draftText">  [DRAFT]</span>
      <button mat-stroked-button (click)="dismissDraft()">Dismiss <fa-icon [icon]="faTrashAlt"></fa-icon></button>
    </span>
  </h2>
</div>

<!--<div class="toolbar-wrapper" [class.readonly]="readOnly">
  <div class="toolbar">
    <ul>
      <li>
        <button mat-button (click)="toggleHighImportance()" [class.active]="isHighImportance" [disabled]="readOnly">
          <fa-icon [icon]="faExclamation" [class.active]="isHighImportance"></fa-icon> High Importance
        </button>
      </li>
      <li>

      </li>
      <li>

      </li>
    </ul>
  </div>
</div>-->

<mat-dialog-content class="mat-typography">

  <div class="actionbuttons">
    <button mat-button (click)="toggleHighImportance()" [class.active]="isHighImportance" [disabled]="readOnly">
      <fa-icon [icon]="faExclamation" [class.active]="isHighImportance"></fa-icon> High Importance
    </button>
    <button mat-stroked-button (click)="forwardEmail()" *ngIf="readOnly">
      <fa-icon [icon]="faLongArrowRight" size="lg"></fa-icon> Forward
    </button>
  </div>
  <form [formGroup]="emailDetails" autocomplete="off">


    <div class="wrapper">



      <div class="row">
        <div class="send" [ngClass]="{'hidden': readOnly}">

          <button mat-stroked-button (click)="sendEmail()" [disabled]="sendingEmail || emailDetails.invalid">
            <fa-icon [icon]="faPaperPlane" size="lg"></fa-icon>
            <br />Send
          </button>
        </div>
        <div class="inner-wrapper">
          <div class="to">
            <button mat-stroked-button (click)="selectAddress('to')" [disabled]="sendingEmail || readOnly">To</button>
            <mat-form-field>
              <input matInput value="" formControlName="toAddresses" (change)="validateEmails()">
            </mat-form-field>
          </div>
          <div class="cc">
            <button mat-stroked-button (click)="selectAddress('cc')" [disabled]="sendingEmail || readOnly">CC</button>
            <mat-form-field>
              <input matInput value="" formControlName="ccAddresses">
            </mat-form-field>
          </div>

        </div>

      </div>

      <div class="row">

        <div class="save" [ngClass]="{'hidden': readOnly}">
          <button mat-stroked-button (click)="saveDraftEmail()" *ngIf="!readOnly && !forwardingEmail">
            <fa-icon [icon]="faSave" size="lg"></fa-icon>
            <br />Save
          </button>
        </div>

        <div class="inner-wrapper">

          <div class="subject">
            <div class="option-label">
              Subject
            </div>

            <mat-form-field>
              <input matInput value="" formControlName="subject">
            </mat-form-field>
          </div>


          <div class="from">
            <div class="option-label">
              From
            </div>

            <mat-form-field floatLabel="never" *ngIf="!readOnly">
              <mat-select formControlName="fromUserTypeId">
                <mat-option [value]="0">enquiries@adlingtonlaw.co.uk</mat-option>
                <mat-option [value]="1">{{currentUserEmail}}</mat-option>
              </mat-select>
            </mat-form-field>

            <label class="fromEmailAddress">{{fromEmailAddress}}</label>
          </div>
        </div>
      </div>


      <div class="row" *ngIf="caseFiles.length > 0">
        <div class="files-label" [ngClass]="{'no-lmargin': readOnly || forwardingEmail}">
          <fa-icon [icon]="faPaperclip" size="lg"></fa-icon>
        </div>
        <div class="filenames">
          <span *ngFor="let file of caseFiles"><span class="clickable" (click)="openFile(file)"> {{file.fileName}} </span> <button *ngIf="!readOnly" mat-icon-button title="Remove" class="removeButton" (click)="removeAttachment(file.caseFileId)">X</button> </span>
        </div>
      </div>

     

      <div class="ckeditor">
        <div *ngIf="!readOnly">
          <ckeditor formControlName="body"
                    [editor]="Editor"
                    [data]="data"
                    [config]="config"
                    (ready)="onCkEditorReady($event)"></ckeditor>

        </div>

        <div class="row border" *ngIf="readOnly || forwardingEmail">
          <div class="emailBody" [innerHTML]="emailBody | emailBodyHtmlPipe"></div>
        </div>
      </div>

      <div class="row">
        <div *ngIf="!readOnly" class="emailSig" [innerHTML]="emailSignatureHtml"></div>
      </div>

    </div>

  </form>



</mat-dialog-content>

<mat-dialog-actions *ngIf="readOnly">
  <div class="row">
    Date Created : {{emailObj.queuedDate  | date:'dd/MM/yyyy HH:mm'}}<br />
    Date Sent : {{emailObj.sentDate  | date:'dd/MM/yyyy HH:mm'}}
  </div>
</mat-dialog-actions>
