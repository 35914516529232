import { Component, OnInit, Inject, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LettersService } from '../../../shared/services/letters.service';
import { LetterTemplateComponent } from './letter-template/letter-template.component';
import { CorrespondenceTimelineService } from 'src/app/shared/services/correspondence-timeline.service';

@Component({
  selector: 'app-letters',
  templateUrl: './letters.component.html',
  styleUrls: ['./letters.component.css']
})
export class LettersComponent implements OnInit {

  letterGroupType: number = 0;
  caseId: number;
  caseTypeId: number;
  otherLettersOnly: boolean = true;
  letters: any[] = [];
  generatingLetters: boolean = false;
  generatingError: boolean = false;
  errorMessage: string = "";
  searchKeyword: string = "";

  @ViewChildren(LetterTemplateComponent) selectedLetters!: QueryList<LetterTemplateComponent>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<LettersComponent>,
              private lettersService: LettersService,
              private correspondenceTimelineService: CorrespondenceTimelineService) {


      this.caseId = data.caseId;
      this.caseTypeId = data.caseTypeId;

      // some case types will have specific case letters - all others won't
      if(this.caseTypeId == 1 || // Equity Release
        this.caseTypeId == 2  || // Lasting Power of Attorney
        this.caseTypeId == 3  || // Probate
        this.caseTypeId == 5  || // Wills
        this.caseTypeId == 6 || // Conveyancing Sale
        this.caseTypeId == 7 || // Equity Release Including Purchase
        this.caseTypeId == 8)	// Conveyancing Purchase
        this.otherLettersOnly = false;
      else
        this.letterGroupType = 4; // Other

      this.loadLettersForType();
  }

  loadLettersForType(){

    this.letters = [];
    this.searchKeyword= "";

    if(this.letterGroupType == 1)
    {

      this.lettersService.getLettersForCaseType(this.caseTypeId).subscribe((response : any) => {

        this.letters = response.letters;
      });
    }
    else if(this.letterGroupType == 2)
    {
      this.lettersService.getAdminLettersForCaseType(this.caseTypeId).subscribe((response : any) => {

        this.letters = response.letters;
      });
    }
    else if(this.letterGroupType == 3)
    {
      this.lettersService.getMortgageLetters().subscribe((response : any) => {

        this.letters = response.letters;
      });
    }
    else if(this.letterGroupType == 4)
    {
      this.lettersService.getAllOtherLetters().subscribe((response : any) => {

        this.letters = response.letters;
      });
    }

  }

  generateLetters(){

    let letters: any [] = [];

    this.generatingLetters = true;
    this.generatingError = false;

    this.selectedLetters.forEach(lt => {
      if(lt.selected == true)
      {
        //items.push(lt.details.id);
        letters.push(lt.details);
      }
    });


    this.lettersService.generateLetters(this.caseId, letters).subscribe((response : any) => {


      this.dialogRef.close();
      this.correspondenceTimelineService.refreshCaseTimeline.next();



    }, (error : any) => {

      // BadRequest - means the request to generate the documents failed

      console.log(error);

      this.generatingError = true;
      if(error.status == 999)
        this.errorMessage = error.error;
      else if(error.status == 500 )
        this.errorMessage = "Internal Error";
      else
        this.errorMessage = error.statusText;

    });
  }

  atLeastOneItemSelected() {
    if (!this.selectedLetters) return false;
    return this.selectedLetters.some(p => p.selected == true);
  }


  ngOnInit(): void {
  }

}
