<button mat-icon-button class="close" tabindex="-1" mat-dialog-close>x</button>

<h2 mat-dialog-title cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">Add Allowance</h2>

<mat-dialog-content class="mat-typography">
    <form [formGroup]="newAllowanceDetails" autocomplete="off">

        <app-card-label label="Description">
            <mat-form-field floatLabel="never">
              <input type="text" matInput formControlName="description" />
            </mat-form-field>
          </app-card-label>

          <app-card-label label="Amount">
            <mat-form-field floatLabel="never">
            <span matPrefix>£ &nbsp;</span>
            <input matInput formControlName="amount" type="number" >
            </mat-form-field>
          </app-card-label>

    </form> 
</mat-dialog-content>

<div mat-dialog-actions align="end">
    <button mat-stroked-button  cdkFocusInitial color="primary" (click)="addAllowance()" [disabled]="newAllowanceDetails.invalid">Add</button>
</div>